import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';

interface GeocodeResponse {
  results: { geometry: { location: { lat: number, lng: number } } }[];
  status: string;
}

@Injectable({
  providedIn: 'root'
})
export class GeoCodingService {
  private GoogleApiKey = 'AIzaSyA_VDafK_hW4l5i4TfENskMzxsCxVesUJc';

  constructor(private http: HttpClient) {}

  getCoordinates(address: string): Observable<{ lat: number, lng: number }> {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${this.GoogleApiKey}`;
    return this.http.get<GeocodeResponse>(url).pipe(
      map(response => {
        console.log(response);
        if (response.status === 'OK' && response.results.length > 0) {
          const location = response.results[0].geometry.location;
          return { lat: location.lat, lng: location.lng };
        } else {
          throw new Error('Location not found');
        }
      })
    );
  }
}
