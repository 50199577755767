import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService } from 'src/app/shared/alert/alert.service';
import { Role } from 'src/app/shared/enums/role.enum';
import { ServerApisService } from 'src/app/shared/services/server-apis.service';
import { Constants,navConstants } from 'src/app/shared/constants/constant';
import { RouteMakingService } from '../../shared/services/route-making.service';

@Component({
  selector: 'app-user-roles-admin-approval',
  templateUrl: './user-roles-admin-approval.component.html',
  styleUrls: ['./user-roles-admin-approval.component.css']
})
export class UserRolesAdminApprovalComponent {
  userPlaceholder = 'Search for User';
  searchtext: string = '';
  searchtextApproved: string = '';
  searchtextRejected: string = '';
  page = 'Main Profession Approval';
  usersList: any = [];
  isEditIndex: number;
  p: number = 1;
  currentData: any;
  currPagePendingMain: number;
  currPageApprovedMain: number;
  selectedOption: string = '';
  selectedAction: string;
  showBreadcrumb3: boolean = false;
  showDeleteModalUser: boolean;
  tempUserList: any;
  searchTextUser: any = '';
  selectedTab: string = 'userPending'; 
  selectedMainProfession: string;

  constructor(private routeMakingService:RouteMakingService,private router: Router, private cdr: ChangeDetectorRef, private _apiService: ServerApisService, protected alertService: AlertService, private spinner: NgxSpinnerService,) { }

  ngOnInit(): void {
  this.getAllUserApprovalCategory('pending');
  this.fetchData();
  this.clearSearchTexts();
  this.selectedTab = 'mainPending';
    
  }
  clearSearchTexts() {
    throw new Error('Method not implemented.');
  }


  fetchData() {
    const user = sessionStorage.getItem('user');
    if (user != null) {
      let data = JSON.parse(user);
      if (data.roleId !== Role.Admin && data.roleId !== Role.SubAdmin) {
        this.router.navigate([""]);
        return;
      }
    } else {
     this.router.navigate([this.routeMakingService.getNavigateUrl([Constants.Login])]);
      return;
    }
  }
  clearFields(){
    this.searchtextApproved = '';
    this.searchtext = '';
    this.searchtextRejected = ''
    }

    pageChanged(event: any) {
      this.p = event;
      this.isEditIndex = -1;
    }

    toggleIcon(data: any) {
      data.showMetaInfo = !data.showMetaInfo;
  }
  
  toggleIconUser(data:any){
    data.expand = !data.expand;
  }

  getAllUserApprovalCategory(type: string) {
    this.spinner.show();
    this._apiService.FetchUserRolesAdminApproval(type).subscribe((res: any) => {
      this.spinner.hide();
      this.usersList = res.FetchUserRolesAdminApprovalResult.resultUserRoles;
      this.tempUserList = this.usersList;
    });
  }

  searchUserApproval(event: any) {
    if (event.target.value != "" && event.target.value != null && event.target.value != undefined) {
      var result = this.usersList.filter((x: any) => x.roleName.toLowerCase().includes(event.target.value.toLowerCase()))
      this.usersList = result;
    } else {
      this.usersList = this.tempUserList;
    }
  }

  dismissModal() {
    this.showDeleteModalUser = false;
  }
  approveFunction(data: any) {
  }
  
  rejectFunction(data: any) {
  }

  cancelFunction(data: any) {
   data.showMetaInfo = false;
   data.expand = false;
  }

  getDataFromApi(tab: string) {
    this.selectedTab = tab;
      switch (tab) {
     case 'userPending':
        this.getAllUserApprovalCategory('pending');
        break;
      case 'userApproved':
        this.getAllUserApprovalCategory('approved');
        break;
      case 'userRejected':
        this.getAllUserApprovalCategory('rejected');
        break;
      default:
        break;
    }
  }

  dropdownAction(action: string, d: any, component: string) {
    this.currentData = d;
    if (action === 'selected') {
      this.selectedOption = '';
     this.showDeleteModalUser = false;
    } else if (action === 'approved' || action === 'reject') {
      if (this.selectedOption !== action) {
        this.selectedOption = action;
        this.selectedMainProfession='';
        this.selectedMainProfession = d.mainProfessionName;
        this.selectedAction = action;
      }
      if (component === 'main') {
       } else if (component === 'child') {
       } else if (component === 'user') {
         this.showDeleteModalUser = true;
      }
    }
  }

  handleApprovalOrRejectionUser(){
    if (this.selectedAction === 'approved') {
      this.approvedUser();
    } else if (this.selectedAction === 'reject') {
      this.rejectUser();
    }
    this.selectedAction = '';
  }

  approvedUser(){
    let data = this.currentData;
    let reqBody = {
      "roleId": data.roleId,
      "roleName": data.roleName,
      "roleNameNew": data.roleNameNew,
      "approvedBy": sessionStorage.getItem('memberId'),
      "isDeleted": data.isDeleted,
      "isApproved": "Y"
    }
    this._apiService.AdminApprovalUserRoles(reqBody).subscribe((res:any)=>{
      if(res.status==200){
        this.alertService.success(res.result);
        // this.getAllUserApprovalCategory('approved');
        window.location.reload();
      }else{
        this.alertService.error(res.result)
      }
    })
    this.showDeleteModalUser = false;
 }

  rejectUser(){
    let data = this.currentData;
    let reqBody = {
      "roleId": data.roleId,
      "roleName": data.roleName,
      "roleNameNew": data.roleNameNew,
      "approvedBy": sessionStorage.getItem('memberId'),
      "isDeleted": data.isDeleted,
      "isApproved": "N"
    }
    this._apiService.AdminApprovalUserRoles(reqBody).subscribe((res:any)=>{
      if(res.status==200){
        this.alertService.success(res.result);
        // this.getAllUserApprovalCategory('rejected');
        window.location.reload();
      }else{
        this.alertService.error(res.result)
      }
    })
    this.showDeleteModalUser = false;
   }


}
