import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/shared/alert/alert.service';
import { ServerApisService } from 'src/app/shared/services/server-apis.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Role } from 'src/app/shared/enums/role.enum';
import { Constants,navConstants } from 'src/app/shared/constants/constant';
import { RouteMakingService } from 'src/app/shared/services/route-making.service';

@Component({
  selector: 'app-vendor-creation-vendor-management',
  templateUrl: './vendor-creation-vendor-management.component.html',
  styleUrls: ['./vendor-creation-vendor-management.component.css']
})
export class VendorCreationVendorManagementComponent {
  vendorForm: FormGroup;
  @ViewChild('selectBox') selectBox: NgSelectComponent;
  @ViewChild('selectBoxs') selectBoxs:NgSelectComponent;
  lstVendors: any = [];
  lstTempVendors: any = [];
  lstalluserVendors:any=[];
  lsttempassignVendors:any=[];
  p = 1;
  vendorIdsort:string="";
  vendorCodesort:string="";
  vendorNamesort:string="";
  submitted:boolean=false;
  selectedIndex:number;
  vendorCode: string = '';
  vendorName: string = '';
  isEditIndex: number;
  isEdit: boolean = false;
  isAdd: boolean = false;
  isdelete:boolean=false;
   searchtext:string='';
  placeholderroleText:string="Select Role"
  page: string = 'vendorcreation';
  placeholderText: string = 'Search with VendorId and Vendor Name';
  lstVendorsnumber: any[];
  lstTempVendorsnumber: any[];
  lstassignvendor: { rolemaskingNumbersIdId: number; mobileMaskingNumber: string; }[];
  lsttempassignvendor: { rolemaskingNumbersIdId: number; mobileMaskingNumber: string; }[];
  lstassignVendors: any;
  

  constructor(private spinner: NgxSpinnerService,private routeMakingService: RouteMakingService, private _apiService: ServerApisService, private router: Router,
    private _alertService: AlertService,private formBuilder: FormBuilder) { }

  ngOnInit(): void {
      const user = sessionStorage.getItem('user');
      if (user != null) {
        let data = JSON.parse(user);
        if (data.roleId == Role.Admin || data.roleId == Role.SubAdmin) {
          this.getVendors();
         } else {
          this.router.navigate([""]);
        
          
        }
   } else {
        this.router.navigate([this.routeMakingService.getNavigateUrl([Constants.Login])]);
      }
      this.vendorForm = this.formBuilder.group({
        selectedUser:[]
      });
    }
    selectedVendor:string="";
    selectedVendorId:any;
    isdeleteservice:boolean=false;
    selectedDelteId:any;
  SelectedTest:any;
  selectedmemberId:any;

    deleteServices(){
      let payload:any={};
      payload.memberId=this.selectedmemberId;
      payload.CategoryId=(+this.selectedDelteId);
      this.spinner.show();
      
      this._apiService.DeleteServiceCategory(payload).subscribe(res => {
        
        this.spinner.hide();
        this.isdeleteservice=false;
        if(res.resultModel.status==200){
            this._alertService.success(res.resultModel.result);
          }
     
      });
      
    }
    update (id: number, data:any){
    
      this.submitted=true;
      let payload:any={};
      if(id==0){
        payload.vendorId=0;
        payload.vendorCode=this.vendorCode;
        payload.vendorName=this.vendorName;
      }else{
        payload.vendorId=data.vendorId;
        payload.vendorCode=this.vendorCode;
        payload.vendorName=this.vendorName;
      }
      
      
      if(this.vendorCode!='' && this.vendorName!=''){
        this.spinner.show();
        this._apiService.InsertUpdateVendors(payload).subscribe(res => {
          this.spinner.hide();
          
          if(res['status']==200){
            if(id==0){
              document.getElementById('registersdata').style.display='none'
            }
            this.vendorCode = '';
            this.vendorName = '';
            this.submitted=false;
            this.isEdit=false;
            this.isAdd=false;
            this.selectedIndex=-1;
            this._alertService.success(res['result']);
          }
        });
      }
      
    }
    removeOption(event:any,d){
    
      if(event.target.value){
        
        let result=d.splitServices.filter((x:any)=>x.name==event.target.value);
        
        this.isdeleteservice=true;
        this.selectedDelteId=result[0].id;
        this.SelectedTest=result[0].name;
        this.selectedmemberId=d.memberId;
      }
  
    }
    selected(d:any){
      this.selectedVendorId=d;
      this.selectedVendor=d.vendorName;
      this.isdelete=true;
    }
    deleteserviceCancel(){
      this.isdeleteservice=false;
      this.selectedDelteId='';
      this.SelectedTest='';
      this.selectedmemberId='';
    }
    searchVendorChange(event: any) {
      if (event.target.value != '' && event.target.value != undefined) {
        var result = this.lstTempVendors.filter((x: any) => x.vendorId.toString().toLowerCase().includes(event.target.value.toLowerCase())
          || x.vendorName.toLowerCase().includes(event.target.value.toLowerCase())
          || x.maskingMobileNumber.toLowerCase().includes(event.target.value.toLowerCase()))
        this.lstVendors = result;
      } else {
        this.lstVendors = this.lstTempVendors;
      }
    }
    pageChanged(event: any) {
      this.p = event;
      this.isEditIndex=-1;
      this.getVendors();
   }
    edit(data: any, index: number) {
      this.isEdit=true;
      this.vendorCode = data.vendorCode;
      this.vendorName = data.vendorName;
      this.selectedIndex=index;
    }
    
    cancel(){
      if(this.isAdd){
        this.lstVendors.pop();
      }
      this.isAdd=false;
        this.isEdit=false;
      this.selectedIndex=-1;
  }
  getVendors() {
    this.spinner.show();
    this._apiService.GetVendors().subscribe(res => {
      this.spinner.hide();
      this.lstVendors = res.FetchAllVendorsResult['resultAllVendors'];
      this.lstTempVendors = this.lstVendors; 
    }, error => {
      this.spinner.hide();
      console.error('Error fetching vendors:', error);
    });
  }
  
  addpop(){
    this.vendorCode="";
    this.vendorName="";
    this.isEdit=false;
    this.selectedIndex=-1;
    this.submitted=false;
  }
    close(){
      this.selectedIndex=-1;
    }
    ok(){
      this.deleteRole(this.selectedVendorId);
    }

    deleteRole(data:any){
      let payload:any={};
      payload.vendorId=data.vendorId
      this.spinner.show();
      this._apiService.deleteVendor(payload).subscribe(res => {
        this.spinner.hide();
       this.submitted=false;
        this.isEdit=false;
        this.isAdd=false;
        this.isdelete=false;
        this.selectedIndex=-1;
        this._alertService.success(res['result']);
      });
    }
    add(){
      this.isAdd=true;
      this.isEdit=false;
      this.submitted=false;
      this.vendorCode = '';
      this.vendorName = '';
      this.selectedIndex=this.lstVendors.length;
 }
 vendorIdOnsort(){
  if (this.vendorIdsort == "") {
    this.vendorIdsort = "up";
    this.lstVendors.sort((a, b) => (b.vendorId > a.vendorId) ? -1 : 1);

  } else if (this.vendorIdsort == "up") {
    this.vendorIdsort = "down";
    this.lstVendors.sort((a, b) => (a.vendorId > b.vendorId) ? -1 : 1);

  } else if (this.vendorIdsort == "down") {
    this.vendorIdsort = "";
    this.lstVendors.sort((a, b) => a.vendorId - b.vendorId);
  }
}

vendorCodeOnsort(){
  if (this.vendorCodesort == "") {
    this.vendorCodesort = "up";
    this.lstVendors.sort((a, b) => (b.vendorCode > a.vendorCode) ? -1 : 1);

  } else if (this.vendorCodesort == "up") {
    this.vendorCodesort = "down";
    this.lstVendors.sort((a, b) => (a.vendorCode > b.vendorCode) ? -1 : 1);

  } else if (this.vendorCodesort == "down") {
    this.vendorCodesort = "";
    this.lstVendors.sort((a, b) => a.vendorId - b.vendorId);
  }
}

vendorNameOnsort(){
  if (this.vendorNamesort == "") {
    this.vendorNamesort = "up";
    this.lstVendors.sort((a, b) => (b.vendorName > a.vendorName) ? -1 : 1);

  } else if (this.vendorNamesort == "up") {
    this.vendorNamesort = "down";
    this.lstVendors.sort((a, b) => (a.vendorName > b.vendorName) ? -1 : 1);

  } else if (this.vendorNamesort == "down") {
    this.vendorNamesort = "";
    this.lstVendors.sort((a, b) => a.vendorId - b.vendorId);
  }
}
}
