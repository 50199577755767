<div class="row pad2-5" *ngIf="lstChildProfessions.length>0">
    <div class="col-12 col-sm-6 col-md-4 col-lg-3" *ngFor="let d of lstChildProfessions">
        <a 
            [href]="generateLink(d)" 
            target="_blank" 
            style="text-decoration: none; color: inherit;"
            (click)="handleClick($event, d)">
            <div class="Rectangle-647">
                <div class="sub-category d-flex rounded-3">
                    <div class="MA">
                        <h6 class="my-auto">{{getchar(d.category)}}</h6>
                    </div>
                    <label class="Artist text-left my-auto">
                        {{d.category}}
                    </label>
                </div>
            </div>
        </a>
    </div>
    
</div>