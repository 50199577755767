import { Injectable } from '@angular/core';
import { ServerApisService } from './server-apis.service';
import { Subject } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  private allProfessionsSubject = new Subject<any>(); 
  public data: any;

  constructor(private apiService: ServerApisService,private spinner:NgxSpinnerService) { }

  get allProfessions$() {
    return this.allProfessionsSubject.asObservable();
  }

  private setAllProfessions(data: any) {
    this.allProfessionsSubject.next(data);
  }

  getDashboardDetails() {
    return this.data;
  }

  setDashboardDetails(data: any) {
    this.data = data;
  }

  getAllProfession(data: any) {
    this.apiService.GetDashboardDetails(data).subscribe((response: any) => {
      this.spinner.hide();
      this.setDashboardDetails(response);
      this.setAllProfessions(response); 
    });
  }
}