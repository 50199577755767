<div class="mt-4 m-2">
  <nav class="mb-50" breadcrumb>
    <app-bread-crumb [breadTitle]="controlPanelTitle" [breadpath1]="home" [breadpath2]="adminPanel" [breadroute1]="controlPanelRoute"></app-bread-crumb>
    </nav>
<div class="mb-4 m-2">
    <div class="row">
      <div class="col-12">
       <div class="row row-cols-1 row-cols-md-2 g-4">
          <div class="col-12 col-md-3" *ngFor="let d of lstcomponents">
            <h5 (click)="gotoRoute(d)" 
                class="titlename Rounded-Rectangle-17 text-center cursor-pointer"
                [ngClass]="{'custom-padding': d.pagename== 'Account Verification' || d.pagename=='Role Management'||  d.pagename=='Vendor Management'}">
              {{ d.pagename }}
            </h5>
          </div>
          
        </div>
      </div>
    </div>
  </div>
</div>
