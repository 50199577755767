<div class="tasks_table1 ">
    <div class="row">
        <div class="col-md-10 search-box">
            <label style="width: 100%;"><span><input type="text" id="search"
                        class="custom-focus-input" autocomplete="off" [(ngModel)]="searchtext"
                        [placeholder]="placeholderText" 
                        style="height: 36px;border: none;width: 113.6%;margin-left: 1px;"
                        (input)="searchroleChange($event)"></span></label>
                 </div>
        <div class="col-md-2" (click)="addpop()" style="padding: 8px;text-align: center;">
            <button class=" hand add"
                onclick="document.getElementById('registersdata').style.display='block'" (click)="openAddPop()">Add
            </button>
        </div>
    </div>
</div>

<table class="table table-striped">
    <thead class="tablehd-sec">
        <tr>
            <th style="width: 20%;" class="padleft-30">Role Id</th>
            <th style="width: 25%;">Role Code</th>
            <th style="width: 25%;">RoleName</th>
            <th style="width: 30%;">Action</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let d of lstRoles| paginate: { itemsPerPage: 10, currentPage: p};let i = index"
            class="tdata">
            <td style="width: 20%;padding-left: 2%;" class="padleft-30">{{d.roleId}}</td>
            <td style="width: 25%;">
                <p *ngIf="selectedIndex!=i">{{d.roleCode}}</p>
                <input type="text" *ngIf="(isEdit || isAdd) && selectedIndex==i" class="form-control"
                    [(ngModel)]="roleCodeNew">

            </td>
            <td style="width: 25%;">
                <p *ngIf="selectedIndex!=i">{{d.roleName}}</p>
                <input type="text" *ngIf="(isEdit || isAdd) && selectedIndex==i" class="form-control"
                    [(ngModel)]="roleNameNew">

            </td>
            <td style="width: 30%;">
                <i *ngIf="selectedIndex!=i" (click)="edit(d,i)" class="fa fa-pencil thclr bl hand"
                    style="margin-right: 10px;"></i>

                <i class="fa fa-save thclr hand bl" (click)="update(d.roleId,d)"
                    style="margin-right: 10px;" *ngIf="isEdit && !isAdd && selectedIndex==i"></i>
                <i class="fa fa-trash-o hand dangr" style="font-size: 20px; margin-right: 10px;"
                    *ngIf="selectedIndex!=i || isEdit" (click)="selected(d)"
                    onclick="document.getElementById('deletee').style.display='block'"></i>
                <i class="fa fa-close thclr hand dangr" (click)="close()" style="font-size: 20px;"
                    *ngIf="isEdit && !isAdd && selectedIndex==i"></i>

            </td>
        </tr>
    </tbody>
</table>
<div class="row">
    <div class="col">
        <div class="d-flex justify-content-center" *ngIf="lstRoles.length!=0">
            <pagination-controls (pageChange)="pageChanged($event)"
                (pageBoundsCorrection)="pageChanged($event)">
            </pagination-controls>
        </div>
    </div>
</div>
<div  *ngIf="showPopupForAddRole" class="modal" id="registersdata" style="overflow: hidden;padding-top: 50px;">
    <div class="modal-content" style="width: 30%;">
        <div class="modal-header" style="padding-top: 0;">
            <h4 class="modal-title text-center" id="myModalLabel">Add Role</h4>
            <span onclick="document.getElementById('registersdata').style.display='none'" class="close hand"
                title="Close Modal" (click)="closeAddPop()">×</span>
        </div>
        <div class="modal-body" style="padding-bottom: 0;">

            <div class="row" style="margin-bottom:15px;">
                <label style="padding: 0;">Role Code<span style="color: red;">*</span></label>
                <input type="text" class="form-control " [(ngModel)]="roleCode" placeholder="Enter role code">
                <label style="color: red;padding: 0;" *ngIf="roleCode=='' && submitted">Role code is
                    required</label>
            </div>
            <div class="row">
                <label style="padding: 0;">Role Name<span style="color: red;">*</span></label>
                <input type="text" class="form-control" [(ngModel)]="roleName" placeholder="Enter role name">
                <label style="color: red;padding: 0;" *ngIf="roleName=='' && submitted">Role name is
                    required</label>
            </div>

            <div class="row mrt-20">
                <button class="save hand" (click)="insertData()">Save</button>
            </div>

        </div>

    </div>

</div>
<div id="deletee" class="modal" style="display: block; overflow: hidden;" *ngIf="isdelete">

    <form class="modal-content" action="/action_page.php" style="width: 35%;">
        <div class="container">
            <div class="modal-header" style="padding-top: 0;">
                <h5 class="modal-title" id="exampleModalLabel" style="margin-left: auto;">Confirm</h5>
                <button type="button" id="dismissModal"
                    onclick="document.getElementById('deletee').style.display='none'" class="btn-close"
                    data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            
            <div style="padding-top: 15px;
        padding-bottom: 15px; text-align: center;">
                <p>Are you sure, you want to delete </p>
                <p> <strong>{{selectedRole}}</strong> role?</p>
            </div>
            <div class="modal-footer" style="padding-bottom: 0;">
                <button type="button" onclick="document.getElementById('deletee').style.display='none'"
                    class="btn btn-danger footer-button ml-auto" data-bs-dismiss="modal"
                    style="width: 18%;margin-left: auto;">Cancel</button>
                <button type="button" class="btn btn-primary footer-button mr-auto" (click)="ok()"
                    style="width: 18%;margin-right: auto;">Ok</button>
            </div>
        </div>
    </form>
</div>