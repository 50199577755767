import {
  Component,
  Inject,
  OnInit,
  Renderer2,
  ElementRef,
  PLATFORM_ID,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core';
import { ServerApisService } from './shared/services/server-apis.service';
import firebase from 'firebase/compat/app';
import 'firebase/auth';
import { NavigationEnd, Router } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { AlertService } from './shared/alert/alert.service';
import { SitemapService } from './shared/services/sitemap.service';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { PopupComponent } from './Components/popup/popup.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { StaticRoutesService } from './shared/services/static-routes.service';
import { DataService } from './shared/services/data.service';
import { Constants } from './shared/constants/constant';
import { DashboardService } from './shared/services/dashboard.service';
import { SearchService } from './shared/services/search.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  @ViewChild('popup') popupComponent: PopupComponent;
  //title = 'Toskie';
  originUrl: string = this.document.location.href;
  isHeader: boolean = true;
  categoryId: any;
  isFooter: boolean;
  allStaticRoutes = this.staticRoutes.getStaticRoutes();
  isStaticRoute: any;
  popUpFlag: any;
  hideButtons: boolean = false;
  constructor(
    private cdr: ChangeDetectorRef,
    private _apiService: ServerApisService,
    private _route: Router,
    private title: Title,
    private meta: Meta,
    @Inject(DOCUMENT) private document: Document,
    private route: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: Object,
    private _alertService: AlertService,
    private _sitemapService: SitemapService,
    private renderer: Renderer2,
    private el: ElementRef,
    private spinner: NgxSpinnerService,
    private staticRoutes: StaticRoutesService,
    private dataService: DataService,
    private dashboardService: DashboardService,
    private searchService: SearchService,
    private metaService: Meta
  ) {
    this.updateMetaTags();
    this.isStaticRoute = this.allStaticRoutes.some((route) =>
      window.location.pathname.includes(route)
    );
    this._route.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        let url = event.urlAfterRedirects;
        if(url.includes(environment.originUrl)) {
          url = url.replace(environment.originUrl, '');
        }
        url  = environment.originUrl + url;
        this.searchService.updateCanonicalLink(url);
        this.metaService.addTags([{ property: 'og:description', content: 'Toskie helps you get your job done. Find expert services for beauty, repair, cleaning, painting, plumbing, bird netting, and more. One-stop destination for your personal, professional, and household needs. get 100% safe service from verified partners.' }]);
        if (url.includes('profile/')) {
          this.categoryId = 1;
        } else if (
          url.includes('admin') ||
          url.includes('adminpanel/') ||
          url.includes('user/edit') ||
          url.includes('not-found')
        ) {
          this.categoryId = 0;
        } else if (
          url.includes('contact-us') ||
          url.includes('privacy-policy') ||
          url.includes('add-service') ||
          url.includes('rating') ||
          url.includes('login') ||
          url.includes('register') ||
          url.includes('forgot-password') ||
          url.includes('call-logs')
        ) {
          this.categoryId = 4;
        } else if (!this.isStaticRoute) {
          if (!url.includes('?') || !url.includes('%')) {
            // this.sendUrlToSiteMapDatabase(url);
          }
        }

        // skipping admin related routes
        if (this.categoryId !== 0 && this.categoryId !== undefined) {
          this.sendUrlToDatabase(url, this.categoryId);
        }

        if (url.includes(Constants.adminPanelRoute)) {
          this.hideButtons = true;
        } else {
          this.hideButtons = false;
        }

        if (url == '/') {
          this.spinner.show();
          let data = {
            memberId: sessionStorage.getItem('memberId')
              ? sessionStorage.getItem('memberId')
              : 0,
            token: sessionStorage.getItem('token')
              ? sessionStorage.getItem('token')
              : '',
          };
          this.dashboardService.getAllProfession(data);
        }
      }
    });

    this.popUpFlag = this.dataService.currentPopUpFlag.subscribe((res) => {
      if (res) {
        if (
          this._route.url === '/' &&
          (sessionStorage.getItem('loggedIn') == undefined ||
            sessionStorage.getItem('loggedIn') == null ||
            sessionStorage.getItem('loggedIn') == 'false')
        ) {
          setTimeout(() => {
            this._route.url === '/' ? this.showPopup() : this.closePopup();
          }, 15000);
        } else {
          this.closePopup();
        }
      }
    });
  }

  sendUrlToSiteMapDatabase(url: string): void {
    // this._sitemapService.storeSitemapToSearchDB(url);
  }

  public setTitle(newTitle: string) {
    this.title.setTitle(newTitle);
  }

  // loadJsScript(src: string, async: boolean = true, defer: boolean = true): HTMLScriptElement {
  //   const script = this.renderer.createElement('script');
  //   script.type = 'text/javascript';
  //   script.src = src;

  //   if (async) {
  //     script.async = true;
  //   }

  //   if (defer) {
  //     script.defer = true;
  //   }

  //   this.renderer.appendChild(this.el.nativeElement.ownerDocument.body, script);
  //   return script;
  // }

  updateMetaTags(): void {
    // Google Site Verification
    // this.meta.updateTag({ name: 'google-site-verification', content: '2dHvNuc8lltUtNchYvflIe7RodzafZB8Io5w0XxlI6k' });
    // // Facebook Domain Verification
    // this.meta.updateTag({ name: 'facebook-domain-verification', content: 'z501kk4ujunl2o1szx47fpds9njwkr' });
    //   // Description
    //   this.meta.updateTag({ name: 'description', content: 'Toskie helps you get your job done. Find expert services for beauty, repair, cleaning, painting, plumbing, bird netting, and more. One-stop destination for your personal, professional, and household needs. get 100% safe service from verified partners.' });
    //   // title
    //   this.meta.updateTag({ property: 'title', content: 'Connect With the Right Service Experts at Near You - Toskie.Com' });
    //   // keywords
    //   this.meta.updateTag({ name: 'keywords', content: 'local search engine, service providers, doorstep services, connect right service experts, local businesses, post your business, get your job done, customer support,' });
    // // Viewport
    // this.meta.updateTag({ name: 'viewport', content: 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no' });
    // // Apple iTunes App
    // this.meta.updateTag({ name: 'apple-itunes-app', content: 'app-id=1574937402' });
    // // Lang
    // this.meta.updateTag({ name: 'lang', content: 'en' });
    // Twitter tags
    // this.meta.updateTag({ name: 'twitter:card', content: 'summary_large_image' });
    // this.meta.updateTag({ name: 'twitter:title', content: 'Best Local Search for all your local needs.' });
    // this.meta.updateTag({ name: 'twitter:description', content: 'Toskie is the World’s First Neighbourhood Professional Search Engine App provides local search from professional, business, domestic, food, medical, residential, teaching & many more in your neighbourhood.' });
    // Open Graph tags
    // this.meta.updateTag({ property: 'og:locale', content: 'en_US' });
    // this.meta.updateTag({ property: 'og:type', content: 'website' });
    // this.meta.updateTag({ property: 'og:title', content: 'Best Local Search for all your local needs.' });
    // this.meta.updateTag({ property: 'og:description', content: 'Toskie is the World’s First Neighbourhood Professional Search Engine App provides local search from professional, business, domestic, food, medical, residential, teaching & many more in your neighborhood.' });
    // this.meta.updateTag({ property: 'og:image', content: 'https://toskie.com/assets/images/toskie.png' });
    // this.meta.updateTag({ property: 'og:site_name', content: 'TOSKIE' });
    // Charset
    // this.meta.updateTag({ charset: 'utf-8' });
    // Get the current route
    // const currentRoute = this.route.snapshot;
    // Set canonical URL based on the current route
    // const canonicalUrl = `https://${window.location.hostname}/${currentRoute.url.join('/')}`;
    // // Update or add the canonical link tag
    // this.meta.updateTag({ name: 'canonical', content: canonicalUrl });
  }

  ngOnInit() {
    window.scrollTo(0, 0);

    const firebaseConfig = {
      apiKey: 'AIzaSyCLq_1WgnuRM_ivgNoEs3PE8EsEDgkuohA',
      authDomain: 'toskie-app.firebaseapp.com',
      projectId: 'toskie-app',
      storageBucket: 'toskie-app.appspot.com',
      messagingSenderId: '123350701056',
      appId: '1:123350701056:web:8e43dc4d233e1244da34e5',
      measurementId: 'G-3Z0ZNNXTHQ',
    };

    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig);
    } else {
      firebase.app(); // if already initialized, use that one
    }
    this.getCurrentUserStatus();
    if (this.originUrl.includes('.xml')) {
      this.isHeader = false;
      this.isFooter = false;
    } else {
      this.isHeader = true;
      this.isFooter = true;
    }

    // // Load Google Tag Manager script
    // this.loadJsScript('https://www.googletagmanager.com/gtm.js?id=GTM-NK8CTG8F');

    // // Load Firebase scripts
    // this.loadJsScript('https://www.gstatic.com/firebasejs/8.0.0/firebase-app.js');
    // this.loadJsScript('https://www.gstatic.com/firebasejs/8.0.0/firebase-auth.js');

    // // Load other scripts
    // this.loadJsScript('assets/js/popper.min.js');
    // this.loadJsScript('https://cdn.jsdelivr.net/npm/@popperjs/core@2.9.2/dist/umd/popper.min.js');
    // this.loadJsScript('https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/js/bootstrap.bundle.min.js',
    //                   true, false); // defer = false
    // this.loadJsScript('assets/js/bootstrap.bundle.min.js');

    // this.loadJsScript('https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5885788742005292',
    //                   true, true);

    // // Load CSS stylesheets
    // this.loadJsScript('https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css', true, true);
    // this.loadJsScript('https://getbootstrap.com/docs/5.3/assets/css/docs.css', true, true);
    // this.loadJsScript('https://cdn.jsdelivr.net/npm/bootstrap-icons@1.9.1/font/bootstrap-icons.css', true, true);
    // this.loadJsScript('https://cdnjs.cloudflare.com/ajax/libs/prism/1.22.0/themes/prism.css', true, true);

    // // Load other async scripts
    // this.loadJsScript('https://www.google.com/recaptcha/api.js', true, true);
    // this.loadJsScript('https://accounts.google.com/gsi/client', true, true);
    // this.loadJsScript('https://apis.google.com/js/platform.js', true, true);

    if (isPlatformBrowser(this.platformId)) {
      if (this.isMobile()) {
        if (this.isIOS()) {
          this.addIOSMetaTags();
        } else if (this.isAndroid()) {
          this.addAndroidMetaTags();
        }
      }
    }
    let data = {
      memberId: sessionStorage.getItem('memberId')
        ? sessionStorage.getItem('memberId')
        : 0,
      token: sessionStorage.getItem('token')
        ? sessionStorage.getItem('token')
        : '',
    };
    // this.getFetAllProfessions();
    this.dashboardService.getAllProfession(data);
    if (sessionStorage.getItem('exactLocation')) {
      this.spinner.hide();
    }
  }

  showPopup() {
    this.popupComponent.show();
  }

  closePopup() {
    this.popupComponent.hide();
  }

  private addIOSMetaTags(): void {
    const iosMetaTag = document.createElement('meta');
    iosMetaTag.name = 'apple-itunes-app';
    iosMetaTag.content =
      'app-id=1574937402, affiliate-data=myAffiliateData, app-argument=myURL';
    document.head.appendChild(iosMetaTag);
  }

  private addAndroidMetaTags(): void {
    // Add Android specific meta tags
    const androidMetaTag1 = document.createElement('meta');
    androidMetaTag1.name = 'mobile-web-app-capable';
    androidMetaTag1.content = 'yes';
    document.head.appendChild(androidMetaTag1);

    // Create manifest.json file
    const manifest = {
      name: 'Toskie',
      short_name: 'Toskie',
      description:
        'Toskie is the best professional service provider and best platform to get required need a service. Toskie is a way to achieve your Professional Requirement.',
      start_url: '/',
      display: 'standalone',
      background_color: '#ffffff',
      theme_color: '#000000',
      icons: [
        {
          src: './images/logo2x.png',
          sizes: '192x192',
          type: 'image/png',
        },
      ],
    };

    // Add link to manifest file
    const androidMetaTag2 = document.createElement('link');
    androidMetaTag2.rel = 'manifest';
    androidMetaTag2.href = 'manifest.json';
    document.head.appendChild(androidMetaTag2);

    // Add manifest.json content
    const manifestTag = document.createElement('script');
    manifestTag.type = 'application/manifest+json';
    manifestTag.innerText = JSON.stringify(manifest);
    document.head.appendChild(manifestTag);
  }

  private isMobile(): boolean {
    const userAgent = navigator.userAgent;
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      userAgent
    );
  }

  private isIOS(): boolean {
    return /iPad|iPhone|iPod/.test(navigator.userAgent);
  }

  private isAndroid(): boolean {
    return /Android/.test(navigator.userAgent);
  }

  sendUrlToDatabase(url: string, categoryId: any): void {
    this._sitemapService.storeSitemapToDB(url, categoryId);
  }

  getCurrentUserStatus() {
    try {
      const loginStatus = sessionStorage.getItem('loggedIn');
      const memberId = sessionStorage.getItem('memberId');
      const user: any = JSON.parse(sessionStorage.getItem('user'));
      const payLoad: any = {};
      payLoad.memberId = memberId;

      if (loginStatus == 'true' && memberId != null && user != null) {
        if (user.provider == 'GOOGLE' || user.provider == 'FACEBOOK') {
          user.token = this._apiService.token;
          let profileImage = user;
          this._apiService.sendUpdate(profileImage);
        } else {
          if (
            memberId == 'undefined' ||
            memberId == '' ||
            memberId == undefined ||
            memberId == null
          ) {
            sessionStorage.clear();
          } else {
            payLoad.token = this._apiService.token;
            this._apiService.getUserProfile(payLoad).subscribe((res) => {
              if (res.status == 200) {
                try {
                  let profileImage = res['Registration'];
                  this._apiService.sendUpdate(profileImage);
                } catch {
                  (err) => {
                    var obj = {
                      ErrorMessage: err,
                    };
                    this._apiService.ExceptionLog(obj).subscribe((res) => {});
                  };
                }
              }
              if (res.status == 404) {
                this._alertService.success('logging out');
                sessionStorage.clear();
                let data = {
                  token: this._apiService.token,
                };
                this._apiService.sendUpdate(data);
                this._route.navigate(['/']).then(() => {
                  window.location.reload();
                });
              }
            });
          }
        }
      } else {
        // sessionStorage.clear();
      }
    } catch (err) {
      //console.log(err)
    }
  }

  ngOnDestroy() {
    this.popUpFlag.unsubscribe();
  }
}
