import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VendorManagmentRoutingModule } from './vendor-managment-routing.module';

@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    VendorManagmentRoutingModule
  ]
})
export class VendorManagmentModule { }
