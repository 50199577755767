<div>
    <mat-tab-group>
      <mat-tab>
        <ng-template mat-tab-label>
          <div class="custom-tab-label"  (click)="getDataFromApi('childPending')">
            <span class="overview-text" [ngClass]="{'active-tab-text': selectedTab === 'childPending'}">Overview</span>
            <img src="../../../assets/images/overview.png" class="overview-image" alt="overview" title="overview">
          </div>
        </ng-template>

        <div class="tasks_table1">
          <div class="row">
            <div class="col-md-10 search-box">
              <label style="width: 100%;"><span><input type="text" id="search"
                    class="custom-focus-input" autocomplete="off" [(ngModel)]="searchtext"
                    [placeholder]="childPlaceholder" style="height: 36px;border: none;width: 120.2%;margin-left: 2px;"
                    (input)="searchchildProfessionApproval($event)"></span></label>
            </div>
            <!-- <div class="col-md-2" (click)="addpop()" style="padding: 8px;text-align: center;">
                    <button class=" hand add"
                        onclick="document.getElementById('categoryModal').style.display='block'">+
                    </button>
                </div> -->
          </div>

        </div>
        <table  class="table table-striped">
          <thead class="tablehd-sec">
            <tr>
              <th style="width: 10%;" class="padleft-30">S NO</th>
              <th style="width: 17%;">Child Profession</th>
              <th style="width: 17%;">New Child Profession</th>
              <th style="width: 29%;">Change Description</th>
              <th style="width: 17%;">Action</th>
              <th style="width: 10%;">Expand</th>
            </tr>
          </thead>
          <tbody>
          <tbody>
            <ng-container
              *ngFor="let d of adminApprovalsubCategories | paginate: { itemsPerPage: 10, currentPage: p}; let i = index">
              <tr class="tdata">
                <td style="width: 10%;padding-left: 2%;" class="padleft-30">{{ (p - 1) * 10 + i + 1 }}</td>
                <td style="width: 17%;">
                  <p>{{d.childProfessionName}}</p>
                </td>
                <td style="width: 17%;">
                  <p>{{d.childProfessionNameNew}}</p>
                </td>
                <td style="width: 29%;">
                  <ng-container *ngIf="d.isDeleted == 'Y'">
                    <p>Profession <b>{{d.childProfessionName}}</b> has been deleted</p>
                  </ng-container>
                  <ng-container *ngIf="d.isDeleted == 'N' && !d.childProfessionNameNew">
                    <p>Profession <b>{{d.childProfessionName}}</b> has been Added</p>
                  </ng-container>
                  <ng-container *ngIf="d.isDeleted == 'N' && d.childProfessionNameNew">
                    <p>Profession Has been Updated from this <b>{{d.childProfessionName}}</b> to
                      <b>{{d.childProfessionNameNew}}</b>
                    </p>
                  </ng-container>
                </td>
                <td style="width: 17%;">
                  <div class="custom-select-container">
                    <select class="custom-select" (change)="dropdownAction($event.target.value, d,'child')">
                      <option value="" selected>Select </option>
                      <option value="approved">Approved</option>
                      <option value="reject">Rejected</option>
                    </select>
                  </div>
                </td>
                <td style="width: 10%;">
                  <!-- <button class="togglemeta1" (click)="toggleMetaInfo(d)">
                            <span style="color: black;">{{ d.showMetaInfo ? 'Close' : 'Open' }}</span>
                        </button> -->
                  <button (click)="toggleIcon(d)" class="icon-toggle" [ngClass]="{'rotate-icon': d.showMetaInfo}">
                    <i class="fa fa-chevron-down" style="color: black;"></i>
                  </button>
                </td>

              </tr>
              <tr *ngIf="d.showMetaInfo">
                <td colspan="6">
                  <div class="meta-info-container">
                    <p><strong>Child Profession:</strong> {{d.childProfessionName}}</p>
                    <p *ngIf="d.childProfessionNameNew !=''"><strong>Updated Child Profession:</strong>
                      {{d.childProfessionNameNew}}</p>
                    <p><strong>Change Description:</strong>
                      <ng-container *ngIf="d.isDeleted == 'Y'">
                        <span>Profession <strong>{{d.childProfessionName}}</strong> has been deleted</span>
                      </ng-container>
                      <ng-container *ngIf="d.isDeleted == 'N' && !d.childProfessionNameNew">
                        <span>Profession <strong>{{d.childProfessionName}}</strong> has been Added</span>
                      </ng-container>
                      <ng-container *ngIf="d.isDeleted == 'N' && d.childProfessionNameNew">
                        <span>Profession Has been Updated from this <strong>{{d.childProfessionName}}</strong> to
                          <strong>{{d.childProfessionNameNew}}</strong></span>
                      </ng-container>
                    </p>
                    <p *ngIf="d.title !=''"><strong>Meta Title:</strong> {{d.title}}</p>
                    <p *ngIf="d.keywords !=''"><strong>Meta Keywords:</strong> {{d.keywords}}</p>
                    <p *ngIf="d.description !=''"><strong>Meta Description:</strong> {{d.description}}</p>
                  </div>
                  <div class="action-button">
                    <button class="approve-button" (click)="dropdownAction('approved',d,'child')">Approved</button>
                    <button class="reject-button" (click)="dropdownAction('reject',d,'child')">Rejected</button>
                    <button class="cancel-button" (click)="cancelFunction(d)">Cancel</button>
                  </div>
                </td>
              </tr>


            </ng-container>
          </tbody>

        </table>

        <div class="row">
          <div class="col">
            <div class="d-flex justify-content-center" *ngIf="adminApprovalsubCategories.length!=0">
              <pagination-controls (pageChange)="pageChanged($event)" (pageBoundsCorrection)="pageChanged($event)">
              </pagination-controls>
            </div>
          </div>
        </div>


      </mat-tab>
      <mat-tab [ngClass]="'custom-approved-tab'">
        <ng-template mat-tab-label>
          <div class="custom-tab-label"  (click)="getDataFromApi('childApproved')">
              <div class="Ellipse-5">
              <div class="union-10"></div>
            </div>
            <span class="approved-text" [ngClass]="{'active-tab-text': selectedTab === 'childApproved'}">Approved</span>
          </div>
        </ng-template>

        <div class="tasks_table1">
          <div class="row">
            <div class="col-md-10 search-box">
              <label style="width: 100%;">
             <span>
                  <input type="text" id="searchApproved" class="custom-focus-input" autocomplete="off"
                    [(ngModel)]="searchtextApproved" [placeholder]="childPlaceholder"
                    style="height: 36px;border: none;width: 120.2%;margin-left: 2px;"
                    (input)="searchsubApprovedMainProfession($event)">
                </span>
              </label>
            </div>
          </div>
        </div>

        <table  class="table table-striped">
          <thead class="tablehd-sec">
            <tr>
              <th style="width: 11%;" class="padleft-30">S NO</th>
              <th style="width: 19%;">Child Profession</th>
              <th style="width: 19%;">New Child Profession</th>
              <th style="width: 29%;">Change Description</th>
              <th style="width: 11%;">Status</th>
              <th style="width: 11%;">Expand</th>
            </tr>
          </thead>
          <tbody>
            <ng-container
              *ngFor="let d of adminApprovalsubCategories | paginate: { itemsPerPage: 10, currentPage: p}; let i = index">
              <tr class="tdata">
                <td style="width: 11%;padding-left: 2%;" class="padleft-30">{{ (p - 1) * 10 + i + 1 }}</td>
                <td style="width: 19%;">
                  <p>{{d.childProfessionName}}</p>
                </td>
                <td style="width: 19%;">
                  <p>{{d.childProfessionNameNew}}</p>
                </td>
                <td style="width: 29%;">
                  <ng-container *ngIf="d.isDeleted == 'Y'">
                    <p>Profession <b>{{d.childProfessionName}}</b> has been deleted</p>
                  </ng-container>
                  <ng-container *ngIf="d.isDeleted == 'N' && !d.mainProfessionNameNew">
                    <p>Profession <b>{{d.childProfessionName}}</b> has been Added</p>
                  </ng-container>
                  <ng-container *ngIf="d.isDeleted == 'N' && d.mainProfessionNameNew">
                    <p>Profession Has been Updated from this <b>{{d.childProfessionName}}</b> to
                      <b>{{d.childProfessionNameNew}}</b>
                    </p>
                  </ng-container>
                </td>
                <!-- <td style="width: 20%;">
                  <div class="custom-select-container">
                    <select class="custom-select" (change)="dropdownAction($event.target.value, d,'child')">
                      <option value="" selected>Select </option>
                      <option value="approved">Approved</option>
                      <option value="reject">Rejected</option>
                    </select>
                  </div>
                </td> -->
                <td style="width: 11%; text-align: center;">
                  <span class="custom-checkmark">&#10003;</span> 
                </td>
                <td style="width: 11%;">
                  <!-- <button class="togglemeta1" (click)="toggleMetaInfo(d)">
                          <span style="color: black;">{{ d.showMetaInfo ? 'Close' : 'Open' }}</span>
                      </button> -->
                  <button (click)="toggleIcon(d)" class="icon-toggle" [ngClass]="{'rotate-icon': d.showMetaInfo}">
                    <i class="fa fa-chevron-down" style="color: black;"></i>
                  </button>
                </td>

              </tr>
              <tr *ngIf="d.showMetaInfo">
                <td colspan="6">
                  <div class="meta-info-container">
                    <p><strong>Child Profession:</strong> {{d.childProfessionName}}</p>
                    <p *ngIf="d.childProfessionNameNew !=''"><strong>Updated Child Profession:</strong>
                      {{d.childProfessionNameNew}}</p>
                    <p><strong>Change Description:</strong>
                      <ng-container *ngIf="d.isDeleted == 'Y'">
                        <span>Profession <strong>{{d.childProfessionName}}</strong> has been deleted</span>
                      </ng-container>
                      <ng-container *ngIf="d.isDeleted == 'N' && !d.childProfessionNameNew">
                        <span>Profession <strong>{{d.childProfessionName}}</strong> has been Added</span>
                      </ng-container>
                      <ng-container *ngIf="d.isDeleted == 'N' && d.childProfessionNameNew">
                        <span>Profession Has been Updated from this <strong>{{d.childProfessionName}}</strong> to
                          <strong>{{d.childProfessionNameNew}}</strong></span>
                      </ng-container>
                    </p>
                    <p *ngIf="d.title !=''"><strong>Meta Title:</strong> {{d.title}}</p>
                    <p *ngIf="d.keywords !=''"><strong>Meta Keywords:</strong> {{d.keywords}}</p>
                    <p *ngIf="d.description !=''"><strong>Meta Description:</strong> {{d.description}}</p>
                  </div>
                  <!-- <div class="action-button">
                    <button class="approve-button" (click)="dropdownAction('approved',d,'child')">Approved</button>
                    <button class="reject-button" (click)="dropdownAction('reject',d,'child')">Rejected</button>
                    <button class="cancel-button" (click)="cancelFunction(d)">Cancel</button>
                  </div> -->
                </td>
              </tr>


            </ng-container>
          </tbody>
        </table>

        <div class="row">
          <div class="col">
            <div class="d-flex justify-content-center">
              <pagination-controls (pageChange)="pageChanged($event)" (pageBoundsCorrection)="pageChanged($event)">
              </pagination-controls>
            </div>
          </div>
        </div>
      </mat-tab>

      <mat-tab [ngClass]="'custom-rejected-tab'">
        <ng-template mat-tab-label>
          <div class="custom-tab-label" (click)="getDataFromApi('childRejected')">
            <div class="Ellipse-6">
              <div class="union-20"></div>
            </div>
            <span class="rejected-text" [ngClass]="{'active-tab-text': selectedTab === 'childRejected'}">Rejected</span>
          </div>
        </ng-template>

         <div class="tasks_table1">
          <div class="row">
            <div class="col-md-10 search-box">
              <label style="width: 100%;">
               <span>
                  <input type="text" id="searchRejected" class="custom-focus-input" autocomplete="off"
                    [(ngModel)]="searchtextRejected" [placeholder]="childPlaceholder"
                    style="height: 36px;border: none;width: 120.2%;margin-left: 2px;"
                    (input)="searchsubRejectedMainProfession($event)">
                </span>
              </label>
            </div>
          </div>
        </div>

        <table class="table table-striped">
          <thead class="tablehd-sec">
            <tr>
              <th style="width: 11%;" class="padleft-30">S NO</th>
              <th style="width: 19%;">Child Profession</th>
              <th style="width: 19%;">New Child Profession</th>
              <th style="width: 29%;">Change Description</th>
              <th style="width: 11%;">Status</th>
              <th style="width: 11%;">Expand</th>
            </tr>
          </thead>
          <tbody>
            <ng-container
              *ngFor="let d of adminApprovalsubCategories | paginate: { itemsPerPage: 10, currentPage: p}; let i = index">
              <tr class="tdata">
                <td style="width: 11%;padding-left: 2%;" class="padleft-30">{{ (p - 1) * 10 + i + 1 }}</td>
                <td style="width: 19%;">
                  <p>{{d.childProfessionName}}</p>
                </td>
                <td style="width: 19%;">
                  <p>{{d.childProfessionNameNew}}</p>
                </td>
                <td style="width: 29%;">
                  <ng-container *ngIf="d.isDeleted == 'Y'">
                    <p>Profession <b>{{d.childProfessionName}}</b> has been deleted</p>
                  </ng-container>
                  <ng-container *ngIf="d.isDeleted == 'N' && !d.childProfessionNameNew">
                    <p>Profession <b>{{d.childProfessionName}}</b> has been Added</p>
                  </ng-container>
                  <ng-container *ngIf="d.isDeleted == 'N' && d.childProfessionNameNew">
                    <p>Profession Has been Updated from this <b>{{d.childProfessionName}}</b> to
                      <b>{{d.childProfessionNameNew}}</b>
                    </p>
                  </ng-container>
                </td>
                <!-- <td style="width: 17%;">
                  <div class="custom-select-container">
                    <select class="custom-select" (change)="dropdownAction($event.target.value, d,'child')">
                      <option value="selected" selected>Select </option>
                      <option value="approved">Approved</option>
                      <option value="reject">Rejected</option>
                    </select>
                  </div>
                </td> -->
                <td style="width: 11%; text-align: center;">
                  <span class="custom-crossmark">&#10060;</span> 
                </td>
                <td style="width: 11%;">
                  <!-- <button class="togglemeta1" (click)="toggleMetaInfo(d)">
                          <span style="color: black;">{{ d.showMetaInfo ? 'Close' : 'Open' }}</span>
                      </button> -->
                  <button (click)="toggleIcon(d)" class="icon-toggle" [ngClass]="{'rotate-icon': d.showMetaInfo}">
                    <i class="fa fa-chevron-down" style="color: black;"></i>
                  </button>
                </td>

              </tr>
              <tr *ngIf="d.showMetaInfo">
                <td colspan="6">
                  <div class="meta-info-container">
                    <p><strong>Child Profession:</strong> {{d.childProfessionName}}</p>
                    <p *ngIf="d.childProfessionNameNew !=''"><strong>Updated Child Profession:</strong>
                      {{d.childProfessionNameNew}}</p>
                    <p><strong>Change Description:</strong>
                      <ng-container *ngIf="d.isDeleted == 'Y'">
                        <span>Profession <strong>{{d.childProfessionName}}</strong> has been deleted</span>
                      </ng-container>
                      <ng-container *ngIf="d.isDeleted == 'N' && !d.childProfessionNameNew">
                        <span>Profession <strong>{{d.childProfessionName}}</strong> has been Added</span>
                      </ng-container>
                      <ng-container *ngIf="d.isDeleted == 'N' && d.childProfessionNameNew">
                        <span>Profession Has been Updated from this <strong>{{d.childProfessionName}}</strong> to
                          <strong>{{d.childProfessionNameNew}}</strong></span>
                      </ng-container>
                    </p>
                    <p *ngIf="d.title !=''"><strong>Meta Title:</strong> {{d.title}}</p>
                    <p *ngIf="d.keywords !=''"><strong>Meta Keywords:</strong> {{d.keywords}}</p>
                    <p *ngIf="d.description !=''"><strong>Meta Description:</strong> {{d.description}}</p>
                  </div>
                  <!-- <div class="action-button">
                    <button class="approve-button" (click)="dropdownAction('approved',d,'child')">Approved</button>
                    <button class="reject-button" (click)="dropdownAction('reject',d,'child')">Rejected</button>
                    <button class="cancel-button" (click)="cancelFunction(d)">Cancel</button>
                  </div> -->
                </td>
              </tr>


            </ng-container>
          </tbody>
        </table>

        <div class="row">
          <div class="col">
            <div class="d-flex justify-content-center">
              <pagination-controls (pageChange)="pageChanged($event)" (pageBoundsCorrection)="pageChanged($event)">
              </pagination-controls>
            </div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
  <div id="deleteModal" class="modal "
  [ngStyle]="{'display': showDeleteModalchild ? 'block' : 'none', 'overflow': 'hidden' }">
  <form class="modal-content-1" action="/action_page.php" style="width: 35%;">
    <div class="container">
      <div class="modal-header" style="padding-top: 0;">
        <h5 class="modal-title" id="exampleModalLabel" style="margin-left: auto;">Confirm</h5>
        <button type="button" id="dismissModal" (click)="dismissModal()" class="btn-close" data-bs-dismiss="modal"
          aria-label="Close"></button>
      </div>
      <div style="padding-top: 15px; padding-bottom: 15px; text-align: center;">
        <ng-container *ngIf="currentData">
          <ng-container *ngIf="currentData.isDeleted == 'Y'">
            <p>Profession <b>{{currentData.childProfessionName}}</b> has been deleted</p>
            <p *ngIf="currentData.title != ''">
              Meta Title <strong>{{currentData.title}}</strong> has been deleted
            </p>
            <p *ngIf="currentData.keywords != ''">
              Meta Title <strong>{{currentData.keywords}}</strong> has been deleted
            </p>
            <p *ngIf="currentData.description != ''">
              Meta Title <strong>{{currentData.description}}</strong> has been deleted
            </p>
          </ng-container>
          <ng-container *ngIf="currentData.isDeleted == 'N' && !currentData.mainProfessionNameNew">
            <p>Profession <b>{{currentData.childProfessionName}}</b> has been Added</p>
          </ng-container>
          <ng-container *ngIf="currentData.isDeleted == 'N' && currentData.mainProfessionNameNew">
            <p>Profession Has been Updated from <b>{{currentData.childProfessionName}}</b> to
              <b>{{currentData.childProfessionNameNew}}</b>
            </p>
          </ng-container>
          <ng-container *ngIf="currentData.isDeleted == 'N' && !currentData.mainProfessionNameNew">
            <p *ngIf="currentData.title != ''">
              Meta Title <strong>{{currentData.title}}</strong> has been added
            </p>
          </ng-container>
          <ng-container *ngIf="currentData.isDeleted == 'N' && !currentData.mainProfessionNameNew">
            <p *ngIf="currentData.keywords != ''">
              Meta Title <strong>{{currentData.keywords}}</strong> has been added
            </p>
          </ng-container>
          <ng-container *ngIf="currentData.isDeleted == 'N' && !currentData.mainProfessionNameNew">
            <p *ngIf="currentData.description != ''">
              Meta Title <strong>{{currentData.description}}</strong> has been added
            </p>
          </ng-container>
        </ng-container>
      </div>

      <div class="modal-footer" style="padding-bottom: 0;">
        <button type="button" (click)="dismissModal()" class="btn btn-danger footer-button ml-auto">NO </button>
        <button type="button" class="btn btn-primary footer-button mr-auto"
          (click)="handleApprovalOrRejectionChild()">YES</button>
      </div>
    </div>
  </form>
</div>