<div class="whole-section">
    <nav aria-label="breadcrumb" class="mlft-6">
    <app-bread-crumb [breadTitle]="userGuideTitle" [breadpath1]="adminPanel" [breadpath2]="pushNotification" [breadpath3]="userGuideNav"
        [breadroute1]="adminPanelRoute"></app-bread-crumb>
</nav>  
<div class="setup">
<div *ngIf="section=='message'" class="scrollable" style="overflow-x:auto; flex:1;">
        <div class="title1"> How to add message?</div>
        <div class="section-title6">Instructions:</div>
        <div class="image-space">
            <img src="../../../assets/gif/Message (1).perf.gif" alt="validation-gif" title="validation-gif" class="mb-3 common-image img-03">
        </div>
        <ol class="file-info-title5">

            <li class="title13">
                Your message can contain a maximum of <strong>1500</strong> characters.
            </li>
            <li class="title14">
                Avoid using formatting styles like <strong> bold, color changes, or large font sizes,</strong> as they
                can increase the length of your message beyond 1500 characters.
            </li>
            <div class="message-title">Editing Tools:</div>
            <li class="title15">
                Modify your message using the provided <strong>editor tools.</strong>(Pointed by blue arrow)
            </li>
            <li class="title16">
                Utilize the <strong>editor tools.</strong> to adjust font styles, add numbering or bullet points, and
                include HTML content.
            </li>
        </ol>
        <div class="html-title">Enclose paragraphs in HTML tags:</div>
         <ol class="html-title5">

            <li>
                When including paragraphs in your message, enclose them within <strong>HTML tags,</strong> it will
                ensure proper formatting within the message.
            </li>
            <li>
                The icon displayed above is for HTML formatting purposes, apply html tags to your message content for
                enhanced formatting options.(Pointed by red arrow)
            </li>
            <li>
                Select the desired message content that requires HTML formatting, Subsequently, click on the HTML icon
                to activate the formatting functionality.
            </li>
            <li>
                Utilize the <strong>editor tools.</strong> to adjust font styles, add numbering or bullet points, and
                include HTML content.
            </li>
            <li>
                Upon application, HTML tags will envelop the selected paragraph entirely.
            </li>
            <li>
                This action serves to minimize unnecessary spaces within the message, while also providing a character
                count display.
            </li>
        </ol>
        <div class="hyperlink-title">How to make a URL hyperlink ?</div>
        <ol class="hyperlink-title1">

             <li>
                To make URLs clickable, just select the URL text and click the designated button present in editing tools.(marked with a red arrow)
            </li> 
             <li>
               After clicking on button, enter the URL into the hyperlink box, then click on the Save button.
            </li>
            <li>
                Once you click the Save button, the URL will become hyperlinked.
            </li>
            <li>
                Once you click Save button, the URL will become a clickable hyperlink, redirecting you to the specified page.
            </li>
            <li>
                To make any text clickable, select the entire phrase and change it into a hyperlink using the same process.
            </li>
            </ol>
            <div class="tele-title">Click on Mobile/Telephone numbers, it should be hyperlinked with text.(e.g.,number:5555555555): </div>
            <ol class="tele-title1">

                <li>
                    Choose the word "Call" that you want to make clickable to initiate a call.
               </li> 
                <li>
                    First, select the word, and then click on the designated hyperlink button. Now, enter the mobile number.
               </li>
               <li>
                Before entering the mobile number into the link, add "Tel:" before it (e.g.,number:5555555555). Then, proceed to click the Save button.
               </li>
               <li>
                After saving, clicking on the word "Call" will initiate a call within the app.
               </li>
          </ol>  
    </div>
    <div *ngIf="section=='receiver type'" class="scrollable" style="overflow-y:auto; flex:1;">
        <div class="title1"> How to select a receiver type?</div>
        <div class="sub-title">Instructions for selecting the receiver type:</div>
        <ul class="receiver-t-info">
            <li>A sender can choose from four receiver types: <strong>clients</strong>, <strong>skills</strong>, <strong>business/organization</strong>, and <strong>location</strong>.</li>
            <li>Each option allows for sending notifications to specific recipients tailored to their needs.</li>
            <li>"By default, the receiver type is set to 'client.'"</li>
        </ul>
        <div class="receiver-title3">Guidelines for selecting a receiver type:</div>
        <div class="image-space">
            <img src="/assets/images/receiver-type.png" alt="Your Image" class="mb-3 common-image img-06">
        </div>
        <div class="image-space">
            <img src="/assets/images/receiver-type1.png" alt="Your Image" class="mb-3 common-image img-07">
        </div>
        <ol class="receiver-t-title1">
            <li>
                <strong>Client:</strong>
                <p>If sender select <strong>'clients'</strong> as the receiver type, the sender can choose to send notifications to <strong>all clients, multiple clients, or a single client</strong> associated with his platform. This allows for targeting specific recipients based on the sender's preferences.</p>
            </li>
            <li>
                <strong>Skills:</strong>
                <p>By selecting <strong>'skills'</strong> as the receiver type, the sender have the flexibility to send notifications to all users <strong>with certain skills, multiple users with various skills, or a single user with a specific skillset</strong>. This flexibility enables targeted communication based on specific skillsets.</p>
            </li>
            <li>
                <strong>Business/Organization:</strong>
                <p>Opting for <strong>'Business or organization'</strong> as the receiver type, it enables sender to send notifications to <strong>all businesses or organizations, multiple businesses/organizations, or a single business/organization</strong>strong on your platform. This functionality allows for targeted messaging tailored to different organizational entities.</p>
            </li>
            <div class="image-space">
                <img src="/assets/images/receiver-type2.png" alt="Your Image" class="mb-3 common-image img-013">
            </div>
            <div class="image-space">
                <img src="/assets/images/receiver-type3.png" alt="Your Image" class="mb-3 common-image img-014">
            </div>
           
            <li class="receiver-t-title2">
                <strong>Location:</strong>
               <p>If you select  <strong>'Location'</strong> as the receiver type, sender can target recipients based on geographic parameters such as <strong>country, state, city, and zip code</strong>. This precision targeting ensures that notifications reach specific regions, facilitating localized communication.</p>
            </li>
        </ol> 
    </div>
    <div *ngIf="section=='receiver'" class="scrollable" style="overflow-y:auto; flex:1;">
        <div class="title1">How to select a receiver?</div>
        <div class="sub-title">Instructions for selecting a <strong>receiver</strong>:</div>
        <ul class="receiver-t-info">
            <li>Before choosing a receiver, clearly identify the specific needs, preferences, or requirements of the communication or task.</li>
            <li>Consider factors such as the audience (all clients, multiple clients, or one client), required skills, business/organization context, and communication location.</li>
        </ul>
        <div class="receiver-title3">Guidelines for selecting a receiver:</div>
        <div class="image-space">
            <img src="/assets/images/Receiver.png" alt="clients Image" class="mb-3 common-image img-06">
        </div>
         <div class="image-space">
            <img src="/assets/images/Receiver-skills.png" alt="skills Image" class="mb-3 common-image img-07i">
        </div>
        <div class="image-space">
            <img src="/assets/images/Receiver-business.png" alt="business Image" class="mb-3 common-image img-07h">
        </div>
        <ol class="receiver-t-title1i">
            <li>
                <strong>Client:</strong>
                <p>If sender select <strong>'clients'</strong> as the receiver, the sender can choose to send notifications to <strong>all clients, multiple clients, or a single client</strong> associated with his platform.</p>
            </li>
            <li>
                <strong>Skills:</strong>
                <p>By selecting <strong>'skills'</strong> as the receiver, the sender have the flexibility to send notifications to all users <strong>with all skills, multiple users with multiple skills, or a single skill</strong>.</p>
            </li>
            <li>
                <strong>Business/Organization:</strong>
                <p>By selecting<strong>'Business or organization'</strong> as the receiver, it enables sender to send notifications to <strong>all businesses or organizations, multiple businesses/organizations, or a single business/organization</strong> associated with his platform.</p>
            </li>
            <li>
                <div class="image-space">
                    <img src="/assets/images/receiver-location.png" alt="Your Image" class="mb-3 common-image img-013i">
                </div>
                <strong>Location:</strong>
               <p>If sender select  <strong>'Location'</strong> as the receiver, sender can target recipients based on geographic parameters such as <strong>country, state, city, and zip code</strong>.</p>
            </li>
        </ol>
    </div>
    <div *ngIf="section=='expiry date'" class="scrollable" style="overflow-y:auto; flex:1;">
        <div class="title1">How to add an expiry date?</div>
        <div class="sub-title">Instructions for adding an <strong>expiry date</strong> to notifications:</div>
        <ul class="receiver-t-info">
            <li>Adding an expiry date ensures that notifications remain relevant and timely.</li>
            <li>It helps in maintaining communication relevance by automatically removing outdated notifications from circulation.</li>
        </ul>
        <div class="expiry-title">Steps to add an expiry date:</div>
        <div class="image-space">
            <img src="/assets/images/expiry-date.png" alt="Your Image" class="mb-3 common-image img-015">
        </div>
        <ol class="expiry-title1">
            <li>Start by clicking on the input date field to choose the expiry date for the notification.</li>
            <li>Check that the selected date is not in the past. The option to select <strong>past dates</strong> is <strong>disabled</strong>.</li>
            <li>Select a date from the calendar that is either present or in the future. Only these dates are available for selection.</li>
        </ol>
        
        <div class="expiry-title4">Tips:</div>
        <ul class="expiry-title5">
            <li> Regularly review and update expiry dates to keep notifications relevant.</li>
            <li>change expiry dates based on the urgency and importance of the notification for your audience.</li>
        </ul>
    </div>
    
    <div *ngIf="section=='title'" class="scrollable" style="overflow-y:auto; flex:1;">
        <div class="title1"> How to add title?</div>
        <div class="sub-title">Instructions for adding a <strong>title</strong> to notifications:</div>
        <ol class="file-info-title5">

            <li class="title10">
                A <strong>title</strong> is <strong>mandatory</strong> for all notifications.Ensure to provide a
                relevant title to effectively convey the purpose of the notification.
            </li>
            <div class="image-space">
                <img src="../../../assets/gif/title-validations.gif" alt="validation-gif" title="validation-gif"
                    class="mb-3 common-image img-012">
            </div>
            <li class="title11">
                Title should not exceed <strong>250 characters</strong> to ensure concise and effective communication.
            </li>
            <li class="title12">
                Do not include <strong>HTML tags</strong> in the title as they are not allowed and may interfere with
                the display of the notification.
            </li>
        </ol>
        <div class="tips-title">Tips:</div>
        <ul>
            <li class="tips-title1"> Avoid using special characters, symbols, or emojis in the title as they may not
                display correctly on all devices and platforms.</li>
            <li class="tips-title2">Titles may need to be updated over time to reflect changes in content or context.
            </li>
        </ul>
    </div>


    <div *ngIf="section=='upload image'" class="scrollable" style="overflow-y:auto; flex:1;">
        <div class="title1">How to add an image?</div>

        <div class="section-title">Instructions:</div>
        <ul class="file-info">
            <li>Accepted file types: JPEG (or JPG), PNG.</li>
            <li>Image size should be between 10KB to 1MB.</li>
        </ul>

        <div class="section-title2">Guidelines for Uploading:</div>
        <div class="image-space">
            <img src="../../../assets/gif/upload_image_run.gif" alt="validation-gif" title="validation-gif" class="mb-3 common-image img-04">
        </div>
        <ol class="file-info1">

            <li class="file-info-sub">
                <strong>Upload File:</strong> Click on the "Upload File" button to select the image you want to
                upload.(Marked by blue arrow)
            </li>
            <li class="file-info-sub1">
                <strong>Check File Type:</strong> Ensure that the file type is either JPEG (or JPG) or PNG.
            </li>
            <li class="file-info-sub2">
                <strong>Verify Size:</strong> Make sure the size of the image is between 10KB to 1MB.
            </li>
            <li class="file-info-sub3">
                <strong>Upload:</strong> Once the file is selected and meets the requirements, click on the "Upload"
                button.
            </li>
        </ol>
        <div class="section-title3">Tips:</div>
        <ul>
            <li class="file-info-tips">Optimize your image size before uploading to meet the size requirements.</li>
            <li class="file-info-tips1">If your image is too large, consider compressing it using an image editing tool.
            </li>
        </ul>
    </div>

    <div *ngIf="section=='upload video'" class="scrollable" style="overflow-y:auto; flex:1;">
        <div class="title1">How to upload a video URL?</div>

        <div class="section-title">Instructions:</div>
        <ul class="file-info">
            <li>Accepted file types: youtubeURL, facebookURL, mp4, mov, avi, mkv, webm, ogg.</li>
        </ul>

        <div class="section-title4">Guidelines for Uploading:</div>
        <div class="image-space">
            <img src="../../../assets/gif/Add_Video_URL.gif" alt="validation-gif" title="validation-gif" class="mb-3 common-image img-05">
        </div>
        <ol class="file-info1">
            <li>
                <strong>Enter URL:</strong> Paste the video URL in the provided field.
            </li>
            <li>
                <strong>Check URL Format:</strong> Ensure that the URL is in the correct format, such as
                (https://www.youtube.com/watch?v=-WF7nz85okM).
            </li>
            <li >
                <strong>Verify:</strong> Ensure that the provided URL points to a valid video source.
            </li>
            <li>
                <strong>Preview:</strong> Click on the "Preview" button to check the video after entering the
                URL.(Marked by blue arrow)
            </li>
        </ol>

        <div class="section-title5">Tips:</div>
        <ul>
            <li class="tips-info7">Ensure that the video URL is accessible and publicly available.</li>
            <li class="tips-info8">If the video is not playing, double-check the URL and try again.</li>
        </ul>
    </div>


    <div class="help-section" style="width: 30%; height: 800px; border: 1px solid white;">
        <div class="help-title">Help?</div>
        <h1 class="header1" (click)="changeSection('title')"><i class="fa-solid fa-newspaper"></i> How to add title?</h1>
        <h2 class="header2" (click)="changeSection('receiver type')"><i class="fa-solid fa-newspaper"></i> How to select a receiver type?</h2>
        <h3 class="header3" (click)="changeSection('receiver')"><i class="fa-solid fa-newspaper"></i> How to select a receiver? </h3>
        <h4 class="header4" (click)="changeSection('expiry date')"><i class="fa-solid fa-newspaper"></i> How to add an expiry date?</h4>
        <h5 class="header5" (click)="changeSection('upload image')"><i class="fa-solid fa-newspaper"></i> How to add an image?</h5>
        <h6 class="header6" (click)="changeSection('upload video')"><i class="fa-solid fa-newspaper"></i> How to add a video URL?</h6>
        <div class="header7" (click)="changeSection('message')"><i class="fa-solid fa-newspaper"></i> How to add message?
        </div>
    </div>
</div>