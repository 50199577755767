<div class="tasks_table1 ">
    <div class="row">
        <div class="col-md-10 search-box" ngi>
            <label style="width: 100%;"><span><input type="text" id="search" class="custom-focus-input"
                autocomplete="off" [placeholder]="placeholderText"
                 style="height: 37px;border: none;width: 115.2%; margin-left: 14px;"
                (input)="searchMainCategory($event)"></span></label>
        </div>
        <div class="col-md-2" (click)="addpop()" style="padding: 8px;text-align: center;">
            <button class=" hand add"
                onclick="document.getElementById('categoryModal').style.display='block'">+
            </button>
        </div>
    </div>

</div>
<table class="table table-striped">
<thead class="tablehd-sec">
    <tr>
        <th style="width: 10%;">Sr. No</th>
        <th style="width: 40%;">Category Name</th>
        <th style="width: 50%;">Action</th>
    </tr>
</thead>
<tbody>
    <tr *ngFor="let d of allNearByServices| paginate: { itemsPerPage: 10, currentPage: p};let i = index"
        class="tdata">
        <td style="width: 10%;" class="padleft-30">
          {{i+1}}
        </td>
        <td style="width: 40%;">
          {{d.nearByServiceCategory}}
        </td>
        <td style="width: 50%;">
            <i (click)="updatepop(d)" class="fa fa-pencil thclr bl hand"
                style="margin-right: 10px;"
                onclick="document.getElementById('categoryModal').style.display='block'"></i>
            <i class="fa fa-trash-o hand dangr" style="font-size: 20px; margin-right: 10px;"
               (click)="selectedService(d)"
               onclick="document.getElementById('deleteModal').style.display='block'"></i>
        </td>
    </tr>
</tbody>
</table>
<div class="row">
<div class="col">
    <div class="d-flex justify-content-center" *ngIf="allNearByServices.length!=0">
        <pagination-controls (pageChange)="pageChanged($event)"
            (pageBoundsCorrection)="pageChanged($event)">
        </pagination-controls>
    </div>
</div>
</div>
<div class="modal" id="categoryModal" style="overflow: hidden;padding-top: 50px;">
    <div class="modal-content" style="width: 30%;">
        <div class="modal-header" style="padding-top: 0;">
            <h4 class="modal-title text-center" id="myModalLabel" *ngIf="modal == 'addModal'">Near By Category</h4>
            <h4 class="modal-title text-center" id="myModalLabel" *ngIf="modal == 'updateModal'">Update Near By Category</h4>
            <span onclick="document.getElementById('categoryModal').style.display='none'" class="close hand"
                title="Close Modal">×</span>
        </div>
        <div class="modal-body" style="padding-bottom: 0;">

            <div class="row" style="margin-bottom:15px;">
                <label style="padding: 0;">Near By Category<span style="color: red;">*</span></label>
                <input *ngIf="modal == 'updateModal'" type="text" class="form-control" [(ngModel)]="newServiceCategory" placeholder="Update near by category">
                <input *ngIf="modal == 'addModal'" type="text" class="form-control" [(ngModel)]="newServiceCategory" placeholder="Add near by category">
            </div>
            <div class="row mrt-20" style="display: flex;">
                <button class="save hand" (click)="addNearByService()" *ngIf="modal == 'addModal'"
                onclick="document.getElementById('categoryModal').style.display='none'">Save</button>
                <button class="save hand" (click)="updateNearByService()" *ngIf="modal == 'updateModal'"
                onclick="document.getElementById('categoryModal').style.display='none'">Update</button>
                <button class="save hand" style="background-color: #d9534f;" onclick="document.getElementById('categoryModal').style.display='none'">Cancel</button>
            </div>

        </div>

    </div>

</div>
<div id="deleteModal" class="modal" style="overflow: hidden;" >

    <form class="modal-content" style="width: 35%;">
        <div class="container">
            <div class="modal-header" style="padding-top: 0;">
                <h5 class="modal-title" id="exampleModalLabel" style="margin-left: auto;">Confirm</h5>
                <button type="button" id="dismissModal"
                    onclick="document.getElementById('deleteModal').style.display='none'" class="btn-close"
                    data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div style="padding-top: 15px;
            padding-bottom: 15px; text-align: center;">
                <p>Are you sure, you want to delete </p>
                <p> <b>{{selectedServiceName}}</b> ?</p>
            </div>
            <div class="modal-footer" style="padding-bottom: 0;">
                <button type="button" onclick="document.getElementById('deleteModal').style.display='none'"
                    class="btn btn-danger footer-button ml-auto" data-bs-dismiss="modal"
                    style="width: 18%;margin-left: auto;">Cancel</button>
                <button type="button" class="btn btn-primary footer-button mr-auto" (click)="deleteNearByService(selectedCategoryId)"
                    style="width: 18%;margin-right: auto;" onclick="document.getElementById('deleteModal').style.display='none'">Delete</button>
            </div>
        </div>
    </form>
</div>