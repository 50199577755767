import { Component } from '@angular/core';
import { Constants,navConstants } from 'src/app/shared/constants/constant';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.css']
})
export class SupportComponent {
  adminPanel: string = Constants.adminPanel;
  pushNotification: string = navConstants.pushNotification;
  userGuideNav: string = Constants.userGuide;
  adminPanelRoute: string = Constants.adminPanelRoute;
  userGuideTitle: string = Constants.userGuideTitle;

section='title'
changeSection(section: string) {
  this.section = section;
}

}


