<div (click)="closeRoleDropdown($event)">

    <div class="tasks_table1 ">
        <div class="row">
            <div class="col-md-3 search-box">
                <div class="dropdown">
                    <ng-select [items]="lstRoles" bindLabel="roleName" placeholder="Select Role"
                        #selectBox (change)="changeRole($event)">
                    </ng-select>
                </div>
            </div>
            <div class="col-md-9 search-box1" style="padding: 6px;"> <input type="text" id="search"
                    class="form-control " autocomplete="off" [placeholder]="placeholderText"
                    [(ngModel)]="filterRoleassignSearch"
                    style="height: 38px;width: 50%;margin-left: 10px;width: 100%;"
                    (input)="searchroleasgnChange($event)">
            </div>
        </div>
    </div>

</div>
<table class="table table-striped">
    <thead class="tablehd-sec">
        <tr>
            <th style="width: 14%;" class="padleft-30">Member Id</th>
            <th style="width: 16%;">Name</th>
            <th style="width: 12%;">UniqueId</th>
            <th style="width: 14%;">Mobile No</th>
            <th style="width: 22%;">Email</th>
            <th style="width: 22%;">Role</th>

        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let d of lstalluserRoles| paginate: { itemsPerPage: 20, currentPage: p};let i = index"
            class="tdata" style="color: #657376;">
            <td style="width: 14%;padding-left: 2%;" class="padleft-30">{{d.memberId}}</td>
            <td style="width: 16%;">{{d.Name}}</td>
            <td style="width: 14%;">{{d.UniqueId}}</td>
            <td style="width: 15%;">{{d.mobile| slice:0:10}}</td>
            <td style="width: 19%;" class="cell">{{d.email}}</td>
            <td style="width: 22%;">
                <div class="row" *ngIf="isEditIndex!=i">
                    <div class="col-md-6">{{d.roleName}}</div>
                    <div class="col-md-4"><i class="fa fa-pencil bl" (click)="editrole(i)"></i></div>
                </div>
                <input type="text" class="form-control form-select dropbtn inpt dropdown-toggle"
                    name="phone" *ngIf="isEditIndex==i" [(ngModel)]="d.roleName" (click)="getindex(d,i)"
                    autocomplete="off" style="border: 1px solid #898888;"
                    (input)="searchCategorychange($event,d)" />


                <ul class="dropdownshow" style="width: 18%;" *ngIf="id==d.memberId && isdisplay">
                    <li *ngFor="let f of lstRoles" (click)="selectedchangeRole(f,d)"
                        class="list-group-item" data-bs-toggle="tooltip"
                        [ngClass]="{ 'selecteditem': d.roleName == getuser(f.roleName)}"
                        data-bs-placement="top"
                        onclick="document.getElementById('assign').style.display='block'">
                        {{f.roleName}}</li>
                </ul>
               </td>

        </tr>
    </tbody>
</table>
<div class="row">
    <div class="col">
        <div class="d-flex justify-content-center" *ngIf="lstalluserRoles.length!=0">
            <pagination-controls (pageChange)="pageChanged($event)"
                (pageBoundsCorrection)="pageChanged($event)">
            </pagination-controls>
        </div>
    </div>
</div>
<div id="assign" class="modal" style="display: block; overflow: hidden;" *ngIf="isassign">

    <form class="modal-content" action="/action_page.php" style="width: 35%;">
        <div class="container">
            <div class="modal-header" style="padding-top: 0;">
                <h5 class="modal-title" id="exampleModalLabel" style="margin-left: auto;">Confirm</h5>
                <button type="button" id="dismissModal"
                    onclick="document.getElementById('assign').style.display='none'" class="btn-close"
                    data-bs-dismiss="modal" aria-label="Close" (click)="roleCancel()"></button>
            </div>
            <div style="padding-top: 15px;
        padding-bottom: 15px; text-align: center;">
                <p>Are you sure, you want to role assign </p>
                <p> from <strong>{{selectedoldRole}}</strong> to <strong>{{selectednewRole}}</strong>?</p>
            </div>
            <div class="modal-footer" style="padding-bottom: 0;">
                <button type="button" onclick="document.getElementById('assign').style.display='none'"
                    class="btn btn-danger footer-button ml-auto" data-bs-dismiss="modal"
                    style="width: 18%;margin-left: auto;" (click)="roleCancel()">Cancel</button>
                <button type="button" class="btn btn-primary footer-button mr-auto" (click)="rolechange()"
                    style="width: 18%;margin-right: auto;">Ok</button>
            </div>
        </div>
    </form>
</div>
