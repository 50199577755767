import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { AuthenticationService } from '../../Auth/authentication.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {



        //         request = request.clone({
        // headers:request.headers.set('content-type','application/json'),
        // url:request.url.replace('http://','https://')
        //         });
        // add auth header with jwt if user is logged in and request is to the api url.
        const currentUser = this.authenticationService.gettoken();
        const token = sessionStorage.getItem('token');



        var Arr = environment.apiUrl.split('/');

        const isApiUrl = request.url.startsWith(environment.apiUrl);
        if (token != '' && token != null) {
            request = request.clone({
                setHeaders: {                    
                   // Authorization: `Bearer ${token}`
                    // 'Content-Type': 'application/json',
                    // 'Access-Control-Allow-Origin': '*',
                    // 'Access-Control-Allow-Headers': 'Origin, X-Requested-With,X-Auth-Token, Content-Type, Accept, Authorization',
                    // 'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                }
            });


        }

        return next.handle(request);
    }
}