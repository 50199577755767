import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService } from 'src/app/shared/alert/alert.service';
import { ServerApisService } from 'src/app/shared/services/server-apis.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { FormControl } from '@angular/forms';
import { Role } from 'src/app/shared/enums/role.enum'; 
import { DatePipe } from '@angular/common';
import { Constants,navConstants } from 'src/app/shared/constants/constant';
import { RouteMakingService } from 'src/app/shared/services/route-making.service';
import { DataService } from 'src/app/shared/services/data.service';

@Component({
  selector: 'app-role-creation-user-management',
  templateUrl: './role-creation-user-management.component.html',
  styleUrls: ['./role-creation-user-management.component.css']
})
export class RoleCreationUserManagementComponent {
  lstRoles: any = [];
  lstTempRoles: any = [];
  lstalluserRoles: any = [];
  lstalluserTempRoles: any = [];
  lstassignRoles: any = [];
  p = 1;
  previd: number = 0;
  isSearchChange: boolean = false;
  oldservice: string = "";
  isEditIndex: number;
  storeRole: string = '';
  id: number = 0;
  isdisplay: boolean = false
  isEdit: boolean = false;
  isrole: boolean = false;
  submitted: boolean = false;
  isAdd: boolean = false;
  isdelete: boolean = false;
  isassign: boolean = false;
   searchtext: string = '';
  placeholderText: string = "Search With Role Code And Name ";
  isPopup: boolean = false;
  page: string = 'rolecreation';
  showModal: boolean = false;
  isEditing: boolean = false;
  showPopup: boolean = false;
  showPopupForAddRole: boolean = false;
  roleCode: string = '';
  roleName: string = '';
  roleCodeNew: string = '';
  roleNameNew: string = '';
  selectedIndex: number;

  constructor(private spinner: NgxSpinnerService,private routeMakingService:RouteMakingService, private _apiService: ServerApisService, private router: Router, private sanitizer: DomSanitizer,private datePipe: DatePipe,
    private _alertService: AlertService,private _elementRef: ElementRef,private renderer:Renderer2,private dataService:DataService) { }

    ngOnInit(): void {
      const user = sessionStorage.getItem('user');
      if (user != null) {
        let data = JSON.parse(user);
        if (Role.Admin == data.roleId || Role.SubAdmin == data.roleId) {
          let date = new Date();
          this.getRoles();
          let path=window.location.href.split('user-management/')
          
         } else {
          this.router.navigate([""]);
        }
  
      } else {
        this.router.navigate([this.routeMakingService.getNavigateUrl([Constants.Login])]);
      }
 }

cancel() {
  if (this.isAdd) {
    this.lstRoles.pop();
  }
  this.isAdd = false;
  this.isEdit = false;
  this.selectedIndex = -1;


}
getuser(event) {
  if (this.lstRoles.length > 0) {
    var result = this.lstRoles.filter((x: any) => x.roleName == event);
    if (result.length > 0) {
      return result[0].roleName;
    } else {
      return -2;
    }
  } else {
    return -1;
  }

}
 
selected(d: any) {
  this.selectedRoleId = d;
  this.selectedRole = d.roleName;
  this.isdelete = true;
}

selectedchangeRole(event, d) {
  this.selectednewRole = event.roleName;
  this.changeroleassign = d;
  this.isassign = true;
}

roleCancel() {
  this.isdisplay = false;
}

ok() {
  this.deleteRole(this.selectedRoleId);
}

searchrolechange() {

}


deleteRole(data: any) {
  let payload: any = {};
  payload.roleId = data.roleId;
  payload.deletedBy = sessionStorage.getItem('memberId') ? parseInt(sessionStorage.getItem('memberId')) : 0;
  this.spinner.show();
  this._apiService.deleteRole(payload).subscribe((res:any) => {
    this.spinner.hide();
    this.submitted = false;
    this.isEdit = false;
    this.isAdd = false;
    this.isdelete = false;
    this.selectedIndex = -1;
    if(res.result.includes('User Role is deleted successfully !')){
      this._alertService.success('User role delete request submitted for approval');
    } else {
      this._alertService.error(res.result);
    }
  });
}
getindex(d: any, i: number) {
  this.lstRoles = this.lstTempRoles;
  this.selectedoldRole = d.roleName;
  if (this.previd == d.roleId) {
    this.isdisplay = false;
    this.isPopup = false;
    this.id = d.memberId;
    this.previd = 0;
    if (this.isSearchChange == true) {
      this.lstalluserRoles[this.selectedIndex].roleName = this.oldservice;
    }
  }
  else {

    this.isdisplay = true;
    this.id = d.memberId;
    this.oldservice = d.roleName;
    let index = this.lstalluserRoles.findIndex(x => x.memberId === this.id);
    this.selectedIndex = index;
    this.lstalluserRoles[this.selectedIndex].roleName = this.oldservice;

  }
}

insertData(){
  let payload: any = {
    roleId: 0,
    roleCode: this.roleCode,
    roleName: this.roleName,
    memberId: sessionStorage.getItem('memberId') ? parseInt(sessionStorage.getItem('memberId')) : 0
  };
  this.spinner.show();
  this._apiService.InsertRolesAdminApproval(payload).subscribe((res:any) => {
    this.spinner.hide();
    if(res.result.includes('User Role Submitted successfully.')){
      this._alertService.success('User role adding request submitted for approval');
    } else {
      this._alertService.error(res.result);
    }
    this.resetValues();
    this.getRoles();
    this.closePopup();
  });
}

resetValues(){
  this.roleCode = '';
  this.roleName = '';
  this.isEdit = false;
  this.isAdd = false;
  this.selectedIndex = -1;
}

closePopup() {
  document.getElementById('registersdata').style.display = 'none';
}

update(id: number, data: any) {

  this.submitted = true;
  let payload: any = {
    roleId: id,
    roleCode: this.roleCodeNew != '' ? this.roleCodeNew : data.roleCode,
    roleName: data.roleName,
    roleNameNew: this.roleNameNew != '' ? this.roleNameNew : data.roleName,
    memberId: sessionStorage.getItem('memberId') ? parseInt(sessionStorage.getItem('memberId')) : 0
  };

  this.spinner.show();
  this._apiService.UpdateRoleAdminApproval(payload).subscribe((res: any) => {
    this.spinner.hide();
    if (res.result.includes('User Role Updated Successfully.')) {
      this._alertService.success('User role update request submitted for approval');
    } else {
      this._alertService.error(res.result);
    }
    this.submitted = false;
    this.isEdit = false;
    this.isAdd = false;
    this.getRoles();
    this.close();
  });
}


close() {
  this.selectedIndex = -1;
}

closePop() {
  this.showPopup = false;
  
}
cancelEdit(){
  this.isEditing = false;
}

openAddPop(){
  this.showPopupForAddRole = true;
}

closeAddPop(){
  this.showPopupForAddRole = false;
}

getRoles() {
  this.spinner.show();
  this.lstRoles = [];
  this._apiService.GetRoles().subscribe(res => {
    this.spinner.hide();
    this.lstRoles = res.FetchAllUserRolesResult['resultUserRoles'].filter((x:any)=>x.isApproved === 'Yes');
    this.lstTempRoles = this.lstRoles;
  });
}


edit(data: any, index: number) {
  this.isEdit = true;
  this.roleCodeNew = data.roleCode;
  this.roleNameNew = data.roleName;
  this.selectedIndex = index;
}

addpop() {
  this.roleCode = "";
  this.roleName = "";
  this.isEdit = false;
  this.selectedIndex = -1;
}
add() {
  this.isAdd = true;
  this.isEdit = false;
  this.submitted = false;
  this.roleCode = '';
  this.roleName = '';
  this.selectedIndex = this.lstRoles.length;
  
}
selectedRoleId: any;
selectedRole: string = "";
selectedoldRole: string = "";
selectednewRole: string = "";
changeroleassign: any;

searchroleChange(event: any) {
  if (event.target.value != '' && event.target.value != undefined) {
    var result = this.lstTempRoles.filter((x: any) => x.roleId.toString().toLowerCase().includes(event.target.value.toLowerCase())
      || x.roleName.toLowerCase().includes(event.target.value.toLowerCase()))
    this.lstRoles = result;
  } else {
    this.lstRoles = this.lstTempRoles;
  }
}

pageChanged(event: any) {
  this.p = event;
  this.isEditIndex = -1;
  window.scrollTo(0, 0);
}


}
