import { Component, ElementRef, Renderer2, EventEmitter, Input, OnInit, Output,NgZone, OnChanges } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService } from 'src/app/shared/alert/alert.service';
import { GoogleApisService } from 'src/app/shared/services/google-apis.service';
import { ServerApisService } from 'src/app/shared/services/server-apis.service';
import { PropsService } from '../../shared/services/props.service';
import { DataService } from 'src/app/shared/services/data.service';
import { SearchService } from 'src/app/shared/services/search.service';
import { Constants} from  'src/app/shared/constants/constant';
import { GeoCodingService } from '../../shared/services/geo-coding.service';

@Component({
  selector: 'app-service-provider-list',
  templateUrl: './service-provider-list.component.html',
  styleUrls: ['./service-provider-list.component.css']
})

export class ServiceProviderListComponent implements OnInit,OnChanges {
  @Output() setAddress: EventEmitter<any> = new EventEmitter();
  @Input() searchData;
  formname: string = 'search';
  providerListWithOutFilter: any = [];
  providerList: any
  providerFilterDistance: any =  Constants.DefaultRangeDistance;
  providerSearch: any = "";
  serviceSearch: any = "";
  home: string = Constants.home;
  search: string = "Search";
  serviceProviderRoute: string = Constants.serviceProviderRoute;
  display = 'none';
  latlng: any = {};
  isResults: boolean = true;
  localAddress: any;
  load: boolean = true;
  addressobj: any = {};
  addresscoordinates: any = [];
  p = 1; // Pagination
  origin = {};
  allRatings: any;
  providerListFinal: any = [];
  servicesList: any;
  latlngs: string = "";
  isMember: boolean = false;
  categoryid: any;
  loggedIn: boolean = false;
  isDropdown: boolean = false;
  lstAllServices: any = [];
  lstallDRpServices: any = [];
  lstTempAllServices: any = [];
  showContactNo: any;
  sessionStorageSearchObj: any = {};
  showGif: boolean = false;
  inProgress: boolean = false;
  authorizatonToken: any;
  call_id: any;
  private intervalId: any;
  recordCount:any = sessionStorage.getItem('totalSearchCount');
  city: any;
  coordinates: { lat: number; lng: number; };
  error: any;

  /**
   * Constructs a new instance of the ServiceProviderListComponent.
   * @param _apiService - The ServerApisService instance.
   * @param router - The Router instance.
   * @param _alertService - The AlertService instance.
   * @param _googleApi - The GoogleApisService instance.
   * @param spinner - The NgxSpinnerService instance.
   * @param autocomplete - The AutocompleteService instance.
   * @param renderer - The Renderer2 instance.
   * @param el - The ElementRef instance.
   */
  constructor(
    private _apiService: ServerApisService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private _alertService: AlertService,
    private _googleApi: GoogleApisService,
    private spinner: NgxSpinnerService,
    private renderer: Renderer2,
    private el: ElementRef,
    private wishListCount : PropsService,
    private ngZone: NgZone,
    private dataService: DataService,
    private _searchService: SearchService,
    private geocodingService: GeoCodingService
  ) {

  }

  /**
   * Initializes the component.
   * - Checks if the page is reloaded and sets the logged-in status accordingly.
   * - Parses the URL to extract search parameters and updates the local storage search object.
   * - Subscribes to changes in data from the API service and updates component properties accordingly.
   * - Retrieves search data from local storage and sets component properties.
   * - Calls methods to get the local address and fetch all services.
   */
  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(params => {
      this.city = params.get('locality');
      if (this.city) {
        this.geocodingService.getCoordinates(this.city).subscribe(
          (coords) => {
            this.coordinates = coords;
            sessionStorage.setItem('latlng', this.coordinates.lat + ',' + this.coordinates.lng);
            sessionStorage.setItem('locality', this.city);
            if(!params.get('parentCategory').includes('not found')){
              sessionStorage.setItem('parentCategory', params.get('parentCategory').replace(/-/g, ' '));
            }
            sessionStorage.setItem('childCategory',params.get('childCategory').replace(/-/g, ' '));
          },
          err => this.error = err.message
        );
      }
    });
    let data = JSON.parse(sessionStorage.getItem('searchData'));
    let distance = JSON.parse(sessionStorage.getItem('distance'));
    if (data != null && data != undefined && data != "") {
      this.providerSearch = data.filterName;
      this.providerFilterDistance = distance ? distance : data.filterDistance;
      this.localAddress = data.localAddress;
      this.categoryid = data.categoryid;
    }


    // let isPageReloaded = sessionStorage.getItem('isPageReloaded');
    // if (!isPageReloaded) {
    const user = sessionStorage.getItem('user');
    let payload = {}
    if (user != null) {
      this.loggedIn = true;
      payload = {
        "memberId": (+sessionStorage.getItem('memberId')),
        "token": sessionStorage.getItem('token')
      }
      // Initial API call
      this.pollApi();
    } else {
      sessionStorage.setItem('redirectSearch','true');
      this.loggedIn = false;
      payload = {
        "memberId": 0,
        "token": " "
      }
    }
    // this.spinner.show();
    this._apiService.GetDashboardDetails(payload).subscribe((res: any) => {
      this.spinner.hide();
      this.lstAllServices = res.GetDashboardDetailsResult.resultDashboardSubProfessions['resultSubProfessionCategory'];
      //search url should work in different browser
      this._searchService.crossBrowserSearch(this.lstAllServices,this.p);
      this.getProviderServiceList();
    });
    // }

    this.intervalId = setInterval(() => {
      this.recordCount = sessionStorage.getItem('totalSearchCount');
      this.pollApi();
    }, 3000);

  }

  ngOnChanges(){
    let data = JSON.parse(sessionStorage.getItem('searchData'));
    let distance = JSON.parse(sessionStorage.getItem('distance'));
    if (data != null && data != undefined && data != "") {
      this.providerSearch = data.filterName;
      this.providerFilterDistance = distance ? distance : data.filterDistance;
      this.localAddress = data.localAddress;
      this.categoryid = data.categoryid;
    }
  }
  

  pollApi(): void {
    if (sessionStorage.getItem('authTokenCall') !== null && sessionStorage.getItem('authTokenCall') !== undefined && sessionStorage.getItem('authTokenCall') !== "" && sessionStorage.getItem('currentCallId') !== null && sessionStorage.getItem('currentCallId') !== undefined && sessionStorage.getItem('currentCallId') !== ""){
      this.authorizatonToken = sessionStorage.getItem('authTokenCall');
      this.call_id = sessionStorage.getItem('currentCallId');
      
      if(this.call_id !== null && this.call_id !== undefined && this.call_id !== "" && this.authorizatonToken !== null && this.authorizatonToken !== undefined && this.authorizatonToken !== ""){
        this._apiService.CheckCallRecords(this.call_id, this.authorizatonToken).subscribe((res: any) => {
          if (res.results.length === 0) {
            this.inProgress = true;
          } else {
            this.inProgress = false; 
          }
        }, (error) => {
          console.log(error);
        });
      }
    }
  }

  ngOnDestroy(): void {
    // Clear the interval when the component is destroyed
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  /**
   * Retrieves the geographic coordinates of a local address using the Google API.
   * @param localAddress The local address to search for.
   */
  getLocalAddress(localAddress) {
    this._googleApi.getSearchAddressGeoCoordinates(localAddress).subscribe(res => {
      this.origin = {};
      if (res.results.length > 0) {
        this.origin['lat'] = res.results[0].geometry.location.lat;
        this.origin['lng'] = res.results[0].geometry.location.lng;
        this.addressobj['latlng'] = res.results[0].geometry.location.lat + ',' + res.results[0].geometry.location.lng;
        this._googleApi.getCurrentGeoAddress(this.addressobj).subscribe(res => {
          for (let i = 0; i < res.results.length; i++) {
            for (let m = 0; m < res.results[i].types.length; m++) {
              const ele = res.results[i].types[m];
              if (ele == "locality") {
               // sessionStorage.setItem('locality', res.results[i].address_components[0].long_name);
              } else if (ele == "country") {
                sessionStorage.setItem('countryCode', res.results[i].address_components[0].short_name);
              }
            }
          }
          this.getProviderServiceList();
        },
          error => { this._alertService.error(error) });
      }
    });
  }

  /**
   * Adds an item to the wishlist.
   * @param data - The data of the item to be added.
   */
  addWishlist(data) {
    if (sessionStorage.getItem('user') != null) {
      let payLoad: any = {
        provider_memberID: data.memberId,
        user_memberID: (+sessionStorage.getItem('memberId')),
        whishlist_id: 0
      }
      this._apiService.InsertWhishlist(payLoad).subscribe(res => {
        if (res.status == 200) {
          this.getProviderServiceList();
          this._alertService.success("Wishlist added successfully!");
          this.getCompleteProfile();
          setTimeout(() => {
            window.location.reload();
          }, 300);
        }
      });
    } else {
      let message = 'Please signin!'
      // window.scrollTo(0, 0);
      this._alertService.warn(message);
    }
    //window.location.reload(); // adding temporary fix for wishlist count - later need to remove and replace with subject service
  }

  deleteWishlist(data) {
    if (sessionStorage.getItem('user') != null) {
      let payLoad: any = {        
        whishlist_id: data.wishlistId
      }
      this._apiService.DeleteWhishlist(payLoad).subscribe(res => {
        if (res.status == 200) {
          this._alertService.success("Wishlist deleted successfully !");
          this.getProviderServiceList();
          this.getCompleteProfile();
          setTimeout(() => {
            window.location.reload();
          }, 300);
        }
      });
    } else {
      let message = 'Please signin!'
      // window.scrollTo(0, 0);
      this._alertService.warn(message);
    }
  }

  /**
   * Retrieves the list of provider services based on the search criteria.
   */
  getProviderServiceList() {
    this._searchService.searchResults$.subscribe((res:any) => {
      if (res.length > 0) {
        this.providerListFinal = res;
        let data = JSON.parse(sessionStorage.getItem('searchData'));
        if (data != null && data != undefined && data != "") {
          this.providerSearch = data.filterName;
        }
      } else {
        this.providerListFinal = [];
        this.isResults = false;
      }
    })
  }

  getCompleteProfile() {   
    let payLoad: any = {
      MemberID: +sessionStorage.getItem('memberId')
    }
    this._apiService.GetCompeteProfileData(payLoad).subscribe(res => {
      if (res.status == 200) {
        this.wishListCount.SetWishList(res.whishlist_count);
      }
    });
  }

  /**
   * Handles the page change event.
   * @param event The page change event.
   */
  pageChanged(event) {
    this.p = event;
    let searchData = JSON.parse(sessionStorage.getItem('searchData'));
    if(searchData != null && searchData != undefined && searchData != ""){
      this._searchService.fetchHireAndExpert(searchData,this.p).subscribe((res: any) => { 
        if(sessionStorage.getItem('pageNumber') !== null && sessionStorage.getItem('pageNumber') !== undefined){
          this.p = 1;
          sessionStorage.removeItem('pageNumber');
        }
      });
    }
    // this._searchService.crossBrowserSearch(this.lstAllServices,this.p);
    window.scrollTo(0, 0);
  }

  /**
   * Opens the phone number modal.
   */
  openModal(): void {
    const modal = this.el.nativeElement.querySelector('#viewPhoneNumberModal');
    this.renderer.addClass(modal, 'show');
    this.renderer.setStyle(modal, 'display', 'block');
    document.body.classList.add('modal-open');
    document.body.classList.add('no-backdrop');
  }

  goToProfile():void{
    const modal = this.el.nativeElement.querySelector('#viewPhoneNumberModal');
    this.renderer.setStyle(modal, 'display', 'none');
    this.renderer.removeClass(modal, 'show');
    let user = JSON.parse(sessionStorage.getItem('user'))
    let id = user.memberId;
    let username = user.fname.toLowerCase() + user.lname.toLowerCase()
    username = username.replace(/\s+/g, ' ');
    username = username.replace(/ /g, '-');
    this.router.navigate(['/profile', `${username}-${id}`]).then(()=>{
      window.location.reload();
    });
  }

  /**
   * Calls the profile function.
   * 
   * @param data - The data object containing the country mobile code and mobile number.
   */
  callProfile(data) {

    let user : any = JSON.parse(sessionStorage.getItem('user'));    
    this.loggedIn = user != null;
    if (this.loggedIn) {
      if (user.mobile === '' || user.mobile === null || user.mobile === undefined){
        this.openModal();
        return;
      } 
      this.showContactNo = data.country_mobile_code + " - " + data.mobile;
      this._alertService.info("Please pick up the call while we connect with the service provider");
      this.showGif = true;
      let payLoad: any = {
        "memberId": +sessionStorage.getItem('memberId'),
        "token": sessionStorage.getItem('token'),
        "deviceType": "web",
        "serviceName": data.Services[0].userService
      }
      this._apiService.FetchTataTeleDetails(payLoad).subscribe((res1:any) => {
        let payLoad: any = {
          "agent_number": user.mobile,
          "destination_number": data.mobile,
          "get_call_id": 1,
          "caller_id" : res1.TataTeleDetails.param4.slice(2)
        }
        let authToken = res1.TataTeleDetails.authorizationToken;
        sessionStorage.setItem("authTokenCall",authToken);
        this._apiService.ClickToCall(payLoad,authToken).subscribe((res2:any) => {
          if(res2.success == false){
            this._alertService.error("Call missed by user");
            this.spinner.hide();
            this.showGif = false;
            let payload: any = {
              "callerId" : sessionStorage.getItem('memberId'),
              "receiverId" : data.memberId,
              "tataTeleResponeCallerId" : '',
              "callerNum" : user.mobile,
              "receiverNum" : data.mobile,
              "agentNum" : res1.TataTeleDetails.param4.slice(2),
              "startDatetime" : this.getCurrentFormattedDateTime(),
              "callStatus" : 'missed',
            }

            this._apiService.InsertCallLogs(payload).subscribe((res3:any) => {
              // this._alertService.success("Call missed by the user")
              this.spinner.hide();
              this.showGif = false;
            }, (error) => {
              console.log(error);
              this.spinner.hide();
              this.showGif = false;
            });
          } else if (res2.call_id != undefined && res2.success === true) {
              sessionStorage.setItem('currentCallId',res2.call_id);
              this._apiService.CheckCallRecords(res2.call_id, authToken).subscribe((res3: any) => {

                if (res3.results.length === 0) {
                  this.inProgress = true;
                  setTimeout(() => {
                    this._alertService.info("If you want to make a another call then please wait for the current call to be over, call button will enabled after the current call is over.");
                  }, 3000);
                } else {
                  this.inProgress = false; 
                }
              }, (error) => {
                console.log(error);
              });
        
              // Insert caller details and call id into database
              let payload: any = {
                "callerId" : sessionStorage.getItem('memberId'),
                "receiverId" : `${data.memberId}`,
                "tataTeleResponeCallerId" : res2.call_id,
                "callerNum" : user.mobile,
                "receiverNum" : data.mobile,
                "agentNum" : res1.TataTeleDetails.param4.slice(2),
                "startDatetime" : this.getCurrentFormattedDateTime(),
                "callStatus" : res2.hasOwnProperty('call_id') && res2.call_id !== '' ? 'Outgoing' : 'missed',
              }

              this._apiService.InsertCallLogs(payload).subscribe((res3:any) => {
                console.log(res3);
                this._alertService.success("Call Connected Successfully")
                this.spinner.hide();
                this.showGif = false;
              }, (error) => {
                console.log(error);
                this.spinner.hide();
                this.showGif = false;
              });

          }
        }, (error) => {
          console.log(error);
          this.spinner.hide();
          this.showGif = false;
          if(error.status === 504 || error.status === 503){
            this._alertService.error("Call missed by the user")
            let payload: any = {
              "callerId" : sessionStorage.getItem('memberId'),
              "receiverId" : data.memberId,
              "tataTeleResponeCallerId" : '',
              "callerNum" : user.mobile,
              "receiverNum" : data.mobile,
              "agentNum" : res1.TataTeleDetails.param4.slice(2),
              "startDatetime" : this.getCurrentFormattedDateTime(),
              "callStatus" : 'missed',
            }
  
            this._apiService.InsertCallLogs(payload).subscribe((res3:any) => {
              // this._alertService.success("Call missed by the user")
              this.spinner.hide();
              this.showGif = false;
            }, (error) => {
              console.log(error);
              this.spinner.hide();
              this.showGif = false;
            });
          }
        });
      }, (error) => {
        console.log(error);
        this.spinner.hide();
        this.showGif = false;
      });
    } else {
      this.display = 'block';
    }
  }

  getCurrentFormattedDateTime(): string {
    const currentDate = new Date();
  
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Month is zero-based
    const day = String(currentDate.getDate()).padStart(2, '0');
    const hours = String(currentDate.getHours()).padStart(2, '0');
    const minutes = String(currentDate.getMinutes()).padStart(2, '0');
    const seconds = String(currentDate.getSeconds()).padStart(2, '0');
    const milliseconds = currentDate.getMilliseconds().toString().padStart(3, '0');
  
    const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
  
    return formattedDateTime;
  }

  /**
   * Handles the error when an image fails to load for a provider.
   * @param provider - The provider object.
   */
  handleImageError(provider: any) {
    provider.imageError = true;
  }

  /**
   * Returns the first character of the first and last name of the provider in uppercase.
   * @param provider - The provider object.
   * @returns The first character of the first and last name in uppercase.
   */
  getuserChar(provider: any) {
    return provider.fname.charAt(0).toUpperCase() + provider.lname.charAt(0).toUpperCase();
  }

  /**
   * Navigates to the profile page of a service provider.
   * @param pr - The service provider object.
   */
  providerProfile(pr) {
    if(sessionStorage.getItem('redirectSearch') !== null && sessionStorage.getItem('redirectSearch') !== undefined){
      sessionStorage.removeItem('redirectSearch');
    }
    sessionStorage.setItem('redirectProviderProfile', 'true');
    let distance = JSON.parse(sessionStorage.getItem('searchData')).filterDistance;
    sessionStorage.setItem('distance', distance);
    let id = pr.memberId;
    let username = pr.fname.toLowerCase() + pr.lname.toLowerCase()
    username = username.replace(/\s+/g, ' ');
    username = username.replace(/ /g, '-');
    this.router.navigate(['/profile', `${username}-${id}`]).then(() => {
      window.location.reload();
    });
  }


  getFormattedUsername(provider: any): string {
    let username = provider.fname.toLowerCase() + provider.lname.toLowerCase()
    username = username.replace(/\s+/g, ' ');
    username = username.replace(/ /g, '-');
    return username;
  }

  handleClick(event: MouseEvent, provider: any): void {
    if (event.button === 0) {
      event.preventDefault();
      this.providerProfile(provider);
    }
  }

  /**
   * Navigates to the home page.
   */
  goToHome() {
    sessionStorage.setItem('redirectSearch', 'true');
    this.router.navigate(["/login"]).then(() => {
      window.location.reload();
    });

  }
}