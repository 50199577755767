import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[appTypingPlaceholder]'
})
export class TypingPlaceholderDirective implements OnInit {
  @Input() appTypingPlaceholder: string[] = [];
  private currentIndex = 0;
  private charIndex = 0;
  private isDeleting = false;
  private speed = 200;
  private delayBetweenWords = 500;
  private deleteDelay = 500; // Delay before deleting the word

  constructor(private el: ElementRef) {}

  ngOnInit() {
    this.type();
  }

  private type() {
    const placeholderText = this.appTypingPlaceholder[this.currentIndex];
    const currentText = placeholderText.substring(0, this.charIndex);

    this.el.nativeElement.placeholder = currentText;

    if (this.isDeleting) {
      this.el.nativeElement.placeholder = '';
      this.isDeleting = false;
      this.currentIndex = (this.currentIndex + 1) % this.appTypingPlaceholder.length;
      this.charIndex = 0; // Reset charIndex for the next word
      setTimeout(() => this.type(), this.deleteDelay);
    } else {
      if (this.charIndex < placeholderText.length) {
        this.charIndex++;
        setTimeout(() => this.type(), this.speed);
      } else {
        setTimeout(() => {
          this.isDeleting = true;
          this.type();
        }, this.delayBetweenWords);
      }
    }
  }
}
