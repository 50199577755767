import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServerApisService } from 'src/app/shared/services/server-apis.service';
import { AlertService } from 'src/app/shared/alert/alert.service';
import { Constants } from 'src/app/shared/constants/constant';
@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {

  contactForm: FormGroup;
  submitted = false;
  userInfo: any = {};
  home = Constants.home;
  contactUs = Constants.contactUs;
  contactUsRoute:string=Constants.contactUsRoute;
  loggedIn: any;
  
  constructor(
    private formBuilder: FormBuilder,
    private _apiService: ServerApisService,
    protected _alertService: AlertService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    const user = sessionStorage.getItem('user');
    if (user == null) {
      this.loggedIn = false;
      sessionStorage.setItem('redirectContactUs','true');
    } else {
      this.loggedIn = true;
      this.contactForm = this.formBuilder.group({
      message: ['', Validators.required]
      });

      const memberId = sessionStorage.getItem('memberId');

      let payLoad: any = {
        memberId: memberId,
        providerMemberId: memberId,
        token: this._apiService.token
      };
      this.spinner.show();
      this._apiService.getUserProfile(payLoad).subscribe((res: any) => {

        this.spinner.hide();
        try {
          if (res.status == 200) {
            this.userInfo = res.Registration;

            let fullname = this.userInfo.fname + " " + this.userInfo.lname;
            this.contactForm.setValue({
               message: ''
            });
          }
        } catch (err) {
          var obj = {
            ErrorMessage: err
          }
          this._apiService.ExceptionLog(obj).subscribe(res => {

          });
        }
      });

      window.scrollTo(0, 0);
    }
  }

  get f() { return this.contactForm.controls; }

  onSubmit() {
    window.scrollTo(0, 0);
    this.submitted = true;
    if (this.contactForm.valid) {
      const _v = this.contactForm.value;
      let user : any;
      user = sessionStorage.getItem('user');
      user = JSON.parse(user);
      let details = {
        RecepientName: user.fname,
        RecepientEmail: user.email,
        PhoneNumber: user.mobile,
        Message: _v.message,
        DeviceType:'web'
      }
      this.spinner.show();
      this._apiService.sendContactUs(details).subscribe(res => {
        this.spinner.hide();
        this.submitted = false;
        this.contactForm.reset();
        if (res != undefined && res != null) {
          if (res.status == 200) {
            window.scrollTo(0, 0);
              this._alertService.success('Thank you, we will get back to you soon!');
          }
        }
      }, err => {
        this.spinner.hide();
      });
  }
}

contact(){
  sessionStorage.setItem('contact','true');
}

onReset() {
  this.submitted = false;
  this.contactForm.reset();
}
goToLink(url: string) {
  window.open(url, "_blank");
}
OnlyNumbersAllowed(event): boolean {

  const charCode = (event.which) ? event.which : event.keycode;
  
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
}
}

} 

