import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AlertService } from 'src/app/shared/alert/alert.service';
import { Router } from '@angular/router';
import { ServerApisService } from 'src/app/shared/services/server-apis.service';
import { Constants,navConstants } from 'src/app/shared/constants/constant';
import { RouteMakingService } from 'src/app/shared/services/route-making.service';


@Component({
  selector: 'app-near-by-service-management',
  templateUrl: './near-by-service-management.component.html',
  styleUrls: ['./near-by-service-management.component.css']
})
export class NearByServiceManagementComponent implements OnInit {
  adminPanel: string = Constants.adminPanel;
  nearByServiceManagement: string = Constants.nearByServiceManagement;
  serviceManagementNav: string = navConstants.nearByCategoryCreationNav;
  adminPanelRoute: string =Constants.adminPanelRoute;
  serviceManagenetTitle: string = Constants.nearByCategoryCreation;
  newSubServiceUrl: string = '';
  mainServiceId:number;
  isCard : any = true;
  page = 'category';
  p = 1;
  memberID: number = Number(sessionStorage.getItem('memberId'));
  token: string = sessionStorage.getItem('token');
 
  
  constructor(private _apiService: ServerApisService,private router: Router,private routeMakingService:RouteMakingService, private _alertService: AlertService,
    private cdr: ChangeDetectorRef) { 
   }

  ngOnInit(): void {
    let path=window.location.href.split('near-by-service-management/')
   // console.log(path[1])
    if(path[1]=='near-by-category-creation'){
      this.searchtab('category')
     }else if(path[1]=='near-by-sub-category-creation'){
      this.searchtab('subCategory')
     }
  }
 menu() {
    if (this.isCard) {
      this.isCard = false;
    } else {
      this.isCard = true;
    }
  }
searchtab(pageName) {
    if (pageName === 'category') {
      this. serviceManagenetTitle = Constants.nearByCategoryCreation
      this.serviceManagementNav = navConstants.nearByCategoryCreationNav
      this.router.navigate([this.routeMakingService.getNavigateUrl([Constants.adminPanelRoute,Constants.nearByServiceManagement,navConstants.nearByCategoryCreationNav])]);
    } else if (pageName === 'subCategory') {
      this. serviceManagenetTitle = Constants.nearBySubCategoryCreation
      this.serviceManagementNav = navConstants.nearBySubCategoryCreationNav
      this.router.navigate([this.routeMakingService.getNavigateUrl([Constants.adminPanelRoute,Constants.nearByServiceManagement,navConstants.nearBySubCategoryCreationNav])]);  
    }
    this.page = pageName;
  }

  onMouseEnter() {
    this.isCard = true;
  }

  onMouseLeave() {
    this.isCard = false;
  }

}
