<div class="tasks_table1 ">
    <div class="row">
      <div class="col-md-10 search-box1">
        <label style="width: 100%;">
          <span>
            <input type="text" placeholder="Search.." id="search" name="search" class="w-50" maxlength="30"
              class="custom-focus-input" autocomplete="off" [(ngModel)]="searchInfo"
              (input)="search($event, 'Login Activities')"
              style="height: 35px;border: none;width: 120%;margin-left: 3px;padding: 10px;border-radius:5px;">
          </span>
        </label>
      </div>
    </div>
  </div>
  <table class="table table-striped">
    <thead class="tablehd-sec">
      <tr>
        <th style="width: 14%;">Unique Id</th>
        <th style="width: 17%;">Name</th>
        <th style="width: 18%;">Mobile No.</th>
        <th style="width: 15%;">Profession</th>
        <th style="width: 17%;">Child Profession</th>
        <th style="width: 17%;">Activity Date</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let d of lstActivities | paginate: { itemsPerPage: 20, currentPage: p }" class="tr">
        <th style="width: 14%;">{{d.uniqueId}}</th>
        <td style="width: 17%;">{{d.Name}}</td>
        <td style="width: 18%;">{{d.Mobile_No}}</td>
        <td style="width: 15%;">{{d.profession}}</td>
        <td style="width: 17%;">{{d.childProfession}}</td>
        <td style="width: 20%;">{{d.Activity_Date | date:'M/d/yyyy, h:mm:ss a'}}</td>
      </tr>
    </tbody>
  </table>
  <div class="row">
    <div class="col">
      <div class="d-flex justify-content-center" *ngIf="lstActivities.length > 0">
        <pagination-controls (pageChange)="pageChangedLoginActivities($event)">
          (pageBoundsCorrection)="pageChanged($event)"></pagination-controls>
      </div>
    </div>
  </div>
