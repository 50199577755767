<div *ngIf="!isProfile">

    <nav aria-label="breadcrumb" class="mlft-6">
        <app-bread-crumb  [breadpath1]="homeAddService" [breadpath2]="addService" [breadroute1]="addServiceRoute"></app-bread-crumb>
    </nav>
    <div class=" custom-container">
        <h4>Services & Professions</h4>
    </div>
    <div class="container service-container">
        <div class="row pad-1rem">
            <div class="col-12 col-md-6">
                <div class="row">
                    <h3 class="font-weight-bold mb-3 f-s-22">Boost Your Visibility</h3>
                    <h5 class="mb-3 f-s-16 font-weight-bold">Make it easy for others to find you online.</h5>
                    <p class="mb-3 f-s-16">Are you a <span class="pt-b-3 text-primary">Doctor, Engineer, Artist,
                            Plumber, or a Professional in any field?</span></p>
                    <p class="mb-3 f-s-16">
                        Take advantage of our service listing feature to enhance your online presence and connect with
                        those who need your expertise.
                    </p>
                </div>
            </div>
            <div class="col-12 col-md-6 service-section">
                <div *ngIf="currentUser.Services.length>0 && !emptyService">
                    <div class="service-card" *ngFor="let s of currentUser.Services; let i = index;">
                        <label>{{ s.userService }}</label>
                        <span (click)="removeService(s.CategoryId)" class="hand close-icon">X</span>
                    </div>
                    <label style="color: red;" class="mb-10" *ngIf="isRemovedService">Required minimum 1 service</label>
                </div>
                <ng-select [items]="lstServices" class="custom-ng-select mb-10" bindLabel="subProfessionCategoryName"
                style="border-radius: 4px;" [placeholder]="isdisableDropdown ? '' : placeholderText"
                [ngClass]="{hide:isdisableDropdown }" #selectBoxs (change)="changeServicedrp($event)">
                 </ng-select>
     
              
              <div class="container sucess-container">
              <img src="assets/images/add-service-sucess.png" class="hand done-img" (click)="addServiceField()"
                  *ngIf="!isdisableDropdown" alt="add service sucess" title="add service sucess">
              
                    <!-- <img src="assets/images/add-service-disable.png" class="done-img cursor-not-allowed"
                        *ngIf="isdisableDropdown"> -->

                </div>
            </div>
        </div>


    </div>
</div>

<div class="col-12 col-md-6 service-section" *ngIf="isProfile">
    <div *ngIf="currentUser.Services.length>0 && !emptyService">
        <div class="service-card" *ngFor="let s of currentUser.Services; let i = index;">
            <label>{{ s.userService }}</label>
            <span (click)="removeService(s.CategoryId)" class="hand close-icon">X</span>
        </div>
        <label style="color: red;" class="mb-10" *ngIf="isRemovedService">Required minimum 1 service</label>

    </div>
    <ng-select [items]="lstServices" class="custom-ng-select mb-10" bindLabel="subProfessionCategoryName"
                style="border-radius: 4px;" [placeholder]="isdisableDropdown ? '' : placeholderText"
                [ngClass]="{hide:isdisableDropdown }" #selectBoxs (change)="changeServicedrp($event)">
     </ng-select>
     
    <div class="container sucess-container">
        <img src="assets/images/add-service-sucess.png" class="hand done-img" (click)="addServiceField()"
            *ngIf="!isdisableDropdown" alt="image" title="image">
        <!-- <img src="assets/images/add-service-disable.png" class="done-img cursor-not-allowed" *ngIf="isdisableDropdown"> -->

    </div>
</div>