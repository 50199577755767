import { Injectable,Inject } from '@angular/core';
import { Router } from '@angular/router';
import {ServerApisService} from './server-apis.service';
import { DOCUMENT } from '@angular/common';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class SitemapService {
  constructor(private router: Router,private serverApi: ServerApisService,
    @Inject(DOCUMENT) private document: Document,
    private http: HttpClient,private sanitizer: DomSanitizer) {}
  domainUrl: string = this.document.location.origin.includes('localhost') ? 'https://toskie-dev-web.azurewebsites.net' : this.document.location.origin;

  storeSitemapToDB(url,categoryId) {

    if(!url.toLowerCase().includes('sitemap.xml')){    
      let routes = this.domainUrl + url;
      const today = new Date().toISOString();
      const api_url = this.serverApi.geturl() + "/InsertSiteMapDetails";
      const catId = categoryId;
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
      });
      let json = {
        "categoryId" : `${catId}`,
        "loc" : `${routes}`,
        "lastmod" : `${today}`,
        "changefreq" : "weekly",
        "priority" : "0.8"
      }
      this.http.post<any>(api_url,json,{headers}).subscribe(
        (response) => {
          // console.log('Url added to database', response);
        },
        (error) => {
          console.error('Error occur during adding the url to database', error);
        }
      );
    }
  }


  storeSitemapToSearchDB(url, type?:any){
    if(url !== '/' && url !== "") {   
      let routes;
      if(type == 'newlyAdded'){
        routes = url;
      } else {
        routes = this.domainUrl + url;
      }
      const today = new Date().toISOString();
      const api_url = this.serverApi.geturl() + "/InsertSiteMapUrls";
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
      });
      let json = {
        "loc" : `${routes}`,
        "lastmod" : `${today}`,
        "changefreq" : "weekly",
        "priority" : "0.8"
      }
      this.http.post<any>(api_url,json,{headers}).subscribe(
        (response) => {
          // console.log('Url added to database', response);
        },
        (error) => {
          console.error('Error occur during adding the url to database', error);
        }
      );
    }
  }

  getSitemapFromDB(id) {
    const url = this.serverApi.geturl() + `/FetchSiteMap?categoryId=${id}`;
    return this.http.get<any>(url);
  }

  getAllParentsCategory() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/xml'
    });
    const url = this.serverApi.geturl() + "/FetchAllParentCategorySitemaps";
    return this.http.get<any>(url, { headers });
  }

  SiteMapForProfileData() {
    const url = this.serverApi.geturl() + "/ProfileSiteMapXml";
    return this.http.get<any>(url);
  }

  getSearchSiteMapFtpFileTransfer() {
    const url = this.serverApi.geturl() + "/GenerateSitemapAsync";
    return this.http.get<any>(url);
  }
}
