import { Component, Input, OnInit, ChangeDetectorRef, OnDestroy, AfterViewInit,Output,EventEmitter } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import 'owl.carousel';
import { AlertService } from 'src/app/shared/alert/alert.service';
import { ServerApisService } from 'src/app/shared/services/server-apis.service';
declare const $: any;

@Component({
  selector: 'app-custom-carousel',
  templateUrl: './custom-carousel.component.html',
  styleUrls: ['./custom-carousel.component.css']
})
export class CustomCarouselComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() images: any[] = [];
  @Input() userProfile: boolean;
  currentIndex = 0;
  showCarousel = false;
  showDefaultImage: boolean = true;
  constructor(private cdr: ChangeDetectorRef,private _apiService: ServerApisService,private _alertService:AlertService,private spinner:NgxSpinnerService) { }

  ngOnInit(): void {
    this.showCarousel = this.images.length > 0;
    this.showDefaultImage = this.images.length < 6 && this.userProfile;
  }

  ngAfterViewInit(): void {
    if(this.images.length < 2) {
      $(".carousel_2").owlCarousel({
        nav: true,
        loop: false,
        items: 3,
        margin: 50,
        center: true,
        animateOut: 'fadeOut',
        animateIn: 'fadeIn',
        navText: ['<i class="fa fa-chevron-left"></i>', '<i class="fa fa-chevron-right"></i>'],
      }).on('initialized.owl.carousel linked.to.owl.carousel', () => {
        this.updateCurrentClass();
      });
    } else {
      $(".carousel_2").owlCarousel({
        nav: true,
        loop: true,
        items: 3,
        margin: 90,
        center: true,
        animateOut: 'fadeOut',
        animateIn: 'fadeIn',
        navText: ['<i class="fa fa-chevron-left"></i>', '<i class="fa fa-chevron-right"></i>'],
      }).on('initialized.owl.carousel linked.to.owl.carousel', () => {
        this.updateCurrentClass();
      });
    }
  }

  triggerFileUpload() {
    document.getElementById('upload-file').click();
  }

  addAttachment(event: any,value): void {
    try {
        for (let i = 0; i < event.target.files.length; i++) {
          var ext = '.png';
          var reader = new FileReader();
          reader.readAsDataURL(event.target.files[i]);
  
          reader.onload = (_event) => {
            var img: any = reader.result;
            fetch(img)
              .then(res => res.blob())
              .then(blob => {
                const fd = new FormData();
                fd.append('image', blob, 'filename');
  
                let payLoad: any = {
                  memberId: sessionStorage.getItem('memberId'),
                  organisationImageName: 'bussiness_file',
                  fileType: ext,
                };
  
                this._apiService.uploadOrganisationImages(payLoad, blob).subscribe((res: any) => {
                  this._alertService.success('Image uploaded successfully');
                  // this.viewUserProfileBYURL(); will be called from emit event
                  setTimeout(() => {
                    window.location.reload();
                  }, 1500);
                }, (error) => {
                  this._alertService.error('Image not uploaded');
                  this.spinner.hide();
                });
              });
          };
      }
    } catch (error) {
      this.spinner.hide();
      throw error;
    }
  }

  // for autoplay
  // autoplay:true,
  // autoplayTimeout:3000,
  // autoplayHoverPause:true,

  private updateCurrentClass(): void {
    const sync2 = $(".carousel_2");
    sync2.find('.owl-item.current').removeClass('current');
    const current = sync2.find('.owl-item.active.center').length
      ? sync2.find('.owl-item.active.center')
      : sync2.find('.owl-item.active').eq(0);
    current.addClass('current');
    this.cdr.detectChanges();
  }

  enlargedImage: string | null = null;
  enlargeImage(imagePath: string): void {
      this.enlargedImage = imagePath;
  }

  closeEnlargedView(): void {
      this.enlargedImage = null;
  }

  ngOnDestroy(): void {
    $(".carousel_2").owlCarousel('destroy');
  }

  slideImage(direction: string): void {
    if (direction === 'left') {
      this.currentIndex = (this.currentIndex > 0) ? this.currentIndex - 1 : this.images.length - 1;
    } else if (direction === 'right') {
      this.currentIndex = (this.currentIndex < this.images.length - 1) ? this.currentIndex + 1 : 0;
    }
    this.enlargedImage = this.images[this.currentIndex].image;
    this.cdr.detectChanges();
  }
  
}