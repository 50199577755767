import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { HomeComponent } from './Core/home/home.component';
import { AuthGuard } from './Auth/auth.guard';
import { Constants, navConstants } from './shared/constants/constant';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: `${Constants.PrivacyPolicy}`, loadChildren: () => import('./Components/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule) },
  { path: `${Constants.Wishlist}`, loadChildren: () => import('./Core/wishlist/wishlist.module').then(m => m.WishlistModule) },
  { path: `${Constants.userNotification}`, loadChildren: () => import('./Components/user-notification/user-notification.module').then(m => m.UserNotificationModule) },
  { path: `${Constants.contactUs}`, loadChildren: () => import('./Components/contact-us/contact-us.module').then(m => m.ContactUsModule) },
  { path: `${Constants.aboutUs}`, loadChildren: () => import('./Components/about-us/about-us.module').then(m => m.AboutUsModule) },
  { path: `${Constants.adminPanelRoute}`, loadChildren: () => import('./Components/control-panel/control-panel.module').then(m => m.ControlPanelModule) },
  { path: `${Constants.adminPanelRoute}/${Constants.activities}/:activities`, loadChildren: () => import('./Components/activities/activities.module').then(m => m.ActivitiesModule) },
  { path: `${Constants.adminPanelRoute}/${Constants.reports}/:reports`, loadChildren: () => import('./Components/reports/reports.module').then(m => m.ReportsModule) },
  { path: `${Constants.adminPanelRoute}/${Constants.AccountVerification}`, loadChildren: () => import('./Components/account-verification/account-verification.module').then(m => m.AccountVerificationModule) },
  { path: `${Constants.adminPanelRoute}/${Constants.ExceptionLogs}`, loadChildren: () => import('./Components/excetion-logs/excetion-logs.module').then(m => m.ExcetionLogsModule) },
  { path: `${Constants.adminPanelRoute}/${Constants.Notification}`, loadChildren: () => import('./Components/notification/notification.module').then(m => m.NotificationModule) },
  { path: `${Constants.adminPanelRoute}/${navConstants.pushNotification}`, loadChildren: () => import('./Components/push-notification/push-notification.module').then(m => m.PushNotificationModule) },
  { path: `${Constants.adminPanelRoute}/${Constants.profession}/:profession`, loadChildren: () => import('./Components/sub-professional-category/sub-professional-category.module').then(m => m.SubProfessionalCategoryModule) },
  { path: `${Constants.adminPanelRoute}/${Constants.userManagement}/:user-management`, loadChildren: () => import('./Components/role-managment/role-managment.module').then(m => m.RoleManagmentModule) },
  { path: `${Constants.adminPanelRoute}/${Constants.vendorManagement}/:vendor-management`, loadChildren: () => import('./Components/vendor-managment/vendor-managment.module').then(m => m.VendorManagmentModule) },
  { path: `${Constants.adminPanelRoute}/${Constants.advertisement}/:advertisement`, loadChildren: () => import('./Components/create-advertisement/create-advertisement.module').then(m => m.CreateAdvertisementModule) },
  { path: `${Constants.adminPanelRoute}/${Constants.adminSitemap}`, loadChildren: () => import('./Components/admin-sitemap/admin-sitemap.module').then(m => m.AdminSitemapModule) },
  { path: `${Constants.adminPanelRoute}/${Constants.nearByServiceManagement}/:near-by-service-management`, loadChildren: () => import('./Components/near-by-service-management/near-by-service-management.module').then(m => m.NearByServiceManagementModule) },
  { path: `${Constants.adminPanelRoute}/${Constants.adminApproval}/:admin-approvals`, loadChildren: () => import('./Components/admin-approvals/admin-approvals.module').then(m => m.AdminApprovalsModule) },
  { path: `${Constants.referAFriend}`, loadChildren: () => import('./Components/refer-friend/refer-friend.module').then(m => m.ReferFriendModule) },
  { path: `${Constants.UnderConsruction}`, loadChildren: () => import('./Components/under-construction/under-construction.module').then(m => m.UnderConstructionModule) },
  { path: `${Constants.NoResultsFound}`, loadChildren: () => import('./Components/no-provider-results-found/no-provider-results-found.module').then(m => m.NoProviderResultsFoundModule) },
  { path: `${Constants.Profile}/:name-:id`, loadChildren: () => import('./Components/provider-profile/provider-profile.module').then(m => m.ProviderProfileModule) },
  { path: `${Constants.Rating}`, loadChildren: () => import('./Components/provider-rating/provider-rating.module').then(m => m.ProviderRatingModule), canActivate: [AuthGuard] },
  { path: `${Constants.Login}`, loadChildren: () => import('./Core/login/login.module').then(m => m.LoginModule) },
  { path: `${Constants.userGuide}`, loadChildren: () => import('./Components/support/support.module').then(m => m.SupportModule) },
  { path: `${Constants.Register}`, loadChildren: () => import('./Core/create-account/create-account.module').then(m => m.CreateAccountModule), canActivate: [AuthGuard] },
  { path: `${Constants.addService}`, loadChildren: () => import('./Core/add-service/add-service.module').then(m => m.AddServiceModule) },
  { path: `${Constants.TermsConditions}`, loadChildren: () => import('./Components/terms-conditions/terms-conditions.module').then(m => m.TermsConditionsModule) },
  { path: `${Constants.callLogs}`, loadChildren: () => import('./Components/call-logs/call-logs.module').then(m => m.CallLogsModule) },
  { path: `:parentCategory/:childCategory/:locality`, loadChildren: () => import('./Core/service-provider-list/service-provider-list.module').then(m => m.ServiceProviderListModule) },
  { path: `:parentCategory`, loadChildren: () => import('./Components/child-professions/child-professions.module').then(m => m.ChildProfessionsModule) },
  { path: `${Constants.NotFound}`, loadChildren: () => import('./Core/not-found/not-found.module').then(m => m.NotFoundModule) },
  { path: '**', redirectTo: `${Constants.NotFound}` },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }