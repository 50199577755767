<div (click)="closeRoleDropdown($event)">

    <div class="tasks_table1 ">
        <div class="row">
            <div class="col-md-3 search-box">

                <div class="dropdown">
                    <ng-select [items]="lstVendors" bindLabel="vendorName" placeholder="Select Vendor" #selectBoxs
                        (change)="changeUser($event)">
                    </ng-select>
                </div>
            </div>
            <div class="col-md-9 search-box1" style="padding: 8px;">
                <input type="text" id="search" class="form-control" autocomplete="off"
                    placeholder="Search with Member Id, Unique Id, Mobile, Masking Mobile Numbers, Service Names, and Vendor Name"
                    (blur)="restorePlaceholder()" [(ngModel)]="filterRoleassignSearch"
                    style="height: 38px;width: 100.4%;" (input)="searchroleasgnChange($event)">
            </div>

        </div>
    </div>
</div>
<table class="table table-striped">
    <thead class="tablehd-sec">
        <tr>
            <th style="width: 10%;" class="padleft-30" (click)="memberIdOnsort()">Member Id
                &nbsp;<span><i class="fa fa-long-arrow-up" style="margin: 0;"
                        [ngClass]="memberIdsort == 'up' ? 'black' : 'grey'"></i>
                    <i class="fa fa-long-arrow-down" style="margin: 0;"
                        [ngClass]="memberIdsort == 'down' ? 'black' : 'grey'"></i></span></th>
            <th style="width: 14%;" (click)="nameOnsort()">Name
                &nbsp;<span><i class="fa fa-long-arrow-up" style="margin: 0;"
                        [ngClass]="Namesort == 'up' ? 'black' : 'grey'"></i>
                    <i class="fa fa-long-arrow-down" style="margin: 0;"
                        [ngClass]="Namesort == 'down' ? 'black' : 'grey'"></i></span></th>
            <th style="width: 10%;" (click)="UniqueIdOnsort()">UniqueId
                &nbsp;<span><i class="fa fa-long-arrow-up" style="margin: 0;"
                        [ngClass]="UniqueIdsort == 'up' ? 'black' : 'grey'"></i>
                    <i class="fa fa-long-arrow-down" style="margin: 0;"
                        [ngClass]="UniqueIdsort == 'down' ? 'black' : 'grey'"></i></span></th>
            <th style="width: 12%;" (click)="mobileOnsort()">Mobile No
                &nbsp;<span><i class="fa fa-long-arrow-up" style="margin: 0;"
                        [ngClass]="mobilesort == 'up' ? 'black' : 'grey'"></i>
                    <i class="fa fa-long-arrow-down" style="margin: 0;"
                        [ngClass]="mobilesort == 'down' ? 'black' : 'grey'"></i></span></th>
            <th style="width: 18%;" (click)="emailOnsort()">Email
                &nbsp;<span><i class="fa fa-long-arrow-up" style="margin: 0;"
                        [ngClass]="emailsort == 'up' ? 'black' : 'grey'"></i>
                    <i class="fa fa-long-arrow-down" style="margin: 0;"
                        [ngClass]="emailsort == 'down' ? 'black' : 'grey'"></i></span></th>
            <th style="width: 18%;" (click)="vendorOnsort()">Vendor&nbsp;<span><i class="fa fa-long-arrow-up"
                        style="margin: 0;" [ngClass]="vendorsort == 'up' ? 'black' : 'grey'"></i>
                    <i class="fa fa-long-arrow-down" style="margin: 0;"
                        [ngClass]="vendorsort == 'down' ? 'black' : 'grey'"></i></span>
            </th>
            <th style="width: 18%;">
                Serivce
            </th>

        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let d of lstalluserVendors| paginate: { itemsPerPage: 10, currentPage: p};let i = index"
            class="tdata" style="color: #657376;">
            <td style="width: 10%;padding-left: 2%;" class="padleft-30">{{d.memberId}}</td>
            <td style="width: 14%;">{{d.Name}}</td>
            <td style="width: 10%;">{{d.UniqueId}}</td>
            <td style="width: 12%;">{{d.mobile| slice:0:10}}</td>
            <td style="width: 18%;">{{d.email}}</td>
            <td style="width: 18%;">
                <div class="row" *ngIf="isEditIndex!=i">
                    {{d.vendorName}}

                </div>
                <input type="text" class="form-control form-select dropbtn inpt dropdown-toggle" name="phone"
                    *ngIf="isEditIndex==i" [(ngModel)]="d.vendorName" (click)="getindex(d,i)" autocomplete="off"
                    style="border: 1px solid #898888;" placeholder="Select Vendor"
                    (input)="searchCategorychange($event,d)" />

                <ul class="dropdownshow" style="width: 18%;" *ngIf="id==d.memberId && isdisplay">
                    <li *ngFor="let f of lstVendors" (click)="selectedchangeRole(f,d)" class="list-group-item "
                        style="width: 78%;" data-bs-toggle="tooltip"
                        [ngClass]="{ 'selecteditem': d.vendorName == getuser(f.vendorName)}" data-bs-placement="top"
                        onclick="document.getElementById('assign').style.display='block'">
                        {{f.vendorName}}</li>
                </ul>
            </td>
            <td style="width: 18%;">
                <div class="row">
                    <div class="col-md-10 ">
                        <select class="wdth-100 form-control input-format form-select" (change)="removeOption($event,d)"
                            style="height: 41px;" *ngIf="d.splitServices.length>0">
                            <option value="">Choose Service</option>
                            <option *ngFor="let s of d.splitServices" [value]="s.name" class="hand"
                                onclick="document.getElementById('deleteservice').style.display='block'">
                                {{ s.name }}<span><i class="fa fa-trash-o" style="color: red;"></i></span>

                            </option>
                        </select>

                    </div>
                    <div class="col-md-2"><i class="fa fa-pencil bl" (click)="editrole(i)"></i></div>
                </div>
            </td>
        </tr>
    </tbody>
</table>
<div class="row">
    <div class="col">
        <div class="d-flex justify-content-center" *ngIf="lstalluserVendors.length!=0">
            <pagination-controls (pageChange)="pageChanged($event)" (pageBoundsCorrection)="pageChanged($event)">
            </pagination-controls>
        </div>
    </div>
</div>
<div id="assign" class="modal" style="display: block; overflow: hidden;" *ngIf="isassign">

    <form class="modal-content" action="/action_page.php" style="width: 35%;">
        <div class="container">
            <div class="modal-header" style="padding-top: 0;">
                <h5 class="modal-title" id="exampleModalLabel" style="margin-left: auto;">Confirm</h5>
                <button type="button" id="dismissModal" onclick="document.getElementById('assign').style.display='none'"
                    class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="roleCancel()"></button>
            </div>
            <!-- <h5>Confirmation <span onclick="document.getElementById('alluser').style.display='none'" class="close"
                        title="Close Modal" (click)="cancel()">×</span></h5> -->
            <div style="padding-top: 15px;
            padding-bottom: 15px; text-align: center;">
                <p>Are you sure, you want to vendor assign </p>
                <p><label *ngIf="selectedoldRole"> from <strong>{{selectedoldRole}}</strong></label> to
                    <strong>{{selectednewRole}}</strong>?</p>
            </div>
            <div class="modal-footer" style="padding-bottom: 0;">
                <button type="button" onclick="document.getElementById('assign').style.display='none'"
                    class="btn btn-danger footer-button ml-auto" data-bs-dismiss="modal"
                    style="width: 18%;margin-left: auto;" (click)="roleCancel()">Cancel</button>
                <button type="button" class="btn btn-primary footer-button mr-auto" (click)="vendorchange()"
                    style="width: 18%;margin-right: auto;">Ok</button>
            </div>
        </div>
    </form>
</div>