<div class="tasks_table1 ">
    <div class="row">
        <div class="col-md-12 search-box">
            <label style="width: 100%;"><span><input type="text" id="search"
                        class="custom-focus-input" autocomplete="off" placeholder="Search with Unique Id, Name, Profession, Mobile no. and Activity Date"
                        style="height: 37px;border: none;width: 100.3%;"
                        (input)="searchChange($event)"></span></label>
        </div>
    </div>

</div>
<table class="table table-striped">
    <thead>
        <tr>
            <th style="width: 15%;">Unique Id</th>
            <th style="width: 15%;">Name</th>
            <th style="width: 15%;">Mobile No.</th>
            <th style="width: 15%;">Profession</th>
            <th style="width: 20%;">Child Profession</th>
             <th style="width: 20%;">Activity Date</th> 
        </tr> 
    </thead>
    <tbody>
        <tr *ngFor="let d of lstActivities | paginate: { itemsPerPage: 20, currentPage: p};" class="tr">
            <td style="width: 15%;">{{d.uniqueId}}</td>
            <td style="width: 15%;">{{d.Name}}</td>
            <td style="width: 15%;">{{d.Mobile_No}}</td>
            <td style="width: 15%;">{{d.profession}}</td>
            <td style="width: 20%;">{{d.childProfession}}</td>
            <td style="width: 20%;">{{d.Activity_Date | date:'M/d/yyyy, h:mm:ss a'}}</td>
      </tr>
    </tbody>
</table>

<div class="row">
    <div class="col">

        <div class="d-flex justify-content-center" *ngIf="lstActivities.length!=0">
            <pagination-controls (pageChange)="pageChanged($event)"
                (pageBoundsCorrection)="pageChanged($event)">
            </pagination-controls>
        </div>

    </div>
</div>