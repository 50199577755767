import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Role } from 'src/app/shared/enums/role.enum';
import { RolePages } from 'src/app/shared/constants/roles.constant';
import { Constants, navConstants } from 'src/app/shared/constants/constant';
import { RouteMakingService } from 'src/app/shared/services/route-making.service';


@Component({
  selector: 'app-control-panel',
  templateUrl: './control-panel.component.html',
  styleUrls: ['./control-panel.component.css']
})
export class ControlPanelComponent implements OnInit {
  constructor(private spinner: NgxSpinnerService, private router: Router,private routeMakingService:RouteMakingService) { }
  pagevalid:boolean=false;
  lstcomponents: any = [];
  home:string=Constants.home;
  adminPanel:string=Constants.adminPanel;
  controlPanelRoute:string=Constants.controlPanelRoute;
  controlPanelTitle=Constants.controlPanelTitle;
  lsttempcomponents: any = [];
  searchInfo: string = "";
  submitted: boolean = false;

  ngOnInit(): void {

    const user = sessionStorage.getItem('user');
    if (user != null) {
      let data=JSON.parse(user);
      if (data.roleId >= Role.Admin && data.roleId <= Role.SeoTeam) {
        this.pagevalid = true;
        this.lstcomponents = RolePages[data.roleId];
        this.controlPanelTitle = data.roleName.toUpperCase() + ' PANEL';
      }
      else{
        this.router.navigate([""]);
      }
    } else {
      let params=[Constants.Login]
      let routingUrl=this.routeMakingService.getNavigateUrl(params)
      this.router.navigate([routingUrl]);
      return;
    }
  }
  gotoRoute(d) {
    var value = d.pagename;
    switch (value) {
      case "Activities": {
        this.router.navigate([this.routeMakingService.getNavigateUrl([Constants.adminPanelRoute,Constants.activities,navConstants.loginActivitiesNav])]);
        break;
      }
      case "Exception Logs": {
        this.router.navigate([this.routeMakingService.getNavigateUrl([Constants.adminPanelRoute,Constants.ExceptionLogs])]);
        break;
      }
      case "Notification": {
        this.router.navigate([this.routeMakingService.getNavigateUrl([Constants.adminPanelRoute,navConstants.pushNotification])]);
        break;
      }
      // case "Push Notification": {
      //   this.router.navigate(["/adminpanel/push-notification"]);
      //   break;
      // }
      case "Reports": {
        this.router.navigate([this.routeMakingService.getNavigateUrl([Constants.adminPanelRoute,Constants.reports,navConstants.userInformationNav])]);
        break;
      }
      case "Professions": {
        this.router.navigate([this.routeMakingService.getNavigateUrl([Constants.adminPanelRoute,Constants.profession,navConstants.assignProfessionNav])]);
        break;
      }
      case "Advertisement": {
        this.router.navigate([this.routeMakingService.getNavigateUrl([Constants.adminPanelRoute,Constants.advertisement,navConstants.postAdvertisementNav])]);
        break;
      }
      case "Account Verification": {
        this.router.navigate([this.routeMakingService.getNavigateUrl([Constants.adminPanelRoute,Constants.AccountVerification])]);
        break;
      }
      case "User Management": {
        this.router.navigate([this.routeMakingService.getNavigateUrl([Constants.adminPanelRoute,Constants.userManagement,navConstants.roleCreationNav])]);
        break;
      }
      case "Vendor Management": {
        this.router.navigate([this.routeMakingService.getNavigateUrl([Constants.adminPanelRoute,Constants.vendorManagement,navConstants.vendorCreationNav])]);
        break;
      }
      case "Near By Service Management": {
        this.router.navigate([this.routeMakingService.getNavigateUrl([Constants.adminPanelRoute,Constants.nearByServiceManagement,navConstants.nearByCategoryCreationNav])]);
        break;
      }
      case "Admin Approval": {
        this.router.navigate([this.routeMakingService.getNavigateUrl([Constants.adminPanelRoute,Constants.adminApproval,navConstants.mainProfessionApproval])]);
        break;
      }
      case "Sitemap": {
        this.router.navigate([this.routeMakingService.getNavigateUrl([Constants.adminPanelRoute,Constants.adminSitemap])]);
        break;
      }
    }
  }

  // search(event:any){

  //   this.submitted=true;
  //   if(this.searchInfo!="" && this.searchInfo!=null && this.searchInfo!=undefined)
  //   {
  //     this.lstcomponents=this.lsttempcomponents;
  //     var result=this.lstcomponents.filter((x:any)=> x.ErrorMessage.toLowerCase().includes(this.searchInfo.toLowerCase()));
  //     this.lstcomponents=result;
  //   }else{
  //     this.lstcomponents=this.lsttempcomponents;
  //   }

  // }


}
