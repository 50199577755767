import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Constants } from 'src/app/shared/constants/constant';

@Component({
  selector: 'app-bread-crumb',
  templateUrl: './bread-crumb.component.html',
  styleUrls: ['./bread-crumb.component.css']
})
export class BreadCrumbComponent implements OnInit {
  @Input() breadpath1: string;
  @Input() breadpath2: string;
  @Input() breadpath3: string;
  @Input() breadroute1: string;
  @Input() breadTitle: string;
  @Input() showBreadcrumb3: boolean = true; 
  
  constructor(private router: Router) { }

  ngOnInit(): void {
    if (this.breadpath1 === 'search') {
      this.breadroute1 = '/';
    } 
    if(this.breadroute1 == Constants.adminPanelRoute) {
      this.breadroute1 = '/' + this.breadroute1;
    }
  }

  homepage(): void {
   // console.log('this.breadroute1', this.breadroute1);
    if (this.breadroute1.includes('undefined')) {
      this.router.navigate(['/']);
    } else {
      this.router.navigate([this.breadroute1]).then(() => {
        window.location.reload();
      });
    }
  }

  home(): void {
    sessionStorage.setItem('searchData', '');
    this.router.navigate(['/']);
  }
}
