import { DatePipe, formatDate } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/shared/alert/alert.service';
import { ServerApisService } from 'src/app/shared/services/server-apis.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Role } from 'src/app/shared/enums/role.enum';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit {
  @ViewChild('selectBox') selectBox: NgSelectComponent;
  elementRef: ElementRef;
  p = 1;
  breadData1: string = "adminpanel";
  isMessageValid: boolean = false;
  breadData2: string = "notification";
  errorMessage:string="";
  isLimitExceeded:boolean=false;
  breadRouteData1: string = "/adminpanel"
  breadTitle:string="Notification"
  selectedCityIds: any = []
  selectedItems: any = [];
  isNotificationFormValid:boolean=false;
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '150px',
    minHeight: '0',
    maxHeight: 'auto',

    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter message here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    //  uploadUrl: 'v1/image',
    //  uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['insertImage',
        'insertVideo']
    ]
  };
  constructor(private router: Router, private formBuilder: FormBuilder, private _apiService: ServerApisService, private spinner: NgxSpinnerService,
    private _alertService: AlertService, private datePipe: DatePipe, private _elementRef: ElementRef, private renderer: Renderer2,private cd: ChangeDetectorRef) {
  
  }
  @ViewChild('editor', { static: true }) editor: Element
  notificationForm: FormGroup;
  lstUsers: any = [];
  lstSkills: any = [];
  lstTempSkills: any = [];
  lstOrganizations: any = [];
  lstTempOrganization: any = [];
  editorcontent: any;
  ismessage: boolean = false;
  messageEvent: string = '';
  lsttempusers: any[];
  isReciverTyp = '1';
  characterLimit = 1000;
  isAllUsers: string = "";
  isTableMessageValid:boolean=false;
  isImageMessageValid:boolean=false;
  selectedRadio: string = '';
  isdropdownshow: boolean = false;
  isnotificationTypeshow: boolean = false;
  notificationtype = "Text";
  lstSelectedUsers: any = [];
  lstCities: any = [];
  lstZipCodes: any = [];
  isFormValid: boolean = false;
  submitted = false;
  isLocation: boolean = false;
  lstCountry: any = [];
  lstState: any = [];
  minDate: any;
  public imagePath;
  extn: any;
  blob: any;
  filestream: any;
  ImgValidate: any;
  temprecever: string = "";
  imageChangedEvent: any = '';
  countryName: string = '';
  stateName: string = '';
  cityName: string = '';
  zipCodeid: number = 0;
  user: string = "select";
  reciverType: string = '1';
  selectedFile: File | null;
  ngOnInit(): void {
    const urlRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
    var date = new Date();
    this.minDate = this.datePipe.transform(date, "yyyy-MM-dd");
    const user = sessionStorage.getItem('user');
    if (user != null) {
      let data = JSON.parse(user);
      if (data.roleId === Role.Admin || data.roleId === Role.SubAdmin || data.roleId === Role.MarketingDM || data.roleId === Role.Manager) {
        this.notificationForm = this.formBuilder.group({
          title: ['', Validators.required],
          message: ['', [Validators.required, Validators.maxLength(1000)]],
          date: ['', Validators.required],
          userid: [0],
          image: ['', Validators.required],
          vediopath: ['', [Validators.required, Validators.pattern(urlRegex)]],
          country: [0],
          username: ["--Select--"],
          state: [0],
          city: [0],
          zipcode: ['']
        });
        this.reciverType = '1';
        this.getusers();
        this.getCountries();
       
      } else {
        this.router.navigate([""]);
      }

    } else {
      this.router.navigate(["/login"]);
    }
    
  }
  isValidRecieverType: boolean = false;
  changeLocation(event) {

    if (event.target.checked) {
      this.isLocation = true;
      this.selectedRadio = 'country';
      if (this.isReciverTyp == '2' || this.isReciverTyp == '3') {
        this.isValidRecieverType = true;
      }
    } else {
      this.isLocation = false;
      this.isValidRecieverType = false;
    }

  }
  changereciverType(event: any) {

    let val = event.target.value;
    this.isValidRecieverType = false;
    this.isReciverTyp = val;
    this.lstSelectedUsers = [];
    this.lstResult=[];
    this.temprecever = "";
    this.notificationForm.patchValue({ userid: -1 });
    this.notificationForm.patchValue({ username: '--Select--' });
    if (val == '1') {
      this.isLocation = false;
      this.getusers();
    } else if (val == '2') {
      this.getSkills();
      this.isLocation = false;
    } else if (val == '3') {
      this.getOrganizations();
      this.isLocation = false;
    } else if (val == '4') {
      this.isLocation = true;
      this.selectedRadio = 'country';
    }
this.onSubmit();
  }
  searchUserchange(event) {

    if (event.target.value != "" && event.target.value != null && event.target.value != undefined) {
      this.isdropdownshow = true;
      var result = [];

      if (this.isReciverTyp == '1') {
        result = this.lsttempusers.filter((x: any) => x.userId.toLowerCase().includes(event.target.value.toLowerCase()));
        this.lstUsers = result;
      } else
        if (this.isReciverTyp == '2') {
          result = this.lstTempSkills.filter((x: any) => x.subProfessionCategoryName.toLowerCase().includes(event.target.value.toLowerCase()));
          this.lstSkills = result;
        } else
          if (this.isReciverTyp == '3') {
            result = this.lstTempOrganization.filter((x: any) => x.organisationName.toLowerCase().includes(event.target.value.toLowerCase()));
            this.lstOrganizations = result;
          }

    } else {

      if (this.isReciverTyp == '1') {
        this.lstUsers = this.lsttempusers;
      } else
        if (this.isReciverTyp == '2') {
          this.lstSkills = this.lstTempSkills;
        } else
          if (this.isReciverTyp == '3') {
            this.lstOrganizations = this.lstTempOrganization;
          }
    }
  }
  getCountries() {
    this._apiService.GetCountries().subscribe(res => {
      this.lstCountry = res['GetAllCountriesResult'].resultCountries;
    });
  }
  getStates(countryId: any) {
    let payload = {
      country_id: countryId
    }
    this.spinner.show();

    this._apiService.GetStatesByCountry(payload).subscribe(res => {
      this.spinner.hide();


      this.lstState = res.resultStates;

    });
  }
  getCities(stateId: any) {
    let payload = {
      state_id: stateId
    }
    this.spinner.show();

    this._apiService.GetCitiesByState(payload).subscribe(res => {
      this.spinner.hide();

      this.lstCities = res.resultCities;
    });
  }
  getZipcodes(cityId: any) {
    let payload = {
      city_id: cityId
    }
    this.spinner.show();
    this._apiService.GetZipcodeByCities(payload).subscribe(res => {
      this.spinner.hide();

      this.lstZipCodes = res.resultZipcodes;
    });
  }
  selectCountryChange(event) {

    this.changeSelectCountry(event.target.value);
  }
  changeSelectCountry(event: any) {

    if (event != undefined) {
      this.notificationForm.patchValue({ country: event.country_id });
      this.countryName = event.country_name;
      this.lstState = [];
      this.getStates(event.country_id)
    } else {
      this.notificationForm.patchValue({ country: 0 });
      this.lstState = [];
      this.lstCities = [];
      this.lstZipCodes = [];
    }

  }
  changeSelectState(event: any) {
    if (event != undefined) {
      this.notificationForm.patchValue({ state: event.state_id });
      this.stateName = event.state_name;
      this.lstCities = [];
      this.getCities(event.state_id)
    } else {
      this.notificationForm.patchValue({ state: 0 });
      this.lstCities = [];
      this.lstZipCodes = [];
    }
  }
  changeSelectCity(event: any) {
    if (event != undefined) {
      this.notificationForm.patchValue({ city: event.city_id });
      this.cityName = event.city_name;
      this.lstZipCodes = [];
      this.getZipcodes(event.city_id)
    } else {
      this.notificationForm.patchValue({ city: 0 });
      this.lstZipCodes = [];
    }


  }
  changeSelectZipcode(event: any) {
    if (event != undefined) {
      this.notificationForm.patchValue({ zipcode: event.zipcode });
      this.zipCodeid = event.zipcode_id;
    } else {
      this.notificationForm.patchValue({ zipcode: '' });
    }

  }
  isTiltleValid: boolean = false;
  titleChange(event: any) {
    if (event.target.value.length >= 250) {
      this.isTiltleValid = true;
    } else {
      this.isTiltleValid = false;
    }
    this.onSubmit();
  }
  get f() { return this.notificationForm.controls; }
  pageChanged(event: any) {
    this.p = event;
  }
  payloadRequest:any;
  onSubmit() {
    this.isLimitExceeded=false;
    this.temprecever = this.notificationForm.controls['username'].value
   
    var senderid = sessionStorage.getItem('memberId');
    const payload: any = {};
    payload.title = this.notificationForm.controls['title'].value;

    payload.message = this.notificationForm.controls['message'].value?.toString();
    if (payload.message == '') {
      this.ismessage = true;
    } else {
      this.ismessage = false;
    }

    //payload.receiverId=this.notificationForm.controls['userid'].value;
    payload.senderId = senderid;
    payload.countryId = (+this.notificationForm.controls['country'].value);
    payload.stateId = (+this.notificationForm.controls['state'].value);
    payload.cityId = (+this.notificationForm.controls['city'].value);
    payload.zipCode = this.notificationForm.controls['zipcode'].value;
    payload.expiryDate = this.notificationForm.controls['date'].value;
    payload.notificationType = this.notificationtype;
    payload.countryName = this.countryName;
    payload.stateName = this.stateName;
    payload.receiverType = "";
    payload.cityName = this.cityName;
    payload.zipCodeId = (+this.zipCodeid);
    payload.receiverId = "";
    payload.receiverTypeSkills = "";
    payload.receiverTypeOrgName = "";

    if (this.lstSelectedUsers.length > 0) {
      for (var i = 0; i < this.lstSelectedUsers.length; i++) {

        if (this.isReciverTyp == '1') {
          payload.receiverType = "Client";
          if (payload.receiverId == "") {
            payload.receiverId = this.lstSelectedUsers[i].userid
          } else {
            payload.receiverId = payload.receiverId + ',' + this.lstSelectedUsers[i].userid;
          }
        }
        if (this.isReciverTyp == '2') {
          payload.receiverId = 0;
          payload.receiverType = "Skills";
          if (this.lstSelectedUsers[i].username == 'ALL') {
            payload.receiverTypeSkills = "";
            break;
          } else
            if (payload.receiverTypeSkills == "") {
              payload.receiverTypeSkills = this.lstSelectedUsers[i].username
            } else {
              payload.receiverTypeSkills = payload.receiverTypeSkills + ',' + this.lstSelectedUsers[i].username;
            }
        }
        if (this.isReciverTyp == '3') {
          payload.receiverId = 0;
          payload.receiverType = "Organization";
          if (this.lstSelectedUsers[i].username == 'ALL') {
            payload.receiverTypeOrgName = "";
            break;
          } else
            if (payload.receiverTypeOrgName == "") {
              payload.receiverTypeOrgName = this.lstSelectedUsers[i].username
            } else {
              payload.receiverTypeOrgName = payload.receiverTypeOrgName + ',' + this.lstSelectedUsers[i].username;
            }
        }

      }
    }
 

    if (payload.title != '' && payload.message != '' && payload.expiryDate != '' && payload.expiryDate!=null && payload.title != null && payload.message != null) {
      this.isFormValid = true;

      var date = new Date();
      var min: any = date.getMinutes();
      if (min.toString().length == 1) {

        min = '0' + min;
      }
      var hrs: any = date.getHours();
      if (hrs.toString().length == 1) {

        hrs = '0' + hrs;
      }
      var time = hrs + ':' + min;
      payload.expiryDate = payload.expiryDate + 'T' + time + 'Z';
    } else {
      this.isFormValid = false;
    }
    if (this.isFormValid == true) {
      if (this.notificationtype == 'ImageAndVideo') {
        payload.imageName = this.notificationForm.controls['image'].value;

        payload.videoUrlPath = this.notificationForm.controls['vediopath'].value;

        if (payload.imageName != '' && payload.videoUrlPath != '' && this.notificationForm.controls['vediopath'].status != "INVALID") {
          this.isFormValid = true;
        } else {
          this.isFormValid = false;
        }
      } else if (this.notificationtype == 'Image') {
        payload.imageName = this.notificationForm.controls['image'].value;
        payload.videoUrlPath = '';
        if (payload.imageName != '') {
          this.isFormValid = true;
        } else {
          this.isFormValid = false;
        }
      } else if (this.notificationtype == 'Video') {
        payload.imageName = '';
        payload.videoUrlPath = this.notificationForm.controls['vediopath'].value;
        this.blob = '';
        if (payload.videoUrlPath != '' && this.notificationForm.controls['vediopath'].status != "INVALID") {
          this.isFormValid = true;
        } else {
          this.isFormValid = false;
        }
      } else if (this.notificationtype == 'Text') {
        payload.imageName = '';
        payload.videoUrlPath = '';
        this.blob = '';
      }

    }
if (this.isReciverTyp == '4') {
  this.temprecever = '';
  payload.receiverId = 0;
  payload.receiverType = "Location";
}else{
  if(this.lstResult.length==0){
    this.isFormValid = false;
  }
}
    if (this.isFormValid && !this.isMessageValid && !this.isTiltleValid && !this.isTableMessageValid) {
      payload.message = this.messageEvent;

      payload.receiverId = payload.receiverId.toString();
      this.isNotificationFormValid=true;
     this.payloadRequest=payload;
    }else{
      this.isNotificationFormValid=false;
    }
  }

  sendNotifications(){
    this.onSubmit();
    if(this.isNotificationFormValid){
    
      this.submitted = true;
      this.ismessage = false;
      this.isNotificationFormValid=false;
      this._apiService.sendPublicPushNotification(this.payloadRequest, this.blob).subscribe(res => {
        
        console.log(res);
        this.submitted = false;
        this.ismessage = false;
        this.isNotificationFormValid=false;
      
        // if (res['Response'] == 'Notification sent successfully!') {
         
        // } else {
        //   window.scrollTo(0, 0);
        //   this._alertService.warn(res['Response']);
        //   this.clearAll();
        // }
      },
        (error: any) => {
          
          if(error=='Payload size exceeds the limit (4 KB)'){
            this.isNotificationFormValid=true;
            this.isLimitExceeded=true;
          }else if(error=='sucess'){
            let sucessMessage="Notification has been successfully sent. Please allow up to 30 minutes for a response."
            this._alertService.success(sucessMessage);
            window.scrollTo(0, 0);
            this.clearAll();
          }
          
          // if (error.status === 400) {
          //   this.spinner.hide();
          // }
        }
      )
    //  if(this.errorMessage!=''){
     
    //  }
    
    }
   
  }
clearAll(){
  
  this.submitted = false;
  this.isFormValid = false;
  this.notificationtype = 'Text';
  this.lstUsers = this.lsttempusers;
  this.isReciverTyp = '1';
  this.isLocation = false;
  this.notificationForm.reset();
  this.notificationForm.patchValue({ zipcode: '' });
  this.notificationForm.patchValue({ country: 0 });
  this.notificationForm.patchValue({ state: 0 });
  this.notificationForm.patchValue({ city: 0 });
  this.countryName = '';
  this.stateName = '';
  this.cityName = '';
  this.zipCodeid = 0;
  this.temprecever = '--Select--';
  this.lstSelectedUsers = [];
  this.selectBox.clearModel();
}
  isMessageImageValid:boolean=false;
  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent): void {
    const clipboardData = event.clipboardData?.getData('text/html');

const strippedData = clipboardData ? clipboardData.replace(/<img.*?>/g, '') : '';
event.clipboardData?.setData('text/html', strippedData);
    
  }
  messageChange(event:any) {
  
    if (this.notificationForm.controls['message'].value != '' && this.notificationForm.controls['message'].value != null && this.notificationForm.controls['message'].value != undefined) {
      this.ismessage = false;
     
      this.messageEvent =event.target.innerHTML;
    } else {

      this.ismessage = true;
    }
    
    if(this.notificationForm.controls['message'].value.includes('<img')){
      let  messageValue = this.notificationForm.controls['message'].value.replace(/<img.*?>/g, '');
      this.messageEvent =messageValue;
      this.notificationForm.get('message').setValue(messageValue);
      //this.isImageMessageValid=true;
    }else{
      //this.isImageMessageValid=false;
    }
    if(this.notificationForm.controls['message'].value.includes('<table')){
      let  messageValue = this.notificationForm.controls['message'].value.replace(/<table\b[^>]*>[\s\S]*?<\/table>/gi, '');
      this.notificationForm.get('message').setValue(messageValue);
      this.isTableMessageValid=true;
    }else{
      this.isTableMessageValid=false;
    }
    let extractedText = this.extractTextContent(this.notificationForm.controls['message'].value);
   
      if (this.notificationForm.controls['message'].value != '' && this.notificationForm.controls['message'].value != null && 
      this.notificationForm.controls['message'].value != undefined && event.target.innerHTML.length >= 1500) {
        this.isMessageValid = true;
      } else {
        this.isMessageValid = false;
      }
    this.onSubmit();
  }
  extractTextContent(html:any)
  {
    
    const tempElement = this.renderer.createElement('div');
    this.renderer.setProperty(tempElement, 'innerHTML', html);
    return  tempElement.textContent || tempElement.innerText || '';
  }
  getusers() {
    this.spinner.show();
    this._apiService.GetAllUsers().subscribe(res => {
      this.spinner.hide();
      let arr = [{ memberId: 0, userId: 'ALL' }];

      let newArr = res['GetAllUsersDropDownValuesResult'].UserDropDownValues;

      for (var i = 0; i < newArr.length; i++) {
        arr.push(newArr[i]);
      }
      this.lstUsers = arr;
      this.lsttempusers = arr;
      this.getCountries();
    });
  }
  getSkills() {
    this.spinner.show();
    this._apiService.getAllSubProfessionCategory().subscribe(res => {
      this.spinner.hide();

      let arr = [{ subProfessionsCategoryId: 0, subProfessionCategoryName: 'ALL' }];

      let newArr = res.GetAllSubProfessionCategoryResult['resultSubProfessionCategory'];

      for (var i = 0; i < newArr.length; i++) {
        arr.push(newArr[i]);
      }
      this.lstSkills = arr;
      this.lstTempSkills = arr;
    });
  }
  getOrganizations() {
    this.spinner.show();
    this._apiService.FetchAllOrganisationNames().subscribe(res => {
      this.spinner.hide();

      let arr = [{ organisationId: 0, organisationName: 'ALL' }];

      let newArr = res['FetchAllOrganisationNamesResult'].resultFetchAllOrganisationNames;
      for (var i = 0; i < newArr.length; i++) {
        if (newArr[i]) {
          arr.push({ organisationId: arr.length + 1, organisationName: newArr[i].organisationName });
        }
      }

      this.lstOrganizations = arr;
      this.lstTempOrganization = arr;
    });
  }
  vedioURlChange(){
    this.onSubmit();
  }
  addAttachment(event: any): void {

    try {
      this.ImgValidate = true;
      if (this.ImgValidate === true) {
        this.imageChangedEvent = event;
        for (let i = 0; i < event.target.files.length; i++) {
          this.selectedFile = event.target.files[i];
          var ext = '.png';
          var reader = new FileReader();
          this.imagePath = event.target.files;

          this.notificationForm.patchValue({ image: this.imagePath[0].name });

          reader.readAsDataURL(event.target.files[i]);

          reader.onload = (_event) => {

            var img: any = reader.result;
            fetch(img)
              .then(res => res.blob())
              .then(blob => {
                const fd = new FormData();
                fd.append('image', blob, 'filename');
                this.extn = ext;
                this.blob = blob;
                // this.userInfo.imagePath = this.imgURL;
                // this.image=this.imgURL;
                // this.imgURL = '';
                let payLoad: any = {
                  memberId: sessionStorage.getItem('memberId'),
                  organisationImageName: 'bussiness_file',
                  fileType: this.extn,

                }

              });
          }
        }
      } else {
        this._alertService.error(this.ImgValidate)
      }
    } catch (error) {
      throw error;
    }
this.onSubmit();
  }
  getuser(event) {
    if (this.lstSelectedUsers.length > 0) {
      var result = this.lstSelectedUsers.filter((x: any) => x.userid == event);
      if (result.length > 0) {
        return result[0].userid;
      } else {
        return -2;
      }
    } else {
      return -1;
    }
  }
  dateChange(){
    this.onSubmit();
  }

  changeUser(value: any) {
    this.lstResult = value;
    if (value.length > 0) {
      this.isdropdownshow = false;
      this.isAllUsers = "";
      if (this.isReciverTyp == '1') {
        if (value[value.length - 1].userId == 'ALL') {
          this.isAllUsers = "visible";
        } else {
          var result = value.filter((x: any) => x.userId == 'ALL')
          if (result.length > 0) {
            const secondSelectedValue = this.selectBox.selectedItems;
            this.selectBox.unselect(secondSelectedValue[0]);
          } else {
            this.lstSelectedUsers = [];
            for (var i = 0; i < value.length; i++) {
              this.lstSelectedUsers.push({ userid: value[i].memberId, username: value[i].userId });
            }

          }

        }

      }
      if (this.isReciverTyp == '2') {
        if (value[value.length - 1].subProfessionCategoryName == 'ALL') {
          this.isAllUsers = "visible";
        } else {
          var result = value.filter((x: any) => x.subProfessionCategoryName == 'ALL')
          if (result.length > 0) {
            const secondSelectedValue = this.selectBox.selectedItems;
            this.selectBox.unselect(secondSelectedValue[0]);
          } else {
            this.lstSelectedUsers = [];
            for (var i = 0; i < value.length; i++) {
              this.lstSelectedUsers.push({ userid: value[i].subProfessionsCategoryId, username: value[i].subProfessionCategoryName });
            }

          }

        }

      }
      if (this.isReciverTyp == '3') {
        if (value[value.length - 1].organisationName == 'ALL') {
          this.isAllUsers = "visible";
        } else {
          var result = value.filter((x: any) => x.organisationName == 'ALL')
          if (result.length > 0) {
            const secondSelectedValue = this.selectBox.selectedItems;
            this.selectBox.unselect(secondSelectedValue[0]);
          } else {
            this.lstSelectedUsers = [];
            for (var i = 0; i < value.length; i++) {
              this.lstSelectedUsers.push({ userid: value[i].organisationId, username: value[i].organisationName });
            }

          }

        }

      }


    } else {
      this.lstSelectedUsers = [];
    }
this.onSubmit();

  }
  pushSelectedUsers(id, username) {

    if (this.lstSelectedUsers.length > 0) {
      var result = this.lstSelectedUsers.filter((x: any) => x.username == username);
      if (result.length == 0) {
        var result2 = this.lstSelectedUsers.filter((x: any) => x.username == 'ALL');
        if (result2.length > 0) {
        } else {
          this.lstSelectedUsers.push({ userid: id, username: username });
        }
      }
    } else {
      this.lstSelectedUsers.push({ userid: id, username: username });
    }

  }
  lstResult: any = [];
  sendAll(event: any) {
    this.isAllUsers = "";
    if (event == 'invisible') {
      const secondSelectedValue = this.selectBox.selectedItems;
      this.selectBox.unselect(secondSelectedValue[secondSelectedValue.length - 1]);

    } else if (event == 'visible') {

      const secondSelectedValue = this.selectBox.selectedItems;
      for (var i = 0; i < secondSelectedValue.length; i++) {
        if (secondSelectedValue[i].label != 'ALL') {
          this.selectBox.unselect(secondSelectedValue[i]);
        }
      }
      this.lstSelectedUsers = [];
      this.lstSelectedUsers.push({ userid: 0, username: 'ALL' });
      this.isAllUsers = 'invisible';
    }
  }

  cancel() {
    this.isAllUsers = "";
    document.getElementById('alluser').click();
    this.notificationForm.patchValue({ userid: -1 });
    this.notificationForm.patchValue({ username: '--Select--' });
  }
  ok() {
    this.isAllUsers = "";
    this.notificationForm.patchValue({ userid: 0 });
    this.notificationForm.patchValue({ username: 'ALL' });
  }
  changeUserAll(value: any) {
    this.isdropdownshow = false;
    this.notificationForm.patchValue({ userid: -1 });
    this.notificationForm.patchValue({ username: '--Select--' });
    this.temprecever = value.username;
    this.lstSelectedUsers = [];
  }
  dropdownshow() {

    if (this.isdropdownshow == false) {
      this.isdropdownshow = true;
      if (this.notificationForm.controls['username'].value == '--Select--') {
        this.notificationForm.patchValue({ username: '' });
      }
      if (this.isReciverTyp == '1') {
        this.lstUsers = this.lsttempusers;
      } else
        if (this.isReciverTyp == '2') {
          this.lstSkills = this.lstTempSkills;
        } else
          if (this.isReciverTyp == '3') {
            this.lstOrganizations = this.lstTempOrganization;
          }
    } else {

      this.isdropdownshow = false;
      this.notificationForm.patchValue({ userid: -1 });
      this.notificationForm.patchValue({ username: '--Select--' });
    }
  }
  notificationTypeshow() {
    if (this.isnotificationTypeshow == false) {
      this.isnotificationTypeshow = true;

    } else {
      this.isnotificationTypeshow = false;
    }
  }

  OnlyLettersAllowed(event): boolean {
    const charCode = (event.which) ? event.which : event.keycode;

    if ((charCode < 97 || charCode > 123) && (charCode < 65 || charCode > 90) && charCode != 32) {
      return false;
    }
    return true
  }

  changeNotification(event) {
    this.notificationtype = event.target.value;
    this.notificationForm.patchValue({ image: '' });
    this.notificationForm.patchValue({ vediopath: '' });
    this.blob = '';
    this.onSubmit();
  }
  removeUser(i) {
    this.lstSelectedUsers.splice(i, 1);
    if (this.lstSelectedUsers.length == 0) {
      this.notificationForm.patchValue({ userid: -1 });
      this.notificationForm.patchValue({ username: '--Select--' });
      this.temprecever = '--Select--';
    }
  }

  changeSelection(event: any) {
    this.notificationForm.patchValue({ zipcode: '' });
    this.notificationForm.patchValue({ country: 0 });
    this.notificationForm.patchValue({ state: 0 });
    this.notificationForm.patchValue({ city: 0 });
    this.countryName = '';
    this.stateName = '';
    this.cityName = '';
    this.zipCodeid = 0;
    if (event.target.value != '') {
      this.selectedRadio = event.target.value;
    } else {
      this.selectedRadio = '';
    }
  }

  closeDropdown() {
    const ngSelectComponent = this.elementRef.nativeElement.querySelector('ng-select')?.ngSelect;
    if (ngSelectComponent) {
      ngSelectComponent.close();
    }
  }
}
