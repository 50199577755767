import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ChildProfessionsRoutingModule } from './child-professions-routing.module';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ChildProfessionsRoutingModule
  ]
})
export class ChildProfessionsModule { }
