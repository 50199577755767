import { Injectable } from '@angular/core';
import { Constants } from '../constants/constant';
@Injectable({
  providedIn: 'root'
})
export class StaticRoutesService {

  constructor() { }
  
  getStaticRoutes() {
    return [
      `/${Constants.PrivacyPolicy}`,
      `/${Constants.Wishlist}`,
      `/${Constants.contactUs}`,
      `/${Constants.aboutUs}`,
      `/${Constants.adminPanelRoute}/${Constants.activities}`,
      `/${Constants.adminPanelRoute}/${Constants.AccountVerification}`,
      `/${Constants.adminPanelRoute}/${Constants.ExceptionLogs}`,
      `/${Constants.adminPanelRoute}/${Constants.Notification}`,
      `/${Constants.adminPanelRoute}/${Constants.nearByServiceManagement}`,
      `/${Constants.adminPanelRoute}/${Constants.adminSitemap}`,
      `/${Constants.adminPanelRoute}`,
      `/${Constants.referAFriend}`,
      `/${Constants.UnderConsruction}`,
      `/${Constants.NoResultsFound}`,
      `/${Constants.Profile}`,
      `/${Constants.Rating}`,
      `/${Constants.Login}`,
      `/${Constants.userGuide}`,
      `/${Constants.Register}`,
      `/${Constants.addService}`,
      `/${Constants.TermsConditions}`,
      `/${Constants.callLogs}`,
      `/${Constants.NotFound}`,
      `/${Constants.userNotification}`
    ];
  }
}
