import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AlertService } from 'src/app/shared/alert/alert.service';
import { Router } from '@angular/router';
import { ServerApisService } from 'src/app/shared/services/server-apis.service';
import { Constants,navConstants } from 'src/app/shared/constants/constant';
import { RouteMakingService } from 'src/app/shared/services/route-making.service';

@Component({
  selector: 'app-near-by-caregory-creation-service-management',
  templateUrl: './near-by-caregory-creation-service-management.component.html',
  styleUrls: ['./near-by-caregory-creation-service-management.component.css']
})
export class NearByCaregoryCreationServiceManagementComponent {
  allNearByServices: any = [];
  allNearBySubServices: any = [];
  allNearByServicesTemp: any = [];
  allNearBySubServicesTemp: any = [];
  newServiceCategory: string = '';
  serviceIdToDelete: number;
  newSubServiceKeyword: string = '';
  subServiceIdToDelete: number;
  newSubServiceUrl: string = '';
  mainServiceId:number;
  page = 'category';
  placeholderText = 'Search by service category';
  p = 1;
  memberID: number = Number(sessionStorage.getItem('memberId'));
  token: string = sessionStorage.getItem('token');
  selectedServiceName: string = '';
  selectedSubServiceKeyword: string = '';
  selectedCategoryId: number = 0;
  selectedSubCategoryId: number = 0;
  modal = 'updateModal';

  constructor(private _apiService: ServerApisService,private router: Router,private routeMakingService:RouteMakingService, private _alertService: AlertService,
    private cdr: ChangeDetectorRef) { 
       this.fetchAllNearByServices();
 }

getMainCategoryName(categoryId: number): string {
  const mainCategory = this.allNearByServices.find(service => service.nearByServiceCategoryId === categoryId);
  return mainCategory ? mainCategory.nearByServiceCategory : '';
}

addpop(){
  this.modal = 'addModal';
  this.newServiceCategory = '';
}

updatepop(data){
  this.selectedServiceName = data.nearByServiceCategory;
  this.selectedCategoryId = data.nearByServiceCategoryId;
  this.newServiceCategory = data.nearByServiceCategory;
}
searchMainCategory(event: any) {
  if (event.target.value != '' && event.target.value != undefined) {
    var result = this.allNearByServices.filter((x: any) => x.nearByServiceCategory.toString().toLowerCase().includes(event.target.value.toLowerCase()));
    this.allNearByServices = result;
  } else {
    this.allNearByServices = this.allNearByServicesTemp;
  }
}
fetchAllNearByServices(): void {
  this._apiService.GetAllNearByServices().subscribe(res => {
    let result: any = res;
    if (result.FetchAllNearByCategoryResult.status == 200) {
      this.allNearByServices = result.FetchAllNearByCategoryResult.resultFetchNearByServicesCategory;
      this.allNearByServicesTemp = result.FetchAllNearByCategoryResult.resultFetchNearByServicesCategory;
    } 
  });
}
selectedService(service): void {
  this.selectedServiceName = service.nearByServiceCategory;
  this.selectedCategoryId = service.nearByServiceCategoryId;
}


addNearByService(): void {
  if (!this.newServiceCategory) {
    this._alertService.error("Service Category is required");
    return;
  }

  const requestBody = {
    nearByServiceCategory: this.newServiceCategory,
    nearByServiceCategoryId: 0,
    createdBy: this.memberID
  };

  this._apiService.InsertUpdateNearByCategory(requestBody).subscribe(res => {
    let result: any = res;
    if (result.status == 200) {
      if(result.result == "Near by service category already exists"){
        this._alertService.error(result.result);
      } else {
        this._alertService.success(result.result)
      }
      this.fetchAllNearByServices();
    } else {
      this._alertService.error("Error occurs while adding near by service");
    }
  });
}

updateNearByService(): void {
  if (!this.newServiceCategory) {
    this._alertService.error("Service Category is required");
    return;
  }

  const requestBody = {
    nearByServiceCategory: this.newServiceCategory,
    nearByServiceCategoryId: this.selectedCategoryId,
    createdBy: this.memberID
  };

  this._apiService.InsertUpdateNearByCategory(requestBody).subscribe(res => {
    let result: any = res;
    if (result.status == 200) {
      if(result.result == "Near by service category already exists"){
        this._alertService.error(result.result);
      } else {
        this._alertService.success(result.result)
      }
      this.fetchAllNearByServices();
    } else {
      this._alertService.error("Error occurs while adding near by service");
    }
  });
}

deleteNearByService(categoryId): void {
  if (!categoryId) {
    this._alertService.error("Please provide a valid Service ID");
    return;
  }

  const deleteParams = {
    nearByServiceCategoryId: categoryId,
    deletedBy: this.memberID
  };

  this._apiService.DeleteNearByCategory(deleteParams).subscribe(res => {
    let result: any = res;
    if (result.DeleteNearByCategoryResult.status == 200) {
      this._alertService.success(result.DeleteNearByCategoryResult.result);
      this.fetchAllNearByServices();
    } 
  });
  this.cdr.detectChanges();
}
isEditIndex:number;
pageChanged(event: any) {
  this.p = event;
  this.isEditIndex=-1;
}
categoryNameOnsort(): void {
  this.allNearByServices.sort((a, b) => {
    if (a.nearByServiceCategory.toLowerCase() < b.nearByServiceCategory.toLowerCase()) {
      return -1;
    }
    if (a.nearByServiceCategory.toLowerCase() > b.nearByServiceCategory.toLowerCase()) {
      return 1;
    }
    return 0;
  });
}
}
