<div class="container-fluid">



    <div class="privacy-box">
        <div>
            <h1  class="privacy-sub-title" style="color:#383838">Legal Info & Our Privacy Policy explains :</h1>
            <ul class="privacy-list">
                <li>What information we gather/ from you (User / Service Provider) and how it benefits you</li>
                <li>How we maintain your privacy of your information and our data</li>
            </ul>
            <p>We, Toskie / Toskie India Pvt Ltd. Toskie product developers, marketing, and protects data and use the Toskie information for our research and development purpose of other app’s / services under the Toskie & Toskie India Pvt Ltd</p>
            <p>Toskie is built with a set of strong privacy principles in mind</p>
            <p>This policy does not apply to the practices of organizations that we do not own or to people that we do not employ or manage. <br>Personal Information will be kept confidential and will be used for our research, marketing, and strategic client analysis objectives and other internal business purposes only. We do not sell or rent Personal Information except that in case you are a customer of our search services through any of the Media, your Personal Information shall be shared with our subscribers/advertisers and you shall be deemed to have given consent to the same.</p>
                <ul class="privacy-list">
                    <li>User / Service Provider or</li>
                    <li>Product / Service of any particular subscriber / advertiser.</li>
                </ul>                                   
            <p >We will share Personal Information only under one or more of the following circumstances: - If we have your consent or deemed consent to do so -</p>
            <p >In furtherance of the confidentiality with which we treat Personal Information we have put in place appropriate physical, electronic, and managerial procedures to safeguard and secure the information we collect online.<br>We give you the ability to edit your account information and preferences at any time, including whether you want us to contact you about new services. To protect your privacy and security, we will also take reasonable steps to verify your identity before granting access or making corrections.</p>
            <h3 class="privacy-sub-title">How We Handle Your Data :</h3>
            <p >How we process your data, for better communication between user and client.
                How we process your data for safety, security, and integrity, and added more direct links to 	user settings, Help Center articles and how you can manage your information
                Better Communication With Businesses and Individual</p> 
                <h3 class="privacy-sub-title">Information We Collect :</h3>
            <p >Toskie gather personal information to operate, provide, improve, understand, customize, support,
                and market our/your skills Services, Toskie try to gather the required info when you install, access,
                or use our Services.<br>The types of information we receive and collect depend on how you use our Services. We require
                certain information to deliver our Services and without this we will not be able to provide our
                services to you. For example, you must provide your mobile phone number to create an account to
                use our Services.<br>Our Services have optional features which, if used by you, require us to collect additional information to provide such features. You will be notified of such collection, as appropriate. 
                If you choose not to provide the information needed to use a feature, you will be unable to use the feature. For example, you cannot share your location with your contacts if you do not permit us to collect your location data from your device. Permissions can be managed through your Settings menu on both Web, Android and iOS devices.
                </p>
                <h3 class="privacy-sub-title">Deletion of personal data & account :</h3>   
                <p>At Toskie, we may delete all your data from our website and
                    mobile app services upon receiving a deletion request sent to <strong>deleteaccount@toskie.com.</strong>
                    We will make reasonable efforts to complete the data deletion process within a
                    maximum of seven (7) days from the receipt of a valid request. Please note that
                    once your account is deleted, you will lose access to all Toskie services.</p>                    
        </div>
    </div>
    <div class="privacy-box">
            <div>
                <h3 class="privacy-sub-title">Information You Provide: </h3>
                <ul class="privacy-list">
                    <li >Your Account Information. You must provide your mobile phone number and basic information
                        (including a profile name of your choice) to create a Toskie account. If you don’t provide us with
                        this information, you will not be able to create an account to use our Services. You can add other
                        information to your account, such as a profile picture and &quot;about&quot; information.</li>
                    <li >Your Connections. You can use the contact upload feature and provide us, if permitted by applicable laws, with the phone numbers in your address book on a regular basis, including those of users of our Services and your other contacts. If any of your contacts aren’t yet using our Services, we’ll manage this information for you in a way that ensures those contacts cannot be identified by us. You can provide a group profile picture or description.</li>
                    <li >Customer Support and Other Communications. When you contact us for customer support or otherwise communicate with us, you may provide us with information related to your use of our Services, including copies of your messages, any other information you deem helpful, and how to contact you (e.g., an email address). For example, you may send us an email with information relating to app performance or other issues.</li>
                    <li >As you are sharing data with us as service provider, you are agreeing to provide your contact details to share with user ( who’s looking for your services )</li>
                </ul>                                   
            </div>
            <h3 class="privacy-sub-title">Information we share :</h3>
            <p >since this application primary objective is to promote, share & find nearest service provider as individual, it is necessary to share data such as: (mobile number, distance to the user( location services), abilities , intro message and reviews from the earlier users.)
                user can directly contact using (call, message and WhatsApp) contact method and service provider who could help the user to fix the issue/ query.
            </p>
            <div>
                <ul class="privacy-list">
                    <li  >Usage And Log Information. We collect information about your activity on our Services, like service-related, diagnostic, and performance information. This includes information about your activity (including how you use our Services, your Services settings, how you interact with others using our Services (including when you interact with a business), and the time, frequency, and duration of your activities and interactions), log files, and diagnostic, crash, website, and performance logs and reports. This also includes information about when you registered to use our Services; the features you use like our messaging, calling and status of the profile building.</li>
                    <li  >Location Information. We collect and use precise location information from your device with your permission when you choose to use location-related features, like when you decide to share your location with your contacts or view locations nearby or locations others have shared with you. There are certain settings relating to location-related information which you can find in your device settings or the in-app settings, such as location sharing. Even if you do not use our location-related features, we use IP addresses and other information like phone number area codes to estimate your general location (e.g., city and country). We also use your location information for diagnostics and troubleshooting purposes.</li>
                    <li  >User Reports. Just as you can report other users, other users or third parties may also choose to report to us your interactions and your messages with them or others on our Services; for example, to report possible violations of our Terms or policies. When a report is made, we collect information on both the reporting user and reported user. To find out more about what happens when a user report is made, please see Advanced Safety and Security Features.</li>
                    <li  >Third-Party Service Providers. We work with third-party service providers and other  to help us operate, provide, improve, understand, customize, support, and market our Services. For example, we work with them to distribute our apps; provide our technical and physical infrastructure, delivery, and other systems; provide engineering support, cybersecurity support, and operational support; supply location, map, and places information; process payments; help us understand how people use our Services; market our Services; help you connect with businesses using our Services; conduct surveys and research for us; ensure safety, security, and integrity; and help with customer service. These companies may provide us with information about you in certain circumstances; for example, app stores may provide us with reports to help us diagnose and fix service issues.</li>
                </ul>                                   
            </div>
    </div>
    <div class="privacy-box">
        <h3 class="privacy-sub-title" >Automatically Collected Information:</h3>
        
        <h3 class="privacy-sub-title">How We Use Information:</h3>
        <p>
            We use information we have (subject to choices you make and applicable law) to operate, provide,
            improve, understand, customize, support, and market our Services. Here&#39;s how our Services. We
            use information we have to operate and provide our Services, including providing customer
        </p>
        <p >support; completing purchases or transactions; improving, fixing, and customizing our Services;
            and connecting our Services with that you may use. We also use information we have to
            understand how people use our Services; evaluate and improve our Services; research, develop,
            and test new services and features; and conduct troubleshooting activities. We also use your
            information to respond to you when you contact us.</p>
        <h3 class="privacy-sub-title">Law, Our Rights, And Protection:</h3>         
        <p >We access, preserve, and share your information described in the "Information We Collect" section of this Privacy Policy above if we have a good-faith belief that it is necessary to: (a) respond pursuant to applicable law or regulations, legal process, or government requests; (b) enforce our Terms and any other applicable terms and policies, including for investigations of potential violations; (c) detect, investigate, prevent, or address fraud and other illegal activity or security, and technical issues; or (d) protect the rights, property, and safety of our users, Toskie, the other Spino , or others, including to prevent death or imminent bodily harm.</p>
        <h3 class="privacy-sub-title">Updates To Our Policy :</h3>
        <p >We may amend or update our Privacy Policy. We will provide you notice of amendments to this Privacy Policy, as appropriate, and update the “Last modified” date at the top of this Privacy Policy. Please review our Privacy Policy from time to time.</p>
        <p style="color:#383838; font-weight: bold;">Contact Us <br>
        If you have questions or issues about our Privacy Policy, please contact us<br>
        <span class="contact-privacy">Toskie India Pvt Ltd,</span><br>
        4th Floor, JVR Towers, Near Omni Hospital, Kothapet, Dilshuknagar, Hyderabad-500035, INDIA.</p>
    </div>

</div>