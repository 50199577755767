<div (click)="closeRoleDropdown($event)">
    <div class="row">
        <div class="tasks_table1">
            <div class="row">
                <div class="col-md-3 search-box">
                    <div class="dropdown">
                        <ng-select [items]="lstVendorsnumber" bindLabel="mobileMaskingNumber" placeholder="Select Masking Mobile Number" #selectBox 
        (change)="changeNumber($event)" >
      </ng-select>
             </div>
             </div>
                <div class="col-md-9 search-box1" style="padding: 8px;"> <input type="text" id="search" class="form-control"
                    autocomplete="off" [(ngModel)]="searchtext" [placeholder]="placeholderText"
                     style="height: 37px;width: 100.4%;"
                    (input)="searchVendorChange($event)">
                </div>
            </div>
            </div>
     </div>
</div>
<table class="table table-striped">
    <thead class="tablehd-sec">
        <tr>
            <th style="width: 20%;" class="padleft-30" (click)="vendorIdOnsort()">Vendor Id &nbsp;<span><i
                        class="fa fa-long-arrow-up" style="margin: 0;"
                        [ngClass]="vendorIdsort == 'up' ? 'black' : 'grey'"></i>
                    <i class="fa fa-long-arrow-down" style="margin: 0;"
                        [ngClass]="vendorIdsort == 'down' ? 'black' : 'grey'"></i></span></th>
            <th style="width: 25%;" (click)="vendorCodeOnsort()">Vendor Code
                &nbsp;<span><i class="fa fa-long-arrow-up" style="margin: 0;"
                        [ngClass]="vendorCodesort == 'up' ? 'black' : 'grey'"></i>
                    <i class="fa fa-long-arrow-down" style="margin: 0;"
                        [ngClass]="vendorCodesort == 'down' ? 'black' : 'grey'"></i></span></th>
            <th style="width: 25%;" (click)="vendorNameOnsort()">Vendor Name
                &nbsp;<span><i class="fa fa-long-arrow-up" style="margin: 0;"
                        [ngClass]="vendorNamesort == 'up' ? 'black' : 'grey'"></i>
                    <i class="fa fa-long-arrow-down" style="margin: 0;"
                        [ngClass]="vendorNamesort == 'down' ? 'black' : 'grey'"></i></span></th>
            <th style="width: 30%;">Masking Mobile Number</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let d of lstVendors| paginate: { itemsPerPage: 10, currentPage: p};let i = index"
            class="tdata">
            <td style="width: 20%;padding-left: 2%;" class="padleft-30">{{d.vendorId}}</td>
            <td style="width: 25%;">
                <p >{{d.vendorCode}}</p>

            </td>
            <td style="width: 25%;">
                <p >{{d.vendorName}}</p>

            </td>
            <td style="width: 30%;">
                <div class="row" *ngIf="isEditIndex!=i">
                    <div class="col-md-10">{{d.maskingMobileNumber}}</div>
                    <div class="col-md-2 mar-auto hand"><i class="fa fa-pencil bl" (click)="editnumber(i,d)"></i></div>
                </div>
                <div class="row" *ngIf="isEditIndex==i">
                    <div class="col-md-10">
                         <form  [formGroup]="vendorForm">
                        <ng-select [items]="lstassignvendor" *ngIf="isEditIndex==i" bindLabel="mobileMaskingNumber" placeholder="Select Masking Mobile Number" #selectBox 
                        (change)="selectedchangenumber($event,d)" (blur)="closeVendorNumber(d)"  formControlName="selectedUser"></ng-select>
                    </form>
                    </div>
                    <div class="col-md-2 mar-auto hand" style="visibility: hidden;"><i class="fa fa-close dangr" (click)="removenumber(i)"></i></div>
                </div>
               
      
            </td>
        </tr>
    </tbody>
</table>
<div class="row">
    <div class="col">
        <div class="d-flex justify-content-center" *ngIf="lstVendors.length!=0">
            <pagination-controls (pageChange)="pageChanged($event)"
                (pageBoundsCorrection)="pageChanged($event)">
            </pagination-controls>
        </div>
    </div>
</div>
<div id="numberassign" class="modal" style="display: block; overflow: hidden;" *ngIf="isnumberassign">

    <form class="modal-content" action="/action_page.php" style="width: 35%;">
        <div class="container">
            <div class="modal-header" style="padding-top: 0;">
                <h5 class="modal-title" id="exampleModalLabel" style="margin-left: auto;">Confirm</h5>
                <button type="button" id="dismissModal" onclick="document.getElementById('numberassign').style.display='none'"
                    class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="vendornumberCancel()"></button>
            </div>
           <div style="padding-top: 15px;
            padding-bottom: 15px; text-align: center;">
                <p>Are you sure, you want to vendor masking number assign </p>
                <p><label *ngIf="selectedoldNumber"> from <strong>{{selectedoldNumber}}</strong></label> to <strong>{{selectednewnumber}}</strong>?</p>
            </div>
            <div class="modal-footer" style="padding-bottom: 0;">
                <button type="button" onclick="document.getElementById('numberassign').style.display='none'"
                    class="btn btn-danger footer-button ml-auto" data-bs-dismiss="modal"
                    style="width: 18%;margin-left: auto;" (click)="vendornumberCancel()">Cancel</button>
                <button type="button" class="btn btn-primary footer-button mr-auto" (click)="vendornumberchange()"
                    style="width: 18%;margin-right: auto;">Ok</button>
            </div>
        </div>
    </form>
</div>