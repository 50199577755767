import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService } from 'src/app/shared/alert/alert.service';
import { Role } from 'src/app/shared/enums/role.enum';
import { ServerApisService } from 'src/app/shared/services/server-apis.service';
import { Constants,navConstants } from 'src/app/shared/constants/constant';
import { RouteMakingService } from 'src/app/shared/services/route-making.service';


@Component({
  selector: 'app-account-verification',
  templateUrl: './account-verification.component.html',
  styleUrls: ['./account-verification.component.css']
})
export class AccountVerificationComponent implements OnInit {

  constructor(private _apiService:ServerApisService,private spinner: NgxSpinnerService,private datePipe: DatePipe,
    private _alertService: AlertService,private router: Router,private routeMakingService:RouteMakingService) { }
  p = 1;
lstAccountVerificationDetails:any=[];
lsttemplstAccountVerificationDetails:any=[];
lstProfileDetails:any=[];
selectedindex:number;
searchTerm:string='';
selectmemberid:any;
prevselcectedIndex:number=-1;
prevSelectedType:string='';
adminPanel:string=Constants.adminPanel;
accountVerification:string=navConstants.accountVerification;
adminPanelRoute:string=Constants.adminPanelRoute;
accountVerificationTitle:string=Constants.accountVerificationTitle;
image: any = null;
imagetext: any;
isprofileData:boolean=false;
isaccountVerified:boolean=false;
isprofessionVerified:boolean=false;
isbusinessVerified:boolean=false;
profileData:any;
minDate :any;
ngOnInit(): void {
  const user = sessionStorage.getItem('user');
  if (user != null) {
    let data=JSON.parse(user);
    if (data.roleId === Role.Admin || data.roleId === Role.SalesTeam || data.roleId === Role.Manager) {
      let date = new Date();
      this.minDate = this.datePipe.transform(date, "yyyy-MM-dd");
      this.getAccountVerificationDetails();
    } else {
      this.router.navigate([""]);
    }
  } else{
    this.router.navigate([this.routeMakingService.getNavigateUrl([Constants.Login])]);
  }
  }
  getAccountVerificationDetails(){
    this.spinner.show();
    this.lstAccountVerificationDetails=[];
    this._apiService.GetFetchUserAccountVerification().subscribe(res => {
      this.spinner.hide();
        this.lstAccountVerificationDetails = res.FetchUserAccountVerificationResult['resultAccountVerification'];
        this.lsttemplstAccountVerificationDetails=this.lstAccountVerificationDetails;
        if(this.searchTerm!=""){
          this.searchChange(this.searchTerm);
        }
        var date= new Date();
    this.minDate=this.datePipe.transform(date,"yyyy-MM-dd")
    });
  }

  pageChanged(event) {
    this.p = event;
  }
  searchChange(event:any){
    if(event!='' && event!=undefined){
      var result= this.lsttemplstAccountVerificationDetails.filter((x:any)=>x.UniqueCode?.toLowerCase().includes(event.toLowerCase())
      || x.Name?.toLowerCase().includes(event.toLowerCase())
      || x.phone?.toLowerCase().includes(event.toLowerCase())
      || x.memberId?.toString().toLowerCase().includes(event.toLowerCase()))
      if(result.length>0){
        this.lstAccountVerificationDetails=result;
      }
    }else{
      this.lstAccountVerificationDetails=this.lsttemplstAccountVerificationDetails;
    }
  }

  getProfileDetails(data:any){
    this.spinner.show();
    this.lstProfileDetails=[];
    this.imagetext='';
    const payload:any ={};
    payload.memberId= data.memberId;
    this._apiService.GetFetchUserPrifileAccountVerification(payload).subscribe(res => {
      this.spinner.hide();
      this.isprofileData=true;
        this.profileData = res.resultVerificationDetails;
        this.image=this.profileData.profileImageUrl;
        this.imagetext=null;
    });
  }


  myImage() {
    this.image = null;
    if(this.profileData){
      let name=this.profileData.Name.replace(/\s/g, '')
      this.imagetext = name.charAt(0).toUpperCase();
    }
  }

  updateAccountVerification(payload:any){
    this.prevselcectedIndex=-1;
    this.prevSelectedType='';
    this.spinner.show();
    this._apiService.updateAccountVerifiacation(payload).subscribe(res=>{
      if(res.status==200){
        this._alertService.success(res['result']);
       
        this.isaccountVerified=false;
      this.isprofessionVerified=false;
      this.isbusinessVerified=false;
      
      this.getAccountVerificationDetails();
      }
    });
  }
  getClearPrevIndex(type:any,indx:any){
    
    if(type=='account'){
      this.lstAccountVerificationDetails[indx].accountVerified='N';
      this.lstAccountVerificationDetails[indx].accountVerifiedExpiry=null;
    }else if(type=='profession'){
      this.lstAccountVerificationDetails[indx].professionVerified='N';
      this.lstAccountVerificationDetails[indx].professionVerifiedExpiry=null;
    }else if(type=='business'){
      this.lstAccountVerificationDetails[indx].businessVerified='N';
      this.lstAccountVerificationDetails[indx].businessVerifiedExpiry=null;
    }
    //this.prevselcectedIndex=-1;
    //this.prevSelectedType='';
  }
  changeAccountVerified(event:any,i:any,data:any){
    
    if(event.target.checked){
      
      if(this.prevselcectedIndex>=0){
        this.getClearPrevIndex(this.prevSelectedType,this.prevselcectedIndex);
        const ind= this.lstAccountVerificationDetails.findIndex(x => x.memberId === data.memberId)
        this.prevselcectedIndex=ind;
        this.prevSelectedType='account';
      }else{
        const ind= this.lstAccountVerificationDetails.findIndex(x => x.memberId === data.memberId)
        this.prevselcectedIndex=ind;
        this.prevSelectedType='account';
      }
      for(var k=0;k<this.lstAccountVerificationDetails.length;k++){
        if(this.lstAccountVerificationDetails[k].memberId==data.memberId){
          this.lstAccountVerificationDetails[k].accountVerified='Y';
        }
      }
      //this.lstAccountVerificationDetails[i].accountVerified='Y';
      this.isaccountVerified=true;
      this.isprofessionVerified=false;
      this.isbusinessVerified=false;
      this.selectedindex=i;
      this.selectmemberid=data.memberId;
    }else{
      for(var k=0;k<this.lstAccountVerificationDetails.length;k++){
        if(this.lstAccountVerificationDetails[k].memberId==data.memberId)
        this.lstAccountVerificationDetails[k].accountVerified='N';
      this.lstAccountVerificationDetails[k].accountVerifiedExpiry=null;
      }
      this.lstAccountVerificationDetails[i].accountVerified='N';
      this.lstAccountVerificationDetails[i].accountVerifiedExpiry="";
      this.isaccountVerified=false;
      this.selectedindex=-1;
      this.selectmemberid='';
      this.disableSwitch(data);
    }
  }
  changeProfessionVerified(event:any,i:any,data:any){
    if(event.target.checked){
      if(this.prevselcectedIndex>=0){
        this.getClearPrevIndex(this.prevSelectedType,this.prevselcectedIndex);
        const ind= this.lstAccountVerificationDetails.findIndex(x => x.memberId === data.memberId)
        this.prevselcectedIndex=ind;
        this.prevSelectedType='profession';
      }else{
        const ind= this.lstAccountVerificationDetails.findIndex(x => x.memberId === data.memberId)
        this.prevselcectedIndex=ind;
        this.prevSelectedType='profession';
      }
      for(var k=0;k<this.lstAccountVerificationDetails.length;k++){
        if(this.lstAccountVerificationDetails[k].memberId==data.memberId){
          this.lstAccountVerificationDetails[k].professionVerified='Y';
        }
      }
      //this.lstAccountVerificationDetails[i].professionVerified='Y';
      this.isprofessionVerified=true;
      this.isaccountVerified=false;
      this.isbusinessVerified=false;
      this.selectedindex=i;
      this.selectmemberid=data.memberId;
    }else{
      for(var k=0;k<this.lstAccountVerificationDetails.length;k++){
        if(this.lstAccountVerificationDetails[k].memberId==data.memberId)
        this.lstAccountVerificationDetails[k].professionVerified='N';
      this.lstAccountVerificationDetails[k].professionVerifiedExpiry=null;
      }
      this.lstAccountVerificationDetails[i].professionVerified='N';
      this.lstAccountVerificationDetails[i].professionVerifiedExpiry=null;
      this.isprofessionVerified=false;
      this.selectedindex=-1;
      this.selectmemberid='';
      this.disableSwitch(data);
    }
  }
  changeBusinessVerified(event:any,i:any,data:any){
    if(event.target.checked){
      if(this.prevselcectedIndex>=0){
        this.getClearPrevIndex(this.prevSelectedType,this.prevselcectedIndex);
        const ind= this.lstAccountVerificationDetails.findIndex(x => x.memberId === data.memberId)
        this.prevselcectedIndex=ind;
        this.prevSelectedType='business';
      }else{
        const ind= this.lstAccountVerificationDetails.findIndex(x => x.memberId === data.memberId)
        this.prevselcectedIndex=ind;
        this.prevSelectedType='business';
      }
      for(var k=0;k<this.lstAccountVerificationDetails.length;k++){
        if(this.lstAccountVerificationDetails[k].memberId==data.memberId){
          this.lstAccountVerificationDetails[k].businessVerified='Y';
        }
      }
      //this.lstAccountVerificationDetails[i].businessVerified='Y';
      this.isbusinessVerified=true;
      this.isaccountVerified=false;
      this.isprofessionVerified=false;
      this.selectedindex=i;
      this.selectmemberid=data.memberId;
    }else{
      for(var k=0;k<this.lstAccountVerificationDetails.length;k++){
        if(this.lstAccountVerificationDetails[k].memberId==data.memberId)
        this.lstAccountVerificationDetails[k].businessVerified='N';
      this.lstAccountVerificationDetails[k].businessVerifiedExpiry=null;
      }
      
      this.isbusinessVerified=false;
      this.selectedindex=-1;
      this.selectmemberid='';
      this.disableSwitch(data);
    }
  }
  disableSwitch(data:any){
    
    const payload:any ={};
    payload.memberId= data.memberId;
    payload.verifiedBy =sessionStorage.getItem('memberId');
    payload.accountVerified=data.accountVerified;
    payload.professionVerified=data.professionVerified;
    payload.businessVerified=data.businessVerified;
    payload.accountVerifiedExpiry=data.accountVerifiedExpiry;
    payload.professionVerifiedExpiry=data.professionVerifiedExpiry;
    payload.businessVerifiedExpiry=data.businessVerifiedExpiry;
    this.updateAccountVerification(payload);
  }
  dateChange(event:any, data:any,type:any){
    
    const payload:any ={};
    payload.memberId= data.memberId;
    payload.verifiedBy =sessionStorage.getItem('memberId');
    payload.accountVerified=data.accountVerified;
    payload.professionVerified=data.professionVerified;
    payload.businessVerified=data.businessVerified;
    let date=this.minDate=this.datePipe.transform(event.target.value,"yyyy-MM-dd");
    if(type=='account'){
      payload.accountVerifiedExpiry=date;
      payload.professionVerifiedExpiry=data.professionVerifiedExpiry;
      payload.businessVerifiedExpiry=data.businessVerifiedExpiry;
      
    }else if(type=='profession'){
      payload.accountVerifiedExpiry=data.accountVerifiedExpiry;
      payload.professionVerifiedExpiry=date;
      payload.businessVerifiedExpiry=data.businessVerifiedExpiry;
    }else if(type=='bussiness'){
      payload.accountVerifiedExpiry=data.accountVerifiedExpiry;
      payload.professionVerifiedExpiry=data.professionVerifiedExpiry;
      payload.businessVerifiedExpiry=date;
    }

    this.updateAccountVerification(payload);
  }

  getmindate(accountVerifiedDate){
    if(accountVerifiedDate!='' && accountVerifiedDate!=null && accountVerifiedDate!=undefined){
      
    }
  }
}
