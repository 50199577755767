<div class="modal-overlay" (click)="closeModal()"></div>
<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <div class="share-modal">
                <h2 style="background-color: bisque; width: 100%;">Share Profile
                    <button class="close-button" (click)="closeModal()">
                        <i class="fas fa-times"></i>
                    </button>
                </h2>
                <div class="row modifiedRow">
                    <div class="col-6 col-sm-3">
                        <a [href]="getSocialMediaLink('whatsapp')" target="_blank" title="Share on WhatsApp">
                            <i class="fab fa-whatsapp"></i>
                        </a>
                    </div>
                    <div class="col-6 col-sm-3">
                        <a [href]="getSocialMediaLink('instagram')" target="_blank" title="Share on Instagram">
                            <i class="fab fa-instagram"></i>
                        </a>
                    </div>
                    <div class="col-6 col-sm-3">
                        <a [href]="getSocialMediaLink('linkedin')" target="_blank" title="Share on LinkedIn">
                            <i class="fab fa-linkedin"></i>
                        </a>
                    </div>
                    <div class="col-6 col-sm-3">
                        <a [href]="getSocialMediaLink('facebook')" target="_blank" title="Share on Facebook">
                            <i class="fab fa-facebook"></i>
                        </a>
                    </div>
                </div>
                <div class="row profile-link">
                    <div class="col-9 col-sm-10">
                        <input class="form-control" type="text" readonly [value]="profileUrl">
                    </div>
                    <div class="col-3 col-sm-2">
                        <button (click)="copyToClipboard()">
                            <i class="far fa-copy"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
