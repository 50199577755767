<div class="container mt-5" style="margin-top: 2rem!important;">
  <div class="row justify-content-center">
    <div class="col-md-6" style="margin-bottom: 20px">
      <div class="row">
        <div class="col" style="width: 130px;">
          <h1>Let’s Get Started</h1>
        </div>        
      </div>
     
      <form [formGroup]="createAccountForm" (ngSubmit)="onSubmit()">
        <div class="mb-3">        
          <label for="fullName" class="form-label form-controls">Full Name</label>
          <div class="input-group">
            
              <span class="input-group-text">
                <img src="../../../assets/images/User.png" class="img-fluid" alt="Location Image" title="location image">
              </span>
            
          <input type="text" class="form-control" style="width: 70%;" id="fullName" formControlName="fullName" required>
          <div *ngIf="createAccountForm.get('fullName').hasError('required') && createAccountForm.get('fullName').touched" class="text-danger">
            Full Name is required
          </div>   
        </div>                
        </div>
        <div class="mb-3">
          <label for="email" class="form-label form-controls">Email</label>
          <div class="input-group">
            <span class="input-group-text">
              <img src="../../../assets/images/user-email.png" class="img-fluid" alt="Location Image" title="Location Image">
            </span>
            <input type="email" class="form-control" style="width: 70%;" id="email" formControlName="email">
            <div *ngIf="createAccountForm.get('email').hasError('email') && createAccountForm.get('email').touched" class="text-danger">
              Invalid email format
            </div>
            
          </div>
        </div>
        <div class="mb-3">
          <label for="address" class="form-label form-controls">Address <span style="font-size: 10px;">(If you do not select location it will automatically takes current location)</span></label>
          <div class="input-group">
            <div class="input-group-append">
              <span class="input-group-text">
                <img src="../../../assets/images/location.png" class="img-fluid" alt="location icon" title="location icon">
              </span>
            </div>
            <app-autocomplete-google-places class="location-bar"
              [currentAddress]="localAddress" [maxLength]="maxLength"
              (setAddress)="getLocationAddressCoordinates($event)"></app-autocomplete-google-places>
          </div>
          <div *ngIf="createAccountForm.get('address').hasError('required')" class="text-danger">
            Address is required
          </div>
        </div>
        <div class="mb-3">
          <label for="promoCode" class="form-label form-controls">Enter Promocode</label>
          <input type="text" class="form-control" id="promoCode" formControlName="promoCode">
        </div>
        <div class="form-group" style="padding-left: 20px;">
          <label><input type="checkbox" class="form-check-input custom-checkbox" name="acceptTerms"  
            (change)="acceptedterms($event)" required />     
          <label for="acceptTerms" class="form-check-label"> &nbsp; By signing up, you agree to the
              <a routerLink="/terms-conditions" target="_blank">Terms and Conditions</a>.
          </label></label>

          <!-- <label *ngIf="submitted && !acceptTermschecked" class="invalidmesg">Please accept "Terms of Services".</label> -->
      </div>
      <div class="text-center">
        <button type="submit" class="btn btn-primary" [disabled]="!acceptTermschecked || createAccountForm.get('fullName').invalid">Submit</button>
        </div>
      </form>
   
    </div>
  </div>
</div>
