<!-- <link href="//netdna.bootstrapcdn.com/font-awesome/3.2.1/css/font-awesome.css" rel="stylesheet"> -->
<ul id="breadcrumb" *ngIf="breadroute1!=''">
  <li><a routerLink="/" (click)="home()"><i class="fa-solid fa-house"></i> </a></li>
  <li><a routerLink="{{breadroute1}}" (click)="homepage()"> {{breadpath1}}</a></li>
  <li ><a style="color: #fff; background: #38b6ff;" > {{breadpath2}}</a></li>
  <li *ngIf="showBreadcrumb3"><a style="color: #fff; background: #38b6ff;"> {{breadpath3}}</a></li>
</ul>

<ul id="breadcrumb" *ngIf="breadroute1==''">
    <li><a routerLink="/" (click)="home()"><i class="fa-solid fa-house"></i> </a></li>
    <li style="display: none;"><a routerLink="{{breadroute1}}"> {{breadpath1}}</a></li>
    <li><a style="color: #fff; background: #38b6ff;" > {{breadpath2}}</a></li>
  </ul>
  
  <span>{{breadTitle}}</span>