import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService } from 'src/app/shared/alert/alert.service';
import { ServerApisService } from 'src/app/shared/services/server-apis.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { FormControl } from '@angular/forms';
import { Role } from 'src/app/shared/enums/role.enum';
import { DatePipe } from '@angular/common';
import { Constants, navConstants } from 'src/app/shared/constants/constant';
import { RouteMakingService } from 'src/app/shared/services/route-making.service';
import { DataService } from 'src/app/shared/services/data.service';

@Component({
  selector: 'app-role-assignment-user-management',
  templateUrl: './role-assignment-user-management.component.html',
  styleUrls: ['./role-assignment-user-management.component.css']
})
export class RoleAssignmentUserManagementComponent {

  lstalluserRoles: any = [];
  lstalluserTempRoles: any = [];
  lstassignRoles: any = [];
  p = 1;
  previd: number = 0;
  oldservice: string = "";
  isPopup: boolean = false;
  newservice: string = "";
  lstRoles: any = [];
  lstTempRoles: any = [];
  isEditIndex: number;
  storeRole: string = '';
  id: number = 0;
  isdisplay: boolean = false
  isEdit: boolean = false;
  isdropdownshow: boolean = false;
  lsttempassignRoles: any = [];
  isrole: boolean = false;
  submitted: boolean = false;
  isAdd: boolean = false;
  isdelete: boolean = false;
  isassign: boolean = false;
  searchtext: string = '';
  placeholderroleText: string = "Select Role"
  filterRole: string = '';
  selectedIndex: number;
  isSearchChange: boolean = false;
  page: string = 'rolecreation';
  placeholderText: string = 'Search with Id and Name';

  constructor(private spinner: NgxSpinnerService, private routeMakingService: RouteMakingService, private _apiService: ServerApisService, private router: Router, private sanitizer: DomSanitizer, private datePipe: DatePipe,
    private _alertService: AlertService, private _elementRef: ElementRef, private renderer: Renderer2, private dataService: DataService) { }

  ngOnInit(): void {
    const user = sessionStorage.getItem('user');
    if (user != null) {
      let data = JSON.parse(user);
      if (Role.Admin == data.roleId || Role.SubAdmin == data.roleId) {
        let date = new Date();
        this.getRolesAssigners();
        this.getRoles();
        let path = window.location.href.split('user-management/')
      } else {
        this.router.navigate([""]);
      }

    } else {
      this.router.navigate([this.routeMakingService.getNavigateUrl([Constants.Login])]);
    }
  }

  getRolesAssigners() {
    this.spinner.show();
    this.lstalluserRoles = [];
    this._apiService.GetRoleAssigners().subscribe(res => {
      this.spinner.hide();

      this.lstalluserRoles = res.FetchAllUsersByRolesResult['resultFetchAllUsersRoles'];
      this.lstalluserTempRoles = this.lstalluserRoles;
    });
  }

  getRoles() {
    this.spinner.show();
    this.lstRoles = [];
    this._apiService.GetRoles().subscribe(res => {
      this.spinner.hide();
      this.lstRoles = res.FetchAllUserRolesResult['resultUserRoles'].filter((x: any) => x.isApproved === 'Yes');
      this.lstTempRoles = this.lstRoles;
    });
  }

  searchroleasgnChange(event: any) {
    if (event.target.value && event.target.value.trim() !== '') {
      let preResult: any[];

      if (this.filterRole !== 'All Roles' && this.filterRole !== 'UserProfileVideo') {
        preResult = this.lstalluserTempRoles.filter((x: any) =>
          x.roleName.toLowerCase().includes(this.filterRole.toLowerCase())
        );
      } else {
        preResult = this.lstalluserTempRoles;
      }

      const result = preResult.filter((x: any) =>
        x.memberId.toString().toLowerCase().includes(event.target.value.toLowerCase()) ||
        x.Name.toLowerCase().includes(event.target.value.toLowerCase()) ||
        x.UniqueId.toLowerCase().includes(event.target.value.toLowerCase()) ||
        x.mobile.toLowerCase().includes(event.target.value.toLowerCase())
      );

      this.lstalluserRoles = result;
    } else {
      if (this.filterRole !== 'All Roles' && this.filterRole !== 'UserProfileVideo') {
        this.lstalluserRoles = this.lstalluserTempRoles.filter((x: any) =>
          x.roleName.toLowerCase().includes(this.filterRole.toLowerCase())
        );
      } else {
        this.lstalluserRoles = this.lstalluserTempRoles;
      }
    }
  }

  pageChanged(event: any) {
    this.p = event;
    this.isEditIndex = -1;
    window.scrollTo(0, 0);
  }

  close() {
    this.selectedIndex = -1;
  }

  selectedRoleId: any;
  selectedRole: string = "";
  selectedoldRole: string = "";
  selectednewRole: string = "";
  changeroleassign: any;

  selected(d: any) {
    this.selectedRoleId = d;
    this.selectedRole = d.roleName;
    this.isdelete = true;
  }
  editrole(i) {
    this.isEditIndex = i;
  }
  selectedchangeRole(event, d) {
    this.selectednewRole = event.roleName;
    this.changeroleassign = d;
    this.isassign = true;
  }
  rolechange() {
    this.catergorychange(this.selectednewRole, this.changeroleassign)
  }
  roleCancel() {
    this.isdisplay = false;
  }

  // ok() {
  //   this.deleteRole(this.selectedRoleId);
  // }

  catergorychange(val, d) {
    let payload: any = {};
    payload.memberId = d.memberId
    payload.roleName = val
    this.spinner.show();

    this._apiService.updateRole(payload).subscribe(res => {
      this.spinner.hide();
      this.isEditIndex = -1;
      this.isdisplay = false;
      this.isassign = false;
      this._alertService.success(res['result']);
    });
  }

  getuser(event) {
    if (this.lstRoles.length > 0) {
      var result = this.lstRoles.filter((x: any) => x.roleName == event);
      if (result.length > 0) {
        return result[0].roleName;
      } else {
        return -2;
      }
    } else {
      return -1;
    }

  }

  filterRoleassignSearch: string = '';
  changeRole(value: any) {
    this.isEditIndex = -1;
    this.isdropdownshow = false;
    this.filterRoleassignSearch = '';

    if (value == undefined) {
      this.lstalluserRoles = this.lstalluserTempRoles;
    } else {
      this.filterRole = value.roleName;
      var result = this.lstalluserTempRoles.filter((x: any) => x.roleName == this.filterRole);
      this.lstalluserRoles = result;
    }

  }

  closeRoleDropdown(event: any) {
    // 
    // if(this.isdropdownshow==false){
    //   this.dropdownshow();
    // }

  }

  getindex(d: any, i: number) {
    this.lstRoles = this.lstTempRoles;
    this.selectedoldRole = d.roleName;
    if (this.previd == d.roleId) {
      this.isdisplay = false;
      this.isPopup = false;
      this.id = d.memberId;
      this.previd = 0;
      if (this.isSearchChange == true) {
        this.lstalluserRoles[this.selectedIndex].roleName = this.oldservice;
      }
    }
    else {

      this.isdisplay = true;
      this.id = d.memberId;
      this.oldservice = d.roleName;
      let index = this.lstalluserRoles.findIndex(x => x.memberId === this.id);
      this.selectedIndex = index;
      this.lstalluserRoles[this.selectedIndex].roleName = this.oldservice;

    }
  }

  dropdownshow() {

    this.storeRole = this.filterRole;
    if (this.isdropdownshow == false) {
      this.isdropdownshow = true;
      if (this.filterRole == 'All Roles') {
        this.filterRole = '';
      }
    } else {

      this.isdropdownshow = false;
      this.filterRole = 'All Roles';
    }
  }

}
