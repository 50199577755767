

<div class="search-results-section">
    <div class="custom-container">
       <div class="no-result-found">
           <div class="no-result-img">
                <img src="assets/images/No-result-img.png" alt="no-result-img" title="no result img">
           </div>
           <div>
            <h1 class="title-no-result" *ngIf="IsData">Results are Loading ! for “{{search}}”</h1>
               <h6 class="title-no-result" *ngIf="!IsData"style="margin-top: -5%;">Sorry, No Result found ! for “{{search}}”</h6>
               <p class="content-no-results" *ngIf="!IsData && !isLoggedIn">Please <a (click) = "openRegister()">Register</a> to get the <a herf="#">Expert/Professional</a> ASAP</p>
               <div class="row" style="display: flex; justify-content: center;">
                <div class="col-md-6">
                    <input type="text" name="name" class="form-control custom-input-found" *ngIf="!IsData && isLoggedIn"
                    [(ngModel)]="addServiceNname" #name="ngModel" id="name" placeholder="Service in Need" style="height: 50px; width: 58%; margin-left: 21%;font-size: 1.2rem;">
                  
                
                       
                    </div>
                    
               </div>
               <div class="col-md-2" style="margin: 17px auto;">
                <div class="Rectangle-4-copy hand" (click)="addService()" *ngIf="!IsData && isLoggedIn">
                    <label class="Search hand">Submit</label>
                </div>
               </div>
               
              
                

           </div>
       </div>
    </div>
</div>