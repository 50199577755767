<div class="whole-section">
    <nav aria-label="breadcrumb" class="mlft-6">
        <app-bread-crumb [breadTitle]="serviceManagenetTitle" [breadpath1]="adminPanel"
            [breadpath2]="nearByServiceManagement" [breadpath3]="serviceManagementNav"
            [breadroute1]="adminPanelRoute"></app-bread-crumb>
    </nav>
    <div class="table-responsive" style="padding: 1%;">
        <div class="row " style="background-color: #fff;">
            <div class="col-md-2 left-section" *ngIf="isCard" (mouseleave)="onMouseLeave()"
                style="z-index: 900;position: absolute;top: 0px;">
                <div class="row">

                    <div class="col-md-12" *ngIf="isCard">
                        <div class="Rectangle-562">
                            <!-- <div class="row">
                          <div class="col-md-10" style="padding-right: 0;padding-top: 12px;">
                              <label class="Reports">Near By Categories</label>
                          </div>
                        </div> -->
                        </div>
                        <div class="hand" (click)="searchtab('category')"
                            [ngClass]="{'Rectangle-563-copy': page!='category', 'Rectangle-563-selected active' : page=='category' }">
                            <p class="mr-0">Near By Category Creation <span style="float: right;"></span></p>
                        </div>
                        <div class="hand" (click)="searchtab('subCategory')"
                            [ngClass]="{'Rectangle-563-copy': page!='subCategory', 'Rectangle-563-selected active' : page=='subCategory' }">
                            <p class="mr-0">Near By Sub Category Creation <span style="float: right;"></span></p>
                        </div>
                    </div>
                </div>

            </div>
            <div class="col-md-1 leftminisec" (mouseenter)="onMouseEnter()">
                <div style="text-align: center; margin-bottom: 10px;">
                    <!-- Content goes here -->
                </div>
            </div>
            <div class="content-section" [ngClass]="{'col-10': isCard, 'col-11' : !isCard}">
                <ng-container *ngIf="page === 'category'">
                    <table class="common-table">
                        <app-near-by-caregory-creation-service-management></app-near-by-caregory-creation-service-management>

                    </table>
                </ng-container>
                <ng-container *ngIf="page === 'subCategory'">
                    <table class="common-table">
                        <app-near-by-sub-caregory-creation-service-management></app-near-by-sub-caregory-creation-service-management>

                    </table>
                </ng-container>
            </div>
        </div>
    </div>
</div>