
<nav aria-label="breadcrumb" class="mlft-6">
    <app-bread-crumb [breadpath1]="home" [breadpath2]="contactUs" [breadroute1]="contactUsRoute"></app-bread-crumb>
  </nav>
<div class="container ">
    <div class="row">
        <div class="col-md-13 contact-box" *ngIf="loggedIn">
            <div>
            <form class="login-form" [formGroup]="contactForm" (ngSubmit)="onSubmit()">
                
                 <div class="form-group mt-4"> 
                        <label class="text-uppercase-lowecase font"> Message</label>
                        <textarea class="form-control"formControlName="message" rows="7" 
                            [ngClass]="{ 'is-invalid': submitted && f.message.errors }"></textarea>
                        <div *ngIf="submitted && f.message.errors" class="invalid-feedback">
                            <div *ngIf="f.message.errors.required">Message is required</div>
                        </div>
                    </div>
                    <button  type="submit"style="width: 95px;"class="btn btn-primary float-end mt-2 font">Submit</button>
                </form>  
            </div> 
        </div> 
        
        <div class="col-md-12 p-2" *ngIf="loggedIn">
        <h2 class="text-uppercase-lowecase font"> &nbsp;Get in Touch</h2>   
            <address class="ms-3 font">
                <label class="bold">Phone:</label> &nbsp;080 6917 9999 <br>
                <label class="bold">Email Us:</label> &nbsp;contact@toskie.com <br>
                <label class="bold">App Support Service:</label> &nbsp;support@toskie.com <br>
                <label class="bold">Ads and Promotions:</label> &nbsp;ads@toskie.com <br> 
                <label class="bold">Careers:</label> 
                &nbsp;<a class="link hand" (click)="goToLink('https://www.spinoinc.com/careers')"> Click on  Careers </a> <br>
                <label class="bold">Investors:</label> &nbsp;investors@toskie.com <br>
                <label class="bold">Copyright and legal:</label> &nbsp;support@toskie.com <br>
            </address>
        </div>

        <div class="col-md-12 p-2 notLogged" *ngIf="!loggedIn">
            <h2 class="text-uppercase-lowecase"> &nbsp;Get in Touch</h2>   
            <address class="ms-3">
                <label class="bold">Phone:</label> &nbsp;080 6917 9999 <br>
                <label class="bold">Email Us:</label> &nbsp;contact@toskie.com <br>
                <label class="bold">App Support Service:</label> &nbsp;support@toskie.com <br>
                <label class="bold">Ads and Promotions:</label> &nbsp;ads@toskie.com <br> 
                <label class="bold">Careers:</label> 
                &nbsp;<a class="link hand" (click)="goToLink('https://www.spinoinc.com/careers')"> Click on  Careers </a> <br>
                <label class="bold">Investors:</label> &nbsp;investors@toskie.com <br>
                <label class="bold">Copyright and legal:</label> &nbsp;support@toskie.com <br>
            </address>
            <button class="btn btn-primary setMargin" [routerLink]="['/login']" routerLinkActive="router-link-active" (click)="contact()"><i class="fa fa-angle-double-right" aria-hidden="true"></i> Contact</button>
        </div>
    </div>
</div>