<div class="breadcrumb1">
  <app-bread-crumb [breadpath1]="home" [breadpath2]="aboutUs"
    [breadroute1]="aboutUsRoute"></app-bread-crumb>
</div>
<div class="container-fluid">

  <!-- About section -->
  <div class="row my-5 mr-1 margin-right-zero ">
    <div class="col-md-5 col-sm-12 padding-right-zero">
      <div class="Rectangle-5 mx-auto"> <!-- Use the Rectangle as background -->
        <div class="embed-responsive embed-responsive-16by9">
          <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/-WF7nz85okM?si=CP2STLj9OhY8RNwX" allowfullscreen></iframe>
        </div>
      </div>
      <!-- Additional content here -->
    </div>
    <div class="col-md-7 col-sm-12 padding-right-zero">
      <h2 class="mb-3 text-right-in-middle">ABOUT TOSKIE</h2>
      <p class="my-paragraph">
        Toskie is a Search Engine App that connects people with professionals from their neighborhood. Users can utilize
        Toskie to search for a variety of professionals, such as plumbers, electricians, teachers, makeup artists, web
        designers, home cleaners, doctors, and many more. The app fetches results based on the user's location,
        providing a convenient way to discover service providers.

        When using Toskie, users have the ability to view detailed profiles of different service providers. These
        profiles include information about the professionals' experience, ratings, and reviews from previous clients.
        This wealth of information empowers users to make informed decisions when selecting a professional to fulfill
        their specific needs.
      </p>

    </div>
  </div>
</div>
<div class="container-fluid px-0 mx-0 toski-solving">
  <!-- How it works section -->
  <div class="row">
    <div class=" background-Rectangle-1">
      <div class="row my-5">
        <div class="col-12">
          <h3 class="mb-3 margin-left-zero center-text">WHAT PROBLEM TOSKIE SOLVING</h3>
          <div class="row">
            <div class="col-md-4">
              <div class="image-container">

                <div class="Rectangle-2 align-box-center">
                  <span> 01 </span>
                  <img src="/assets/images/problem-1.png" alt="Choose What To Do" title="Choose What To Do"  class="mb-3 common-image">
                  <h5>The Problem-1</h5>
                  <p class="my-paragraph-1">Every person possesses multiple skills as part of their main occupation, but
                    there's no platform to showcase and publish all their talents and skills.</p>


                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="image-container">
                <div class="Rectangle-2 align-box-center">
                  <span> 02 </span>
                  <img src="/assets/images/problem-2.png" alt="Find What You Want" title="Find What You Want" class="mb-3 common-image">
                  <h5>The Problem-2</h5>
                  <p class="my-paragraph-1">We don't know what our neighbors' profes-sions are and the kinds of talents
                    they have!</p>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="image-container">
                <div class="Rectangle-3 align-box-center">
                  <h4>The Solution</h4>
                  <img src="/assets/images/the-solution.png" alt="Solution" title="Solution" class="common-image3 img-fluid align-middle">
                  <p class="my-paragraph-3">
                    Toskie enables every person to register with their multi skills, which creates a digital page for
                    them to promote. This allows them to be available to connect with their neighborhood and, in turn,
                    <strong>facilitate business connections and opportunities.</strong>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Why Choose Us section -->
<div class="container-fluid">
  <div class="row my-5 mr-1 margin-right-zero">
    <div class="col-md-5 col-sm-12 padding-right-zero">
      <div class="Rectangle-6 mx-auto"> <!-- Use the Rectangle as background -->        <img src="../../../assets/images/about-us-1.png" alt="Connecting Around" title="Connecting Around" class="=">
      </div>
      <!-- Additional content here -->
    </div>

    <div class="col-md-6">
      <h6 class="mb-3 text-right-in-middle">Toskie Mission</h6>
      <p class="my-paragraph-2">Collaboration, Innovation, and Inclusion for a more equitable and inspiring world
        we envision a future where equitable opportunities and inspiring possibilities are accessible to all. It's a
        world where collaboration unlocks innovation and inclusion empowers everyone to contribute and thrive.</p>
    </div>
  </div>
</div>