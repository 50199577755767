import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject } from 'rxjs';
import { AlertService } from '../../shared/alert/alert.service';
import { ServerApisService } from '../../shared/services/server-apis.service';

@Component({
  selector: 'app-provider-rating',
  templateUrl: './provider-rating.component.html',
  styleUrls: ['./provider-rating.component.css']
})
export class ProviderRatingComponent implements OnInit {
  review:String;
  // qualityRating: number;
  // relaibleRating: number;
submitted:boolean=false;
  ratingAndReview: any = [];
  QueryParamId: any;
  providerInfo: any ={};
  services: any=[];
  display='none';
  breadData1:string="expert-profile";
  breadData2:string="expert-rating";
  breadRouteData1:string="/"
  starQualityList: boolean[] = [true,true,true,true,true];       // create a list which contains status of 5 stars
  Qrating:number=0;
  starReliableList: boolean[] = [true,true,true,true,true];   
  Rrating:number=0;
  constructor(private _apiService: ServerApisService, private _alert: AlertService,
     private spinner: NgxSpinnerService, private route: ActivatedRoute,private router: Router) { }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe((URLparams: any) => {
      const EncodeURL = URLparams.params.q;
      this.QueryParamId = URLparams.params.q;
       //console.log(this.QueryParamId);
       
    }); 
    this.getAllReviewAndRatings(this.QueryParamId);
    this.viewProviderProfileBYURL(this.QueryParamId);

    window.scrollTo(0, 0)
  }

  viewProviderProfileBYURL(p){
    const payLoad:any={};
    payLoad.memberId=sessionStorage.getItem('memberId');
    
    payLoad.providerMemberId=p;
    payLoad.token=this._apiService.token;
    this._apiService.getProviderProfile(payLoad).subscribe((res:any)=>{
      try
      {
        
      //console.log(res.Registration)
      this.providerInfo = res.Registration;
       res.Registration.Services.forEach(element => {
        this.services.push(element);
      });
    }
    catch{ err=>{
      var obj={
        ErrorMessage:err
      }
      this._apiService.ExceptionLog(obj).subscribe(res => {
       
      });

    }  
  }
      ////console.log(ser);
    })

  }

 

setQualityStar(data:any){
  
      this.Qrating=data+1;                               
      for(var i=0;i<=4;i++){  
        if(i<=data){  
          this.starQualityList[i]=false;  
        }  
        else{  
          this.starQualityList[i]=true;  
        }  
     }  
     //console.log(this.Qrating)
 }  
 setReliableStar(s:any){
  this.Rrating=s+1;                               
  for(var i=0;i<=4;i++){  
    if(i<=s){  
      this.starReliableList[i]=false;  
    }  
    else{  
      this.starReliableList[i]=true;  
    }  
 }  
 //console.log(this.Rrating)
 }

  submitRatingAndReview(p){
    
    this.submitted=true;
    if(this.Qrating!=0 && this.Rrating!=0)
    {
      const payload:any={};
      payload.userMemberId= sessionStorage.getItem('memberId');
      payload.quality = this.Qrating;
      payload.reliable = this.Rrating;
      payload.comment=this.review;
      payload.providerMemberId=p;
      this.spinner.show();
      payload.token=this._apiService.token;
      
      this._apiService.submitRatingAndReview(payload).subscribe(
        
        res=>{
          window.scrollTo(0, 0);
          this.spinner.hide();
          try{
          if(res['resultModel'].status==200){
            this._alert.success(res['resultModel'].result);
            this.resetForm();
            this.getAllReviewAndRatings(p);
            setTimeout(()=>{
              this.router.navigate(["/"]);
          }, 2000);
          } else{
            
            this._alert.success("Unable to send your feedback, Please try again");
          }
          //console.log(res['resultModel'].status);
        }
        catch{ err=>{
          var obj={
            ErrorMessage:err
          }
          this._apiService.ExceptionLog(obj).subscribe(res => {
           
          });
  
        }  
      }
        }
      )
    }
   
  }

  getAllReviewAndRatings(p){
    const payLoad:any={};
    
    payLoad.memberId=sessionStorage.getItem('memberId');
    payLoad.providerMemberId=p;
    payLoad.token=this._apiService.token;
    this._apiService.GetRatingAndReview(payLoad).subscribe((res:any)=>{
      if (res.status == 200 ) {
        try{
          this.ratingAndReview = res.resultRatingAndReview;
          //console.log(this.ratingAndReview)
          
            this.ratingAndReview.forEach(element => {
            
              if(element.profileImageUrl.split("/").splice(-1)[0] !=='null'){
                element.profileImageUrl= element.profileImageUrl;
              } else {
                element.profileImageUrl="assets/images/person.jpg"
              }
              
            });
          }
          catch{ err=>{
            var obj={
              ErrorMessage:err
            }
            this._apiService.ExceptionLog(obj).subscribe(res => {
             
            });
    
          }  
        }
      }else if(res.status == 404)
      {
        window.scrollTo(0, 0)
        this._alert.success('logging out');
        sessionStorage.clear();
        let data={
          token:this._apiService.token
        }
        this._apiService.sendUpdate(data);
        this.router.navigate(["/"]).then(()=>{
          window.location.reload();
        })
      }
    
      
    });
    
    
  }

resetForm(){
  this.review='';
  this.starQualityList  = [true,true,true,true,true];     
  
  this.starReliableList = [true,true,true,true,true];   
  
}
goToHome() {
  sessionStorage.setItem('redirectSearch', 'true');
  this.router.navigate(["/login"]).then(() => {
    window.location.reload();
  });

}
}
