import { Component, OnInit } from '@angular/core';
import { ServerApisService } from 'src/app/shared/services/server-apis.service';
@Component({
  selector: 'app-near-by-services',
  templateUrl: './near-by-services.component.html',
  styleUrls: ['./near-by-services.component.css']
})
export class NearByServicesComponent implements OnInit {

  constructor(private _apiService:ServerApisService) { }
  lstNearbyServices: any = [];
  ngOnInit(): void {
  //   this.lstNearbyServices = [{ title: 'Makeup Artists in hyderabad' },
  //   { title: 'Web Development hyderabad' },
  //   { title: 'Electrician near me' },
  //   { title: 'Home Tuitions in hyderabad' },
  //   { title: 'Drivers For Hire near me' },
  //   { title: 'Electrician near me Home Service' },
  //   { title: 'Post Wedding Photoshoot Locations in hyderabad' },
  //   { title: 'Commercial Real Estate Investment' },
  //   { title: 'Plumbers near me' },
  //   { title: 'Best Plumbers in hyderabad' },
  //   { title: 'Best Beauticians near me' },
  //   { title: 'Drivers near me' },
  //   { title: 'Social Media Marketing hyderabad' },
  //   { title: 'Tuitions near me' },
  //   { title: 'AC Servicing near me' },
  //   { title: 'Digital Agency hyderabad' },
  //   { title: 'Best Photographer near me' },
  //   { title: 'AC Repair near me' },]
  // }
  this.fetchAllNearBySubServices();
  }
  fetchAllNearBySubServices(): void {
    this._apiService.GetAllNearBySubServices().subscribe(res => {
      let result: any = res;
      if (result.FetchAllNearBySubCategoryResult.status == 200) {
        this.lstNearbyServices = result.FetchAllNearBySubCategoryResult.resultFetchNearByServicesCategory;
      } 
    });
  }

}
