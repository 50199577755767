import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  private navigateToSearchSubject = new BehaviorSubject<boolean>(false);
  private currentLocationSubject = new BehaviorSubject<{ latitude: number; longitude: number }>({ latitude: 0, longitude: 0 });

  setNavigateToSearch(value: boolean): void {
    this.navigateToSearchSubject.next(value);
  }

  getNavigateToSearch(): Observable<boolean> {
    return this.navigateToSearchSubject.asObservable();
  }

  setCurrentLocation(latitude: number, longitude: number): void {
    this.currentLocationSubject.next({ latitude, longitude });
  }

  getCurrentLocation(): Observable<{ latitude: number; longitude: number }> {
    return this.currentLocationSubject.asObservable();
  }
}
