import { Component, OnInit, ViewChildren, ElementRef, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { interval, Subscription } from 'rxjs';
import { AlertService } from 'src/app/shared/alert/alert.service';
import { WindowService } from '../../shared/services/window.service';
import { LoginComponent } from '../login/login.component';
import 'firebase/auth';
import firebase from 'firebase/compat/app';
@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.css']
})
export class OtpComponent implements OnInit {
  @ViewChild('formRow', { static: false }) firstInput: ElementRef;
  form: FormGroup;
  submitted: boolean = false;
  formInput = ['input1', 'input2', 'input3', 'input4', 'input5', 'input6'];
  @ViewChildren('formRow') rows: any;
  @Output() register: EventEmitter<any> = new EventEmitter<any>();
  @Output() resgisterSubmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() login: EventEmitter<any> = new EventEmitter<any>();
  @Output() reset: EventEmitter<any> = new EventEmitter<any>();
  @Output() profile: EventEmitter<any> = new EventEmitter<any>();
  @Input() result: any;
  @Input() registerFormData: any;
  @Input() formnames: any;
  @Input() mobilenum: string;
  @Input() newUser: any;
  num1: number = 180;
  verificationCode: string = "";
  windowRef: any;
  timerValid = false;
  subscription: Subscription;
  source = interval(1000);
  private recaptchaVerifier: firebase.auth.RecaptchaVerifier | null = null;
  // private registerpage:RegisterComponent,
  constructor(private router: Router, private win: WindowService, private spinner: NgxSpinnerService,
    private _alert: AlertService) {
    this.form = this.toFormGroup(this.formInput);
  }

  ngAfterViewInit() {
    setTimeout(() => this.firstInput.nativeElement.focus(), 0);
  }

  ngOnInit() {
    this.spinner.hide();
    this.windowRef = this.win.windowRef
    this.subscription = this.source.subscribe(() => this.numDecrement(this.num1))
    setTimeout(() => this.numDecrement(this.num1), 1000)
    window.scrollTo(0, 0);
  }

  numDecrement(numberrecived: number) {
    if (this.num1 <= 0) {
      this.subscription.unsubscribe();
      this.timerValid = true;
    } else {
      this.num1--;
    }
  }

  toFormGroup(elements) {
    const group: any = {};
    elements.forEach(key => {
      group[key] = new FormControl('', Validators.required);
    });
    return new FormGroup(group);
  }

  keyUpEvent(event, index) {
    let pos = index - 1;
    if (event.keyCode === 8 && event.which === 8) {
      pos = index - 1;
    }
    else if (event.keyCode === 9 && event.which === 9) {
      pos = index;
    }
    else {
      pos = index + 1;
    }
    if (pos > -1 && pos < this.formInput.length) {
      this.rows._results[pos].nativeElement.focus();
    }
  }

  onSubmit() {
    this.verificationCode = Object.values(this.form.value).join('');
    if (this.verificationCode.length == 6) {
      this.submitted = true;
      this.windowRef.confirmationResult = this.result;
      this.spinner.show();
      this.windowRef.confirmationResult.confirm(this.verificationCode)
        .then(result => {
          this.submitted = false;
          this.spinner.hide();
          if (this.formnames == "Login") {
            this._alert.success('OTP Successfully Verified');
            this.login.emit();
          }
          if (this.formnames == "Register") {
            this._alert.success('OTP Successfully Verified');
            this.resgisterSubmit.emit();
          }
          if (this.formnames == "ProfileUpdate") {

            this.profile.emit();
            this._alert.success('OTP Successfully Verified');
          }
        }
        )
        .catch(error => {
          this.spinner.hide();
          this._alert.error('Incorrect code entered?')
        });
    }
  }

  resendOtp() {
    if (this.registerFormData.countryCode == null) {
      this.registerFormData.countryCode = '+91';
    }
    var num1: any = this.registerFormData.countryCode + this.registerFormData.mobile;
    const num = num1;
    this.spinner.show();
    if (!this.recaptchaVerifier) {
      this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('loginrecaptcha1', {
        'size': 'invisible'       
      });
    }
    firebase.auth().signInWithPhoneNumber(num, this.recaptchaVerifier)
      .then(result => {
        this.result = result;
        this.spinner.hide();
        this._alert.success('OTP has been sent to your mobile number. Please check.');
        this.timerValid = false;
        this.num1 = 180;
        this.subscription = this.source.subscribe(() => this.numDecrement(this.num1));
        setTimeout(() => this.numDecrement(this.num1), 1000);
      })
      .catch(error => this.otpFailed(error));
  }
  otpFailed(event: any) {
    this.spinner.hide();
    this._alert.error(event.message);
  }

  cancel() {
    window.location.reload();
  }
  
}