<div>
    <mat-tab-group>
      <!-- Overview Tab -->
      <mat-tab>
        <ng-template mat-tab-label>
          <div class="custom-tab-label" (click)="getDataFromApi('mainPending')">
            <span class="overview-text" [ngClass]="{'active-tab-text': selectedTab === 'mainPending'}">Overview</span>
            <img src="../../../assets/images/overview.png" class="overview-image" alt="overview" title="overview">
          </div>
        </ng-template>

        <div class="tasks_table1">
          <div class="row">
            <div class="col-md-10 search-box">
              <label style="width: 100%;">
               <span>
                  <input type="text" id="search" class="custom-focus-input" autocomplete="off"
                    [(ngModel)]="searchtext" [placeholder]="mainPlaceholder"
                    style="height: 36px;border: none;width: 120.2%;margin-left: 2px;"
                    (input)="searchMainProfessionApproval($event)">
                </span>
              </label>
            </div>
          </div>
        </div>

        <table  class="table table-striped">
          <thead class="tablehd-sec">
            <tr>
              <th style="width: 10%;" class="padleft-30">S NO</th>
              <th style="width: 20%;">Main Profession</th>
              <th style="width: 20%;">New Main Profession</th>
              <th style="width: 30%;">Change Description</th>
              <th style="width: 20%;">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let d of adminApprovalCategories | paginate: { itemsPerPage: 10, currentPage: p}; let i = index"
              class="tdata">
              <td style="width: 10%;padding-left: 2%;" class="padleft-30">{{ (p - 1) * 10 + i + 1 }}</td>
              <td style="width: 20%;">
                <p>{{d.mainProfessionName}}</p>
              </td>
              <td style="width: 20%;">
                <p>{{d.mainProfessionNameNew}}</p>
              </td>
              <td style="width: 30%;">
                <ng-container *ngIf="d.isDeleted == 'Y'">
                  <p>Profession <b>{{d.mainProfessionName}}</b> has been deleted</p>
                </ng-container>
                <ng-container *ngIf="d.isDeleted == 'N' && !d.mainProfessionNameNew">
                  <p>Profession <b>{{d.mainProfessionName}}</b> has been Added</p>
                </ng-container>
                <ng-container *ngIf="d.isDeleted == 'N' && d.mainProfessionNameNew">
                  <p>Profession Has been Updated from <b>{{d.mainProfessionName}}</b> to
                    <b>{{d.mainProfessionNameNew}}</b>
                  </p>
                </ng-container>
              </td>
              <td style="width: 20%;">
                <div class="custom-select-container">
                  <select class="custom-select" (change)="dropdownAction($event.target.value, d,'main')">
                    <option value="selected" selected>Select </option>
                    <option value="approved">Approved</option>
                    <option value="reject">Rejected</option>
                  </select>
               </div>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="row">
          <div class="col">
            <div class="d-flex justify-content-center" *ngIf="adminApprovalCategories.length!=0">
              <pagination-controls (pageChange)="pageChanged($event)"
                (pageBoundsCorrection)="pageChanged($event)"></pagination-controls>

            </div>
          </div>
        </div>
      </mat-tab>

      <!-- Approved Tab -->
      <mat-tab [ngClass]="'custom-approved-tab'">
        <ng-template mat-tab-label>
          <div class="custom-tab-label" (click)="getDataFromApi('mainApproved')">
            <div class="Ellipse-5">
              <div class="union-10"></div>
            </div>
            <span class="approved-text" [ngClass]="{'active-tab-text': selectedTab === 'mainApproved'}">Approved</span>
          </div>
        </ng-template>
        <div class="tasks_table1">
          <div class="row">
            <div class="col-md-10 search-box">
              <label style="width: 100%;">
               <span>
                  <input type="text" id="searchApproved" class="custom-focus-input" autocomplete="off"
                    [(ngModel)]="searchtextApproved" [placeholder]="mainPlaceholder"
                    style="height: 36px;border: none;width: 120.2%;margin-left: 2px;"
                    (input)="searchApprovedMainProfession($event)">
                </span>
              </label>
            </div>
          </div>
        </div>

        <table class="table table-striped">
          <thead class="tablehd-sec">
            <tr>
              <th style="width: 11%;" class="padleft-30">S. NO</th>
              <th style="width: 22%;">Main Profession</th>
              <th style="width: 22%;">New Main Profession</th>
              <th style="width: 34%;">Change Description</th>
              <th style="width: 11%;">Status</th>
            </tr>
          </thead>
          <tbody>
            <!-- Assuming this is within the appropriate mat-tab -->
            <tr
              *ngFor="let d of adminApprovalCategories | paginate: { itemsPerPage: 10,currentPage: p};let i = index"
              class="tdata">
              <td style="width: 11%;padding-left: 2%;" class="padleft-30">{{ (p - 1) * 10 + i + 1 }}</td>
              <td style="width: 22%;">
                <p>{{d.mainProfessionName}}</p>
              </td>
              <td style="width: 22;">
                <p>{{d.mainProfessionNameNew}}</p>
              </td>
              <td style="width: 34%;">
                <ng-container *ngIf="d.isDeleted == 'Y'">
                  <p>Profession <b>{{d.mainProfessionName}}</b> has been deleted</p>
                </ng-container>
                <ng-container *ngIf="d.isDeleted == 'N' && !d.mainProfessionNameNew">
                  <p>Profession <b>{{d.mainProfessionName}}</b> has been Added</p>
                </ng-container>
                <ng-container *ngIf="d.isDeleted == 'N' && d.mainProfessionNameNew">
                  <p>Profession Has been Updated from <b>{{d.mainProfessionName}}</b> to
                    <b>{{d.mainProfessionNameNew}}</b>
                  </p>
                </ng-container>
              </td>
              <!-- <td style="width: 20%;">
                <div class="custom-select-container">
                  <select class="custom-select" (change)="dropdownAction($event.target.value, d, 'main')">
                    <option value="" selected>Select </option>
                    <option value="approved">Approved</option>
                    <option value="reject">Rejected</option>
                  </select>
                </div>
              </td> -->
              <td style="width: 11%; text-align: center;">
                <span class="custom-checkmark">&#10003;</span> 
              </td>
            </tr>

          </tbody>
        </table>

        <div class="row">
          <div class="col">
            <div class="d-flex justify-content-center" *ngIf="adminApprovalCategories.length != 0">
              <pagination-controls (pageChange)="pageChanged($event)" (pageBoundsCorrection)="pageChanged($event)">
              </pagination-controls>
            </div>
          </div>
        </div>
      </mat-tab>

      <!-- Rejected Tab -->
      <mat-tab [ngClass]="'custom-rejected-tab'">
        <ng-template mat-tab-label>
          <div class="custom-tab-label" (click)="getDataFromApi('mainRejected')">
            <div class="Ellipse-6">
              <div class="union-20"></div>
            </div>
            <span class="rejected-text" [ngClass]="{'active-tab-text': selectedTab === 'mainRejected'}">Rejected</span>
          </div>
        </ng-template>
        <div class="tasks_table1">
          <div class="row">
            <div class="col-md-10 search-box">
              <label style="width: 100%;">
               <span>
                  <input type="text" id="searchRejected" class="custom-focus-input" autocomplete="off"
                    [(ngModel)]="searchtextRejected" [placeholder]="mainPlaceholder"
                    style="height: 36px;border: none;width: 120.2%;margin-left: 2px;"
                    (input)="searchRejectedMainProfession($event)">
                </span>
              </label>
            </div>
          </div>
        </div>

        <table  class="table table-striped">
          <thead class="tablehd-sec">
            <tr>
              <th style="width: 11%;" class="padleft-30">S. NO</th>
              <th style="width: 22%;">Main Profession</th>
              <th style="width: 22%;">New Main Profession</th>
              <th style="width: 34%;">Change Description</th>
              <th style="width: 11%;">Status</th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let d of adminApprovalCategories | paginate: { itemsPerPage: 10, currentPage: p}; let i = index"
              class="tdata">
              <td style="width: 11%;padding-left: 2%;" class="padleft-30">{{ (p - 1) * 10 + i + 1 }}</td>
              <td style="width: 22%;">
                <p>{{d.mainProfessionName}}</p>
              </td>
              <td style="width: 22%;">
                <p>{{d.mainProfessionNameNew}}</p>
              </td>
              <td style="width: 34%;">
                <ng-container *ngIf="d.isDeleted == 'Y'">
                  <p>Profession <b>{{d.mainProfessionName}}</b> has been deleted</p>
                </ng-container>
                <ng-container *ngIf="d.isDeleted == 'N' && !d.mainProfessionNameNew">
                  <p>Profession <b>{{d.mainProfessionName}}</b> has been Added</p>
                </ng-container>
                <ng-container *ngIf="d.isDeleted == 'N' && d.mainProfessionNameNew">
                  <p>Profession Has been Updated from <b>{{d.mainProfessionName}}</b>
                    to<b>{{d.mainProfessionNameNew}}</b></p>
                </ng-container>
              </td>
              <!-- <td style="width: 20%;">
                <div class="custom-select-container">
                  <select class="custom-select" (change)="dropdownAction($event.target.value, d,'main')">
                    <option value="">Select </option>
                    <option value="approved">Approved</option>
                    <option value="reject" selected>Rejected</option>
                  </select>
                </div>
              </td> -->
              <td style="width: 11%; text-align: center;">
                <span class="custom-crossmark">&#10060;</span> 
              </td>
            </tr>
          </tbody>
        </table>

        <div class="row">
          <div class="col">
            <div class="d-flex justify-content-center" *ngIf="adminApprovalCategories.length != 0">
              <pagination-controls (pageChange)="pageChanged($event)" (pageBoundsCorrection)="pageChanged($event)">
              </pagination-controls>
            </div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
 </div>
 <div id="deleteModal" class="modal" [ngStyle]="{'display': showDeleteModal ? 'block' : 'none', 'overflow': 'hidden' }">
    <form class="modal-content-1" action="/action_page.php" style="width: 35%;">
      <div class="container">
        <div class="modal-header" style="padding-top: 0;">
          <h5 class="modal-title" id="exampleModalLabel" style="margin-left: auto;">Confirm</h5>
          <button type="button" id="dismissModal" (click)="dismissModal()" class="btn-close" data-bs-dismiss="modal"
            aria-label="Close"></button>
        </div>
        <div style="padding-top: 15px; padding-bottom: 15px; text-align: center;">
          <ng-container *ngIf="currentData">
            <ng-container *ngIf="currentData.isDeleted == 'Y'">
              <p>Profession <b>{{currentData.mainProfessionName}}</b> has been deleted</p>
            </ng-container>
            <ng-container *ngIf="currentData.isDeleted == 'N' && !currentData.mainProfessionNameNew">
              <p>Profession <b>{{currentData.mainProfessionName}}</b> has been Added</p>
            </ng-container>
            <ng-container *ngIf="currentData.isDeleted == 'N' && currentData.mainProfessionNameNew">
              <p>Profession Has been Updated from <b>{{currentData.mainProfessionName}}</b> to
                <b>{{currentData.mainProfessionNameNew}}</b>
              </p>
            </ng-container>
          </ng-container>
        </div>
        <div class="modal-footer" style="padding-bottom: 0;">
          <button type="button" (click)="dismissModal()" class="btn btn-danger footer-button ml-auto">NO </button>
          <button type="button" (click)="handleApprovalOrRejection()"
            class="btn btn-primary footer-button mr-auto">Yes</button>
        </div>
      </div>
    </form>
  </div>

