import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { NgSelectComponent } from '@ng-select/ng-select';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServerApisService } from 'src/app/shared/services/server-apis.service';
import { Role } from 'src/app/shared/enums/role.enum';
import { Constants, navConstants } from 'src/app/shared/constants/constant';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { RouteMakingService } from 'src/app/shared/services/route-making.service';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {
  @ViewChild('selectBox') selectBox: NgSelectComponent;
  p = 1;
  isCard: boolean = true;
  page: string = 'usersfordigitalteam';
  adminPanel: string = Constants.adminPanel;
  reports: string = Constants.reports;
  reportsNav: string = navConstants.userInformationNav;
  adminPanelRoute: string = Constants.adminPanelRoute;
  reportsTitle: string = Constants.userInformationTitle;
  isRotated = false;

  constructor(private router: Router, private formBuilder: FormBuilder,
    private _apiService: ServerApisService, private spinner: NgxSpinnerService, private cdr: ChangeDetectorRef, private datePipe: DatePipe, private sanitizer: DomSanitizer, private routeMakingService: RouteMakingService) {
  }

  ngOnInit(): void {
    const user = sessionStorage.getItem('user');
    if (user != null) {
      let data = JSON.parse(user);
      if (data.roleId === Role.Admin || data.roleId === Role.CallCenter) {
        let path = window.location.href.split('reports/');
        if (path[1] == 'user-information') {
          this.searchtab('usersfordigitalteam');
        } else if (path[1] == 'profile-transaction-logs') {
          this.searchtab('allusersdisplay');
        } else if (path[1] == 'notifications') {
          this.searchtab('notifications');
        } else if (path[1] == 'daily-count') {
          this.searchtab('dailycount');
        } else if (path[1] == 'profile-data') {
          this.searchtab('profiledata');
        } else if (path[1] == 'activity-data') {
          this.searchtab('activityData');
        }
      } else {
        this.router.navigate([""]);
      }
    } else {
      let params = [Constants.Login];
      let routingUrl = this.routeMakingService.getNavigateUrl(params);
      this.router.navigate([routingUrl]);
    }
  }

  searchtab(pageName: string) {
    let reportsTitle: string;
    if (pageName === 'usersfordigitalteam') {
      reportsTitle = Constants.userInformationTitle;
      this.reportsNav = navConstants.userInformationNav;
      this.router.navigate([this.routeMakingService.getNavigateUrl([Constants.adminPanelRoute, Constants.reports, navConstants.userInformationNav])]);
    } else if (pageName === 'allusersdisplay') {
      reportsTitle = Constants.profileTransactionTitle;
      this.reportsNav = navConstants.profileTransactionNav;
      this.router.navigate([this.routeMakingService.getNavigateUrl([Constants.adminPanelRoute, Constants.reports, navConstants.profileTransactionNav])]);
    } else if (pageName === 'notifications') {
      reportsTitle = Constants.NotificationTitle;
      this.reportsNav = navConstants.NotificationNav;
      this.router.navigate([this.routeMakingService.getNavigateUrl([Constants.adminPanelRoute, Constants.reports, navConstants.NotificationNav])]);
    } else if (pageName === 'dailycount') {
      reportsTitle = Constants.dailyCountTitle;
      this.reportsNav = navConstants.dailyCountNav;
      this.router.navigate([this.routeMakingService.getNavigateUrl([Constants.adminPanelRoute, Constants.reports, navConstants.dailyCountNav])]);
    } else if (pageName === 'profiledata') {
      reportsTitle = Constants.profileDataTitle;
      this.reportsNav = navConstants.profileDataNav;
      this.router.navigate([this.routeMakingService.getNavigateUrl([Constants.adminPanelRoute, Constants.reports, navConstants.profileDataNav])]);
    } else if (pageName === 'activityData') {
      reportsTitle = Constants.activityDataTitle;
      this.reportsNav = navConstants.activityDataNav;
      this.router.navigate([this.routeMakingService.getNavigateUrl([Constants.adminPanelRoute, Constants.reports, navConstants.activityDataNav])]);
    }
    this.page = pageName;
    this.reportsTitle = reportsTitle;
  }

  onMouseEnter() {
     this.isCard = true;
   }

   onMouseLeave() {
     this.isCard = false;
   }

}
