<table class="table table-striped">
    <thead>
        <tr>
            <th style="width: 40%;" class="padleft-15" (click)="registereddatesort()">Registration
                Date&nbsp;<span><i class="fa fa-long-arrow-up" style="margin: 0;"
                        [ngClass]="datesort == 'up' ? 'black' : 'grey'"></i>
                    <i class="fa fa-long-arrow-down" style="margin: 0;"
                        [ngClass]="datesort == 'down' ? 'black' : 'grey'"></i></span></th>
            <th style="width: 60%;" class="padright-15">Registration Count</th>
        </tr>
    </thead>
    <tbody>
        <tr
            *ngFor="let d of lstDailyCount| paginate: { itemsPerPage: 15, currentPage: p};let i = index">
            <td style="width: 40%;" class="hand padleft-15"
                onclick="document.getElementById('registersdata').style.display='block'"
                (click)="getRegistedInfo(d)">{{d.RegistrationDate | date: 'dd/MM/yyyy'}}</td>
            <td style="width: 60%;" class="hand padright-15"
                onclick="document.getElementById('registersdata').style.display='block'"
                (click)="getRegistedInfo(d)">{{d.UserCount}}</td>
        </tr>
    </tbody>
</table>
<div class="row">
    <div class="col">
        <div class="d-flex justify-content-center" *ngIf="lstDailyCount.length!=0">
            <pagination-controls (pageChange)="pageChanged($event)"
                (pageBoundsCorrection)="pageChanged($event)">
            </pagination-controls>
        </div>

    </div>
</div>
<div class="modal" id="registersdata" style="overflow: hidden;padding-top: 50px;">
    <div class="modal-content" style="width: 90%;">
        <div class="modal-header" style="padding-top: 0;">
            <h4 class="modal-title text-center" id="myModalLabel">{{selectedPopDate | date: 'dd/MM/yyyy'}}
                &nbsp;Register's Data</h4>
            <span onclick="document.getElementById('registersdata').style.display='none'" class="close hand"
                title="Close Modal" (click)="close()">×</span>
        </div>
        <div class="modal-body" *ngIf="lstUsersByRegisteredDate" style="padding-bottom: 0;">
            <div class="table-title">
                <div class="row">

                    <div class="search-box">
                        <div class="row">
                            <div class="col-12"> <input type="text" id="search" class="form-control"
                                    autocomplete="off"
                                    placeholder="Search with Name, MobileNo, EmailID, Location"
                                    [(ngModel)]="dailyCountSearch" style="color: #000;height: 38px;"
                                    (input)="changeDailyCountSearch($event)">
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <table  class="table table-striped">
                <thead>
                    <tr>
                        <th style="width: 9%;">Id</th>
                        <th style="width: 14%">Name</th>
                        <th style="width: 12%;">Mobile No</th>
                        <th style="width: 19%;">Email Id</th>
                        <th style="width: 14%;">Organisation Name</th>
                        <th style="width: 9%;">Profile Active</th>
                        <th style="width: 23%;">Address</th>
                    </tr>
                </thead>

            </table>
            <div class="reportstbl">
                <table  class="table table-striped">
                    <tbody>
                        <!-- | paginate: { itemsPerPage: 10, currentPage: p} -->
                        <tr *ngFor="let d of lstUsersByRegisteredDate;let i = index">
                            <td style="width: 9%;">{{d.MemberID}}</td>
                            <td style="width: 14%;">{{d.OrganisationName}}</td>
                            <td style="width: 12%;">{{d.MobileNo}}</td>
                            <td style="width: 19%;">{{d.EmailID}}</td>
                            <td style="width: 14%;">{{d.OrganisationName}}</td>
                            <td style="width: 9%;">{{d.ProfileActive}}</td>
                            <td style="width: 23%;">{{d.Address}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
          </div>

    </div>


</div>