
import { PlatformLocation } from '@angular/common';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ActivatedRoute } from '@angular/router'
@Injectable({
  providedIn: 'root'
})
export class ServerApisService {

  dataObj: any;
  prf$ = new Subject<any>();
  profile$ = this.prf$.asObservable();
  currentCountryName$ = new Subject<any>();
  sList$ = new Subject<any>();
  servicesList$ = this.sList$.asObservable();
  token = sessionStorage.getItem('token');

  constructor(private http: HttpClient, private activatedRoute: ActivatedRoute, private url: PlatformLocation) { }

  geturl() {
    return environment.apiUrl;
  }
  getweburl() {
    return environment.webUrl;
  }

  generateSitemap(name, index): string {
    // Generate your sitemap URLs dynamically here
    const API_URL = this.geturl();
    var tempurl = API_URL.split("/", 3);
    var getapiUrl = tempurl[0] + "//" + tempurl[2];
    const urls = [getapiUrl + '/profile/' + `${name}-${index}` + '/sitemap.xml'];
    //const urls =[`https://toskie.com/profile/${name}-${index}`];
    return this.createSitemapXml(urls);
  }
  private createSitemapXml(urls: string[]): string {
    const xmlItems = urls.map((url) => `<url><loc>${url}</loc></url>`).join('\n');
    return `<?xml version="1.0" encoding="UTF-8"?>
    <urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
      ${xmlItems}
    </urlset>`;
  }
  userLogin(ul) {
    const API_URL = this.geturl();
    return this.http.post<any>(API_URL + '/UserLogin', ul);
  }
  loginAccount(user) {
    const API_URL = this.geturl();
    return this.http.post<any>(API_URL + '/LoginAccount', user);
  }
  removeOrganizationImage(details) {
    const API_URL = this.geturl();
    return this.http.post<any>(API_URL + '/RemoveOrganisationImages', details);
  }

  userRegistration(ur, fileStream) {
    const API_URL = this.geturl();
    const cntrycd = '+' + ur.countryCode;
    return this.http.post<any>(API_URL + '/AddUserRegistration?accountId=' + ur.accountId + '&fname=' + ur.fname +
      '&' + 'lname=' + ur.lname + '&email=' + ur.email + '&mobile=' + ur.mobile + '&password=' + ur.password +
      '&serviceId=' + ur.serviceId + '&houseNo=' + ur.houseNo + '&area=' + ur.area + '&city=' + ur.City +
      '&DeviceType=' + ur.DeviceType + '&pinCode=' + ur.pinCode + '&longitude=' +
      ur.longitude + '&latitude=' + ur.latitude + '&countryCode=' + cntrycd + '&fullAddress=' + ur.fullAddress +
      '&country_id=' + ur.country_id + '&state_id=' + ur.state_id + '&fileName=' + ur.fileName, fileStream);
  }

  createAccount(payload) {
    const API_URL = this.geturl();
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const payloadJson = JSON.stringify(payload);
    return this.http.post<any[]>(API_URL + '/CreateAccount', payloadJson, { headers });
  }

  getAllBlogs() {
    const API_URL = this.geturl();
    return this.http.post<any>(API_URL + '/GetAllBlogs', '');
  }
  getAllSubProfessionCategory() {
    const API_URL = this.geturl();
    return this.http.get<any>(API_URL + '/GetAllSubProfessionCategory');
  }
  FetchAllChildProfessions() {
    const API_URL = this.geturl();
    return this.http.get<any>(API_URL + '/FetchAllChildProfessions');
  }
  insertBlog(ur, fileStream) {
    const API_URL = this.geturl();
    return this.http.post<any>(API_URL + '/InsertBlog?memberId=' + ur.memberId, fileStream);
  }

  fetchAccountSponspored() {
    const API_URL = this.geturl();
    return this.http.get<any>(API_URL + '/FetchUserAccountSponsoredUnSponsored');
  }

  updateAccountSponsoredUnSponsored(payload) {
    const API_URL = this.geturl();
    return this.http.post<any>(API_URL + '/UpdateAccountSponsoredUnSponsored', payload);
  }

  autoUpdateAccountSponsoredUnSponsored() {
    const API_URL = this.geturl();
    return this.http.get<any>(API_URL + '/AutoUpdateAccountSponsoredUnSponsored');
  }

  UpdateProfile(up, fileStream) {
    const API_URL = this.geturl();
    return this.http.post<any[]>(API_URL + '/UpdateToskieProfile?memberId=' + up.memberId + '&mobile=' + up.mobile + '&fname=' + up.fname +
      '&' + 'lname=' + up.lname + '&longitude=' + up.longitude + '&latitude=' + up.latitude + '&fileName=' + up.fileName
      + '&profileDescription=' + up.profileDescription + '&email=' + up.email + '&countryCode=' + up.countryCode
      + '&fullAddress=' + up.fullAddress + '&UPImobile=' + '' + '&token=' + up.token + '&country_id=' + up.country_id
      + '&organisationName=' + up.organisationName
      , fileStream);
  }
  UpdateUserProfile(payload, fileStream) {
    const API_URL = this.geturl();
    const queryString = Object.keys(payload)
      .map(key => key + '=' + encodeURIComponent(payload[key]))
      .join('&');

    return this.http.post<any[]>(API_URL + '/UpdateUserProfile?' + queryString, fileStream);
  }
  GetDashboardDetails(up) {
    const API_URL = this.geturl();
    var tempurl = API_URL.split("/", 3);
    let url = tempurl[0] + "//" + tempurl[2];
    return this.http.get<any[]>(API_URL + '/GetDashboardDetails?memberId=' + up.memberId + '&apiUrl=' + url + '&token=' + up.token);
  }
  uploadOrganisationImages(up, fileStream) {
    const API_URL = this.geturl();
    return this.http.post<any[]>(API_URL + '/UploadOrganisationImages?memberId=' + up.memberId
      + '&organisationImageName=' + up.organisationImageName + '&fileType=' + up.fileType + '&apiUrl=' + API_URL,
      fileStream);
  }
  GetActivities() {
    const API_URL = this.geturl();
    return this.http.get(API_URL + '/TrackActivityLogs');
  }

  InsertActivityLogWithoutLogIn(payload) {
    const API_URL = this.geturl();
    return this.http.post<any>(API_URL + '/InsertActivityLogWithoutLogIn', payload);
  }

  GetProfileData(payload) {
    const API_URL = this.geturl();
    let url = this.getweburl();
    return this.http.get<any>(API_URL + '/FetchReportUserProfileDataList?websiteUrl=' + url + '/profile&currentPage=' + payload.page + '&memberId=' + payload.searchedMemberID + '&name=' + payload.searchedName + '&userUniqueId=' + payload.searchedUniqueID);
}

GetOrganizationImage(id) {
    const API_URL = this.geturl();
    return this.http.get<any>(API_URL + '/GetAllOrganisationalImages?memberId=' + id);
  }
  FetchAllOrganisationNames() {
    const API_URL = this.geturl();
    return this.http.get(API_URL + '/FetchAllOrganisationNames');
  }
  GetAllSubProfessionCategories() {
    const API_URL = this.geturl();
    return this.http.get(API_URL + '/GetAllSubProfessionCategory');
  }
  getAllSiteMapList(payload) {
    const API_URL = this.geturl();
    return this.http.get<any>(API_URL + '/FetchAllSiteMapList?currentPage=' + payload.pageNo + '&loc=' + payload.loc + '&fromDate=' + payload.fromDate + '&toDate=' + payload.toDate);
  }
  getActivityLogWithoutLogin(payload) {
    const API_URL = this.geturl();
    return this.http.get<any>(`${API_URL}/GetActivityLogWithoutLogin?name=${payload.name}&mobileNo=${payload.mobileNo}&activityDescription=${payload.activityDescription}&currentPage=${payload.currentPage}&approvalType=${payload.approvalType}`);
  }

  GetSubProfessionalCategories(payLoad) {
    const API_URL = this.geturl();
    return this.http.post<any>(API_URL + '/FetchAllServices', payLoad);
  }

  GetCompeteProfileData(payLoad) {
    const API_URL = this.geturl();
    return this.http.post<any>(API_URL + '/CompleteProfile', payLoad);
  }

  saveService(payLoad) {
    const API_URL = this.geturl();
    return this.http.post<any>(API_URL + '/SaveService', payLoad);
  }
  deleteServiceCategory(payLoad) {
    const API_URL = this.geturl();
    return this.http.post<any>(API_URL + '/DeleteServiceCategory', payLoad);
  }
  GetAllUsersDisplayReport() {
    const API_URL = this.geturl();
    return this.http.get<any>(API_URL + '/GetAllUsersDisplayReport');
  }

  GetAllUsersDigitalTeamReport() {
    const API_URL = this.geturl();
    const payload: any = {};
    var tempurl = API_URL.split("/", 3);
    payload.apiUrl = tempurl[0] + "//" + tempurl[2];
    return this.http.post<any>(API_URL + '/GetAllUsersDigitalTeamReport', payload);
  }

  GetActivityLogWithoutLogin(data) {
    const API_URL = this.geturl();
    return this.http.get<any>(API_URL + '/GetActivityLogWithoutLogin?name=' + data.name + '&mobileNo=' + data.mobileNo + '&activityDescription=' + data.activityDescription + '&currentPage=' + data.currentPage + '&approvalType=' + data.approvalType);
  }
  GetAllUsersInformationReport(data) {
    const API_URL = this.geturl();
     return this.http.get<any>( API_URL + '/GetAllUsersInformationReport?currentPage=' + data.currentPage + '&name=' + data.name + '&fromDate=' + data.fromDate + '&toDate=' + data.toDate + '&uniqueId=' + data.uniqueId + '&mobileNo=' + data.mobileNo + '&profession=' + data.profession + '&country=' +data.country +'&state=' + data.state + '&zipCode=' + data.zipCode + '&isDeleted=' + data.isDeleted);
 }

getAllNotifications(page) {
 const API_URL = this.geturl();
    return this.http.get<any>(API_URL + '/FetchAllNotificationList?currentPage=' + page);
  }
  fetchNotificationsDetails(id) {

    const API_URL = this.geturl();
    return this.http.get<any>(API_URL + '/FetchNotificationDeatils?notificationId=' + id);
  }
  updateSubProfessionalCategories(payLoad) {
    const API_URL = this.geturl();
    return this.http.post<any>(API_URL + '/UpdateSubProfessionalCategory', payLoad);
  }
  UpdateActivityLogWithoutLogin(payLoad) {
    const API_URL = this.geturl();
    return this.http.post<any>(API_URL + '/UpdateActivityLogWithoutLogin', payLoad);
  }
  updateAccountVerifiacation(payLoad) {
    const API_URL = this.geturl();
    return this.http.post<any>(API_URL + '/InsertUpdateAccountVerificationDetails', payLoad);
  }
  fetchAllProfessions(payLoad) {
    const API_URL = this.geturl();
    return this.http.post<any>(API_URL + '/FetchAllProfessionsWebsite', payLoad);
  }
  GetExceptionLogs() {
    const API_URL = this.geturl();
    return this.http.get(API_URL + '/GetExceptionlog');
  }
  GetProviderServiceData(ps) {
    const API_URL = this.geturl();
    var tempurl = API_URL.split("/", 3);
    ps.apiUrl = tempurl[0] + "//" + tempurl[2];
    return this.http.post<any>(API_URL + '/FetchHireAnExpert', ps);
  }
  GetChildProfessionData(id) {
    const API_URL = this.geturl();
    return this.http.get<any>(API_URL + '/GetChildProfession?childProfessionId=' + id);
  }
  GetHireAndExpert(payload): Observable<any> {
    const API_URL = this.geturl();
    const tempurl = API_URL.split("/", 3);
    payload.apiUrl = tempurl[0] + "//" + tempurl[2];

    // Create a HttpParams object for the query parameters
    const params = new HttpParams({
      fromObject: payload,
    });

    // Make the GET request with the URL and query parameters
    return this.http.get(API_URL + '/GetHireAnExpert', { params });
  }
  GetFetchUserAccountVerification() {
    const API_URL = this.geturl();
    return this.http.get<any>(API_URL + '/FetchUserAccountVerification');
  }
  GetProfileVideoUrl(payload) {
    const API_URL = this.geturl();
    return this.http.get<any>(API_URL + '/FetchAllUsersByProfileVideoUrl?currentPage=' + payload.page + '&memberId=' + payload.searchedMemberID + '&name=' + payload.searchedName + '&userUniqueId=' + payload.searchedUniqueID);
  }

  GetRoles() {
    const API_URL = this.geturl();
    return this.http.get<any>(API_URL + '/FetchAlluserRoles');
  }
  GetVendors() {
    const API_URL = this.geturl();
    return this.http.get<any>(API_URL + '/FetchAllVendors');
  }
  GetMainProfession() {
    const API_URL = this.geturl();
    return this.http.get<any>(API_URL + '/FetchAllMainProfessions');
  }
  GetAdminApprovalCategories(type) {
    const API_URL = this.geturl();
    return this.http.get<any>(API_URL + '/FetchCategoriesAdminApproval?approvalType=' + type);
  }
  GetAdminApprovalsubCategories(type) {
    const API_URL = this.geturl();
    return this.http.get<any>(API_URL + '/FetchChildCategoriesAdminApproval?approvalType=' + type);
  }
  InsertMainProfession(data) {
    const API_URL = this.geturl();
    return this.http.post<any>(API_URL + '/InsertMainProfessionAdminApproval', data)
  }
  AdminApprovalApi(data) {
    const API_URL = this.geturl();
    return this.http.post<any>(API_URL + '/AdminApprovalMainProfession', data)
  }
  AdminApprovalsubApi(data) {
    const API_URL = this.geturl();
    return this.http.post<any>(API_URL + '/AdminApprovalChildProfession', data)
  }
  GetVendorMaskingNumbers() {
    const API_URL = this.geturl();
    return this.http.get<any>(API_URL + '/FetchAllMaskingMobileNumbers');
  }
  GetRoleAssigners() {
    const API_URL = this.geturl();
    return this.http.get<any>(API_URL + '/FetchAllUsersByRoles');
  }
  GetVendorAssigners() {
    const API_URL = this.geturl();
    return this.http.get<any>(API_URL + '/FetchAllMembersByVendor');
  }
  FetchUsersByRegisteredDate(payLoad) {
    const API_URL = this.geturl();
    return this.http.post<any>(API_URL + '/FetchUsersByRegisteredDate', payLoad);
  }
  deleteRole(payLoad) {
    const API_URL = this.geturl();
    return this.http.post<any>(API_URL + '/DeleteRoleAdminApproval', payLoad);
  }
  deleteVendor(payLoad) {
    const API_URL = this.geturl();
    return this.http.post<any>(API_URL + '/DeleteVendor', payLoad);
  }
  deleteMainProfession(payLoad) {
    const API_URL = this.geturl();
    return this.http.post<any>(API_URL + '/DeleteMainProfessionAdminApproval', payLoad);
  }
  deleteChildProfession(payLoad) {
    const API_URL = this.geturl();
    return this.http.post<any>(API_URL + '/DeleteChildProfessionAdminApproval', payLoad);
  }
  updateRole(payLoad) {
    const API_URL = this.geturl();
    return this.http.post<any>(API_URL + '/UpdateRole', payLoad);
  }
  updateMainProfession(payLoad) {
    const API_URL = this.geturl();
    return this.http.post<any>(API_URL + '/UpdateMainProfessionAdminApproval', payLoad);
  }
  updateChildProfession(payload) {
    const API_URL = this.geturl();
    return this.http.post<any>(API_URL + '/UpdateChildProfessionAdminApproval', payload);
  }
  UpdateProfileVideoUrl(payload) {
    const API_URL = this.geturl();
    return this.http.post<any>(API_URL + '/InsertUpdateProfileVideoUrl', payload);
  }

  updateVendorAssigning(payLoad) {
    const API_URL = this.geturl();
    return this.http.post<any>(API_URL + '/VendorMemberAssigning', payLoad);
  }
  updatemaskingnumberVendor(payLoad) {
    const API_URL = this.geturl();
    return this.http.post<any>(API_URL + '/VendorMaskingNumberAssigning', payLoad);
  }
  DeleteProfileVideo(memberId, deletedById, buttonId) {
    return this.http.get<any>(this.geturl() + `/DeleteProfileVideoUrl?memberId=${memberId}&deletedBy=${deletedById}&customButonsId=${buttonId}`)
  }
  DeleteServiceCategory(payLoad) {
    const API_URL = this.geturl();
    return this.http.post<any>(API_URL + '/DeleteServiceCategoryByAdmin', payLoad);
  }

  UpdateRoleAdminApproval(payLoad) {
    const API_URL = this.geturl();
    return this.http.post<any>(API_URL + '/UpdateRoleAdminApproval', payLoad);
  }
  InsertRolesAdminApproval(payLoad) {
    const API_URL = this.geturl();
    return this.http.post<any>(API_URL + '/InsertRolesAdminApproval', payLoad);
  }

  FetchUserRolesAdminApproval(type) {
    const API_URL = this.geturl();
    return this.http.get<any>(API_URL + '/FetchUserRolesAdminApproval?approvalType=' + type);
  }

  AdminApprovalUserRoles(payload) {
    const API_URL = this.geturl();
    return this.http.post<any>(API_URL + '/AdminApprovalUserRoles', payload);
  }

  GetFetchUserPrifileAccountVerification(payLoad) {
    const API_URL = this.geturl();
    var tempurl = API_URL.split("/", 3);
    payLoad.apiUrl = tempurl[0] + "//" + tempurl[2];
    return this.http.post<any>(API_URL + '/FetchUserVerificationDetails', payLoad);
  }
  GetFindClientsData(ps) {
    const API_URL = this.geturl();
    var tempurl = API_URL.split("/", 3);
    ps.apiUrl = tempurl[0] + "//" + tempurl[2];
    return this.http.post<any>(API_URL + '/SearchFindClients', ps);
  }
  sendPublicPushNotification(up, fileStream) {
    const API_URL = this.geturl();
    var tempurl = API_URL.split("/", 3);
    var url = tempurl[0] + "//" + tempurl[2];
    const encodedText = encodeURIComponent(up.message);
    const upJsonString = JSON.stringify(API_URL + '/SendToskieNotifications?title=' + up.title + '&message=' + encodedText +
      '&receiverId=' + up.receiverId + '&senderId=' + up.senderId + '&videoUrlPath=' + up.videoUrlPath +
      '&imageName=' + up.imageName + '&expiryDate=' + up.expiryDate + '&apiUrl=' + url + '&countryId=' + up.countryId + '&stateId=' + up.stateId + '&zipCode=' + up.zipCode +
      '&notificationType=' + up.notificationType + '&countryName=' + up.countryName + '&stateName=' + up.stateName +
      '&cityId=' + up.cityId + '&cityName=' + up.cityName + '&zipCodeId=' + up.zipCodeId + '&receiverTypeSkills=' + up.receiverTypeSkills +
      '&receiverTypeOrgName=' + up.receiverTypeOrgName + '&receiverType=' + up.receiverType);
    const payloadSizeInBytes = this.calculatePayloadSize(upJsonString);

    const payloadSizeInKB = payloadSizeInBytes / 1024;
    console.log(payloadSizeInKB);
    if (payloadSizeInKB < 4) {
      const result: any = 'sucess';
      this.http.post<any[]>(API_URL + '/SendToskieNotifications?title=' + up.title + '&message=' + encodedText +
        '&receiverId=' + up.receiverId + '&senderId=' + up.senderId + '&videoUrlPath=' + up.videoUrlPath +
        '&imageName=' + up.imageName + '&expiryDate=' + up.expiryDate + '&apiUrl=' + url + '&countryId=' + up.countryId + '&stateId=' + up.stateId + '&zipCode=' + up.zipCode +
        '&notificationType=' + up.notificationType + '&countryName=' + up.countryName + '&stateName=' + up.stateName +
        '&cityId=' + up.cityId + '&cityName=' + up.cityName + '&zipCodeId=' + up.zipCodeId + '&receiverTypeSkills=' + up.receiverTypeSkills +
        '&receiverTypeOrgName=' + up.receiverTypeOrgName + '&receiverType=' + up.receiverType,
        fileStream).subscribe(
          (response) => {
            return response;
          });
      return throwError(result);
    }
    else {
      // Payload size exceeds the limit, return an error message

      return throwError('Payload size exceeds the limit (4 KB)');
    }
  }

  sendPublicPushNotificationViaFirebase(up, fileStream) {
    const API_URL = this.geturl();
    var tempurl = API_URL.split("/", 3);
    var url = tempurl[0] + "//" + tempurl[2];
    const encodedText = encodeURIComponent(up.message);
    const encodedTitle = encodeURIComponent(up.title);
    const upJsonString = JSON.stringify(API_URL + '/PushNotificationViaFirebase?title=' + encodedTitle + '&message=' + encodedText +
      '&receiverId=' + up.receiverId + '&senderId=' + up.senderId + '&videoUrlPath=' + up.videoUrlPath +
      '&imageName=' + up.imageName + '&expiryDate=' + up.expiryDate + '&apiUrl=' + url + '&countryId=' + up.countryId + '&stateId=' + up.stateId + '&zipCode=' + up.zipCode +
      '&notificationType=' + up.notificationType + '&countryName=' + up.countryName + '&stateName=' + up.stateName +
      '&cityId=' + up.cityId + '&cityName=' + up.cityName + '&zipCodeId=' + up.zipCodeId + '&receiverTypeSkills=' + up.receiverTypeSkills +
      '&receiverTypeOrgName=' + up.receiverTypeOrgName + '&receiverType=' + up.receiverType);
    const payloadSizeInBytes = this.calculatePayloadSize(upJsonString);

    const payloadSizeInKB = payloadSizeInBytes / 1024;
    console.log(payloadSizeInKB);
    if (payloadSizeInKB < 4) {
      const result: any = 'success';
      return this.http.post<any[]>(API_URL + '/PushNotificationViaFirebase?title=' + encodedTitle + '&message=' + encodedText +
        '&receiverId=' + up.receiverId + '&senderId=' + up.senderId + '&videoUrlPath=' + up.videoUrlPath +
        '&imageName=' + up.imageName + '&expiryDate=' + up.expiryDate + '&apiUrl=' + url + '&countryId=' + up.countryId + '&stateId=' + up.stateId + '&zipCode=' + up.zipCode +
        '&notificationType=' + up.notificationType + '&countryName=' + up.countryName + '&stateName=' + up.stateName +
        '&cityId=' + up.cityId + '&cityName=' + up.cityName + '&zipCodeId=' + up.zipCodeId + '&receiverTypeSkills=' + up.receiverTypeSkills +
        '&receiverTypeOrgName=' + up.receiverTypeOrgName + '&receiverType=' + up.receiverType,
        fileStream)
    }
    else {
      // Payload size exceeds the limit, return an error message

      return throwError('Payload size exceeds the limit (4 KB)');
    }
  }

  private calculatePayloadSize(upJsonString: string): number {
    const upSizeInBytes = new TextEncoder().encode(upJsonString).length;
    let size = upSizeInBytes;

    return size;
  }
  sendAdvertisements(up, fileStream) {
    const API_URL = this.geturl();
    var tempurl = API_URL.split("/", 3);
    var url = tempurl[0] + "//" + tempurl[2];
    return this.http.post<any[]>(API_URL + '/InsertAdvertisements?addsPostedMemberId=' + up.addsPostedMemberId + '&fname=' + up.fname + '&title=' + up.title +
      '&description=' + up.description + '&category=' + up.category + '&slideShow=Yes&screen=' + up.screen + '&price=' + up.price + '&imageName=' + up.imageName +
      '&videoURL=' + up.videoURL + '&expiryDate=' + up.expiryDate + '&contactEmail=' + up.contactEmail + '&contactPhone=' + up.contactPhone + '&redirectLink=' + up.redirectLink +
      '&clientType=' + up.clientType + '&clientNavigation=' + up.clientNavigation + '&navigatingToMemberId=' + up.navigatingToMemberId, fileStream);
  }
  udpateAdvertisements(up, fileStream) {
    const API_URL = this.geturl();
    var tempurl = API_URL.split("/", 3);
    var url = tempurl[0] + "//" + tempurl[2];
    return this.http.post<any[]>(API_URL + '/UpdateAdvertisements?addsId=' + up.addsId + '&addsPostedMemberId=' + up.addsPostedMemberId + '&fname=' + up.fname + '&title=' + up.title +
      '&description=' + up.description + '&category=' + up.category + '&slideShow=Yes&screen=' + up.screen + '&price=' + up.price + '&imageName=' + up.imageName +
      '&videoURL=' + up.videoURL + '&expiryDate=' + up.expiryDate + '&contactEmail=' + up.contactEmail + '&contactPhone=' + up.contactPhone + '&redirectLink=' + up.redirectLink +
      '&clientType=' + up.clientType + '&clientNavigation=' + up.clientNavigation + '&navigatingToMemberId=' + up.navigatingToMemberId, fileStream);
  }
  FetchAllUserCountByRegisteredDate() {
    const API_URL = this.geturl();
    return this.http.get(API_URL + '/FetchAllUserCountByRegisteredDate');
  }
  GetProviderServices(ps) {

    const API_URL = this.geturl();
    //var tempurl= API_URL.split(['/'][2]);
    //ps.apiUrl=tempurl[0]+"//"+tempurl[2];;
    return this.http.post<any>(API_URL + '/GetProviderServices', ps);
  }

  GetServicesInNeed(sn) {
    const API_URL = this.geturl();
    var tempurl = API_URL.split(['/'][2]);
    sn.apiUrl = tempurl[0] + "//" + tempurl[2];
    return this.http.post<any>(API_URL + '/GetServicesInNeed', sn);
  }

  FetchAllAds() {
    const API_URL = this.geturl();
    var tempurl = API_URL.split("/", 3);
    const payLoad: any = {};
    payLoad.apiUrl = tempurl[0] + "//" + tempurl[2];
    return this.http.post<any>(API_URL + '/FetchAllAds', payLoad);
  }
  FetchAddsDetails(payload) {
    const API_URL = this.geturl();
    var tempurl = API_URL.split(['/'][2]);
    payload.apiUrl = tempurl[0] + "//" + tempurl[2];
    return this.http.post<any>(API_URL + '/FetchAddsDetails', payload);
  }
  DeleteAdvertisements(payload) {
    const API_URL = this.geturl();
    return this.http.post<any>(API_URL + '/DeleteAdvertisements', payload);
  }
  NotificationDetails(payload) {
    const API_URL = this.geturl();
    return this.http.post<any>(API_URL + '/FetchAppNotificationDetails', payload);
  }

  UpdateNotificationIsRead(payload) {
    const API_URL = this.geturl();
    return this.http.post<any>(API_URL + '/UpdateNotificationIsRead', payload);
  }

  InsertWhishlist(payload) {
    const API_URL = this.geturl();
    return this.http.post<any>(API_URL + '/InsertWhishlist', payload);
  }
  DeleteWhishlist(payload) {
    const API_URL = this.geturl();
    return this.http.post<any>(API_URL + '/DeleteWhishlist', payload);
  }
  SubmitServiceInNeed(ssn) {
    const API_URL = this.geturl();
    return this.http.post<any>(API_URL + '/SubmitServiceInNeed', ssn);
  }
  InsertUpdateVendors(payload) {
    const API_URL = this.geturl();
    return this.http.post<any>(API_URL + '/InsertUpdateVendors', payload);
  }
  checkMobileNum(details) {
    const API_URL = this.geturl();
    return this.http.get<any>(API_URL + `/IsUserExists?mobile=${details.mobile}&countryCode=${details.countryCode}`);
  }
  deleteAccount(details) {
    const API_URL = this.geturl();
    return this.http.post<any>(API_URL + '/RemoveAccount', details);
  }
  getUserProfile(profile) {

    const API_URL = this.geturl();
    var tempurl = API_URL.split("/", 3);
    profile.apiUrl = tempurl[0] + "//" + tempurl[2];
    return this.http.post<any>(API_URL + '/UserProfile', profile);
  }

  InsertChildProfession(data) {
    const API_URL = this.geturl();
    return this.http.post<any>(API_URL + '/InsertChildProfessionAdminApproval', data);
  }



  getProviderProfile(profile) {

    const API_URL = this.geturl();
    var tempurl = API_URL.split("/", 3);
    profile.apiUrl = tempurl[0] + "//" + tempurl[2];
    return this.http.post<any>(API_URL + '/UserProfile', profile);
  }
  // updatePassword(upass) {
  //   const API_URL = this.geturl();
  //   return this.http.post<any>(API_URL + '/ForgotPassword', upass);
  // }
  submitRatingAndReview(sr) {
    const API_URL = this.geturl();
    return this.http.post<any>(API_URL + '/SubmitRatingAndReview', sr);
  }
  GetRatingAndReview(rr) {
    const API_URL = this.geturl();
    var tempurl = API_URL.split("/", 3);
    rr.apiUrl = tempurl[0] + "//" + tempurl[2];
    return this.http.post<any>(API_URL + '/GetRatingAndReview', rr);
  }
  GetAllRatingAndReview(data) {
    const API_URL = this.geturl();
    return this.http.post<any>(API_URL + '/GetAllRatingAndReview', data);
  }
  AddService(data) {
    const API_URL = this.geturl();
    return this.http.post<any>(API_URL + '/SaveService', data);
  }
  GetCountries() {
    const API_URL = this.geturl();
    return this.http.get(API_URL + '/GetAllCountries');
  }
  GetStatesByCountry(data) {
    const API_URL = this.geturl();
    return this.http.post<any>(API_URL + '/GetSatesByCountry', data);
  }

  GetCitiesByState(data) {
    const API_URL = this.geturl();
    return this.http.post<any>(API_URL + '/GetCitiesByStates', data);
  }
  GetZipcodeByCities(data) {
    const API_URL = this.geturl();
    return this.http.post<any>(API_URL + '/GetZipcodeByCities', data);
  }
  GetAllUsers() {
    const API_URL = this.geturl();
    return this.http.get(API_URL + '/GetAllUsersDropDownValues');
    // return this.http.post<any>(API_URL +'/GetSatesByCountry',data);
  }

  GetAllNearByServices() {
    const API_URL = this.geturl();
    return this.http.get(API_URL + '/FetchAllNearByCategory');
  }

  DeleteNearByCategory(data) {
    const API_URL = this.geturl();
    return this.http.get(API_URL + `/DeleteNearByCategory?nearByServiceCategoryId=${data.nearByServiceCategoryId}&deletedBy=${data.deletedBy}`);
  }

  DeleteNearBySubCategory(data) {
    const API_URL = this.geturl();
    return this.http.get(API_URL + `/DeleteNearBySubCategory?nearByServiceSubCategoryId=${data.nearByServiceSubCategoryId}&deletedBy=${data.deletedBy}`);
  }

  GetAllNearBySubServices() {
    const API_URL = this.geturl();
    return this.http.get(API_URL + '/FetchAllNearBySubCategory');
  }

  InsertUpdateNearByCategory(data) {
    const API_URL = this.geturl();
    return this.http.post<any>(API_URL + '/InsertUpdateNearByCategory', data);
  }

  InsertUpdateNearBySubCategory(data) {
    const API_URL = this.geturl();
    return this.http.post<any>(API_URL + '/InsertUpdateNearBySubCategory', data);
  }

  GetUserActivateStatus(memberId, providerMemberId, token) {
    const API_URL = this.geturl();
    return this.http.get(API_URL + `/GetUserProfileInformation?memberId=${memberId}&providerMemberId=${providerMemberId}&apiUrl=${API_URL}&token=${token}`);
  }

  GetUserWhishlist(memberId) {
    const API_URL = this.geturl();
    return this.http.get(API_URL + `/FetchUserWhishlist?memberId=${memberId}`);
  }

  AutoCompleteServices() {
    const obj = {
      token: this.token,
      serviceName: ''
    }
    const API_URL = this.geturl();
    //obj.serviceName=""
    return this.http.post<any>(API_URL + '/GetMasterServices', obj);

  }

  ProfileTurnOnOff(a) {
    const API_URL = this.geturl();
    return this.http.post<any>(API_URL + '/ProfileTurnOnOff', a);
  }

  CompleteProfileStatus(data) {
    const API_URL = this.geturl();
    return this.http.post<any>(API_URL + '/CompleteProfile', data);
  }

  GetUserUniqueCode(u) {
    const API_URL = this.geturl();
    return this.http.post<any>(API_URL + '/GetUserUniqueCode', u);
  }

  sendContactUs(u) {
    const API_URL = this.geturl();
    return this.http.post<any>(API_URL + '/CustomerSupport', u);
  }

  ExceptionLog(ex) {
    const API_URL = this.geturl();
    ex.token = this.token
    return this.http.post<any>(API_URL + '/ExceptionLog', ex);

  }

  // Tatateli apis for calling functionalities

  FetchTataTeleDetails(data) {
    const API_URL = this.geturl();
    return this.http.post<any>(API_URL + '/FetchTataTeleDetails', data);
  }

  ClickToCall(data, token) {
    const API_URL = "https://api-smartflo.tatateleservices.com/v1/click_to_call";

    // Set the Authorization header
    const headers = new HttpHeaders({
      'Authorization': `${token}`
    });

    return this.http.post<any>(API_URL, data, { headers: headers });
  }

  getCallLogs(payload) {
    const API_URL = this.geturl();
    return this.http.post<any>(API_URL + '/FetchAlCallLogs', payload);
  }


  InsertCallLogs(data) {
    const API_URL = this.geturl();
    return this.http.post<any>(API_URL + '/InsertCallLogs', data);
  }

  CheckCallRecords(data, token) {
    const API_URL = "https://api-smartflo.tatateleservices.com/v1/call/records?call_id=" + data;
    const headers = new HttpHeaders({
      'Authorization': `${token}`
    });
    return this.http.get<any>(API_URL, { headers: headers });
  }


  // SUBJECT AND BEHAVIOR SUBJECT METHODS

  sendUpdate(profile) {

    // return this.http.post<any>(API_URL +'/UserProfile', profile);//the component that wants to update something, calls this fn
    this.prf$.next(profile);
    //next() will feed the value in Subject
  }

  sListUpdate(list) {
    this.sList$.next(list)
  }
  // getUpdate(): Observable<any> { //the receiver component calls this function 
  //     return this.profile$.asObservable(); //it returns as an observable to which the receiver funtion will subscribe
  // }

  currentCountry(data) { //the component that wants to update something, calls this fn
    this.currentCountryName$.next(data); //next() will feed the value in Subject
  }

  // providerList(pl:any){
  //   this.pList$.next(pl);

  // }
  // serviceInNeedList(sl:any){
  //   this.sNeedList$.next(sl);
  // }


  // TO share data between components for editing setdata() and getdata()
  setdata(data) {
    this.dataObj = {};
    this.dataObj = data;

  }
  getdata() {
    let temp = this.dataObj;
    return temp
  }

  private dataSubject = new BehaviorSubject<any>(null);
  //data$: Observable<any> = this.dataSubject.asObservable();
  sendMessage(item: any) {
    this.dataSubject.next(item);
  }
  currentData = this.dataSubject.asObservable();
}
