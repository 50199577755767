import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

import { Alert, AlertType } from './alert.model';
import { ToastrService } from 'ngx-toastr';

@Injectable({ providedIn: 'root' })
export class AlertService {
    constructor(private toastr: ToastrService) {}
    private subject = new Subject<Alert>();
    private defaultId = 'default-alert';

    // enable subscribing to alerts observable
    onAlert(id = this.defaultId): Observable<Alert> {
        return this.subject.asObservable().pipe(filter(x => x && x.id === id));
    }

    // convenience methods
    success(message: string) {
    this.toastr.success(message, null, {
    closeButton: true,
    progressBar: true,
    positionClass: 'toast-center', // You can customize the positionClass
    timeOut: 3000,   
  });
}
error(message: string) {
    this.toastr.error(message, null, {
      closeButton: true,
      progressBar: true,
      positionClass: 'toast-center',
      timeOut: 3000,      
    });
  }
  
  // Function to show a warning toast
  warn(message: string) {
    this.toastr.warning(message, null, {
      closeButton: true,
      progressBar: true,
      positionClass: 'toast-center',
      timeOut: 3000,      
    });
  }

  info(message: string) {
    this.toastr.info(message, null, {
      closeButton: true,
      progressBar: true,
      positionClass: 'toast-center',
      timeOut: 5000,
    });
  }
    // success(message: string) {
    //     this.alert(new Alert({  type: AlertType.Success, message }));
    // }

    // error(message: string) {
    //     this.alert(new Alert({  type: AlertType.Error, message }));
    // }

    // info(message: string) {
    //     this.alert(new Alert({  type: AlertType.Info, message }));
    // }

    // warn(message: string) {
    //     this.alert(new Alert({  type: AlertType.Warning, message }));
    // }

    // main alert method    
    alert(alert: Alert) {
        alert.id = alert.id || this.defaultId;
        this.subject.next(alert);
    }

    // clear alerts
    clear(id = this.defaultId) {
        this.subject.next(new Alert({ id }));
    }
}