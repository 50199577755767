import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/shared/alert/alert.service';
import { ServerApisService } from 'src/app/shared/services/server-apis.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Role } from 'src/app/shared/enums/role.enum';
import { Constants,navConstants } from 'src/app/shared/constants/constant';
import { RouteMakingService } from 'src/app/shared/services/route-making.service';

@Component({
  selector: 'app-vendor-managment',
  templateUrl: './vendor-managment.component.html',
  styleUrls: ['./vendor-managment.component.css']
})
export class VendorManagmentComponent implements OnInit {
  vendorForm: FormGroup;
  @ViewChild('selectBox') selectBox: NgSelectComponent;
  @ViewChild('selectBoxs') selectBoxs:NgSelectComponent;
  lstVendors: any = [];
  lstTempVendors: any = [];
  lstalluserVendors:any=[];
  adminPanel:string=Constants.adminPanel;
  vendorManagement:string=Constants.vendorManagement;
  vendorManagementNav:string=navConstants.vendorCreationNav;
  adminPanelRoute: string = Constants.adminPanelRoute;
  vendorManagementTitle:string=Constants.vendorCreationTitle;
  lstalluserTempVendors:any=[];
  lstassignVendors:any=[];
  storeRole:string='';
  id: number = 0;
  isdisplay: boolean = false
  isEdit: boolean = false;
  isdropdownshow:boolean=false;
  lsttempassignVendors:any=[];
  isrole:boolean=false;
  isCard: boolean = true;
  searchtext:string='';
  vendorCode: string = '';
  vendorName: string = '';
  filterRole:string = '';
  selectedIndex:number;
  isSearchChange: boolean = false;
  oldservice: string = "";
  isPopup:boolean=false;
  newservice: string = "";
  previd: number = 0;
  page: string = 'vendorcreation';
 constructor(private spinner: NgxSpinnerService,private routeMakingService: RouteMakingService, private _apiService: ServerApisService, private router: Router,
    private _alertService: AlertService,private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    const user = sessionStorage.getItem('user');
    if (user != null) {
      let data = JSON.parse(user);
      if (data.roleId == Role.Admin || data.roleId == Role.SubAdmin) {
        this.searchtab(this.page);
        let path=window.location.href.split('vendor-management/')
        if(path[1]==navConstants.vendorCreationNav){
          this.searchtab('vendorcreation')
         }else if(path[1]==navConstants.vendorAssignmentNav){
          this.searchtab('vendorassignment')
         }else if(path[1]==navConstants.vendorNumberAssignmentNav){
          this.searchtab('vendornumberassignment')
         }
      } else {
        this.router.navigate([""]);
      }

    } else {
      this.router.navigate([this.routeMakingService.getNavigateUrl([Constants.Login])]);
    }
    this.vendorForm = this.formBuilder.group({
      selectedUser:[]
    });
  }
  
 searchtab(pageName: string) {
    let  vendorManagementTitle: string;

    if (pageName === 'vendorcreation') {
      vendorManagementTitle = Constants.vendorCreationTitle;
        this. vendorManagementNav = navConstants.vendorCreationNav;
        this.router.navigate([this.routeMakingService.getNavigateUrl([Constants.adminPanelRoute,Constants.vendorManagement,navConstants.vendorCreationNav])]);  
      
    } else if (pageName === 'vendorassignment') {
         vendorManagementTitle = Constants.vendorAssignmentTitle;
        this. vendorManagementNav =  navConstants.vendorAssignmentNav;
        this.router.navigate([this.routeMakingService.getNavigateUrl([Constants.adminPanelRoute,Constants.vendorManagement,navConstants.vendorAssignmentNav])]);  
      
    } else if (pageName === 'vendornumberassignment') {
       vendorManagementTitle =  Constants.vendorNumberAssignmentTitle;
      this. vendorManagementNav =  navConstants.vendorNumberAssignmentNav;
      this.router.navigate([this.routeMakingService.getNavigateUrl([Constants.adminPanelRoute,Constants.vendorManagement,navConstants.vendorNumberAssignmentNav])]);  
   } 
   this.page = pageName;
    this. vendorManagementTitle =  vendorManagementTitle;
    this.searchtext='';
    this.lstVendors = [];
    this.selectedIndex=-1;
    this.isdisplay=false;
    this.lstTempVendors = [];
    this.lstalluserVendors=[];
    this.filterRole='--Select Vendor--';
    this.lstalluserTempVendors=[];
   
    if (pageName == 'vendorcreation') {
    
    } else if (pageName == 'vendorassignment') {
      this.getVendorsAssigners();
      
    }
    else if(pageName=='vendornumberassignment'){
      this.getVendorNumberAssigners();
    }
    this.isCard = true;
  }
   getVendorNumberAssigners(){
    // this.spinner.show();
    // this.lstVendorsnumber = [];
    // this._apiService.GetVendorMaskingNumbers().subscribe(res => {
    //   this.spinner.hide();
      
    //   this.lstVendorsnumber = res.FetchAllMaskingMobileNumbersResult['resultAllMaskingMobileNumbers'];
 
    //   this.lstTempVendorsnumber= this.lstVendorsnumber;
    //   let arr=[{rolemaskingNumbersIdId:0,mobileMaskingNumber:'None'}];
    //   let newArr=this.lstVendorsnumber;
    //   for(var i=0;i<newArr.length;i++){
    //     arr.push(newArr[i]);
    //   }
    //   this.lstassignvendor=arr;
    //   this.lsttempassignvendor=this.lstassignvendor;
    // });
  }
  
  onMouseEnter() {
    this.isCard = true;
  }

  onMouseLeave() {
    this.isCard = false;
  }

   getVendorsAssigners(){
    // this.spinner.show();
    // this.lstalluserVendors = [];
    // this._apiService.GetVendorAssigners().subscribe(res => {
      
    //   this.spinner.hide();
    //   this.lstalluserVendors = res.FetchAllMembersByVendorResult['resultFetchAllMembersVendor'];
    //   for (var i = 0; i < this.lstalluserVendors.length; i++) {
    //     if (this.lstalluserVendors[i].serviceIds != '0' && this.lstalluserVendors[i].serviceNames != "") {
    //       const serviceIdsArray = this.lstalluserVendors[i].serviceIds.split(',');
    //       const serviceNamesArray = this.lstalluserVendors[i].serviceNames.split(',');
    //       this.lstalluserVendors[i].splitServices = [];
    //       for (let j = 0; j < serviceIdsArray.length; j++) {
    //         this.lstalluserVendors[i].splitServices.push({
    //           id: serviceIdsArray[j],
    //           name: serviceNamesArray[j]
    //         });
    //       }
    //     }else{
    //       this.lstalluserVendors[i].splitServices = [];
    //     }
    //   }
    //   this.lstalluserTempVendors = this.lstalluserVendors;
    // });
  }
 removeDropdown(event){
    
    if(this.isdisplay==true && this.isSearchChange ){
      
      this.isdisplay = false;
      this.isPopup=false;
      this.lstalluserVendors[this.selectedIndex].vendorName=this.oldservice;
      this.isSearchChange=false;  
      this.previd=0;
    }
    if(event.target.value==undefined){
      
      this.isdisplay = false;
      this.previd=0;
      this.isPopup=false;
      if(this.oldservice!=''){
        this.lstalluserVendors[this.selectedIndex].vendorName=this.oldservice;
      }
      
    }
  
  }
}

