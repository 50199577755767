import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService } from 'src/app/shared/alert/alert.service';
import { Role } from 'src/app/shared/enums/role.enum';
import { ServerApisService } from 'src/app/shared/services/server-apis.service';
import { Constants, navConstants } from 'src/app/shared/constants/constant';
import { RouteMakingService } from 'src/app/shared/services/route-making.service';
interface Food {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-assign-profession-professions',
  templateUrl: './assign-profession-professions.component.html',
  styleUrls: ['./assign-profession-professions.component.css']
})
export class AssignProfessionProfessionsComponent {
  public bankCtrl: FormControl = new FormControl();
  public bankFilterCtrl: FormControl = new FormControl();
  lstCategories: any = [];
  sort: string = "";
   placeholderText = 'Search With MemberId, Member Name And Service '
  isUpdate: boolean = false;
  selectedCountry: string;
  isSearchChange: boolean = false;
  lstallcategories: any = [];
  lstalltempcategories: any = [];
  oldservice: string = "";
  isPopup: boolean = false;
  newservice: string = "";
  previd: number = 0;
  selectedIndex: number = 0;
  servicesort: string = "";
  mobilesort: string = "";
  newservicesort: string = "";
  categorytype: string = "";
  membersort: string = "";
  displayedColumns: string[] = ['MemberId', 'Member Name', 'Service', 'CategoryId'];
  lstTempcategories: any = [];
  id: number = 0;
  memberId: number = 0;
  isdisplay: boolean = false;
  serviceName: string = "";
  subProfessionsCategoryId: number = 0;
  searchtext: string = '';
  p = 1;
  foods: Food[] = [
    { value: 'steak-0', viewValue: 'Steak' },
    { value: 'pizza-1', viewValue: 'Pizza' },
    { value: 'tacos-2', viewValue: 'Tacos' },
  ];
  page: any = "assignService";

  constructor(private router: Router, private routeMakingService: RouteMakingService, private _apiService: ServerApisService, protected alertService: AlertService, private spinner: NgxSpinnerService,) { }

  ngOnInit(): void {
    const user = sessionStorage.getItem('user');
    if (user != null) {

      let data = JSON.parse(user);
      if (data.roleId === Role.Admin || data.roleId === Role.SubAdmin || Role.SeoTeam) {
        this.getSubProfessionalCategories();
        this.getAllSubProfessionCategory();
        let path = window.location.href.split('profession/')
        } else {
        this.router.navigate([""]);
      }
    } else {
      this.router.navigate([this.routeMakingService.getNavigateUrl([Constants.Login])]);
    }
    
  }

  searchMainCategory(event: any) {
    const searchValue = event.target.value;
    if (searchValue && searchValue.trim() !== '') {
      const searchValueLower = searchValue.toLowerCase();
      this.lstCategories = this.lstTempcategories.filter((x: any) => 
        (x.service && x.service.toString().toLowerCase().includes(searchValueLower)) ||
        (x.member_name && x.member_name.toString().toLowerCase().includes(searchValueLower)) ||
        (x.mobile && x.mobile.toString().toLowerCase().includes(searchValueLower)) ||
        (x.userUniqueCode && x.userUniqueCode.toString().toLowerCase().includes(searchValueLower)) ||
        (x.categoryName && x.categoryName.toLowerCase().includes(searchValueLower))
      );
    } else {
      this.lstCategories = this.lstTempcategories;
    }
  }
  removeDropdown(event) {

    if (this.isdisplay == true && this.isSearchChange) {

      this.isdisplay = false;
      this.isPopup = false;
      this.lstCategories[this.selectedIndex].categoryName = this.oldservice;
      this.isSearchChange = false;
      this.previd = 0;
    }
    if (event.target.value == undefined) {

      this.isdisplay = false;
      this.previd = 0;
      this.isPopup = false;
      if (this.oldservice != '') {
        this.lstCategories[this.selectedIndex].categoryName = this.oldservice;
      }

    }

  }

  getSubProfessionalCategories() {
    let payLoad = {
      serviceId: 0
    }
    this.spinner.show();
    if (this.newservice != '') {
      this.oldservice = this.newservice;
      this.lstCategories[this.selectedIndex].categoryName = this.oldservice;
    }
    this._apiService.GetSubProfessionalCategories(payLoad).subscribe(res => {
      this.lstCategories = res['Services'];
      this.lstTempcategories = res['Services'];
      this.spinner.hide();
    });
  }

  getAllSubProfessionCategory() {
    this._apiService.getAllSubProfessionCategory().subscribe(res => {
      this.lstallcategories = res.GetAllSubProfessionCategoryResult['resultSubProfessionCategory'];
      this.lstalltempcategories = res.GetAllSubProfessionCategoryResult['resultSubProfessionCategory'];
    });
  }

  pageChanged(event) {
    this.p = event;
  }

  getindex(d: any, i: number) {

    this.lstallcategories = this.lstalltempcategories;
    if (this.previd == d.serviceId) {
      this.isdisplay = false;
      this.isPopup = false;
      this.id = d.serviceId;
      this.previd = 0;
      if (this.isSearchChange == true) {
        this.lstCategories[this.selectedIndex].categoryName = this.oldservice;
      }
    }
    else {
      this.isdisplay = true;
      this.id = d.serviceId;
      this.oldservice = d.categoryName;
      let index = this.lstCategories.findIndex(x => x.serviceId === this.id);
      this.selectedIndex = index;
      this.lstCategories[this.selectedIndex].categoryName = this.oldservice;
    }

  }

 OnlyNumbersAllowed(event): boolean {

    const charCode = (event.which) ? event.which : event.keycode;

    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true
  }

  catergorychange(event, d) {
    this.subProfessionsCategoryId = event.subProfessionsCategoryId;
    this.memberId = d.memberId;
    this.newservice = event.subProfessionCategoryName;
    this.isPopup = true;
    this.lstCategories[this.selectedIndex].categoryName = this.newservice;
    this.isdisplay = false;
  }
  getcategoryname(index) {
    return this.lstTempcategories[index].categoryName;
  }

  closepopup() {
    document.getElementById('dismissModal').click();
    this.lstCategories[this.selectedIndex].categoryName = this.oldservice;
    this.isdisplay = false;
    this.isPopup = false;
    this.previd = 0;
  }

  serviceid: number = 0;
  edit(d: any, type: any) {

    if (this.isdisplay == false) {
      this.previd = 0;
    } else {
      this.previd = d.serviceId;
    }
    this.serviceName = d.service;
    this.serviceid = d.serviceId;
}

  valchange(event) {
    this.isUpdate = true;
    this.serviceName = event.target.value;
  }

  update() {

    this.isdisplay = false;
    this.isPopup = false;
    if (this.newservice != '') {
      this.spinner.show();
      let payLoad = {
        serviceId: this.serviceid,
        memberId: this.memberId,
        updatedbyId: sessionStorage.getItem('memberId'),
        serviceName: this.newservice,
        subProfessionsCategoryId: this.subProfessionsCategoryId
      }
      this._apiService.updateSubProfessionalCategories(payLoad).subscribe(res => {

        this.spinner.hide();
        document.getElementById('dismissModal').click();
        if (res['result'] == 'Success') {


          this.id = 0;
          this.previd = 0;
          this.serviceid = 0;
          this.serviceName = "";
          this.subProfessionsCategoryId = 0;
          this.categorytype = "";
          this.isUpdate = false;
          this.oldservice = this.newservice;
          this.alertService.success(res['resultServices'].result);
        } else {
          this.alertService.error('Service Updation Failed!');
        }
       ;
        let payload1 =
        {
          serviceId: 0
        }
        this.getSubProfessionalCategories();
      });
    }

  }

  onSort() {

    if (this.sort == "") {
      this.sort = "up";
      this.lstCategories.sort((a, b) => a.CategoryId - b.CategoryId);
    } else if (this.sort == "up") {
      this.sort = "down";
      this.lstCategories.sort((a, b) => b.CategoryId - a.CategoryId);

    } else if (this.sort == "down") {
      this.sort = "";
      this.lstCategories.sort((a, b) => a.memberId - b.memberId);
    }
  }

 mobileonSort() {

    if (this.mobilesort == "") {
      this.mobilesort = "up";
      this.lstCategories.sort((a, b) => (b.mobile > a.mobile) ? -1 : 1);

    } else if (this.mobilesort == "up") {
      this.mobilesort = "down";
      this.lstCategories.sort((a, b) => (a.mobile > b.mobile) ? -1 : 1);

    } else if (this.mobilesort == "down") {
      this.mobilesort = "";
      this.lstCategories.sort((a, b) => a.memberId - b.memberId);
    }
  }
  serviceonSort() {

    if (this.servicesort == "") {
      this.servicesort = "up";
      this.lstCategories.sort((a, b) => (b.service > a.service) ? -1 : 1);

    } else if (this.servicesort == "up") {
      this.servicesort = "down";
      this.lstCategories.sort((a, b) => (a.service > b.service) ? -1 : 1);

    } else if (this.servicesort == "down") {
      this.servicesort = "";
      this.lstCategories.sort((a, b) => a.memberId - b.memberId);
    }
  }
  newserviceonSort() {

    if (this.newservicesort == "") {
      this.newservicesort = "up";
      this.lstCategories.sort((a, b) => (b.categoryName > a.categoryName) ? -1 : 1);

    } else if (this.newservicesort == "up") {
      this.newservicesort = "down";
      this.lstCategories.sort((a, b) => (a.categoryName > b.categoryName) ? -1 : 1);

    } else if (this.newservicesort == "down") {
      this.newservicesort = "";
      this.lstCategories.sort((a, b) => a.memberId - b.memberId);
    }
  }
  memberSort() {
    if (this.membersort == "") {
      this.membersort = "up";
      this.lstCategories.sort((a, b) => (b.member_name > a.member_name) ? -1 : 1);

    } else if (this.membersort == "up") {
      this.membersort = "down";
      this.lstCategories.sort((a, b) => (a.member_name > b.member_name) ? -1 : 1);

    } else if (this.membersort == "down") {
      this.membersort = "";
      this.lstCategories.sort((a, b) => a.memberId - b.memberId);
    }
  }
  searchCategorychange(event, val) {

    if (event.target.value != "" && event.target.value != null && event.target.value != undefined) {
      this.isdisplay = true;
      this.isSearchChange = true;
      this.lstallcategories = this.lstalltempcategories;
      var result = this.lstallcategories.filter((x: any) => x.subProfessionCategoryName.toLowerCase().includes(event.target.value.toLowerCase()));
      this.lstallcategories = result;
    } else {
      this.isSearchChange = true;
      this.lstallcategories = this.lstalltempcategories;
    }
  }

}
