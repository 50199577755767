import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServerApisService } from 'src/app/shared/services/server-apis.service';
import { Role } from 'src/app/shared/enums/role.enum';
import { Constants, navConstants } from 'src/app/shared/constants/constant';
import { RouteMakingService } from 'src/app/shared/services/route-making.service';
import { AlertService } from 'src/app/shared/alert/alert.service';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NgxPaginationModule } from 'ngx-pagination';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-activities-after-login-activities',
  templateUrl: './activities-after-login-activities.component.html',
  styleUrls: ['./activities-after-login-activities.component.css']
})
export class ActivitiesAfterLoginActivitiesComponent {
  ActivityLogs: any;
  isCard = true;
  page = 'Login Activities';
  lstactivityLogList: any = [];
  tempActivityLogList: any;
  count: number;
  placeholderText: string;
  lstActivities: any = [];
  lstTempActivities: any = [];
  searchInfo: string = "";
  p = 1;

  constructor(
    private _apiService: ServerApisService,
    private serviceApi: ServerApisService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private routeMakingService: RouteMakingService,
    private  NgxPaginationModule: NgxPaginationModule,
    private cdr: ChangeDetectorRef,
    private datePipe: DatePipe  
  ) { }
  ngOnInit(): void {
    const user = sessionStorage.getItem('user');
    if (user != null) {
      let data = JSON.parse(user);
      if (data.roleId === Role.Admin) {
        let path = window.location.href.split('activities/')
        if (path[1] == navConstants.loginActivitiesNav) {
         this.getActivities();
        
        }
      } else {
        this.router.navigate([""]);
      }
    } else {
      this.router.navigate([this.routeMakingService.getNavigateUrl([Constants.Login])]);
    }
  }
  scrollToTop() {
    window.scrollTo(0, 0);
  }
  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  getActivities() {
    this.spinner.show();
    this._apiService.GetActivities().subscribe(res => {
      this.spinner.hide();
      if (res['TrackActivityLogsResult'].status == 200) {
        this.lstActivities = res['TrackActivityLogsResult'].ActivityLogs.map(activity => {
         
          const utcDate = new Date(activity.Activity_Date);
          const istDate = new Date(utcDate.getTime() + (5.5 * 60 * 60 * 1000));
          activity.Activity_Date = istDate.toLocaleString();
          return activity;
        });
        this.lstTempActivities = this.lstActivities;
        this.scrollToTop();
      }
    });
  }
  pageChangedLoginActivities(event) {
    this.p = event;
    this.cdr.detectChanges();
  }
  search(event: any, type: string) {
    const value = event.target.value.toLowerCase();
   if (event.target.value != '' && event.target.value != undefined) {

        var result = this.lstActivities.filter((x: any) => x.Name.toLowerCase().includes(event.target.value.toLowerCase())
          || x.uniqueId.toLowerCase().includes(event.target.value.toLowerCase())
          || x.Name.toLowerCase().includes(event.target.value.toLowerCase())
          || x.Mobile_No.toLowerCase().includes(event.target.value.toLowerCase())
          || x.profession.toString().toLowerCase().includes(event.target.value.toString().toLowerCase())
          || x.childProfession.toLowerCase().includes(event.target.value.toLowerCase())
          || x.Activity_Date.toLowerCase().includes(event.target.value.toLowerCase()))

        this.lstActivities = result;

      } else {
        this.lstActivities = this.lstTempActivities;
      }
}
  
clearPlaceholder() {
    this.placeholderText = '';
  }
 restorePlaceholder() {
    this.placeholderText = 'Search...';
  }

  

}
