import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RouteMakingService {

  constructor() { }

  getNavigateUrl(params){
    let finalUrl = '';
    for (let item = 0; item < params.length; item++){
      finalUrl += '/' + params[item];
    }
    return finalUrl;
  }
}
