<div class="whole-section">
    <nav aria-label="breadcrumb" class="mlft-6">
        <app-bread-crumb [breadTitle]="reportsTitle" [breadpath1]="adminPanel" [breadpath2]="reports"
            [breadpath3]="reportsNav" [breadroute1]="adminPanelRoute"></app-bread-crumb>
    </nav>
    <div class="row">
        <div class="col-3"></div>
    </div>
    <div class="table-responsive" style="padding: 1% 16px 0px 12.3px;">
        <div class="row" style="background-color: #fff;">
            <div class="col-md-1 leftminisec"(mouseenter)="onMouseEnter()">
               <div style="text-align: center; margin-bottom: 10px;">
                    <i class="fa-solid fa-ellipsis-h" style="cursor: pointer;width: 29px; height: 29px; position: relative; top: 12px; font-size: 26px;right: 2px;" title="ellipsis"></i></div>

                <div
                    [ngClass]="{'Rectangle-563-copy': page !== 'usersfordigitalteam', 'Rectangle-563-selected active' : page === 'usersfordigitalteam'}">
                    <div class="separator"></div>
                    <div class="align-icon"><i class="fa-regular fa-address-card"></i></div>
                </div>
                <div
                    [ngClass]="{'Rectangle-563-copy': page !== 'allusersdisplay', 'Rectangle-563-selected active' : page === 'allusersdisplay'}">
                    <div class="align-icon"><i class="fa-solid fa-user-pen"></i></div>
                </div>
                <div
                    [ngClass]="{'Rectangle-563-copy': page !== 'notifications', 'Rectangle-563-selected active' : page === 'notifications'}">
                    <div class="align-icon"><i class="fa-solid fa-bell"></i></div>
                </div>
                <div
                    [ngClass]="{'Rectangle-563-copy': page !== 'dailycount', 'Rectangle-563-selected active' : page === 'dailycount'}">
                    <div class="align-icon"><i class="fa-solid fa-calendar-days"></i></div>
                </div>
                <div
                    [ngClass]="{'Rectangle-563-copy': page !== 'profiledata', 'Rectangle-563-selected active' : page === 'profiledata'}">
                    <div class="align-icon"><i class="fa-regular fa-address-card"></i></div>
                </div>
                <div
                    [ngClass]="{'Rectangle-563-copy': page !== 'activityData', 'Rectangle-563-selected active' : page === 'activityData'}">
                    <div class="align-icon"><i class="fa-solid fa-users"></i></div>
                </div>
            </div>
            <div class="col-md-2 left-section" *ngIf="isCard" (mouseleave)="onMouseLeave()"
                style="z-index: 900;position: absolute;top: 0px;">
                <div class="row">
                    <div class="col-md-12" *ngIf="isCard" style="padding: 7% 0% 5%;">
                       <div class="hand" (click)="searchtab('usersfordigitalteam')"
                            [ngClass]="{'Rectangle-563-copy': page !== 'usersfordigitalteam', 'Rectangle-563-selected active' : page === 'usersfordigitalteam'}">
                            <p class="mr-0"><span class="icon-align2"><i class="fa-regular fa-address-card"></i></span>
                                <span class="icon-text-space">User Information</span><span style="float: right;"></span>
                            </p>
                        </div>
                        <div class="hand" (click)="searchtab('allusersdisplay')"
                            [ngClass]="{'Rectangle-563-copy': page !== 'allusersdisplay', 'Rectangle-563-selected active' : page === 'allusersdisplay'}">
                            <p class="mr-0"><span class="icon-align2"><i class="fa-solid fa-user-pen"></i></span> <span
                                    class="icon-text-space">Profile Transaction Logs</span><span
                                    style="float: right;"></span></p>
                        </div>
                        <div class="hand" (click)="searchtab('notifications')"
                            [ngClass]="{'Rectangle-563-copy': page !== 'notifications', 'Rectangle-563-selected active' : page === 'notifications'}">
                            <p class="mr-0"><span class="icon-align2"><i class="fa-solid fa-bell"></i></span> <span
                                    class="icon-text-space">Notifications</span><span style="float: right;"></span></p>
                        </div>
                        <div class="hand" (click)="searchtab('dailycount')"
                            [ngClass]="{'Rectangle-563-copy': page !== 'dailycount', 'Rectangle-563-selected active' : page === 'dailycount'}">
                            <p class="mr-0"><span class="icon-align2"><i class="fa-solid fa-calendar-days"></i></span>
                                <span class="icon-text-space">Daily Count</span><span style="float: right;"></span></p>
                        </div>
                        <div class="hand" (click)="searchtab('profiledata')"
                            [ngClass]="{'Rectangle-563-copy': page !== 'profiledata', 'Rectangle-563-selected active' : page === 'profiledata'}">
                            <p class="mr-0"><span class="icon-align2"><i class="fa-solid fa-user"></i></span> <span
                                    class="icon-text-space">Profile Data</span><span style="float: right;"></span></p>
                        </div>
                        <div class="hand" (click)="searchtab('activityData')"
                            [ngClass]="{'Rectangle-563-copy': page !== 'activityData', 'Rectangle-563-selected active' : page === 'activityData'}">
                            <p class="mr-0"><span class="icon-align2"><i class="fa-solid fa-users"></i></span> <span
                                    class="icon-text-space">Activity Data</span><span style="float: right;"></span></p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content-section" style="padding-left: 35px;" [ngClass]="{'col-10': isCard, 'col-11' : !isCard}">
                <ng-container *ngIf="page === 'usersfordigitalteam'">
                    <table class="common-table">
                    <app-user-information-report></app-user-information-report>
                   </table>
                </ng-container>
                <ng-container *ngIf="page === 'allusersdisplay'">
                    <table class="common-table">
                    <app-profile-transaction-logs-reports></app-profile-transaction-logs-reports>
                </table>
                </ng-container>
                <ng-container *ngIf="page === 'notifications'">
                    <table class="common-table">
                    <app-notifications-reports></app-notifications-reports>
                </table>
                </ng-container>
                <ng-container *ngIf="page === 'dailycount'">
                    <table class="common-table">
                    <app-daily-counts-reports></app-daily-counts-reports>
                </table>
                 </ng-container>
                <ng-container *ngIf="page === 'profiledata'">
                    <table class="common-table">
                    <app-profile-data-reports></app-profile-data-reports>
                </table>
                </ng-container>
                <ng-container *ngIf="page === 'activityData'">
                    <table class="common-table">
                    <app-activity-data-reports></app-activity-data-reports>
                </table>
                </ng-container>
            </div>
        </div>
    </div>
</div>