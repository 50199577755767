import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NoProviderResultsFoundComponent } from './no-provider-results-found.component';

const routes: Routes = [
  {
    path:'', component: NoProviderResultsFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class NoProviderResultsFoundRoutingModule { }
