<div class="" style="background: #fff;">
  <nav aria-label="breadcrumb" class="mlft-6">
    <app-bread-crumb [breadTitle]="exceptionLogsTitle" [breadpath1]="adminPanel" [breadpath2]="exceptionLogs"
      [breadroute1]="adminPanelRoute" [showBreadcrumb3]="false">
    </app-bread-crumb>
  </nav>

  <div class="row justify-content-center">
    <div class="col-md-12">
      <div class="table-responsive" style="padding: 1%;">
        <div style="min-height: 70vh;">
          <div class="tasks_table">
            <div class="row">
              <div class="col-md-4">
                <!-- <label for="startDateValue" class="date-label">Start Date</label>
                <input type="date" class="form-control-1" name="startDateValue" id="startDateValue"
                  [(ngModel)]="startDateValue" (ngModelChange)="search()" [max]="maxStartDate" />
                <label for="startDateValue" class="date-label1">End Date</label>
                <input type="date" class="form-control-2" name="endDateValue" id="endDateValue"
                  [(ngModel)]="endDateValue" (ngModelChange)="search()" [max]="maxEndDate" /> -->
              </div>
              <div class="col-md-8">
                <div class="tasks_table1">
                  <div class="search-section">
                    <form class="example" action="action_page.php" (submit)="search($event)">
                      <input type="text" placeholder="Search..." name="search" [(ngModel)]="searchInfo"
                        autocomplete="on" class="search-input" (input)="search()" />
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <table class="table table-striped custom-table">
              <thead class="tablehd-sec custom-table-header">
                <tr class="header-row" style="background-color: #1a1f24; color: #d3c2c2;">
                  <th style="width: 5%; min-width: 150px;" class="padleft-30">Sr No.</th>
                  <th style="width: 14%; min-width: 150px;">Date</th>
                  <th style="width: 81%;">Error Message</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let d of lstExceptionlogs | paginate: { itemsPerPage: 20, currentPage: p }; let i = index" class="tdata">
                  <td style="width: 5%; min-width: 150px; padding-left: 2%;" class="padleft-30">{{ i + 1 }}</td>
                  <td style="width: 14%; min-width: 150px;">{{ d.Date }}</td>
                  <td style="width: 81%;" (click)="showErrorMessagePopup(d.ErrorMessage)" class="error-message-cell" title="Click to view full message">
                    {{ truncateErrorMessage(d.ErrorMessage, 160) }}
                  </td>
                </tr>
              </tbody>
            </table>

            <div class="row">
              <div class="col">
                <div class="d-flex justify-content-center" *ngIf="lstExceptionlogs.length != 0">
                  <pagination-controls (pageChange)="pageChanged($event)" (pageBoundsCorrection)="pageChanged($event)">
                  </pagination-controls>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="showErrorMessageModal" class="modal"
    style="overflow: hidden; display: flex; align-items: center; justify-content: center;"
    [ngStyle]="{'display': showErrorMessageModal ? 'block' : 'none'}">
    <form class="modal-content">
      <div class="container">
        <div class="modal-header" style="padding-top: 0;">
          <h5 class="modal-title" id="exampleModalLabel" style="margin-left: 41%; font-size: 21px; font-weight: bolder;">
            Exception Details</h5>
          <span class="close" (click)="closeModal()">&times;</span>
        </div>
        <div style="padding: 10px; text-align: left; overflow: auto; border: 1px solid #8d8787; border-radius: 5px; max-height: 200px;">
          <p style="font-size: 12px; white-space: pre-wrap; overflow-wrap: break-word; margin: 0;">
            <b>{{selectedErrorMessage}}</b>
          </p>
        </div>
        <div class="modal-footer" style="padding-bottom: 0;">
          <button type="button" (click)="closeModal()" class="btn btn-danger footer-button" data-bs-dismiss="modal"
            style="width: 18%; margin-top: 0px;">Cancel</button>
          <button type="button" class="btn btn-primary footer-button" (click)="copyErrorMessageToClipboard()"
            style="width: 18%; position: relative; left: 2%; margin-top: 0px;">Copy</button>
        </div>
      </div>
    </form>
  </div>
</div>