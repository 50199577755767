<div>
  <mat-tab-group>
    <mat-tab>
      <ng-template mat-tab-label>
        <div class="custom-tab-label" (click)="getDataFromApi('userPending')">
          <span class="overview-text" [ngClass]="{'active-tab-text': selectedTab === 'userPending'}">Overview</span>
          <img src="../../../assets/images/overview.png" class="overview-image" alt="overview" title="overview">
        </div>
      </ng-template>
   <div class="tasks_table1">
        <div class="row">
          <div class="col-md-10 search-box">
            <label style="width: 100%;"><span><input type="text" id="search" class="custom-focus-input"
                  autocomplete="off" [(ngModel)]="searchtext" [placeholder]="userPlaceholder"
                  style="height: 36px;border:none;width:120.2%;margin-left:2px;"
                  (input)="searchUserApproval($event)"></span></label>
          </div>
        </div>

      </div>
      <table class="table table-striped">
        <thead class="tablehd-sec">
          <tr>
            <th style="width: 10%;" class="padleft-30">Role Id</th>
            <th style="width: 17%;">Role Name</th>
            <th style="width: 17%;">Updated Role Name</th>
            <th style="width: 29%;">Change Description</th>
            <th style="width: 17%;">Action</th>
            <th style="width: 10%;">Expand</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let d of usersList | paginate: { itemsPerPage: 10, currentPage: p}; let i = index">
            <tr class="tdata">
              <td style="width: 10%;padding-left: 2%;" class="padleft-30">{{d.roleId}}</td>
              <td style="width: 17%;">
                <p>{{d.roleName}}</p>
              </td>
              <td style="width: 17%;">
                <p>{{d.roleNameNew}}</p>
              </td>
              <td style="width: 29%;">
                <ng-container *ngIf="d.isDeleted == 'Y'">
                  <p>User <b>{{d.roleName}}</b> has been deleted</p>
                </ng-container>
                <ng-container *ngIf="d.isDeleted == 'N' && !d.childProfessionNameNew">
                  <p>User <b>{{d.roleName}}</b> has been Added</p>
                </ng-container>
                <ng-container *ngIf="d.isDeleted == 'N' && d.childProfessionNameNew">
                  <p>User Has been Updated from this <b>{{d.roleName}}</b> to
                    <b>{{d.roleNameNew}}</b>
                  </p>
                </ng-container>
              </td>
              <td style="width: 17%;">
                <div class="custom-select-container">
                  <select class="custom-select" (change)="dropdownAction($event.target.value, d,'user')">
                    <option value="" selected>Select </option>
                    <option value="approved">Approved</option>
                    <option value="reject">Rejected</option>
                  </select>
                </div>
              </td>
              <td style="width: 10%;">

                <button (click)="toggleIconUser(d)" class="icon-toggle" [ngClass]="{'rotate-icon': d.showMetaInfo}">
                  <i class="fa fa-chevron-down" style="color: black;"></i>
                </button>
              </td>

            </tr>
            <tr *ngIf="d.expand">
              <td colspan="6">
                <div class="meta-info-container">
                  <p><strong>Role Name:</strong> {{d.roleName}}</p>
                  <p *ngIf="d.roleNameNew !=''"><strong>Updated Role Name:</strong>
                    {{d.roleNameNew}}</p>
                  <p><strong>Change Description:</strong>
                    <ng-container *ngIf="d.isDeleted == 'Y'">
                      <span>User <strong>{{d.roleName}}</strong> has been deleted</span>
                    </ng-container>
                    <ng-container *ngIf="d.isDeleted == 'N' && !d.roleNameNew">
                      <span>User <strong>{{d.roleName}}</strong> has been Added</span>
                    </ng-container>
                    <ng-container *ngIf="d.isDeleted == 'N' && d.roleNameNew">
                      <span>User Has been Updated from this <strong>{{d.roleName}}</strong> to
                        <strong>{{d.roleNameNew}}</strong></span>
                    </ng-container>
                  </p>
                </div>
                <div class="action-button">
                  <button class="approve-button" (click)="dropdownAction('approved',d,'user')">Approved</button>
                  <button class="reject-button" (click)="dropdownAction('reject',d,'user')">Rejected</button>
                  <button class="cancel-button" (click)="cancelFunction(d)">Cancel</button>
                </div>
              </td>
            </tr>
          </ng-container>
        </tbody>

      </table>

      <div class="row">
        <div class="col">
          <div class="d-flex justify-content-center" *ngIf="usersList.length!=0">
            <pagination-controls (pageChange)="pageChanged($event)" (pageBoundsCorrection)="pageChanged($event)">
            </pagination-controls>
          </div>
        </div>
      </div>


    </mat-tab>
    <mat-tab [ngClass]="'custom-approved-tab'">
      <ng-template mat-tab-label>
        <div class="custom-tab-label"  (click)="getDataFromApi('userApproved')">
          <div class="Ellipse-5">
            <div class="union-10"></div>
          </div>
          <span class="approved-text" [ngClass]="{'active-tab-text': selectedTab === 'userApproved'}">Approved</span>
        </div>
      </ng-template>


      <div class="tasks_table1">
        <div class="row">
          <div class="col-md-10 search-box">
            <label style="width: 100%;">
             <span>
                <input type="text" id="searchApproved" class="custom-focus-input" autocomplete="off"
                  [(ngModel)]="searchtextApproved" [placeholder]="userPlaceholder"
                  style="height: 36px;border: none;width: 120.2%;margin-left: 2px;"
                  (input)="searchUserApproval($event)">
              </span>
            </label>
          </div>
        </div>
      </div>

      <table  class="table table-striped">
        <thead class="tablehd-sec">
          <tr>
            <th style="width: 11%;" class="padleft-30">Role Id</th>
            <th style="width: 19%;">Role Name</th>
            <th style="width: 19%;">Updated Role Name</th>
            <th style="width: 29%;">Change Description</th>
            <th style="width: 11%;">Status</th>
            <th style="width: 11%;">Expand</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let d of usersList | paginate: { itemsPerPage: 10, currentPage: p}; let i = index">
            <tr class="tdata">
              <td style="width: 10%;padding-left: 2%;" class="padleft-30">{{d.roleId}}</td>
              <td style="width: 17%;">
                <p>{{d.roleName}}</p>
              </td>
              <td style="width: 17%;">
                <p>{{d.roleNameNew}}</p>
              </td>
              <td style="width: 29%;">
                <ng-container *ngIf="d.isDeleted == 'Y'">
                  <p>User <b>{{d.roleName}}</b> has been deleted</p>
                </ng-container>
                <ng-container *ngIf="d.isDeleted == 'N' && !d.childProfessionNameNew">
                  <p>User <b>{{d.roleName}}</b> has been Added</p>
                </ng-container>
                <ng-container *ngIf="d.isDeleted == 'N' && d.childProfessionNameNew">
                  <p>User Has been Updated from this <b>{{d.roleName}}</b> to
                    <b>{{d.roleNameNew}}</b>
                  </p>
                </ng-container>
              </td>
              <!-- <td style="width: 17%;">
                  <div class="custom-select-container">
                    <select class="custom-select" (change)="dropdownAction($event.target.value, d,'user')">
                      <option value="" selected>Select </option>
                      <option value="approved">Approved</option>
                      <option value="reject">Rejected</option>
                    </select>
                  </div>
                  </td> -->
              <td style="width: 11%; text-align: center;">
                <span class="custom-checkmark">&#10003;</span>
              </td>
              <td style="width: 10%;">

                <button (click)="toggleIconUser(d)" class="icon-toggle" [ngClass]="{'rotate-icon': d.showMetaInfo}">
                  <i class="fa fa-chevron-down" style="color: black;"></i>
                </button>
              </td>

            </tr>
            <tr *ngIf="d.expand">
              <td colspan="6">
                <div class="meta-info-container">
                  <p><strong>Role Name:</strong> {{d.roleName}}</p>
                  <p *ngIf="d.roleNameNew !=''"><strong>Updated Role Name:</strong>
                    {{d.roleNameNew}}</p>
                  <p><strong>Change Description:</strong>
                    <ng-container *ngIf="d.isDeleted == 'Y'">
                      <span>User <strong>{{d.roleName}}</strong> has been deleted</span>
                    </ng-container>
                    <ng-container *ngIf="d.isDeleted == 'N' && !d.roleNameNew">
                      <span>User <strong>{{d.roleName}}</strong> has been Added</span>
                    </ng-container>
                    <ng-container *ngIf="d.isDeleted == 'N' && d.roleNameNew">
                      <span>User Has been Updated from this <strong>{{d.roleName}}</strong> to
                        <strong>{{d.roleNameNew}}</strong></span>
                    </ng-container>
                  </p>
                </div>
                <!-- <div class="action-button">
                    <button class="approve-button" (click)="dropdownAction('approved',d,'user')">Approved</button>
                    <button class="reject-button" (click)="dropdownAction('reject',d,'user')">Rejected</button>
                    <button class="cancel-button" (click)="cancelFunction(d)">Cancel</button>
                  </div> -->
              </td>
            </tr>


          </ng-container>
        </tbody>
      </table>

      <div class="row">
        <div class="col">
          <div class="d-flex justify-content-center">
            <pagination-controls (pageChange)="pageChanged($event)" (pageBoundsCorrection)="pageChanged($event)">
            </pagination-controls>
          </div>
        </div>
      </div>
    </mat-tab>

    <mat-tab [ngClass]="'custom-rejected-tab'">
        <ng-template mat-tab-label>
          <div class="custom-tab-label" (click)="getDataFromApi('userRejected')">
            <div class="Ellipse-6">
              <div class="union-20"></div>
            </div>
            <span class="rejected-text" [ngClass]="{'active-tab-text': selectedTab === 'userRejected'}">Rejected</span>
          </div>
        </ng-template>


      <div class="tasks_table1">
        <div class="row">
          <div class="col-md-10 search-box">
            <label style="width: 100%;">
              <span>
                <input type="text" id="searchRejected" class="custom-focus-input" autocomplete="off"
                  [(ngModel)]="searchtextRejected" [placeholder]="userPlaceholder"
                  style="height: 36px;border: none;width: 120.2%;margin-left: 2px;"
                  (input)="searchUserApproval($event)">
              </span>
            </label>
          </div>
        </div>
      </div>

      <table  class="table table-striped">
        <thead class="tablehd-sec">
          <tr>
            <th style="width: 11%;" class="padleft-30">Role Id</th>
            <th style="width: 19%;">Role Name</th>
            <th style="width: 19%;">Updated Role Name</th>
            <th style="width: 29%;">Change Description</th>
            <th style="width: 11%;">Status</th>
            <th style="width: 11%;">Expand</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let d of usersList | paginate: { itemsPerPage: 10, currentPage: p}; let i = index">
            <tr class="tdata">
              <td style="width: 11%;padding-left: 2%;" class="padleft-30">{{d.roleId}}</td>
              <td style="width: 19%;">
                <p>{{d.roleName}}</p>
              </td>
              <td style="width: 19%;">
                <p>{{d.roleNameNew}}</p>
              </td>
              <td style="width: 29%;">
                <ng-container *ngIf="d.isDeleted == 'Y'">
                  <p>User <b>{{d.roleName}}</b> has been deleted</p>
                </ng-container>
                <ng-container *ngIf="d.isDeleted == 'N' && !d.childProfessionNameNew">
                  <p>User <b>{{d.roleName}}</b> has been Added</p>
                </ng-container>
                <ng-container *ngIf="d.isDeleted == 'N' && d.childProfessionNameNew">
                  <p>User Has been Updated from this <b>{{d.roleName}}</b> to
                    <b>{{d.roleNameNew}}</b>
                  </p>
                </ng-container>
              </td>
              <!-- <td style="width: 17%;">
                  <div class="custom-select-container">
                    <select class="custom-select" (change)="dropdownAction($event.target.value, d,'user')">
                      <option value="" selected>Select </option>
                      <option value="approved">Approved</option>
                      <option value="reject">Rejected</option>
                    </select>
                  </div>
                </td> -->
              <td style="width: 11%; text-align: center;">
                <span class="custom-crossmark">&#10060;</span>
              </td>
              <td style="width: 11%;">
                <button (click)="toggleIconUser(d)" class="icon-toggle" [ngClass]="{'rotate-icon': d.showMetaInfo}">
                  <i class="fa fa-chevron-down" style="color: black;"></i>
                </button>
              </td>

            </tr>
            <tr *ngIf="d.expand">
              <td colspan="6">
                <div class="meta-info-container">
                  <p><strong>Role Name:</strong> {{d.roleName}}</p>
                  <p *ngIf="d.roleNameNew !=''"><strong>Updated Role Name:</strong>
                    {{d.roleNameNew}}</p>
                  <p><strong>Change Description:</strong>
                    <ng-container *ngIf="d.isDeleted == 'Y'">
                      <span>User <strong>{{d.roleName}}</strong> has been deleted</span>
                    </ng-container>
                    <ng-container *ngIf="d.isDeleted == 'N' && !d.roleNameNew">
                      <span>User <strong>{{d.roleName}}</strong> has been Added</span>
                    </ng-container>
                    <ng-container *ngIf="d.isDeleted == 'N' && d.roleNameNew">
                      <span>User Has been Updated from this <strong>{{d.roleName}}</strong> to
                        <strong>{{d.roleNameNew}}</strong></span>
                    </ng-container>
                  </p>
                </div>
                <!-- <div class="action-button">
                    <button class="approve-button" (click)="dropdownAction('approved',d,'user')">Approved</button>
                    <button class="reject-button" (click)="dropdownAction('reject',d,'user')">Rejected</button>
                    <button class="cancel-button" (click)="cancelFunction(d)">Cancel</button>
                  </div> -->
              </td>
            </tr>


          </ng-container>
        </tbody>
      </table>

      <div class="row">
        <div class="col">
          <div class="d-flex justify-content-center">
            <pagination-controls (pageChange)="pageChanged($event)" (pageBoundsCorrection)="pageChanged($event)">
            </pagination-controls>
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
<div id="deleteModal" class="modal "
  [ngStyle]="{'display': showDeleteModalUser ? 'block' : 'none', 'overflow': 'hidden' }">
  <form class="modal-content-1" action="/action_page.php" style="width: 35%;">
    <div class="container">
      <div class="modal-header" style="padding-top: 0;">
        <h5 class="modal-title" id="exampleModalLabel" style="margin-left: auto;">Confirm</h5>
        <button type="button" id="dismissModal" (click)="dismissModal()" class="btn-close" data-bs-dismiss="modal"
          aria-label="Close"></button>
      </div>
      <div style="padding-top: 15px; padding-bottom: 15px; text-align: center;">
        <ng-container *ngIf="currentData">
          <ng-container *ngIf="currentData.isDeleted == 'Y'">
            <p>User <b>{{currentData.roleName}}</b> has been deleted</p>
          </ng-container>
          <ng-container *ngIf="currentData.isDeleted == 'N' && !currentData.roleNameNew">
            <p>User <b>{{currentData.roleName}}</b> has been Added</p>
          </ng-container>
          <ng-container *ngIf="currentData.isDeleted == 'N' && currentData.roleNameNew">
            <p>User Has been Updated from <b>{{currentData.roleName}}</b> to
              <b>{{currentData.roleNameNew}}</b>
            </p>
          </ng-container>
        </ng-container>
      </div>

      <div class="modal-footer" style="padding-bottom: 0;">
        <button type="button" (click)="dismissModal()" class="btn btn-danger footer-button ml-auto">NO </button>
        <button type="button" class="btn btn-primary footer-button mr-auto"
          (click)="handleApprovalOrRejectionUser()">YES</button>
      </div>
    </div>
  </form>
</div>