<!-- Remove the container if you want to extend the Footer to full width. -->
<!-- <div class="container-fluid "> -->

<!-- <footer class="text-center text-white" style="background-color: #195fbd">
      <div class="pad10">
        <div class="row">
          <div class="col-md-4">
            
            <h6 class="font-weight-bold margintop10">
              <a href={{API_URL}}   class="marginright17 footermenu hand a-blog">Blog</a>
              <span><label  class="text-white marginright17 footermenu">|</label></span>
              <label routerLink="/home"  class="text-white" class="marginright17 footermenu hand">About Us</label>

              <span><label  class="text-white marginright17 footermenu">|</label></span>
            <span><label routerLink="/contactus" class="text-white marginright17 footermenu hand">Contact</label></span>
            <span><label  class="text-white marginright17 footermenu">|</label></span>
            <span><label routerLink="/privacypolicy" target="_blank" class="text-white hand">Privacy Policy</label></span>
          </h6>
          </div>
          <div class="col-md-4">
            <a href="https://play.google.com/store/apps/details?id=com.spinoinc.conwok" target="_blank" class="marginright18"><img  class="logoHeight" src="assets/images/play-store.png" ></a>
            
            <a  href="https://apps.apple.com/us/app/toskie/id1574937402" target="_blank" class="marginleft18"><img  class="logoHeight" src="assets/images/app-store.png" ></a>
            
          </div>
  
          <div class="col-md-4 ">
            <div class="margintop10">
              <a  (click)="goToLink('https://www.facebook.com/ToskieConnect')" class="marginright14">
                <img src="assets/images/f-facebook.png">
              </a>
              <a  (click)="goToLink('https://twitter.com/ToskieConnect')" class="marginright14">
                <img src="assets/images/twitter.png">
              </a>
              <a (click)="goToLink('https://www.instagram.com/toskieapp/')"  class="marginright14">
                <img src="assets/images/insta.png">
              </a>
              <a (click)="goToLink('https://www.linkedin.com/company/toskieconnect')"  >
                <img src="assets/images/linkedin.png">
              </a>
            </div>
         
             </div>
        </div>
      </div>
  
      <div
           class="text-center p-3"
           style="background-color: #3b7cd2"
           >
        © {{currentyear}} Spino Inc.
        <label class="text-white" href="https://toskie.com/"
           >All Rights Reserved</label
          >
      </div>
    </footer>  -->

<!-- old -->

<footer class="text-center text-white" style="background-color: #444444">
  <div class="row text-center pad-1">
    <div class="col-md-3 text-center">
      <label class="Copyright-2023-Toskiecom">Copyright ©2024 Toskie.com</label>
    </div>
    <div class="col-md-6 text-center">
      <label class="font-weight-bold  linksinfo">
        <a href="{{API_URL}}" class="marginright17 footermenu hand a-blog"><h6 class="mb-0 d-inline fw-bold">Blogs</h6></a>
        <span><label class="text-white marginright17 footermenu">|</label></span>
        <span><label (click)="navigate('/privacy-policy')" target="_blank" class="text-white hand marginright17">Privacy
            Policy</label></span>
            <span><label class="text-white marginright17 footermenu">|</label></span>
            <span><label (click)="navigate('/terms-conditions')" target="_blank" class="text-white hand marginright17">Terms and Conditions</label></span>
        <span><label class="text-white marginright17 footermenu">|</label></span>
        <label (click)="navigate('/about-us')" class="text-white" class="marginright17 footermenu hand">About Us</label>

        <span><label class="text-white marginright17 footermenu">|</label></span>
        <span><label (click)="navigate('/contact-us')" class="text-white marginright17 footermenu hand">Contact</label></span>
        <span><label class="text-white marginright17 footermenu">|</label></span>
        <span><label class="marginright17 footermenu hand a-blog"> <a style="text-decoration: none; color: #FFFFFF;font-weight: bolder; "  target="_blank" href="{{FAQ_URL}}">Faqs</a></label></span>
      </label>
    </div>
    <div class="col-md-3 text-center">
      <ul class="nav justify-content-center">
        <li class="nav-item marginright14 cursor-pointer" (click)="goToLink('https://www.facebook.com/ToskieConnect')">
          <img src="assets/images/f_fb.png" title="logo by facebook" alt="logo by facebook">
        </li>
        <li class="nav-item marginright14 cursor-pointer" (click)="goToLink('https://twitter.com/ToskieConnect')">
          <img src="assets/images/f_twitter.png" alt="logo twitter" title="logo twitter">
        </li>
        <li class="nav-item marginright14 cursor-pointer" (click)="goToLink('https://www.instagram.com/toskieapp/')">
          <img src="assets/images/f_insta.png" alt="logo by instagram" tile="logo by instagram"> 
        </li>
        <li class="nav-item marginright14 cursor-pointer" (click)="goToLink('https://www.youtube.com/@toskie')">
          <i class="fa-brands fa-youtube"></i>
        </li>
        <li class="nav-item cursor-pointer" (click)="goToLink('https://www.linkedin.com/company/toskieconnect')">
          <img src="assets/images/f-linkedin.png" alt="logo by linkedin" title="logo by linkedin">
        </li>
      </ul>
    </div>
  </div>
</footer>