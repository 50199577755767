import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService } from 'src/app/shared/alert/alert.service';
import { ServerApisService } from 'src/app/shared/services/server-apis.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { FormControl } from '@angular/forms';
import { Role } from 'src/app/shared/enums/role.enum'; 
import { DatePipe } from '@angular/common';
import { Constants,navConstants } from 'src/app/shared/constants/constant';
import { RouteMakingService } from 'src/app/shared/services/route-making.service';
import { DataService } from 'src/app/shared/services/data.service';

@Component({
  selector: 'app-role-managment',
  templateUrl: './role-managment.component.html',
  styleUrls: ['./role-managment.component.css']
})
export class RoleManagmentComponent implements OnInit {
  lstRoles: any = [];
  lstTempRoles: any = [];
  lstalluserRoles: any = [];
  lstalluserTempRoles: any = [];
  lstassignRoles: any = [];
  p = 1;
  storeRole: string = '';
  id: number = 0;
  isdisplay: boolean = false
  isEdit: boolean = false;
  isdropdownshow: boolean = false;
  lsttempassignRoles: any = [];
  isrole: boolean = false;
  adminPanel: string = Constants.adminPanel;
  userManagement: string = Constants.userManagement;
  userManagementNav: string = navConstants.roleCreationNav;
  adminPanelRoute: string = Constants.adminPanelRoute;
  userManagementTitle: string = Constants.roleCreationTitle;
  submitted: boolean = false;
  isAdd: boolean = false;
  isdelete: boolean = false;
  isassign: boolean = false;
  isCard: boolean = true;
  searchtext: string = '';
  placeholderroleText: string = "Select Role"
  roleCode: string = '';
  roleName: string = '';
  roleCodeNew: string = '';
  roleNameNew: string = '';
  editedProfileVideoUrl: string = '';
  filterRole: string = '';
  selectedIndex: number;
  isSearchChange: boolean = false;
  oldservice: string = "";
  isPopup: boolean = false;
  newservice: string = "";
  previd: number = 0;
  memberID: number = Number(sessionStorage.getItem('memberId'));
  page: string = 'rolecreation';
  placeholderText: string = 'Search with Id and Name';
  profileVideoUrl: any = [];
  selectedMemberId: any;
  modal = "updateModal";
  showModal: boolean = false;
  showDeleteVideoModal: boolean = false;
  showPopupForAddRole: boolean = false;
  selectedRoleVideo: any;
  profileVideoUrlEdit: boolean;
  userprofileVideoUrl: SafeResourceUrl | string = '';
  selectedProfileVideoUrl: string;
  previewBtn: boolean = false;
  onSubmit: any;
  videoModal: boolean;
  tempProfileVideoUrl: any;
  recordCount: number;
  selectedOption: any = 'name';
  searchedMemberID: any = '';
  searchedName: any = '';
  searchedUniqueID: any = '';
  searchedSponsoredUniqueID: any = '';
  searchedSponsoredName: any = '';
  searchedSponsoredMemberID: any = '';
  isChecked: any;
  selectedSponsoredOption: any = 'name';
  listAllSponsoredAccount: any;
  sponsoredPosition: any = '';
  toDate: any = '';
  fromDate: any = '';
  isSponsored: boolean = false;
  temp: any;
  minDate :any;
  todayDate:any;
  maxDate:any;
  showPopup: boolean = false;
  selectedUser: any = {};
  buttonData: any = [];
  updateButton: boolean = false;
  profileStatus: boolean = true;
  isEditing: boolean = false;
  buttonStatus: any = true;
  newButtonName: string = '';
  newButtonLink: string = '';
  profileVideoUrlLink: any;
  selectedUserSub: any;
  oldButtonName: any = '';

  constructor(private spinner: NgxSpinnerService,private routeMakingService:RouteMakingService, private _apiService: ServerApisService, private router: Router, private sanitizer: DomSanitizer,private datePipe: DatePipe,
    private _alertService: AlertService,private _elementRef: ElementRef,private renderer:Renderer2,private dataService:DataService) { }

  ngOnInit(): void {
    const user = sessionStorage.getItem('user');
    if (user != null) {
      let data = JSON.parse(user);
      if (Role.Admin == data.roleId || Role.SubAdmin == data.roleId) {
        let date = new Date();
        this.todayDate = this.datePipe.transform(date, "yyyy-MM-dd");
        this.searchtab(this.page);
        let path=window.location.href.split('user-management/')
        if(path[1]=='role-creation'){
           this.searchtab('rolecreation')
          }else if(path[1]=='role-assignment'){
           this.searchtab('roleassignment')
          }else if(path[1]=='profile-video'){
            this.searchtab('userprofilevideo')
          }else if(path[1]=='sponsored-user'){
            this.searchtab('sponsoredUser')
          }
           
      } else {
        this.router.navigate([""]);
      }

    } else {
      this.router.navigate([this.routeMakingService.getNavigateUrl([Constants.Login])]);
    }
    this.getProfileVideoUrl(this.p);
    this.selectedUserSub = this.dataService.currentUserData.subscribe((data) => {
      this.selectedUser = data;
      this.buttonData = this.selectedUser ? this.selectedUser.profileCustomButtons : [];
    })
  }
 
 searchtab(pageName: string) {
    let userManagementTitle: string;

    if (pageName === 'rolecreation') {
      userManagementTitle = Constants.roleCreationTitle;
      this.userManagementNav = navConstants.roleCreationNav;
      this.router.navigate([this.routeMakingService.getNavigateUrl([Constants.adminPanelRoute,Constants.userManagement,navConstants.roleCreationNav])]);
    
  } else if (pageName === 'roleassignment') {
      userManagementTitle = Constants.roleAssignmentTitle;
      this.userManagementNav = navConstants.roleAssignmentNav;
      this.router.navigate([this.routeMakingService.getNavigateUrl([Constants.adminPanelRoute,Constants.userManagement,navConstants.roleAssignmentNav])]);
    
  } else if (pageName === 'userprofilevideo') {
      userManagementTitle = Constants.profileVideoTitle;
      this.userManagementNav =  navConstants.profileVideoNav;
      this.router.navigate([this.routeMakingService.getNavigateUrl([Constants.adminPanelRoute,Constants.userManagement,navConstants.profileVideoNav])]);
     
  }else if (pageName === 'sponsoredUser') {
    userManagementTitle = Constants.sponsoredUserTitle;
    this.userManagementNav = navConstants.sponsoredUserNav;
    this.router.navigate([this.routeMakingService.getNavigateUrl([Constants.adminPanelRoute,Constants.userManagement,navConstants.sponsoredUserNav])]);
     
  }
   this.page = pageName;
    this.userManagementTitle = userManagementTitle; 
    this.searchtext = '';
    this.p = 1;
    this.lstRoles = [];
    this.selectedIndex = -1;
    this.isdisplay = false;
    this.lstTempRoles = [];
    this.lstalluserRoles = [];
    this.lstalluserTempRoles = [];
    if (pageName == 'rolecreation') {
        this.getRoles();
    } else if (pageName == 'roleassignment') {
        this.getRolesAssigners();
        this.getRoles();
    }
    if (pageName == 'sponsoredUser') {
      this.fetchSponsoredAccount();
    }
    this.isCard = true;

  }
  menu() {
    if (this.isCard) {
      this.isCard = false;
    } else {
      this.isCard = true;
    }
  }

  onMouseEnter() {
    this.isCard = true;
  }

  onMouseLeave() {
    this.isCard = false;
  }


  // searchUserProfileVideoChange(event: any) {
  //   if (event.target.value != '' && event.target.value != undefined) {

  //     this.profileVideoUrl = this.profileVideoUrl.filter((x: any) => 
  //       x.memberId.toString().toLowerCase().includes(event.target.value.toLowerCase()) ||
  //       x.Name.toLowerCase().includes(event.target.value.toLowerCase()) ||
  //       x.UniqueId.toLowerCase().includes(event.target.value.toLowerCase())
  //     );
  //   } else {
  //     this.profileVideoUrl = this.tempProfileVideoUrl;
  //   }
  
  //   // Restore placeholder only when the input value is empty
  //   if (event.target.value === '' || event.target.value === undefined) {
  //     this.restorePlaceholder();
  //   }
  // }
  
  //profileVideoChange() {
  //   if (this.selectedProfileVideoUrl != '' && this.profileVideoUrlEdit) {
  //     this.previewBtn = true;
  //   } else {
  //     this.previewBtn = false;
  //   }
  //   this.onSubmit();
  // }


  getRoles() {
    // this.spinner.show();
    // this.lstRoles = [];
    // this._apiService.GetRoles().subscribe(res => {
    //   this.spinner.hide();
    //   this.lstRoles = res.FetchAllUserRolesResult['resultUserRoles'].filter((x:any)=>x.isApproved === 'Yes');
    //   this.lstTempRoles = this.lstRoles;
    // });
  }
  getRolesAssigners() {
    // this.spinner.show();
    // this.lstalluserRoles = [];
    // this._apiService.GetRoleAssigners().subscribe(res => {
    //   this.spinner.hide();

    //   this.lstalluserRoles = res.FetchAllUsersByRolesResult['resultFetchAllUsersRoles'];
    //   this.lstalluserTempRoles = this.lstalluserRoles;
    // });
  }

  fetchSponsoredAccount() {
    // this._apiService.fetchAccountSponspored().subscribe((res: any) => {
    //   this.listAllSponsoredAccount = res.FetchUserAccountSponsoredUnSponsoredResult.resultAccountSponsoredUnSponsored;
    //   this.temp = this.listAllSponsoredAccount;
    // });
  }

  getProfileVideoUrl(page?: number) {
    // let payload = {
    //   "searchedMemberID": this.searchedMemberID != '' ? this.searchedMemberID : 0,
    //   "searchedName": this.searchedName,
    //   "searchedUniqueID":this.searchedUniqueID,
    //   "page": page,
    // }
    // this._apiService.GetProfileVideoUrl(payload).subscribe((res: any) => {
    //   this.profileVideoUrl = res.FetchAllUsersByProfileVideoUrlResult.resultFetchProfileVideoUrlArgs;
    //   this.tempProfileVideoUrl = this.profileVideoUrl;
    //   this.recordCount = res.FetchAllUsersByProfileVideoUrlResult.RecordCount;
    //   if(this.selectedUser !== '{}'){
    //     this.selectedUser = this.profileVideoUrl.find((item) => item.memberId === this.selectedUser.memberId);
    //     this.dataService.setUserData(this.selectedUser);
    //   }
    //   this.spinner.hide();
    // });
  }

 closePopup() {
    document.getElementById('registersdata').style.display = 'none';
  }

removeDropdown(event) {

    if (this.isdisplay == true && this.isSearchChange) {

      this.isdisplay = false;
      this.isPopup = false;
      this.lstalluserRoles[this.selectedIndex].roleName = this.oldservice;
      this.isSearchChange = false;
      this.previd = 0;
    }
    if (event.target.value == undefined) {

      this.isdisplay = false;
      this.previd = 0;
      this.isPopup = false;
      if (this.oldservice != '') {
        this.lstalluserRoles[this.selectedIndex].roleName = this.oldservice;
      }

    }

  }
}
