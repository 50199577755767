import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService } from 'src/app/shared/alert/alert.service';
import { Role } from 'src/app/shared/enums/role.enum';
import { ServerApisService } from 'src/app/shared/services/server-apis.service';
import { Constants,navConstants } from 'src/app/shared/constants/constant';
import { RouteMakingService } from '../../shared/services/route-making.service';
import { SitemapService } from 'src/app/shared/services/sitemap.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-child-profession-admin-approval',
  templateUrl: './child-profession-admin-approval.component.html',
  styleUrls: ['./child-profession-admin-approval.component.css']
})
export class ChildProfessionAdminApprovalComponent {
  page = 'Main Profession Approval';
  childPlaceholder='Search for child Profession';
  searchtext: string = '';
  searchtextApproved: string = '';
  searchtextRejected: string = '';
  allChildProfessionCategory: any;
  tempChildProfessionsCategory: any;
  selectedChildProfesionId: any;
  childProfessionName: any;
  childProfessionNameNew: any;
  adminApprovalsubCategories: any = [];
  tempAdminApprovalsubCategories: any = [];
  isEditIndex: number;
  p: number = 1;
  currentData: any;
  approvedsubCategories: any = [];
  subRejectCategories: any = [];
  tempapprovedsubCategory: any = [];
  tempsubRejectCategory: any = [];
  currentChildData: any;
  selectedchildProfession: any;
  showDeleteModalchild: boolean = false;
  selectedchildProfessionId: any;
  pendingSubProfession: any;
  tempPendingSubProfession: any;
  currPagePendingMain: number;
  currPageApprovedMain: number;
  selectedOption: string = '';
  selectedAction: string;
  showBreadcrumb3: boolean = false;
  selectedTab: string = 'childPending'; 
  selectedMainProfession: string;

  constructor(private sitemapService:SitemapService,private routeMakingService:RouteMakingService,private router: Router, private cdr: ChangeDetectorRef, private _apiService: ServerApisService, protected alertService: AlertService, private spinner: NgxSpinnerService,) { }

  ngOnInit(): void {
    this.getAllChildCategories();
    this.getAllAdminApprovalsubCategory('pending');
    this.fetchData();
    this.clearSearchTexts();
    localStorage.removeItem('selectedTab');
   }

   clearSearchTexts() {
    throw new Error('Method not implemented.');
  }

  fetchData() {
    const user = sessionStorage.getItem('user');
    if (user != null) {
      let data = JSON.parse(user);
      if (data.roleId !== Role.Admin && data.roleId !== Role.SubAdmin) {
        this.router.navigate([""]);
        return;
      }
    } else {
     this.router.navigate([this.routeMakingService.getNavigateUrl([Constants.Login])]);
      return;
    }
  }
  clearFields(){
    this.searchtextApproved = '';
    this.searchtext = '';
    this.searchtextRejected = ''
    }

    toggleIcon(data: any) {
      data.showMetaInfo = !data.showMetaInfo;
  }
   
  getAllChildCategories() {
    this._apiService.FetchAllChildProfessions().subscribe((res: any) => {
      this.allChildProfessionCategory = res.FetchAllChildProfessionsResult.resultFetchProfessionAdminApproval;
      this.tempChildProfessionsCategory = this.allChildProfessionCategory;
    })
  }

  getAllAdminApprovalsubCategory(type) {
    this.spinner.show();
    this._apiService.GetAdminApprovalsubCategories(type).subscribe((res: any) => {
      this.spinner.hide();
      this.adminApprovalsubCategories = res.FetchChildCategoriesAdminApprovalResult.resultFetchProfessionAdminApproval;
      this.tempAdminApprovalsubCategories = this.adminApprovalsubCategories;
    });
  }

  searchsubApprovedMainProfession(event: any) {
    if (event.target.value != "" && event.target.value != null && event.target.value != undefined) {
      var result = this.adminApprovalsubCategories.filter((x: any) => x.childProfessionName.toLowerCase().includes(event.target.value.toLowerCase()))
      this.adminApprovalsubCategories = result;
    } else {
      this.adminApprovalsubCategories = this.tempAdminApprovalsubCategories;
    }
  }
  searchsubRejectedMainProfession(event: any) {
    if (event.target.value != "" && event.target.value != null && event.target.value != undefined) {
      var result = this.adminApprovalsubCategories.filter((x: any) => x.childProfessionName.toLowerCase().includes(event.target.value.toLowerCase()))
      this.adminApprovalsubCategories = result;
    } else {
      this.adminApprovalsubCategories = this.tempAdminApprovalsubCategories;
    }
  }

 searchchildProfessionApproval(event: any) {
    if (event.target.value != "" && event.target.value != null && event.target.value != undefined) {
      var result = this.adminApprovalsubCategories.filter((x: any) => x.childProfessionName.toLowerCase().includes(event.target.value.toLowerCase()))
      this.adminApprovalsubCategories = result;
    } else {
      this.adminApprovalsubCategories = this.tempAdminApprovalsubCategories;
    }
  }

  pageChanged(event: any) {
    this.p = event;
    this.isEditIndex = -1;
  }

  dismissModal() {
    this.showDeleteModalchild = false;
   }

   approvechild(data) {
    this.selectedchildProfession = data.childProfessionName;
    this.selectedchildProfessionId = data.childProfessionId;
    this.showDeleteModalchild = true;
  }
  rejectedchild(data) {
    this.selectedchildProfession = data.childProfessionName;
    this.selectedchildProfessionId = data.childProfessionId;
    this.showDeleteModalchild = true;

  }
  approveFunction(data: any) {
  }
  rejectFunction(data: any) {
   }
  cancelFunction(data: any) {
  data.showMetaInfo = false;
  data.expand = false;
}

getDataFromApi(tab: string) {
  this.selectedTab = tab;

  switch (tab) {
   case 'childPending':
      this.getAllAdminApprovalsubCategory('pending');
      break;
    case 'childApproved':
      this.getAllAdminApprovalsubCategory('approved');
      break;
    case 'childRejected':
      this.getAllAdminApprovalsubCategory('rejected');
      break;
    default:
      break;
  }
}

dropdownAction(action: string, d: any, component: string) {
  this.currentData = d;
  if (action === 'selected') {
    this.selectedOption = '';
     this.showDeleteModalchild = false;
  } else if (action === 'approved' || action === 'reject') {
    if (this.selectedOption !== action) {
      this.selectedOption = action;
      this.selectedMainProfession='';
      this.selectedMainProfession = d.mainProfessionName;
      this.selectedAction = action;
    }
    if (component === 'main') {
      this.showDeleteModalchild = false;
    } else if (component === 'child') {
      this.showDeleteModalchild = true;
     ;
   
  }
}
}
handleApprovalOrRejectionChild() {

  if (this.selectedAction === 'approved') {
    this.approvedchild();
  } else if (this.selectedAction === 'reject') {
    this.rejectchild();
  }
  this.selectedAction = '';
}

approvedchild() {
  let data = this.currentData;
  let reqBody = {
    "childProfessionId": data.childProfessionId,
    "childProfessionName": data.childProfessionName,
    "childProfessionNameNew": data.childProfessionNameNew,
    "title": data.title,
    "description": data.description,
    "keywords": data.keywords,
    "approvedBy": sessionStorage.getItem('memberId'),
    "isDeleted": data.isDeleted,
    "isApproved": "Y"
  }
  this._apiService.AdminApprovalsubApi(reqBody).subscribe((res: any) => {
    if (res.status == 200) {
      if(data.isDeleted == 'N' && data.childProfessionNameNew != ''){
          let url = environment.sitemapUrl +  `${this.selectedMainProfession}/` +  data.childProfessionNameNew;
          url = url.replace(/\s+/g, '-');
          this.sitemapService.storeSitemapToSearchDB(url.toLowerCase() + '.xml','newlyAdded');
          this.sitemapService.storeSitemapToSearchDB(url.toLowerCase() + '/hyderabad','newlyAdded');
      } else if(data.isDeleted == 'N' && data.childProfessionNameNew == ''){
          let url = environment.sitemapUrl + `${this.selectedMainProfession}/` + data.childProfessionName;
          let webUrl = environment.webUrl + `/${this.selectedMainProfession}/` + data.childProfessionName;
          webUrl = webUrl.replace(/\s+/g, '-');
          url = url.replace(/\s+/g, '-');
          this.sitemapService.storeSitemapToSearchDB(url.toLowerCase() + '.xml','newlyAdded');
          this.sitemapService.storeSitemapToSearchDB(webUrl.toLowerCase() + '/hyderabad','newlyAdded');
      }
      this.getAllAdminApprovalsubCategory('approved');
      this.alertService.success(res.result)
    } else {
      this.alertService.error(res.result)
    }
  })
  this.showDeleteModalchild = false;
}

rejectchild() {
  let data = this.currentData;
  let reqBody = {
    "childProfessionId": data.childProfessionId,
    "childProfessionName": data.childProfessionName,
    "childProfessionNameNew": data.childProfessionNameNew,
    "title": data.title,
    "description": data.description,
    "keywords": data.keywords,
    "approvedBy": sessionStorage.getItem('memberId'),
    "isDeleted": data.isDeleted,
    "isApproved": "N"
  }
  this._apiService.AdminApprovalsubApi(reqBody).subscribe((res: any) => {
    if (res.status == 200) {
      this.getAllAdminApprovalsubCategory('rejected');
      this.alertService.success(res.result)
    } else {
      this.alertService.error(res.result)
    }
  })
  this.showDeleteModalchild = false;
}





}
