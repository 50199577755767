import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdminApprovalsRoutingModule } from './admin-approvals-routing.module';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    AdminApprovalsRoutingModule
  ]
})
export class AdminApprovalsModule { }
