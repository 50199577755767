import { Component, OnInit, ViewChild,ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgSelectComponent } from '@ng-select/ng-select';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServerApisService } from 'src/app/shared/services/server-apis.service';
import { Role } from 'src/app/shared/enums/role.enum';
import { Constants,navConstants } from 'src/app/shared/constants/constant';
import { DomSanitizer, SafeResourceUrl,SafeUrl } from '@angular/platform-browser';
import { RouteMakingService } from 'src/app/shared/services/route-making.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-profile-transaction-logs-reports',
  templateUrl: './profile-transaction-logs-reports.component.html',
  styleUrls: ['./profile-transaction-logs-reports.component.css']
})
export class ProfileTransactionLogsReportsComponent {
  p = 1;
  lstallusersdisplay: any = [];
  lsttempallusersdisplay: any = [];
  indx: number = -1;
  isCard: boolean = true;
  titleExpandedIndex: number = -1;
  messageExpandedIndex: number = -1;
  page: string;
  isEditIndex: number;
  indx1: number;

constructor(private router: Router, private formBuilder: FormBuilder,
  private _apiService: ServerApisService, private spinner: NgxSpinnerService,private cdr: ChangeDetectorRef, private sanitizer: DomSanitizer,private routeMakingService: RouteMakingService) {
}
ngOnInit(): void {
  const user = sessionStorage.getItem('user');
   if (user != null) {
     let data = JSON.parse(user);
     if (data.roleId === Role.Admin || data.roleId === Role.CallCenter) {
       this.getallusersdisplay() 
     } else {
       this.router.navigate([""]);
     }
   } else {
     let params=[Constants.Login]
     let routingUrl= this.routeMakingService.getNavigateUrl(params)
     this.router.navigate([routingUrl]);
   }

 }
 loadTabData(report: any) {
   throw new Error('Method not implemented.');
 }
 searchChange(event: any) {

   {
    if (event.target.value != '' && event.target.value != undefined) {

      var result = this.lsttempallusersdisplay.filter((x: any) => x.Name.toLowerCase().includes(event.target.value.toLowerCase())
        || x.Location.toLowerCase().includes(event.target.value.toLowerCase())
        || x.stateName.toLowerCase().includes(event.target.value.toLowerCase())
        || x.city.toLowerCase().includes(event.target.value.toLowerCase())
        || x.mobile.toString().toLowerCase().includes(event.target.value.toString().toLowerCase())
        || x.UserUniqueCode.toLowerCase().includes(event.target.value.toLowerCase()))
        this.lstallusersdisplay = result;
     } else {
      this.lstallusersdisplay = this.lsttempallusersdisplay;
    }
 }
}
getallusersdisplay() {
  this.spinner.show();
  this.lstallusersdisplay = [];
  this._apiService.GetAllUsersDisplayReport().subscribe(res => {
    this.spinner.hide();
    this.lstallusersdisplay = res.GetAllUsersDisplayReportResult['ToskieUsers'];
    this.lsttempallusersdisplay = this.lstallusersdisplay;
  });
}
scrollToTop() {
  window.scrollTo({ top: 0, behavior: 'smooth' });
}
pageChanged(event: any) {
  this.p = event;
  this.isEditIndex=-1;
 }
readmore(i: number) {
  this.indx = i;
  this.indx1 = -1;
}

readmore1(i: number) {
  this.indx1 = i;
  this.indx = -1;
}

readless() {
  this.indx = -1;
}

readless1() {
  this.indx1 = -1;
}
menu() {
  if (this.isCard) {
    this.isCard = false;
  } else {
    this.isCard = true;
  }
}

}
