import { Component, OnInit, AfterViewInit, Renderer2 } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Constants } from 'src/app/shared/constants/constant';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit, AfterViewInit {
  home: string = Constants.home;
  aboutUs: string = Constants.aboutUs;
  aboutUsRoute: string = Constants.aboutUsRoute;

  constructor(private renderer: Renderer2, private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    // Set the scroll position to the top when the component is fully initialized
    this.renderer.setProperty(document.body, 'scrollTop', 0);
    
    
    // this.spinner.show();
    setTimeout(() => {
      this.spinner.hide();
    }, 400);
  }
}
