 <div class="whole-section">
        <nav aria-label="breadcrumb"  class="mlft-6">
            <app-bread-crumb [breadTitle]=" vendorManagementTitle" [breadpath1]="adminPanel"
                [breadpath2]="vendorManagement" [breadpath3]=" vendorManagementNav"
                [breadroute1]="adminPanelRoute"></app-bread-crumb>

        </nav>
        <div class="row">
            <div class="col-3">

            </div>
        </div>

        <div class="table-responsive" style="padding: 1%;">
            <div class="row " style="background-color: #fff;">
                <div class="col-md-2 left-section" *ngIf="isCard" (mouseleave)="onMouseLeave()"
                    style="z-index: 900;position: absolute;top: 0px;">
                    <div class="row">
                        <div class="col-md-12" *ngIf="isCard">
                            <div class="Rectangle-562">

                            </div>
                            <div class="hand" (click)="searchtab('vendorcreation')"
                                [ngClass]="{'Rectangle-563-copy': page!='vendorcreation', 'Rectangle-563-selected active' : page=='vendorcreation' }">
                                <p class="mr-0">Vendor Creation <span style="float: right;"></span></p>
                            </div>
                            <div class="hand" (click)="searchtab('vendorassignment')"
                                [ngClass]="{'Rectangle-563-copy': page!='vendorassignment', 'Rectangle-563-selected active' : page=='vendorassignment' }">
                                <p class="mr-0">Vendor Assignment <span style="float: right;"></span></p>
                            </div>
                            <div class="hand" (click)="searchtab('vendornumberassignment')"
                                [ngClass]="{'Rectangle-563-copy': page!='vendornumberassignment', 'Rectangle-563-selected active' : page=='vendornumberassignment' }">
                                <p class="mr-0">Vendor Number Assignment <span style="float: right;"></span></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-1 leftminisec" (mouseenter)="onMouseEnter()">
                    <div style="text-align: center; margin-bottom: 10px;">
                        <!-- Content goes here -->
                    </div>
                </div>
                <div class="content-section" [ngClass]="{'col-10': isCard, 'col-11' : !isCard}">
                    <ng-container *ngIf="page === 'vendorcreation'">
                        <table class="common-table">
                            <app-vendor-creation-vendor-management></app-vendor-creation-vendor-management>
                        </table>
                    </ng-container>
                    <ng-container *ngIf="page === 'vendorassignment'">
                        <table class="common-table">
                            <app-vendor-assignment-vendor-management></app-vendor-assignment-vendor-management>
                        </table>
                    </ng-container>
                    <ng-container *ngIf="page === 'vendornumberassignment'">
                        <table class="common-table">
                            <app-vendor-number-assignment-vendor-management></app-vendor-number-assignment-vendor-management>
                        </table>
                    </ng-container>
                </div>

            </div>
        </div>
    </div>
