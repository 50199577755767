<div class="align-carousel" *ngIf="showCarousel">
  <div class="owl-carousel owl-theme carousel_2">
    <img src="assets/images/default-image.png" (click)="triggerFileUpload()" *ngIf="showDefaultImage">
    <img *ngFor="let img of images" [src]="img.image" (click)="enlargeImage(img.image)">
  </div>
</div>
<ng-container *ngIf="enlargedImage">
  <div class="enlarged-image-modal"> 
    <span class="left-arrow" (click)="slideImage('left')"><i class="fa fa-arrow-left"></i></span>
    <span class="right-arrow" (click)="slideImage('right')"><i class="fa fa-arrow-right"></i></span>
    <img [src]="enlargedImage" alt="enlarged-image" title="enlarged-image">
    <span class="close-icon-image" (click)="closeEnlargedView()" style="pointer-events: all;"><i class="fa fa-times-circle"></i></span>
  </div>
</ng-container>
