import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService } from 'src/app/shared/alert/alert.service';
import { Role } from 'src/app/shared/enums/role.enum';
import { ServerApisService } from 'src/app/shared/services/server-apis.service';
import { Constants, navConstants } from 'src/app/shared/constants/constant';
import { RouteMakingService } from 'src/app/shared/services/route-making.service';

@Component({
  selector: 'app-main-profession-professions',
  templateUrl: './main-profession-professions.component.html',
  styleUrls: ['./main-profession-professions.component.css']
})
export class MainProfessionProfessionsComponent {
  placeholderText = 'Search with Profession'
  professionName: string = '';
  professionNameNew: any;
  isPopup: boolean = false;
  previd: number = 0;
  selectedMainProfession: any = '';
  selectedMainProfessionId: any;
  modal: string = 'add';
  mainProfessions: any;
  tempMainProfession: any;
  p = 1;
  page: any = "assignService";
  mainProfessionSort: string = "";
  childProfessionSort: string = "";
  allChildProfessionCategory: any;
  adminApprovalCategories: any;
  tempAdminApprovalCategories: any;
  
  constructor(private router: Router, private routeMakingService: RouteMakingService, private _apiService: ServerApisService, protected alertService: AlertService, private spinner: NgxSpinnerService,) { }

  ngOnInit(): void {
    const user = sessionStorage.getItem('user');
    if (user != null) {

      let data = JSON.parse(user);
      if (data.roleId === Role.Admin || data.roleId === Role.SubAdmin || Role.SeoTeam) {
        this.fetchMainProfession();
        this.getAllAdminApprovalCategory();
        let path = window.location.href.split('profession/')
       
      } else {
        this.router.navigate([""]);
      }
    } else {
      this.router.navigate([this.routeMakingService.getNavigateUrl([Constants.Login])]);
    }
  }

  searchMainCategory(event: any) {
    {
       {
        this.mainProfessions = this.tempMainProfession;
        if (event.target.value != "" && event.target.value != null && event.target.value != undefined) {
          var result = this.mainProfessions.filter((x: any) => x.mainProfessionName.toLowerCase().includes(event.target.value.toLowerCase()))
          this.mainProfessions = result;
        } else {
          this.mainProfessions = this.tempMainProfession;
        }
   }
 }
}
getAllAdminApprovalCategory() {
  this._apiService.GetAdminApprovalCategories('pending').subscribe((res: any) => {
    this.adminApprovalCategories = res.FetchCategoriesAdminApprovalResult.resultFetchProfessionAdminApproval;
    this.tempAdminApprovalCategories = this.adminApprovalCategories;
   
  })
}


approved(data) {
  let reqBody = {
    "mainProfessionId": data.mainProfessionId,
    "mainProfessionName": data.mainProfessionName,
    "mainProfessionNameNew": data.mainProfessionNameNew,
    "approvedBy": sessionStorage.getItem('memberId'),
    "isDeleted": data.isDeleted,
    "isApproved": "Y"
  }
  this._apiService.AdminApprovalApi(reqBody).subscribe((res: any) => {
    if (res.status == 200) {
      this.alertService.success(res.result)
    } else {
      this.alertService.error(res.result)
    }
  })
}

reject(data) {
  let reqBody = {
    "mainProfessionId": data.mainProfessionId,
    "mainProfessionName": data.mainProfessionName,
    "mainProfessionNameNew": data.mainProfessionNameNew,
    "approvedBy": sessionStorage.getItem('memberId'),
    "isDeleted": data.isDeleted,
    "isApproved": "N"
  }
  this._apiService.AdminApprovalApi(reqBody).subscribe((res: any) => {
    if (res.status == 200) {
      this.alertService.success(res.result)
    } else {
      this.alertService.error(res.result)
    }
  })
}

addPop() {
  this.professionName = '';
}

deleteMainProfessionPop(data) {
  this.selectedMainProfession = data.mainProfessionName;
  this.selectedMainProfessionId = data.mainProfessionId;
}
updateMainProfessionPop(data) {
  this.modal = 'update';
  this.selectedMainProfessionId = data.mainProfessionId;
  this.selectedMainProfession = data.mainProfessionName;
  this.professionName = data.mainProfessionName;
}

fetchMainProfession() {
  this._apiService.GetMainProfession().subscribe((res: any) => {
    this.mainProfessions = res.FetchAllMainProfessionsResult.resultFetchProfessionAdminApproval;
    this.tempMainProfession = this.mainProfessions;
  
  })
}

updateProfession(): void {
  if (!this.professionNameNew) {
    this.alertService.error('Please Enter Update Value');
    return;
  }

  const reqBody = {
    "mainProfessionId": this.selectedMainProfessionId,
    "mainProfessionName": this.selectedMainProfession,
    "mainProfessionNameNew": this.professionNameNew,
    "approvedBy": sessionStorage.getItem('memberId')
  };

  this._apiService.updateMainProfession(reqBody).subscribe((res: any) => {
    if (res.status === 200) {
      this.alertService.success('Update request submitted for admin approval!');
    } else {
      this.alertService.error(res.result);
    }
  });
}

deleteMainProfession(Id) {
  let payload = {
    "mainProfessionId": Id,
    "approvedBy": sessionStorage.getItem('memberId')
  }
  this._apiService.deleteMainProfession(payload).subscribe((res: any) => {
    if (res.status === 200) {
      this.alertService.success('Delete request submitted for admin approval!');
      this.fetchMainProfession();
    } else {
      this.alertService.error(res.result);
    }
  })
}

saveProfession(): void {
  if (!this.professionName) {
    this.alertService.error('Please Enter Main Profession First');
    return;
  }

  const reqBody = {
    "mainProfessionName": this.professionName,
    "approvedBy": sessionStorage.getItem('memberId')
  };

  this._apiService.InsertMainProfession(reqBody).subscribe((res: any) => {
    if (res.status === 200) {
      this.alertService.success('Request to Create a Profession submitted to admin approval');
     
      document.getElementById('addModal').style.display = 'none';
      this.fetchMainProfession();
    } else {
      this.alertService.error(res.result);
    }
  });
}

pageChanged(event) {
 this.p = event;
}

mainProfessionNameSort() {
  if (this.mainProfessionSort === "") {
    this.mainProfessionSort = "up";
    this.allChildProfessionCategory.sort((a, b) => a.mainProfessionName.localeCompare(b.mainProfessionName));
  } else if (this.mainProfessionSort === "up") {
    this.mainProfessionSort = "down";
    this.allChildProfessionCategory.sort((a, b) => b.mainProfessionName.localeCompare(a.mainProfessionName));
  } else if (this.mainProfessionSort === "down") {
    this.mainProfessionSort = "";
    this.allChildProfessionCategory.sort((a, b) => a.mainProfessionId - b.mainProfessionId);
  }
}

mainProfessionOnSort(sortField: string): void {
  if (this.mainProfessionSort === "") {
    this.mainProfessionSort = "up";
    this.mainProfessions.sort((a, b) => a.mainProfessionName.localeCompare(b.mainProfessionName));
  } else if (this.mainProfessionSort === "up") {
    this.mainProfessionSort = "down";
    this.mainProfessions.sort((a, b) => b.mainProfessionName.localeCompare(a.mainProfessionName));
  } else if (this.mainProfessionSort === "down") {
    this.mainProfessionSort = "";
    this.mainProfessions.sort((a, b) => a.mainProfessionId - b.mainProfessionId);
  }
}
}
