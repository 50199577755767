import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TermsConditionsRoutingModule } from './terms-conditions-routing.module';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    TermsConditionsRoutingModule
  ]
})
export class TermsConditionsModule { }
