import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgSelectComponent } from '@ng-select/ng-select';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServerApisService } from 'src/app/shared/services/server-apis.service';
import { Role } from 'src/app/shared/enums/role.enum';
import { Constants, navConstants } from 'src/app/shared/constants/constant';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { RouteMakingService } from 'src/app/shared/services/route-making.service';
import { environment } from 'src/environments/environment';
import { MatTabChangeEvent } from '@angular/material/tabs';

@Component({
  selector: 'app-user-information-report',
  templateUrl: './user-information-report.component.html',
  styleUrls: ['./user-information-report.component.css']
})
export class UserInformationReportComponent implements OnInit {

  usersInformationForm: FormGroup;
  isCard: boolean = true;
  lstState: any[];
  lstCountry: any;
  bussinessImages: any;
  profleimge: any;
  imageheader: any;
  isEditIndex: number;
  activeUsers: any[] = [];
  inactiveUsers: any[] = [];
  filteredActiveUsers: any[] = [];
  filteredInactiveUsers: any[] = [];
  p = 1;
  searchtextActive: string = '';
  searchtextInactive: string = '';
  usersInfoCount: any;
  tempUsersInfoList: any;
  selectedActivitiesOption: string = 'All';
  selectedActivitiesDeletedUserOption: string = 'All';
  searchedAll: string;
  searchedName: string;
  searchedMobileNo: string;
  searchedUniqueId: string;
  searchedProfession: string;
  searchedDeletedUserAll: string;
  searchedDeletedUserName: string;
  searchedDeletedUserMobileNo: string;
  searchedDeletedUserUniqueId: string;
  searchedDeletedUserProfession: string;
  lstOrganizationalImages: any;
  maxDate: string;
  currentImageIndex: number = 0;
  images: string[] = [];


  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private _apiService: ServerApisService,
    private serviceApi: ServerApisService,
    private spinner: NgxSpinnerService,
    private cdr: ChangeDetectorRef,
    private datePipe: DatePipe,
    private sanitizer: DomSanitizer,
    private routeMakingService: RouteMakingService
  ) { }

  ngOnInit(): void {
    this.usersInformationForm = this.formBuilder.group({
      selectedCountry: [],
      fromdate: [''],
      todate: [""],
      selectedState: [],
    });
    let tdate = new Date();
    let fdate = new Date();
    fdate.setDate(fdate.getDate() - 10);
    this.usersInformationForm.get('fromdate')?.patchValue(this.formatDate(fdate));
    this.usersInformationForm.get('todate')?.patchValue(this.formatDate(tdate));
    this.maxDate = this.formatDate(new Date());
    const user = sessionStorage.getItem('user');
    console.log(this.usersInformationForm)
    if (user != null) {
      let data = JSON.parse(user);
      if (data.roleId === Role.Admin || data.roleId === Role.CallCenter) {
        this.getAllUsersInformationReport(this.p);
        this.getAllUsersInformationDeletedUserReport(this.p);
        this.getCountries();
      } else {
        this.router.navigate([""]);
      }
    } else {
      let params = [Constants.Login]
      let routingUrl = this.routeMakingService.getNavigateUrl(params)
      this.router.navigate([routingUrl]);
    }
  }

  loadTabData(report: any) {
    throw new Error('Method not implemented.');
  }

  formatDate(date: Date): string {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  }


  changeSelectCountry(event: any) {
    if (event != undefined) {
      this.usersInformationForm.patchValue({ country: event.country_id });
      this.lstState = [];
      this.getStates(event.country_id)
    } else {
      this.usersInformationForm.patchValue({ country: 0 });
      this.lstState = [];
    }
  }

  changeSelectState(event: any) {
    if (event != undefined) {
      this.usersInformationForm.patchValue({ state: event.state_id });
    } else {
      this.usersInformationForm.patchValue({ state: 0 });
    }
  }

  getCountries() {
    this._apiService.GetCountries().subscribe(res => {
      this.lstCountry = res['GetAllCountriesResult'].resultCountries;
      this.getStates(2);
      const index = this.lstCountry.findIndex(user => user.country_id === '2');
      if (index >= 0) {
        this.usersInformationForm.get('selectedCountry').setValue(this.lstCountry[index]);
      }
    });
  }

  getOrganizationImages(memberId: number) {
    this._apiService.GetOrganizationImage(memberId).subscribe((res: any) => {
      this.lstOrganizationalImages = res['GetAllOrganisationalImagesResult'].resulFetchOrganisationalImages;
      this.images = this.lstOrganizationalImages.map(image => image.orgImagePath);
     this.currentImageIndex = 0;
     this.updateImage();
    const modal = document.getElementById('imageModal');
    if (modal) {
      modal.style.display = "block";
    }
    });
  }

  searchtab(page: string) {
    throw new Error('Method not implemented.');
  }

  getStates(countryId: any) {
    let payload = { country_id: countryId }
    this.spinner.show();

    this._apiService.GetStatesByCountry(payload).subscribe(res => {
      this.spinner.hide();
      this.lstState = res.resultStates;
    });
  }
  formatDateString(date: Date): string {
    if (!date) return '';
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, '0');
    const month = String(d.getMonth() + 1).padStart(2, '0'); 
    const year = d.getFullYear();
    return `${month}/${day}/${year}`;
  }
  
  getAllUsersInformationReport(page) {
    this.spinner.show();
    const fromDate = this.usersInformationForm.get('fromdate').value;
    const toDate = this.usersInformationForm.get('todate').value;
  
    let payload = {
      name: '',
      fromDate: this.formatDateString(fromDate),
      toDate: this.formatDateString(toDate),
      uniqueId: '',
      mobileNo: '',
      profession: '',
      country: '',
      state: '',
      zipCode: '',
      currentPage: page,
      isDeleted: "No"
    };
    console.log(payload);
    this.serviceApi.GetAllUsersInformationReport(payload).subscribe(
      (res: any) => {
        this.spinner.hide();
        if (res.GetAllUsersInformationReportResult && res.GetAllUsersInformationReportResult.result === "Success") {
          const users = res.GetAllUsersInformationReportResult.resultUsersInformation;
          this.activeUsers = users.filter(user => user.isDeleted === "No");
          this.filteredActiveUsers = this.activeUsers;
          this.usersInfoCount = users.length ? users[0].RecordCount : 0;
          this.scrollToTop();
        } else {
          console.error('API response not as expected:', res);
        }
      },
      (error) => {
        this.spinner.hide();
        console.error('Error fetching users information report:', error);
      }
    );
  }
  

  getAllUsersInformationDeletedUserReport(page) {
    this.spinner.show();
    const fromDate = this.usersInformationForm.get('fromdate').value;
    const toDate = this.usersInformationForm.get('todate').value;
  
    let payload = {
      name: '',
      fromDate: this.formatDateString(fromDate),
      toDate: this.formatDateString(toDate),
      uniqueId: '',
      mobileNo: '',
      profession: '',
      country: '',
      state: '',
      zipCode: '',
      currentPage: page,
      isDeleted: "Yes"
    };
    this.serviceApi.GetAllUsersInformationReport(payload).subscribe(
      (res: any) => {
        this.spinner.hide();
        if (res.GetAllUsersInformationReportResult && res.GetAllUsersInformationReportResult.result === "Success") {
          const users = res.GetAllUsersInformationReportResult.resultUsersInformation;
          this.inactiveUsers = users.filter(user => user.isDeleted === "Yes");
          this.filteredInactiveUsers = this.inactiveUsers;
          this.usersInfoCount = users.length ? users[0].RecordCount : 0;
          this.scrollToTop();
        } else {
          console.error('API response not as expected:', res);
        }
      },
      (error) => {
        this.spinner.hide();
        console.error('Error fetching users information report:', error);
      }
    );
  }

  openProfileImageModal(profileImageUrl: string): void {
    this.images = [profileImageUrl];
    this.currentImageIndex = 0;
    this.updateImage();
    const modal = document.getElementById('imageModal');
    if (modal) {
      modal.style.display = "block";
    }
  }
  
  openOrganisationImageModal(data: any): void {
    this.getOrganizationImages(data.MemberID)
    
  }
  
  updateImage(): void {
    const modalImg = document.getElementById('popupImage') as HTMLImageElement;
    if (modalImg) {
      modalImg.src = this.images[this.currentImageIndex] || 'assets/images/no-image.png';
    }
  }
  
  changeImage(direction: number): void {
    this.currentImageIndex += direction;
    if (this.currentImageIndex >= this.images.length) {
      this.currentImageIndex = 0;
    } else if (this.currentImageIndex < 0) {
      this.currentImageIndex = this.images.length - 1;
    }
    this.updateImage();
  }
  

  resetActivitiesOptions() {
    this.searchedAll = '';
    this.searchedName = '';
    this.searchedMobileNo = '';
    this.searchedUniqueId = '';
    this.searchedProfession = '';
  }

  searchedByAll(event: any) {
    if (event.target.value != '' && event.target.value != undefined) {
      this.filteredActiveUsers = this.activeUsers.filter((x: any) =>
        x.Name.toString().toLowerCase().includes(event.target.value.toLowerCase()) ||
        x.MobileNo.toString().toLowerCase().includes(event.target.value.toLowerCase()) ||
        x.Professions.toString().toLowerCase().includes(event.target.value.toLowerCase()) ||
        x.UserUniqueCode.toLowerCase().includes(event.target.value.toLowerCase())
      );
    } else {
      this.filteredActiveUsers = this.activeUsers;
    }
  }

  searchedByName(event: any) {
    if (event.target.value != '' && event.target.value != undefined) {
      this.filteredActiveUsers = this.activeUsers.filter((x: any) =>
        x.Name.toString().toLowerCase().includes(event.target.value.toLowerCase())
      );
    } else {
      this.filteredActiveUsers = this.activeUsers;
    }
  }

  searchedByMobileNo(event: any) {
    const inputValue = event.target.value;
    if (/^\d*$/.test(inputValue)) {
      if (inputValue !== '') {
        this.filteredActiveUsers = this.activeUsers.filter((x: any) =>
          x.MobileNo.toString().toLowerCase().includes(inputValue.toLowerCase())
        );
      } else {
        this.filteredActiveUsers = this.activeUsers;
      }
    } else {
      event.target.value = inputValue.replace(/\D/g, '');
    }
  }

  searchedByUniqueId(event: any) {
    if (event.target.value != '' && event.target.value != undefined) {
      this.filteredActiveUsers = this.activeUsers.filter((x: any) =>
        x.UserUniqueCode.toLowerCase().includes(event.target.value.toLowerCase())
      );
    } else {
      this.filteredActiveUsers = this.activeUsers;
    }
  }

  searchedByProfession(event: any) {
    if (event.target.value != '' && event.target.value != undefined) {
      this.filteredActiveUsers = this.activeUsers.filter((x: any) =>
        x.Professions.toLowerCase().includes(event.target.value.toLowerCase())
      );
    } else {
      this.filteredActiveUsers = this.activeUsers;
    }
  }

  OnlyLettersAllowed(event): boolean {
    const charCode = (event.which) ? event.which : event.keycode;
    if ((charCode < 97 || charCode > 123) && (charCode < 65 || charCode > 90)) {
      return false;
    }
    return true;
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  profilePopup(imageUrl: string, title: string) {
    this.profleimge = imageUrl;
    this.imageheader = title;
    document.getElementById('profileModal').style.display = 'block';
  }

  getindex(user) {
    this.bussinessImages = user.OrganisationImages;
    document.getElementById('id05').style.display = 'block';
  }

  menu() {
    this.isCard = !this.isCard;
  }

  getprofession(data: any) {
    let profession: string = '';
    if (data.Professions) {
      profession = data.Professions.replace(/,/g, ', ');
    }
    return profession;
  }

  resetActivitiesDeletedUsersOptions() {
    this.searchedDeletedUserAll = '';
    this.searchedDeletedUserName = '';
    this.searchedDeletedUserMobileNo = '';
    this.searchedDeletedUserUniqueId = '';
    this.searchedDeletedUserProfession = '';
  }

  searchedByDeletedUsersAll(event: any) {
    if (event.target.value != '' && event.target.value != undefined) {
      this.filteredInactiveUsers = this.inactiveUsers.filter((x: any) =>
        x.Name.toString().toLowerCase().includes(event.target.value.toLowerCase()) ||
        x.MobileNo.toString().toLowerCase().includes(event.target.value.toLowerCase()) ||
        x.Professions.toString().toLowerCase().includes(event.target.value.toLowerCase()) ||
        x.UserUniqueCode.toLowerCase().includes(event.target.value.toLowerCase())
      );
    } else {
      this.filteredInactiveUsers = this.inactiveUsers;
    }
  }

  searchedByDeletedUsersName(event: any) {
    if (event.target.value != '' && event.target.value != undefined) {
      this.filteredInactiveUsers = this.inactiveUsers.filter((x: any) =>
        x.Name.toString().toLowerCase().includes(event.target.value.toLowerCase())
      );
    } else {
      this.filteredInactiveUsers = this.inactiveUsers;
    }
  }

  searchedByDeletedUsersMobileNo(event: any) {
    const inputValue = event.target.value;
    if (/^\d*$/.test(inputValue)) {
      if (inputValue !== '') {
        this.filteredInactiveUsers = this.inactiveUsers.filter((x: any) =>
          x.MobileNo.toString().toLowerCase().includes(inputValue.toLowerCase())
        );
      } else {
        this.filteredInactiveUsers = this.inactiveUsers;
      }
    } else {
      event.target.value = inputValue.replace(/\D/g, '');
    }
  }

  searchedByDeletedUsersUniqueId(event: any) {
    if (event.target.value != '' && event.target.value != undefined) {
      this.filteredInactiveUsers = this.inactiveUsers.filter((x: any) =>
        x.UserUniqueCode.toLowerCase().includes(event.target.value.toLowerCase())
      );
    } else {
      this.filteredInactiveUsers = this.inactiveUsers;
    }
  }

  searchedByDeletedUsersProfession(event: any) {
    if (event.target.value != '' && event.target.value != undefined) {
      this.filteredInactiveUsers = this.inactiveUsers.filter((x: any) =>
        x.Professions.toLowerCase().includes(event.target.value.toLowerCase())
      );
    } else {
      this.filteredInactiveUsers = this.inactiveUsers;
    }
  }


  clearFields() {
    this.searchtextActive = '';
    this.searchtextInactive = '';
  }


  onTabChange(event: MatTabChangeEvent) {
    this.resetActivitiesOptions();
    this.resetActivitiesDeletedUsersOptions();
  }

  pageChangedActiveUser(event) {
    this.p = event;
    this.getAllUsersInformationReport(this.p);
    this.cdr.detectChanges();
  }

  pageChangedDeletedUser(event) {
    this.p = event;
    this.getAllUsersInformationDeletedUserReport(this.p);
    this.cdr.detectChanges();
  }
}


