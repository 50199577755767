<div class="container-fluid ">
  <div class="row">
    <h1 class="text-center"><b class="blue-color">Verify OTP</b></h1>
</div>
    <form [formGroup]="form" class="marginop-3"> 
      <label class="lightgrey">Enter the verification code we sent to</label><br>
      <label class="lightgrey">{{mobilenum}} </label><br>
      <img src=".../../../../../assets/images/mobile-icon.png" alt="mobile icon" title="mobile icon" ><br>
      <label class="lightgrey">Enter 6 digit code</label>
      <h3> <i class="fa fa-mobile"></i> Enter your OTP </h3>              
      <div #dynamicDiv id="loginrecaptcha1"></div>           
      <div class="row">
          <div class="form-group col-2" *ngFor="let input of formInput;  index as i">
              <input #formRow type="text" formControlName="{{input}}" class="form-control" maxlength="1" (keyup)="keyUpEvent($event, i)" [autofocus]="i === 0">
            </div>
            <p class="time" *ngIf="!timerValid">{{num1}} seconds</p>
            <label *ngIf="verificationCode.length==0 && submitted" style="color: red; text-align: end;">Please enter your OTP</label>
      </div>
      <div class="row">
        <div class="col-md-6">
          <button class="btn btn-primary Verify-btn w-100 mt-2" (click)="onSubmit()">Verify</button>
          <p class="btn btn-primary Verify-btn w-100 mt-2" *ngIf="timerValid" (click)="resendOtp()" style="margin-left: 60%;">Resend OTP</p>
        </div>
        <div class="col-md-6">
          <button class="btn btn-secondary cancel-btn w-100 mt-2" (click)="cancel()">Cancel</button>
        </div>
      </div>
      </form>
  </div>