import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private flagSource = new BehaviorSubject<boolean>(false);
  private popUpFlagSource = new Subject<boolean>();
  private serviceSearchData = new BehaviorSubject<any>('');
  private userData = new BehaviorSubject<any>('');
  currentFlag = this.flagSource.asObservable();
  currentPopUpFlag = this.popUpFlagSource.asObservable();
  currentServiceSearchData = this.serviceSearchData.asObservable();
  currentUserData = this.userData.asObservable();

  constructor() { }

  changeFlag(flag: boolean) {
    this.flagSource.next(flag);
  }

  popUpFlag(flag: boolean) {
    this.popUpFlagSource.next(flag);
  }

  setSearchData(data: any) {
    this.serviceSearchData.next(data);
  }

  setUserData(data: any) {
    this.userData.next(data);
  }
}
