import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NoProviderResultsFoundRoutingModule } from './no-provider-results-found-routing.module';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    NoProviderResultsFoundRoutingModule
  ]
})
export class NoProviderResultsFoundModule { }
