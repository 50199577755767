import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/shared/alert/alert.service';
import { ServerApisService } from 'src/app/shared/services/server-apis.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Role } from 'src/app/shared/enums/role.enum';
import { Constants,navConstants } from 'src/app/shared/constants/constant';
import { RouteMakingService } from 'src/app/shared/services/route-making.service';

@Component({
  selector: 'app-modify-advertisement-advertisement',
  templateUrl: './modify-advertisement-advertisement.component.html',
  styleUrls: ['./modify-advertisement-advertisement.component.css']
})
export class ModifyAdvertisementAdvertisementComponent {
  constructor(private router: Router,private formBuilder: FormBuilder,private _apiService:ServerApisService,private spinner: NgxSpinnerService,
    private _alertService: AlertService,private datePipe: DatePipe,private routeMakingService: RouteMakingService) { }
    @ViewChild('selectBox') selectBox: NgSelectComponent;
    advertisementForm: FormGroup;
    extn: any;
    p = 1;
    placeholderText: string = 'Search with Title, Description';
   filestream: any;
   profleimge:any;
   lstadvertisements:any=[];
   lsttempadvertisements:any=[];
   lstUsers:any=[];
   isupdate:boolean=false;
   lsttempusers:any=[];
   ImgValidate: any;
   public imagePath;
   imageChangedEvent: any = '';
   page: string = 'createadvertisements';
   isEditIndex:number;
   minDate :any;
   membersort: string = "";
   titlesort: string = "";
   datesort: string = "";
   descriptionsort:string="";


   ngOnInit(): void {
    const urlRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
    var date= new Date();
    date=new Date(date.setDate(date.getDate() + 1));
    this.minDate=this.datePipe.transform(date,"yyyy-MM-dd")
    const user = sessionStorage.getItem('user');
    if (user != null) {
      let data=JSON.parse(user);
      if (data.roleId === Role.Admin || data.roleId === Role.SubAdmin || data.roleId === Role.SalesTeam || data.roleId === Role.Manager) {
      
        this.advertisementForm = this.formBuilder.group({
        title: ['', Validators.required],
        description: ['', Validators.required],
        date:['', Validators.required],
        image:['', Validators.required],
        vediopath:['',[Validators.pattern(urlRegex)]],
        screen:["Dashboard", Validators.required],
        category:["Top", Validators.required],
        price:[""],
        redirectlink:[''],
        phone:[""],
        email:[""],
        clientType:['InternalClient'],
        clientNavigation :['Profile'],
        navigatingToMemberId:["0"],
        selectedUser:[]
      });

       let path=window.location.href.split('advertisement/')
       this.router.navigate([this.routeMakingService.getNavigateUrl([Constants.adminPanelRoute,Constants.advertisement,navConstants.modifyAdvertisementNav])]);
    }
      
    }else{
      this.router.navigate([this.routeMakingService.getNavigateUrl([Constants.Login])]);
    } 
  }

  getallAds(){
    this.spinner.show();
    this._apiService.FetchAllAds().subscribe(res => {
      this.spinner.hide();
      this.lstadvertisements = res.resultAdvertisementsArgs;
      this.lsttempadvertisements = res.resultAdvertisementsArgs;
      this.page="advertisement";
    });
  }
  back(){
    this.page="advertisement";
  }
  Cancel(){
    this.advertisementForm.reset();
        this.advertisementForm.patchValue({screen: ''});
        this.advertisementForm.patchValue({category: ''});
        this.advertisementForm.patchValue({image: ''});
        this.advertisementForm.patchValue({clientType: 'InternalClient'});
        this.advertisementForm.patchValue({clientNavigation: 'Profile'});
        this.advertisementForm.patchValue({navigatingToMemberId:'0'});
        this.selectBox.clearModel();
        this.isupdate=false;
        this.page="advertisement";
  }
 
  pageChanged(event: any) {
    this.p = event;
    this.isEditIndex=-1;
  }

  updateProfile:any;
  selectedUsers:any=[];
  editAdvertisement(value){
    const payLoad:any={};
    payLoad.addsId=value.addsId;
    this._apiService.FetchAddsDetails(payLoad).subscribe(res => {
     if(res.status==200){
        this.updateProfile=res.resultFetchAddsDetails;
        this.page='createadvertisements';
        this.advertisementForm.patchValue({clientType: res.resultFetchAddsDetails.clientType});
        this.advertisementForm.patchValue({clientNavigation: res.resultFetchAddsDetails.clientNavigation});
        this.advertisementForm.patchValue({title: res.resultFetchAddsDetails.title});
        this.advertisementForm.patchValue({description: res.resultFetchAddsDetails.description});
        this.advertisementForm.patchValue({clientType: res.resultFetchAddsDetails.clientType});
        this.advertisementForm.patchValue({screen:res.resultFetchAddsDetails.screen});
        this.advertisementForm.patchValue({image:res.resultFetchAddsDetails.imageName});
        const valueToSet = (res.resultFetchAddsDetails.videoURL !== null && res.resultFetchAddsDetails.videoURL !== "null") ? res.resultFetchAddsDetails.videoURL : '';
        this.advertisementForm.patchValue({vediopath:valueToSet});
        let exprydate=this.datePipe.transform(res.resultFetchAddsDetails.expiryDate,"yyyy-MM-dd");
        this.advertisementForm.patchValue({date:exprydate});
        this.advertisementForm.patchValue({price:res.resultFetchAddsDetails.price});
        this.advertisementForm.patchValue({email:res.resultFetchAddsDetails.contactEmail!="null" ?res.resultFetchAddsDetails.contactEmail:''});
        this.advertisementForm.patchValue({phone:res.resultFetchAddsDetails.contactPhone!="null" ?res.resultFetchAddsDetails.contactPhone:''});
        this.advertisementForm.patchValue({redirectlink:res.resultFetchAddsDetails.redirectLink!="null" ?res.resultFetchAddsDetails.redirectLink:''});
        this.advertisementForm.patchValue({category:res.resultFetchAddsDetails.category});
        this.advertisementForm.patchValue({navigatingToMemberId:res.resultFetchAddsDetails.navigatingToMemberId});
        if(res.resultFetchAddsDetails.navigatingToMemberId!=0){
          const index = this.lstUsers.findIndex(user => user.memberId === res.resultFetchAddsDetails.navigatingToMemberId);
        if(index>=0){
         }
     }
       this.isupdate=true;
      }
   });
  }
  searchChange(event:any){
    
    if(event.target.value!='' && event.target.value!=undefined){
      
      var result= this.lsttempadvertisements.filter((x:any)=>x.title.toLowerCase().includes(event.target.value.toLowerCase())
      || x.description.toLowerCase().includes(event.target.value.toLowerCase()))
        this.lstadvertisements=result;
    }else{
      this.lstadvertisements=this.lsttempadvertisements;
    }
  }

  addsId:number=0;
  isdelete:boolean=false;
  deleteAdvertisement(value){
   this.addsId=value.addsId;
   this.isdelete=true;
  }
  ok(){
    const payLoad:any={};
    payLoad.addsId=this.addsId;
    payLoad.deletedBy=(+sessionStorage.getItem('memberId'));
    this._apiService.DeleteAdvertisements(payLoad).subscribe(res => {
      this.spinner.hide();
      if(res.status==200){
        this._alertService.success(res['result']);
        this.getallAds();
        this.isdelete=false;
      }
    });
  }
  close(){
    this.isdelete=false;
  }
  profilePopup(image){
    this.profleimge=image;
  }
  memberSort() {
    if (this.membersort == "") {
      this.membersort = "up";
      this.lstadvertisements.sort((a, b) => (b.addsId > a.addsId) ? -1 : 1);

    } else if (this.membersort == "up") {
      this.membersort = "down";
      this.lstadvertisements.sort((a, b) => (a.addsId > b.addsId) ? -1 : 1);

    } else if (this.membersort == "down") {
      this.membersort = "";
      this.lstadvertisements.sort((a, b) => a.addsId - b.addsId);
    }
  }
  titleonSort() {

    if (this.titlesort == "") {
      this.titlesort = "up";
      this.lstadvertisements.sort((a, b) => (b.title > a.title) ? -1 : 1);

    } else if (this.titlesort == "up") {
      this.titlesort = "down";
      this.lstadvertisements.sort((a, b) => (a.title > b.title) ? -1 : 1);

    } else if (this.titlesort == "down") {
      this.titlesort = "";
      this.lstadvertisements.sort((a, b) => a.addsId - b.addsId);
    }
  }
  descriptionOnsort(){
    if (this.descriptionsort == "") {
      this.descriptionsort = "up";
      this.lstadvertisements.sort((a, b) => (b.description > a.description) ? -1 : 1);

    } else if (this.descriptionsort == "up") {
      this.descriptionsort = "down";
      this.lstadvertisements.sort((a, b) => (a.description > b.description) ? -1 : 1);

    } else if (this.descriptionsort == "down") {
      this.descriptionsort = "";
      this.lstadvertisements.sort((a, b) => a.addsId - b.addsId);
    }
  }

  exiprydatesort() {
    if (this.datesort == "") {
      this.datesort = "up";
      this.lstadvertisements.sort((a, b) => (b.expiryDate > a.expiryDate) ? -1 : 1);

    } else if (this.datesort == "up") {
      this.datesort = "down";
      this.lstadvertisements.sort((a, b) => (a.expiryDate > b.expiryDate) ? -1 : 1);

    } else if (this.datesort == "down") {
      this.datesort = "";
      this.lstadvertisements.sort((a, b) => a.addsId - b.addsId);
    }
  }

}
