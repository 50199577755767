import { Component, EventEmitter, NgZone, OnInit,Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { ServerApisService } from 'src/app/shared/services/server-apis.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService } from 'src/app/shared/alert/alert.service';
import { Router } from '@angular/router';
import { GoogleApisService } from '../../shared/services/google-apis.service'

@Component({
  selector: 'app-create-account',
  templateUrl: './create-account.component.html',
  styleUrls: ['./create-account.component.css']
})
export class CreateAccountComponent implements OnInit {
  @Output() setAddress: EventEmitter<any> = new EventEmitter();
  createAccountForm: FormGroup;
  maxLength = 60;
  localAddress: string[];
  autoCompleteOptions = {
    types: ['address']
  };
  origin: {};
  locationDisplay: boolean = true;
  userObject = {
    accountId: 1,
    fname: '',
    lname: '',
    email: '',
    mobile: '',
    serviceId: 1,
    longitude: '',
    latitude: '',
    fullAddress: '',
    referral_code: '',
    countryCode: '',
    country_id: 0,
    deviceType: 'web',
    deviceId: '',
    state_name: '',
    pinCode: '',
  };
  addressobj: any;
  lstCountry: any;
  selectedcountry: any;
  model: any = {};
  submitted: boolean = false;
  acceptTermschecked: boolean = false;

  constructor(
    private zone: NgZone,
    private router: Router,
    private _alertService: AlertService,
    private formBuilder: FormBuilder,
    private serverApi: ServerApisService,
    private spinner: NgxSpinnerService,
    private googleApi: GoogleApisService,
  ) {}

  ngOnInit() {
    this.createAccountForm = this.formBuilder.group({
      fullName: ['', [Validators.required, this.nameValidator]],
      email: ['', [Validators.email]],
      address: ['', Validators.required],
      promoCode: ['']
    });
    this.addressobj = {};
    this.getCurrentLocation();
  }


  getCurrentLocation() {
    navigator.geolocation.getCurrentPosition((position) => {
      this.addressobj['latlng'] = position.coords.latitude + ',' + position.coords.longitude;
      this.origin = {};
      this.origin['lat'] = position.coords.latitude;
      this.origin['lng'] = position.coords.longitude;
      this.googleApi.originLatLng(this.origin);
      this.getAddress(this.addressobj)
    });
  }

  acceptedterms(event) {

    if (event.target.checked) {
      this.model.acceptTerms = true;
      this.acceptTermschecked = true;
      this.acceptTermschecked = event.target.checked;
    } else {
      this.model.acceptTerms = false;
      this.acceptTermschecked = false;
    }
  }
  
  getAddress(a) {
    this.googleApi.getCurrentGeoAddress(a).subscribe(res => {
      let formatAddress = res.results[0].formatted_address
      this.createAccountForm.get('address').setValue(formatAddress);
      this.localAddress = formatAddress;
      this.setAddress.emit(this.localAddress);
      res.results[0].address_components;
      for (let i = 0; i < res.results[0].address_components.length; i++) {
        const element = res.results[0].address_components[i];
        for (let m = 0; m < element.types.length; m++) {
          const ele = element.types[m];
          if (ele == "postal_code") {
            this.userObject.pinCode = element.long_name;
          }
          if (ele == "administrative_area_level_1") {
            this.userObject.state_name = element.long_name;
          }
        }
      }
      this.userObject.latitude = res.results[0].geometry.location.lat;
      this.userObject.longitude = res.results[0].geometry.location.lng;
    },
      error => { console.log(error) })
  }


  getLocationAddressCoordinates(address: any) {
    this.origin = {};
    if (typeof address !== 'string') {
      this.localAddress = address.formatted_address;
      this.origin['lat'] = address.geometry.location.lat();
      this.origin['lng'] = address.geometry.location.lng();
      this.googleApi.originLatLng(this.origin);
      this.userObject.latitude = address.geometry.location.lat();
      this.userObject.longitude = address.geometry.location.lng();
    } else {
      this.localAddress = [address];
    }
    this.zone.run(() => {
      this.localAddress = typeof this.localAddress === 'string' ? [this.localAddress] : this.localAddress;
      this.createAccountForm.get('address').setValue(this.localAddress[0]);
    });
  }

  nameValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const regex = /^[a-zA-Z\s]*$/;
    if (control.value && !regex.test(control.value)) {
      alert('Special character and numbers are not allowed, Form will not submit');
      return { 'invalidName': true };
    }
    return null;
  }

  isFieldInvalid(field: string) {
    const control = this.createAccountForm.get(field);
    return control.touched && control.invalid;
  }

  getFullNameErrorMessage() {
    const fullNameControl = this.createAccountForm.get('fullName');
    if (fullNameControl.hasError('required')) {
      return 'Name is required';
    } else if (fullNameControl.hasError('invalidName')) {
      return 'Invalid characters in Name';
    }

    return '';
  }

  onSubmit() {
    if (this.createAccountForm.invalid) {
      this.createAccountForm.get('fullName').markAsTouched();
      // this.createAccountForm.get('email').markAsTouched();
      this.createAccountForm.get('address').markAsTouched();
      return;
    }
    const formValue = this.createAccountForm.value;
    this.userObject.fname = formValue.fullName;
    this.userObject.email = formValue.email;
    this.userObject.fullAddress = formValue.address;
    this.userObject.referral_code = formValue.promoCode !== '' ? formValue.promoCode : '';
    this.userObject.countryCode = sessionStorage.getItem('countryCodeNumber');
    this.userObject.mobile = sessionStorage.getItem('mobileNum');
    this.userObject.country_id = Number(sessionStorage.getItem('countryId'));
    this.spinner.show();

    this.serverApi.createAccount(this.userObject).subscribe((res: any) => {
      if (res.resultModel.status == 200) {
        if (res.resultModel.memberId !== undefined) {
          let payLoad: any = {
            memberId: res.resultModel.memberId,
            providerMemberId: res.resultModel.memberId
          };
          this.serverApi.token = res.resultModel.token;
          sessionStorage.setItem('token', res.resultModel.token);
          payLoad.token = res.resultModel.token;
          this.serverApi.getUserProfile(payLoad).subscribe(
            result => {
              this.spinner.hide();
              let tempMemeberId = res.resultModel.memberId;
              this._alertService.success(res.resultModel.result);
              this.router.navigate(['']);
              let profile = result['Registration'];
              sessionStorage.setItem('loggedIn', 'true');
              sessionStorage.setItem('token', res.resultModel.token);
              sessionStorage.setItem('memberId', tempMemeberId);
              sessionStorage.setItem('user', JSON.stringify(profile));
              this.serverApi.sendUpdate(profile);
              if(sessionStorage.getItem('redirectSearch') != null && sessionStorage.getItem('redirectSearch') != undefined){
                sessionStorage.removeItem('redirectSearch');
                let searchData = JSON.parse(sessionStorage.getItem('searchData'));
                let professionNameWithHyphen = searchData.filterName;
                if (professionNameWithHyphen.includes(' ')) {
                  professionNameWithHyphen = professionNameWithHyphen.replace(/ /g, '-');
                }
                this.router.navigate([`${sessionStorage.getItem('parentCategory').replace(/ /g, '-').toLowerCase()}/${professionNameWithHyphen.toLowerCase()}/${sessionStorage.getItem('locality').replace(/ /g, '-')}`]).then(() => {
                  window.location.reload();
                });
              } else if(sessionStorage.getItem('redirectProviderProfile') != null && sessionStorage.getItem('redirectProviderProfile') != undefined){
                sessionStorage.removeItem('redirectProviderProfile');
                let providerProfile = JSON.parse(sessionStorage.getItem('providerProfile'));
                let fullName = `${providerProfile.fname}${providerProfile.lname}`;
                fullName = fullName.replace(/\s+/g, ' ');
                fullName = fullName.replace(/\s/g, '-');
                this.router.navigate(['/profile', `${fullName.toLowerCase()}-${providerProfile.memberId}`]).then(() => {
                  window.location.reload();
                });
              } else if(sessionStorage.getItem('redirectContactUs') != null && sessionStorage.getItem('redirectContactUs') != undefined) {
                sessionStorage.removeItem('redirectContactUs');
                this.router.navigate(['/contact-us']).then(()=>{
                  window.location.reload();
                })
              }
            });
        } else {
          this.spinner.hide();
          this._alertService.error(res.resultModel.result);
        }
        this.spinner.hide();
      }
    });
  }
}
