import { Component, HostListener, Inject, OnInit, ViewChild, ElementRef, Renderer2, AfterViewInit, EventEmitter, Output, OnChanges,ChangeDetectorRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NavigationEnd, Router , ActivatedRoute} from '@angular/router';
import { AlertService } from 'src/app/shared/alert/alert.service';
import { ServerApisService } from 'src/app/shared/services/server-apis.service';
import { LoginComponent } from '../login/login.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { GoogleApisService } from 'src/app/shared/services/google-apis.service';
import { ServiceProviderListComponent } from '../service-provider-list/service-provider-list.component';
import { filter } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { NgSelectComponent } from '@ng-select/ng-select';
import { DataService } from '../../shared/services/data.service';
import { PropsService } from '../../shared/services/props.service';
import { SearchService } from '../../shared/services/search.service';
import { HeaderService } from '../../shared/services/header.service';
import { GoogleTagManagerService } from 'src/app/shared/services/google-tag-manager.service';
import { Role } from 'src/app/shared/enums/role.enum';
import { Constants} from  'src/app/shared/constants/constant';
import { RouteMakingService } from 'src/app/shared/services/route-making.service';
import { StaticRoutesService } from 'src/app/shared/services/static-routes.service';
import { DashboardService } from 'src/app/shared/services/dashboard.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css', './header2.component.css']
})
export class HeaderComponent implements OnInit, AfterViewInit {
  private clickListener: () => void;
  originUrl: string = this.document.location.pathname;
  image: any = null;
  imagetext: any;
  localAddress: string[];
  isdisableDropdown: boolean = false;
  search = false;
  isRemovedService: boolean = false;
  searchData: {
    filterName?: string;
    filterDistance?: number;
    localAddress?: string[];
    categoryid?: number;
    toCorrdinates?: string;
  } = {};
  isHeader: boolean = true;
  serviceid: number = 0;
  wishlistCount: number = 0;
  psearch = true;
  addresscoordinates: any = [];
  selectedProfessionName: string = "";
  origin: {};
  userLocation: string = "";
  autocompleteaddress: any;
  page: string = "";
  locationdisplay: boolean = true;
  lstServices: any = [];
  lstTempServices: any = [];
  servicename: string = "";
  isSearchResultsPage: boolean = window.location.pathname.includes('search');
  addserviceForm: FormGroup;
  loggedIn: boolean = false;
  isDrpdown: boolean = false;
  API_URL: string = "https://blogs.toskie.com/"
  isAdmin: boolean = false;
  currentUser: any;
  isneedService: boolean = false;
  notificationList: any = [];
  lstAllServices: any = [];
  lstallDRpServices: any = [];
  lstTempAllServices: any = [];
  providerSearch: any = "";
  providerFilterDistance: any =  Constants.DefaultRangeDistance;
  isadd: boolean = false;
  issaveaddservice: boolean = false;
  isDropdown: boolean = false;
  isaddservice: boolean = false;
  submitted: boolean = false;
  @ViewChild('selectBoxs') selectBoxs: NgSelectComponent;
  @ViewChild('loginComponent') loginComponent: LoginComponent;
  @ViewChild('serviceProviderListCmpnt') serviceProviderListCmpnt: ServiceProviderListComponent;
  @Output() clickOutside = new EventEmitter<void>();
  loginPage: boolean = false;
  lstCountry: any;
  addressobj: any = {};
  latlngs: string = '';
  userChangedLocationSub: any;
  currentLocationSubject: any;
  userLatLng: { latitude: number; longitude: number; };
  currentLatLng: { latitude: number; longitude: number; };
  isLogin: boolean = false;
  profileName: string = "";
  wishlistCountSub: any;
  navigateToSearchAfterLoginSub: any;
  navigateToSearchAfterLogin: boolean;
  flag: boolean = false;
  lstChildProfessions: any = [];
  @ViewChild('navbar') navbar: ElementRef;
  @ViewChild('toggleButton') toggleButton: ElementRef;
  notificationCount: number = 0;
  allStaticRoutes = this.staticRoutes.getStaticRoutes();
  isStaticRoute: boolean;
  allProfessionSub: any;

  constructor(
    private dashboardService: DashboardService,
    private staticRoutes:StaticRoutesService,
    private headerService: HeaderService,
    private _apiService: ServerApisService,
    private _alert: AlertService,
    private wishListCountService: PropsService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private _googleApi: GoogleApisService,
    private searchService: SearchService,
    private el: ElementRef,
    private renderer: Renderer2,
    private dataService: DataService,
    private routeMakingService:RouteMakingService,
    private gtmService: GoogleTagManagerService,
    private activatedRoute: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.initializeHeader();
  }


  ngAfterViewInit() {
    const toggleButton = document.querySelector('.btn-toggle');
    const navbar = document.getElementById('navbarSupportedContent');

    if (toggleButton && navbar) {
      toggleButton.addEventListener('click', (event) => {
        event.stopPropagation(); // Prevents the body click event from firing
        if (navbar.classList.contains('show')) {
          this.renderer.removeClass(navbar, 'show');
        } else {
          this.renderer.addClass(navbar, 'show');
        }
      });

      this.clickListener = this.renderer.listen('body', 'click', (event) => {
        const isClickInsideNavbar = navbar.contains(event.target as Node);
        const isClickOnToggleButton = toggleButton.contains(event.target as Node);

        if (!isClickInsideNavbar && !isClickOnToggleButton && navbar.classList.contains('show')) {
          this.renderer.removeClass(navbar, 'show');
        }
      });
    }
  }

  ngOnDestroy() {
    if (this.clickListener) {
      this.clickListener();
    }
  }

  reloadCount: number = 0;
  ngOnInit(): void {
    this.isStaticRoute = this.allStaticRoutes.some(route => window.location.pathname.includes(route));
    let url = window.location.pathname;
    this.providerSearch = url.split('/')[2]?.replace(/-/g, ' ');
    this.setupSubscriptions();
    this.redirectToSearchAfterLogin();
    this.handleRouterEvents();
    this.checkUserAuthentication();
    this.setApiUrl();
    this.getCountries();
    this.setupGoogleApi();
    this.getAllServices();
    if (window.location.pathname.includes('profile')) {
      this.getUserDetails();
    }
     
    this.allProfessionSub = this.dashboardService.allProfessions$.subscribe((data:any) => {
      this.lstAllServices = data.GetDashboardDetailsResult.resultDashboardSubProfessions['resultSubProfessionCategory'];
      this.lstTempAllServices = this.lstAllServices;
      this.lstallDRpServices = this.lstAllServices;
      this.wishlistCount = data.GetDashboardDetailsResult.resultCompleteProfile.whishlist_count;
      this.notificationCount = data.GetDashboardDetailsResult.resultCompleteProfile.notification_count;
      this.getIsSearchResults();
      this.searchService.crossBrowserSearch(this.lstAllServices,1);
    });
  }

  getUserDetails() {
    let payLoad: any = {
      memberId: (+sessionStorage.getItem('memberId')),
      providerMemberId: (+sessionStorage.getItem('memberId')),
      token: this._apiService.token
    };

    this._apiService.getUserProfile(payLoad).subscribe((res: any) => {
      if (res.status == 200) {
        // this.currentUser = res.Registration;
        if (sessionStorage.getItem('user') != null) {
          sessionStorage.setItem('user', JSON.stringify(res['Registration']));
        }
      }
    });
  }


  private initializeHeader(): void {
    this.isaddservice = false;
    this.issaveaddservice = false;
    const regexForParent = /^\/[a-zA-Z-]+\/[a-zA-Z-]+$/
    this.isHeader = window.location.pathname === '/' || !this.isStaticRoute;
    this.isLogin = window.location.pathname.includes('login');
    if (this._apiService !== undefined) {
      this.subscribeToProfileChanges();
    }
  }

  private subscribeToProfileChanges(): void {
    this._apiService.profile$.subscribe(
      user => this.handleUserProfile(user),
      err => console.log(err)
    );
  }

  private handleUserProfile(user): void {
    if (user != null) {
      const userData = JSON.parse(sessionStorage.getItem('user'));
      this.processUserRole(userData);
      this.currentUser = userData;

      if (this.currentUser.Services.length === 5) {
        this.isdisableDropdown = true;
        this.selectBoxs.setDisabledState(true);
      }

      this.loggedIn = true;
      this.setUserName();
      this.setUserImage();
    } else {
      this.loggedIn = false;
    }
  }

  private processUserRole(userData): void {
    if ([Role.Admin, Role.SubAdmin, Role.MarketingDM, Role.SalesTeam, Role.Manager, Role.CallCenter,Role.SeoTeam].includes(userData.roleId)) {
      this.isAdmin = true;
    }
  }

  private setUserName(): void {
    if (this.currentUser.name !== undefined && this.currentUser.name !== "") {
      this.currentUser.name = this.currentUser.fname;
    } else {
      this.currentUser.name = this.currentUser.fname + " " + this.currentUser.lname;
    }
  }

  private setUserImage(): void {
    if (this.isUserWithImage()) {
      this.image = this.currentUser.photoUrl;
    } else {
      this.setProfileImageText();
    }
  }

  private isUserWithImage(): boolean {
    return this.currentUser.provider === "GOOGLE" || this.currentUser.provider === "FACEBOOK";
  }

  private setProfileImageText(): void {
    if (this.currentUser.profileImageUrl !== "") {
      this.image = this.currentUser.profileImageUrl.split("/").splice(-1)[0] !== 'null'
        ? this.currentUser.profileImageUrl
        : null;
    } else {
      this.imagetext = this.currentUser.fname.charAt(0).toUpperCase() + this.currentUser.lname.charAt(0).toUpperCase();
    }
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    if (!this.el.nativeElement.contains(event.target)) {
      this.clickOutside.emit();
    }
  }

  private setupSubscriptions(): void {
    this.userChangedLocationSub = this.searchService.getuserChangedLocationSubject().subscribe(res => {
      this.userLatLng = res;
    });

    this.currentLocationSubject = this.searchService.getCurrentLocationSubject().subscribe(res => {
      this.currentLatLng = res;
    });

    this.wishlistCountSub = this.wishListCountService.GetWishList().subscribe((res) => {
      this.wishlistCount = res;
    });

    this.navigateToSearchAfterLoginSub = this.headerService.getNavigateToSearch().subscribe((res) => {
      this.navigateToSearchAfterLogin = res;
    })
  }

  private redirectToSearchAfterLogin(): void {
    if (this.navigateToSearchAfterLogin) {
      const data = sessionStorage.getItem('searchData');
      if (data != '' && data != null) {
        this.providerSearch = JSON.parse(data).filterName;
        this.localAddress = JSON.parse(data).localAddress;
        if(this.userLatLng.latitude !== 0 && this.userLatLng.longitude !== 0) {
          this.router.navigateByUrl(`/${sessionStorage.getItem('parentCategory').replace(/ /g, '-').toLowerCase()}/${this.providerSearch.replace(/ /g, '-').toLowerCase()}/${sessionStorage.getItem('locality').replace(/ /g, '-')}`).then(() => {
            sessionStorage.setItem('noResultFoundNavigateToSearchAfterLogin', 'false');
          });
        } else {
          this.router.navigateByUrl(`/${sessionStorage.getItem('parentCategory').replace(/ /g, '-').toLowerCase()}/${this.providerSearch.replace(/ /g, '-').toLowerCase()}/${sessionStorage.getItem('locality').replace(/ /g, '-')}`).then(() => {
            sessionStorage.setItem('noResultFoundNavigateToSearchAfterLogin', 'false');
          });
        }
        
      }
    }
  }

  private handleRouterEvents(): void {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.originUrl = this.document.location.pathname;
      const regexForParent = /^\/[a-zA-Z-]+\/[a-zA-Z-]+$/;
      if (this.originUrl === '/' || !this.isStaticRoute) {
        this.handleSearchPage();
      } else {
        this.handleNonSearchPage();
      }
    });
  }

  private handleSearchPage(): void {
    if (!this.isStaticRoute) {
      let data: any = sessionStorage.getItem('searchData') ?  JSON.parse(sessionStorage.getItem('searchData')) : null;
      if (data != '' && data != null) {
        this.providerSearch = data.filterName;
      }
      this.isSearchResultsPage = true;
    } else {
      this.providerSearch = "";
      this.isSearchResultsPage = false;
      this.providerFilterDistance =  Constants.DefaultRangeDistance;
    }
    this.isHeader = true;
    this.loginPage = false;
  }

  private handleNonSearchPage(): void {
    if (this.originUrl.includes('login')) {
      this.loginPage = true;
    } else {
      this.loginPage = false;
    }
    this.isHeader = false;
  }

  private checkUserAuthentication(): void {
    const user = sessionStorage.getItem('user');
    if (user != null) {
      this.handleAuthenticatedUser(user);
    } else {
      this.handleUnauthenticatedUser();
    }
  }

  private handleAuthenticatedUser(user: string): void {
    this.currentUser = user;
    this.loggedIn = true;
    this.currentUser = JSON.parse(this.currentUser);
    this.setUserNameAndImage();
  }

  private handleUnauthenticatedUser(): void {
    this.loggedIn = false;
  }

  private setUserNameAndImage(): void {
    if (this.currentUser.name !== undefined) {
      this.currentUser.name = this.currentUser.fname;
    } else {
      this.currentUser.name = this.currentUser.fname + " " + this.currentUser.lname;
    }

    if (this.currentUser.provider == "GOOGLE" || this.currentUser.provider == "FACEBOOK") {
      this.image = this.currentUser.photoUrl;
    } else {
      this.handleImageText();
    }
  }

  private handleImageText(): void {
    if (this.currentUser.profileImageUrl != '') {
      if (this.currentUser.profileImageUrl.split("/").splice(-1)[0] !== 'null') {
        this.image = this.currentUser.profileImageUrl;
      } else {
        this.imagetext = this.currentUser.fname.charAt(0).toUpperCase();
      }
    } else {
      this.imagetext = this.currentUser.fname.charAt(0).toUpperCase();
    }
  }

  private setApiUrl(): void {
    let path = window.location.href;
    if (path.includes('localhost') || path.includes('toskie-dev-web')) {
    } else if (path.includes('toskie.com')) {
      this.API_URL = 'https://toskie.com/Blogs';
    }
  }

  private setupGoogleApi(): void {
    // this.spinner.show();
    this._googleApi.originLatLng$.subscribe(data => {
      setTimeout(() => {
        if (data != null && data != undefined && data != '') {
          if (!this.origin) {
            this.origin = {};
          }
          this.origin['lat'] = data.lat;
          this.origin['lng'] = data.lng;
          this.latlngs = data.lat + ',' + data.lng;
          this.addressobj['latlng'] = data.lat + ',' + data.lng;
          this._googleApi.getCurrentGeoAddress(this.addressobj).subscribe(res => {
            this.handleGeoAddressResults(res);
          },
            error => { console.log(error) })
        }
      }, 1000);
    });
  }

  private handleGeoAddressResults(res: any): void {
    for (let i = 0; i < res.results.length; i++) {
      for (let m = 0; m < res.results[i].types.length; m++) {
        const ele = res.results[i].types[m];
        if (ele == "locality") {
          if (!sessionStorage.getItem('locality')) {
            sessionStorage.setItem('locality', res.results[i].address_components[0].long_name);
          }
          this.spinner.hide();
        } else if (ele == "country") {
          sessionStorage.setItem('countryCode', res.results[i].address_components[0].short_name);
          if(res.results[i].address_components[0].short_name == 'IN'){
            sessionStorage.setItem('countryId', '2');
          } else if(res.results[i].address_components[0].short_name == 'US'){
            sessionStorage.setItem('countryId', '3');
          } else if(res.results[i].address_components[0].short_name == 'CA'){
            sessionStorage.setItem('countryId', '1');
          }
        }
      }
    }
  }

  private getCountries(): void {
    this._apiService.GetCountries().subscribe(res => {
      this.lstCountry = res;
    });
  }

  updateFlag(flag: boolean) {
    this.dataService.changeFlag(flag);
  }

  changeSearch() {
    this.isDropdown = false;
  }

  getAllServices() {
    const user = sessionStorage.getItem('user');
    let payload = {}
    if (user != null) {
      payload = {
        "memberId": (+sessionStorage.getItem('memberId')),
        "token": sessionStorage.getItem('token')
      }
    } else {
      payload = {
        "memberId": 0,
        "token": " "
      }
    }
    // this._apiService.GetDashboardDetails(payload).subscribe((res: any) => {
    //   this.lstAllServices = res.GetDashboardDetailsResult.resultDashboardSubProfessions['resultSubProfessionCategory'];
    //   this.lstTempAllServices = this.lstAllServices;
    //   this.lstallDRpServices = this.lstAllServices;
    //   this.wishlistCount = res.GetDashboardDetailsResult.resultCompleteProfile.whishlist_count;
    //   this.getIsSearchResults();
    //   this.searchService.crossBrowserSearch(this.lstAllServices,1);
    // });
  }

  getIsSearchResults() {
    let data: any = sessionStorage.getItem('searchData');
    window.scrollTo(0, 0);
    if (data != '' && data != null) {
      data = JSON.parse(sessionStorage.getItem('searchData'));
      this.providerSearch = data.filterName;
      this.providerFilterDistance = Constants.DefaultRangeDistance;
      this.localAddress = data.localAddress;
      this.userLocation = data.localAddress;
      // this.providerfilter();
    }
  }

  professionChange(event) {
    this.isDropdown = true;
    var res = this.lstTempAllServices.filter((x: any) => x.subProfessionCategoryName.toLowerCase().includes(this.providerSearch.toLowerCase()));
    this.lstallDRpServices = res;
    // this.providerFilterDistance = 25;
    // let searchData = {
    //   "filterName": this.providerSearch,
    //   "toCorrdinates": sessionStorage.getItem('latlng'),
    //   "localAddress": sessionStorage.getItem('location'),
    //   "filterDistance": 5
    // }
    // sessionStorage.setItem('searchData',JSON.stringify(searchData));
  }

  selectedFilter(data) {
    this.providerSearch = data.subProfessionCategoryName;
    this.dataService.setSearchData(this.providerSearch);
    this.getChildCategoryData(data.subProfessionsCategoryId);
    this.isDropdown = false;
    this.flag = true;
  }

  getChildCategoryData(id) {
    this._apiService.GetChildProfessionData(id).subscribe((res: any) => {
      const professionData = res.GetChildProfessionResult.resultServiceProfessions[0];
      sessionStorage.setItem('parentCategory', professionData?.mainProfessionName);
      this.providerfilter();
    });
  }

  logoutUser() {
    try {
      sessionStorage.clear();
      let data = {
        token: this._apiService.token
      }
      this._apiService.sendUpdate(data);
      document.getElementById('id011').click();
      this.router.navigate(["/"]).then(() => {
        window.location.reload();
      });
      this._alert.success("You have successfully logged out!");
      this.home();
    } catch (error) {
      console.log(error);
    }
  }

  home() {
    this.gtmService.pushEvent('homeClicked', {
      'homeClicked': 'Home Clicked',
    });
    this.page = '';
    if (sessionStorage.getItem('location')) {
      setTimeout(() => {
        this.localAddress = [`${sessionStorage.getItem('location')}`];
      }, 500);
    }
    // sessionStorage.setItem('searchData', '');
    this.router.navigate(["/"]).then(() => {
      window.location.reload();
    })
    sessionStorage.setItem('searchData', '');
    window.scrollTo(0, 0);
  }

  callPageRoutes(page: any) {
    if (page == 'adminPanel') {
      this.router.navigate([this.routeMakingService.getNavigateUrl([Constants.adminPanelRoute])]).then(()=>{
        window.location.reload();
      });
    } else if (page == 'addService') {
      this.router.navigate([this.routeMakingService.getNavigateUrl([Constants.addService])]).then(()=>{
        window.location.reload();
      });
    }
  }

  refreshTab(val: string) {
    if (val == 'hire') {
      this.locationdisplay = true;
    } else {
      this.locationdisplay = false;
    }
  }

  clearSearch(){
    this.providerSearch = '';
  }

  // getChildCategory(data: any) {
  //   this.selectedProfessionName = data.professionName;
  //   let payload = {
  //     "professiontype": "childProfession",
  //     "professionsId": data.professionsId
  //   }
  //   // this.spinner.show();
  //   this._apiService.fetchAllProfessions(payload).subscribe(res => {
  //     // this.spinner.hide();
  //     this.lstChildProfessions = res['resultServiceProfessions'];
  //   })
  // }

  getLocationAddressCoordinates(address: any) {
    this.origin = {};
    if (typeof (address) != 'string') {
      if (address.formatted_address != undefined) {
        this.localAddress = address.formatted_address;
        this.origin['lat'] = address.geometry.location.lat();
        this.origin['lng'] = address.geometry.location.lng()
        sessionStorage.setItem('latlng', this.origin['lat'] + ',' + this.origin['lng']);
        this.searchService.userChangedUpdateLocation(this.origin['lat'], this.origin['lng']);
        this._googleApi.originLatLng(this.origin);
        if (this.locationdisplay) {
          this.providerfilter();
        } else {
          this.localAddress = address[0];
        }
      }
    } else {
      this.localAddress = [address];
    }
  }

  providerfilter() {
    if(this.providerSearch == ''){
      this.showDropdownWarning();
      return;
    }
    if(this.providerSearch === undefined) return;
    this.initializeSearchData();
  
    const result = this.findServiceByName(this.providerSearch);
  
    if (result) {
      this.setupSearchData(result);
    } else {
      this._alert.warn("Please select a service from dropdown");
    }
  }
  
  private initializeSearchData() {
    this.searchData = {
      filterName: this.providerSearch,
      filterDistance: this.providerFilterDistance,
      localAddress: this.localAddress,
    };
  
    this.isDropdown = false;
    sessionStorage.setItem('pasteURL', 'false');
  }
  
  private findServiceByName(serviceName: string): any {
    return this.lstAllServices.find(
      (x: any) => x.subProfessionCategoryName.toLowerCase() === serviceName?.toLowerCase()
    );
  }
  
  private setupSearchData(result: any) {
    this.searchData.categoryid = result.subProfessionsCategoryId;
    this.isSearchResultsPage = true;
    if(this.userLatLng.latitude !== 0 && this.userLatLng.longitude !== 0) {
      this.searchData.toCorrdinates = `${this.userLatLng.latitude},${this.userLatLng.longitude}`;
    } else if (this.currentLatLng.latitude !== 0 && this.currentLatLng.longitude !== 0){
      this.searchData.toCorrdinates = `${this.currentLatLng.latitude},${this.currentLatLng.longitude}`;
    }
    setTimeout(() => {
      sessionStorage.setItem('searchData', JSON.stringify(this.searchData));
      this.providerSearch = result.subProfessionCategoryName;
      this.navigateToSearch();
    },1500)
  }
  
  
  private navigateToSearch() {
    this.router.navigateByUrl(`/${sessionStorage.getItem('parentCategory').replace(/ /g, '-').toLowerCase()}/${this.providerSearch.replace(/ /g, '-').toLowerCase()}/${sessionStorage.getItem('locality').replace(/ /g, '-')}`).then(() => {
      this.searchService.fetchHireAndExpert(this.searchData, 1).subscribe((res: any) => {
        this.spinner.hide();
      });
    });
    // if(this.flag){
    //   this.flag = false;
    //   this.router.navigateByUrl(`/${sessionStorage.getItem('parentCategory').replace(/ /g, '-').toLowerCase()}/${this.providerSearch.replace(/ /g, '-').toLowerCase()}/${sessionStorage.getItem('locality').replace(/ /g, '-')}`).then(() => {
    //     window.location.reload();
    //   });
    // } else {
    
    // }
  }
  
  private showDropdownWarning() {
    this.isDropdown = true;
    const message: any = 'Please enter your service';
    this._alert.warn(message);
    this.spinner.hide();
  }
  
  private async getCurrentGeoLatLng(): Promise<{ lat: number; lng: number }> {
    return new Promise((resolve) => {
      navigator.geolocation.getCurrentPosition((position) => {
        const origin = { lat: position.coords.latitude, lng: position.coords.longitude };
        this.headerService.setCurrentLocation(origin.lat, origin.lng);
        this.headerService.setNavigateToSearch(true);
        resolve(origin);
      });
    });
  }

  removeSpecialCharacters(inputString: string): string {
    const regex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/g;
    const sanitizedString = inputString.replace(regex, '');
    return sanitizedString;
  }

  viewProfile(u) {
    this.updateFlag(true);
    let id = u.memberId;
    let username = u.fname.toLowerCase() + u.lname.toLowerCase()
    username = username.replace(/\s+/g, ' ');
    username = username.replace(/ /g, '-');
    this.router.navigate(['/profile', `${username}-${id}`]).then(() => {
      window.location.reload();
    });
  }

  deleteAccount() {

    let payLoad = {
      'memberId': sessionStorage.getItem('memberId'),
      'token': this._apiService.token,
      'deviceType': "Website"
    }
    this._apiService.deleteAccount(payLoad).subscribe(
      res => {
        sessionStorage.clear();
        document.getElementById('id01').click();
        this._alert.success('Account delete successfully');
        // this.home();
        this.router.navigate(["/"]).then(() => {
          window.location.reload();
        })
        window.scrollTo(0, 0);

      });
  }

  navigate(value) {
    if (value === 'login') {
      if(window.location.pathname.includes('profile')) {
        sessionStorage.setItem('redirectProviderProfile', 'true');
      }
      this.isHeader = false;
      this.loginPage = true;
    } else {
      this.loginPage = false;
    }
    this.router.navigateByUrl(`${value}`).then(() => {
      window.location.reload();
    });
  }

  navigateToUserNotification(){
    this.router.navigate(['/user-notification']).then(() => {
      window.location.reload();
    });
  }

  myImage() {
    this.image = null;
    this.imagetext = this.currentUser.fname.charAt(0).toUpperCase() + this.currentUser.lname.charAt(0).toUpperCase();
  }

  addservice() {
    this.isaddservice = false;
    this.submitted = false;
    this.isneedService = false;
  }

  getServices() {
    this.selectBoxs.clearModel();
    this.isadd = false;
    this._apiService.GetAllSubProfessionCategories().subscribe((res: any) => {
      this.lstServices = res.GetAllSubProfessionCategoryResult['resultSubProfessionCategory'];
      this.lstTempServices = res.GetAllSubProfessionCategoryResult['resultSubProfessionCategory'];
    });

  }

  getWishlist() {
    if (this.wishlistCount > 0) {
      this.router.navigate(["/wishlist"]).then(() => {
        window.location.reload();
      });
    }
    else {
      let message = 'No Wishlist Found !.'
      this._alert.warn(message);
    }
  }

  getServiceLetter(service) {
    let text = service.replace(/\s+/g, '');
    return text.charAt(0).toUpperCase();
  }

  cancelAddService() {
    this.addserviceForm.reset();
  }

  addServiceField() {
    var result = this.currentUser.Services.filter((x: any) => x.userService == this.servicename)
    if (result.length > 0) {
      let message = 'Please add your service.'
      this._alert.warn(message);
    } else {
      let payLoad: any = {
        memberId: (+sessionStorage.getItem('memberId')),
        serviceName: this.servicename,
        subProfessionsCategoryId: this.serviceid,
        deviceType: "Website",
        token: this._apiService.token
      };

      this._apiService.saveService(payLoad).subscribe(result => {

        if (result['resultModel'].status == 200) {
          this.selectBoxs.clearModel();
          this.isadd = false;
          let payLoad1: any = {
            memberId: (+sessionStorage.getItem('memberId')),
            providerMemberId: (+sessionStorage.getItem('memberId')),
            token: this._apiService.token
          };

          this._apiService.getUserProfile(payLoad1).subscribe((res: any) => {

            if (res.status == 200) {
              this.servicename = "";
              this.serviceid = 0;

              this.currentUser = res.Registration;
              let message = 'Your Service / Profession has been saved'
              this._alert.success(message);
              if (this.currentUser.Services.length == 5) {
                this.isdisableDropdown = true;
                this.selectBoxs.setDisabledState(true);
              } else {
                this.isdisableDropdown = false;
                this.selectBoxs.setDisabledState(false);
              }
              sessionStorage.setItem('user', JSON.stringify(res['Registration']));
            }
          });
        }
      });
    }

  }

  changeService(event) {
    if (event.target.value != '' && event.target.value != undefined && event.target.value != null) {
      var result = this.lstTempServices.filter((x: any) => x.subProfessionCategoryName.toLowerCase().includes(event.target.value.toLowerCase()));
      this.isDrpdown = true;
      this.lstServices = result;
    } else {
      this.isDrpdown = false;
      this.isadd = false;
      this.lstServices = this.lstTempServices;
    }
  }
  changeServicedrp(event: any) {
    if (event == undefined) {
      this.isadd = false;
    } else {
      this.serviceid = event.subProfessionsCategoryId;
      this.servicename = event.subProfessionCategoryName;
      this.isDrpdown = false;
      this.isadd = true;
    }

  }

  OnlyLettersAllowed(event): boolean {
    const charCode = (event.which) ? event.which : event.keycode;

    if ((charCode < 97 || charCode > 123) && (charCode < 65 || charCode > 90) && charCode != 32) {
      return false;
    }
    return true
  }
  removeService(i) {
    if (this.currentUser.Services.length > 1) {
      this.isRemovedService = false;
      let payLoad: any = {
        memberId: (+sessionStorage.getItem('memberId')),
        CategoryId: i,
        token: this._apiService.token
      };

      this._apiService.deleteServiceCategory(payLoad).subscribe(result => {
        this.spinner.hide();

        if (result.resultModel.status == 200) {
          let payLoad1: any = {
            memberId: (+sessionStorage.getItem('memberId')),
            providerMemberId: (+sessionStorage.getItem('memberId')),
            token: this._apiService.token
          };

          this._apiService.getUserProfile(payLoad1).subscribe((res: any) => {


            if (res.status == 200) {
              this.currentUser = res.Registration;
              this.selectBoxs.setDisabledState(false);
              this.isdisableDropdown = false;
              this.spinner.hide();
              sessionStorage.setItem('user', JSON.stringify(res['Registration']));
              this._alert.success(result.resultModel.result);
            }
          });

        }
      })
    } else {
      this.isRemovedService = true;
    }

  }

  closeModal(event: MouseEvent, id: string): void {
    const modal = document.getElementById(id);
    if (
      event.target === modal ||
      (event.target instanceof HTMLElement && event.target.classList.contains('close'))
    ) {
      modal.style.display = 'none';
      modal.classList.remove('show');
    }
  }

  goToCallLogs(){
    this.router.navigate(['/call-logs']).then(() => {
      window.location.reload();
    });
  }

  goToRefer(){
    this.router.navigate(['/refer-a-friend']).then(() => {
      window.location.reload();
    });
  }
}