import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.authenticationService.gettoken();
    const mobileNum = sessionStorage.getItem('mobileNum');
    if (currentUser || mobileNum !== undefined || mobileNum !== null) {
      if (mobileNum !== undefined || mobileNum !== null) {
        return true;
      } else {
        if (currentUser) {
          return true
        }
        this.router.navigate(['/']);
        this.openSignIn();
        return false;
      }
      
    } else {
      this.router.navigate(['/']);
      this.openSignIn();
      return false;
    }
  }

  openSignIn() {
    this.router.navigate(['/login']);
  }
}
