import { Component, OnInit, ViewChild, VERSION } from '@angular/core';
import { ServerApisService } from '../../shared/services/server-apis.service';
import { SocialAuthService, SocialUser, FacebookLoginProvider, GoogleInitOptions } from '@abacritt/angularx-social-login';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { AlertService } from '../../shared/alert/alert.service';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { getAuth, signInWithPopup, OAuthProvider } from "firebase/auth";
import { Auth, UserCredential } from '@angular/fire/auth';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Login } from '../../shared/Interfaces/login.model';
export class PhoneNumber {
  phoneNo: string;
  get e164() {
    const num = this.phoneNo;
    return `+${num}`
  }
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {
  @ViewChild('f') formValues;
  // name = "Custom Validator Angular " + VERSION.major;
  signInForm: FormGroup;
  user: SocialUser;
  formName: string = "Login";
  phno: string = "";
  loggedIn: boolean;
  mobilenumVerified: boolean = false;
  verificationResult: any;
  logindata: any;
  country = "India";
  cntrycode = "+91";
  loginres: any;
  mobileInvalidMessage: string = "";
  model: any = {};
  windowRef: any;
  otpsent: any;
  submitted: boolean = false;
  acceptTerms = false;
  countryname: any;
  phoneNumber = new PhoneNumber();
  recaptchaVerifier: firebase.auth.RecaptchaVerifier | undefined;
  newUser: boolean;
  countryId: string;
  lstCountry: any;
  validateNumber : Boolean = true;
  phoneNum:any;
  userInfo : Login = {
    accountId: 1,
    fname: '',
    lname: '',
    email: '',
    password: '',
    mobile: '',
    serviceId: 1,
    userId: '',
    imagePath: '',
    countryCode: '',
    deviceType: 'website',
    deviceId: ''
  }
  

  constructor(
    private fb: FormBuilder,
    private _apiService: ServerApisService,
    private socialAuthService: SocialAuthService,
    private spinner: NgxSpinnerService,
    private router: Router,
    protected alertService: AlertService,
    private auth: Auth,
    private deviceService: DeviceDetectorService
  ) { 

  }

  /**
   * Initializes the component.
   * If the user is already logged in, it navigates to the home page.
   * Otherwise, it sets up the sign-in form and subscribes to the authentication state.
   * It also handles exceptions and scrolls the window to the top.
   */
  ngOnInit(): void {

    var loggedIn = sessionStorage.getItem('loggedIn');
    if (loggedIn == "true") {
      this.router.navigate(['/']);
    } else {
      this.submitted = false;
      this.model.countryCode = '+91';
      this.signInForm = this.fb.group({
        mobileNum: ['', [Validators.required, Validators.minLength(10), Validators.pattern(/^-?(0|[1-9]\d*)?$/)]]
      });
      setTimeout(() => {
        this.socialAuthService.authState.subscribe((user) => {
          this.user = user;
          if (this.user){
            this.loginWithGoogle(user);
          }
        }, err => {
          console.log(err);
        });
      }, 100); 
      firebase.app();
      this._apiService.currentCountryName$.subscribe(res => {
        try {
          this.countryname = res;
        }
        catch {
          err => {
            var obj = {
              ErrorMessage: err
            }
            this._apiService.ExceptionLog(obj).subscribe(res => {
            });
          }
        }
      })
      window.scrollTo(0, 0);
    }
    this.getCountries();
    setTimeout(() => {
      if (this.country == 'India'){
        this.lstCountry.forEach(element => {
          if (element.country_name == 'India') {
            this.cntrycode = element.country_mobile_code;
            this.model.countryCode = element.country_mobile_code;
            this.userInfo.countryCode = element.country_mobile_code;
            this.model.country_id = element.country_id;
          }
        });
        sessionStorage.setItem('countryId', this.model.country_id);
      }
    }, 2000);
  }

  /**
   * Retrieves the list of countries from the API service.
   * Populates the `lstCountry` property with the result.
   */
  getCountries() {
    this._apiService.GetCountries().subscribe(res => {
      this.lstCountry = res['GetAllCountriesResult'].resultCountries;
    });
  }


  /**
   * Updates the selected country and sets the country code and country ID accordingly.
   * @param {string} countryName - The name of the selected country.
   */
  countrychange(countryName) {
    console.log(this.phoneNum)
    this.country = countryName;
    this.lstCountry.forEach(element => {
      if (element.country_name == countryName) {
        this.cntrycode = element.country_mobile_code;
        this.model.countryCode = element.country_mobile_code;
        this.model.country_id = element.country_id;
      }
    });
    sessionStorage.setItem('countryId', this.model.country_id);
    if(this.phoneNum != undefined && this.phoneNum != null && this.phoneNum != '' && this.phoneNum.length == 10){
      this.changeNum(this.phoneNum);
    }
  }

  /**
   * Gets the mobile number control from the signInForm.
   * @returns The mobile number control.
   */
  get mobileNumber() {
    return this.signInForm.controls['mobileNum'];
  };

  /**
   * Gets the password form control.
   * 
   * @returns The password form control.
   */
  get passWord() {
    return this.signInForm.controls['password'];
  };

  /**
   * Handles the form submission event.
   * Sets the submitted flag to true.
   * Sets the default country code if not provided.
   * Constructs the phone number using the country code and mobile number.
   * Shows the spinner.
   * Calls the verifyPhoneNumber method with the constructed phone number.
   */
  onSubmit() {
    this.submitted = true;
  
    // Set default country code if not provided
    if (this.model.countryCode == null) {
      this.model.countryCode = '+91';
    }

    // Construct phone number
    const phoneNumber = this.model.countryCode + this.model.mobile;
    this.phno = phoneNumber;
    if(this.validateNumber){
      this.spinner.show();
      this.verifyPhoneNumber(phoneNumber);
    } 
  }

  clearFields(){
    this.userInfo = {
      accountId: 1,
      fname: '',
      lname: '',
      email: '',
      password: '',
      mobile: '',
      serviceId: 1,
      userId: '',
      imagePath: '',
      countryCode: '',
      deviceType: 'website',
      deviceId: ''
    }
  }

  /**
   * Verifies the phone number by sending an OTP to the provided phone number.
   * If the reCAPTCHA verifier is not initialized, it initializes it first.
   * @param phoneNumber The phone number to verify.
   */
  verifyPhoneNumber(phoneNumber: string) {
    if (!this.recaptchaVerifier) {
      this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('loginrecaptcha', { size: 'invisible' });
    }
    firebase.auth().signInWithPhoneNumber(phoneNumber, this.recaptchaVerifier)
      .then(result => {
        // this.spinner.hide();
          this.otpsent = true;
          this.alertService.success('OTP has been sent to your mobile number. Please check.');
          this.verificationResult = result;
        
      })
      .catch(error => this.otpFailed(error));

    const phRegExp = /[0-9]{10}/;
    this.model.accountId = 1;
    this.model.serviceId = 1;
    this.model.userId = '';
    this.model.fname = '';
    this.model.lname = '';
    this.model.imagePath = '';

    if (phRegExp.test(this.model.phone)) {
      this.model.email = '';
      this.model.mobile = this.model.phone;
    } else {
      this.model.mobile = '';
      this.model.email = this.model.phone;
    }
  }

  /**
   * Handles the event when OTP verification fails.
   * @param event - The event object.
   */
  otpFailed(event: any) {
    this.spinner.hide();
    this.alertService.error(event.message);
  }

  /**
   * Performs the validation and login process for the user.
   * If the user is a new user, it sets the mobile number and country code in the session storage and navigates to the registration page.
   * If the user is an existing user, it sends a login request to the API service and handles the response accordingly.
   * If the login is successful, it retrieves the user profile and updates the session storage with the necessary information.
   * If the login fails, it displays an error message.
   */
  validuser() {
    this.spinner.show();
    if (this.newUser) {
      sessionStorage.setItem('mobileNum', this.model.mobile);
      sessionStorage.setItem('countryCodeNumber', this.model.countryCode);
      this.router.navigateByUrl('register');
      this.spinner.hide();
    } else {
      this.userInfo.countryCode = this.model.countryCode;
      this._apiService.loginAccount(this.userInfo)
        .subscribe(data => {
          if (data["resultModel"].status == 200) {
            if (data["resultModel"].memberId !== undefined) {
              let payLoad: any = {
                memberId: data["resultModel"].memberId,
                providerMemberId: data["resultModel"].memberId
              };
              this._apiService.token = data["resultModel"].token;
              sessionStorage.setItem('token', data["resultModel"].token);
              payLoad.token = data["resultModel"].token;
              this._apiService.getUserProfile(payLoad).subscribe(
                res => {
                  let profile = res['Registration'];
                  sessionStorage.setItem('loggedIn', 'true');
                  sessionStorage.setItem('token', data["resultModel"].token);
                  sessionStorage.setItem('user', JSON.stringify(profile));
                  this._apiService.sendUpdate(profile);
                  // this.spinner.hide();
                  let tempMemeberId = data["resultModel"].memberId;
                  this.alertService.success('LogIn Successfully !')
                  if(sessionStorage.getItem('redirectSearch') != null && sessionStorage.getItem('redirectSearch') != undefined){
                    sessionStorage.removeItem('redirectSearch');
                    let searchData = JSON.parse(sessionStorage.getItem('searchData'));
                    if(searchData.filterName){
                      let professionNameWithHyphen = searchData.filterName;
                      if (professionNameWithHyphen.includes(' ')) {
                        professionNameWithHyphen = professionNameWithHyphen.replace(/ /g, '-');
                      }
                      this.router.navigate([`${sessionStorage.getItem('parentCategory').replace(/ /g, '-').toLowerCase()}/${professionNameWithHyphen.toLowerCase()}/${sessionStorage.getItem('locality').replace(/ /g, '-')}`]).then(() => {
                        window.location.reload();
                      });
                    }
                  } else if(sessionStorage.getItem('redirectProviderProfile') != null && sessionStorage.getItem('redirectProviderProfile') != undefined){
                    sessionStorage.removeItem('redirectProviderProfile');
                    let providerProfile = JSON.parse(sessionStorage.getItem('providerProfile'));
                    let fullName = `${providerProfile.fname}${providerProfile.lname}`;
                    fullName = fullName.replace(/\s+/g, ' ');
                    fullName = fullName.replace(/\s/g, '-');
                    
                    this.router.navigate(['/profile', `${fullName.toLowerCase()}-${providerProfile.memberId}`]).then(() => {
                      window.location.reload();
                    });

                  } else if(sessionStorage.getItem('redirectContactUs') != null && sessionStorage.getItem('redirectContactUs') != undefined) {
                    sessionStorage.removeItem('redirectContactUs');
                    this.router.navigate(['/contact-us']).then(()=>{
                      window.location.reload();
                    })
                  }
                  else {
                    this.router.navigate(['/']).then(() => {
                      window.location.reload();
                   });
                  }
                  sessionStorage.setItem('memberId', tempMemeberId);
                  this.clearFields();
                });
            } else {
              this.spinner.hide();
              this.alertService.error(data["resultModel"].result);
              this.formValues.resetForm();
            }
          }
          else {
            this.spinner.hide();
            this.alertService.error('Login Failed !')
            this.formValues.resetForm();
          }
        }, err => {
          console.log(err)
          this.spinner.hide
        });
    }

  }

  /**
   * Logs in the user using Google authentication.
   * 
   * @returns void
   */
  googleLoginOptions: GoogleInitOptions = {
    oneTapEnabled: false, 
    scopes: 'https://www.googleapis.com/auth/calendar.readonly'
  };

  loginWithGoogle(user:any) {
    try {
        let profile: any = user;
        // const googleData: any = {};
        // googleData.accountId = 1;
        // googleData.fname = profile.firstName;
        // googleData.lname = profile.lastName;
        // googleData.email = profile?.email;
        // googleData.password = "";
        // googleData.mobile = profile?.mobile;
        // googleData.serviceId = 3;
        // googleData.userId = profile.id;
        // googleData.imagePath = profile.photoUrl;
        // googleData.token = this._apiService.token;

        this.userInfo.fname = profile.firstName;
        this.userInfo.lname = profile.lastName;
        this.userInfo.email = profile?.email;
        this.userInfo.mobile = profile?.mobile;
        this.userInfo.userId = profile.id;
        this.userInfo.imagePath = profile.photoUrl;
        this.userInfo.serviceId = 3;

        this.spinner.show();
        this._apiService.loginAccount(this.userInfo).subscribe(res => {
          try {

            // this.spinner.hide();
            if (res.resultModel.status == '200') {
              sessionStorage.setItem('loggedIn', 'true');

              let payLoad: any = {
                memberId: res.resultModel.memberId,
                providerMemberId: res.resultModel.memberId
              };
              this._apiService.token = res.resultModel.token;
              sessionStorage.setItem('token', res.resultModel.token);
              payLoad.token = res.resultModel.token;
              this._apiService.getUserProfile(payLoad).subscribe(
                res => {
                  try {

                    sessionStorage.setItem('loggedIn', 'true');
                    sessionStorage.setItem('memberId', res['Registration'].memberId);
                    sessionStorage.setItem('user', JSON.stringify(res['Registration']));
                    res['Registration'].token = this._apiService.token;

                    this._apiService.sendUpdate(res['Registration']);
                    // this.spinner.hide();
                    this.alertService.success('LogIn Successfully !');
                    if(sessionStorage.getItem('redirectSearch') != null && sessionStorage.getItem('redirectSearch') != undefined){
                      sessionStorage.removeItem('redirectSearch');
                      let searchData = JSON.parse(sessionStorage.getItem('searchData'));
                      let professionNameWithHyphen = searchData.filterName;
                      if (professionNameWithHyphen.includes(' ')) {
                        professionNameWithHyphen = professionNameWithHyphen.replace(/ /g, '-');
                      }
                      this.router.navigate([`${sessionStorage.getItem('parentCategory').replace(/ /g, '-').toLowerCase()}/${professionNameWithHyphen.toLowerCase()}/${sessionStorage.getItem('locality').replace(/ /g, '-')}`]).then(() => {
                        window.location.reload();
                      });
                    } else if(sessionStorage.getItem('redirectProviderProfile') != null && sessionStorage.getItem('redirectProviderProfile') != undefined){
                      sessionStorage.removeItem('redirectProviderProfile');
                      let providerProfile = JSON.parse(sessionStorage.getItem('providerProfile'));
                      // providerProfile.fname = providerProfile.fname.replace(/\s+/g, ' ');
                      // providerProfile.fname = providerProfile.fname.replace(/\s/g, '-');
                      // providerProfile.lname = providerProfile.lname.replace(/\s+/g, ' ')
                      // providerProfile.lname = providerProfile.lname.replace(/\s/g, '-');    
                      let fullName = `${providerProfile.fname}${providerProfile.lname}`;
                      fullName = fullName.replace(/\s+/g, ' ');
                      fullName = fullName.replace(/\s/g, '-');

                      this.router.navigate(['/profile', `${fullName.toLowerCase()}-${providerProfile.memberId}`]).then(() => {
                        window.location.reload();
                      });
  
                    } else {
                      this.router.navigate(['/']).then(() => {
                        window.location.reload();
                     });
                    }
                    this.clearFields();
                  }
                  catch {
                    err => {
                      var obj = {
                        ErrorMessage: err
                      }
                      this._apiService.ExceptionLog(obj).subscribe(res => {

                      });

                    }
                  }
                });
            } else {
              this.alertService.error(res.resultModel.result);
            }
          }
          catch {
            err => {
              var obj = {
                ErrorMessage: err
              }
              this._apiService.ExceptionLog(obj).subscribe(res => {
              });
              this.clearFields();
            }
          }
        })
    } catch (error) {
      console.log(error);
      this.spinner.hide();
    }
  }

  /**
   * Sign in with Facebook.
   */
  signInWithFB(): void {
    try {
      this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID).then((data) => {
        let profile: any = data;
        //const fbData: any = this.prepareFbData(profile);
        this.userInfo.fname = profile.firstName;
        this.userInfo.lname = profile.lastName;
        this.userInfo.email = profile?.email;
        this.userInfo.mobile = profile?.mobile;
        this.userInfo.userId = profile.id;
        this.userInfo.imagePath = profile.photoUrl;
        this.userInfo.serviceId = 2;
        
        this.spinner.show();
        this._apiService.loginAccount(this.userInfo).subscribe(res => {
          this.handleLoginResponse(res);
        })
        this.clearFields();
      }).catch(data => {
        this.handleLoginError();
        this.clearFields();
      });
    } catch (error) {
      console.log(error);
      this.spinner.hide();
    }
  }

  prepareFbData(profile: any): any {
    const fbData: any = {};
    fbData.accountId = 1;
    fbData.fname = profile.firstName;
    fbData.lname = profile.lastName;
    fbData.email = profile?.email;
    fbData.password = "";
    fbData.mobile = profile?.mobile;
    fbData.serviceId = 2;
    fbData.userId = profile.id;
    fbData.imagePath = profile.photoUrl;
    fbData.token = this._apiService.token;
    return fbData;
  }

  handleLoginResponse(res: any): void {
    const payLoad: any = {};
    payLoad.memberId = res.resultModel.memberId;
    if (res.resultModel.status == '200') {
      this.handleSuccessfulLogin(res, payLoad);
    } else {
      this.alertService.error(res.resultModel.result);
    }
  }

  handleSuccessfulLogin(res: any, payLoad: any): void {
    sessionStorage.setItem('loggedIn', 'true');
    payLoad = {
      memberId: res.resultModel.memberId,
      providerMemberId: res.resultModel.memberId
    };
    this._apiService.token = res.resultModel.token;
    sessionStorage.setItem('token', res.resultModel.token);
    payLoad.token = res.resultModel.token;
    this._apiService.getUserProfile(payLoad).subscribe(
      res => {
        this.handleUserProfileResponse(res);
      });
  }

  handleUserProfileResponse(res: any): void {
    try {
      sessionStorage.setItem('loggedIn', 'true');
      sessionStorage.setItem('memberId', res['Registration'].memberId);
      sessionStorage.setItem('user', JSON.stringify(res['Registration']));
      res['Registration'].token = this._apiService.token;
      this._apiService.sendUpdate(res['Registration']);
      this.alertService.success('LogIn Successfully !');
      this.handleRedirects();
    }
    catch {
      err => {
        var obj = {
          ErrorMessage: err
        }
        this._apiService.ExceptionLog(obj).subscribe(res => {

        });
      }
    }
  }

  handleRedirects(): void {
    if(sessionStorage.getItem('redirectSearch') != null && sessionStorage.getItem('redirectSearch') != undefined){
      this.redirectToSearch();
    } else if(sessionStorage.getItem('redirectProviderProfile') != null && sessionStorage.getItem('redirectProviderProfile') != undefined){
      this.redirectToProfile();
    } else {
      this.redirectToHome();
    }
  }

  redirectToSearch(): void {
    sessionStorage.removeItem('redirectSearch');
    let searchData = JSON.parse(sessionStorage.getItem('searchData'));
    let professionNameWithHyphen = searchData.filterName;
    if (professionNameWithHyphen.includes(' ')) {
      professionNameWithHyphen = professionNameWithHyphen.replace(/ /g, '-');
    }
    this.router.navigate([`${sessionStorage.getItem('parentCategory').replace(/ /g, '-').toLowerCase()}/${professionNameWithHyphen.toLowerCase()}/${sessionStorage.getItem('locality').replace(/ /g, '-')}`]).then(() => {
      window.location.reload();
    });
  }

  redirectToProfile(): void {
    sessionStorage.removeItem('redirectProviderProfile');
    let providerProfile = JSON.parse(sessionStorage.getItem('providerProfile'));
    // providerProfile.fname = providerProfile.fname.replace(/\s+/g, ' ');
    // providerProfile.fname = providerProfile.fname.replace(/\s/g, '-');
    // providerProfile.lname = providerProfile.lname.replace(/\s+/g, ' ')
    // providerProfile.lname = providerProfile.lname.replace(/\s/g, '-');    
    let fullName = `${providerProfile.fname}${providerProfile.lname}`;
    fullName = fullName.replace(/\s+/g, ' ');
    fullName = fullName.replace(/\s/g, '-');

    this.router.navigate(['/profile', `${fullName.toLowerCase()}-${providerProfile.memberId}`]).then(() => {
      window.location.reload();
    });
  }

  redirectToHome(): void {
    this.router.navigate(['/']).then(() => {
      window.location.reload();
    });
  }

  handleLoginError(): void {
    this.signOut();
    this.spinner.hide();
    this.alertService.error('Login Failed!')
  }

  signInWithApple() {
    const auth = getAuth();
    const provider = new OAuthProvider('apple.com');
    provider.addScope('email');
    provider.addScope('name');
    provider.addScope('public_profile');
    signInWithPopup(auth, provider)
      .then((result: UserCredential) => {
        const user = result.user;
        const credential = OAuthProvider.credentialFromResult(result);
        const accessToken = credential.accessToken;
        const idToken = credential.idToken;
        //console.log('Signed in with Apple:', user);
        // code will added if it is successfully tested in dev url
      })
      .catch((error) => {
        console.error('Error signing in with Apple:', error);
      });
  }

  /**
   * Signs out the user.
   */
  signOut(): void {
    this.socialAuthService.signOut().then((data) => {
      this.alertService.success('You have successfully logged out !')
    }).catch((data) => {
      console.log(data);
    });
  }

  /**
   * Opens the register page.
   */
  openRegister() {
    this.submitted = false;
    this.router.navigateByUrl('register');
  }

  /**
   * Opens the forgot password page.
   */
  // openForgotPassword() {
  //   this.router.navigateByUrl('forgot-password')
  // }

  /**
   * Checks if only numbers are allowed in the input field.
   * 
   * @param event - The event object representing the key press event.
   * @returns Returns true if only numbers are allowed, false otherwise.
   */
  OnlyNumbersAllowed(event): boolean {
    const charCode = (event.which) ? event.which : event.keycode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  /**
   * Handles the change event of the input field for phone number.
   * If the entered phone number has a length of 10, it sets the country code to '91' if it is null.
   * It validates the phone number using a regular expression and updates the model accordingly.
   * It then checks if the mobile number exists using the API service.
   * If the mobile number exists, it sets the 'mobilenumVerified' flag to false and 'newUser' flag to false.
   * If the mobile number does not exist, it sets the 'mobilenumVerified' flag to true and 'newUser' flag to true.
   * @param event - The change event object.
   */
  changeNum(event?: Event) {
    if (this.phoneNum.length !== 10) return;

    this.setCountryCodeIfNull();
    this.setPhoneNumber(this.phoneNum);
    this.checkMobileNum();
  }

  setCountryCodeIfNull() {
    if (this.model.countryCode == null) {
      this.model.countryCode = '91';
    }
  }

  setPhoneNumber(value: string) {
    const phRegExp = /[0-9]{10}/;
    this.model.phone = value;
    if (phRegExp.test(this.model.phone)) {
      this.model.mobile = this.model.phone;
      this.phoneNumber.phoneNo = this.model.mobile;

      this.userInfo.mobile = value;
    }
  }

  checkMobileNum() {
    let details = {
      email: "",
      mobile: this.phoneNumber.phoneNo,
      countryCode: this.model.countryCode
    }
    this._apiService.checkMobileNum(details).subscribe((res:any) => {
      if (res != undefined && res != null) {
        if (res.IsUserExistsResult.status == 200) {
          this.handleUserExistsResult(res.IsUserExistsResult, details);
        }
      }
    });
  }

  handleUserExistsResult(result: any, details: any) {
    if (result.message == "User Exists") {
      this.handleExistingUser(details);
    } else {
      this.handleNewUser(result);
    }
  }

  handleExistingUser(details: any) {
    this.mobilenumVerified = false;
    this.newUser = false;
    this.setCountryBasedOnCode(details);
    this.mobileInvalidMessage = "";
    this.validateNumber = true;
  }

  handleNewUser(result: any) {
    this.mobilenumVerified = true;
    this.newUser = true;
    if(result.message.includes("already registered")){ 
      this.alertService.error(result.message);
      this.mobilenumVerified = false;
      this.newUser = false;
      this.validateNumber = false;
    } else {
      this.validateNumber = true;
    }
    this.mobileInvalidMessage = result.message;
  }

  setCountryBasedOnCode(details: any) {
    if (details.countryCode == "+1" || details.countryCode == "1") {
      this.country = this.model.country_id == 3 ? 'United States' : 'Canada';
      this.model.countryCode = "+1";
    } else {
      this.country = 'India';
      this.model.countryCode = "+91";
    }
  }
}
