<div class="tasks_table1">
    <div class="row">
                    <div class="col-md-12 search-box">
                      <label style="width: 100%;"><span><input type="text" id="search" class="custom-focus-input"
                        autocomplete="off"  [placeholder]="placeholderText" style="height: 37px;border: none;width: 100%;"
                        (input)="searchChange($event)"></span></label>
               </div>
    </div>
</div>
 <table class="table table-hover tbl" >
    <thead class="tablehd-sec">
      <tr>
        <th style="width: 15%; padding-left: 2%;" (click)="memberSort()">Advertisement Id&nbsp;<span><i class="fa fa-long-arrow-up" style="margin: 0;" 
          [ngClass]="membersort == 'up' ? 'black' : 'grey'"></i>
          <i class="fa fa-long-arrow-down" style="margin: 0;" [ngClass]="membersort == 'down' ? 'black' : 'grey'" ></i></span></th>
        <th style="width: 17%;" (click)="titleonSort()">Title
          &nbsp;<span><i class="fa fa-long-arrow-up" style="margin: 0;" 
            [ngClass]="titlesort == 'up' ? 'black' : 'grey'"></i>
            <i class="fa fa-long-arrow-down" style="margin: 0;" [ngClass]="titlesort == 'down' ? 'black' : 'grey'" ></i></span></th>
        <th style="width: 18%;" (click)="descriptionOnsort()">Description
          &nbsp;<span><i class="fa fa-long-arrow-up" style="margin: 0;" 
            [ngClass]="descriptionsort == 'up' ? 'black' : 'grey'"></i>
            <i class="fa fa-long-arrow-down" style="margin: 0;" [ngClass]="descriptionsort == 'down' ? 'black' : 'grey'" ></i></span></th>
        <th style="width: 17%;" (click)="exiprydatesort()">Expiry Date&nbsp;<span><i class="fa fa-long-arrow-up" style="margin: 0;" 
          [ngClass]="datesort == 'up' ? 'black' : 'grey'"></i>
          <i class="fa fa-long-arrow-down" style="margin: 0;" [ngClass]="datesort == 'down' ? 'black' : 'grey'" ></i></span></th>
        <th style="width: 17%;">Image</th>
        <th style="width: 16%;">Action</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let d of lstadvertisements| paginate: { itemsPerPage: 10, currentPage: p};let i = index"
        class="tdata">
        <td style="width: 15%;padding-left: 2%;cursor: pointer;" (click)="editAdvertisement(d)">{{d.addsId}}</td>
        <td style="width: 17%;cursor: pointer;" (click)="editAdvertisement(d)">{{d.title}}</td>
        <td style="width: 18%; cursor: pointer;" (click)="editAdvertisement(d)">{{d.description}}</td>
        <td style="width: 17%;">{{d.expiryDate| date: 'dd/MM/yyyy'}}</td>
        <td style="width: 17%;">
          <img onclick="document.getElementById('id033').style.display='block'" class="profileimage"
            style="border: 2px solid #f2f2f2; cursor: pointer;" src="{{d.imagePath}}" (click)="profilePopup(d.imagePath)" 
            onerror="this.src='assets/images/person.jpg';" alt="profileimage" title="profileimage">
        </td>
        <td style="width: 16%;" >
        <i class="fa fa-trash-o" style="color: red;font-size: 20px;cursor: pointer;margin-left: 10px;" (click)="deleteAdvertisement(d)"
          onclick="document.getElementById('deletee').style.display='block'"></i>
      </td>
      </tr>
    </tbody>
  </table>
  <div class="row">
    <div class="col">
      <div class="d-flex justify-content-center" *ngIf="lstadvertisements.length!=0">
        <pagination-controls (pageChange)="pageChanged($event)" (pageBoundsCorrection)="pageChanged($event)">
        </pagination-controls>
      </div>
    </div>
  </div>
  <div id="deletee" class="modal" style="display: block; overflow: hidden;" *ngIf="isdelete">

    <form class="modal-content" action="/action_page.php" style="width: 35%;">
        <div class="container">
            <div class="modal-header" style="padding-top: 0;">
                <h5 class="modal-title" id="exampleModalLabel" style="margin-left: auto;">Confirm</h5>
                <button type="button" id="dismissModal"
                    onclick="document.getElementById('deletee').style.display='none'" class="btn-close"
                    data-bs-dismiss="modal" aria-label="Close" (click)="close()"></button>
            </div>
             <div style="padding-top: 15px;
            padding-bottom: 15px; text-align: center;">
                <p>Are you sure, you want to delete </p>
                <p> Advertisement?</p>
            </div>
            <div class="modal-footer" style="padding-bottom: 0;">
                <button type="button" onclick="document.getElementById('deletee').style.display='none'"
                    class="btn btn-danger footer-button ml-auto" data-bs-dismiss="modal"
                    style="width: 18%;margin-left: auto;" (click)="close()">Cancel</button>
                <button type="button" class="btn btn-primary footer-button mr-auto" (click)="ok()"
                    style="width: 18%;margin-right: auto;">Ok</button>
            </div>
        </div>
    </form>
</div>
<div id="id033" class="w3-modal">
    <div class="w3-modal-content">
        <div class="w3-container">
          <div>
            <!-- <h2> Advertisement Images<span onclick="document.getElementById('id033').style.display='none'" class="close"
              title="Close Modal">×</span></h2> -->
          </div>
            
            <!-- <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <img src={{profleimge}} onerror="this.src='assets/images/person.jpg';"
                            style="width: 881px;height: 550px;padding: 2%;" alt="image" title="image" />
                    </div>
                </div>
                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators"
                    data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators"
                    data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                </button>
            </div> -->
        </div>
    </div>
  </div>