import { ChangeDetectorRef, Component, ElementRef, NgZone, ViewChild, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ServerApisService } from 'src/app/shared/services/server-apis.service';
import 'firebase/auth';
import firebase from 'firebase/compat/app';
import { environment } from 'src/environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserProfileObject } from 'src/app/shared/Interfaces/user-profile-obj-interface';
import { AlertService } from 'src/app/shared/alert/alert.service';
import { interval, Subscription } from 'rxjs';

@Component({
  selector: 'app-update-mobile-number',
  templateUrl: './update-mobile-number.component.html',
  styleUrls: ['./update-mobile-number.component.css']
})
export class UpdateMobileNumberComponent implements OnInit {

  userProfileObj: UserProfileObject;
  mobileNumber: string = '';
  countryCode: string = '';
  phoneNum: string = '';
  formName = 'updateMobileNumber';
  otpsent = false;
  errorMsg: string = '';
  notValid: boolean = true;
  confirmationResult: firebase.auth.ConfirmationResult;
  otp: string[] = ['', '', '', '', '', ''];
  recaptchaVerifier: firebase.auth.RecaptchaVerifier | null = null;
  blob: any;
  countryName: string = '';
  mobileNumWithoutCountryCode: string;
  timerValid: boolean = false;
  num1: number = 180;
  subscription: Subscription;
  source = interval(1000);
  country: string = '';
  isOtpValid: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<UpdateMobileNumberComponent>,
    private _apiService: ServerApisService,
    private _alertService: AlertService,
    private cdr: ChangeDetectorRef,
    private zone: NgZone,
    private spinner: NgxSpinnerService
  ) {
    firebase.initializeApp(environment.firebaseConfig);
    this.userProfileObj = {
      memberId: Number(sessionStorage.getItem('memberId')),
      mobile: '',
      fname: '',
      lname: '',
      longitude: '',
      latitude: '',
      fileName: '',
      profileDescription: '',
      email: '',
      countryCode: '',
      fullAddress: '',
      UPImobile: '',
      token: sessionStorage.getItem('token'),
      country_id: 0,
      organisationName: '',
      state_name: '', 
      pinCode: '',    
      dateOfBirth: '', 
      gender: '',     
      deviceType: 'web'
    };
  }

  ngOnInit() {}

  resendOTP() {
    if (this.timerValid) {
      this.num1 = 180;
      this.timerValid = false;
      this.subscription = this.source.subscribe(() => this.numDecrement());

      firebase.auth().signInWithPhoneNumber(this.phoneNum, this.recaptchaVerifier)
        .then((confirmationResult) => {
          this.confirmationResult = confirmationResult;
        })
        .catch((error) => {
          console.error('Error sending OTP:', error);
        });
    }
  }

  numDecrement() {
    if (this.num1 <= 0) {
      this.subscription.unsubscribe();
      this.timerValid = true;
      this.updateButtonState();
    } else {
      this.num1--;
    }
  }

  onMobileInputChange(event: any) {
    event.target.value = event.target.value.replace(/[^0-9]/g, '');
    if (event.target.value.length === 10) {
      let details = {
        email: "",
        mobile: event.target.value,
        countryCode: this.countryCode
      };
      this._apiService.checkMobileNum(details).subscribe((res: any) => {
        if (res != undefined && res != null) {
          if (res.IsUserExistsResult.status === 200) {
            if (res.IsUserExistsResult.message === "User Exists") {
              this.errorMsg = "Mobile number already exists";
              this.notValid = true;
            } else {
              this.errorMsg = "";
              this.notValid = false;
            }
            this.cdr.detectChanges();
          }
        }
      });
    } else {
      this.errorMsg = "";
      this.notValid = true;
    }
    this.updateButtonState();
  }

  updateButtonState() {
    const isCountrySelected = this.country !== '';
    const isMobileValid = this.mobileNumber.length === 10 && !this.notValid;
    this.notValid = !(isCountrySelected && isMobileValid);
    this.isOtpValid = this.otp.every(digit => digit !== '');
    this.cdr.detectChanges();
  }

  onClose(): void {
    this.dialogRef.close();
  }

  validuser(): boolean {
    return this.mobileNumber.length === 10;
  }

  onRequestOtp(): void {
    if (this.validuser()) {
      this.errorMsg = '';
      this.phoneNum = `${this.countryCode}${this.mobileNumber}`;
      this.otpsent = true;
      this.mobileNumWithoutCountryCode = this.mobileNumber;

      if (!this.recaptchaVerifier) {
        this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptchaContainer', {
          'size': 'invisible',
          'callback': (response) => {
            this.zone.run(() => {
              this.verifyOTP();
            });
          }
        });
      }

      firebase.auth().signInWithPhoneNumber(this.phoneNum, this.recaptchaVerifier)
        .then((confirmationResult) => {
          this.confirmationResult = confirmationResult;
          this.num1 = 180;
          this.timerValid = false;
          this.subscription = this.source.subscribe(() => this.numDecrement());
          setTimeout(() => { this.numDecrement() }, 1000);
        })
        .catch((error) => {
          console.error('Error sending OTP:', error);
          this.errorMsg = 'Error sending OTP. Please try again';
        });
    } else {
      this.errorMsg = 'Invalid mobile number';
    }
  }

  verifyOTP() {
    throw new Error('Method not implemented.');
  }

  onSubmitOtp(): void {
    const otpCode = this.otp.join('');
    if (otpCode.length === 6 && this.confirmationResult) {
      this.confirmationResult.confirm(otpCode)
        .then((result) => {
          this.spinner.show();
          this.userProfileObj.mobile = this.mobileNumWithoutCountryCode;
          if (this.countryCode == '+91') {
            this.userProfileObj.country_id = 2;
            this.userProfileObj.countryCode = '+91';
          } else if (this.countryCode == '+1' && this.countryName == 'USA') {
            this.userProfileObj.country_id = 3;
            this.userProfileObj.countryCode = '+1';
          } else if (this.countryCode == '+1' && this.countryName == 'CANADA') {
            this.userProfileObj.country_id = 1;
            this.userProfileObj.countryCode = '+1';
          }
          this._apiService.UpdateUserProfile(this.userProfileObj, this.blob).subscribe((res: any) => {
            this.spinner.hide();
            if (res.status == 200) {
              this._apiService.sendUpdate(this.userProfileObj);
              this._alertService.success('Mobile number is updated successfully');
               setTimeout(() => {
                this.onClose();
                window.location.reload();
              }, 2000);
            } else {
              this._alertService.error('Error occurred !!');
            }
          });
        })
        .catch((error) => {
          console.error('Error verifying OTP:', error);
          this.errorMsg = 'The OTP you entered is invalid, please try again';
        });
    } else {
      this.errorMsg = 'Please enter the 6-digit OTP';
    }
  }
   countrychange(countryName) {
    this.country = countryName;
    if (countryName === 'India') {
      this.countryCode = '+91';
    } else if (countryName === 'Canada' || countryName === 'United States') {
      this.countryCode = '+1';
    } else {
      this.countryCode = '';
    }
    this.updateButtonState();
  }

  setCountryName(countryName: string) {
    this.countryName = countryName;
  }

  focusNext(event: any, index: number) {
    const keyCode = event.keyCode || event.which;
    const target = event.target as HTMLInputElement;
  if (keyCode === 8) {
      if (target.value.length === 0 && index > 0) {
        const prevElement = document.querySelectorAll('.otp-input input')[index - 1] as HTMLInputElement;
        if (prevElement) {
          this.otp[index - 1] = '';
          prevElement.focus();
          this.cdr.detectChanges();
        }
      } else {
       this.otp[index] = '';
        this.cdr.detectChanges();
      }
    } else if (target.value.length >= 1 && index < 5) {
      const nextElement = document.querySelectorAll('.otp-input input')[index + 1] as HTMLInputElement;
      if (nextElement) {
        nextElement.focus();
        this.cdr.detectChanges();
      }
    }
  this.updateButtonState();
  }
  
}  
  

