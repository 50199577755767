<button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal" style="display: none;" id="popupModal">
  Launch demo modal
</button>

<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-backdrop="false" >
  <div class="modal-dialog modal-lg">
    <form [formGroup]="formData" (ngSubmit)="submitForm()">
      <div class="modal-content">
        <button type="button" class="btn-close position-absolute close-button-ctrl" data-bs-dismiss="modal" aria-label="Close" id="closePopup" (click)="closeButton()"></button>
        <div class="modal-body py-0">
          <div class="container px-0">
            <div class="row">
              <div class="col-12 col-sm-12 col-md-12 col-lg-6 px-0">
                <h2 class="text-start pt-3 pb-2 mb-0 ps-3" style="font-size: 22px;font-weight: bold;">Just a few details of yours:</h2>
                <h2 class="text-start py-2 ps-3">Toskie team shall call you within 10 mins.</h2>
                <div class="col-11 mx-auto mb-3 mt-2">
                  <div class="form-floating">
                    <input type="text" class="form-control" id="floatingInput" placeholder="Name" formControlName="name" required>
                    <label for="floatingInput">Name:</label>
                    <div *ngIf="formData.get('name').touched && formData.get('name').invalid" class="text-danger text-start">
                      <small *ngIf="formData.get('name').errors.required">Name is required.</small>
                      <small *ngIf="formData.get('name').errors.minlength">Name must be at least 2 characters long.</small>
                    </div>
                  </div>
                </div>
                <div class="col-11 mx-auto mb-3 mt-2">
                  <div class="input-group mb-3">
                    <button class="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                      <span class="px-2">({{country_code}})</span>
                    </button>
                    <ul class="dropdown-menu">
                      <li class="dropdown-item" (click)="countryChange('India')"><img src="../../../assets/images/layer-9-copy.png" alt="Indian Flag" title="Indian Flag" />  India <span>+91</span> </li>
                      <li class="dropdown-item" (click)="countryChange('USA')"><img src="../../../assets/images/USA.png" alt="USA Flag" title="USA Flag" /> US <span>+1</span></li>
                      <li class="dropdown-item" (click)="countryChange('Canada')"><img src="../../../assets/images/canada.png" alt="USA Flag" title="USA Flag" /> CANADA <span>+1</span></li>
                    </ul>
                    <input type="text" class="form-control" aria-label="Text input with dropdown button" formControlName="mobileNo" max="10" maxlength="10" required>
                  </div>
                  <div *ngIf="formData.get('mobileNo').touched && formData.get('mobileNo').invalid" class="text-danger text-start">
                    <small *ngIf="formData.get('mobileNo').errors.required">Mobile number is required.</small>
                    <small *ngIf="formData.get('mobileNo').errors.pattern">Mobile number must be 10 digits long.</small>
                  </div>
                </div> 
                <!-- <div class="col-11 mx-auto mb-3 mt-2">
                  <div class="form-floating">
                    <input type="text" class="form-control" id="floatingInput" placeholder="Email" formControlName="email" required>
                    <label for="floatingInput">Email:</label>
                    <div *ngIf="formData.get('email').touched && formData.get('email').invalid" class="text-danger text-start">
                      <small *ngIf="formData.get('email').errors.required">Email is required.</small>
                      <small *ngIf="formData.get('email').errors.email">Invalid email</small>
                    </div>
                  </div>
                </div> -->
                <div class="col-11 mx-auto mb-3 mt-2">
                  <div class="form-floating">
                    <input type="text" class="form-control" id="floatingInput" placeholder="Searching For" formControlName="service" required>
                    <label for="floatingInput">Searching For:</label>
                    <div *ngIf="formData.get('service').touched && formData.get('service').invalid" class="text-danger text-start">
                      <small *ngIf="formData.get('service').errors.required">Service field is required.</small>
                    </div>
                  </div>
                </div>
                <div class="col-5 d-grid mx-auto mb-2">
                  <button type="submit" class="btn btn-primary mb-0" [disabled]="formData.invalid">Submit</button>
                </div>
                <!-- <div class="col-12 mx-auto mb-2 hundred">
                  <small><img src="../../../assets/images/shield-halved-solid.png" class="mx-2" alt="shield-halved" title="shield-halved" /> <span>number is secure as per our privacy policy</span></small>
                </div> -->
                <!-- <div class="col-12 mx-auto hundred">
                  <small><img src="../../../assets/images/WhatsApp.png" class="mx-2" alt="WhatsApp" title="WhatsApp" /> <span>Receive updates on WhatsApp</span></small>
                </div> -->
                <div class="row display-none">
                  <h2 class="text-center pt-4 pb-2 mb-0" style="font-size: 22px;font-weight: bold;">Why Toskie?</h2>
                  <div class="row pt-2">
                    <div class="col-12 mb-1 hundred">
                      <div>
                        <!-- <p class="rounded-circle float-start mx-2 mb-0"><img src="../../../assets/images/tickmark.png" class="mx-auto d-block image-verticle-center" alt="WhatsApp" title="WhatsApp" /></p>  -->
                        <p class="text-start align-paragraph padding-bottom-ctrl ps-3">Quality Service at the Lowest Cost</p>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 mb-1 hundred">
                      <div>
                        <!-- <p class="rounded-circle float-start mx-2 mb-0"><img src="../../../assets/images/tickmark.png" class="mx-auto d-block image-verticle-center" alt="WhatsApp" title="WhatsApp" /></p>  -->
                        <p class="text-start align-paragraph padding-bottom-ctrl ps-3">Customised Quotation</p>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 mb-1 hundred">
                      <div>
                        <!-- <p class="rounded-circle float-start mx-2 mb-0"><img src="../../../assets/images/tickmark.png" class="mx-auto d-block image-verticle-center" alt="WhatsApp" title="WhatsApp" /></p>  -->
                        <p class="text-start align-paragraph padding-bottom-ctrl ps-3">India's only neighbourhood search engine</p>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 mb-1 hundred">
                      <div>
                        <!-- <p class="rounded-circle float-start mx-2 mb-0"><img src="../../../assets/images/tickmark.png" class="mx-auto d-block image-verticle-center" alt="WhatsApp" title="WhatsApp" /></p>  -->
                        <p class="text-start align-paragraph padding-bottom-ctrl ps-3">Connects Neighbourhood Professionals</p>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 mb-1 hundred">
                      <div>
                        <!-- <p class="rounded-circle float-start mx-2 mb-0"><img src="../../../assets/images/tickmark.png" class="mx-auto d-block image-verticle-center" alt="WhatsApp" title="WhatsApp" /></p>  -->
                        <p class="text-start mb-3 align-paragraph padding-bottom-ctrl ps-3">Thousands of Trusted & Happy Customers</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-12 col-lg-6 popup-bg px-0 border-radius-rightside-verticle women-bg">
                <img src="../../../assets/images/Womenimage.png" class="img-fluid women-image" alt="Womenimage" title="Womenimage" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>