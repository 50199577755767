<div class="" style="background: #f0f0f0;">
    <nav aria-label="breadcrumb" class="mlft-6">
        <app-bread-crumb [breadTitle]="pushNotificationTitle" [breadpath1]="adminPanel" [breadpath2]="pushNotification"
          [breadroute1]="adminPanelRoute" [showBreadcrumb3]="false">
        </app-bread-crumb>
      </nav>
    <app-confirmation [item]="isAllUsers" *ngIf="isAllUsers" (sendallusers)="sendAll($event)"></app-confirmation>
    <span class="open-Support" (click)="openSupportPage()"><strong style="color: black">Need Help?</strong></span>
    
    <div class="profile-content">
        <div class="container-connecting-dots">
            <div class="profile-section">
                <div class="row insection">
                     <label style="color: red;" *ngIf="isLimitExceeded">The combination of title, image URL/video URL,
                        and message exceeds the limit to 4kb.</label>
                    <form class="login-form" [formGroup]="notificationForm" style="padding: 1% 5% 3% 5%;">
                        <div class="form-group mt-2">
                            <label class="bld mr-1">Title <span style="color: red;">*</span></label>
                            <i class="fa fa-info-circle" matTooltipPosition="after"
                                matTooltip="The title would be of a maximum of 250 characters"
                                [matTooltipShowDelay]="showDelay.value" [matTooltipHideDelay]="hideDelay.value"></i>
                            <input type="text" class="form-control inpt" formControlName="title"
                                (input)="titleChange($event)" style="font-weight: bold;"
                                [ngClass]="{ 'is-invalid': f.title.invalid && (f.title.dirty || f.title.touched) }">
                            <div *ngIf="f.title.invalid && (f.title.dirty || f.title.touched)" class="invalid-feedback">
                                <div *ngIf="f.title.errors.required">Title is required</div>
                                <div *ngIf="f.title.errors.noHtml">HTML tags are not allowed</div>
                            </div>
                            <label *ngIf="isTitleValid" style="color: red;">Your Title is too long! Please limit it to
                                250 characters or less.</label>
                        </div>
                        <div class="row">
                            <div class="form-group col-3 mt-2">
                                <label class="bld mr-1">Receiver Type<span style="color: red;">*</span></label>
                                <i class="fa fa-info-circle" matTooltipPosition="after"
                                    matTooltip="The default is Client, you can change it to Skills, Business/Organization, or Location(Based on your type of Receiver)"
                                    [matTooltipShowDelay]="showDelay.value" [matTooltipHideDelay]="hideDelay.value"></i>
                                <select class="form-control form-select width-100 inpt"
                                    (change)="changereciverType($event)">
                                    <option value="1" [selected]="isReciverTyp=='1'">Client</option>
                                    <option value="2" [selected]="isReciverTyp=='2'">Skills</option>
                                    <option value="3" [selected]="isReciverTyp=='3'">Business/Organization</option>
                                    <option value="4" [selected]="isReciverTyp=='4'">Location</option>
                                </select>
                            </div>
                            <div class="form-group col-3 mt-2" *ngIf="!isLocation || isValidRecieverType">
                                <label class="bld mr-1">Receiver <span style="color: red;">*</span></label>
                                <i class="fa fa-info-circle" matTooltipPosition="after"
                                    matTooltip="Select Receiver, if it is ALL or individual"
                                    [matTooltipShowDelay]="showDelay.value" [matTooltipHideDelay]="hideDelay.value"></i>
                                <div class="dropdown">
                                    <ng-select *ngIf="isReciverTyp=='1'" [items]="lstUsers" [virtualScroll]="true"
                                        bindLabel="userId" [bufferAmount]="1000" bindValue="memberId" [multiple]="true"
                                        class="custom-ng-select-container" placeholder="Select Receiver" #selectBox
                                        (change)="changeUser($event)">
                                    </ng-select>
                                    <ng-select *ngIf="isReciverTyp=='2'" [items]="lstSkills" [virtualScroll]="true"
                                        bindLabel="subProfessionCategoryName" [bufferAmount]="1000"
                                        bindValue="subProfessionsCategoryId" [multiple]="true"
                                        placeholder="Select Receiver" (change)="changeUser($event)" #selectBox>
                                    </ng-select>
                                    <ng-select *ngIf="isReciverTyp=='3'" [items]="lstOrganizations"
                                        [virtualScroll]="true" bindLabel="organisationName" [bufferAmount]="1000"
                                        bindValue="organisationId" [multiple]="true" placeholder="Select Receiver"
                                        (change)="changeUser($event)" #selectBox>
                                    </ng-select>

                                    <div *ngIf="submitted && !isLocation && lstSelectedUsers.length==0" class="invalid">
                                        <div>Receiver is required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-6 " style="margin-bottom: auto;margin-top: auto;"
                                *ngIf="isReciverTyp=='2' || isReciverTyp=='3'">
                                <label><input type="checkbox" class="form-check-input" name="acceptTerms"
                                        (change)="changeLocation($event)" required />&nbsp; Add Specific Location
                                </label>
                            </div>
                            <div class="form-group col-6 mt-2" *ngIf="isLocation">
                                <label class="bld">Location wise</label>
                                <select class="form-control form-select width-100 inpt"
                                    (change)="changeSelection($event)">
                                    <option value="country">Country</option>
                                    <option value="state">State</option>
                                    <option value="city">City</option>
                                    <option value="zipcode">Zipcode</option>

                                </select>
                            </div>
                            <div class="form-group col-6 mt-2"
                                *ngIf="(this.selectedRadio=='country' || this.selectedRadio=='state' || this.selectedRadio=='city' || this.selectedRadio=='zipcode') && isLocation">
                                <label class="bld">Country</label>
                                <ng-select [items]="lstCountry" bindLabel="country_name" placeholder="Select Country"
                                    #selectBox (change)="changeSelectCountry($event)" [(ngModel)]="selectedItems">
                                </ng-select>
                            </div>
                            <div class="form-group col-6 mt-2"
                                *ngIf="(this.selectedRadio=='state'|| this.selectedRadio=='city' || this.selectedRadio=='zipcode') && isLocation">
                                <label class="bld">State </label>
                                <ng-select [items]="lstState" bindLabel="state_name" placeholder="Select State"
                                    #selectBox (change)="changeSelectState($event)">
                                </ng-select>
                            </div>
                            <div class="form-group col-6 mt-2"
                                *ngIf="(this.selectedRadio=='city' || this.selectedRadio=='zipcode') && isLocation">
                                <label class="bld">City </label>
                                <ng-select [items]="lstCities" bindLabel="city_name" placeholder="Select City"
                                    #selectBox (change)="changeSelectCity($event)">
                                </ng-select>
                            </div>
                            <div class="form-group col-6 mt-2" *ngIf="this.selectedRadio=='zipcode' && isLocation">
                                <label class="bld">Zipcode </label>
                                <ng-select [items]="lstZipCodes" bindLabel="zipcode" placeholder="Select Zipcode"
                                    #selectBox (change)="changeSelectZipcode($event)">
                                </ng-select>
                            </div>
                            <div class="form-group col-6 mt-2">
                                <label class="bld mr-1">Expiry Date <span style="color: red;">*</span></label>
                                <i class="fa fa-info-circle" matTooltipPosition="after"
                                    matTooltip="Selection of Notification Expire Date"
                                    [matTooltipShowDelay]="showDelay.value" [matTooltipHideDelay]="hideDelay.value"></i>
                                <input type="date" class="form-control inpt" formControlName="date"
                                    placeholder="mm-dd-yyyy" onkeydown="return false" min="{{minDate}}"
                                    (input)="dateChange()" [ngClass]="{ 'is-invalid': submitted && f.date.errors }">
                                <div *ngIf="submitted && f.date.errors" class="invalid-feedback">
                                    <div *ngIf="f.date.errors.required">Date is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-6 mt-2">
                                <label class="bld mr-1">Upload Image</label> &nbsp; (Image size should be in between
                                10Kb to 1 MB)
                                <i class="fa fa-info-circle" matTooltipPosition="after"
                                    matTooltip="Image size should be between 10Kb to 1 MB and Accepted file types jpeg (or jpg), png."
                                    [matTooltipShowDelay]="showDelay.value" [matTooltipHideDelay]="hideDelay.value"></i>
                                <div class="right-inner-addon input-container">
                                    <i class="fa fa-cloud-upload imgupld" (click)="fileUpload.click()">
                                        <input #fileUpload style="display: none;" type="file" accept=".jpeg,.jpg,.png"
                                            id="upload-file" name="upload-file"
                                            accept="image/png, image/gif, image/jpeg" (change)="addAttachment($event)">
                                    </i>
                                    <input type="text" class="form-control inpt mb-0" aria-describedby="basic-addon2"
                                        accept="image/png, image/gif, image/jpeg" (click)="fileUpload.click()"
                                        formControlName="image" accept=".jpeg,.jpg,.png" readonly
                                        [ngClass]="{ 'is-invalid': submitted && f.image.errors }"
                                        style="border-style: dashed !important;background-color: #fff;">
                                    <label *ngIf="imageError !== ''" style="color: red;">{{imageError}}</label>
                                    <label *ngIf="imageError === ''" class="mb-1">Accepted file types jpeg (or jpg),
                                        png.</label>
                                    <button *ngIf="notificationForm.get('image').value !== ''" type="button"
                                        class="btn btn-link" (click)="clearImage()">Clear Image</button>
                                </div>
                            </div>

                            <div class="form-group col-6 mt-2">
                                <label class="bld mr-1">Add Video Url</label>&nbsp;
                                (https://www.youtube.com/watch?v=-WF7nz85okM)
                                <i class="fa fa-info-circle" matTooltipPosition="after"
                                    matTooltip="Accepted file types YouTube URL, YouTube shorts, Facebook URL,mp4, mov, avi, mkv, webm, ogg"
                                    [matTooltipShowDelay]="showDelay.value" [matTooltipHideDelay]="hideDelay.value"></i>
                                <div class="right-inner-addon input-container">
                                    <i class="fa fa-play-circle vdupld" *ngIf="previewBtn"
                                        (click)="openVideoPreview()"></i>
                                    <input type="url" class="form-control inpt mb-0" aria-describedby="basic-addon2"
                                        formControlName="vediopath" (input)="videoURlChange()"
                                        (blur)="validateVideoUrl()"
                                        [ngClass]="{ 'is-invalid': notificationForm.get('vediopath').errors }">
                                    <label class="mb-1">Accepted file types youtubeURL,facebookURL,mp4, mov, avi, mkv,
                                        webm, ogg</label>
                                    <div *ngIf="notificationForm.get('vediopath').errors?.invalidVideoUrl"
                                        class="invalid-feedback">
                                        Invalid video URL, please provide a valid video URL otherwise notification will
                                        not be send.
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="form-group mt-2" style="position: relative;">
                            <label class="bld mr-1">Message <span style="color: red;">*</span></label>
                            <i class="fa fa-info-circle" (click)="openPopup()" style="cursor: pointer;"></i>
                            <div *ngIf="submitted && ismessage" class="alert-message">
                                <div *ngIf="ismessage">Message is required</div>
                            </div>
                            <div *ngIf="showPopup" class="popup">
                                <div class="popup-content">
                                    <span class="close" (click)="closePopup()">&times;</span>
                                    <p>1. The message would accept a maximum of 1500 characters.</p>
                                    <p>2. Can edit the message with edit components.</p>
                                    <p>3. If there are any paragraphs, it would be under the HTML tag.</p>
                                    <p>4. URL links should be hyperlinked.</p>
                                    <p>5. Click on Mobile/Tele numbers.</p>
                                    <p>6. It should be hyperlinked with Tel: Number.</p>
                                </div>
                            </div>
                        </div>
                        <quill-editor [formControl]="notificationForm.get('message')" [modules]="editorModules"
                            style="width: 100%; height: 140px;"
                            (onContentChanged)="handleChangeEditor($event)"></quill-editor>
                        <div>
                            <div class="error-message" *ngIf="errorMessage">{{ errorMessage }}</div>
                            <div class="error-message" *ngIf="errorMessageTag">{{ errorMessageTag }}</div>
                        </div>
                        <div class="row pt-7">
                            <button type="submit" class="btn btn-primary text-center mt-2 sendbtn hand"
                                [disabled]="!isNotificationFormValid" (click)="sendNotifications()">Send</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

</div>

<div id="videoPreviewModal" *ngIf="videoModal">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header" style="padding: 0.5rem;">
                <h5 class="modal-title">Video Preview</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" style="padding: 0.5rem;"
                    (click)="closeModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <iframe [src]="getSafeUrl(notificationForm.get('vediopath').value)" width="100%" height="500"
                    frameborder="0" allowfullscreen></iframe>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal"
                    (click)="closeModal()">Close</button>
            </div>
        </div>
    </div>
</div>