export enum Role {
    User = 1,
    Toskie = 2,
    Admin = 3,
    SubAdmin = 4,
    MarketingDM = 5,
    SalesTeam = 6,
    Manager = 7,
    CallCenter = 8,
    SeoTeam = 9
}