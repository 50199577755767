<div class="row pad2-5">
    <div class="col-3 col-sm-4 col-md-2" *ngFor="let d of lstallprofessions"
         (click)="getChildCategory(d)">
        <a [routerLink]="[getRouteLink(d)]" routerLinkActive="router-link-active" style="text-decoration: none; color:#fff">
            <div class="Rectangle-647">
                <div class="Rectangle-8 rounded-3">
                    <img *ngIf="d.iconUrlPath !== ''" src="{{geticon(d.professionsId)[0]}}" class="Layer-1348" alt="{{geticon(d.professionsId)[1]}}" title="{{geticon(d.professionsId)[2]}}">
                    <p class="profession-name" *ngIf="d.iconUrlPath == ''">{{ getchar(d.professionName) }}</p>
                    <h5 class="Artist">
                        {{d.professionName}}
                    </h5>
                </div>
            </div>  
        </a> 
    </div>  
</div>