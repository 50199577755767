<div>
    <mat-tab-group>
      <mat-tab>
        <ng-template mat-tab-label>
          <div (click)="getActivityLogWithoutLogin(1)">
            <div class="union-10"></div>
            <span class="approved-text">Follow-Up Pending</span>
          </div>
        </ng-template>
        <div class="tasks_table2 ">
          <div class="row">
            <div class="content">
              <mat-form-field class="select-option">
                <select [(ngModel)]="selectedActivitiesOption" (change)="resetActivitiesOptions()"
                  matNativeControl required style="height: 36px;">
                  <option value="All">---All---</option>
                  <option value="name">Name</option>
                  <option value="mobileNo">Mobile No</option>
                  <option value="activityDescription">Activity Description</option>
                </select>
              </mat-form-field>
              <div class="search-box">
                <div class="search-section" *ngIf="selectedActivitiesOption === 'All'">
                  <label>
                    <span>
                      <input type="text" id="search" class="custom-focus-input" autocomplete="off"
                        [(ngModel)]="searchedName" placeholder="Search..." (input)="searchedByAll($event)">
                    </span>
                  </label>
                </div>
                <div class="search-section" *ngIf="selectedActivitiesOption === 'name'">
                  <label>
                    <span>
                      <input type="text" id="search" class="custom-focus-input" autocomplete="off"
                        [(ngModel)]="searchedName" placeholder="Search With Name"
                        (input)="searchedByName($event)">
                    </span>
                  </label>
                </div>
                <div class="search-section" *ngIf="selectedActivitiesOption === 'mobileNo'">
                  <label>
                    <span>
                      <input type="text" id="search" class="custom-focus-input" autocomplete="off"
                        [(ngModel)]="searchedMobileNo" placeholder="Search With Mobile No."
                        (input)="searchedByMobileNo($event)">
                    </span>
                  </label>
                </div>
                <div class="search-section" *ngIf="selectedActivitiesOption === 'activityDescription'">
                  <label>
                    <span>
                      <input type="text" id="search" class="custom-focus-input" autocomplete="off"
                        [(ngModel)]="searchedactivityDescription" placeholder="Search With Activity Description"
                        (input)="searchedByActivityDescription($event)">
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <table class="table table-striped">
          <thead class="tablehd-sec">
            <tr>
              <th style="width: 11%;">Activity Id</th>
              <th style="width: 17%;">Name</th>
              <th style="width: 18%;">Mobile#</th>
              <th style="width: 21%;">Activity Description</th>
              <th style="width: 17%;">Date</th>
              <th style="width: 16%;">Follow-Up</th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let d of lstactivityLogList | paginate: { itemsPerPage: 20, currentPage: p, totalItems:pendingCount  }; let i = index"
              class="tdata">
              <td style="width: 11%;">{{d.activityLogWithoutLogInId}}</td>
              <td style="width: 17%;">{{d.name}}</td>
              <td style="width: 18%;">{{d.mobileNo}}</td>
              <td style="width: 21%;">{{d.activityDescription}}</td>
              <td style="width: 17%;">{{d.dates}}</td>
              <td style="width: 16%;">
                <mat-slide-toggle
                  [(ngModel)]="d.isFollowUpDone"
                  (change)="updateFollowUpStatus(d.activityLogWithoutLogInId, $event.checked ? 'Yes' : 'No')"
                  [ngModel]="d.isFollowUpDone === 'Yes'"
                  class="custom-slide-toggle"
                  aria-label="Follow-up status">
                </mat-slide-toggle>
              </td>

            </tr>
          </tbody>
        </table>

        <div class="row">
          <div class="col">
            <div class="d-flex justify-content-center"  *ngIf="lstactivityLogList.length > 0">
              <pagination-controls (pageChange)="pageChangedWithoutLoginActivities($event)"
                (pageBoundsCorrection)="pageChanged($event)"></pagination-controls>
            </div>
          </div>
        </div>
      </mat-tab>

      <mat-tab [ngClass]="'custom-approved-tab'" >
        <ng-template mat-tab-label>
          <div (click)="getActivityLogWithoutLoginApproved(1)">
            <div class="union-10"></div>
            <span class="approved-text">Follow-Up Done</span>
          </div>
        </ng-template>
        <div class="tasks_table2">
          <div class="row">
            <div class="content">
              <mat-form-field class="select-option">
                <select [(ngModel)]="selectedActivitiesApprovedOption" (change)="resetActivitiesApprovedOptions()"
                  matNativeControl required style="height: 36px;">
                  <option value="AllApproved">---All---</option>
                  <option value="nameApproved">Name</option>
                  <option value="mobileNoApproved">Mobile No</option>
                  <option value="activityDescriptionApproved">Activity Description</option>
                </select>
              </mat-form-field>
              <div class="search-box">
                <div class="search-section" *ngIf="selectedActivitiesApprovedOption === 'AllApproved'">
                  <label>
                    <span>
                      <input type="text" id="search" class="custom-focus-input" autocomplete="off"
                        [(ngModel)]="searchedAllApproved" placeholder="Search..."
                        (input)="searchedByAllApproved($event)">
                    </span>
                  </label>
                </div>
                <div class="search-section" *ngIf="selectedActivitiesApprovedOption === 'nameApproved'">
                  <label>
                    <span>
                      <input type="text" id="search" class="custom-focus-input" autocomplete="off"
                        [(ngModel)]="searchedNameApproved" placeholder="Search With Name"
                        (input)="searchedByNameApproved($event)">
                    </span>
                  </label>
                </div>
                <div class="search-section" *ngIf="selectedActivitiesApprovedOption === 'mobileNoApproved'">
                  <label>
                    <span>
                      <input type="text" id="search" class="custom-focus-input" autocomplete="off"
                        [(ngModel)]="searchedMobileNoApproved" placeholder="Search With Mobile No."
                        (input)="searchedByMobileNoApproved($event)">
                    </span>
                  </label>
                </div>
                <div class="search-section" *ngIf="selectedActivitiesApprovedOption === 'activityDescriptionApproved'">
                  <label>
                    <span>
                      <input type="text" id="search" class="custom-focus-input" autocomplete="off"
                        [(ngModel)]="searchedactivityDescriptionApproved" placeholder="Search With Activity Description"
                        (input)="searchedByActivityDescriptionApproved($event)">
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <table class="table table-striped">
          <thead class="tablehd-sec">
            <tr>
              <th style="width: 11%;">Activity Id</th>
              <th style="width: 17%;">Name</th>
              <th style="width: 18%;">Mobile#</th>
              <th style="width: 21%;">Activity Description</th>
              <th style="width: 17%;">Date</th>
              <th style="width: 16%;">Follow-Up</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let d of lstactivityLogListApproved | paginate: { itemsPerPage: 20, currentPage: p, totalItems:count }; let i = index" class="tdata">
              <td style="width: 11%;">{{d.activityLogWithoutLogInId}}</td>
              <td style="width: 17%;">{{d.name}}</td>
              <td style="width: 18%;">{{d.mobileNo}}</td>
              <td style="width: 21%;">{{d.activityDescription}}</td>
              <td style="width: 17%;">{{d.dates}}</td>
              <td style="width: 16%;">
             <span *ngIf="d.isFollowUpDone === 'Yes'" class="checkmark">&#10003;</span>
                </td>
              </tr>
          </tbody>
        </table>
        
        <div class="row">
          <div class="col">
            <div class="d-flex justify-content-center" *ngIf="lstactivityLogListApproved.length > 0">
              <pagination-controls (pageChange)="pageChangedWithoutLoginActivitiesApproved($event)"></pagination-controls>
            </div>
          </div>
        </div>
        
      </mat-tab>
      
    </mat-tab-group>
  </div>