import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs'
@Injectable({
  providedIn: 'root'
})
export class PropsService {
  // wishlistCount: number = 0;
  private wishlistCountSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private profileCompeletionSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  constructor() { }
  public GetWishList()
  {
  return this.wishlistCountSubject.asObservable();
  }
  public SetWishList(data)
  {
    this.wishlistCountSubject.next(data);
  }

  public setProfileCompeletion(data)
  {
    this.profileCompeletionSubject.next(data);
  }

  public getProfileCompeletion()
  {
    return this.profileCompeletionSubject.asObservable();
  }
}
