import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild,ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgSelectComponent } from '@ng-select/ng-select';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServerApisService } from 'src/app/shared/services/server-apis.service';
import { Role } from 'src/app/shared/enums/role.enum';
import { Constants,navConstants } from 'src/app/shared/constants/constant';
import { DomSanitizer, SafeResourceUrl,SafeUrl } from '@angular/platform-browser';
import { RouteMakingService } from 'src/app/shared/services/route-making.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-daily-counts-reports',
  templateUrl: './daily-counts-reports.component.html',
  styleUrls: ['./daily-counts-reports.component.css']
})
export class DailyCountsReportsComponent {
  p = 1;
  selectedItems: any = [];
  lstDailyCount: any = [];
  dailyCountSearch: string = '';
  isRegistredData = [];
  lsttempUsersByRegisteredDate: any = [];
  isCard: boolean = true;
  selectedPopDate: any;
  datesort: string = "";
  lstUsersByRegisteredDate: any = [];
  page: string = '';
  searchedreceiverFullName: any;
  submitted: boolean = false;
  isEditIndex:number;
  lstallusersdisplay: any[];

  constructor(private router: Router, private formBuilder: FormBuilder,
    private _apiService: ServerApisService, private spinner: NgxSpinnerService,private cdr: ChangeDetectorRef, private datePipe: DatePipe, private sanitizer: DomSanitizer,private routeMakingService: RouteMakingService) {
  }

 ngOnInit(): void {
  const user = sessionStorage.getItem('user');
   if (user != null) {
     let data = JSON.parse(user);
     if (data.roleId === Role.Admin || data.roleId === Role.CallCenter) {
      this.getalldailyCount();
     } else {
       this.router.navigate([""]);
     }
   } else {
     let params=[Constants.Login]
     let routingUrl= this.routeMakingService.getNavigateUrl(params)
     this.router.navigate([routingUrl]);
   }

   }
 loadTabData(report: any) {
   throw new Error('Method not implemented.');
 }
 changeDailyCountSearch(event: any) {
  if (event.target.value != '' && event.target.value != undefined) {
    var result = this.lsttempUsersByRegisteredDate.filter((x: any) => x.OrganisationName.toLowerCase().includes(event.target.value.toLowerCase())
      || x.Address.toLowerCase() == event.target.value.toLowerCase()
      || x.EmailID.toLowerCase() == event.target.value.toLowerCase()
      || x.MobileNo == event.target.value)
    this.lstUsersByRegisteredDate = result;
  } else {
    this.lstUsersByRegisteredDate = this.lsttempUsersByRegisteredDate;
  }
}
scrollToTop() {
  window.scrollTo({ top: 0, behavior: 'smooth' });
}
pageChanged(event: any) {
  this.p = event;
  this.isEditIndex=-1;
 }
 getisRead(value: any) {
  if (value == 'Y') {
    return "Yes";
  } else {
    return "No";
  }
}
menu() {
  if (this.isCard) {
    this.isCard = false;
  } else {
    this.isCard = true;
  }
}
getalldailyCount() {
  this.spinner.show();
  this.lstallusersdisplay = [];
  this._apiService.FetchAllUserCountByRegisteredDate().subscribe(res => {
    this.spinner.hide();
    this.lstDailyCount = res['FetchAllUserCountByRegisteredDateResult'].resultCountByRegisteredDate;
  });

}
registereddatesort() {
  if (this.datesort == "") {
    this.datesort = "up";
    this.lstDailyCount.sort((a, b) => (b.RegistrationDate > a.RegistrationDate) ? -1 : 1);

  } else if (this.datesort == "up") {
    this.datesort = "down";
    this.lstDailyCount.sort((a, b) => (a.RegistrationDate > b.RegistrationDate) ? -1 : 1);

  } else if (this.datesort == "down") {
    this.datesort = "";
    this.lstDailyCount.sort((a, b) => a.RegistrationDate - b.RegistrationDate);
  }
}

getRegistedInfo(data: any) {
  this.selectedPopDate = data.RegistrationDate;
  let payload: any = {};
  this.spinner.show();
  this.lstUsersByRegisteredDate = [];
  this.lsttempUsersByRegisteredDate = [];
  payload.registeredDate = this.datePipe.transform(data.RegistrationDate, "yyyy-MM-dd");
  this._apiService.FetchUsersByRegisteredDate(payload).subscribe(res => {
    this.spinner.hide();
    this.lstUsersByRegisteredDate = res['resultUsersByRegisteredDate'];
    this.lsttempUsersByRegisteredDate = this.lstUsersByRegisteredDate
  });
}


close() {
  this.lstUsersByRegisteredDate = [];
  this.lsttempUsersByRegisteredDate = [];
  this.dailyCountSearch = "";
}
}
