import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ServiceProviderListRoutingModule } from './service-provider-list-routing.module';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ServiceProviderListRoutingModule
  ]
})
export class ServiceProviderListModule { }
