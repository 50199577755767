export const Constants = {
   DefaultRangeDistance: 20,
   Profile: 'profile',
   Login: 'login',
   adminPanel: 'admin-panel',
   callLogs: 'call-logs',
   SubProfessionalCategory: 'sub-professional-category',
   PrivacyPolicy: 'privacy-policy',
   Wishlist: 'wishlist',
   Notification: 'notification',
   adminApproval: 'admin-approvals',
   ExceptionLogs: 'exception-logs',
   AccountVerification:'account-verification',
   mainProfessionTitle: 'Main Profession Approval',
   userRolesTitle: 'User Roles Approval',
   childProfessionTitle: 'Child Profession Approval',
   Service: 'service',
   home: '',
   aboutUs: 'about-us',
   aboutUsRoute: '',
   contactUs: 'contact-us',
   controlPanelTitle: '',
   controlPanelRoute: '',
   accountVerificationTitle: 'Account-Verification',
   activities: 'activities',
   loginActivitiesTitle: 'Login Activities',
   withoutLoginActivitiesTitle: 'Without Login Activities',
   activitiesTite: 'Activities',
   adminPanelRoute: 'admin-panel',
   adminSitemap: 'sitemap',
   Admin: 'admin',
   NotFound: 'not-found',
   TermsConditions: 'terms-conditions',
   adminSitemapTitle: 'Sitemap',
   advertisement: 'advertisement',
   postAdvertisementTitle: 'Post Advertisement',
   modifyAdvertisementTitle: 'Modify Advertisement',
   exceptionLogsTitle: 'Exception Logs',
   nearByServiceManagement: 'near-by-service-management',
   nearByCategoryCreation: 'Near By Category Creation',
   nearBySubCategoryCreation: 'Near By Sub Category Creation',
   expertProfile: 'expert-profile',
   expertRating: 'expert-rating',
   pushNotificationTitle: 'Push Notification',
   activitiesNav: 'login-activities',
   referAFriend: 'refer-a-friend',
   reports: 'reports',
   reportsNav: 'user-information',
   Reports: 'Reports',
   UnderConsruction: 'under-consruction',
   NoResultsFound: 'noresults-found',
   SearchResults:   'search-results',
   userManagement: 'user-management',
   Search: 'search',
   userManagementNav: 'role-creation',
   userManagementTitle: 'User Management',
   profession: 'profession',
   Refer: 'refer',
   Rating: 'rating',
   callLogsRoute: '',
   contactUsRoute: '',
   referAFriendRoute: '',
   reportsTitle: 'Reports',
   professionsNav: 'assign-profession',
   professionsTitle: 'Assign Profession',
   userGuide: 'user-guide',
   Register: 'register',
   userGuideTitle: 'User Guide',
   vendorManagement: 'vendor-management',
   vendorCreationTitle: 'Vendor Creation',
   vendorAssignmentTitle: 'Vendor Assignment',
   vendorNumberAssignmentTitle: 'Vendor Number Assignment',
   addService: 'add-service',
   addServiceRoute: '',
   serviceProviderRoute: '',
   wishlistRoute: '',
   userInformationTitle: 'User Information',
   profileTransactionTitle: 'Profile Tranction Logs',
   NotificationTitle: 'Notification',
   dailyCountTitle: 'Dailly Count',
   profileDataTitle: 'Profile Data',
   activityDataTitle: 'Activity Data',
   roleCreationTitle: 'Role Creation',
   roleAssignmentTitle: 'Role Assignment',
   profileVideoTitle: 'Profile Video',
   sponsoredUserTitle: 'Sponsored User',
   assignProfessionTitle: 'Assign Profession',
   mainProfessionsTitle: 'Main Profession',
   childProfessionsTitle: 'Child Profession',
   userNotification : 'user-notification',
};

 export const navConstants = {
   mainProfessionApproval: 'main-profession-approval',
   childProfessionApproval: 'child-profession-approval',
   loginActivitiesNav: 'login-activities',
   withoutLoginActivitiesNav: 'without-login-activities',
   userRolesApproval: 'user-roles-approval',
   accountVerification: 'account-verification',
   postAdvertisementNav: 'post-advertisement',
   modifyAdvertisementNav: 'modify-advertisement',
   exceptionLogs: 'exception-logs',
   nearByCategoryCreationNav: 'near-by-category-creation',
   nearBySubCategoryCreationNav: 'near-by-sub-category-creation',
   pushNotification: 'push-notification',
   vendorCreationNav: 'vendor-creation',
   vendorAssignmentNav: 'vendor-assignment',
   vendorNumberAssignmentNav: 'vendor-number-assignment',
   userInformationNav: 'user-information',
   profileTransactionNav: 'profile-tranction-logs',
   NotificationNav: 'notification',
   dailyCountNav: 'daily-count',
   profileDataNav: 'profile-data',
   activityDataNav: 'activity-data',
   roleCreationNav: 'role-creation',
   roleAssignmentNav: 'role-assignment',
   profileVideoNav: 'profile-video',
   sponsoredUserNav: 'sponsored-user',
   mainProfessionsNav: 'main-profession',
   childProfessionsNav: 'child-profession',
   assignProfessionNav: 'assign-profession',
};