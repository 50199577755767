import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ImageValidationService {

  constructor(private http: HttpClient) {}

  checkImageSize(url: string): Observable<number> {
    return this.http.get(url, { responseType: 'blob' }).pipe(
      map(response => response.size),
      catchError(() => of(0))
    );
  }

  isImageValid(url: string): Observable<boolean> {
    return this.http.get(url, { responseType: 'blob' }).pipe(
      map(response => response.type.startsWith('image/')),
      catchError(() => of(false))
    );
  }

  validateImage(url: string): Observable<boolean> {
    if (!url) {
      return of(false);
    }

    return this.checkImageSize(url).pipe(
      switchMap(size => {
        console.log(size);
        
        if (size > 100) {
          return this.isImageValid(url);
        } else {
          return of(false);
        }
      }),
      catchError(() => of(false))
    );
  }
}
