import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser'; 
import { Constants } from 'src/app/shared/constants/constant';
import { ServerApisService } from 'src/app/shared/services/server-apis.service';

@Component({
  selector: 'app-refer-friend',
  templateUrl: './refer-friend.component.html',
  styleUrls: ['./refer-friend.component.css']
})
export class ReferFriendComponent implements OnInit {

  uniqueId: string;
  isConnect: boolean = true;
  obj: any = {};
  home: string = Constants.home;
  referAFriend: string = Constants.referAFriend;
  referAFriendRoute: string = Constants.referAFriendRoute;
  profileUrl: string = ''; 

  constructor(private _serverService: ServerApisService, private sanitizer: DomSanitizer) {} 

  ngOnInit(): void {
    this.getUserUniqueId();
    window.scrollTo(0, 0);
  }

  connect(val: string) {
    if (val == 'connect') {
      this.isConnect = true;
    } else if (val == 'earn') {
      this.isConnect = false;
    }
  }

  getUserUniqueId() {
    const user = sessionStorage.getItem('user');
    if (user != null) {
      let data = JSON.parse(user);
      if (data.UserUniqueCode != null) {
        this.uniqueId = data.UserUniqueCode;
      } else {
        this.uniqueId = 'NA';
      }
    }
  }

  
  getSocialMediaLink(platform: string): SafeUrl {
    const deepLinks = {
      whatsapp: 'whatsapp://send?text=',
      sms: 'sms:?&body=', 
      gmail: 'mailto:?subject=Refer%20a%20Friend&body=', 
      facebook: 'fb://page/?id=',          
    };

    const isMobile = window.innerWidth < 768;
    const deepLink = deepLinks[platform.toLowerCase()];

    if (isMobile && deepLink) {
      // Try to open the app
      const message = `Hey there! Sign-up on the Toskie App using my referral code and get Rs.50 in your Toskie Wallet. So hurry, download the Toskie App now: https://qr.toskie.com/g/3F9CHZgf3Ic and use my referral code ${this.uniqueId} while signing up`;
      const deepLinkUrl = `${deepLink}${encodeURIComponent(message)}`;
      return this.sanitizer.bypassSecurityTrustUrl(deepLinkUrl);
    } else {
      
      switch (platform.toLowerCase()) {
        case 'whatsapp':
          const whatsappMessage = `Hey there! Sign-up on the Toskie App using my referral code and get Rs.50 in your Toskie Wallet. So hurry, download the Toskie App now: https://qr.toskie.com/g/3F9CHZgf3Ic and use my referral code ${this.uniqueId} while signing up`;
          return this.sanitizer.bypassSecurityTrustUrl(`https://web.whatsapp.com/send?text=${encodeURIComponent(whatsappMessage)}`);
        case 'message':
          const smsMessage = `Hey there! Sign-up on the Toskie App using my referral code and get Rs.50 in your Toskie Wallet. So hurry, download the Toskie App now: https://qr.toskie.com/g/3F9CHZgf3Ic and use my referral code ${this.uniqueId} while signing up`;
          return this.sanitizer.bypassSecurityTrustUrl(`sms:?&body=${encodeURIComponent(smsMessage)}`);
          case 'gmail':
           const gmailMessage = `Hey there! Sign-up on the Toskie App using my referral code and get Rs.50 in your Toskie Wallet. So hurry, download the Toskie App now: https://qr.toskie.com/g/3F9CHZgf3Ic and use my referral code ${this.uniqueId} while signing up`;
            return this.sanitizer.bypassSecurityTrustUrl(`https://mail.google.com/mail/?view=cm&to=&su=Refer%20a%20Friend&body=${encodeURIComponent(gmailMessage)}`);
        case 'facebook':
          const encodedUrl = encodeURIComponent(this.uniqueId);
          const facebookMessage = `Hey there! Sign-up on the Toskie App using my referral code and get Rs.50 in your Toskie Wallet. So hurry, download the Toskie App now: https://qr.toskie.com/g/3F9CHZgf3Ic and use my referral code ${this.uniqueId} while signing up`;
          const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=https://www.toskie.com?ref=${encodedUrl}&quote=${encodeURIComponent(facebookMessage)}`;
          return this.sanitizer.bypassSecurityTrustUrl(facebookShareUrl);
        default:
          return this.sanitizer.bypassSecurityTrustUrl(''); 
      }
    }
  }
}
