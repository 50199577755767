<div class="whole-section">
    <nav aria-label="breadcrumb" class="mlft-6">
      <app-bread-crumb [breadTitle]="adminSitemapTitle" [breadpath1]="adminPanel" [breadpath2]="adminSitemap" [breadroute1]="adminPanelRoute" [showBreadcrumb3]="false">
      </app-bread-crumb>
    </nav>
  <div class="main-container">
      <div class="sidebar">
        <div class="button-link-container">
          <button type="button" class="btn btn-primary generateSitemapBtn" (click)="generateSitemap();" [disabled]="btnDisable" >Generate Profile Sitemap</button>
          <button type="button" class="btn btn-primary generateSitemapBtn" (click)="generateSearchSitemap();" [disabled]="btnDisable" >Generate Search Sitemap</button>
          <div class="url-container">
            <!-- <div *ngFor="let url of urls">
              <a href="{{url?.sitemapUrl}}" class="links" target="_blank">{{ url?.sitemapUrl }}</a>
            </div> -->
          </div>
        </div>
      </div>
  
      <div class="content">
        <div class="table-section">
          <div class="table-title">
            <div class="date-search-container">
              <input type="date" class="section-border form-control"  [(ngModel)]="fromDate" (change)="onDateChange($event, 'fromDate')" />
              <input type="date" class="section-border form-control"  [(ngModel)]="toDate" (change)="onDateChange($event, 'toDate')" />
              <input type="text" id="search" class="custom-focus-input search-box" autocomplete="off" [(ngModel)]="searchedLoc" placeholder="Search With Url" (input)="searchedByLoc($event)">
            </div>
          </div>
      
          <table class="table table-striped">
            <thead>
              <tr>
                <th style="width: 20%;">Date</th>
                <th style="width: 80%;">Url</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let d of lstSitemapList | paginate: { itemsPerPage: 20, totalItems: count, currentPage: p }; let i = index">
                <td style="width: 20%;">{{ d.lastmod | date:'M/d/yyyy, h:mm:ss a' }}</td>
                <td style="width: 80%;">
                  <a [href]="d.loc" target="_blank">{{d.loc}}</a>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="pagination-controls">
            <pagination-controls (pageChange)="pageChanged($event)" (pageBoundsCorrection)="pageChanged($event)">
            </pagination-controls>
          </div>
        </div>
      </div>
    </div>
  </div>
  