import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.css']
})
export class ConfirmationComponent implements OnInit {
  @Input() item = '';
  @Output() sendallusers = new EventEmitter<string>();
  constructor() { }

  ngOnInit(): void {
  }
  cancel(){
    this.item='';
    this.sendallusers.emit('invisible');
  }
  ok(){
    this.item='';
    this.sendallusers.emit('visible');
  }
}
