import { Component, OnInit, OnChanges, Input, ChangeDetectorRef, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgZone } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ServerApisService } from '../../shared/services/server-apis.service';
import { AlertService } from 'src/app/shared/alert/alert.service';
import { DataService } from 'src/app/shared/services/data.service';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { UserProfileObject } from '../../shared/Interfaces/user-profile-obj-interface';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.css']
})
export class ReviewComponent implements OnInit {
  QueryParamId: string;
  UserProfile: boolean = false;
  ratingAndReview: any = [];
  userProfileObj: UserProfileObject;
  Qrating: number = 0;
  starQualityList: boolean[] = [false, false, false, false, false]; // Initial state set to empty stars
  Rrating: number = 0;
  review: string = '';
  providerInfo: any = {};
  services: any[] = [];
  starClicked: boolean = false; // Track if a star has been clicked

  constructor(private zone: NgZone, private cdr: ChangeDetectorRef, public dialog: MatDialog, private route: ActivatedRoute, private router: Router, private _apiService: ServerApisService,
    private _alert: AlertService, private datePipe: DatePipe, private spinner: NgxSpinnerService, private dataService: DataService, private sanitizer: DomSanitizer) {
    this.userProfileObj = {
      memberId: 0,
      mobile: '',
      fname: '',
      lname: '',
      longitude: '',
      latitude: '',
      fileName: '',
      profileDescription: '',
      email: '',
      countryCode: '',
      fullAddress: '',
      UPImobile: '',
      token: '',
      country_id: 0,
      organisationName: '',
      state_name: '',
      pinCode: '',
      dateOfBirth: '',
      gender: '',
      deviceType: 'web'
    };
  }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe((URLparams: any) => {
      this.route.params.subscribe(params => {
        const parts = params['name-:id'].split('-');
        if (parts.length > 1) {
          this.QueryParamId = parts[parts.length - 1];
        }
      });
    });
    this.getAllReviewAndRatings(this.QueryParamId);
    this.viewProviderProfileBYURL(this.QueryParamId);
    if (this.QueryParamId == sessionStorage.getItem('memberId')) {
      this.UserProfile = true;
    }
    if (null == sessionStorage.getItem('memberId')) {
      this.UserProfile = true;
    }
  }

  viewProviderProfileBYURL(p) {
    const payLoad: any = {};
    payLoad.memberId = sessionStorage.getItem('memberId');
    payLoad.providerMemberId = p;
    payLoad.token = this._apiService.token;
    this._apiService.getProviderProfile(payLoad).subscribe((res: any) => {
      try {
        this.providerInfo = res.Registration;
        res.Registration.Services.forEach(element => {
          this.services.push(element);
        });
      }
      catch {
        err => {
          var obj = {
            ErrorMessage: err
          }
          this._apiService.ExceptionLog(obj).subscribe(res => {

          });

        }
      }
    })
  }

  submitRatingAndReview(p) {
     //this.submitted=true;
  // if(this.Qrating!=0)
  // {
    const payload: any = {};
    payload.userMemberId = sessionStorage.getItem('memberId');
    payload.quality = this.Qrating;
    payload.reliable = this.Qrating;
    payload.comment = this.review;
    payload.providerMemberId = p;
    this.spinner.show();
    payload.token = this._apiService.token;

    this._apiService.submitRatingAndReview(payload).subscribe(
      res => {
        window.scrollTo(0, 0);
        this.spinner.hide();
        try{
        if(res['resultModel'].status==200){
          this._alert.success(res['resultModel'].result);
          this.resetForm();
          this.getAllReviewAndRatings(p);    
          this.Qrating=0; 
          //this.submitted=false;
          // window.location.reload();
        //   setTimeout(()=>{
        //     this.router.navigate(["/"]);
        // }, 2000);
        } else{
          
          this._alert.success("Unable to send your feedback, Please try again");
        }
        //console.log(res['resultModel'].status);
      }
      catch{ err=>{
        var obj={
          ErrorMessage:err
        }
        this._apiService.ExceptionLog(obj).subscribe(res => {
         
        });

          }
        }
      }
    )
  }

  getAllReviewAndRatings(p) {
    const payLoad: any = {};
    const memberId = sessionStorage.getItem('memberId');
    if (memberId === null) {
      payLoad.memberId = 0
      payLoad.token = '';
    } else {
      payLoad.memberId = +memberId;
      payLoad.token = this._apiService.token;
    }
    payLoad.providerMemberId = p;
    this.spinner.show();
    this._apiService.GetRatingAndReview(payLoad).subscribe((res: any) => {
      this.spinner.hide();
      try {
        res.resultRatingAndReview.forEach(element => {
          if (element.imagePath.split("/").splice(-1)[0] !== 'null') {
            element.imagePath = element.imagePath
          } else {
            element.imagePath = 'assets/images/person.jpg'
          }
          if (element.modifiedDate) {
            element.modifiedDate = this.convertUtcToBrowserTimeZone(element.modifiedDate);
          }
        });
        this.ratingAndReview = res.resultRatingAndReview;
      }
      catch {
        err => {
          var obj = {
            ErrorMessage: err
          }
          this._apiService.ExceptionLog(obj).subscribe(res => {

          });

        }
      }
    })
  }

  convertUtcToBrowserTimeZone(utcDateTime: string): string {
    const utcDate = new Date(utcDateTime);
    const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // Set the UTC offset to zero to ensure correct conversion
    utcDate.setMinutes(utcDate.getMinutes() - utcDate.getTimezoneOffset());

    const day = ('0' + utcDate.getDate()).slice(-2);
    const month = ('0' + (utcDate.getMonth() + 1)).slice(-2);
    const year = utcDate.getFullYear();
    const hours = ('0' + ((utcDate.getHours() + 11) % 12 + 1)).slice(-2); // Convert 24-hour to 12-hour
    const minutes = ('0' + utcDate.getMinutes()).slice(-2);
    const seconds = ('0' + utcDate.getSeconds()).slice(-2);
    const ampm = utcDate.getHours() >= 12 ? 'PM' : 'AM';

    const formattedDateTime = `${day}-${month}-${year} ${hours}:${minutes}:${seconds} ${ampm}`;
    return formattedDateTime;
  }

  setQualityStar(data: any) {
    this.Qrating = data + 1;
    this.starClicked = true;
    for (var i = 0; i < 5; i++) {
      this.starQualityList[i] = i <= data;
    }
  }

  isSubmitEnabled(): boolean {
    return this.review.trim().length > 0 || this.starClicked;
  }

  resetForm() {
    this.review = '';
    this.starQualityList = [false, false, false, false, false]; // Reset to empty stars
    this.starClicked = false;
    this.Qrating = 0;
  }
}


