import { Component,Output,EventEmitter,Input } from '@angular/core';
import {NgxSpinnerService} from 'ngx-spinner';
import { ActivatedRoute,Router } from '@angular/router';
import {ServerApisService} from 'src/app/shared/services/server-apis.service';
import { Constants } from 'src/app/shared/constants/constant';
import { DashboardService } from 'src/app/shared/services/dashboard.service';

@Component({
  selector: 'app-child-professions',
  templateUrl: './child-professions.component.html',
  styleUrls: ['./child-professions.component.css']
})
export class ChildProfessionsComponent {
  @Input() selectedParent : any;
  @Input() selectedParentId : any;
  lstallprofessions: any = [];
  city: any;
  parentCategory: any;
  lstChildProfessions:any=[];
  allProfessionSub: any;

  constructor(private spinner: NgxSpinnerService,private _apiService: ServerApisService,private route:Router,
    private activatedRoute: ActivatedRoute,private dashboardService:DashboardService) { 
    }
    
    ngOnInit(): void {
      
      this.activatedRoute.paramMap.subscribe(params => {
        // this.city = params.get('city');
        this.parentCategory = params.get('parentCategory');
      });
      
      this.activatedRoute.params.subscribe(params => {
        // this.city = params['city'];
      if(this.dashboardService.getDashboardDetails()){
        this.lstallprofessions = this.dashboardService.getDashboardDetails().GetDashboardDetailsResult.resultCategoryHierarchy;
        this.setChildProfession();
      } else {
        this.allProfessionSub = this.dashboardService.allProfessions$.subscribe((data:any) => {
          this.lstallprofessions = data.GetDashboardDetailsResult.resultCategoryHierarchy;
          this.setChildProfession();
        });
      }
      this.parentCategory = params['parentCategory'];
      if(!this.parentCategory.includes('not found')){
        sessionStorage.setItem('parentCategory', this.parentCategory);
      }
    });
    // const currentUrl = window.location.href;
    // const [baseUrl, categoryAndId] = currentUrl.split('sub-professional-category/');
    // const [category, id] = categoryAndId.split('-');
    // this.getChildCategory(id);
  }

  setChildProfession(){
    this.lstallprofessions.forEach((element: any) => {
      if (element.category.toLowerCase().replace(/ /g, '-') == this.parentCategory.toLowerCase()) {
        this.lstChildProfessions = element.children;
      }
    });
  }
  
  // getChildCategory(id){
  //   let payload = {
  //     "professiontype": "childProfession",
  //     "professionsId": id
  //   }
  //   this.spinner.show();
  //   this._apiService.fetchAllProfessions(payload).subscribe(res => {
  //     window.scrollTo(0, 0);
  //     this.spinner.hide();
  //     this.lstChildProfessions = res['resultServiceProfessions'];
  //   })
  // }

  // routeLink(data:any){
  //   let professionNameWithHyphen = data.professionName;
  //   if (professionNameWithHyphen.includes(' ')) {
  //     professionNameWithHyphen = professionNameWithHyphen.replace(/ /g, '-');
  //   }
  //   return '/search/'+professionNameWithHyphen.toLowerCase()+'--'+sessionStorage.getItem('latlng');
  // }

  getchar(professionName:string){
    const words = professionName.split(' ');
    return words.map(word => word.charAt(0)).join('');
  }

  generateLink(d: any): string {
    let professionNameWithHyphen = d.category;
    if (professionNameWithHyphen.includes(' ')) {
      professionNameWithHyphen = professionNameWithHyphen.replace(/ /g, '-');
    }
    return `/${sessionStorage.getItem('parentCategory')?.replace(/ /g, '-')?.toLowerCase()}/${professionNameWithHyphen?.toLowerCase()}/${sessionStorage.getItem('locality')?.replace(/ /g, '-').toLowerCase()}`;
  }

  handleClick(event: MouseEvent, d: any): void {
    if (event.button === 0) {
      event.preventDefault(); 
      this.sendChildCategory(d);
    }
  }

  sendChildCategory(d: any): void {
    let searchData = {
      "filterName": d.category,
      "categoryid": d.id,
      "toCorrdinates": sessionStorage.getItem('latlng'),
      "localAddress": sessionStorage.getItem('location'),
      "filterDistance": Constants.DefaultRangeDistance
    }
    sessionStorage.setItem('searchData', JSON.stringify(searchData));
    let professionNameWithHyphen = d.category;
    if (professionNameWithHyphen.includes(' ')) {
      professionNameWithHyphen = professionNameWithHyphen.replace(/ /g, '-');
    }

    let locality = sessionStorage.getItem('locality');

    this.route.navigate([`/${sessionStorage.getItem('parentCategory')?.replace(/ /g, '-')?.toLowerCase()}/${professionNameWithHyphen?.toLowerCase()}/${sessionStorage.getItem('locality')?.replace(/ /g, '-').toLowerCase()}`]).then(() => {
      window.location.reload();
    });
  }
}