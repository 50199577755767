<div id="alluser" class="modal" style="display: block;" *ngIf="item=='visible'">

    <form class="modal-content" action="/action_page.php">
        <div class="container">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel" style="margin-left: auto;">Confirm</h5>
                <button type="button" id="dismissModal" onclick="document.getElementById('alluser').style.display='none'" 
                class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="cancel()"></button>
            </div>
                <!-- <h5>Confirmation <span onclick="document.getElementById('alluser').style.display='none'" class="close"
                        title="Close Modal" (click)="cancel()">×</span></h5> -->
            <div style="padding-top: 15px;
            padding-bottom: 15px;">
                <p>Are you sure, you want to send notification </p>
                <p>to everyone?</p>
            </div>
            <div class="modal-footer">
                <button type="button" onclick="document.getElementById('alluser').style.display='none'"
                    (click)="cancel()" class="btn btn-danger footer-button ml-auto"
                    data-bs-dismiss="modal" style="margin-top: 15px;">Cancel</button>
                <button type="button" class="btn btn-primary footer-button mr-auto" (click)="ok()" style="margin-top: 15px;">Ok</button>
            </div>
            <!-- <div class="clearfix">
                <button type="button" onclick="document.getElementById('alluser').style.display='none'"
                    class="cancelbtn modalbutton" (click)="cancel()">Cancel</button>
                <button type="button" (click)="ok()" class="deletebtn modalbutton btn-blue">Ok</button>
            </div> -->
        </div>
    </form>
</div>