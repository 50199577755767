export const environment = {
  production:true, 
  apiUrl: "https://toskie-prod-api.azurewebsites.net/ToskieService.svc",
  webUrl: "https://www.toskie.com",
  sitemapUrl: "https://www.toskie.com/sitemap/",
  firebaseConfig : {
  apiKey: "AIzaSyCLq_1WgnuRM_ivgNoEs3PE8EsEDgkuohA",
  authDomain: "toskie-app.firebaseapp.com",
  projectId: "toskie-app",
  storageBucket: "toskie-app.appspot.com",
  messagingSenderId: "123350701056",
  appId: "1:123350701056:web:8e43dc4d233e1244da34e5",
  measurementId: "G-3Z0ZNNXTHQ",
},
  originUrl: "https://www.toskie.com",
  // https://live.api.toskie.com/ToskieService.svc
};
