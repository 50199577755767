<div class="" style="background: #f0f0f0;">
    <nav aria-label="breadcrumb" class="mlft-6">
        <app-bread-crumb [breadpath1]="breadData1" [breadpath2]="breadData2"
            [breadroute1]="breadRouteData1"></app-bread-crumb>

    </nav>
  
<!-- <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
        <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
        <li class="breadcrumb-item"><a>Expert Profile</a></li>
        <li class="breadcrumb-item active" aria-current="page">Expert Rating</li>
    </ol>
</nav> -->


<div class="profile-content">
    <div class="container-connecting-dots">
        <div class="profile-section">
            <div class="row">
                <div class=" col-md-7 ">
                    <div class="row">
                        <div class="col-4 user-profile profile-jhone">
                            <img [src]="providerInfo.profileImageUrl" class="ms-3 img-responsive user-img" alt="Provider Image" title="Provider Image"
                            onerror="this.src='assets/images/person.jpg';">
                        </div>
                        <div class="col-8 rating-box">
                            <h1 class="mt-0 user-rating-title">Please rate {{providerInfo.fname}}
                                {{providerInfo.lname}}</h1>
                            <p class="user-dsc">Your Feedback is valuable to enhance our services</p>
                        </div>

                    </div>

                    <!-- <div class="user-profile profile-jhone mb-3">
                        <div class="rating-box"> -->
                            <div class="Profile-rate-card">
                                <div class="row">
                                    <div class="col">
                                        <span class="quality-work">
                                            Quality of work
                                        </span>
                                    </div>
                                    <div class="col">
                                        <div class="profile-rating-list">
                                            <a class="star" *ngFor="let item of starQualityList; let i=index "
                                                (click)="setQualityStar(i)">
                                                <i class="fa"
                                                    [ngClass]="{'fa-star-o emoji':item,'fa-star profile-rating-list-active':!item}"></i>
                                            </a>
                                        </div>
                                    </div>

                                </div>


                            </div>
                            <div class="Profile-rate-card">

                                <div class="row">
                                    <div class="col">
                                        <span class="quality-work">
                                            Reliable
                                        </span>
                                    </div>
                                    <div class="col">
                                        <div class="profile-rating-list">
                                            <a class="star" *ngFor="let item of starReliableList; let i=index"
                                                (click)="setReliableStar(i)">
                                                <i class="fa"
                                                    [ngClass]="{'fa-star-o emoji':item,'fa-star profile-rating-list-active':!item}"></i>
                                            </a>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        <!-- </div>
                    </div> -->
                    <div class="text-end" *ngIf="submitted && (Qrating==0 || Rrating==0)">
                        <label>Please Enter valid inputs</label>
                    </div>
                    <div class="review-box">
                        <span class="r-ct">Please leave a comment</span>
                        <textarea class="text-area-con" [(ngModel)]="review"></textarea>

                    </div>

                    <div class="text-end">
                        <button type="button" (click)="submitRatingAndReview(providerInfo.memberId)"
                            class="btn-submit">Submit</button>
                    </div>
                </div>
                <div class=" col-md-5">
                    <div class="profile-reviews">
                        <h2 class="reviews-title">Customer reviews</h2>
                        <div *ngIf="ratingAndReview.length>0">
                            <div *ngFor="let r of ratingAndReview">
                                <div class="reviews">
                                    <div class="row">
                                        <div class="col-2 customer-review-img">
                                            <img [src]="r.profileImageUrl" class="imagedimms" onerror="this.src='assets/images/person.jpg';" alt="profile image" title="profile image">

                                        </div>
                                        <div class="col-10">
                                            <h4 class="title-customer">{{r.userName | titlecase}}
                                                <span class="lh-s">{{r.duration}}</span>
                                            </h4>
                                            <ng-template #t let-fill="fill">
                                                <span class="fetchstar" [class.full]="fill === 100">
                                                    <span class="half" [style.width.%]="fill">&#9733;</span>&#9733;
                                                </span>
                                            </ng-template>
        
                                            <ngb-rating [(rate)]="r.Average" [starTemplate]="t" [readonly]="true" max="5">
                                            </ngb-rating>
                                            <p class="text-customer">{{r.comment}}</p>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="g-flex position-relative"> -->
                                <!-- <span class="customer-review-img">
                                    <img [src]="r.profileImageUrl" width="70" height="70" onerror="this.src='assets/images/person.jpg';"></span>
                                <div>
                                    <h4 class="title-customer">{{r.userName | titlecase}}
                                        <span class="lh-s">{{r.duration}}</span>
                                    </h4> -->
                                    
                                <!-- </div> -->
                                <!-- </div> -->
                            </div>
                        </div>
                        <div *ngIf="ratingAndReview.length==0">
                            <h3>No Reviews found!</h3>
                        </div>




                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>
<div id="id01" class="modal" [ngStyle]="{'display':display}">

    <form class="modal-content" action="/action_page.php">
        <div class="container">

            <p class="paragraph">Please sign in to reach the Service Provider</p>

            <div class="clearfix">
                <button type="button" (click)="goToHome()" class="modalbutton">Ok</button>
            </div>
        </div>
    </form>
</div>