<div class="container-fluid">



    <div class="privacy-box">
    
    <h3 class="privacy-sub-title">Refund Policy :</h3>
        <p>Welcome to Toskie, developed by Toskie India Private Limited. Our Refund Policy is designed to ensure your satisfaction with our services.</p>
         
        <p><b>Paid Subscriptions: </b>Professionals subscribing to our premium services benefit from increased visibility and advanced features. If, within the first 7 days of your subscription, you find it doesn't meet your expectations, contact our support team for a hassle-free refund.</p>   
        <p><b> Generation Services:</b> Our lead generation services provide professionals with genuine leads within the specified radius. If you encounter issues with lead quality, our support team is ready to investigate and address your concerns promptly.</p>
    
    <h3 class="privacy-sub-title">Return Policy:</h3>
        <p>Toskie offers a seamless experience for users and professionals. In the event you encounter any issues with the services provided, please refer to our Return Policy.</p>
         
        <p><b>Service Dissatisfaction:  </b>If you are dissatisfied with the services of a professional you connected with through Toskie, please contact our support team. We'll work to understand the issue and assist you in finding a satisfactory resolution.</p>   
        <p><b>Professional Disputes: </b> In case of disputes between users and professionals, Toskie will act as a mediator to ensure fair resolution and maintain the integrity of our community.</p>
    
    <h3 class="privacy-sub-title">Shipping Policy:</h3>
        <p>While Toskie primarily provides a digital platform, our Shipping Policy outlines how we handle any physical goods or materials associated with our services.</p>
         
        <p><b>Digital Services:  </b>Toskie primarily offers digital services, and no physical shipping is involved. Any transactions for physical goods should be handled directly between the user and the professional.</p>   
        <p><b>Third-Party Services: </b> For professionals offering physical goods or services that involve shipping, users should refer to the professional's individual terms and conditions. Toskie is not responsible for third-party transactions.</p>
    
    <h3 class="privacy-sub-title">Terms & Conditions:</h3>
        <p>By using the Toskie app, you agree to adhere to the following terms and conditions established by Toskie India Private Limited:</p>
        <div style="padding-left: 30px;">
            <h3 class="privacy-sub-title">1.User Registration:</h3>
            <ul class="privacy-list">
                <li>
                    Users and professionals must provide accurate and complete information during the registration process.
                </li>
                <li>Toskie reserves the right to suspend or terminate accounts with misleading or false information.</li>
            </ul>
            <h3 class="privacy-sub-title">2.Service Usage:</h3>
            <ul class="privacy-list">
                <li>
                    Users can seamlessly search for and contact professionals within a 25 km radius.
                </li>
                <li>Professionals opting for paid subscriptions are subject to the terms outlined in the subscription agreement.</li>
            </ul>
            <h3 class="privacy-sub-title">3.Refund Policy:</h3>
            <ul class="privacy-list">
                <li>
                    Refunds for paid subscriptions are available within 07 days of the subscription start date.
                </li>
                <li>Refunds related to lead generation services are subject to investigation and addressed on a case-by-case basis.</li>
            </ul>
            <h3 class="privacy-sub-title">4.Return Policy:</h3>
            <ul class="privacy-list">
                <li>
                    Users dissatisfied with professional services can contact our support team for assistance in finding a resolution.
                </li>
                <li>Toskie acts as a mediator in disputes between users and professionals.</li>
            </ul>
            <h3 class="privacy-sub-title">5.Shipping Policy:</h3>
            <ul class="privacy-list">
                <li>
                    Toskie primarily offers digital services, and users should refer to the professional's terms for any physical goods or services that involve shipping.
                </li>
                <li>Toskie is not responsible for third-party transactions</li>
            </ul>
            <h3 class="privacy-sub-title">6.Privacy Policy:</h3>
            <ul class="privacy-list">
                <li>
                    Toskie prioritizes user privacy, utilizing collected information solely for app functionality and service improvement
                </li>
            </ul>
            <h3 class="privacy-sub-title">7.Termination of Services:</h3>
            <ul class="privacy-list">
                <li>
                    Toskie reserves the right to suspend or terminate services for users and professionals violating our terms and conditions.
                </li>
            </ul>
            <h3 class="privacy-sub-title">8.Modification of Terms:</h3>
            <ul class="privacy-list">
                <li>
                    Toskie India Private Limited retains the right to update or modify these terms at any time. Users will be notified of any significant changes.
                </li>
            </ul>
        </div> 
        <p>By using Toskie, you acknowledge and agree to these terms and conditions. These terms are subject to periodic review and may be updated without prior notice.</p>

        <h3 class="privacy-sub-title">About Us</h3>
        <p>Welcome to Toskie, the revolutionary neighborhood professional search engine app developed by Toskie India Private Limited. At Toskie, we are driven by a vision to empower communities by connecting skilled professionals with individuals and businesses in need, all within a convenient 25 km radius.</p>
         
        <p><b>Our Mission: </b>We are committed to fostering local economies, promoting entrepreneurship, and simplifying the way people access services in their own neighborhoods.</p>   
        <p><b> How Toskie Works:</b> Toskie provides a platform for individuals and businesses to showcase their skills and highlights. Whether you're a plumber, electrician, tutor, or any other professional, Toskie makes it easy for users to discover and directly contact you for the services they require. Our user-friendly interface and advanced search capabilities ensure a seamless experience for both professionals and users.</p>
        <p><b> Community-Centric Approach:</b> Toskie is more than just an app; it's a community-driven initiative. We believe in the strength of local connections and the positive impact that skilled professionals can have on the neighborhoods they serve.</p>
</div>
</div>