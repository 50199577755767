import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService } from 'src/app/shared/alert/alert.service';
import { Role } from 'src/app/shared/enums/role.enum';
import { ServerApisService } from 'src/app/shared/services/server-apis.service';
import { Constants, navConstants } from 'src/app/shared/constants/constant';
import { RouteMakingService } from 'src/app/shared/services/route-making.service';

@Component({
  selector: 'app-child-profession-professions',
  templateUrl: './child-profession-professions.component.html',
  styleUrls: ['./child-profession-professions.component.css']
})
export class ChildProfessionProfessionsComponent {
  placeholderText = 'Search With Child Profession';
  isUpdate: boolean = false;
  isdisplay: boolean = false;
  mainProfessionSort: string = "";
  childProfessionSort: string = "";
  isPopup: boolean = false;
  p = 1;
  id: number = 0;
  page: any = "assignService";
  modal: string = 'add';
  allChildProfessionCategory: any;
  tempChildProfessionsCategory: any;
  selectedChildProfesionId: any;
  childProfessionName: any;
  childProfessionNameOld: any;
  metaTitle: any = '';
  metaDescription: any = '';
  metaKeywords: any = '';
  updatedmetaTitle: any = '';
  updatedmetaDescription: any = '';
  updatedmetaKeywords: any = '';
  adminApprovalCategories: any;
  tempAdminApprovalCategories: any;
  selectedMainProfession: any;
  selectedMainProfessionId: any;
  professionName: any;
  mainProfessions: any;
  tempMainProfession: any;

  constructor(private router: Router, private routeMakingService: RouteMakingService, private _apiService: ServerApisService, protected alertService: AlertService, private spinner: NgxSpinnerService,) { }

  ngOnInit(): void {
    const user = sessionStorage.getItem('user');
    if (user != null) {

      let data = JSON.parse(user);
      if (data.roleId === Role.Admin || data.roleId === Role.SubAdmin || Role.SeoTeam) {
        this.getAllChildCategories();
        this.getAllAdminApprovalCategory();
        this.fetchMainProfession();
        let path = window.location.href.split('profession/')
      } else {
        this.router.navigate([""]);
      }
    } else {
      this.router.navigate([this.routeMakingService.getNavigateUrl([Constants.Login])]);
    }

  }

  getAllAdminApprovalCategory() {
    this._apiService.GetAdminApprovalCategories('pending').subscribe((res: any) => {
      this.adminApprovalCategories = res.FetchCategoriesAdminApprovalResult.resultFetchProfessionAdminApproval;
      this.tempAdminApprovalCategories = this.adminApprovalCategories;
    })
  }

  searchMainCategory(event: any) {
    {
       {
        this.allChildProfessionCategory = this.tempChildProfessionsCategory;
        if (event.target.value != "" && event.target.value != null && event.target.value != undefined) {
          var result = this.allChildProfessionCategory.filter((x: any) => x.childProfessionName.toLowerCase().includes(event.target.value.toLowerCase()))
          this.allChildProfessionCategory = result;
        } else {
          this.allChildProfessionCategory = this.tempChildProfessionsCategory;
        }
      }

    }
  }

  addChildPop() {

  }

  saveChildProfession(): void {
    if (!this.childProfessionName) {
      this.alertService.error('Please Enter Child Profession First');
      return;
    }

    if (!this.selectedMainProfessionId) {
      this.alertService.error('Please Select Main Profession First');
      return;
    }

    let payload = {
      "mainProfessionId": this.selectedMainProfessionId,
      "childProfessionName": this.childProfessionName,
      "approvedBy": sessionStorage.getItem('memberId'),
      "title": this.metaTitle,
      "description": this.metaDescription,
      "keywords": this.metaKeywords
    };

    this._apiService.InsertChildProfession(payload).subscribe((res: any) => {
      if (res.status === 200) {
        if (res.result == 'Child profession already exixts !') {
          this.alertService.error(res.result);
        } else {
          this.alertService.success(res.result);
        }
        document.getElementById('addChildModal').style.display = 'none';
      } else {
        this.alertService.error(res.result);
      }
    });
  }
  updateChildProfessionPop(data) {
    this.childProfessionNameOld = data.childProfessionName;
    this.selectedMainProfessionId = data.mainProfessionId;
    this.selectedChildProfesionId = data.childProfessionId;
    this.childProfessionName = data.childProfessionName;
    this.professionName = data.mainProfessionName;
    this.updatedmetaTitle = data.title;
    this.updatedmetaDescription = data.description;
    this.updatedmetaKeywords = data.keywords;
  }

  selectedMainProfessionForChildProfession() {
    // this.selectedMainProfession = this.mainProfessions.filter(x => x.mainProfessionId == this.mainServiceId)[0].nearByServiceCategory;
  }

  fetchMainProfession() {
    this._apiService.GetMainProfession().subscribe((res: any) => {
      this.mainProfessions = res.FetchAllMainProfessionsResult.resultFetchProfessionAdminApproval;
      this.tempMainProfession = this.mainProfessions;
    })
  }

   getAllChildCategories() {
    this._apiService.FetchAllChildProfessions().subscribe((res: any) => {
      this.allChildProfessionCategory = res.FetchAllChildProfessionsResult.resultFetchProfessionAdminApproval;
      this.tempChildProfessionsCategory = this.allChildProfessionCategory;
    })
  }


  updateChildProfession(): void {
    if (!this.childProfessionName) {
      this.alertService.error('Please enter the value to update!');
      return;
    }

    if (!this.selectedMainProfessionId) {
      this.alertService.error('Please Select Main Profession First!');
      return;
    }

    const reqBody = {
      "childProfessionId": this.selectedChildProfesionId,
      "childProfessionName": this.childProfessionNameOld,
      "childProfessionNameNew": this.childProfessionName == this.childProfessionNameOld ? '' : this.childProfessionName,
      "approvedBy": sessionStorage.getItem('memberId'),
      "title": this.updatedmetaTitle,
      "description": this.updatedmetaDescription,
      "keywords": this.updatedmetaKeywords,
      "mainProfessionId": this.selectedMainProfessionId
    };

    this._apiService.updateChildProfession(reqBody).subscribe((res: any) => {
      if (res.status === 200) {
        this.alertService.success('Update request submitted for admin approval!');
      } else {
        this.alertService.error(res.result);
      }

    });
  }
  pageChanged(event) {

    this.p = event;
  }

  childProfessionOnSort() {
    if (this.childProfessionSort === "") {
      this.childProfessionSort = "up";
      this.allChildProfessionCategory.sort((a, b) => a.childProfessionName.localeCompare(b.childProfessionName));
    } else if (this.childProfessionSort === "up") {
      this.childProfessionSort = "down";
      this.allChildProfessionCategory.sort((a, b) => b.childProfessionName.localeCompare(a.childProfessionName));
    } else if (this.childProfessionSort === "down") {
      this.childProfessionSort = "";
      this.allChildProfessionCategory.sort((a, b) => a.childProfessionId - b.childProfessionId);
    }
  }

  deleteChildProfessionPop(data) {
    this.selectedMainProfession = data.mainProfessionName;
    this.selectedMainProfessionId = data.mainProfessionId;
    this.selectedChildProfesionId = data.childProfessionId;
    this.childProfessionName = data.childProfessionName;
    this.professionName = data.mainProfessionName;
  }

  deleteChildProfession(Id) {
    let payload = {
      "childProfessionId": Id,
      "approvedBy": sessionStorage.getItem('memberId')
    }
    this._apiService.deleteChildProfession(payload).subscribe((res: any) => {
      if (res.status === 200) {
        this.alertService.success('Delete request submitted for admin approval!');
        this.getAllChildCategories()
      } else {
        this.alertService.error(res.result);
      }
    })
  }

 
}
