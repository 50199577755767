import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AlertService } from 'src/app/shared/alert/alert.service';
import { Router } from '@angular/router';
import { ServerApisService } from 'src/app/shared/services/server-apis.service';
import { Constants,navConstants } from 'src/app/shared/constants/constant';
import { RouteMakingService } from 'src/app/shared/services/route-making.service';

@Component({
  selector: 'app-near-by-sub-caregory-creation-service-management',
  templateUrl: './near-by-sub-caregory-creation-service-management.component.html',
  styleUrls: ['./near-by-sub-caregory-creation-service-management.component.css']
})
export class NearBySubCaregoryCreationServiceManagementComponent {
  allNearBySubServicesTemp: any = [];
  allNearBySubServices: any = [];
  allNearByServicesTemp: any = [];
  allNearByServices: any = [];
  newServiceCategory: string = '';
  serviceIdToDelete: number;
  newSubServiceKeyword: string = '';
  subServiceIdToDelete: number;
  newSubServiceUrl: string = '';
  mainServiceId:number;
  page = 'category';
  p = 1;
  memberID: number = Number(sessionStorage.getItem('memberId'));
  token: string = sessionStorage.getItem('token');
  selectedServiceName: string = '';
  selectedSubServiceKeyword: string = '';
  selectedCategoryId: number = 0;
  selectedSubCategoryId: number = 0;
 subModal = 'subUpdateModal'
 placeholderText = 'Search by service sub category';
  
 constructor(private _apiService: ServerApisService,private router: Router,private routeMakingService:RouteMakingService, private _alertService: AlertService,
    private cdr: ChangeDetectorRef) { 
     this.fetchAllNearBySubServices();
    }

   addSubpop(){
      this.subModal = 'subAddModal';
      this.newSubServiceKeyword = '';
      this.newSubServiceUrl = '';
      this.selectedCategoryId = 0;
    }
  
    updateSubpop(data){
      this.selectedSubServiceKeyword = data.nearByServiceSubCategoryKeyword;
      this.selectedSubCategoryId = data.nearByServiceSubCategoryId;
      this.newSubServiceUrl = data.nearByServiceSubCategoryUrl;
      this.newSubServiceKeyword = data.nearByServiceSubCategoryKeyword;
      this.selectedCategoryId = data.nearByServiceCategoryId;
    }
    searchSubCategory(event: any) {
      if (event.target.value != '' && event.target.value != undefined) {
        var result = this.allNearBySubServices.filter((x: any) => x.nearByServiceSubCategoryKeyword.toString().toLowerCase().includes(event.target.value.toLowerCase()));
        this.allNearBySubServices = result;
      } else {
        this.allNearBySubServices = this.allNearBySubServicesTemp;
      }
    }
    fetchAllNearBySubServices(): void {
      this._apiService.GetAllNearBySubServices().subscribe(res => {
        let result: any = res;
        if (result.FetchAllNearBySubCategoryResult.status == 200) {
          this.allNearBySubServices = result.FetchAllNearBySubCategoryResult.resultFetchNearByServicesCategory;
          this.allNearBySubServicesTemp = result.FetchAllNearBySubCategoryResult.resultFetchNearByServicesCategory;
        } 
      });
    }
  
    selectedSubService(subService): void {
      this.selectedSubServiceKeyword = subService.nearByServiceSubCategoryKeyword;
      this.selectedSubCategoryId = subService.nearByServiceSubCategoryId;
    }
    selectedServiceForSubService(){
      this.selectedServiceName = this.allNearByServices.filter(x => x.nearByServiceCategoryId == this.mainServiceId)[0].nearByServiceCategory;
    }
    addNearBySubService(): void {
      if (!this.newSubServiceKeyword.trim()) {
        this._alertService.error("Sub-Service Keyword is required");
        return;
      }
  
      const requestBody = {
        nearByServiceCategoryId: this.selectedCategoryId, 
        nearByServiceSubCategoryKeyword: this.newSubServiceKeyword,
        nearByServiceSubCategoryUrl: this.newSubServiceUrl,
        nearByServiceSubCategoryId: 0,
        createdBy: this.memberID
      };
  
      this._apiService.InsertUpdateNearBySubCategory(requestBody).subscribe(res => {
        let result: any = res;
        if (result.status == 200) {
          if(result.result == "Near by service sub category already exists"){
            this._alertService.error(result.result);
          } else {
            this._alertService.success(result.result)
          }
          this.fetchAllNearBySubServices();
        } else {
          this._alertService.error("Error occurs while adding near by sub-service");
        }
      });
    }
  
    updateNearBySubService(): void {
      if (!this.newSubServiceKeyword.trim()) {
        this._alertService.error("Sub-Service Keyword is required");
        return;
      }
  
      const requestBody = {
        nearByServiceCategoryId: this.selectedCategoryId, 
        nearByServiceSubCategoryKeyword: this.newSubServiceKeyword,
        nearByServiceSubCategoryUrl: this.newSubServiceUrl,
        nearByServiceSubCategoryId: this.selectedSubCategoryId,
        createdBy: this.memberID
      };
  
      this._apiService.InsertUpdateNearBySubCategory(requestBody).subscribe(res => {
        let result: any = res;
        if (result.status == 200) {
          if(result.result == "Near by service sub category already exists"){
            this._alertService.error(result.result);
          } else {
            this._alertService.success(result.result)
          }
          this.fetchAllNearBySubServices();
        } else {
          this._alertService.error("Error occurs while adding near by sub-service");
        }
      });
    }
  
    deleteNearBySubService(categoryId): void {
      if (!this.selectedSubCategoryId) {
        this._alertService.error("Please provide a valid Sub-Service ID");
        return;
      }
  
      const deleteParams = {
        nearByServiceSubCategoryId: categoryId,
        deletedBy: this.memberID
      };
  
      this._apiService.DeleteNearBySubCategory(deleteParams).subscribe(res => {
        let result: any = res;
        if (result.DeleteNearBySubCategoryResult.status == 200) {
          this._alertService.success(result.DeleteNearBySubCategoryResult.result);
          this.fetchAllNearBySubServices();
        } 
      });
    }
    isEditIndex:number;
    pageChanged(event: any) {
      this.p = event;
      this.isEditIndex=-1;
    }
  
}
