import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService } from 'src/app/shared/alert/alert.service';
import { Role } from 'src/app/shared/enums/role.enum';
import { ServerApisService } from 'src/app/shared/services/server-apis.service';
import { Constants, navConstants } from 'src/app/shared/constants/constant';
import { RouteMakingService } from '../../shared/services/route-making.service';
@Component({
  selector: 'app-admin-approvals',
  templateUrl: './admin-approvals.component.html',
  styleUrls: ['./admin-approvals.component.css']
})
export class AdminApprovalsComponent {
  adminPanel: string = Constants.adminPanel;
  adminapproval: string = Constants.adminApproval;
  professionNav: string = navConstants.mainProfessionApproval;
  adminPanelRoute: string = Constants.adminPanelRoute;
  adminAppprovalTitle: string = Constants.mainProfessionTitle;
  isCard: any = true;
  lstallcategories: any = [];
  lstalltempcategories: any = [];
  page = 'Main Profession Approval';
  mainPlaceholder = 'Search for Main Profession';
  childPlaceholder = 'Search for child Profession';
  userPlaceholder = 'Search for User';
  mainProfessions: any;
  tempMainProfession: any;
  searchtext: string = '';
  searchtextApproved: string = '';
  searchtextRejected: string = '';
  selectedMainProfession: any = '';
  selectedMainProfessionId: any;
  adminApprovalCategories: any = [];
  tempAdminApprovalCategories: any = [];
  allChildProfessionCategory: any;
  tempChildProfessionsCategory: any;
  selectedChildProfesionId: any;
  childProfessionName: any;
  childProfessionNameNew: any;
  ProfessionNameNew: any;
  professionName: string = '';
  adminApprovalsubCategories: any = [];
  tempAdminApprovalsubCategories: any = [];
  usersList: any = [];
  isEditIndex: number;
  p: number = 1;
  approvedCategories: any = [];
  rejectedCategories: any = [];
  approvedsubCategories: any = [];
  subRejectCategories: any = [];
  pendingProfessions: any = [];
  tempPendingProfession: any = [];
  tempApprovedCategory: any = [];
  temprejectedCategory: any = [];
  tempapprovedsubCategory: any = [];
  tempsubRejectCategory: any = [];
  showDeleteModal: boolean = false;
  currentData: any;
  currentChildData: any;
  selectedchildProfession: any;
  showDeleteModalchild: boolean = false;
  selectedchildProfessionId: any;
  pendingSubProfession: any;
  tempPendingSubProfession: any;
  currPagePendingMain: number;
  currPageApprovedMain: number;
  selectedOption: string = '';
  selectedAction: string;
  showBreadcrumb3: boolean = false;
  showDeleteModalUser: boolean;
  tempUserList: any;
  searchTextUser: any = '';
  selectedTab: string = 'mainPending';

  constructor(private routeMakingService: RouteMakingService, private router: Router, private cdr: ChangeDetectorRef, private _apiService: ServerApisService, protected alertService: AlertService, private spinner: NgxSpinnerService,) { }

  ngOnInit(): void {
    this.fetchMainProfession();
    this.getAllChildCategories();
    this.getAllAdminApprovalsubCategory('pending');
    this.getAllAdminApprovalCategory('pending');
    this.getAllUserApprovalCategory('pending');
    this.selectedTab = 'mainPending';
    localStorage.removeItem('selectedTab');
    let path = window.location.href.split('admin-approvals/')
    if (path[1] == navConstants.mainProfessionApproval) {
      this.searchtab(Constants.mainProfessionTitle)
    } else if (path[1] == navConstants.childProfessionApproval) {
      this.searchtab(Constants.childProfessionTitle)
    } else if (path[1] == navConstants.userRolesApproval) {
      this.searchtab(Constants.userRolesTitle)
    }
  }
clearFields() {
    this.searchtextApproved = '';
    this.searchtext = '';
    this.searchtextRejected = ''
  }
 searchtab(pageName) {
    if (pageName === 'Main Profession Approval') {
      this.clearFields();
      this.getAllAdminApprovalCategory('pending');
      this.adminAppprovalTitle = Constants.mainProfessionTitle;
      this.professionNav = navConstants.mainProfessionApproval;
      let params = [Constants.adminPanelRoute, Constants.adminApproval, navConstants.mainProfessionApproval];
      let routingUrl = this.routeMakingService.getNavigateUrl(params);
      this.router.navigate([routingUrl]);
    } else if (pageName === 'Child Profession Approval') {
      this.clearFields();
      this.getAllAdminApprovalsubCategory('pending');
      this.adminAppprovalTitle = Constants.childProfessionTitle;
      this.professionNav = navConstants.childProfessionApproval;
      let params = [Constants.adminPanelRoute, Constants.adminApproval, navConstants.childProfessionApproval];
      let routingUrl = this.routeMakingService.getNavigateUrl(params);
      this.router.navigate([routingUrl]);
    } else if (pageName === 'User Roles Approval') {
      this.clearFields();
      this.getAllUserApprovalCategory('pending');
      this.adminAppprovalTitle = Constants.userRolesTitle;
      this.professionNav = navConstants.userRolesApproval;
      let params = [Constants.adminPanelRoute, Constants.adminApproval, navConstants.userRolesApproval];
      let routingUrl = this.routeMakingService.getNavigateUrl(params);
      this.router.navigate([routingUrl]);
    }
    this.page = pageName;
  }
  //   toggleMetaInfo(data: any) {
  //     data.showMetaInfo = !data.showMetaInfo;
  // }

  fetchMainProfession() {
    // this._apiService.GetMainProfession().subscribe((res: any) => {
    //   this.mainProfessions = res.FetchAllMainProfessionsResult.resultFetchProfessionAdminApproval;
    //   this.tempMainProfession = this.mainProfessions;
    // })
  }
  getAllChildCategories() {
    // this._apiService.FetchAllChildProfessions().subscribe((res: any) => {
    //   this.allChildProfessionCategory = res.FetchAllChildProfessionsResult.resultFetchProfessionAdminApproval;
    //   this.tempChildProfessionsCategory = this.allChildProfessionCategory;
    // })
  }
  getAllAdminApprovalCategory(type: string) {
    // this.spinner.show();
    // this._apiService.GetAdminApprovalCategories(type).subscribe(
    //   (res: any) => {
    //     this.spinner.hide();
    //     this.adminApprovalCategories = res.FetchCategoriesAdminApprovalResult.resultFetchProfessionAdminApproval;
    //     this.tempAdminApprovalCategories = this.adminApprovalCategories;
    //   }
    // );
  }
  getAllAdminApprovalsubCategory(type) {
    // this.spinner.show();
    // this._apiService.GetAdminApprovalsubCategories(type).subscribe((res: any) => {
    //   this.spinner.hide();
    //   this.adminApprovalsubCategories = res.FetchChildCategoriesAdminApprovalResult.resultFetchProfessionAdminApproval;
    //   this.tempAdminApprovalsubCategories = this.adminApprovalsubCategories;
    // });
  }
  getAllUserApprovalCategory(type: string) {
    // this.spinner.show();
    // this._apiService.FetchUserRolesAdminApproval(type).subscribe((res: any) => {
    //   this.spinner.hide();
    //   this.usersList = res.FetchUserRolesAdminApprovalResult.resultUserRoles;
    //   this.tempUserList = this.usersList;
    // });
  }

  menu() {
    if (this.isCard) {
      this.isCard = false;
    } else {
      this.isCard = true;
    }
  }

  onMouseEnter() {
    this.isCard = true;
  }

  onMouseLeave() {
    this.isCard = false;
  }
  getDataFromApi(tab: string) {
    this.selectedTab = tab;

    switch (tab) {
      case 'mainPending':
        this.getAllAdminApprovalCategory('pending');
        break;
      case 'mainApproved':
        this.getAllAdminApprovalCategory('approved');
        break;
      case 'mainRejected':
        this.getAllAdminApprovalCategory('rejected');
        break;
      case 'childPending':
        this.getAllAdminApprovalsubCategory('pending');
        break;
      case 'childApproved':
        this.getAllAdminApprovalsubCategory('approved');
        break;
      case 'childRejected':
        this.getAllAdminApprovalsubCategory('rejected');
        break;
      case 'userPending':
        this.getAllUserApprovalCategory('pending');
        break;
      case 'userApproved':
        this.getAllUserApprovalCategory('approved');
        break;
      case 'userRejected':
        this.getAllUserApprovalCategory('rejected');
        break;
      default:
        break;
    }
  }

}