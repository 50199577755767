<div (click)="closeRoleDropdown($event)">
    <div class="tasks_table1">
        <div class="content">
            <div class="row">
                <mat-form-field class="select-option">
                    <select [(ngModel)]="selectedOption" (change)="resetOptions()" matNativeControl required
                        style="height: 36px;">
                        <option value="name" selected>Name</option>
                        <option value="memberId">Member Id</option>
                        <option value="uniqueId">Unique Id</option>
                    </select>
                </mat-form-field>
                <div class="search-box">
                    <div class="search-section" *ngIf="selectedOption == 'memberId'">
                        <label>
                            <span>
                                <input type="text" id="search" class="custom-focus-input" autocomplete="off"
                                    [(ngModel)]="searchedMemberID" placeholder="Search With MemberId"
                                    (input)="searchByMemberId($event)">
                            </span>
                        </label>
                    </div>

                    <div class="search-section" *ngIf="selectedOption == 'name'">
                        <label>
                            <span>
                                <input type="text" id="search" class="custom-focus-input" autocomplete="off"
                                    [(ngModel)]="searchedName" placeholder="Search With Name"
                                    (input)="searchByName($event)">
                            </span>
                        </label>
                    </div>

                    <div class="search-section" *ngIf="selectedOption == 'uniqueId'">
                        <label>
                            <span>
                                <input type="text" id="search" class="custom-focus-input" autocomplete="off"
                                    [(ngModel)]="searchedUniqueID" placeholder="Search With UniqueId"
                                    (input)="searchByUniqueId($event)">
                            </span>
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>

<table class="table table-striped">
    <thead class="tablehd-sec">
        <tr>
            <th style="width: 20%;">Member Id</th>
            <th style="width: 20%;">Unique Id</th>
            <th style="width: 20%;">Name</th>
            <th style="width: 30%;">Email</th>
            <th style="width: 10%;">Action</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let d of profileVideoUrl | paginate: { itemsPerPage: 20, totalItems:recordCount, currentPage: p }; let i = index"
            class="tdata" (click)="openPopup(d)">
            <td style="width: 20%;" class="padleft-30">{{d.memberId}}</td>
            <td style="width: 20%;">{{d.UniqueId}}</td>
            <td style="width: 20%;">{{d.Name}}</td>
            <td style="width: 40%;">{{d.email}}</td>
            <td style="width: 10%;">
                <span *ngIf="!d.profileVideoUrlEdit">
                    <i class="fa fa-pencil bl" aria-hidden="true"></i>
                </span>
                <!-- <span *ngIf="d.profileVideoUrlEdit">
                    <i class="fa fa-save bl" aria-hidden="true"
                        (click)="saveData(d, 'profileVideoUrl'); $event.stopPropagation();"></i>
                </span> -->
                <!-- <span>
                    <i class="fa fa-trash-o hand dangr" aria-hidden="true"
                        (click)="deleteProfileVideoUrlPop(d); $event.stopPropagation();"></i>
                </span> -->
            </td>
        </tr>
    </tbody>
</table>

<div class="row">
    <div class="col">
        <div class="d-flex justify-content-center" *ngIf="profileVideoUrl !== null && profileVideoUrl.length > 0">
            <pagination-controls (pageChange)="pageChanged($event)"
                (pageBoundsCorrection)="pageChanged($event)"></pagination-controls>
        </div>
    </div>
</div>
<div id="videoPreviewModal" *ngIf="videoModal">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header" style="padding: 0.5rem;">
                <h5 class="modal-title">Video Preview</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" style="padding: 0.5rem;"
                    (click)="closeModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <iframe [src]="userprofileVideoUrl" width="100%" height="300" frameborder="0" allowfullscreen></iframe>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal"
                    (click)="closeModal()">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" *ngIf="showModal" id="showVideoModal" style="overflow: hidden; padding-top: 50px;">
    <div class="modal-content" style="width: 30%; margin-top: 23px;">
        <div class="modal-header" style="padding-top: 0;">
            <h4 class="modal-title text-center" id="myModalLabel" *ngIf="modal == 'showModal'">Update Video Url</h4>
            <span class="close hand" (click)="closeVideoModal()" title="Close Modal">×</span>
        </div>
        <div class="modal-body" style="padding-bottom: 0;">
            <div class="row" style="margin-bottom:15px;">
                <label style="padding: 0;">Profile Video Url<span style="color: red;">*</span></label>
                <input type="text" class="form-control" [(ngModel)]="profileVideoUrl"
                    placeholder="Update profile video url">
            </div>
            <div class="row mrt-20" style="display: flex;">
                <button class="save hand" (click)="updateProfileVideoUrl()">Update</button>
                <button class="save hand" style="background-color: #d9534f;" (click)="closeVideoModal()">Cancel</button>
            </div>
        </div>
    </div>
</div>
<!-- Popup Modal -->
<div *ngIf="showPopup" class="modal fade show d-block" tabindex="-1" role="dialog" style="background: rgba(0,0,0,0.5);">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content custom-style">
            <div class="modal-header">
                <h5 class="modal-title">Manage Profile</h5>
                <span class="close-popup" aria-hidden="true" (click)="closePop()">&times;</span>
            </div>
            <div class="modal-body">
                <form>
                    <div class="form-row">
                        <div class="form-group col-md-6" style="display: flex;">
                            <label for="profileStatus">Profile Status:</label>
                            <div class="form-check form-switch">
                                <span>
                                    <input class="form-check-input" type="checkbox" role="switch"
                                        id="flexSwitchCheckChecked" [checked]="profileStatus"
                                        (change)="updateProfile($event,'profile')">
                                    <label class="form-check-label" for="flexSwitchCheckChecked"
                                        *ngIf="profileStatus"><b>Active</b></label>
                                    <label class="form-check-label" for="flexSwitchCheckChecked"
                                        *ngIf="!profileStatus"><b>In Active</b></label>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-6">
                            <label for="memberId">Member Id:</label>
                            <span><b>{{selectedUser.memberId}}</b></span>
                        </div>
                        <div class="form-group col-md-6">
                            <label for="uniqueId">Unique Id:</label>
                            <span><b>{{selectedUser.UniqueId}}</b></span>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-6">
                            <label for="name">Name:</label>
                            <span><b>{{selectedUser.Name}}</b></span>
                        </div>
                        <div class="form-group col-md-6">
                            <label for="email">Email:</label>
                            <span><b>{{selectedUser.email}}</b></span>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-12" style="display: flex;">
                            <label for="profileVideoUrl">Profile Video Url:</label>
                            <span *ngIf="!isEditing">
                                <a href="{{selectedUser.profileVideoUrl}}" target="_blank"
                                    style="margin-right: 10px;"><b>{{selectedUser.profileVideoUrl}}</b></a>
                                <i class="fa fa-pencil" aria-hidden="true" (click)="toggleEdit()"
                                    style="color:blue"></i> &nbsp;
                                <i class="fa fa-play-circle vdupld" aria-hidden="true"
                                    *ngIf="selectedUser.profileVideoUrl"
                                    (click)="previewProfileVideo(selectedUser.profileVideoUrl)"></i>
                            </span>
                            <div *ngIf="isEditing" class="edit-icons">
                                <input type="text" [(ngModel)]="profileVideoUrlLink"
                                    [ngModelOptions]="{standalone: true}" class="form-control">
                                <i class="fa fa-save" aria-hidden="true" (click)="updateProfile($event,'videoUrl')"
                                    style="color:blue; margin-right: 10px; margin-top: 10px;"></i>
                                <i class="fa fa-times" aria-hidden="true" (click)="cancelEdit()"
                                    style="color:red; margin-top: 10px;"></i>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <!-- Button group -->
                    <div class="form-group">
                        <div class="btn-group" role="group" aria-label="Button group">
                            <button *ngFor="let button of buttonData" type="button" class="btn btn-secondary"
                                (click)="populateButtonData(button)"
                                [ngClass]="{'button-style': button.customButonIsActive == 'N'}">
                                {{ button.customButonName }}
                                <span class="separator"> | </span>
                                <i class="fa fa-times cross-icon" aria-hidden="true"
                                    (click)="deleteButton(button, $event)"></i>
                            </button>
                        </div>
                    </div>

                    <!-- Add Button form -->
                    <div class="form-row">
                        <div class="form-group col-md-12">
                            <label for="ButtonName">Button Name</label>
                            <input type="text" id="newButtonName" [(ngModel)]="newButtonName" name="newButtonName"
                                class="form-control" placeholder="Button Name">
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-12">
                            <label for="ButtonLink">Button Link</label>
                            <input type="text" id="newButtonLink" [(ngModel)]="newButtonLink" name="newButtonLink"
                                class="form-control" placeholder="Button Link">
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-6" style="display: flex;">
                            <label for="buttonStatus">Button Status:</label>
                            <div class="form-check form-switch">
                                <span>
                                    <input class="form-check-input" type="checkbox" role="switch"
                                        id="flexSwitchCheckChecked" [checked]="buttonStatus" [(ngModel)]="buttonStatus"
                                        [ngModelOptions]="{standalone: true}"
                                        [disabled]="newButtonName == '' && newButtonLink == ''">
                                    <label class="form-check-label" for="flexSwitchCheckChecked"
                                        *ngIf="buttonStatus"><b>Active</b></label>
                                    <label class="form-check-label" for="flexSwitchCheckChecked"
                                        *ngIf="!buttonStatus"><b>In Active</b></label>
                                </span>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="closePop()">Close</button>
                <button *ngIf="!updateButton" type="button" class="btn btn-primary"
                    (click)="updateProfile($event,'addButton')"
                    [disabled]="!(newButtonLink !== '' && newButtonName !== '')">Add New</button>
                <button *ngIf="updateButton" type="button" class="btn btn-primary"
                    (click)="updateProfile($event,'addButton')">Update</button>
            </div>
        </div>
    </div>
</div>
<!-- <div id="deleteVideoModal" class="modal "
        [ngStyle]="{'display': showDeleteVideoModal ? 'block' : 'none', 'overflow': 'hidden', }">
        <form class="modal-content-1" action="/action_page.php" style="width: 35%;">
            <div class="container">
                <div class="modal-header" style="padding-top: 0;">
                    <h5 class="modal-title" id="exampleModalLabel" style="margin-left: auto;">Confirm</h5>
                    <button type="button" id="dismissModal" (click)="dismissModal()" class="btn-close"
                        data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div style="padding-top: 15px; padding-bottom: 15px; text-align: center;">
                    <p>Are you sure you want to delete the video URL of the user?</p>
                    <p><strong>{{selectedRoleVideo}}</strong></p>
                </div>
                <div class="modal-footer" style="padding-bottom: 0;">
                    <button type="button" (click)="dismissModal()" class="btn btn-danger footer-button ml-auto"
                        style="width: 18%; margin-left: auto;">Cancel</button>
                    <button type="button" class="btn btn-primary footer-button mr-auto" (click)="deleteVideo()"
                        style="width: 18%; margin-right: auto;">Delete</button>
                </div>
            </div>
        </form>
    </div> -->