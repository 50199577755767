<div class="tasks_table1">
    <div class="row">
      <div class="col-md-10 search-box">
        <label style="width: 100%;"><span><input type="text" id="search"
              class="custom-focus-input" autocomplete="off" [placeholder]="placeholderText"
              style="height: 36px;border: none;width: 115%;"
              (input)="searchMainCategory($event)"></span></label>
      </div>
      <div class="col-md-2" style="padding: 8px;text-align: center;">
        <button class="btn-primary hand add" (click)="addChildPop()"
            onclick="document.getElementById('addChildModal').style.display='block'" title="Add Child Profession">
            +
        </button>
      </div>
    </div>
  </div>
  <div>
    <table class="table table-striped">
      <thead class="tablehd-sec">
        <tr>
          <th style="width: 10%;" class="padleft-30">S. NO &nbsp;<span></span></th>
          <th style="width: 20%;" (click)="childProfessionOnSort()">
            Child Profession Name
            &nbsp;<span>
              <i class="fa fa-long-arrow-up" style="margin: 0;"
                [ngClass]="childProfessionSort === 'up' ? 'black' : 'grey'"></i>
              <i class="fa fa-long-arrow-down" style="margin: 0;"
                [ngClass]="childProfessionSort === 'down' ? 'black' : 'grey'"></i>
            </span>
          </th>
          <th style="width: 20%;">Meta Title</th>
          <th style="width: 20%;">Meta Keywords</th>
          <th style="width: 20%;">Meta Description</th>
          <th style="width: 15%;">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let d of allChildProfessionCategory | paginate: { itemsPerPage: 10, currentPage: p};let i = index"
          class="tdata">
          <td style="width: 10%;padding-left: 2%;" class="padleft-30">{{ (p - 1) * 10 + i + 1 }}</td>
          <td style="width: 20%;">
            <p>{{d.childProfessionName}}</p>
          </td>
          <td style="width: 20%;">
            <p>{{d.isRejected === "No" ? d.title : ""}}</p>
          </td>
          <td style="width: 20%;">
            <p>{{d.isRejected === "No" ? d.keywords : ""}}</p>
          </td>
          <td style="width: 20%;">
            <p>{{d.isRejected === "No" ? d.description : ""}}</p>
          </td>
          <td style="width: 10%;" [title]="d.isRejected === 'Yes' ? 'Changes is rejected by admin for this profession' : 'Perform Some Action'">
            <i class="fa fa-pencil thclr hand bl" (click)="updateChildProfessionPop(d)"
              style="margin-right: 10px;"
              [ngStyle]="{'pointer-events': d.isRejected === 'Yes' ? 'none' : 'auto', 'opacity': d.isRejected === 'Yes' ? 0.5 : 1}"
              onclick="document.getElementById('updateChildModal').style.display='block'"></i>
            <i class="fa fa-trash-o hand dangr" style="font-size: 20px; margin-right: 10px;"
              (click)="deleteChildProfessionPop(d)"
              [ngStyle]="{'pointer-events': d.isRejected === 'Yes' ? 'none' : 'auto', 'opacity': d.isRejected === 'Yes' ? 0.5 : 1}"
              onclick="document.getElementById('deleteChildModal').style.display='block'"></i>
          </td>                  
        </tr>
      </tbody>
    </table>
    <div class="row">
      <div class="col">
        <div class="d-flex justify-content-center">
          <pagination-controls (pageChange)="pageChanged($event)" (pageBoundsCorrection)="pageChanged($event)">
          </pagination-controls>
        </div>
      </div>
    </div>
  </div>
  <div class="modal" id="addChildModal" style="overflow: hidden;padding-top: 50px;">
    <div class="modal-content" style="width: 60%;">
      <div class="modal-header" style="padding-top: 0;">
        <h4 class="modal-title1 text-center"  id="myModalLabel">
          Add Child Profession</h4>
        <span onclick="document.getElementById('addChildModal').style.display='none'" class="close hand"
          title="Close Modal">×</span>
      </div>
      <div class="modal-body" style="padding-bottom: 0;">
        <div class="row">
          <label style="padding: 0;">Select Main Profession<span style="color: red;">*</span></label>
          <select class="form-control" [(ngModel)]="selectedMainProfessionId"
            (change)="selectedMainProfessionForChildProfession()">
            <option value="0" disabled selected>Select Main Profession</option>
            <option *ngFor="let d of mainProfessions" [value]="d.mainProfessionId">{{ d.mainProfessionName }}</option>
          </select>
        </div>
        <div class="row" style="margin-bottom:15px;">
          <label style="padding: 0;">Child Profession<span style="color: red;">*</span></label>
          <input type="text" class="form-control" [(ngModel)]="childProfessionName"
            placeholder="Enter Child Profession">
          <label style="color: red;padding: 0;"></label>
        </div>
        <div class="row" style="margin-bottom:15px;">
          <label style="padding: 0;">Meta Title</label>
          <textarea type="text" class="form-control" [(ngModel)]="metaTitle"
            placeholder="Enter meta title" ></textarea>
          <label style="color: red;padding: 0;"></label>
        </div>
        <div class="row" style="margin-bottom:15px;">
          <label style="padding: 0;">Meta Keywords</label>
          <textarea type="text" class="form-control" [(ngModel)]="metaKeywords"
            placeholder="Enter meta keywords" style="height: 100px;"></textarea>
          <label style="color: red;padding: 0;"></label>
        </div>
        <div class="row" style="margin-bottom:15px;">
          <label style="padding: 0;">Meta Description</label>
          <textarea type="text" class="form-control" [(ngModel)]="metaDescription"
            placeholder="Enter meta description" style="height: 100px;"></textarea>
          <label style="color: red;padding: 0;"></label>
        </div>
        <div class="row mrt-20" style="display: flex;">
          <button class="save hand save-btn" (click)="saveChildProfession()">Submit</button>
          <button class="btn btn-cancel"
            onclick="document.getElementById('addChildModal').style.display='none'">Cancel</button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal" id="updateChildModal" style="overflow: hidden;padding-top: 50px;">
    <div class="modal-content" style="width: 60%;">
      <div class="modal-header" style="padding-top: 0;">
        <h4 class="modal-title3 text-center"  id="myModalLabel">
         Edit Child Profession</h4>
        <span onclick="document.getElementById('updateChildModal').style.display='none'" class="close hand"
          title="Close Modal">×</span>
      </div>
      <div class="modal-body" style="padding-bottom: 0;">
        <div class="row" style="margin-bottom:15px;">
          <label style="padding: 0;">Main Profession<span style="color: red;">*</span></label>
          <input type="text" class="form-control input-readonly" [(ngModel)]="professionName"
            placeholder="Enter Main Profession">
          <label style="color: red;padding: 0;"></label>
        </div>
        <div class="row" style="margin-bottom:15px;">
          <label style="padding: 0;">Child Profession Name<span style="color: red;">*</span></label>
          <input type="text" class="form-control" [(ngModel)]="childProfessionName"
            placeholder="Update Child Profession">
          <label style="color: red;padding: 0;"></label>
        </div>
        <div class="row" style="margin-bottom:15px;">
          <label style="padding: 0;">Meta Title</label>
          <textarea type="text" class="form-control" [(ngModel)]="updatedmetaTitle"
            placeholder="Enter meta title"></textarea>
          <label style="color: red;padding: 0;"></label>
        </div>
        <div class="row" style="margin-bottom:15px;">
          <label style="padding: 0;">Meta Keywords</label>
          <textarea type="text" class="form-control" [(ngModel)]="updatedmetaKeywords"
            placeholder="Enter meta keywords" style="height: 100px;"></textarea>
          <label style="color: red;padding: 0;"></label>
        </div>
        <div class="row" style="margin-bottom:15px;">
          <label style="padding: 0;">Meta Description</label>
          <textarea type="text" class="form-control" [(ngModel)]="updatedmetaDescription"
            placeholder="Enter meta description" style="height: 100px;"></textarea>
          <label style="color: red;padding: 0;"></label>
        </div>
        <div class="row mrt-20" style="display: flex;">
          <button class="save hand save-btn" (click)="updateChildProfession()"
            onclick="document.getElementById('updateChildModal').style.display='none'">Update</button>
          <button class="btn btn-cancel"
            onclick="document.getElementById('updateChildModal').style.display='none'">Cancel</button>
        </div>
      </div>
    </div>
  </div>
  <div id="deleteChildModal" class="modal" style="overflow: hidden;">
    <form class="modal-content" action="/action_page.php" style="width: 35%;">
      <div class="container">
        <div class="modal-header" style="padding-top: 0;">
          <h5 class="modal-title" id="exampleModalLabel" style="margin-left: auto;">Confirm</h5>
          <button type="button" id="dismissModal"
            onclick="document.getElementById('deleteChildModal').style.display='none'" class="btn-close"
            data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div style="padding-top: 15px; padding-bottom: 15px; text-align: center;">
          <p>Are you sure, you want to delete </p>
          <p> <strong>{{childProfessionName}}</strong> Child Profession?</p>
        </div>
        <div class="modal-footer" style="padding-bottom: 0;">
          <button type="button" onclick="document.getElementById('deleteChildModal').style.display='none'"
            class="btn btn-danger footer-button ml-auto" data-bs-dismiss="modal"
            style="width: 18%;margin-left: auto;">Cancel</button>
          <button type="button" class="btn btn-primary footer-button mr-auto"
            (click)="deleteChildProfession(selectedChildProfesionId)" style="width: 18%;margin-right: auto;"
            onclick="document.getElementById('deleteChildModal').style.display='none'">submit</button>
        </div>
      </div>
    </form>
  </div>
  
  