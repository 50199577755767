<div class="whole-section">
  <nav aria-label="breadcrumb" class="mlft-6">
    <app-bread-crumb [breadTitle]="activitiesTite" [breadpath1]="adminPanel" [breadpath2]="activities"
      [breadpath3]="activitiesNav" [breadroute1]="adminPanelRoute">
    </app-bread-crumb>
  </nav>
  <div class="table-responsive" style="padding: 1%;">
    <div class="row " style="background-color: #fff;">
      <div class="col-md-2 left-section" *ngIf="isCard" (mouseleave)="onMouseLeave()"
        style="z-index: 900;position: absolute;top: 0px;">
        <div class="row">
          <div class="col-md-12" *ngIf="isCard">
            <div class="Rectangle-562">
              <div class="row">
                <!-- <div class="col-md-10" style="padding-right: 0;padding-top: 12px;text-align: initial;">
                <label class="Reports">Activities</label>
              </div>
              <div class="col-md-2" style="text-align: end;padding-right: 0;">
                <img src="../../../assets/images/double-right-arrow.png" (click)="menu()" style="cursor: pointer;"
                  class="leftimage-1" alt="double right arrow" title="double right arrow">
              </div> -->
              </div>
            </div>
            <div class="hand" (click)="searchtab('Login Activities')"
              [ngClass]="{'Rectangle-563-copy':page!='Login Activities',  'Rectangle-563-selected active' : page=='Login Activities' }">
              <p class="mr-0">Login Activities<span style="float: right;"></span></p>
            </div>
            <div class="hand" (click)="searchtab('Without Login Activities')"
              [ngClass]="{'Rectangle-563-copy':page!='Without Login Activities', 'Rectangle-563-selected active' : page=='Without Login Activities' }">
              <p class="mr-0">Without Login Activities<span style="float: right;"></span></p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-1 leftminisec" (mouseenter)="onMouseEnter()">
        <div style="text-align: center; margin-bottom: 10px;">
          <!-- Content goes here -->
        </div>
      </div>
      <div class="content-section" [ngClass]="{'col-10': isCard, 'col-11' : !isCard}">
        <ng-container *ngIf="page === 'Login Activities'">
          <table class="common-table">
            <app-activities-after-login-activities></app-activities-after-login-activities>

          </table>
        </ng-container>
        <ng-container *ngIf="page === 'Without Login Activities'">
          <table class="common-table">
            <app-activities-without-login-activities></app-activities-without-login-activities>

          </table>
        </ng-container>


      </div>

    </div>
  </div>
</div>