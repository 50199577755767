<div class="" style="background: #f0f0f0;">
    <nav aria-label="breadcrumb" class="mlft-6">
        <app-bread-crumb [breadTitle]="breadTitle" [breadpath1]="breadData1" [breadpath2]="breadData2"
            [breadroute1]="breadRouteData1"></app-bread-crumb>

    </nav>
    <app-confirmation [item]="isAllUsers" *ngIf="isAllUsers" (sendallusers)="sendAll($event)"></app-confirmation>
    <div class="profile-content">
        <div class="container-connecting-dots">
            <div class="profile-section">
                <div class="row insection">
                 <label style="color: red;" *ngIf="isLimitExceeded">The combination of title, image URL/video URL, and message exceeds the limit to 4kb.</label>
                    <form class="login-form" [formGroup]="notificationForm" style="padding: 1% 5% 3% 5%;">
                        <div class="form-group mt-2">
                            <label class="bld">Title <span style="color: red;">*</span></label>
                            <input type="text" class="form-control inpt" formControlName="title" 
                                (input)="titleChange($event)" style="font-weight: bold;"
                                [ngClass]="{ 'is-invalid': submitted && f.title.errors }">

                            <div *ngIf="submitted && f.title.errors" class="invalid-feedback">
                                <div *ngIf="f.title.errors.required">Title is required</div>
                            </div>
                            <label *ngIf="isTiltleValid" style="color: red;">Your Title is too long! Please limit it to 250 characters or less.</label>
                        </div>
                        <div class="row">
                            <div class="form-group col-6 mt-2">
                                <label class="bld">Receiver Type<span style="color: red;">*</span></label>
                                <select class="form-control form-select width-100 inpt"
                                    (change)="changereciverType($event)">
                                    <option value="1" [selected]="isReciverTyp=='1'">Client</option>
                                    <option value="2" [selected]="isReciverTyp=='2'">Skills</option>
                                    <option value="3" [selected]="isReciverTyp=='3'">Business/Organization</option>
                                    <option value="4" [selected]="isReciverTyp=='4'">Location</option>

                                </select>
                            </div>
                            <div class="form-group col-6 mt-2" *ngIf="!isLocation || isValidRecieverType">
                                <label class="bld">Receiver <span style="color: red;">*</span></label>
                                <div class="dropdown">
                                    <ng-select *ngIf="isReciverTyp=='1'" [items]="lstUsers" [virtualScroll]="true"
                                        bindLabel="userId" [bufferAmount]="1000" bindValue="memberId" [multiple]="true"
                                        class="custom-ng-select-container" placeholder="Select Receiver" #selectBox
                                        (change)="changeUser($event)">
                                    </ng-select>
                                    <ng-select *ngIf="isReciverTyp=='2'" [items]="lstSkills" [virtualScroll]="true"
                                        bindLabel="subProfessionCategoryName" [bufferAmount]="1000"
                                        bindValue="subProfessionsCategoryId" [multiple]="true"
                                        placeholder="Select Receiver" (change)="changeUser($event)" #selectBox>
                                    </ng-select>
                                    <ng-select *ngIf="isReciverTyp=='3'" [items]="lstOrganizations"
                                        [virtualScroll]="true" bindLabel="organisationName" [bufferAmount]="1000"
                                        bindValue="organisationId" [multiple]="true" placeholder="Select Receiver"
                                        (change)="changeUser($event)" #selectBox>
                                    </ng-select>

                                    <div *ngIf="submitted && !isLocation && lstSelectedUsers.length==0" class="invalid">
                                        <div>Receiver is required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-6 " style="margin-bottom: auto;margin-top: auto;"
                                *ngIf="isReciverTyp=='2' || isReciverTyp=='3'">
                                <label><input type="checkbox" class="form-check-input" name="acceptTerms"
                                        (change)="changeLocation($event)" required />&nbsp; Add Specific Location
                                </label>
                            </div>
                            <div class="form-group col-6 mt-2" *ngIf="isLocation">
                                <label class="bld">Location wise</label>
                                <select class="form-control form-select width-100 inpt"
                                    (change)="changeSelection($event)">
                                    <option value="country">Country</option>
                                    <option value="state">State</option>
                                    <option value="city">City</option>
                                    <option value="zipcode">Zipcode</option>

                                </select>
                            </div>
                            <div class="form-group col-6 mt-2"
                                *ngIf="(this.selectedRadio=='country' || this.selectedRadio=='state' || this.selectedRadio=='city' || this.selectedRadio=='zipcode') && isLocation">
                                <label class="bld">Country</label>
                                <ng-select [items]="lstCountry" bindLabel="country_name" placeholder="Select Country"
                                    #selectBox (change)="changeSelectCountry($event)" [(ngModel)]="selectedItems">
                                </ng-select>
                            </div>
                            <div class="form-group col-6 mt-2"
                                *ngIf="(this.selectedRadio=='state'|| this.selectedRadio=='city' || this.selectedRadio=='zipcode') && isLocation">
                                <label class="bld">State </label>
                                <ng-select [items]="lstState" bindLabel="state_name" placeholder="Select State"
                                    #selectBox (change)="changeSelectState($event)">
                                </ng-select>
                            </div>
                            <div class="form-group col-6 mt-2"
                                *ngIf="(this.selectedRadio=='city' || this.selectedRadio=='zipcode') && isLocation">
                                <label class="bld">City </label>
                                <ng-select [items]="lstCities" bindLabel="city_name" placeholder="Select City"
                                    #selectBox (change)="changeSelectCity($event)">
                                </ng-select>
                            </div>
                            <div class="form-group col-6 mt-2" *ngIf="this.selectedRadio=='zipcode' && isLocation">
                                <label class="bld">Zipcode </label>
                                <ng-select [items]="lstZipCodes" bindLabel="zipcode" placeholder="Select Zipcode"
                                    #selectBox (change)="changeSelectZipcode($event)">
                                </ng-select>
                            </div>

                            <div class="form-group col-6 mt-2">
                                <label class="bld">Expiry Date <span style="color: red;">*</span></label>
                                <input type="date" class="form-control inpt" formControlName="date"
                                    placeholder="mm-dd-yyyy" onkeydown="return false" min="{{minDate}}" (input)="dateChange()"
                                    [ngClass]="{ 'is-invalid': submitted && f.date.errors }">
                                <div *ngIf="submitted && f.date.errors" class="invalid-feedback">
                                    <div *ngIf="f.date.errors.required">Date is required</div>
                                </div>
                            </div>
                            <div class="form-group col-6 mt-2">
                                <label class="bld">Notification Type<span style="color: red;">*</span></label>
                                <select class="form-control form-select width-100 inpt"
                                    (change)="changeNotification($event)" style="cursor: pointer; ">
                                    <option value="Text" [selected]="notificationtype =='Text'"><span
                                            style="cursor: pointer;">Text</span></option>
                                    <option value="Image" style="cursor: pointer;"
                                        [selected]="notificationtype =='Image'"><span
                                            style="cursor: pointer;">Image</span></option>
                                    <option value="Video" style="cursor: pointer;"
                                        [selected]="notificationtype =='Video'"><span
                                            style="cursor: pointer;">Video</span></option>
                                    <option value="ImageAndVideo" style="cursor: pointer;"
                                        [selected]="notificationtype =='ImageAndVideo'"><span
                                            style="cursor: pointer;">Image & Video</span></option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group  mt-2"
                            *ngIf="notificationtype=='Image' || notificationtype=='ImageAndVideo'">
                            <label class="bld">Image Upload <span style="color: red;">*</span></label>
                            <div class="right-inner-addon input-container">
                                <i class="fa fa-cloud-upload imgupld" (click)="fileUpload.click()"><input #fileUpload
                                        style="display: none;" type="file" accept=".jpeg,.jpg,.png,.gif,.tiff"
                                        id="upload-file" name="upload-file" accept="image/png, image/gif, image/jpeg"
                                        (change)="addAttachment($event)"></i>
                                <input type="text" class="form-control inpt mb-0" aria-describedby="basic-addon2" accept="image/png, image/gif, image/jpeg"
                                    (click)="fileUpload.click()" formControlName="image" accept=".jpeg,.jpg,.png,.gif,.tiff" readonly
                                    [ngClass]="{ 'is-invalid': submitted && f.image.errors }"
                                    style="border-style: dashed !important;background-color: #fff;">
                                <label class="mb-1">Accepted file types jpeg (or jpg), png, gif, tiff</label>

                                <div *ngIf="submitted && f.image.errors && (notificationtype=='Image' || notificationtype=='ImageAndVideo')"
                                    class="invalid-feedback">
                                    <div *ngIf="f.image.errors?.required">Image is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group  mt-2"
                            *ngIf="notificationtype=='Video' || notificationtype=='ImageAndVideo'">
                            <label class="bld">Video Url Upload<span style="color: red;">*</span></label>
                            <div class="right-inner-addon input-container">
                                <i class="fa fa-play-circle vdupld"></i>
                                <input type="url" class="form-control inpt mb-0" aria-describedby="basic-addon2"
                                    formControlName="vediopath" (input)="vedioURlChange()"
                                    [ngClass]="{ 'is-invalid': submitted && f.vediopath.errors }">
                                <label class="mb-1">Accepted file types mp4, mov, avi, mkv, webm, ogg</label>
                                <div *ngIf="submitted && f.vediopath.errors && (notificationtype=='Video' || notificationtype=='ImageAndVideo')"
                                    class="invalid-feedback">
                                    <div *ngIf="f.vediopath.errors?.required">Video url is required</div>
                                    <div *ngIf="f.vediopath.errors?.pattern">Please provide valid url</div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group mt-2">
                            <label class="bld">Message <span style="color: red;">*</span></label>
                            <angular-editor id="editor1" formControlName="message" appRestrictPaste
                                [config]="editorConfig" (input)="messageChange($event)"></angular-editor>
                                
                            <div *ngIf="submitted && ismessage" style="    width: 100%;
                            margin-top: 0.25rem;
                            font-size: .875em;
                            color: #dc3545;">
                                <div *ngIf="ismessage">Message is required</div>

                            </div>
                            <label *ngIf="isMessageValid" style="color: red;">You cannot send the message if the actual text length exceeds 1500 characters or if applied styles (like bold, color, font size) increase its length beyond 1500 characters.</label>
                            <label *ngIf="isTableMessageValid" style="color: red;">Tabular format is not allowed , please provide simple text.</label>
                            <!-- <label *ngIf="isImageMessageValid" style="color: red;">Ensure you're not including an image in the message.</label> -->
                            
                        </div>

                        
                        <div class="row pt-7">
                            <button type="submit" class="btn btn-primary text-center mt-2 sendbtn hand" [disabled]="!isNotificationFormValid"
                                (click)="sendNotifications()">Send</button>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    </div>

</div>