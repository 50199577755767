<div class="whole-section">
    <nav aria-label="breadcrumb" class="mlft-6">
        <app-bread-crumb [breadTitle]="userManagementTitle" [breadpath1]="adminPanel" [breadpath2]="userManagement"
            [breadpath3]="userManagementNav" [breadroute1]="adminPanelRoute"></app-bread-crumb>
    </nav>
    <div class="row">
        <div class="col-3">
     </div>
    </div>
    <div class="table-responsive" style="padding:  1% 16px 0px 12.3px ;">
        <div class="row " style="background-color: #fff;">
            <div class="col-md-1 leftminisec" (mouseenter)="onMouseEnter()">
                <div style="text-align: center; margin-bottom: 10px;">
                </div>

                <!-- <div
                     [ngClass]="{'Rectangle-563-copy': page !== 'usersfordigitalteam', 'Rectangle-563-selected active' : page === 'usersfordigitalteam'}">
                    
                     </div>
                 <div
                     [ngClass]="{'Rectangle-563-copy': page !== 'allusersdisplay', 'Rectangle-563-selected active' : page === 'allusersdisplay'}">
                     <div class="align-icon"><i class="fa-solid fa-user-pen"></i></div>
                 </div>
                 <div
                     [ngClass]="{'Rectangle-563-copy': page !== 'notifications', 'Rectangle-563-selected active' : page === 'notifications'}">
                     <div class="align-icon"><i class="fa-solid fa-bell"></i></div>
                 </div>
                 <div
                     [ngClass]="{'Rectangle-563-copy': page !== 'dailycount', 'Rectangle-563-selected active' : page === 'dailycount'}">
                     <div class="align-icon"><i class="fa-solid fa-calendar-days"></i></div>
                 </div>
                 <div
                     [ngClass]="{'Rectangle-563-copy': page !== 'profiledata', 'Rectangle-563-selected active' : page === 'profiledata'}">
                     <div class="align-icon"><i class="fa-regular fa-address-card"></i></div>
                 </div>
                 <div
                     [ngClass]="{'Rectangle-563-copy': page !== 'activityData', 'Rectangle-563-selected active' : page === 'activityData'}">
                     <div class="align-icon"><i class="fa-solid fa-users"></i></div>
                 </div> -->
            </div>
            <div class="col-md-2 left-section" *ngIf="isCard" (mouseleave)="onMouseLeave()"
                style="z-index: 900;position: absolute;top: 0px;">
                <div class="row">
                    <div class="col-md-12" *ngIf="isCard">
                        <div class="Rectangle-562">
                        </div>
                        <div class="hand" (click)="searchtab('rolecreation')"
                            [ngClass]="{'Rectangle-563-copy': page !== 'rolecreation', 'Rectangle-563-selected active' : page === 'rolecreation' }">
                            <p class="mr-0">Role Creation<span style="float: right;"></span></p>
                        </div>
                        <div class="hand" (click)="searchtab('roleassignment')"
                            [ngClass]="{'Rectangle-563-copy': page !== 'roleassignment', 'Rectangle-563-selected active' : page === 'roleassignment' }">
                            <p class="mr-0"> Role Assignment<span style="float: right;"></span></p>
                        </div>
                        <div class="hand" (click)="searchtab('userprofilevideo')"
                            [ngClass]="{'Rectangle-563-copy': page !== 'userprofilevideo', 'Rectangle-563-selected active' : page === 'userprofilevideo' }">
                            <p class="mr-0">Manage Profile<span style="float: right;"></span></p>
                        </div>
                        <div class="hand" (click)="searchtab('sponsoredUser')"
                            [ngClass]="{'Rectangle-563-copy': page !== 'sponsoredUser', 'Rectangle-563-selected active' : page === 'sponsoredUser' }">
                            <p class="mr-0">Sponsored User<span style="float: right;"></span></p>
                        </div>
                    </div>
                </div>

            </div>
            <div class="content-section"  [ngClass]="{'col-10': isCard, 'col-11' : !isCard}">
                <ng-container *ngIf="page === 'rolecreation'">
                    <table class="common-table">
                     <app-role-creation-user-management></app-role-creation-user-management>
                    </table>
                </ng-container>
                <ng-container *ngIf="page === 'roleassignment'">
                    <table class="common-table">
                    <app-role-assignment-user-management></app-role-assignment-user-management>
                </table>
                </ng-container>
                <ng-container *ngIf="page === 'userprofilevideo'">
                    <table class="common-table">
                    <app-manage-profile-user-management></app-manage-profile-user-management>
                </table>
                </ng-container>
                <ng-container *ngIf="page === 'sponsoredUser'">
                    <table class="common-table">
                    <app-sponsored-user-user-management></app-sponsored-user-user-management>
                </table>
                </ng-container>
            </div>
        </div>
    </div>
</div>