import { Component, EventEmitter, Input, NgZone, OnChanges, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { GoogleApisService } from '../services/google-apis.service';
import { NgxGpAutocompleteDirective } from '@angular-magic/ngx-gp-autocomplete';
import { SearchService } from '../services/search.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { PopupComponent } from '../../Components/popup/popup.component';
import { DataService } from '../services/data.service';
@Component({
  selector: 'app-autocomplete-google-places',
  templateUrl: './autocomplete-google-places.component.html',
  styleUrls: ['./autocomplete-google-places.component.css']
})
export class AutocompleteGooglePlacesComponent implements OnInit, OnChanges, OnDestroy {

  @Input() adressType: string;
  @Input() currentAddress: string;
  @Input() maxLength: number;
  @Input() pageName: string;
  @Output() setAddress: EventEmitter<any> = new EventEmitter();
  @ViewChild('addresstext') addresstext: any;
  @ViewChild('ngx-places') placesRef: NgxGpAutocompleteDirective;
  @ViewChild('popup') popupComponent: PopupComponent;
  addressobj: any = {};
  origin: {};
  localAddress: any;
  convertedAddress: string;
  queryWait: boolean;
  userAddress: string = ''
  userLatitude: string = ''
  userLongitude: string = ''
  isLoading: boolean = true;
  constructor(private _googleApi: GoogleApisService,private zone:NgZone,private searchService: SearchService,
    private spinner: NgxSpinnerService,private _route: Router,private dataService:DataService
  ) {
  }

  ngOnChanges() {
  }

  ngOnInit() {
    if (this.currentAddress == undefined || this.currentAddress == null || this.currentAddress == ''  ) {
      this.getCurrentGeoLatLng();
      this.spinner.hide();
    } else if (this.currentAddress != undefined && this.currentAddress != null){
      if (typeof this.currentAddress != 'string') {
        this.currentAddress = this.currentAddress[0];
      }
      this.localAddress = this.currentAddress;
      this.convertedAddress = this.localAddress;
      this.userAddress = this.currentAddress;
    }
    this.setUrlLocalAddress();
    let location = sessionStorage.getItem('location');
    if(location) {
      this.isLoading = false;
    }
  }

  handleAddressChange(address: any) {
    // console.log(address);
    sessionStorage.setItem('location', address.formatted_address);
    sessionStorage.setItem('locality', address.name);
    this.setAddress.emit(address);
  }

  clearSearch(){
    this.userAddress = '';
    this.convertedAddress = '';
  }

  getCurrentGeoLatLng() {
    navigator.geolocation.getCurrentPosition((position) => {
      this.addressobj['latlng'] = position.coords.latitude + ',' + position.coords.longitude;
      this.origin = {};
      this.origin['lat'] = position.coords.latitude;
      this.origin['lng'] = position.coords.longitude;
      this._googleApi.originLatLng(this.origin);
      this.searchService.currentUpdateLocation(this.origin['lat'], this.origin['lng']);
      // sessionStorage.setItem('latlng', position.coords.latitude + ',' + position.coords.longitude);
      this.getAddress(this.addressobj)
    });
  }


  getGeoCoordinates(data) {
    this._googleApi.getSearchAddressGeoCoordinates(data).subscribe(res => {
      this.origin = {};
      if (res.results.length > 0) {
        this.origin['lat'] = res.results[0].geometry.location.lat;
        this.origin['lng'] = res.results[0].geometry.location.lng;
        this.addressobj['latlng'] = res.results[0].geometry.location.lat + ',' + res.results[0].geometry.location.lng;
        this._googleApi.originLatLng(this.origin);
      }
    });
  }


  getAddress(a) {
    this._googleApi.getCurrentGeoAddress(a).subscribe(res => {
      if(!sessionStorage.getItem('location')){
        let formatAddress = res.results[0].formatted_address;
        if (formatAddress != undefined && formatAddress != null && formatAddress != '') {
          this.localAddress = formatAddress;
        }
        this.convertedAddress = this.localAddress;
      }
      this.isLoading = false;
      this.dataService.popUpFlag(true);
      // this.spinner.hide();
      sessionStorage.setItem('exactLocation', res.results[0].formatted_address);
    },
      error => { console.log(error) })
  }



  setUrlLocalAddress() {
    setTimeout(() => {
      const searchData = sessionStorage.getItem('searchData');
      if (searchData != null && searchData != undefined && searchData != '') {
        let data = JSON.parse(searchData);
        if (data.localAddress != undefined) {
          this.localAddress = data.localAddress;
          this.convertedAddress = this.localAddress;
          this.userAddress = this.currentAddress;
          sessionStorage.setItem('location', this.convertedAddress);
        }
      }
    }, 1000);
  }

  ngOnDestroy(): void {
    setTimeout(() => {
      this.setUrlLocalAddress();
    }, 1000);
  }
}
