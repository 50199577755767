<div style="background-color: #6a6a6a;">
    <div class="row near-by-services-align">
        <h2 class="servicehead mb-3">NEAR BY SERVICES</h2>
        <div class="col-md-1 mb-3">
            <h5 style="border-bottom: 1px solid #fff;"></h5>
        </div>

        <div class="row">
            <div class="col-md-4" *ngFor="let f of lstNearbyServices">
                <div class="service-upper">
                    <h5 class="servicedesc mb-3"><img src="assets/images/right_arrow.png" class="mr-2" alt="right arrow service icon" title="right arrow service icon"><a
                            href={{f.nearByServiceSubCategoryUrl}} class="link-style"
                            target="_blank">{{f.nearByServiceSubCategoryKeyword}}</a></h5>
                </div>
            </div>
        </div>
    </div>
</div>