
<nav aria-label="breadcrumb" class="mlft-6">
    <app-bread-crumb [breadpath1]="home" [breadpath2]="referAFriend" [breadroute1]="referAFriendRoute"></app-bread-crumb>

    <!-- <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
     
      <li class="breadcrumb-item active" aria-current="page">Refer a friend</li>
    </ol> -->
  </nav>
  
 
<div class="profile-content">
    <div class="container-connecting-dots">
            <div class="referSection">
                <div class="row">
                    <div class="col-md-5 col-sm-12">
                        <h1 class="refer-title">Connect & Earn</h1>
                        <div class="text-center">
                            <img class="img-fluid" src="assets/images/referimg.png" alt="refeImg" title="refeImg">
                        </div>
                        
                    </div>
                    <div class="col-md-7 col-sm-12">
                        <div class="row">
                            <div class="col-md-12 tabbuttons">
                                    <button class="btn  btns mr-2" [ngClass]="{ 'act': isConnect, 'inact' : !isConnect }" (click)="connect('connect')">Connect</button>
                                    <button class="btn inact btns" [ngClass]="{ 'act': !isConnect, 'inact' : isConnect }" (click)="connect('earn')">Earnings</button>
                            </div>

                            <div class="col-md-12 msgsection">
                            <div class="rect">
                               <div class="col-md-12">
                                        <img class="Invite-contacts" src="assets/images/invite-contacts.png" alt="invite contacts" title="invite contacts">
                                        <img class="Invite-contacts" src="assets/images/download-app.png" alt="download app" title="download app">
                                        <img class="Invite-contacts" src="assets/images/install-and-earn.png" alt="install & earn" title="install & earn">
                                </div>
                                <h2 class="about">KNOW MORE ABOUT THE APP</h2>
                            </div>
                            </div>
                            <div class="col-md-12 msgsection">
                                <div class="rect">
                                    <p class="about mt-15">OR</p>
                                    <p>Share your invite code with friends</p>
                                    <div class="about mt-15">{{uniqueId}}</div>
                                    <div class="col-md-12">
                                        <div class="row modifiedRow">
                                            <div class="col-6 col-sm-3">
                                                <a [href]="getSocialMediaLink('message')" target="_blank" title="Page in Progress">
                                                    <!-- <i class="fab fa-instagram"></i> -->
                                                    <img class="Invite-contacts ml-33" src="assets/images/message.png" alt="message" title="message">
                                                </a>
                                            </div>
                                            <div class="col-6 col-sm-3">
                                                <a [href]="getSocialMediaLink('whatsapp')" target="_blank" title="Share on WhatsApp">
                                                    <!-- <i class="fab fa-whatsapp"></i> -->
                                                    <img class="Invite-contacts" src="assets/images/layer-565.png" alt="whatsapp" title="whatsapp">
                                                </a>
                                            </div>
                                            <div class="col-6 col-sm-3">
                                                <a [href]="getSocialMediaLink('gmail')" target="_blank" title="Share on Gmail">
                                                    <!-- <i class="fab fa-linkedin"></i> -->
                                                    <img class="Invite-contacts" src="assets/images/layer-566.png" alt="gmail" title="gmail">
                                                </a>
                                            </div>
                                            <div class="col-6 col-sm-3">
                                                <a [href]="getSocialMediaLink('facebook')" target="_blank" title="Share on Facebook">
                                                    <!-- <i class="fab fa-facebook"></i> -->
                                                    <img class="Invite-contacts" src="assets/images/layer-567.png" alt="facebook" title="facebook">
                                                </a>
                                            </div>
                                         </div>
                                </div>
                                </div>
                        </div>
                       
                    </div>
                    <!-- <div class="col-sm-12 col-md-7 d-none d-md-block ">
                        <div class="refer-text-con">
                            <div class="refer-title">Refer a friend</div>
                            
                            <div class="inputSection">
                                <b><h3>Send friend a promo code "#{{uniqueId}}"</h3></b>
                                
                                <input type="text" class="form-control input-refer" id="referId" placeholder="Enter Mobile Number"> 
                                <span class="invite-text">
                                    Invite
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class=" col-sm-12 col-md-5">
                        <div>
                            <img class="img-fluid"  src="assets/images/referimg.png" alt="refeImg">
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-7 d-md-none ">
                        <div class="refer-text-con">
                            <div class="refer-title">Referral Code</div>
                            <p class="refer-text">
                            </p>
                            <div class="inputSection">
                                <b>@{{uniqueId}}</b>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
</div>
</div>
