<div class="tasks_table1 ">
    <div class="row">
        <div class="col-md-12 search-box">
            <label style="width: 100%;"><span><input type="text" id="search"
                        class="custom-focus-input" autocomplete="off" placeholder="Search with Title, Names, Message"
                        style="height: 37px;border: none;width: 100.3%;"
                        (input)="searchChange($event)"></span></label>
        </div>
    </div>

</div>
<table class="table table-striped">
    <thead>
        <tr>
            <th style="width: 40%;">Notification Id</th>
            <th style="width: 60%;">Title</th>
        </tr>
    </thead>
    <tbody>
        <tr
            *ngFor="let d of lstNotificatons| paginate: { itemsPerPage: 20, currentPage: p, totalItems:recordCount};let i = index">
            <td style="width: 40%;" class="pointer" (click)="showPopup(d.notificationId)">
                {{d.notificationId }}</td>
            <td style="width: 60%;" class="pointer" (click)="showPopup(d.notificationId)">{{ d.title }}
            </td>
        </tr>
    </tbody>
</table>
<div class="row">
    <div class="col">
        <div class="d-flex justify-content-center"
            *ngIf="lstNotificatons!==null&&lstNotificatons?.length!=0">
            <pagination-controls (pageChange)="pageChanged($event)"
                (pageBoundsCorrection)="pageChanged($event)">
            </pagination-controls>
        </div>
    </div>
</div>

<div *ngIf="showNotificationPopup" class="modal" style="display: block;">
    <div class="Rectangle-9081" style="width: 90%; margin: auto;">
      <div class="modal-content1" [ngStyle]="{ height: (notificationDetails.videoUrlPath || notificationDetails.imagePath) ? '100vh' : 'auto', 'max-height': '100vh', 'display': 'flex', 'flex-direction': 'column', 'border-radius': '5px' }">
        <div class="modal-header" style="height: 50px;">
          <h4 class="modal-title">Notification Details</h4>
          <span class="close" (click)="closePopup()">&times;</span>
        </div>
        <div class="modal-body" style="flex-grow: 1; overflow-y: auto; width: 100%; padding: 15px;">
         <div *ngIf="notificationDetails.videoUrlPath || notificationDetails.imagePath" class="Rectangle-9082" style="margin-bottom: 15px;">
            <div class="row">
              <div class="col-md-6">
                <div class="Video-Player---Neutral">
                  <div *ngIf="notificationDetails.videoUrlPath; else placeholder">
                    <iframe [src]="safeYoutubeUrl" frameborder="0" allowfullscreen style="width: 100%; height: auto;"></iframe>
                  </div>
                  <ng-template #placeholder>
                    <div class="video-placeholder">
                      <img src="assets/video-placeholder.png" alt="No video available" style="width: 100%;">
                    </div>
                  </ng-template>
                </div>
              </div>
              <div class="col-md-6">
                <div class="earth-europe">
                  <div class="image-container">
                    <img *ngIf="notificationDetails.imagePath" [src]="notificationDetails.imagePath" alt="Notification Image" class="img-fluid" title="notification" style="width: 100%;">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Scrollable messages section -->
          <div class="messages-section" [ngClass]="{ 'messages-scroll': !(notificationDetails.videoUrlPath || notificationDetails.imagePath) }">
            <div class="row">
              <div class="col-md-6">
                <p class="Title">
                  <strong>Title:</strong><br>
                  {{ notificationDetails.title }}
                </p>
              </div>
              <div class="col-md-6">
                <p class="Receiver-name">
                  <strong>Receiver :</strong><br>
                  {{ notificationDetails.receiverFullName }}
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="sender-name">
                  <strong>Message:</strong><br>
                  <span [innerHTML]="notificationDetails.message"></span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <p class="sender-name">
                  <strong>Sender :</strong><br>
                  {{ notificationDetails.senderFullName }}
                </p>
                <p class="Start Date">
                  <strong>Start Date:</strong><br>
                  {{ notificationDetails.createdDate }}
                </p>
              </div>
              <div class="col-md-6">
                <p class="Notification Type">
                  <strong>Notification Type:</strong><br>
                  {{ notificationDetails.notificationType }}
                </p>
                <p class="Expiry Date">
                  <strong>Expiry Date:</strong><br>
                  {{ notificationDetails.expiryDate }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer" style="height: 50px;">
          <button type="button" class="btn btn-danger footer-button ml-auto" style="width: 160px; background-color: #195fbd; border-color: #195fbd; position: relative; top: 11px;" (click)="closePopup()">Close</button>
        </div>
      </div>
    </div>
  </div>