import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExcetionLogsRoutingModule } from './excetion-logs-routing.module';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ExcetionLogsRoutingModule
  ]
})
export class ExcetionLogsModule { }
