
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Cons, interval, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { GoogleApisService } from 'src/app/shared/services/google-apis.service';
import { ServerApisService } from 'src/app/shared/services/server-apis.service';
import { AutocompleteService } from '../../shared/services/autocomplete.service';
import { Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/shared/alert/alert.service';
import { NgSelectComponent } from '@ng-select/ng-select';
import { ServiceProviderListComponent } from '../service-provider-list/service-provider-list.component';
import { SearchService } from 'src/app/shared/services/search.service';
import { Constants } from 'src/app/shared/constants/constant';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  @ViewChild('selectBoxs') selectBoxs: NgSelectComponent;
  @ViewChild(ServiceProviderListComponent) serviceProviderListComponent: ServiceProviderListComponent;
  image: any = null;
  imagetext: any;
  formname: string = 'home';
  userLocation: string = "";
  providerListWithOutFilter: any = [];
  isadd: boolean = false;
  lstServices: any = [];
  lstTempServices: any = [];
  providerList: any = [];
  servicename: string = "";
  isDrpdown: boolean = false;
  selectedProfessionName: string = "";
  serviceInNeedList: any = [];
  API_URL: string = "https://toskie.com/Blogs"
  isaddservice: boolean = false;
  lstallprofessions: any = [];
  needServiceListWithOutFilter: any = [];
  locationdisplay: boolean = true;
  needServiceList: any = [];
  providerFilterDistance = Constants.DefaultRangeDistance;
  serviceFilterDistance: any;
  // filterName: any = "";
  providerSearch: any = "";
  serviceSearch: any = "";
  latlng = {};
  isSearchResultsPage: boolean;
  countryname: any;
  loggedIn: boolean = false;
  localAddress: any;
  addresscoordinates: any = [];
  search = false;
  searchData: {};
  serviceid: number = 0;
  psearch = true;
  addressobj: any = {};
  origin: {};
  autocompleteaddress: any;
  servicesList: any;
  providerProfession: boolean = false;
  currentuser: any = [];
  dataFromChild: boolean = false;
  userLatLng: any;
  currentLatLng: any;
  isSubProfessionPage: boolean = false;
  constructor(private _apiService: ServerApisService, private _googleApi: GoogleApisService,
    private router: Router,
    private spinner: NgxSpinnerService, private autocomplete: AutocompleteService, private title: Title, private meta: Meta,
    private _alert: AlertService, private searchService: SearchService) {
    this.isSearchResultsPage = window.location.pathname.includes('search') ? true : false;
    var repeat = interval(3000).subscribe((x => {
      //  this.getServicesList();
    }));
  }

  lstNearbyServices: any = [];
  ngOnInit(): void {
    const regexForParent = /^\/[a-zA-Z-]+\/[a-zA-Z-]+$/;
    this.isSubProfessionPage = regexForParent.test(window.location.pathname) && !window.location.pathname.includes('admin-panel') ? true : false;
    this.getAllServices();
    const user = sessionStorage.getItem('user');
    if (user != null) {
      this.loggedIn = true;
      const userdetails = sessionStorage.getItem('user');
      const userdata = JSON.parse(userdetails);
      const contact = sessionStorage.getItem('contact');
      if (contact != null) {
        this.router.navigate(['/contact-us']);
        sessionStorage.removeItem('contact');
      }
      this.currentuser = userdata;
      if (this.currentuser.name !== undefined && this.currentuser.name != "") {
        this.currentuser.name = this.currentuser.fname;
      } else {
        this.currentuser.name = this.currentuser.fname + " " + this.currentuser.lname;
      }

      if (this.currentuser.provider == "GOOGLE" || this.currentuser.provider == "FACEBOOK") {
        this.image = this.currentuser.photoUrl;

      } else {
        if (this.currentuser.profileImageUrl != '') {
          if (this.currentuser.profileImageUrl.split("/").splice(-1)[0] !== 'null') {

            this.image = this.currentuser.profileImageUrl;
            this.imagetext = null;
          } else {

            this.imagetext = this.currentuser.fname.charAt(0).toUpperCase() + this.currentuser.lname.charAt(0).toUpperCase();
          }
        } else {

          this.imagetext = this.currentuser.fname.charAt(0).toUpperCase() + this.currentuser.lname.charAt(0).toUpperCase();
        }
      }

    } else {
      this.currentuser = [];
      this.loggedIn = false;
    }
    // this.getFetAllProfessions();
    //  this.getCurrentGeoLatLng();
    //this.GetAllProviderServices();

    this.getServicesList();
    this.selectedValue();
    this.lstNearbyServices = [{ title: 'Makeup Artists in hyderabad' },
    { title: 'Web Development hyderabad' },
    { title: 'Electrician near me' },
    { title: 'Home Tuitions in hyderabad' },
    { title: 'Drivers For Hire near me' },
    { title: 'Electrician near me Home Service' },
    { title: 'Post Wedding Photoshoot Locations in hyderabad' },
    { title: 'Commercial Real Estate Investment' },
    { title: 'Plumbers near me' },
    { title: 'Best Plumbers in hyderabad' },
    { title: 'Best Beauticians near me' },
    { title: 'Drivers near me' },
    { title: 'Social Media Marketing hyderabad' },
    { title: 'Tuitions near me' },
    { title: 'AC Servicing near me' },
    { title: 'Digital Agency hyderabad' },
    { title: 'Best Photographer near me' },
    { title: 'AC Repair near me' },]
    window.scrollTo(0, 0)
    let path = window.location.href;
    if (path.includes('localhost') || path.includes('toskie-dev-web')) {
      this.API_URL = 'https://toskie-dev-web.azurewebsites.net/Blogs';
    }
    else if (path.includes('toskie.com')) {
      this.API_URL = 'https://toskie.com/Blogs';
    } else {
      this.API_URL = 'https://toskie-uat-web.azurewebsites.net/Blogs';
    }

  }
  lstNotifications: any = [];
  isNotification: boolean = false;
  getNotiicationDetails() {
    let payload = {
      "receiver_id": (+sessionStorage.getItem('memberId'))
    }
    this._apiService.NotificationDetails(payload).subscribe((res: any) => {
      this.isNotification = true;
      // this.spinner.hide();
      this.lstNotifications = res.ResultFCMPushNotification;
    });
  }
  getMessage(message: any) {
    return message;
  }
  lstAllServices: any = [];
  lstallDRpServices: any = [];
  lstTempAllServices: any = [];
  getAllServices() {
    const user = sessionStorage.getItem('user');
    let payload = {}
    if (user != null) {
      payload = {
        "memberId": (+sessionStorage.getItem('memberId')),
        "token": sessionStorage.getItem('token')
      }
    } else {
      payload = {
        "memberId": 0,
        "token": " "
      }
    }
    // this.spinner.show();
    // this._apiService.GetDashboardDetails(payload).subscribe((res: any) => {
    //   // this.spinner.hide();
    //   this.lstAllServices = res.GetDashboardDetailsResult.resultDashboardSubProfessions['resultSubProfessionCategory'];
    //   this.lstTempAllServices = this.lstAllServices;
    //   this.lstallDRpServices = this.lstAllServices;
    //   this.getIsSearchResults();
    // });
  }

  getIsSearchResults() {
    let pathname = window.location.pathname;
    if (pathname !== '/') {
      let data: any = sessionStorage.getItem('searchData');
      window.scrollTo(0, 0);
      if (data != '' && data != null) {
        data = JSON.parse(sessionStorage.getItem('searchData'));
        this.providerSearch = data.filterName;
        this.providerFilterDistance = Constants.DefaultRangeDistance;
        this.localAddress = data.localAddress;
        this.userLocation = data.localAddress;
        this.providerfilter();
      }
    }
  }

  selectedFilter(data) {
    this.providerSearch = data.subProfessionCategoryName;
    this.isDropdown = false;
    this.providerfilter();
  }

  getServices() {
    // this.spinner.show();
    this._apiService.GetAllSubProfessionCategories().subscribe((res: any) => {
      // this.spinner.hide();
      if(sessionStorage.getItem('locality')) {
        this.spinner.hide();
      } 
      this.lstServices = res.GetAllSubProfessionCategoryResult['resultSubProfessionCategory'];
      this.lstTempServices = res.GetAllSubProfessionCategoryResult['resultSubProfessionCategory'];
    });
  }

  lstChildProfessions: any = [];
  // getChildCategory(data: any) {
  //   this.selectedProfessionName = data.professionName;
  //   let payload = {
  //     "professiontype": "childProfession",
  //     "professionsId": data.professionsId
  //   }
  //   // this.spinner.show();
  //   this._apiService.fetchAllProfessions(payload).subscribe(res => {
  //     // this.spinner.hide();
  //     if(sessionStorage.getItem('locality')) {
  //       this.spinner.hide();
  //     } 
  //     this.lstChildProfessions = res['resultServiceProfessions'];
  //   })
  // }

  getSearchResults(data) {
    this.providerSearch = data.professionName;

    this.providerfilter();
  }
  changeService(event) {

    if (event.target.value != '' && event.target.value != undefined && event.target.value != null) {
      var result = this.lstTempServices.filter((x: any) => x.subProfessionCategoryName.toLowerCase().includes(event.target.value.toLowerCase()));
      this.isDrpdown = true;
      this.lstServices = result;
    } else {
      this.isDrpdown = false;
      this.isadd = false;
      this.lstServices = this.lstTempServices;
    }
  }
  isDropdown: boolean = false;
  changeSearch() {
    this.isDropdown = false;
  }
  changeServicedrp(event: any) {

    if (event != undefined) {
      this.serviceid = event.subProfessionsCategoryId;
      this.servicename = event.subProfessionCategoryName;
      this.isDrpdown = false;
      this.isadd = true;
    } else {
      this.isadd = false;
    }

  }
  handleChildData(data: any) {
    if (data != null && data != undefined && data != '') {
      this.providerSearch = data.professionName;
      if (this.localAddress == undefined) {
        this.localAddress = sessionStorage.getItem('location');
      }
      this.dataFromChild = true;
      this.providerfilter();
    }
  }
  addServiceField() {

    if (this.isadd) {
      let payLoad: any = {
        memberId: (+sessionStorage.getItem('memberId')),
        serviceName: this.servicename,
        subProfessionsCategoryId: this.serviceid,
        deviceType: "Website",
        token: this._apiService.token
      };
      // this.spinner.show();

      this._apiService.saveService(payLoad).subscribe(result => {

        if (result['resultModel'].status == 200) {
          let payLoad1: any = {
            memberId: (+sessionStorage.getItem('memberId')),
            providerMemberId: (+sessionStorage.getItem('memberId')),
            token: this._apiService.token
          };

          this._apiService.getUserProfile(payLoad1).subscribe((res: any) => {

            // this.spinner.hide();
            if (res.status == 200) {
              this.servicename = "";
              this.serviceid = 0;
              this.isadd = false;
              this.currentuser = res.Registration;
              sessionStorage.setItem('user', JSON.stringify(res['Registration']));
              this._alert.success(result.resultModel.result);
            }
          });
        }
      });
    }

  }
  myImage() {

    this.image = null;
    this.imagetext = this.currentuser.fname.charAt(0).toUpperCase() + this.currentuser.lname.charAt(0).toUpperCase();

  }
  getServiceLetter(service) {
    let text = service.replace(/\s+/g, '');
    return text.charAt(0).toUpperCase();
  }
  // getFetAllProfessions() {

  //   let payload = {
  //     "professiontype": "mainProfession",
  //     "professionsId": 0
  //   }
  //   // this.spinner.show();
  //   this._apiService.fetchAllProfessions(payload).subscribe(res => {
  //     // this.spinner.hide();
  //     this.lstallprofessions = res['resultServiceProfessions'];
  //   })
  // }
  searchChange(event: any) {
    this.isDropdown = true;
    let dd = event.target.value;
    // 
    // this.getServicesList()
  }
  register() {
    this.router.navigateByUrl('register');
  }
  signin() {
    this.router.navigateByUrl('login');
  }
  getServicesList() {

    // this._apiService.AutoCompleteServices().subscribe(res => {

    //   try {
    //     const obj: any = res.Services;
    //     obj.token = this._apiService.token;
    //     this._apiService.sListUpdate(obj);

    //     this.servicesList = res.Services.map(item => item.userService);

    //     this.autocomplete.set_autocomplete('providerProfession', 'form1_complete', this.servicesList);

    //     this.autocomplete.set_autocomplete('needServiceProfession', 'form2_complete', this.servicesList);
    //   }
    //   catch {
    //     err => {

    //       var obj = {
    //         ErrorMessage: err
    //       }
    //       this._apiService.ExceptionLog(obj).subscribe(res => {

    //       });

    //     }
    //   }
    // })
  }


  refreshTab(val: string) {
    if (val == 'hire') {
      this.locationdisplay = true;
    } else {
      this.locationdisplay = false;
    }
    this.providerSearch = '';
    this.serviceSearch = '';
    document.getElementById('form1_complete').classList.add('invisible');
    document.getElementById('form2_complete').classList.add('invisible');
    // document.getElementById('providerLocation').classList.add('invisible');
    // document.getElementById('needServiceLocation').classList.add('invisible');
    this.providerFilterDistance = Constants.DefaultRangeDistance;
    this.serviceFilterDistance = Constants.DefaultRangeDistance;
  }

  getLocationAddressCoordinates(address: any) {
    this.origin = {};
    if (typeof (address) != 'string') {
      this.localAddress = address.formatted_address;
      // this.userLocation=this.localAddress;
      this.origin['lat'] = address.geometry.location.lat();
      this.origin['lng'] = address.geometry.location.lng();
      this._googleApi.originLatLng(this.origin);

      if (this.locationdisplay) {

        this.providerfilter();
      } else {
        this.servicefilter();
      }
    } else {
      let data = JSON.parse(sessionStorage.getItem('searchData'));
      if (data != '' && data != null && data.localAddress != undefined) {
        this.localAddress = data.localAddress;
      } else {
        this.localAddress = [address];
      }
    }

    // this.autoAddress['userLatitude' ]= address.geometry.location.lat();
    // this.autoAddress['userLongitude'] = address.geometry.location.lng();


  }

  selectedValue() {

    this.autocomplete.selectedValue$.subscribe(
      res => {
        if (res != null && res != undefined && this.locationdisplay) {
          this.providerSearch = res;

          this.providerfilter();
        }
        if (res != null && res != undefined && !this.locationdisplay) {
          this.serviceSearch = res;
          this.servicefilter();
        }

      }

    );


  }
  changeRange(event) {

    if (this.locationdisplay) {

      this.providerfilter();
    } else {
      this.servicefilter();
    }
  }


  removeDropdown() {
    document.getElementById('form1_complete').classList.add('invisible');
    document.getElementById('form2_complete').classList.add('invisible');
  }

  providerfilter() {
    this.searchData = {};

    this.search = true;
    this.psearch = true;
    this.searchData['filterName'] = this.providerSearch;
    this.searchData['filterDistance'] = this.providerFilterDistance;
    this.searchData['localAddress'] = this.localAddress;

    var result = this.lstAllServices.filter((x: any) => x.subProfessionCategoryName.toLowerCase() == this.providerSearch.toLowerCase())
    if (result.length > 0) {
      this.isDropdown = false;

      this.searchData['categoryid'] = result[0].subProfessionsCategoryId;
      sessionStorage.setItem('searchData', JSON.stringify(this.searchData));
      this.isSearchResultsPage = true;

      if (this.serviceProviderListComponent) {
        this.serviceProviderListComponent.getProviderServiceList();
      }

      if (this.dataFromChild) {
        let professionNameWithHyphen = this.providerSearch;
        if (professionNameWithHyphen.includes(' ')) {
          professionNameWithHyphen = professionNameWithHyphen.replace(/ /g, '-');
        }
        this.router.navigate([`${sessionStorage.getItem('parentCategory').replace(/ /g, '-').toLowerCase()}/${professionNameWithHyphen.toLowerCase()}/${sessionStorage.getItem('locality').replace(/ /g, '-')}`]);
      }
    } else {
      this.isDropdown = true;
      let message: any = 'Please enter your service ';
      this._alert.warn(message);
    }
  }
  changeProviderSearch(event: any) {

    var d = event.target.value;
  }
  professionChange(event) {

    this.isDropdown = true;
    var res = this.lstTempAllServices.filter((x: any) => x.subProfessionCategoryName.toLowerCase().includes(this.providerSearch.toLowerCase()));
    this.lstallDRpServices = res;
    this.getServicesList()
  }
  servicefilter() {
    this.search = true;
    this.psearch = false;
    this.searchData = {};
    this.searchData['filterName'] = this.serviceSearch;
    this.searchData['filterDistance'] = this.serviceFilterDistance;
    this.searchData['localAddress'] = this.localAddress;
  }



  close() {

  }

  viewProfile() {
    let u = this.currentuser;

    if (u.provider == 'GOOGLE' || u.provider == 'FACEBOOK') {
      const EncodeURL = btoa(u.memberId);
      let id = u.memberId;
      let username = u.fname.toLowerCase() + u.lname.toLowerCase()
      username = username.replace(/\s+/g, ' ');
      username = username.replace(/ /g, '-');
      this.router.navigate(['/profile', `${username}-${id}`]).then(() => {
        window.location.reload();
      });
    }

  }
  logoutUser() {
    try {
      sessionStorage.clear();
      let data = {
        token: this._apiService.token
      }
      this._apiService.sendUpdate(data);
      // let r, s;
      // let m = new HomeComponent(r, s);
      // m.dismissRegisterModalOpenLoginModal();
      document.getElementById('id011').click();
      window.location.reload();
      this._alert.success("You have successfully logged out!");

      this.router.navigate(["/"]).then(() => {
        window.location.reload();
      })
    } catch (error) {
      console.log(error);
    }
  }
  deleteAccount() {

    let payLoad = {
      'memberId': sessionStorage.getItem('memberId'),
      'token': this._apiService.token,
      'deviceType': "Website"
    }
    this._apiService.deleteAccount(payLoad).subscribe(
      res => {
        sessionStorage.clear();
        document.getElementById('id01').click();
        this._alert.success('Account delete successfully');
        this.router.navigate(["/"]).then(() => {
          window.location.reload();
        })
      });
  }
  OnlyLettersAllowed(event): boolean {
    const charCode = (event.which) ? event.which : event.keycode;

    if ((charCode < 97 || charCode > 123) && (charCode < 65 || charCode > 90) && charCode != 32) {
      return false;
    }
    return true
  }
  removeService(i) {
    let payLoad: any = {
      memberId: (+sessionStorage.getItem('memberId')),
      CategoryId: i,
      token: this._apiService.token
    };
    // this.spinner.show();

    this._apiService.deleteServiceCategory(payLoad).subscribe(result => {
      // this.spinner.hide();

      if (result.resultModel.status == 200) {
        let payLoad1: any = {
          memberId: (+sessionStorage.getItem('memberId')),
          providerMemberId: (+sessionStorage.getItem('memberId')),
          token: this._apiService.token
        };

        this._apiService.getUserProfile(payLoad1).subscribe((res: any) => {


          if (res.status == 200) {
            this.currentuser = res.Registration;
            // this.spinner.hide();
            sessionStorage.setItem('user', JSON.stringify(res['Registration']));
            this._alert.success(result.resultModel.result);
          }
        });

      }
    })
  }
}

