import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { NgModule, isDevMode } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './Core/header/header.component';
import { FooterComponent } from './Core/footer/footer.component';
import { AuthenticationService } from './Auth/authentication.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from './shared/Interceptors/jwt.interceptor';
import { LoginComponent } from './Core/login/login.component';
import { RouterModule } from '@angular/router';
import { HomeComponent } from './Core/home/home.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ServerApisService } from './shared/services/server-apis.service';
import { SocialLoginModule, SocialAuthServiceConfig, GoogleLoginProvider, FacebookLoginProvider, GoogleSigninButtonModule, GoogleSigninButtonDirective } from '@abacritt/angularx-social-login';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxPaginationModule } from 'ngx-pagination';
import { AlertModule } from './shared/alert/alert.module';
import { CheckPasswordDirective } from './shared/directives/check-password.directive';
import { ProviderProfileComponent } from './Components/provider-profile/provider-profile.component';
import { ProviderRatingComponent } from './Components/provider-rating/provider-rating.component';
import { ServiceInNeedListComponent } from './Core/service-in-need-list/service-in-need-list.component';
import { ServiceProviderListComponent } from './Core/service-provider-list/service-provider-list.component';
import { ReferFriendComponent } from './Components/refer-friend/refer-friend.component';
import { NoProviderResultsFoundComponent } from './Components/no-provider-results-found/no-provider-results-found.component';
import { AddServiceNeededComponent } from './Components/add-service-needed/add-service-needed.component';
import { AutocompleteGooglePlacesComponent } from './shared/autocomplete-google-places/autocomplete-google-places.component';
import { PrivacyPolicyComponent } from './Components/privacy-policy/privacy-policy.component';
import { ContactUsComponent } from './Components/contact-us/contact-us.component';
import { UnderConstructionComponent } from './Components/under-construction/under-construction.component';
import { ServicePolicyComponent } from './Components/service-policy/service-policy.component';
import { RecaptchcaComponent } from './Core/recaptchca/recaptchca.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { OtpComponent } from './Core/otp/otp.component';
import { ActivitiesComponent } from './Components/activities/activities.component';
import { ExcetionLogsComponent } from './Components/excetion-logs/excetion-logs.component';
import { NotificationComponent } from './Components/notification/notification.component';
import { NgImageSliderModule } from 'ng-image-slider';
import { SubProfessionalCategoryComponent } from './Components/sub-professional-category/sub-professional-category.component';
import { ReportsComponent } from './Components/reports/reports.component';
import { ConfirmationComponent } from './Components/confirmation/confirmation.component';
import { ControlPanelComponent } from './Components/control-panel/control-panel.component';
import { CreateAdvertisementComponent } from './Components/create-advertisement/create-advertisement.component';
import { DatePipe } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AccountVerificationComponent } from './Components/account-verification/account-verification.component';
import { RoleManagmentComponent } from './Components/role-managment/role-managment.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { VendorManagmentComponent } from './Components/vendor-managment/vendor-managment.component';
import { BreadCrumbComponent } from './Components/bread-crumb/bread-crumb.component';
import { DownloadAppComponent } from './Components/download-app/download-app.component';
import { NearByServicesComponent } from './Components/near-by-services/near-by-services.component';
import { DashboardServicesComponent } from './Components/dashboard-services/dashboard-services.component';
import { SitemapComponent } from './Components/sitemap/sitemap.component';
import { NotFoundComponent } from './Core/not-found/not-found.component';
import { CopyToClipboardDirective } from '../app/shared/directives/copy-to-clipboard.directive';
import { AdminSitemapComponent } from '../app/Components/admin-sitemap/admin-sitemap.component';
import { AddServiceComponent } from './Core/add-service/add-service.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ShareModalComponent } from './Components/share-modal/share-modal.component';
import { CreateAccountComponent } from './Core/create-account/create-account.component';
import { ReviewComponent } from './Components/review/review.component';
import { AboutUsComponent } from './Components/about-us/about-us.component';
import { PushNotificationComponent } from './Components/push-notification/push-notification.component';
import { NgxGpAutocompleteModule } from "@angular-magic/ngx-gp-autocomplete";
import { Loader } from '@googlemaps/js-api-loader';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { environment } from '../environments/environment';
import { provideAuth, getAuth } from '@angular/fire/auth';
import { provideFirestore, getFirestore } from '@angular/fire/firestore';
import { UpdateMobileNumberComponent } from './Core/update-mobile-number/update-mobile-number.component';
import { QuillModule } from 'ngx-quill';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { WishlistComponent } from './Core/wishlist/wishlist.component';
import { NearByServiceManagementComponent } from './Components/near-by-service-management/near-by-service-management.component';
import { RoundProgressModule } from 'angular-svg-round-progressbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AdminApprovalsComponent } from './Components/admin-approvals/admin-approvals.component';
import { CallLogsComponent } from './Components/call-logs/call-logs.component';
import { MatTabsModule } from '@angular/material/tabs';
import { CustomCarouselComponent } from './Components/custom-carousel/custom-carousel.component';
import { ChildProfessionsComponent } from './Components/child-professions/child-professions.component';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { SupportComponent } from './Components/support/support.component';
import { PopupComponent } from './Components/popup/popup.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { UserInformationReportComponent } from './Components/user-information-report/user-information-report.component';
import { ProfileTransactionLogsReportsComponent } from './Components/profile-transaction-logs-reports/profile-transaction-logs-reports.component';
import { NotificationsReportsComponent } from './Components/notifications-reports/notifications-reports.component';
import { DailyCountsReportsComponent } from './Components/daily-counts-reports/daily-counts-reports.component';
import { ProfileDataReportsComponent } from './Components/profile-data-reports/profile-data-reports.component';
import { ActivityDataReportsComponent } from './Components/activity-data-reports/activity-data-reports.component';
import { PrivacyPolicyModule } from './Components/privacy-policy/privacy-policy.module';
import { WishlistModule } from './Core/wishlist/wishlist.module';
import { ContactUsModule } from './Components/contact-us/contact-us.module';
import { AboutUsModule } from './Components/about-us/about-us.module';
import { ActivitiesModule } from './Components/activities/activities.module';
import { ReportsModule } from './Components/reports/reports.module';
import { AccountVerificationModule } from './Components/account-verification/account-verification.module';
import { NotificationModule } from './Components/notification/notification.module';
import { PushNotificationModule } from './Components/push-notification/push-notification.module';
import { SubProfessionalCategoryModule } from './Components/sub-professional-category/sub-professional-category.module';
import { RoleManagmentModule } from './Components/role-managment/role-managment.module';
import { VendorManagmentModule } from './Components/vendor-managment/vendor-managment.module';
import { CreateAdvertisementModule } from './Components/create-advertisement/create-advertisement.module';
import { AdminSitemapModule } from './Components/admin-sitemap/admin-sitemap.module';
import { NearByServiceManagementModule } from './Components/near-by-service-management/near-by-service-management.module';
import { AdminApprovalsModule } from './Components/admin-approvals/admin-approvals.module';
import { ReferFriendModule } from './Components/refer-friend/refer-friend.module';
import { UnderConstructionModule } from './Components/under-construction/under-construction.module';
import { NoProviderResultsFoundModule } from './Components/no-provider-results-found/no-provider-results-found.module';
import { ProviderProfileModule } from './Components/provider-profile/provider-profile.module';
import { ProviderRatingModule } from './Components/provider-rating/provider-rating.module';
import { LoginModule } from './Core/login/login.module';
import { SupportModule } from './Components/support/support.module';
import { CreateAccountModule } from './Core/create-account/create-account.module';
import { ControlPanelModule } from './Components/control-panel/control-panel.module';
import { AddServiceModule } from './Core/add-service/add-service.module';
import { TermsConditionsModule } from './Components/terms-conditions/terms-conditions.module';
import { CallLogsModule } from './Components/call-logs/call-logs.module';
import { ServiceProviderListModule } from './Core/service-provider-list/service-provider-list.module';
import { ChildProfessionsModule } from './Components/child-professions/child-professions.module';
import { NotFoundModule } from './Core/not-found/not-found.module';
import { ExcetionLogsModule } from './Components/excetion-logs/excetion-logs.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ActivitiesAfterLoginActivitiesComponent } from './Components/activities-after-login-activities/activities-after-login-activities.component';
import { ActivitiesWithoutLoginActivitiesComponent } from './Components/activities-without-login-activities/activities-without-login-activities.component';
import { NearByCaregoryCreationServiceManagementComponent } from './Components/near-by-caregory-creation-service-management/near-by-caregory-creation-service-management.component';
import { NearBySubCaregoryCreationServiceManagementComponent } from './Components/near-by-sub-caregory-creation-service-management/near-by-sub-caregory-creation-service-management.component';
import { PostAdvertisementAdvertisementComponent } from './Components/post-advertisement-advertisement/post-advertisement-advertisement.component';
import { ModifyAdvertisementAdvertisementComponent } from './Components/modify-advertisement-advertisement/modify-advertisement-advertisement.component';
import { VendorCreationVendorManagementComponent } from './Components/vendor-creation-vendor-management/vendor-creation-vendor-management.component';
import { VendorAssignmentVendorManagementComponent } from './Components/vendor-assignment-vendor-management/vendor-assignment-vendor-management.component';
import { VendorNumberAssignmentVendorManagementComponent } from './Components/vendor-number-assignment-vendor-management/vendor-number-assignment-vendor-management.component';
import { AssignProfessionProfessionsComponent } from './Components/assign-profession-professions/assign-profession-professions.component';
 import { MainProfessionProfessionsComponent } from './Components/main-profession-professions/main-profession-professions.component';
import { ButtonGroupComponent } from './Components/button-group/button-group.component';
import { ChildProfessionProfessionsComponent } from './Components/child-profession-professions/child-profession-professions.component';
import { RoleCreationUserManagementComponent } from './Components/role-creation-user-management/role-creation-user-management.component';
import { RoleAssignmentUserManagementComponent } from './Components/role-assignment-user-management/role-assignment-user-management.component';
import { ManageProfileUserManagementComponent } from './Components/manage-profile-user-management/manage-profile-user-management.component';
import { SponsoredUserUserManagementComponent } from './Components/sponsored-user-user-management/sponsored-user-user-management.component';
import { MainProfessionAdminApprovalComponent } from './Components/main-profession-admin-approval/main-profession-admin-approval.component';
import { ChildProfessionAdminApprovalComponent } from './Components/child-profession-admin-approval/child-profession-admin-approval.component';
import { UserRolesAdminApprovalComponent } from './Components/user-roles-admin-approval/user-roles-admin-approval.component';
import { UserNotificationComponent } from './Components/user-notification/user-notification.component';
import { TypingPlaceholderDirective } from './shared/directives/typing-placeholder.directive';

@NgModule({
  declarations: [
    AboutUsComponent,
    AppComponent,
    HeaderComponent,
    FooterComponent,
    LoginComponent,
    OtpComponent,
    RecaptchcaComponent,
    CheckPasswordDirective,
    HomeComponent,
    CopyToClipboardDirective,
    ProviderProfileComponent,
    ProviderRatingComponent,
    ServiceInNeedListComponent,
    ServiceProviderListComponent,
    ReferFriendComponent,
    NoProviderResultsFoundComponent,
    AddServiceNeededComponent,
    AutocompleteGooglePlacesComponent,
    PrivacyPolicyComponent,
    ContactUsComponent,
    UnderConstructionComponent,
    ServicePolicyComponent,
    ActivitiesComponent,
    ExcetionLogsComponent,
    NotificationComponent,
    SubProfessionalCategoryComponent,
    ReportsComponent,
    ControlPanelComponent,
    ConfirmationComponent,
    CreateAdvertisementComponent,
    AccountVerificationComponent,
    AdminSitemapComponent,
    PushNotificationComponent,
    RoleManagmentComponent,
    VendorManagmentComponent,
    BreadCrumbComponent,
    ShareModalComponent,
    DownloadAppComponent,
    NearByServicesComponent,
    DashboardServicesComponent,
    SitemapComponent,
    NotFoundComponent,
    AddServiceComponent,
    CreateAccountComponent,
    ReviewComponent,
    UpdateMobileNumberComponent,
    PostAdvertisementAdvertisementComponent, ModifyAdvertisementAdvertisementComponent, VendorCreationVendorManagementComponent, VendorAssignmentVendorManagementComponent, VendorNumberAssignmentVendorManagementComponent,AssignProfessionProfessionsComponent, MainProfessionProfessionsComponent, 
    WishlistComponent, NearByServiceManagementComponent, AdminApprovalsComponent, CallLogsComponent, CustomCarouselComponent, ChildProfessionsComponent, SupportComponent, PopupComponent, UserInformationReportComponent, ProfileTransactionLogsReportsComponent, NotificationsReportsComponent, DailyCountsReportsComponent, ProfileDataReportsComponent, ActivityDataReportsComponent, ActivitiesAfterLoginActivitiesComponent, ActivitiesWithoutLoginActivitiesComponent, NearByCaregoryCreationServiceManagementComponent, NearBySubCaregoryCreationServiceManagementComponent, ButtonGroupComponent, ChildProfessionProfessionsComponent, RoleCreationUserManagementComponent, RoleAssignmentUserManagementComponent, ManageProfileUserManagementComponent, SponsoredUserUserManagementComponent, MainProfessionAdminApprovalComponent, ChildProfessionAdminApprovalComponent, UserRolesAdminApprovalComponent, UserNotificationComponent, TypingPlaceholderDirective, 
  ],
  imports: [AppRoutingModule,
    RouterModule,
    HttpClientModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AlertModule,
    BrowserAnimationsModule,
    SocialLoginModule,
    NgxSpinnerModule,
    NgxPaginationModule,
    AngularEditorModule,
    NgImageSliderModule,
    DragDropModule,
    NgSelectModule,
    CommonModule,
    MatDialogModule,
    NgxGpAutocompleteModule,
    GoogleSigninButtonModule,
    MatButtonModule,
    MatInputModule,
    RoundProgressModule,
    MatTooltipModule,
    MatTabsModule,
    MatSlideToggleModule,
    QuillModule.forRoot(),
    ToastrModule.forRoot(),
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideAuth(() => getAuth()),
    provideFirestore(() => getFirestore()),
    GoogleTagManagerModule.forRoot({
      id: 'GTM-NK8CTG8'
    }),
    PrivacyPolicyModule,
    WishlistModule,
    ContactUsModule,
    AboutUsModule,
    ActivitiesModule,
    ExcetionLogsModule,
    ReportsModule,
    AccountVerificationModule,
    NotificationModule,
    PushNotificationModule,
    SubProfessionalCategoryModule,
    CreateAdvertisementModule,
    AdminSitemapModule,
    NearByServiceManagementModule,
    AdminApprovalsModule,
    ReferFriendModule,
    UnderConstructionModule,
    NoProviderResultsFoundModule,
    ProviderProfileModule,
    ProviderRatingModule,
    LoginModule,
    SupportModule,
    CreateAccountModule,
    ControlPanelModule,
    AddServiceModule,
    TermsConditionsModule,
    CallLogsModule,
    ServiceProviderListModule,
    ChildProfessionsModule,
    NotFoundModule,
    VendorManagmentModule,
    RoleManagmentModule,
    NgbModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    {
      provide: Loader,
      useValue: new Loader({
        apiKey: 'AIzaSyA_VDafK_hW4l5i4TfENskMzxsCxVesUJc',
        libraries: ['places']
      })
    },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '123350701056-mm9mn71srt88tkifqkdqto3sh9i3j3a6.apps.googleusercontent.com'
            ),
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('1652376118468168'),
          },
        ],
      } as SocialAuthServiceConfig,
    },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: 'googleTagManagerId', useValue: 'GTM-NK8CTG8F' },
    AuthenticationService, ServerApisService, DatePipe, GoogleSigninButtonDirective
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }