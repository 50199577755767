<nav aria-label="breadcrumb" class="mlft-6">
    <app-bread-crumb [breadpath1]="breadData1" [breadpath2]="breadData2" *ngIf="isProviderProfile"
        [breadroute1]="breadRouteData1" [showBreadcrumb3]="false"></app-bread-crumb>
    <app-bread-crumb [breadpath1]="breadData3" [breadpath2]="breadData4" *ngIf="!isProviderProfile"
        [breadroute1]="breadRouteData2"></app-bread-crumb>
</nav>

<div class="row adv1">
</div>
<div class="row" *ngIf="providerInfo?.OrganisationImages?.length === 0 && !isProviderProfile">
    <div class="slide-section-default">
        <img src="assets/images/default-image.png" class="default-image" (click)="handleClick()" width="250"
            height="250" alt="" title="" />
        <input class="hidden" type="file" id="upload-file" name="upload-file" accept="image/png, image/gif, image/jpeg"
            (change)="addAttachment($event,'default')">
    </div>
</div>
<div class="row" *ngIf="providerInfo?.OrganisationImages?.length>0" class="slide-section">
    <app-custom-carousel [images]="imageObject" [userProfile]="!isProviderProfile"></app-custom-carousel>
</div>

<div class="row profile-contact mt-3" [ngClass]="{'active-button-ctrl':!isProviderProfile}" *ngIf="!otpsent">
    <div class="col-sm-12 col-md-12 col-lg-6" style="display: flex;">
        <div class="user-img background-camera mt-3" (click)="openFileInput.click()"
            *ngIf="isProfileEdit && !isProviderProfile">
            <input #openFileInput type="file" id="fileInput" style="display: none" (change)="onPhotoSelected($event)"
                accept="image/*" />
            <div class="camera-overlay" *ngIf="photoEdit"></div>
            <img *ngIf="profileImageUrl != '' && imageValidated" [src]="profileImageUrl" class="user-img1 cover mt-1"
                (error)="myImage()" alt="" title="" [ngClass]="{'hide-image': !image}" />
            <h1 *ngIf="profileImageNotFound && imagetext != null" style="font-size: 60px;"
                class="profileImg user-name-ctrl user-img">{{imagetext}}</h1>
        </div>
        <div class="user-img" *ngIf="!isProfileEdit && !isProviderProfile">
            <img *ngIf="profileImageUrl != '' && imageValidated" [src]="profileImageUrl" class="user-img1 cover"
                (error)="myImage()" (click)="enlargeImage(profileImageUrl)" alt="" title=""
                [ngClass]="{'hide-image': !image}" />
            <h2 *ngIf="profileImageNotFound && imagetext != null" style="font-size: 60px;" class="profileImg ">
                {{imagetext}}</h2>
            <ng-container *ngIf="enlargedImage">
                <div *ngIf="enlargedImage" class="enlarged-image-modal" style="pointer-events: none;">
                    <img src="{{enlargedImage}}" alt="enlarged image" title="enlarged image">
                    <span class="close-icon-image" (click)="closeEnlargedView()"><i
                            class="fa fa-times-circle" style="pointer-events: all;"></i></span>
                </div>
            </ng-container>
        </div>
        <div *ngIf="isProviderProfile" class="container-user">
            <img *ngIf="image != null && providerImageValidated" [src]="image" class="user-img1 cover mt-1"
                (error)="myImage()" (click)="enlargeImage(image)" alt="" title="" [ngClass]="{'hide-image': !image}" />
            <div class="user-img-ctrl">
                <h4 *ngIf="imagetext != null" style="font-size: 60px;" class="profileImg user-img mt-1">{{imagetext}}
                </h4>
            </div>
            <ng-container *ngIf="enlargedImage">
                <div *ngIf="enlargedImage" class="enlarged-image-modal" style="pointer-events: none;">
                    <img src="{{enlargedImage}}" alt="enlarged-image" title="enlarged image">
                    <span class="close-icon-image" (click)="closeEnlargedView()"><i
                            class="fa fa-times-circle" style="pointer-events: all;"></i></span>
                </div>
            </ng-container>

            <!-- <img  *ngIf="image != null" [src]="image" class="user-img1 cover mt-5" (error)="myImage()" (click)="enlargeImage(image)"/>  
            <h2 *ngIf="imagetext != null" style="font-size: 60px;" class="profileImg user-img margin-custom">{{imagetext}}</h2> -->

        </div>
        <span><i class="fa fa-close photo-pencil-close" aria-hidden="true" *ngIf="isProfileEdit && photoEdit"
                (click)="cancelEditing('photo')"></i></span>
        <span><i class="fa fa-check photo-pencil" aria-hidden="true" *ngIf="isProfileEdit && photoEdit"
                (click)="saveData('photo')"></i></span>
        <span><i class="fa fa-pencil photo-pencil" aria-hidden="true" *ngIf="isProfileEdit && !photoEdit"
                (click)="editUserProfile('photo')"></i></span>
        <h2 class="name-align" *ngIf="!fnameEdit">{{providerInfo.fname | titlecase}} {{providerInfo.lname | titlecase}}
            <h6 style="color: #38b6ff;" *ngIf="!isProfileEdit && !isProviderProfile">Unique Id: {{uniqueID}}</h6>
            <span><i class="fa fa-pencil update name-pencil" aria-hidden="true"
                    *ngIf="isProfileEdit && !fnameEdit && !lnameEdit" (click)="editUserProfile('name')"></i></span>
            <div class="verified-alignment" *ngIf="toskieVerified || professionVerified || businessVerified">
                <img src="assets/images/toskie-verified.png" class="img-fluid" *ngIf="toskieVerified && !isProfileEdit"
                    alt="toskie verified" title="toskie verified">
                <img src="assets/images/profession-verified.png" class="img-fluid"
                    *ngIf="professionVerified && !isProfileEdit" title="profession verified" alt="profession verified">
                <img src="assets/images/business-verified.png" class="img-fluid"
                    *ngIf="businessVerified && !isProfileEdit" alt="business verified" title="business verified">
            </div>
            <div class="verified-alignment not-verified" *ngIf="notVerified">
                <img src="assets/images/not-verified.png" class="img-fluid notVerified"
                    *ngIf="notVerified && !isProfileEdit" alt="not verified" title="not verified">
            </div>
        </h2>
        <div class="container-name" [ngClass]="{displayNone: !isProfileEdit}">
            <span *ngIf="fnameEdit">
                <input type="text" class="form-control" name="userFName" id="userFName" [(ngModel)]="userFName"
                    placeholder="Enter First Name" (input)="onInputChange($event, 'fname')" />
            </span>
            <span *ngIf="lnameEdit">
                <input type="text" class="form-control" name="userLName" id="userLName" [(ngModel)]="userLName"
                    placeholder="Enter Last Name" (input)="onInputChange($event, 'lname')" />
            </span>
            <span><i class="fa fa-check update" aria-hidden="true" *ngIf="fnameEdit || lnameEdit"
                    (click)="saveData('name')" style="font-size: 35px;margin-right: 10px;margin-left: 10px;"></i></span>
            <span><i class="fa fa-close close1" aria-hidden="true" *ngIf="fnameEdit || lnameEdit"
                    (click)="cancelEditing('name')" style="font-size: 35px;"></i></span>
        </div>
    </div>

    <div class="col-sm-12 col-md-12 col-lg-6 contact-section">
        <div class="row pad-top-60">
            <!-- Share Button -->
            <div class="w-25 textend"></div>

            <div class="w-25 btn-ctrl textend">
                <button class="share-btn" (click)="openShareModal()">
                    <i class="fa fa-share-alt" aria-hidden="true"></i>
                    <span> Share </span>
                </button>
            </div>

            <!-- Edit Profile Buttons -->
            <div class="col-sm-4 col-md-6 w-25" *ngIf="!isProviderProfile">
                <button class="contact-btn edit-btn" *ngIf="!isProfileEdit" (click)="editProfile()">
                    <i class="icon-edit mr-1" aria-hidden="true"></i>
                    <span>Edit</span>
                </button>
                <button class="contact-btn" *ngIf="isProfileEdit" (click)="editProfileDone()">
                    <span>Done</span>
                </button>
            </div>

            <!-- Contact Button for Provider Profile -->
            <div class="w-25 btn-ctrl" *ngIf="isProviderProfile">
                <button class="contact-btn contact-btn-ctrl" (click)="verifyuser()"
                    [ngClass]="{'disabled-btn': inProgress}">
                    <i class="fa fa-phone mr-1"></i>
                    &nbsp;
                    <span>Make a call</span>
                </button>
            </div>
        </div>

        
        <div *ngIf="isProviderProfile" class="row mt-3">
            <div class="w-25 textend">
                <button class="whatsapp-btn-ctrl" (click)="connectWithWhatsapp()">
                    <i class="fab fa-whatsapp mr-1"></i>
                </button>
            </div>

            <div class="w-25 btn-ctrl" *ngFor="let button of customButtons; let i = index">
                <button class="custom-button-ctrl" [ngClass]="{'first-button': i === 0, 'second-button': i === 1}" *ngIf="button.customButonIsActive === 'Y'" (click)="openButtonUrl(button.customButonUrl)">
                    <span>{{button.customButonName}}</span>
                </button>
            </div>
            
            
            <!-- <div *ngFor="let button of customButtons">
                <div [ngClass]="{'offset-sm-3 col-sm-4 offset-md-0 col-md-6 textend': true}" *ngIf="button.customButonIsActive === 'Y'">
                    <button [ngClass]="{'custom-button-style': true}" (click)="openButtonUrl(button.customButonUrl)">
                        <i class="fa fa-whatsapp mr-1" *ngIf="" (click)="connectWithWhatsapp()" style="font-size: 30px;"></i>
                        <span class="ml-1">{{button.customButonName}}</span>
                    </button>
                </div>
            </div> -->
        </div>

        <!-- Checkbox for Active/Inactive Profile -->
        <div class="row mt-3 checkbox-alignment" *ngIf="!isProviderProfile">
            <div class="col-12" style="padding-left: 6%;">
                <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"
                        [(ngModel)]="active" (change)="toggleProfile($event)" checked>
                    <label class="form-check-label" for="flexSwitchCheckChecked">Active/Inactive Profile</label>
                </div>
            </div>
        </div>
    </div>

</div>

<div class="details-secion">
    <div class="details">
        <div class="col-md-12 tab-header">
            <form name="form" #f="ngForm" novalidate>
                <mat-tab-group>
                    <mat-tab label="About">
                        <div class="tab-content py-3 px-3" id="nav-tabContent">
                            <div class="tab-pane fade show active" id="nav-about" role="tabpanel"
                                aria-labelledby="nav-about-tab">
                                <div class="row">
                                    <div class="col-md-7">
                                        <div class="row pad-l-30">
                                            <div class="col-12 col-md-12" style="margin-bottom: 10px;">
                                                <h6 style="font-weight: 600;">Personal Information</h6>
                                            </div>
                                            <div class="col-12 col-md-12">
                                                <div class="row Personal-information">
                                                    <ul class="list-group list-group-horizontal col-md-12 mb-2">
                                                        <li
                                                            class="list-group-item col-12 col-sm-5 col-md-5 col-lg-4 px-0 py-0 mb-0 border-0">
                                                            <i class="fa fa-building me-2" aria-hidden="true"></i>
                                                            <b>Organization</b>
                                                            <span><i class="fa fa-pencil update" aria-hidden="true"
                                                                    *ngIf="isProfileEdit && !organizationEdit"
                                                                    (click)="editUserProfile('organization')"></i></span>
                                                            <span><i class="fa fa-check update" aria-hidden="true"
                                                                    *ngIf="organizationEdit"
                                                                    (click)="saveData('organization')"></i></span>
                                                            <span><i class="fa fa-close close1" aria-hidden="true"
                                                                    *ngIf="organizationEdit"
                                                                    (click)="cancelEditing('organization')"></i></span>
                                                        </li>
                                                        <li
                                                            class="list-group-item col-12 col-sm-7 col-md-7 col-lg-8 px-0 py-0 border-0">
                                                            <span *ngIf="!organizationEdit">
                                                                {{providerInfo.organisationName}}
                                                            </span>
                                                            <span *ngIf="organizationEdit">
                                                                <input type="text" class="form-control"
                                                                    name="userOrganizationValue"
                                                                    id="userOrganizationValue"
                                                                    [(ngModel)]="userOrganizationValue"
                                                                    (input)="onOrganizationInputChange($event)" />
                                                            </span>
                                                        </li>
                                                    </ul>
                                                    <ul *ngIf="!isProviderProfile"
                                                        class="list-group list-group-horizontal col-md-12 mb-2">
                                                        <li
                                                            class="list-group-item col-12 col-sm-5 col-md-5 col-lg-4 px-0 py-0 mb-0 border-0">
                                                            <i class="fa fa-venus-mars me-2" aria-hidden="true"></i>
                                                            <b>Gender</b>
                                                        </li>
                                                        <li *ngIf="isProfileEdit"
                                                            class="list-group-item col-12 col-sm-7 col-md-7 col-lg-8 px-0 py-0 border-0">
                                                            <label>
                                                                <input type="radio" name="gender" value="Male"
                                                                    [(ngModel)]="selectedGender"
                                                                    (change)="updateGender()" />
                                                                Male
                                                            </label>
                                                            <label style="margin-left: 10px;">
                                                                <input type="radio" name="gender" value="Female"
                                                                    [(ngModel)]="selectedGender"
                                                                    (change)="updateGender()" />
                                                                Female
                                                            </label>
                                                            <label style="margin-left: 10px;">
                                                                <input type="radio" name="gender" value="NotToSay"
                                                                    [(ngModel)]="selectedGender"
                                                                    (change)="updateGender()" />
                                                                Not to say
                                                            </label>
                                                        </li>
                                                        <li *ngIf="!isProfileEdit"
                                                            class="list-group-item col-12 col-sm-7 col-md-7 col-lg-8 px-0 py-0 border-0">
                                                            {{providerInfo.gender}}
                                                        </li>
                                                    </ul>
                                                    <ul class="list-group list-group-horizontal col-md-12 mb-2">
                                                        <li
                                                            class="list-group-item col-12 col-sm-5 col-md-5 col-lg-4 px-0 py-0 mb-0 border-0">
                                                            <i class="fa fa-briefcase me-2"
                                                                aria-hidden="true"></i><b>Service</b>
                                                        </li>
                                                        <li
                                                            class="list-group-item col-12 col-sm-7 col-md-7 col-lg-8 px-0 py-0 border-0">
                                                            {{PrimaryService}}
                                                        </li>
                                                    </ul>
                                                    <ul class="list-group list-group-horizontal col-md-12 mb-2">
                                                        <li
                                                            class="list-group-item col-12 col-sm-5 col-md-5 col-lg-4 px-0 py-0 mb-0 border-0">
                                                            <i class="fa fa-map-marker me-2"
                                                                aria-hidden="true"></i><b>Location</b>
                                                            <span><i class="fa fa-pencil update" aria-hidden="true"
                                                                    *ngIf="isProfileEdit && !addressEdit"
                                                                    (click)="editUserProfile('address')"></i></span>
                                                            <span><i class="fa fa-check update" aria-hidden="true"
                                                                    *ngIf="addressEdit"
                                                                    (click)="saveData('address')"></i></span>
                                                            <span><i class="fa fa-close close1" aria-hidden="true"
                                                                    *ngIf="addressEdit"
                                                                    (click)="cancelEditing('address')"></i></span>
                                                        </li>
                                                        <li *ngIf="!addressEdit"
                                                            class="list-group-item col-12 col-sm-7 col-md-7 col-lg-8 px-0 py-0 border-0">
                                                            {{providerInfo.fullAddress}}
                                                        </li>
                                                        <li *ngIf="addressEdit"
                                                            class="list-group-item col-12 col-sm-7 col-md-7 col-lg-8 px-0 py-0 border-0">
                                                            <app-autocomplete-google-places
                                                                [currentAddress]="userAddress" [pageName]="pageName"
                                                                (setAddress)="getLocationAddressCoordinates($event)"></app-autocomplete-google-places>
                                                        </li>
                                                    </ul>
                                                    <ul *ngIf="!isProviderProfile"
                                                        class="list-group list-group-horizontal col-md-12 mb-2">
                                                        <li
                                                            class="list-group-item col-12 col-sm-5 col-md-5 col-lg-4 px-0 py-0 mb-0 border-0">
                                                            <i class="fa fa-birthday-cake me-2" aria-hidden="true"></i>
                                                            <b>Date Of Birth</b>
                                                            <span><i class="fa fa-pencil update" aria-hidden="true"
                                                                    *ngIf="isProfileEdit && !dateOfBirthEdit"
                                                                    (click)="editUserProfile('dateOfBirth')"></i></span>
                                                            <span><i class="fa fa-check update" aria-hidden="true"
                                                                    *ngIf="dateOfBirthEdit"
                                                                    (click)="saveData('dateOfBirth')"></i></span>
                                                            <span><i class="fa fa-close close1" aria-hidden="true"
                                                                    *ngIf="dateOfBirthEdit"
                                                                    (click)="cancelEditing('dateOfBirth')"></i></span>
                                                        </li>
                                                        <li *ngIf="!dateOfBirthEdit"
                                                            class="list-group-item col-12 col-sm-7 col-md-7 col-lg-8 px-0 py-0 border-0">
                                                            {{providerInfo.dateOfBirth}}
                                                        </li>
                                                        <li *ngIf="dateOfBirthEdit"
                                                            class="list-group-item col-12 col-sm-7 col-md-7 col-lg-8 px-0 py-0 border-0">
                                                            <input type="date" class="form-control"
                                                                name="dateOfBirthValue" id="dateOfBirthValue"
                                                                [(ngModel)]="dateOfBirthValue" />
                                                        </li>
                                                    </ul>
                                                    <ul class="list-group list-group-horizontal col-md-12 mb-2">
                                                        <li
                                                            class="list-group-item col-12 col-sm-5 col-md-5 col-lg-4 px-0 py-0 mb-0 border-0">
                                                            <i class="fa fa-star me-2"
                                                                aria-hidden="true"></i><b>Rating</b>
                                                        </li>
                                                        <li
                                                            class="list-group-item col-12 col-sm-7 col-md-7 col-lg-8 px-0 py-0 border-0">
                                                            4.5
                                                        </li>
                                                    </ul>
                                                    <ul class="list-group list-group-horizontal col-md-12 mb-2">
                                                        <li
                                                            class="list-group-item col-12 col-sm-5 col-md-5 col-lg-4 px-0 py-0 mb-0 border-0">
                                                            <i class="fa fa-pencil-square-o me-2"
                                                                aria-hidden="true"></i><b>Description</b>
                                                            <span><i class="fa fa-pencil update" aria-hidden="true"
                                                                    *ngIf="isProfileEdit  && !descriptionEdit"
                                                                    (click)="editUserProfile('description')"></i></span>
                                                            <span><i class="fa fa-check update" aria-hidden="true"
                                                                    *ngIf="descriptionEdit"
                                                                    (click)="saveData('description')"></i></span>
                                                            <span><i class="fa fa-close close1" aria-hidden="true"
                                                                    *ngIf="descriptionEdit"
                                                                    (click)="cancelEditing('description')"></i></span>
                                                        </li>
                                                        <li *ngIf="!descriptionEdit"
                                                            class="list-group-item col-12 col-sm-7 col-md-7 col-lg-8 px-0 py-0 border-0">
                                                            {{providerInfo.profileDescription}}
                                                        </li>
                                                        <li *ngIf="descriptionEdit"
                                                            class="list-group-item col-12 col-sm-7 col-md-7 col-lg-8 px-0 py-0 border-0">
                                                            <textarea class="form-control" name="userDescription"
                                                                id="userDescription" (change)="checkLength($event)"
                                                                [(ngModel)]="userDescription"
                                                                maxlength="1200"></textarea>
                                                            <p style="color: red;" *ngIf="overflowText !== ''">
                                                                {{overflowText}}</p>
                                                        </li>
                                                    </ul>
                                                    <ul class="list-group list-group-horizontal col-md-12 mb-2">
                                                        <li
                                                            class="list-group-item col-12 col-sm-5 col-md-5 col-lg-4 px-0 py-0 mb-0 border-0">
                                                            <i class="fa fa-briefcase me-2"
                                                                aria-hidden="true"></i><b>Skills</b>
                                                            <span><i class="fa fa-check update" aria-hidden="true"
                                                                    *ngIf="skillsEdit"
                                                                    (click)="saveData('skills')"></i></span>
                                                            <span><i class="fa fa-close close1" aria-hidden="true"
                                                                    *ngIf="skillsEdit"
                                                                    (click)="cancelEditing('skills')"></i></span>
                                                        </li>
                                                        <li
                                                            class="list-group-item col-12 col-sm-7 col-md-7 col-lg-8 px-0 py-0 border-0">
                                                            <ul *ngIf="!skillsEdit"
                                                                class="list-group list-group-horizontal user-services flex-column">
                                                                <li *ngFor="let s of services; let i=index"
                                                                    class="list-group-item">
                                                                    {{s.userService}}
                                                                </li>
                                                            </ul>
                                                            <ul *ngIf="skillsEdit"
                                                                class="list-group list-group-horizontal">
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                    <ul *ngIf="!isProviderProfile"
                                                        class="list-group list-group-horizontal col-md-12 mb-2">
                                                        <li
                                                            class="list-group-item col-12 col-sm-5 col-md-5 col-lg-4 px-0 py-0 mb-0 border-0">
                                                            <i class="fa fa-phone me-2"
                                                                aria-hidden="true"></i><b>Contact Number</b>
                                                            <span><i class="fa fa-pencil update" aria-hidden="true"
                                                                    *ngIf="isProfileEdit && !mobileEdit"
                                                                    (click)="openUpdateMobileNumberModal()"></i></span>
                                                            <span><i class="fa fa-check update" aria-hidden="true"
                                                                    *ngIf="mobileEdit"
                                                                    (click)="saveData('mobile')"></i></span>
                                                            <span><i class="fa fa-close close1" aria-hidden="true"
                                                                    *ngIf="mobileEdit"
                                                                    (click)="cancelEditing('mobile')"></i></span>
                                                        </li>
                                                        <li
                                                            class="list-group-item col-12 col-sm-7 col-md-7 col-lg-8 px-0 py-0 border-0">
                                                            <span *ngIf="!mobileEdit && isProviderProfile">
                                                                {{providerInfo.mobile}}
                                                            </span>
                                                            <span *ngIf="!mobileEdit && !isProviderProfile">
                                                                {{phoneNumber}}
                                                            </span>
                                                        </li>
                                                    </ul>
                                                    <ul *ngIf="!isProviderProfile"
                                                        class="list-group list-group-horizontal col-md-12 mb-2">
                                                        <li
                                                            class="list-group-item col-12 col-sm-5 col-md-5 col-lg-4 px-0 py-0 mb-0 border-0">
                                                            <i class="fa fa-envelope me-2"
                                                                aria-hidden="true"></i><b>Email</b>
                                                            <span><i class="fa fa-pencil update" aria-hidden="true"
                                                                    *ngIf="isProfileEdit && !emailEdit"
                                                                    (click)="editUserProfile('email')"></i></span>
                                                            <span><i class="fa fa-check update" aria-hidden="true"
                                                                    *ngIf="emailEdit"
                                                                    (click)="saveData('email')"></i></span>
                                                            <span><i class="fa fa-close close1" aria-hidden="true"
                                                                    *ngIf="emailEdit"
                                                                    (click)="cancelEditing('email')"></i></span>
                                                        </li>
                                                        <li *ngIf="!emailEdit"
                                                            class="list-group-item col-12 col-sm-7 col-md-7 col-lg-8 px-0 py-0 text-break border-0">
                                                            {{providerInfo.email}}
                                                        </li>
                                                        <li *ngIf="emailEdit"
                                                            class="list-group-item col-12 col-sm-7 col-md-7 col-lg-8 px-0 py-0 border-0">
                                                            <input type="email" class="form-control" name="userEmail"
                                                                id="userEmail" [(ngModel)]="userEmail" />
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-5">
                                        <div class="row">
                                            <iframe [src]="safeYoutubeUrl" width="620" height="260" frameborder="0"
                                                allowfullscreen></iframe>
                                            <strong style="padding-top: 10px;">Toskie - Intro Video</strong>
                                        </div>
                                        <div class="row" *ngIf="!isProviderProfile">
                                            <div class="position-relative">
                                                <div class="position-relative">
                                                    <round-progress [max]="100" [current]="progressValue"
                                                        [color]="'#45ccce'" [background]="'#eaeaea'" [radius]="100"
                                                        [stroke]="15" [semicircle]="false" [rounded]="true"
                                                        [responsive]="true" [clockwise]="true" [duration]="800"
                                                        [animationDelay]="0">
                                                    </round-progress>
                                                </div>
                                                <div
                                                    class="position-absolute top-50 mx-auto translate-middle profile-complete-text">
                                                    <span class="percentage-label">
                                                        <span class="d-block">{{ progressValue }}% </span>
                                                        <span class="profile-complete-label"><span
                                                                class="profile-complete-ctrl position-absolute text-center">Profile
                                                                Complete</span><span></span></span>
                                                    </span>
                                                </div>
                                            </div>

                                            <div *ngIf="remainingFields.length !== 0">
                                                <b>Remaining Fields :</b>
                                                <ul class="bullet-list">
                                                    <li *ngFor="let field of remainingFields">
                                                        {{ field }}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab label="Work Gallery">
                        <div class="row aligned">
                            <div class="row img-section" *ngIf="providerInfo?.OrganisationImages?.length > 0">
                                <div class="orgimg" *ngFor="let d of providerInfo.OrganisationImages; let i = index" (click)="enlargeImage(d.orgImagePath, i)">
                                    <img [src]="d.orgImagePath" alt="add-photos" title="add photos" class="orgimgview">
                                    <span *ngIf="isProfileEdit" class="remove-icon" (click)="removePhoto(i)"><i class="fa fa-minus minuss"></i></span>
                                </div>
                                <div class="empty-photo-section hand" (click)="handleClick()" *ngIf="!isAddPhotos && isProfileEdit">
                                    <img src="assets/images/default-image.png" class="default-image" alt="upload image" title="upload Image" />
                                    <input class="hidden" type="file" id="upload-file" name="upload-file" accept="image/png, image/gif, image/jpeg" (change)="addAttachment($event, 'gallery')">
                                </div>
                            </div>
                            <div style="padding: 4%;" *ngIf="providerInfo?.OrganisationImages?.length == 0 && !isProfileEdit">
                                <strong>No Photos Found!</strong>
                            </div>
                            <div style="padding: 4%;" *ngIf="providerInfo?.OrganisationImages?.length == 0 && isProfileEdit">
                                <div class="empty-photo-section hand" (click)="handleClick()" *ngIf="!isAddPhotos && isProfileEdit">
                                    <img src="assets/images/default-image.png" class="default-image" alt="upload image" title="upload image" />
                                    <input class="hidden" type="file" id="upload-file" name="upload-file" accept="image/png, image/gif, image/jpeg" (change)="addAttachment($event, 'gallery')">
                                </div>
                            </div>
                        </div>
                        
                        <ng-container *ngIf="enlargedImage">
                            <div class="enlarged-image-modal">
                                <span class="left-arrow" (click)="slideImage('left')"><i class="fa fa-arrow-left"></i></span>
                                <span class="right-arrow" (click)="slideImage('right')"><i class="fa fa-arrow-right"></i></span>
                                <img [src]="enlargedImage" alt="enlarged-image" title="enlarged-image">
                                <span class="close-icon-image" (click)="closeEnlargedView()"><i class="fa fa-times-circle"></i></span>
                            </div>
                        </ng-container>
                    </mat-tab>                    
                    <mat-tab label="Service">
                        <div class="row margin-right" *ngIf="!isProfileEdit">
                            <div class="col-md-6">
                                <h5 class="service-tab">Service</h5>
                                <ul>
                                    <li class="serviceName" *ngFor="let s of services; let i=index">
                                        {{ s.userService }}
                                        <span *ngIf="services.length==0 || s.userService==''">Do you want to add
                                            services?</span>
                                    </li>
                                </ul>
                                <img src="assets/images/add-service-sucess.png" class="hand done-img"
                                    (click)="addServiceField()" *ngIf="!isdisableDropdown" alt="add service sucess"
                                    title="add service sucess">
                            </div>
                            <div class="col-md-6 service-adv-section">
                            </div>
                        </div>
                        <app-add-service *ngIf="isProfileEdit"></app-add-service>
                    </mat-tab>
                    <mat-tab label="Reviews">
                        <div class="row margin-right">
                            <div class="col-md-9">
                                <div #reviewComponent>
                                    <app-review></app-review>
                                </div>
                            </div>
                            <div class="col-md-3"></div>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </form>
            <div class="description-container">
                <h3 class="description-heading">Description</h3>
                <div class="description-text">
                    {{description}}
                </div>
            </div>
            <div class="row adv2">
            </div>
        </div>
    </div>
</div>

<app-near-by-services></app-near-by-services>

<!-- Share Modal -->
<div id="shareModal" class="share-modal" *ngIf="showModal">
    <app-share-modal [show]="showModal" [profileUrl]="profileUrl" (closeModalEvent)="closeModal()"></app-share-modal>
</div>

<div class="modal fade" id="viewPhoneNumberModal" tabindex="-1" aria-labelledby="viewPhoneNumberModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body">
                <div class="text-center">
                    Update Mobile Number
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary close" data-bs-dismiss="modal" (click)="goToProfile()">Go
                    To Profile</button>
            </div>
        </div>
    </div>
</div>

<div *ngIf="showGif" class="loading-overlay">
    <div class="loading-gif-container">
        <img src="../../../assets/gif/call.gif" alt="Calling..." title="Calling..." />
    </div>
</div>