<div class="buttonizer buttonizer-group">
    <a role="button" class="buttonizer-button email-btn" href="mailto:contact@toskie.com" aria-label="Mail action (email address)">
        <i aria-hidden="true" class="fas fa-envelope"></i>
      </a>      
    <a role="button" class="buttonizer-button whatsapp-btn" target="_blank" href="https://wa.me/9108069179999?text=Hi Toskie" aria-label="WhatsApp Chat">
      <i aria-hidden="true" class="fab fa-whatsapp"></i>
    </a>
    <a role="button" class="buttonizer-button call-btn" target="_blank"  href="tel:08069179999" aria-label="Call action (phone number)">
      <i aria-hidden="true" class="fas fa-phone"></i>
    </a>
</div>