import { Component, OnChanges, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgSelectComponent } from '@ng-select/ng-select';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService } from 'src/app/shared/alert/alert.service';
import { Constants } from 'src/app/shared/constants/constant';
import { ServerApisService } from 'src/app/shared/services/server-apis.service';

@Component({
  selector: 'app-add-service',
  templateUrl: './add-service.component.html',
  styleUrls: ['./add-service.component.css']
})
export class AddServiceComponent implements OnInit {
  @ViewChild('selectBoxs') selectBoxs: NgSelectComponent;
  lstServices: any = [];
  lstTempServices: any = [];
  isadd: boolean = false;
  currentUser: any;
  servicename: string = "";
  serviceid: number = 0;
  isdisableDropdown: boolean = false;
  isRemovedService: boolean = false;
  homeAddService: string = Constants.home;
  addService: string = Constants.addService;
  addServiceRoute: string =Constants.addServiceRoute;
  // breadTitle: string = "Services & Profession"
  emptyService: boolean;
  isProfile: boolean;
  
  placeholderText: string = "Do you want to add services?";
  

  constructor(private _apiService: ServerApisService,private spinner: NgxSpinnerService,private _alert: AlertService,private router: Router) { }

  ngOnInit(): void {
    const userdetails = sessionStorage.getItem('user');
    this.currentUser = JSON.parse(userdetails);
    if(this.currentUser.Services.length == 1){
      if(this.currentUser.Services[0].CategoryId === 0){
        this.emptyService = true;
      } else {
        this.emptyService = false;
      }
    } 
    if(window.location.href.includes('profile')){
      this.isProfile = true;
    }
    this.getServices();
    this.updatePlaceholderText();
  }

  getServices() {
    
    this.isadd = false;
    this.spinner.show();
    this._apiService.GetAllSubProfessionCategories().subscribe((res: any) => {
      this.spinner.hide();
      this.lstServices = res.GetAllSubProfessionCategoryResult['resultSubProfessionCategory'];
      this.lstTempServices = res.GetAllSubProfessionCategoryResult['resultSubProfessionCategory'];
      if (this.currentUser.Services.length == 5) {
        this.isdisableDropdown = true;
        this.selectBoxs.setDisabledState(true);
      } else {
        this.isdisableDropdown = false;
        this.selectBoxs.setDisabledState(false);
      }
    });
  }
  addServiceField() {
    this.isRemovedService = false;
    var result = this.currentUser.Services.filter((x: any) => x.userService == this.servicename)
    if (result.length > 0) {
      let message = 'Please add your service.'
      this._alert.warn(message);
    } else {
      let payLoad: any = {
        memberId: (+sessionStorage.getItem('memberId')),
        serviceName: this.servicename,
        subProfessionsCategoryId: this.serviceid,
        deviceType: "Website",
        token: this._apiService.token
      };
      if (payLoad.subProfessionsCategoryId != 0 && payLoad.serviceName != "") {
        this.spinner.show();
        this._apiService.saveService(payLoad).subscribe(result => {
          this.selectBoxs.clearModel();
          if (result['resultModel'].status == 200) {
            this.currentUser.services = this.currentUser.Services;
            this.selectBoxs.clearModel();
            this.isadd = false;
            let payLoad1: any = {
              memberId: (+sessionStorage.getItem('memberId')),
              providerMemberId: (+sessionStorage.getItem('memberId')),
              token: this._apiService.token
            };
  
            this._apiService.getUserProfile(payLoad1).subscribe((res: any) => {
              this.spinner.hide();
              if (res.status == 200) {
                this.servicename = "";
                this.serviceid = 0;
                this.currentUser = res.Registration;
                let message = 'Your Service / Profession has been saved'
                this._alert.success(message);
                if (this.currentUser.Services.length == 5) {
                  this.isdisableDropdown = true;
                  this.selectBoxs.setDisabledState(true);
                } else {
                  this.isdisableDropdown = false;
                  this.selectBoxs.setDisabledState(false);
                }
                if(res['Registration'].Services.length == 1){
                  if(res['Registration'].Services[0].CategoryId === 0){
                    this.emptyService = true;
                  } else {
                    this.emptyService = false;
                  }
                }
                sessionStorage.setItem('user', JSON.stringify(res['Registration']));
              }
            });
          }
        });
      } else {
        let message = 'Please add your service.'
        this._alert.warn(message);
      }
    }

  }
  removeService(i) {
    if (this.currentUser.Services.length > 1) {
      this.isRemovedService = false;
      let payLoad: any = {
        memberId: (+sessionStorage.getItem('memberId')),
        CategoryId: i,
        token: this._apiService.token
      };
      this.spinner.show();

      this._apiService.deleteServiceCategory(payLoad).subscribe(result => {
        this.spinner.hide();
        this.isadd=true;
        if (result.resultModel.status == 200) {
          let payLoad1: any = {
            memberId: (+sessionStorage.getItem('memberId')),
            providerMemberId: (+sessionStorage.getItem('memberId')),
            token: this._apiService.token
          };

          this._apiService.getUserProfile(payLoad1).subscribe((res: any) => {


            if (res.status == 200) {
              this.currentUser = res.Registration;
              this.selectBoxs.setDisabledState(false);
              this.isdisableDropdown = false;
              this.spinner.hide();
              sessionStorage.setItem('user', JSON.stringify(res['Registration']));
              this._alert.success(result.resultModel.result);
            }
          });

        }
      })
    } else {
      this.isRemovedService = true;
    }

  }
  changeServicedrp(event: any) {
    if (event == undefined) {
      this.isadd = false;
      this.updatePlaceholderText();
    } else {
      this.serviceid = event.subProfessionsCategoryId;
      this.servicename = event.subProfessionCategoryName;
      this.isadd = true;
      this.updatePlaceholderText();
    }
  }
  
  updatePlaceholderText() {
    const maxServices = 5;
  
    if (this.currentUser.Services.length === 0 || this.currentUser.Services[0].serviceType == '') {
      this.placeholderText = "Do you want to add services?";
      this.isdisableDropdown = false;
    } else if (this.currentUser.Services.length === 1) {
      this.placeholderText = "Do you want to add more services?";
    } else if (this.currentUser.Services.length >= maxServices) {
      this.placeholderText = "Do you want to add more services?";
      this.isdisableDropdown = true;
    } else {
      this.placeholderText = "Do you want to add more services?";
      this.isdisableDropdown = false;
    }
  }
  
  
  
  
  home(){
    sessionStorage.setItem('searchData', '');
    this.router.navigate(['']);
  }
}
