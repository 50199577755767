import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServerApisService } from 'src/app/shared/services/server-apis.service';
import { AlertService } from 'src/app/shared/alert/alert.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { HeaderService } from 'src/app/shared/services/header.service';
import { DataService } from 'src/app/shared/services/data.service';
@Component({
  selector: 'app-no-provider-results-found',
  templateUrl: './no-provider-results-found.component.html',
  styleUrls: ['./no-provider-results-found.component.css']
})
export class NoProviderResultsFoundComponent implements OnInit {
  @Input() search: string;
  @Input() IsData: boolean;
  @Input() Islogin: boolean;
  addServiceNname: string = "";
  isLoggedIn=false
  searchSub: any;

  constructor(
    private _apiService: ServerApisService,
    private _alert: AlertService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private headerService: HeaderService,
    private dataService : DataService
  ) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.isLoggedIn=sessionStorage.getItem('user')!==null? true:false;
    this.search = JSON.parse(sessionStorage.getItem('searchData')).filterName;
    this.searchSub = this.dataService.currentServiceSearchData.subscribe((data) => {
      this.search = data !== '' ? data : JSON.parse(sessionStorage.getItem('searchData')).filterName;
    });
  }

  ngOnChanges() {
    this.search = JSON.parse(sessionStorage.getItem('searchData')).filterName;  
  }

  openRegister() {
    this.headerService.setNavigateToSearch(true);
    this.router.navigate(['/login']).then(() => {
      window.location.reload();
    });
  }

  addService() {
    const payload: any = {};
    payload.memberId = sessionStorage.getItem('memberId');
    payload.serviceInNeedId = "";
    payload.serviceName = this.addServiceNname;
    payload.token = this._apiService.token;

    // this.spinner.show();
    this._apiService.SubmitServiceInNeed(payload).subscribe(res => {
      this.spinner.hide();
      try {
        if (res.resultModel.status == 200) {
          if (res.resultModel.result == "Result Submitted successfully.") {
            this._alert.success(res.resultModel.result);
          } else {
            this._alert.error(res.resultModel.result);
          }
          this.addServiceNname = "";
        } else {
          this._alert.error("Error Occurred!!");
        }
      } catch (err) {
        var obj = {
          ErrorMessage: err
        };
        this._apiService.ExceptionLog(obj).subscribe(res => {});
      }
    });
  }
}
