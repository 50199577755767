import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServerApisService } from 'src/app/shared/services/server-apis.service';
import { Router } from '@angular/router';
import { DashboardService } from 'src/app/shared/services/dashboard.service';

@Component({
  selector: 'app-dashboard-services',
  templateUrl: './dashboard-services.component.html',
  styleUrls: ['./dashboard-services.component.css']
})
export class DashboardServicesComponent implements OnInit {
  lstallprofessions: any = [];
  selectedProfessionId: any;
  allProfessionsub: any;
  constructor(private spinner: NgxSpinnerService,private _apiService: ServerApisService,private route:Router,private dashboardService: DashboardService) {
    this.allProfessionsub = this.dashboardService.allProfessions$.subscribe((data:any) => {
      this.lstallprofessions = data.GetDashboardDetailsResult.resultServiceProfessions.resultServiceProfessions;
      // console.log(this.lstallprofessions);
      // console.log(data.GetDashboardDetailsResult.resultCategoryHierarchy.filter((item:any) => item.id == 18)[0].children);
    });
   }

  ngOnInit(): void {
    
    if(sessionStorage.getItem('exactLocation')){
      this.spinner.hide();
    }
  }
  // getFetAllProfessions() {
    
  //   let payload = {
  //     "professiontype": "mainProfession",
  //     "professionsId": 0
  //   }
  //   // this.spinner.show();
  //   this._apiService.fetchAllProfessions(payload).subscribe(res => {
  //     if(sessionStorage.getItem('latlng')){
  //       this.spinner.hide();
  //     }
  //     this.lstallprofessions = res['resultServiceProfessions'];
  //    console.log(this.lstallprofessions);
      
  //   })
  // }
  lstChildProfessions:any=[];
  selectedProfessionName:string="";
  getChildCategory(data:any){
    this.selectedProfessionName = data.professionName.toLowerCase();
    if(this.selectedProfessionName.includes(' ')){
      this.selectedProfessionName = this.selectedProfessionName.replace(/ /g, '-');
    }
    this.selectedProfessionId = data.professionsId;
    this.route.navigate([`${this.selectedProfessionName}`]);
  }

  getRouteLink(data:any){
    return data.professionName.toLowerCase().replace(/ /g, '-');
  }
  
  geticon(id: number) {
    if (id == 18) {
        return [
           './assets/images/mainCategoryImages/ic_serviceicon_agriculture.png',
           'agriculture service image',
           'agriculture service image'
        ];
    }
    else if (id == 1) {
      return [
         './assets/images/mainCategoryImages/ic_serviceicon_artist.png',
         'artist service image',
         'artist service image'
      ];
    }
    else if (id == 3) {
      return [
         './assets/images/mainCategoryImages/ic_serviceicon_beauty.png',
         'beauty service image',
         'beauty service image'
      ];
    }
    else if (id == 2) {
      return [
         './assets/images/mainCategoryImages/ic_serviceicon_business.png',
         'business service image',
         'business service image'
      ];
    }
    else if (id == 23) {
      return [
         './assets/images/mainCategoryImages/ic_serviceicon_construction.png',
         'construction service image',
         'construction service image'
      ];
    }
    else if (id == 4) {
      return [
         './assets/images/mainCategoryImages/ic_serviceicon_consultant.png',
         'consultant service image',
         'consultant service image'
      ];
    }
    else if (id == 5) {
      return [
         './assets/images/mainCategoryImages/ic_serviceicon_domesticworks.png',
         'domestic works service image',
         'domestic works service image'
      ];
    }
    else if (id == 20) {
      return [
         './assets/images/mainCategoryImages/ic_serviceicon_education.png',
         'education service image',
         'education service image'
      ];
    }
    else if (id == 6) {
      return [
         './assets/images/mainCategoryImages/ic_serviceicon_engineer.png',
         'engineer service image',
         'engineer service image'
      ];
    }
    else if (id == 19) {
      return [
         './assets/images/mainCategoryImages/ic_serviceicon_engineering_works.png',
         'engineer works service image',
         'engineer works service image'
      ];
    }
    else if (id == 7) {
      return [
         './assets/images/mainCategoryImages/ic_serviceicon_eventsorganisers.png',
         'event organizing service image',
         'event organizing service image'
      ];
    }
    else if (id == 9) {
      return [
         './assets/images/mainCategoryImages/ic_serviceicon_fashion.png',
         'fashion service image',
         'fashion service image'
      ];
    }
    else if (id == 10) {
      return [
         './assets/images/mainCategoryImages/ic_serviceicon_finance.png',
         'finance service image',
         'finance service image'
      ];
    }
    else if (id == 8) {
      return [
         './assets/images/mainCategoryImages/ic_serviceicon_food.png',
         'food service image',
         'food service image'
      ];
    }
    else if (id == 11) {
      return [
         './assets/images/mainCategoryImages/ic_serviceicon_homeservice.png',
         'home service image',
         'home service image'
      ];
    }
    else if (id == 21) {
      return [
         './assets/images/mainCategoryImages/ic_serviceicon_internet.png',
         'internet service image',
         'internet services image'
      ];
    }
    else if (id == 12) {
      return [
         './assets/images/mainCategoryImages/ic_serviceicon_it_professional.png',
         'it services image',
         'it services image'
      ];
    }
    else if (id == 13) {
      return [
         './assets/images/mainCategoryImages/ic_serviceicon_legal.png',
         'legal service image',
         'legal service image'
      ];
    }
    else if (id == 22) {
      return [
         './assets/images/mainCategoryImages/ic_serviceicon_management.png',
         'management service image',
         'management service image'
      ];
    }
    else if (id == 24) {
      return [
         './assets/images/mainCategoryImages/ic_serviceicon_media.png',
         'media service image',
         'media service image'
      ];
    }
    else if (id == 14) {
      return [
         './assets/images/mainCategoryImages/ic_serviceicon_medical.png',
         'medical service image',
         'medical service image'
      ];
    }
    else if (id == 15) {
      return [
         './assets/images/mainCategoryImages/ic_serviceicon_socialmedia.png',
         'socialmedia service image',
         'socialmedia service image'
      ];
    }
    else if (id == 16) {
      return [
         './assets/images/mainCategoryImages/ic_serviceicon_teaching.png',
         'teaching service image',
         'teaching service image'
      ];
    }
    else if (id == 25) {
      return [
         './assets/images/mainCategoryImages/ic_serviceicon_technicians.png',
         'technicians service image',
         'technicians service image'
      ];
    }
    else if (id == 17) {
      return [
         './assets/images/mainCategoryImages/ic_serviceicon_training.png',
         'training service image',
         'training service image'
      ];
    } else {
      return [];
    }
  }
  getchar(professionName:string){
    const words = professionName.toUpperCase().split(' ');
    return words.map(word => word.charAt(0)).join('');
  }
}
