import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServerApisService } from 'src/app/shared/services/server-apis.service';
import { Role } from 'src/app/shared/enums/role.enum';
import { Constants, navConstants } from 'src/app/shared/constants/constant';
import { RouteMakingService } from 'src/app/shared/services/route-making.service';
import { AlertService } from 'src/app/shared/alert/alert.service';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NgxPaginationModule } from 'ngx-pagination';

@Component({
  selector: 'app-activities',
  templateUrl: './activities.component.html',
  styleUrls: ['./activities.component.css']
})
export class ActivitiesComponent implements OnInit {
  ActivityLogs: any;
  isCard = true;
  page = 'Login Activities';
  lstactivityLogList: any = [];
  tempActivityLogList: any;
  count: number;
  placeholderText: string;
  searchedName: string;
  searchedMobileNo: string;
  searchedActivityDescription: string;
  selectedActivitiesOption: string = 'All';
  updateMessage: string;
  activityLogWithoutLogInId: any;
  selectedActivitiesWithoutLogOption: string = '';
  lstactivityLogListApproved: any = [];
  tempActivityLogListApproved: any;
  searchedAll: string;
  selectedActivitiesApprovedOption: string = 'AllApproved';
  searchedAllApproved: string;
  searchedNameApproved: string;
  searchedMobileNoApproved: string;
  searchedActivityDescriptionApproved: string;
  searchedEmail: string;
  pendingCount: any;

  constructor(
    private _apiService: ServerApisService,
    private serviceApi: ServerApisService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private routeMakingService: RouteMakingService,
    private  NgxPaginationModule: NgxPaginationModule,
    protected alertService: AlertService,
    protected matSlideToggleModule: MatSlideToggleModule,
    private cdr: ChangeDetectorRef
  ) { }

  lstActivities: any = [];
  lstTempActivities: any = [];
  searchInfo: string = "";
  submitted: boolean = false;
  adminPanel: string = Constants.adminPanel;
  activities: string = Constants.activities;
  adminPanelRoute: string = Constants.adminPanelRoute;
  activitiesTite: string = Constants.loginActivitiesTitle;
  activitiesNav: string = Constants.activitiesNav;
  p = 1;


  ngOnInit(): void {
    const user = sessionStorage.getItem('user');
    if (user != null) {
      let data = JSON.parse(user);
      if (data.roleId === Role.Admin) {
        let path = window.location.href.split('activities/')
        if (path[1] == navConstants.loginActivitiesNav) {
          this.searchtab('Login Activities')
          this.getActivities();
        } else if (path[1] == navConstants.withoutLoginActivitiesNav) {
          this.searchtab(Constants.withoutLoginActivitiesTitle)
          this.getActivityLogWithoutLogin(this.p);
        }
      } else {
        this.router.navigate([""]);
      }
    } else {
      this.router.navigate([this.routeMakingService.getNavigateUrl([Constants.Login])]);
    }
  }
getActivities() {
    // this.spinner.show();
    // this._apiService.GetActivities().subscribe(res => {
    //   this.spinner.hide();
    //   if (res['TrackActivityLogsResult'].status == 200) {
    //     this.lstActivities = res['TrackActivityLogsResult'].ActivityLogs;
    //     this.lstTempActivities = this.lstActivities;
    //     this.scrollToTop();
    //   }
    // });
  }
  onMouseEnter() {
    this.isCard = true;
  }

  onMouseLeave() {
    this.isCard = false;
  }

getActivityLogWithoutLogin(page) {
  //   this.spinner.show();
  //   let payload = {
  //     name: '',
  //     mobileNo: '',
  //     activityDescription: '',
  //     currentPage: page,
  //     approvalType: 'pending'
  //   };

  //   this.serviceApi.getActivityLogWithoutLogin(payload).subscribe(
  //     (res: any) => {
  //       this.spinner.hide();
  //       if (res.GetActivityLogWithoutLoginResult && res.GetActivityLogWithoutLoginResult.result === "Success") {
  //         this.lstactivityLogList = res.GetActivityLogWithoutLoginResult.resultActivityLogWithoutLogin;
  //         if (this.lstactivityLogList.length > 0) {
  //           this.pendingCount = this.lstactivityLogList[0].RecordCount;
  //           this.tempActivityLogList = this.lstactivityLogList;
  //           this.scrollToTop();
  //         }
  //       } else {
  //         console.error('API response not as expected:', res);
  //       }
  //     },
  //     (error) => {
  //       this.spinner.hide();
  //       console.error('Error fetching activity log data:', error);
  //     }
  //   );
   }

  getActivityLogWithoutLoginApproved(page) {
    // this.spinner.show();
    // let payload = {
    //   name: '',
    //   mobileNo: '',
    //   activityDescription: '',
    //   currentPage: page,
    //   approvalType: 'approved'
    // };

    // this.serviceApi.getActivityLogWithoutLogin(payload).subscribe(
    //   (res: any) => {
    //     this.spinner.hide();
    //     if (res.GetActivityLogWithoutLoginResult && res.GetActivityLogWithoutLoginResult.result === "Success") {
    //       this.lstactivityLogListApproved = res.GetActivityLogWithoutLoginResult.resultActivityLogWithoutLogin;
    //       if (this.lstactivityLogListApproved.length > 0) {
    //         this.count = this.lstactivityLogListApproved[0].RecordCount;
    //         this.tempActivityLogListApproved = this.lstactivityLogListApproved;
    //         this.scrollToTop();
    //       }
    //     } else {
    //       console.error('API response not as expected:', res);
    //     }
    //   },
    //   (error) => {
    //     this.spinner.hide();
    //     console.error('Error fetching activity log data:', error);
    //   }
    // );
  }

 

 

 
 



  searchtab(pageName) {
    if (pageName === 'Login Activities') {
      this.getActivities();
      this. activitiesTite = Constants.loginActivitiesTitle
      this.activitiesNav = navConstants.loginActivitiesNav
      this.router.navigate([this.routeMakingService.getNavigateUrl([Constants.adminPanelRoute,Constants.activities,navConstants.loginActivitiesNav])]);
    } else if (pageName === 'Without Login Activities') {
      // this.getActivityLogWithoutLogin(this.p);
      // this.getActivityLogWithoutLoginApproved(this.p);
      this. activitiesTite = Constants.withoutLoginActivitiesTitle
      this.activitiesNav = navConstants.withoutLoginActivitiesNav
      this.router.navigate([this.routeMakingService.getNavigateUrl([Constants.adminPanelRoute,Constants.activities,navConstants.withoutLoginActivitiesNav])]);
    }
    this.page = pageName;
  }

  menu() {
    this.isCard = !this.isCard;
  }
}
