<div (click)="closeRoleDropdown($event)">
    <div class="tasks_table1">
        <div class="row" style="display: flex;">
            <mat-form-field class="select-option" style="flex: 0.5">
              <select [(ngModel)]="selectedSponsoredOption" (change)="resetSponsoredOptions()"
                    matNativeControl requiredstyle="height: 36px;">
                    <option value="name" selected>Name</option>
                    <option value="memberId">Member Id</option>
                    <option value="uniqueId">Unique Id</option>
                </select>
            </mat-form-field>
            <div class="col-md-10 search-box" style="flex: 2.5;position: relative;bottom: 1px;"
                *ngIf="selectedSponsoredOption == 'memberId'">
                <label style="width: 100%;display: inline-flex;">
                   <span>
                        <input type="text" id="search" class="custom-focus-input" autocomplete="off"
                            [(ngModel)]="searchedSponsoredMemberID" placeholder="Search With MemberId"
                            style="height: 41px; border: none; width: 490%;"
                            (input)="searchBySponsoredMemberId($event)">
                    </span>
                </label>
            </div>

            <div class="col-md-10 search-box" style="flex: 2.5;position: relative;bottom: 1px;"
                *ngIf="selectedSponsoredOption == 'name'">
                <label style="width: 100%;display: inline-flex;">
                   <span>
                        <input type="text" id="search" class="custom-focus-input" autocomplete="off"
                            [(ngModel)]="searchedSponsoredName" placeholder="Search With Name"
                            style="height: 41px; border: none; width: 435%;"
                            (input)="searchBySponsoredName($event)">
                    </span>
                </label>
            </div>

            <div class="col-md-10 search-box" style="flex: 2.5;position: relative;bottom: 1px;"
                *ngIf="selectedSponsoredOption == 'uniqueId'">
                <label style="width: 100%;display: inline-flex;">
                   <span>
                        <input type="text" id="search" class="custom-focus-input" autocomplete="off"
                            [(ngModel)]="searchedSponsoredUniqueID" placeholder="Search With UniqueId"
                            style="height: 41px; border: none; width: 435%;"
                            (input)="searchBySponsoredUniqueId($event)">
                    </span>
                </label>
            </div>
        </div>
    </div>
</div>

<table  class="table table-striped">
    <thead class="tablehd-sec">
        <tr>
            <th style="width: 10%;">Member Id</th>
            <th style="width: 10%;">Unique Id</th>
            <th style="width: 10%;">Name</th>
            <th style="width: 10%;">Mobile No</th>
            <th style="width: 10%;">Sponsored</th>
            <th style="width: 15%;">From Date</th>
            <th style="width: 15%;">To Date</th>
            <th style="width: 10%;" *ngIf="listAllSponsoredAccount?.length > 0">Order <p
                    style="font-size: 10px; color: red;">(Next Available Spot:
                    {{listAllSponsoredAccount[0]?.NextAvailableSponsoredPosition}})</p>
            </th>
            <th style="width: 10%;">Action</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let d of listAllSponsoredAccount | paginate: { itemsPerPage: 20, currentPage: p }; let i = index"
            class="tdata">
            <td style="width: 10%;" class="padleft-30">{{d.memberId}}</td>
            <td style="width: 10%;">{{d.UniqueCode}}</td>
            <td style="width: 10%;">{{d.Name}}</td>
            <td style="width: 10%;">{{d.phone}}</td>
            <td style="width: 10%;">
                <div class="form-check form-switch">
                    <input class="form-check-input chck-box hand" type="checkbox" role="switch"
                        id="flexSwitchCheckChecked" [checked]="d.isSponsored === 'Yes'"
                        (change)="changeAccountVerified($event, d)">
                </div>
            </td>
            <td style="width: 15%;">
                <input type="date" class="form-control hand" min="{{todayDate}}"
                    onkeydown="return false" [ngModel]="formatDate(d.sponsoredFromDate)"
                    (change)="changeFromDate($event, d)" [disabled]="d.isSponsored !== 'Yes'">
            </td>
            <td style="width: 15%;">
                <input type="date" class="form-control hand" min="{{todayDate}}"
                    onkeydown="return false" [ngModel]="formatDate(d.sponsoredToDate)"
                    (change)="changeToDate($event, d)" [disabled]="d.isSponsored !== 'Yes'">
            </td>
            <td style="width: 10%;">
                <input type="number" class="form-control hand" [(ngModel)]="d.sponsoredPosition"
                    [disabled]="d.isSponsored !== 'Yes'" (change)="changeSponsoredPosition($event,d) "
                    (keyup)="changeSponsoredPosition($event,d)">
            </td>
            <td style="width: 10%;">
                <span>
                    <i class="fa fa-check bl hand" aria-hidden="true"
                        (click)="updateSponsoredAccount(d)"
                        *ngIf="d.isSponsored === 'Yes' && d.enabledActionItems"></i>
                </span>
                <span>
                    <i class="fa fa-close dangr hand" aria-hidden="true"
                        (click)="cancelSponsoredAccountEdit(d)"
                        *ngIf="d.isSponsored === 'Yes' && d.enabledActionItems"></i>
                </span>
            </td>
        </tr>
    </tbody>
</table>

<div class="row">
    <div class="col">
        <div class="d-flex justify-content-center"
            *ngIf="listAllSponsoredAccount && listAllSponsoredAccount.length > 0">
            <pagination-controls (pageChange)="sponsorPageChanged($event)"
                (pageBoundsCorrection)="sponsorPageChanged($event)"></pagination-controls>
        </div>
    </div>
</div>