import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService } from 'src/app/shared/alert/alert.service';
import { ServerApisService } from 'src/app/shared/services/server-apis.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { FormControl } from '@angular/forms';
import { Role } from 'src/app/shared/enums/role.enum'; 
import { DatePipe } from '@angular/common';
import { Constants,navConstants } from 'src/app/shared/constants/constant';
import { RouteMakingService } from 'src/app/shared/services/route-making.service';
import { DataService } from 'src/app/shared/services/data.service';

@Component({
  selector: 'app-manage-profile-user-management',
  templateUrl: './manage-profile-user-management.component.html',
  styleUrls: ['./manage-profile-user-management.component.css']
})
export class ManageProfileUserManagementComponent {
  isEditIndex: number;
  p = 1;
  isEdit: boolean = false;
  isdropdownshow: boolean = false;
  submitted: boolean = false;
  searchtext: string = '';
  editedProfileVideoUrl: string = '';
  isPopup: boolean = false;
  page: string = 'rolecreation';
  placeholderText: string = 'Search with Id and Name';
  profileVideoUrl: any = [];
  selectedMemberId: any;
  modal = "updateModal";
  showModal: boolean = false;
  showDeleteVideoModal: boolean = false;
  selectedRoleVideo: any;
  profileVideoUrlEdit: boolean;
  userprofileVideoUrl: SafeResourceUrl | string = '';
  selectedProfileVideoUrl: string;
  previewBtn: boolean = false;
  onSubmit: any;
  videoModal: boolean;
  tempProfileVideoUrl: any;
  recordCount: number;
  selectedOption: any = 'name';
  searchedMemberID: any = '';
  searchedName: any = '';
  searchedUniqueID: any = '';
  isChecked: any;
  showPopup: boolean = false;
  buttonData: any = [];
  updateButton: boolean = false;
  profileStatus: boolean = true;
  isEditing: boolean = false;
  buttonStatus: any = true;
  newButtonName: string = '';
  newButtonLink: string = '';
  profileVideoUrlLink: any;
  selectedUserSub: any;
  oldButtonName: any = '';
  selectedUser: any = {};

  constructor(private spinner: NgxSpinnerService,private routeMakingService:RouteMakingService, private _apiService: ServerApisService, private router: Router, private sanitizer: DomSanitizer,private datePipe: DatePipe,
    private _alertService: AlertService,private _elementRef: ElementRef,private renderer:Renderer2,private dataService:DataService) { }

    ngOnInit(): void {
      const user = sessionStorage.getItem('user');
      if (user != null) {
        let data = JSON.parse(user);
        if (Role.Admin == data.roleId || Role.SubAdmin == data.roleId) {
          let date = new Date();
          
          let path=window.location.href.split('user-management/')
          
         } else {
          this.router.navigate([""]);
        }
  
      } else {
        this.router.navigate([this.routeMakingService.getNavigateUrl([Constants.Login])]);
      }
       this.getProfileVideoUrl(this.p);
       this.selectedUserSub = this.dataService.currentUserData.subscribe((data) => {
         this.selectedUser = data;
         this.buttonData = this.selectedUser ? this.selectedUser.profileCustomButtons : [];
       })
    }

    toggleEdit(){
      this.isEditing = !this.isEditing;
      this.clearForm();
    }

    closePop() {
      this.showPopup = false;
      this.clearForm();
    }
  
    clearForm(){
      this.newButtonName = '';
      this.newButtonLink = '';
      this.buttonStatus = true;
      this.updateButton = false;
      this.profileVideoUrlLink = '';
      this.oldButtonName = '';
      if(this.selectedUser.profileCustomButtons.length > 0){
        this.buttonData = this.selectedUser.profileCustomButtons.filter((item) => item.customButonName !== "");
      } else {
        this.buttonData = [];
      }
    }

    openPopup(user: any) {
      this.selectedUser = { ...user };
      console.log(this.selectedUser);
      
      this.showPopup = true;
      if(this.selectedUser.profileCustomButtons.length > 0){
        this.buttonData = this.selectedUser.profileCustomButtons.filter((item) => item.customButonName !== "");
      } else {
        this.buttonData = [];
      }
      this.profileStatus = this.selectedUser.profileIsActive === 'Y' ? true : false;
    }

    populateButtonData(button: any) {
      this.oldButtonName = button.customButonName;
      this.newButtonName = button.customButonName;
      this.newButtonLink = button.customButonUrl;
      this.buttonStatus = button.customButonIsActive === 'Y' ? true : false;
      this.updateButton = true;
    }
  
    updateProfile(event, type?: string) {
      this.updateProfileStatusIfNeeded(type, event);
      const profileVideoUrl = this.determineProfileVideoUrl(type);
      this.isEditing = false;
  
      const memberId = parseInt(sessionStorage.getItem('memberId'));
      const reqBody = this.buildRequestBody(memberId, profileVideoUrl);
  
      this._apiService.UpdateProfileVideoUrl(reqBody).subscribe(
        (res: any) => this.handleApiResponse(res),
        (error) => this._alertService.error('Error Occurred while saving record to DB')
      );
    }
  
    private updateProfileStatusIfNeeded(type: string, event: any) {
      if (type === 'profile') {
        this.profileStatus = event.target.checked;
      }
    }
  
    private determineProfileVideoUrl(type: string): string {
      if (type === 'addButton') {
        return this.selectedUser.profileVideoUrl;
      }
      return this.profileVideoUrlLink || this.selectedUser.profileVideoUrl;
    }
  
    private buildRequestBody(memberId: number, profileVideoUrl: string) {
      return {
        memberId: this.selectedUser.memberId,
        profileVideoUrl,
        createdBy: memberId,
        modifiedBy: memberId,
        profileIsActive: this.profileStatus ? 'Y' : 'N',
        customButonName: this.oldButtonName === '' ? this.newButtonName : this.oldButtonName,
        newCustomButonName: this.oldButtonName !== this.newButtonName ? this.newButtonName : this.oldButtonName,
        customButonUrl: this.newButtonLink,
        customButonIsActive: this.buttonStatus ? 'Y' : 'N',
      };
    }
  
    private handleApiResponse(res: any) {
      if (res.status == 200) {
        this._alertService.success(res.result);
        this.clearForm();
        this.getProfileVideoUrl(this.p);
      } else {
        this._alertService.error('Error Occurred while saving record to DB');
      }
    }

    getProfileVideoUrl(page?: number) {
      let payload = {
        "searchedMemberID": this.searchedMemberID != '' ? this.searchedMemberID : 0,
        "searchedName": this.searchedName,
        "searchedUniqueID":this.searchedUniqueID,
        "page": page,
      }
      this._apiService.GetProfileVideoUrl(payload).subscribe((res: any) => {
        this.profileVideoUrl = res.FetchAllUsersByProfileVideoUrlResult.resultFetchProfileVideoUrlArgs;
        this.tempProfileVideoUrl = this.profileVideoUrl;
        this.recordCount = res.FetchAllUsersByProfileVideoUrlResult.RecordCount;
        if(this.selectedUser !== '{}'){
          this.selectedUser = this.profileVideoUrl.find((item) => item.memberId === this.selectedUser.memberId);
          this.dataService.setUserData(this.selectedUser);
        }
        this.spinner.hide();
      });
    }

    updateProfileVideoUrl(): void {
      const reqBody = {
        "memberId": this.selectedMemberId,
        "profileVideoUrl": this.profileVideoUrl,
        "createdBy": parseInt(sessionStorage.getItem('memberId')),
        "modifiedBy": 0
      };
  
      this._apiService.UpdateProfileVideoUrl(reqBody).subscribe((res: any) => {
        if (res.status == 200) {
          this.spinner.show();
          this._alertService.success(res.result);
          this.getProfileVideoUrl(this.p);
        } else {
          this._alertService.error('Error Occured while saving record to DB');
        }
        this.showModal = false;
      });
    }
  
    deleteButton(button: any,event) {
      event.stopPropagation();
      this._apiService.DeleteProfileVideo(this.selectedUser.memberId, parseInt(sessionStorage.getItem('memberId')),button.customButonsId).subscribe((res: any) => {
        if (res.DeleteProfileVideoUrlResult.status == 200) {
          this.spinner.show()
          this._alertService.success(res.DeleteProfileVideoUrlResult.result);
          this.getProfileVideoUrl(this.p)
        } else {
          this._alertService.error('Error occured while deleting the video url');
        }
        this.showDeleteVideoModal = false;
      })
    }
  
    deleteProfileVideoUrlPop(data) {
      this.selectedRoleVideo = data.Name;
      this.selectedMemberId = data.memberId;
      this.showDeleteVideoModal = true;
    }
    dismissModal() {
      this.showDeleteVideoModal = false;
    }
    dropdownshow() {

     
      // if (this.isdropdownshow == false) {
      //   this.isdropdownshow = true;
      //   if (this.filterRole == 'All Roles') {
      //     this.filterRole = '';
      //   }
      // } else {
  
      //   this.isdropdownshow = false;
       
      // }
    }

    closeRoleDropdown(event: any) {
      // 
      // if(this.isdropdownshow==false){
      //   this.dropdownshow();
      // }
  
    }
    saveEdit(){
      this.isEditing = false;
    }
    cancelEdit(){
      this.isEditing = false;
    }

    cancelEditing(data: any, field: string): void {
      if (data.hasOwnProperty(field + 'Edit')) {
        data[field + 'Edit'] = false;
        data.userProfileVideoUrl = '';
      }
    }
  
    closePreviewModal(): void {
      this.previewBtn = false;
      this.userprofileVideoUrl = ''; 
    }

    saveData(data: any, field: string): void {
      const reqBody = {
        "memberId": data.memberId,
        "profileVideoUrl": data.userProfileVideoUrl,
        "createdBy": parseInt(sessionStorage.getItem('memberId')),
        "modifiedBy": 0
      };
  
      this._apiService.UpdateProfileVideoUrl(reqBody).subscribe((res: any) => {
        if (res.status == 200) {
          // this.spinner.show();
          this._alertService.success(res.result);
          this.getProfileVideoUrl(this.p);
        } else {
          this._alertService.error('Error Occurred while saving record to DB');
        }
       
  
      });
    }

    searchByUniqueId(event:any){
      this.getProfileVideoUrl(1);
    }
  
    searchByMemberId(event:any){
      this.getProfileVideoUrl(1);
    }
  
    searchByName(event:any){
      this.getProfileVideoUrl(1);
    }
  

    previewProfileVideo(videoUrl: string): void {
      if(videoUrl == ""){
         this.userprofileVideoUrl = this.getSafeUrl('https://youtu.be/nYM5ESfpp-g');
       } else {
         this.userprofileVideoUrl = this.getSafeUrl(videoUrl);
       }
       this.previewBtn = true;
       this.openVideoPreview();
     }
   
     editUserProfile(data: any, field: string): void {
       data[field + 'Edit'] = true;
   
       data.userProfileVideoUrl = data.profileVideoUrl;
     }

    resetOptions() {
      this.searchedMemberID = '';
      this.searchedName = '';
      this.searchedUniqueID = '';
      this.getProfileVideoUrl(1);
    }
  
  updateProfileVideoUrlPop(data): void {
      this.selectedMemberId = data.memberId;
      this.profileVideoUrl = data.profileVideoUrl;
      this.showModal = true;
    }
    closeVideoModal(): void {
      this.showModal = false;
      this.previewBtn = false;
    }
    openVideoPreview() {
      this.videoModal = true;
      this.openModal();
    }
    pageChanged(event: any) {
      this.p = event;
      this.getProfileVideoUrl(this.p);
      this.isEditIndex = -1;
      window.scrollTo(0, 0);
    }
  

    closeModal(): void {
      this.videoModal = false;
      const modal = this._elementRef.nativeElement.querySelector('#videoPreviewModal');
      this.renderer.setStyle(modal, 'display', 'none');
      this.renderer.removeClass(modal, 'show');
    }

    openModal(): void {
      const modal = this._elementRef.nativeElement.querySelector('#videoPreviewModal');
      this.renderer.addClass(modal, 'show');
      this.renderer.setStyle(modal, 'display', 'block');
      document.body.classList.add('modal-open');
      document.body.classList.add('no-backdrop'); 
    }

    private urlCache: { [url: string]: SafeResourceUrl } = {};
    getSafeUrl(url: string): SafeResourceUrl {
      if (this.urlCache[url]) {
        return this.urlCache[url];
      }
      let safeUrl: SafeResourceUrl;
      if (url.includes('youtube.com/watch?v=') || url.includes('youtu.be/')) {
        const embedUrl = this.getYoutubeEmbedUrl(url);
        if (!embedUrl) {
          return '';
        }
        safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(embedUrl);
      } else if (url.includes('shorts/')) {
        const videoId = this.extractShortsVideoId(url);
        if (!videoId) {
          return '';
        }
        const embedUrl = `https://www.youtube.com/embed/${videoId}`;
        safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(embedUrl);
      } else if (url.includes('fb.watch') || url.includes('fb') || url.includes('facebook')) {
        const videoId = this.extractFacebookVideoId(url);
        if (videoId) {
          const embedUrl = `https://www.facebook.com/plugins/video.php?href=${encodeURIComponent(url)}&width=500&show_text=false`;
          safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(embedUrl);
        } else {
          return '';
        }
      } else {
        return '';
      }
  
      this.urlCache[url] = safeUrl;
      return safeUrl;
    }
  
    videoUrlValidator(control: FormControl) {
      const videoUrl = control.value;
      if (!videoUrl) {
        return null;
      }
      const acceptedExtensions = ['.mp4', '.mov', '.avi', '.mkv', '.webm', '.ogg'];
      const isYouTubeUrl = videoUrl.includes('youtube.com/watch?v=') || videoUrl.includes('youtu.be/') || videoUrl.includes('youtube.com/shorts/') || videoUrl.includes('youtu.be/shorts/');
      const isFacebookUrl = videoUrl.includes('fb.watch') || videoUrl.includes('fb') || videoUrl.includes('facebook');
  
      if (isYouTubeUrl || isFacebookUrl) {
        return null;
      }
      const urlParts = videoUrl.split('.');
      const fileExtension = urlParts[urlParts.length - 1].toLowerCase();
  
      if (acceptedExtensions.includes(`.${fileExtension}`)) {
        return null;
      } else {
        return { invalidVideoUrl: true };
      }
    }
  
    extractShortsVideoId(url: string): string | null {
      const shortsRegex = /(?:youtube\.com\/shorts\/|youtu.be\/shorts\/)([a-zA-Z0-9_-]{11})/i;
      const match = url.match(shortsRegex);
  
      if (match && match[1]) {
        return match[1];
      } else {
        return null;
      }
    }
  
    extractFacebookVideoId(url: string): string | null {
      const regex = /(?:fb\.watch\/|facebook\.com\/.*\/videos\/)([a-zA-Z0-9_-]+)/i;
      const match = url.match(regex);
  
      if (match && match[1]) {
        return match[1];
      } else {
        return null;
      }
    }
  
    getYoutubeEmbedUrl(youtubeUrl: string): string | null {
      const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
      const match = youtubeUrl.match(regex);
      if (match && match[1]) {
        return `https://www.youtube.com/embed/${match[1]}`;
      }
      return null;
    }
  
  

}
