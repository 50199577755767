<div class="whole-section">
<nav aria-label="breadcrumb" class="mlft-6">
    <app-bread-crumb [breadTitle]="advertisementTitle" [breadpath1]="adminPanel" [breadpath2]="advertisement" [breadpath3]="advertisementNav" [breadroute1]="adminPanelRoute"></app-bread-crumb>
</nav>
  <div class="row">
    <div class="col-3">
   </div>
  </div>
  <div class="table-responsive" style="padding: 1%;">
    <div class="row " style="background-color: #fff;">
      <div class="col-md-2 left-section"  *ngIf="isCard"(mouseleave)="onMouseLeave()"
      style="z-index: 900;position: absolute;top: 0px;">
        <div class="row">
          <div class="col-md-12" *ngIf="isCard">
            <div class="Rectangle-562">
              <!-- <div class="row">
                <div class="col-md-10" style="padding-right: 0;padding-top: 12px;text-align: initial;">
                  <label class="Reports">Advertisement</label>
                  
                </div>
                <div class="col-md-2" style="text-align: end;padding-right: 0;">
                  <img src="../../../assets/images/double-right-arrow.png" (click)="menu()" 
                  style="cursor: pointer;" class="leftimage-1" alt="double right arrow" title="double right arrow">
                </div>
              </div> -->
            </div>
            <div class="hand" (click)="searchtab('createadvertisements')"
              [ngClass]="{'Rectangle-563-copy': page!='createadvertisements', 'Rectangle-563-selected active' : page=='createadvertisements' }">
              <p class="mr-0">Post Advertisement<span style="float: right;"></span></p>
            </div>
            <div class="hand" (click)="searchtab('advertisement')"
              [ngClass]="{'Rectangle-563-copy': page!='advertisement', 'Rectangle-563-selected active' : page=='advertisement' }">
              <p class="mr-0">Modify Advertisement<span style="float: right;"></span></p>
            </div>
             </div>
        </div>
     </div>
     <div class="col-md-1 leftminisec" (mouseenter)="onMouseEnter()">
      <div style="text-align: center; margin-bottom: 10px;">
        <!-- Content goes here -->
      </div>
    </div>
    <div class="content-section"  [ngClass]="{'col-10': isCard, 'col-11' : !isCard}">
      <ng-container *ngIf="page === 'advertisement'">
          <table class="common-table">
          <app-modify-advertisement-advertisement></app-modify-advertisement-advertisement>


            </table>
      </ng-container>
      <ng-container *ngIf="page === 'createadvertisements'">
          <table class="common-table">
    <app-post-advertisement-advertisement></app-post-advertisement-advertisement>

       </table>
      </ng-container>

    </div>
  </div>
 <!-- <div class="profile-content">
    <div class="container-connecting-dots">
      <div class="profile-section">
        <div class="row insection">
          <h6 class="head"><strong>Advertisement</strong> </h6>

          <form class="login-form pad-form" [formGroup]="advertisementForm">
            <div class="row form-group  ">
              <div class="col-md-6">
                <label class="bld">Client Type</label>
                <select class="form-control form-select width-100 inpt" formControlName="clientType"
                  (change)="clientTypeChange($event)">
                  <option value="InternalClient">Internal Client</option>
                  <option value="ExternalClient">External Client</option>
                </select>
              </div>
              <div class="col-md-6" *ngIf="advertisementForm.controls.clientType.value=='InternalClient'">
                <label class="bld">Client Navigation </label>
                <select class="form-control form-select width-100 inpt" formControlName="clientNavigation"
                  (change)="clientNavigationChange($event)">
                  <option value="Profile">Profile</option>
                  <option value="Website">Website</option>
                </select>
              </div>
            </div>
            <div class="form-group mt-2">
              <label class="bld">Title <span style="color: red;">*</span></label>
              <input type="text" class="form-control inpt" formControlName="title" maxlength="50"
                style="font-weight: bold;" [ngClass]="{ 'is-invalid': submitted && f.title.errors }">

              <div *ngIf="(submitted && advertisementForm.controls.title.errors)
                  ||(advertisementForm.controls.title.errors && advertisementForm.controls.title.touched)"
                class="text-danger">
                <div *ngIf="advertisementForm.controls.title.errors.required">Title is required</div>
              </div>
            </div>
            <div class="form-group mt-2">
              <label class="bld">Description <span style="color: red;">*</span></label>
              <textarea type="textarea" class="form-control inpt" formControlName="description" maxlength="50"
                style="font-weight: bold;height: 49px;" [ngClass]="{ 'is-invalid': submitted && f.description.errors }"></textarea>

              <div *ngIf="(submitted && advertisementForm.controls.description.errors)
                  ||(advertisementForm.controls.description.errors && advertisementForm.controls.description.touched)"
                class="text-danger">
                <div *ngIf="advertisementForm.controls.description.errors.required">Description is required</div>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-4 mt-2" *ngIf="advertisementForm.controls.clientType.value=='InternalClient' && 
              advertisementForm.controls.clientNavigation.value=='Profile'">
                <label class="bld">Sender<span style="color: red;">*</span></label>
                <ng-select [items]="lstUsers" bindLabel="userId" placeholder="Select Sender" #selectBox
                                (change)="changeuser($event)">
                                </ng-select>
                <div *ngIf="(submitted && f.navigatingToMemberId.value=='0' && f.clientType.value=='InternalClient' && 
                f.clientNavigation.value=='Profile' ) ||(f.navigatingToMemberId.value=='0' && f.navigatingToMemberId.touched)"
                  class="text-danger">
                  <div >Please Select Sender</div>
                </div>
              </div>
              <div class="form-group col-4 mt-2">
                <label class="bld">Screen<span style="color: red;">*</span></label>
         <select class="form-control form-select width-100 inpt" formControlName="screen" (change)="screenChange($event)"
                [ngClass]="{ 'is-invalid': submitted && f.screen.errors }">
                  <option value="">--Select--</option>
                  <option value="Dashboard">Dashboard</option>
                  <option value="Profile">Profile</option>
                </select>
                <div *ngIf="(submitted && advertisementForm.controls.screen.errors)
                  ||(advertisementForm.controls.screen.errors && advertisementForm.controls.screen.touched)"
                  class="text-danger">
                  <div >Please Select screen</div>
                </div>
              </div>
              <div class="form-group col-4 mt-2">
                <label class="bld">Category <span style="color: red;">*</span></label>
                <select class="form-control form-select width-100 inpt" formControlName="category" [ngClass]="{ 'is-invalid': submitted && f.category.errors }">
                  <option value="">--Select--</option>
                  <option value="Top" *ngIf="advertisementForm.controls.screen.value!='Profile'">Top</option>
                  <option value="Bottom">Bottom</option>
                </select>
                <div *ngIf="(submitted && f.category.errors) || (f.category.errors && f.touched)"
                  class="text-danger">
                  <div >Please Select category</div>
                </div>
              </div>
              <div class="form-group col-4 mt-2">
                <label class="bld">Image Upload <span style="color: red;">*</span></label>
                <div class="right-inner-addon input-container">
                  <i class="fa fa-cloud-upload imgupld" (click)="fileUpload.click()"><input #fileUpload
                      style="display: none;" type="file" accept=".jpeg,.jpg,.png,.gif,.tiff" id="upload-file"
                      name="upload-file" accept="image/png, image/gif, image/jpeg" (change)="addAttachment($event)"></i>
                  <input type="text" class="form-control inpt mb-0" aria-describedby="basic-addon2"
                    (click)="fileUpload.click()" formControlName="image" readonly
                    [ngClass]="{ 'is-invalid': submitted && f.image.errors }"
                    style="border-style: dashed !important;background-color: #fff;">
                  <label class="mb-1">Accepted file types jpeg (or jpg), png, gif, tiff</label>

                  <div *ngIf="submitted && f.image.errors " class="invalid-feedback text-danger">
                    <div *ngIf="f.image.errors?.required">Image is required</div>
                  </div>
                </div>
              </div>
              <div class="form-group col-4 mt-2">
                <label class="bld">Video Url Upload</label>
                <div class="right-inner-addon input-container">
                  <i class="fa fa-play-circle vdupld"></i>
                  <input type="url" class="form-control inpt mb-0" aria-describedby="basic-addon2"
                    formControlName="vediopath" [ngClass]="{ 'is-invalid': submitted && f.vediopath.errors }">
                  <label class="mb-1">Accepted file types mp4, mov, avi, mkv, webm, ogg</label>
                  <div *ngIf="submitted && f.vediopath.errors " class="invalid-feedback">
                    <div *ngIf="f.vediopath.errors?.pattern">Please provide valid url</div>
                  </div>
                </div>
              </div>
              <div class="form-group col-4 mt-2">
                <label class="bld">Expiry Date <span style="color: red;">*</span></label>
                <input type="date" class="form-control inpt" formControlName="date" placeholder="mm-dd-yyyy"
                  onkeydown="return false" min="{{minDate}}" [ngClass]="{ 'is-invalid': submitted && f.date.errors }">
                <div *ngIf="submitted && f.date.errors" class="invalid-feedback text-danger">
                  <div *ngIf="f.date.errors.required">Date is required</div>
                </div>
              </div>
              <div class="form-group col-4 mt-2">
                <label class="bld">Price</label>
                <input type="text" class="form-control inpt" formControlName="price" maxlength="50"
                  (keypress)="OnlyNumbersAllowed($event)" style="font-weight: bold;">
              </div>

              <div class="form-group col-4 mt-2">
                <label class="bld">Contact Email</label>
                <input type="text" class="form-control inpt" formControlName="email" maxlength="50"
                  style="font-weight: bold;">
              </div>
              <div class="form-group col-4 mt-2">
                <label class="bld">Contact phone</label>
                <input type="text" class="form-control inpt" formControlName="phone" maxlength="10"
                  (keypress)="OnlyNumbersAllowed($event)" style="font-weight: bold;">
              </div>

              <div class="form-group col-4 mt-2" *ngIf="(advertisementForm.controls.clientType.value=='InternalClient' && 
              advertisementForm.controls.clientNavigation.value=='Website')|| (advertisementForm.controls.clientType.value=='ExternalClient')">
                <label class="bld">Redirect Link</label>
                <input type="text" class="form-control inpt" formControlName="redirectlink" 
                  style="font-weight: bold;">
              </div>
            </div>


            <div class="row pt-7">
              <button type="submit" class="btn btn-primary text-center mt-2 sendbtn" (click)="onSubmit()">Send</button>
            </div>

          </form>
        </div>
      </div>
    </div>
  </div> -->
</div>