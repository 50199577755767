import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService } from 'src/app/shared/alert/alert.service';
import { Role } from 'src/app/shared/enums/role.enum';
import { ServerApisService } from 'src/app/shared/services/server-apis.service';
import { Constants,navConstants } from 'src/app/shared/constants/constant';
import { RouteMakingService } from '../../shared/services/route-making.service';
import { SitemapService } from '../../shared/services/sitemap.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-main-profession-admin-approval',
  templateUrl: './main-profession-admin-approval.component.html',
  styleUrls: ['./main-profession-admin-approval.component.css']
})
export class MainProfessionAdminApprovalComponent {
  lstallcategories: any = [];
  lstalltempcategories: any = [];
  page = 'Main Profession Approval';
  mainProfessions: any;
  tempMainProfession: any;
  searchtext: string = '';
  adminApprovalCategories: any = [];
  tempAdminApprovalCategories: any = [];
  ProfessionNameNew: any;
  professionName: string = '';
  isEditIndex: number;
  p: number = 1;
  selectedMainProfession: any = '';
  selectedMainProfessionId: any;
  approvedCategories: any = [];
  rejectedCategories: any = [];
  pendingProfessions: any = [];
  tempPendingProfession: any = [];
  tempApprovedCategory: any = [];
  temprejectedCategory: any = [];
  showDeleteModal: boolean = false;
  currentData: any;
  currPagePendingMain: number;
  currPageApprovedMain: number;
  selectedOption: string = '';
  selectedAction: string;
  showBreadcrumb3: boolean = false;
  selectedTab: string = 'mainPending'; 
  mainPlaceholder = 'Search for Main Profession';
  searchtextApproved: string = '';
  searchtextRejected: string = '';

  constructor(private sitemapService:SitemapService,private routeMakingService:RouteMakingService,private router: Router, private cdr: ChangeDetectorRef, private _apiService: ServerApisService, protected alertService: AlertService, private spinner: NgxSpinnerService,) { }

  ngOnInit(): void {
    this.fetchMainProfession();
    this.getAllAdminApprovalCategory('pending');
    //  this.clearSearchTexts();
     this.fetchData();
    localStorage.removeItem('selectedTab');
    }

    fetchData() {
      const user = sessionStorage.getItem('user');
      if (user != null) {
        let data = JSON.parse(user);
        if (data.roleId !== Role.Admin && data.roleId !== Role.SubAdmin) {
          this.router.navigate([""]);
          return;
        }
      } else {
       this.router.navigate([this.routeMakingService.getNavigateUrl([Constants.Login])]);
        return;
      }
    }

    clearSearchTexts() {
      throw new Error('Method not implemented.');
    }

    clearFields(){
      this.searchtextApproved = '';
      this.searchtext = '';
      this.searchtextRejected = ''
      }

    pageChanged(event: any) {
      this.p = event;
      this.isEditIndex = -1;
    }

    toggleIcon(data: any) {
      data.showMetaInfo = !data.showMetaInfo;
  }

  fetchMainProfession() {
    this._apiService.GetMainProfession().subscribe((res: any) => {
      this.mainProfessions = res.FetchAllMainProfessionsResult.resultFetchProfessionAdminApproval;
      this.tempMainProfession = this.mainProfessions;
    })
  }

  getAllAdminApprovalCategory(type: string) {
    this.spinner.show();
    this._apiService.GetAdminApprovalCategories(type).subscribe(
      (res: any) => {
        this.spinner.hide();
        this.adminApprovalCategories = res.FetchCategoriesAdminApprovalResult.resultFetchProfessionAdminApproval;
        this.tempAdminApprovalCategories = this.adminApprovalCategories;
      }
    );
  }

  searchRejectedMainProfession(event) {
    if (event.target.value != "" && event.target.value != null && event.target.value != undefined) {
      var result = this.adminApprovalCategories.filter((x: any) => x.mainProfessionName.toLowerCase().includes(event.target.value.toLowerCase()))
      this.adminApprovalCategories = result;
    } else {
      this.adminApprovalCategories = this.tempAdminApprovalCategories;
    }
  }
  searchApprovedMainProfession(event) {
    if (event.target.value != "" && event.target.value != null && event.target.value != undefined) {
      var result = this.adminApprovalCategories.filter((x: any) => x.mainProfessionName.toLowerCase().includes(event.target.value.toLowerCase()))
      this.adminApprovalCategories = result;
    } else {
      this.adminApprovalCategories = this.tempAdminApprovalCategories;
    }
  }
  searchMainProfessionApproval(event: any) {
    if (event.target.value != "" && event.target.value != null && event.target.value != undefined) {
      var result = this.adminApprovalCategories.filter((x: any) => x.mainProfessionName.toLowerCase().includes(event.target.value.toLowerCase()))
      this.adminApprovalCategories = result;
    } else {
      this.adminApprovalCategories = this.tempAdminApprovalCategories;
    }
  }

  dismissModal() {
    this.showDeleteModal = false;
    }

    approveMain(data) {
      this.selectedMainProfession = data.mainProfessionName;
      this.selectedMainProfessionId = data.mainProfessionId;
      this.showDeleteModal = true;
    }
  
    rejectedMain(data) {
      this.selectedMainProfession = data.mainProfessionName;
      this.selectedMainProfessionId = data.mainProfessionId;
      this.showDeleteModal = true;
  
    }

    approveFunction(data: any) {
    
    }
    
    rejectFunction(data: any) {
       
    }
    cancelFunction(data: any) {
     data.showMetaInfo = false;
     data.expand = false;
    }

     getDataFromApi(tab: string) {
      this.selectedTab = tab;
        switch (tab) {
        case 'mainPending':
        this.getAllAdminApprovalCategory('pending');
           break;
        case 'mainApproved':
        this.getAllAdminApprovalCategory('approved');
          break;
       case 'mainRejected':
       this.getAllAdminApprovalCategory('rejected');
         break;
        default:
          break;
      }
     }

    dropdownAction(action: string, d: any, component: string) {
      this.currentData = d;
      if (action === 'selected') {
        this.selectedOption = '';
        this.showDeleteModal = false;
        this.selectedMainProfession='';
      } else if (action === 'approved' || action === 'reject') {
        if (this.selectedOption !== action) {
          this.selectedOption = action;
          this.selectedMainProfession='';
          this.selectedMainProfession = d.mainProfessionName;
          this.selectedAction = action;
        }
        if (component === 'main') {
          this.showDeleteModal = true;
        } else if (component === 'child') {
          this.showDeleteModal = false;
        } else if (component === 'user') {
          this.showDeleteModal = false;
           }
      }
    }
  
    handleApprovalOrRejection() {
      if (this.selectedAction === 'approved') {
        this.approvedMain();
      } else if (this.selectedAction === 'reject') {
        this.rejectMain();
      }
      this.selectedAction = '';
    }


    approvedMain() {
      let data = this.currentData;
      let reqBody = {
        "mainProfessionId": data.mainProfessionId,
        "mainProfessionName": data.mainProfessionName,
        "mainProfessionNameNew": data.mainProfessionNameNew,
        "approvedBy": sessionStorage.getItem('memberId'),
        "isDeleted": data.isDeleted,
        "isApproved": "Y"
      }
      this._apiService.AdminApprovalApi(reqBody).subscribe((res: any) => {
        if (res.status == 200) {
          this.alertService.success(res.result);
          // call api for storing into sitemap
          if(data.isDeleted == 'N'  && data.mainProfessionNameNew != ''){
            let url = environment.sitemapUrl + data.mainProfessionNameNew + '.xml';
            url = url.replace(/\s+/g, '-');
            this.sitemapService.storeSitemapToSearchDB(url.toLowerCase(),'newlyAdded');
          } else if(data.isDeleted == 'N' && data.mainProfessionNameNew == ''){
            let url = environment.sitemapUrl + data.mainProfessionName + '.xml';
            url = url.replace(/\s+/g, '-');
            this.sitemapService.storeSitemapToSearchDB(url.toLowerCase(),'newlyAdded');
          }
          this.getAllAdminApprovalCategory('approved');
        } else {
          this.alertService.error(res.result)
        }
      })
      this.showDeleteModal = false;
    }
  
    rejectMain() {
      let data = this.currentData;
      let reqBody = {
        "mainProfessionId": data.mainProfessionId,
        "mainProfessionName": data.mainProfessionName,
        "mainProfessionNameNew": data.mainProfessionNameNew,
        "approvedBy": sessionStorage.getItem('memberId'),
        "isDeleted": data.isDeleted,
        "isApproved": "N"
      }
      this._apiService.AdminApprovalApi(reqBody).subscribe((res: any) => {
        if (res.status == 200) {
          this.alertService.success(res.result);
          this.getAllAdminApprovalCategory('rejected');
        } else {
          this.alertService.error(res.result)
        }
      })
      this.showDeleteModal = false;
    }

}
