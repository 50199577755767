<div class="whole-section">
  <nav aria-label="breadcrumb" class="mlft-6">
    <app-bread-crumb [breadTitle]="adminAppprovalTitle" [breadpath1]="adminPanel" [breadpath2]="adminapproval"
      [breadpath3]="professionNav" [breadroute1]="adminPanelRoute"></app-bread-crumb>
  </nav>
  <div class="row">
    <div class="col-3">

    </div>
  </div>

  <div class="table-responsive" style="padding: 1% 16px 0px 12.3px;">
    <div class="row" style="background-color: #fff;">
      <div class="col-md-1 leftminisec" (mouseenter)="onMouseEnter()">
        <!-- <div
           [ngClass]="{'Rectangle-563-copy': page !== 'usersfordigitalteam', 'Rectangle-563-selected active' : page === 'usersfordigitalteam'}">
          
           </div>
       <div
           [ngClass]="{'Rectangle-563-copy': page !== 'allusersdisplay', 'Rectangle-563-selected active' : page === 'allusersdisplay'}">
           <div class="align-icon"><i class="fa-solid fa-user-pen"></i></div>
       </div>
       <div
           [ngClass]="{'Rectangle-563-copy': page !== 'notifications', 'Rectangle-563-selected active' : page === 'notifications'}">
           <div class="align-icon"><i class="fa-solid fa-bell"></i></div>
       </div>
       <div
           [ngClass]="{'Rectangle-563-copy': page !== 'dailycount', 'Rectangle-563-selected active' : page === 'dailycount'}">
           <div class="align-icon"><i class="fa-solid fa-calendar-days"></i></div>
       </div>
       <div
           [ngClass]="{'Rectangle-563-copy': page !== 'profiledata', 'Rectangle-563-selected active' : page === 'profiledata'}">
           <div class="align-icon"><i class="fa-regular fa-address-card"></i></div>
       </div>
       <div
           [ngClass]="{'Rectangle-563-copy': page !== 'activityData', 'Rectangle-563-selected active' : page === 'activityData'}">
           <div class="align-icon"><i class="fa-solid fa-users"></i></div>
       </div> -->
      </div>
      <div class="col-md-2 left-section" *ngIf="isCard" (mouseleave)="onMouseLeave()"
        style="z-index: 900;position: absolute;top: 0px;">
        <div class="row">
          <div class="col-md-12" *ngIf="isCard">
            <div class="Rectangle-562">
            </div>
            <div class="hand" (click)="searchtab('Main Profession Approval')"
              [ngClass]="{'Rectangle-563-copy': page!='Main Profession Approval', 'Rectangle-563-selected active' : page=='Main Profession Approval' }">
              <p class="mr-0">Main Profession<span style="float: right;"></span></p>
            </div>
            <div class="hand" (click)="searchtab('Child Profession Approval')"
              [ngClass]="{'Rectangle-563-copy': page!='Child Profession Approval', 'Rectangle-563-selected active' : page=='Child Profession Approval' }">
              <p class="mr-0">Child Profession<span style="float: right;"></span></p>
            </div>
            <div class="hand" (click)="searchtab('User Roles Approval')"
              [ngClass]="{'Rectangle-563-copy': page!='User Roles Approval', 'Rectangle-563-selected active' : page=='User Roles Approval' }">
              <p class="mr-0">User Roles<span style="float: right;"></span></p>
            </div>
          </div>
        </div>
      </div>
      <div class="content-section" [ngClass]="{'col-10': isCard, 'col-11': !isCard}">
        <ng-container *ngIf="page === 'Main Profession Approval'">
          <table class="common-table">
            <app-main-profession-admin-approval></app-main-profession-admin-approval>
          </table>
        </ng-container>
        <ng-container *ngIf="page === 'Child Profession Approval'">
          <table class="common-table">
            <app-child-profession-admin-approval></app-child-profession-admin-approval>
          </table>
        </ng-container>
        <ng-container *ngIf="page === 'User Roles Approval'">
          <table class="common-table">
            <app-user-roles-admin-approval></app-user-roles-admin-approval>
          </table>
        </ng-container>
      </div>
    </div>
  </div>
</div>