<div class="" style="background: #f0f0f0;">

    <nav aria-label="breadcrumb" class="mlft-6">
        <app-bread-crumb [breadTitle]="accountVerificationTitle" [breadpath1]="adminPanel" [breadpath2]="accountVerification"
          [breadroute1]="adminPanelRoute" [showBreadcrumb3]="false">
        </app-bread-crumb>
      </nav>
    <div  style="padding: 5px;" class="col-md-12">
        <div class="table-title">
            <div class="row">
                
                <div class="search-box">
                    <!-- <div class="input-group searchinput"> -->
                        <div class="row">
                            <!-- <div class="col-1" (click)="menu()"><i class="fa fa-bars" aria-hidden="true" style="color: #fff;"></i></div> -->
                            <div class="col-12"> <input type="text" id="search" class="form-control " autocomplete="off"
                                placeholder="Search with Name, Id and UniqueCode" [(ngModel)]="searchTerm"
                                style="color: #000;height: 38px;" (input)="searchChange($event.target.value)">
                            <!-- <span class="input-group-addon" style="padding-right: 12%;"><i class="fa fa-search" aria-hidden="true"></i></span> &nbsp; -->
                        </div>
                        </div>
                       

                    <!-- </div> -->

                </div>

            </div>
        </div>
    <table class="table table-striped">
        <thead>
            <tr>
                <th style="width: 8%;" class="padleft-30">ID</th>
                <th style="width: 10%;">UniqueCode</th>
                <th style="width: 14%;">Name</th>
                <th style="width: 11%;">Mobile No</th>
                <th style="width: 23%;">AccountVerified / Expiry</th>
                <th style="width: 23%">ProfessionVerified / Expiry</th>
                <th style="width: 23%;">BusinessVerified / Expiry</th>
                <!-- <th style="width: 11%;">AccountVerifiedExpiry</th>
                <th style="width: 12%;">ProfessionVerifiedExpiry</th>
                <th style="width: 12%;">BusinessVerifiedExpiry</th> -->
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let d of lstAccountVerificationDetails| paginate: { itemsPerPage: 10, currentPage: p};let i = index"
            style="color: #657376;">
            <td style="width: 8%;" class="hand padleft-30" onclick="document.getElementById('registers').style.display='block'" (click)="getProfileDetails(d)">{{d.memberId}}</td>
                <td style="width: 10%;" class="hand" onclick="document.getElementById('registers').style.display='block'" (click)="getProfileDetails(d)">{{d.UniqueCode}}</td>
                <td style="width: 14%;" class="hand" onclick="document.getElementById('registers').style.display='block'" (click)="getProfileDetails(d)">{{d.Name}}</td>
                <td style="width: 11%;" class="hand" onclick="document.getElementById('registers').style.display='block'" (click)="getProfileDetails(d)">{{d.phone| slice:0:10}}</td>
                
                <td style="width: 23%;">
                    
                        <div class="row pad-right50">
                            <div class="col-md-3 pad-left-0">
                                <div class="form-check form-switch ">
                                <input class="form-check-input chck-box hand" type="checkbox" role="switch" id="flexSwitchCheckChecked" [checked]="d.accountVerified=='Y'" (change)="changeAccountVerified($event,i,d)"
                                 ></div>
                            </div>
                            <div class="col-md-9 pad-left-0">
                                <input type="date" class="form-control hand" onkeydown="return false"  min="{{minDate}}" [disabled]="d.accountVerified=='N'" [(ngModel)]="d.accountVerifiedExpiry" (change)="dateChange($event,d,'account')">
                                <label style="color: red;" *ngIf="isaccountVerified && selectmemberid==d.memberId">Date is required</label>
                            </div>
                        </div>
                </td>
                <td style="width: 23%">
                    <div class="row pad-right50">
                        <div class="col-md-3 pad-left-0">
                            <div class="form-check form-switch ">
                            <input class="form-check-input chck-box hand" type="checkbox" role="switch" id="flexSwitchCheckChecked" [checked]="d.professionVerified=='Y'"  (change)="changeProfessionVerified($event,i,d)"
                             ></div>
                        </div>
                        <div class="col-md-9 pad-left-0">
                            <input type="date" class="form-control hand" onkeydown="return false"  min="{{minDate}}" [disabled]="d.professionVerified=='N'" [(ngModel)]="d.professionVerifiedExpiry"  (change)="dateChange($event,d,'profession')">
                            <label style="color: red;" *ngIf="isprofessionVerified && selectmemberid==d.memberId">Date is required</label>
                        </div>
                    </div>
                </td>
                <td style="width: 23%;">
                    <div class="row pad-right50">
                        <div class="col-md-3 pad-left-0">
                            <div class="form-check form-switch ">
                            <input class="form-check-input chck-box hand" type="checkbox" role="switch" id="flexSwitchCheckChecked" [checked]="d.businessVerified=='Y'"  (change)="changeBusinessVerified($event,i,d)"
                            ></div>
                        </div>
                        <div class="col-md-9 pad-left-0">
                            <input type="date" class="form-control hand" onkeydown="return false"  min="{{minDate}}" [disabled]="d.businessVerified=='N'" [(ngModel)]="d.businessVerifiedExpiry" (change)="dateChange($event,d,'bussiness')">
                            <label style="color: red;" *ngIf="isbusinessVerified && selectmemberid==d.memberId">Date is required</label>
                        </div>
                    </div>
                </td>
                <!-- accountVerifiedExpiry
                businessVerifiedExpiry
                professionVerifiedExpiry -->
                <!-- <td style="width: 11%;">{{d.AccountVerifiedExpiry}}</td>
                <td style="width: 12%;">{{d.ProfessionVerifiedExpiry}}</td>
                <td style="width: 12%;">{{d.BusinessVerifiedExpiry}}</td> -->
            </tr>
        </tbody>
    </table>
    <div class="row">
        <div class="col">
            <div class="d-flex justify-content-center" *ngIf="lstAccountVerificationDetails.length!=0">
                <pagination-controls (pageChange)="pageChanged($event)"
                    (pageBoundsCorrection)="pageChanged($event)">
                </pagination-controls>
            </div>
        </div>
    </div>
    </div>
</div>

<div class="modal" id="registers" style="overflow: hidden;" >
    <!-- <div class="modal-dialog"> -->
        <div class="modal-content">
            <div class="modal-header" *ngIf="isprofileData">
                <h4 class="modal-title text-center" id="myModalLabel">More About {{profileData.Name}}</h4><span onclick="document.getElementById('registers').style.display='none'" class="close hand"
                title="Close Modal" >×</span>
                </div>
            <div class="modal-body" *ngIf="isprofileData">
                <div class="text-center">
                    <img *ngIf="image!=null" class="user-img cover" src="{{image}}" alt="profileImg" title="profileImg"
                    (error)="myImage()">
                <div *ngIf="imagetext!= null" id="profileImage" class="profileImg ">{{imagetext}}</div>
                </div>
                <div class="row" style="padding: 1%;" >
                    <p class="text-left"><strong>Details: </strong><br>
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-4">
                                <P>ID</P>
                            </div>
                            <div class="col-md-8">
                                <p>: &nbsp; &nbsp; {{profileData.memberId}}</p>
                            </div>
                            <div class="col-md-4">
                                <P>Name</P>
                            </div>
                            <div class="col-md-8">
                                <p>: &nbsp;&nbsp;{{profileData.Name}}</p>
                            </div>
                            <div class="col-md-4">
                                <P>Phone</P>
                            </div>
                            <div class="col-md-8">
                                <p>: &nbsp;&nbsp; {{profileData.phone| slice:0:10}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-4">
                                <P>UniqueCode</P>
                            </div>
                            <div class="col-md-8">
                                <p>: &nbsp;&nbsp; {{profileData.UniqueCode}}</p>
                            </div>
                            <div class="col-md-4">
                                <P>Email</P>
                            </div>
                            <div class="col-md-8">
                                <p>: &nbsp;&nbsp; {{profileData.email}}</p>
                            </div>
                            <div class="col-md-4">
                                <P>Address</P>
                            </div>
                            <div class="col-md-8">
                                <p>: &nbsp;&nbsp; {{profileData.address}}</p>
                            </div>
                        </div>
                    </div>
                    <p class="text-left" style="margin: 0;"><strong>Skills: </strong><br>
                    <div class="col-md-12" style="padding: 1%;">
                        {{profileData.Skills}}
                        <!-- <button class="service" 
                        style="margin-left: 5px;">Doctor</button>
                        <button class="service" 
                        style="margin-left: 5px;">Teacher</button>
                        <button class="service" 
                        style="margin-left: 5px;">Yoga Teacher</button>
                        <button class="service" 
                        style="margin-left: 5px;">Biology Teacher</button> -->
                    </div>
                </div>
            </div>
            
        </div>
    <!-- </div> -->
  
  </div>