<div class="container">
  <div class="row">
    <div class="col-12" style="border: solid 1px #c8c8c8;">
      <ng-container *ngIf="!UserProfile">
        <div class="row" style="margin-top: 4%;">
          <div class="col-md-4">
            <p class="rate-service">Rate the service</p>
          </div>
          <!-- Star rating input -->
          <div class="col-md-12">
            <div class="profile-rating-list">
              <a class="star" *ngFor="let item of starQualityList; let i = index" (click)="setQualityStar(i)">
                <i class="fa" [ngClass]="{'fa-star-o emoji': !item, 'fa-star profile-rating-list-active': item}"></i>
              </a>
            </div>
          </div>
        </div>

        <div class="form-group" style="padding-top: 5px;">
            <!-- <div class="text-end" style="color: red;" *ngIf="submitted && (Qrating==0)">
              <label>Please enter valid inputs</label>
          </div> -->
          <textarea class="form-control" rows="3" [(ngModel)]="review" placeholder="Add Review"></textarea>
        </div>
        <div class="col-12 col-md-12 textend">
          <button type="button" (click)="submitRatingAndReview(providerInfo.memberId)" 
                  class="share-btn" [disabled]="!isSubmitEnabled()">Submit</button>
        </div>
      </ng-container>

      <!-- List of reviews -->
      <div class="col-md-12 review-main" *ngIf="ratingAndReview.length > 0">
        <div class="row pad-4" *ngFor="let r of ratingAndReview">
          <div class="col-md-1 col-sm-2 col-3">
            <img [src]="r.imagePath" class="rating-img img-responsive" alt="Image" title="image" onerror="this.src='assets/images/person.jpg';">
          </div>
          <div class="col-md-7 col-sm-9 col-8 user-details">
            <label>{{r.userName | titlecase}}</label><br>
             <!-- <ng-template #t let-fill="fill">
                                                <span class="fetchstar" [class.full]="fill === 100">
                                                    <span class="half" [style.width.%]="fill">&#9733;</span>&#9733;
                                                </span>
                                            </ng-template>

                                            <ngb-rating [(rate)]="r.Average" [starTemplate]="t" [readonly]="true" max="5">
                                            </ngb-rating> -->
            <ng-template #t let-fill="fill">
              <span class="fetchstar mr-5" [class.full]="fill === 100">
                <span class="half" [style.width.%]="fill">&#9733;</span>&#9733;
              </span>
            </ng-template>
            <ngb-rating [(rate)]="r.Average" [starTemplate]="t" [readonly]="true" max="5"></ngb-rating>
          </div>
          <div class="col-md-4 pl-0 offset-sm-3 offset-md-0 col-sm-8 offset-2 col-9">
            <label class="date-align">{{r.modifiedDate}}</label>
          </div>
          <div class="col-sm-9 col-md-7 offset-sm-3 offset-md-2 offset-2 col-9" style="padding-left: 0;">
            <p class="align-reviews-padding">{{r.comment | titlecase}}</p>
          </div>
        </div>
      </div>
      <div *ngIf="ratingAndReview.length == 0" style="padding: 4%; text-align: center;">
        <strong>No Reviews Found!</strong>
      </div>
    </div>
  </div>
</div>
