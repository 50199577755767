
<app-dashboard-services  *ngIf="!isSearchResultsPage && !isSubProfessionPage"></app-dashboard-services>
<app-child-professions (childEvent)="handleChildData($event)" *ngIf="!isSearchResultsPage && isSubProfessionPage"></app-child-professions>
<app-download-app *ngIf="!isSearchResultsPage"></app-download-app>
<app-near-by-services *ngIf="!isSearchResultsPage"></app-near-by-services>
<!-- <app-service-provider-list *ngIf="isSearchResultsPage"></app-service-provider-list> -->
<!-- ADD SERVICE MODAL -->


<div id="id01" class="modal">

    <form class="modal-content" action="/action_page.php">
        <div class="container">
            <p>Delete Account <span onclick="document.getElementById('id01').style.display='none'" class="close"
                    title="Close Modal">×</span></p>
            <p>Are you sure you want to delete your account?</p>

            <div class="clearfix">
                <button type="button" onclick="document.getElementById('id01').style.display='none'"
                    class="cancelbtn modalbutton">Cancel</button>
                <button type="button" (click)="deleteAccount()" class="deletebtn modalbutton" style=" background-color: #f44336;
                color: #fff;">Delete</button>
            </div>
        </div>
    </form>
</div>

<div id="id011" class="modal">

    <form class="modal-content" action="/action_page.php">
        <div class="container">
            <p>Logout <span onclick="document.getElementById('id011').style.display='none'" class="close"
                    title="Close Modal">×</span></p>
            <p>Are you sure, Do you want to Logout?</p>

            <div class="clearfix">
                <button type="button" onclick="document.getElementById('id011').style.display='none'"
                    class="cancelbtn modalbutton">Cancel</button>
                <button type="button" (click)="logoutUser()" class="deletebtn modalbutton btn-blue" style=" background-color: #f44336;
                color: #fff;">Logout</button>
            </div>
        </div>
    </form>
</div>

<div id="subcategory" class="modals" >
        <div class="subcategory-section">
        <div class="header-section">
            <label class="">
                <span><i class="fa fa-arrow-left hand" onclick="document.getElementById('subcategory').style.display='none'"></i>&nbsp;&nbsp;&nbsp;{{selectedProfessionName}}</span>
            </label>
        </div>
        <div class="professionals-list">
            <div class="row item hand"  *ngFor="let d of lstChildProfessions" (click)="getSearchResults(d)">
                <div class="col-md-3" >
                    <div class="Rectangle-802">
                        <span class="MA">
                            {{getchar(d.professionName)}}
                          </span>
                    </div>
                </div>
                <div class="col-md-7">
                    <span class="Makeup-Artist">
                        {{d.professionName}}
                      </span>
                </div>
                <div class="col-md-2"><i class="fa fa-angle-right" style="cursor: pointer; font-size: 27px;"
                    aria-hidden="true" ></i></div>
            </div>
        </div>
      
    </div>
  </div>
  <div id="notifications" class="modals" *ngIf="isNotification">
    <div class="subcategory-section">
    <div class="header-section">
        <h4>Notifications</h4>
    </div>
    <div class="professionals-list">
        <div class="row item hand" style="padding: 10px;" *ngFor="let d of lstNotifications">
            <div class="col-md-2">
                <i class="fa fa-bell-o" style="font-size:24px"></i>
            </div>
            <div class="col-md-8" style="border-left: 1px solid #dfe1e1;">
                <p [innerHTML]="getMessage(d.message)"></p>
                <p>{{d.userFullName}}</p>
                <p>1 hr ago</p>
            </div>
            <div class="col-md-2">
                <i class="fa fa-star-o" style="font-size:24px"></i>
            </div>
        </div>
       
      
    </div>
  
</div>
</div>
