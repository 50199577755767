<div *ngIf="pageName != 'profile'" style="display: flex; position: relative;">
  <input #placesRef="ngx-places" ngx-gp-autocomplete [(ngModel)]="convertedAddress" maxlength="100"
    *ngIf="pageName != 'profile'" class="form-control inputs" name="autolocation" title="localAddress"
    #providerLocation="ngModel" placeholder="Current Location" (onAddressChange)="handleAddressChange($event)">
  <i class="fa fa-times-circle-o clearBtn" *ngIf="pageName != 'profile' && convertedAddress !== ''"
    (click)="clearSearch()"></i>
  <div *ngIf="isLoading" class="spinner-overlay">
    <div class="spinner"></div>
  </div>
</div>

<div *ngIf="pageName == 'profile'" style="display: flex; position: relative;">
  <input #placesRef="ngx-places" ngx-gp-autocomplete [(ngModel)]="userAddress" maxlength="100"
    *ngIf="pageName == 'profile'" class="form-control inputs" name="autolocation" title="localAddress"
    #providerLocation="ngModel" (onAddressChange)="handleAddressChange($event)" />
  <i class="fa fa-times-circle-o clearBtn" *ngIf="pageName == 'profile' && userAddress !== ''"
    (click)="clearSearch()"></i>
</div>
<app-popup #popup></app-popup>