import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/shared/alert/alert.service';
import { ServerApisService } from 'src/app/shared/services/server-apis.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Role } from 'src/app/shared/enums/role.enum';
import { Constants,navConstants } from 'src/app/shared/constants/constant';
import { RouteMakingService } from 'src/app/shared/services/route-making.service';

@Component({
  selector: 'app-vendor-number-assignment-vendor-management',
  templateUrl: './vendor-number-assignment-vendor-management.component.html',
  styleUrls: ['./vendor-number-assignment-vendor-management.component.css']
})
export class VendorNumberAssignmentVendorManagementComponent {
  vendorForm: FormGroup;
  lstVendors: any = [];
  lstTempVendors: any = [];
  lstalluserVendors:any=[];
  isdisplay: boolean = false
  p = 1;
  isEditIndex: number;
  isdropdownshow:boolean=false;
  isassign:boolean=false;
  isnumberassign:boolean=false;
  searchtext:string='';
  vendorCodesort:string="";
  vendorNamesort:string="";
  filterRole:string = '';
  lstassignVendors: any;
  lsttempassignVendors: any;
  placeholderText: string = 'Search with VendorId and Vendor Name';
  vendorIdsort: string;

  constructor(private spinner: NgxSpinnerService,private routeMakingService: RouteMakingService, private _apiService: ServerApisService, private router: Router,
    private _alertService: AlertService,private formBuilder: FormBuilder) { }

    ngOnInit(): void {
      const user = sessionStorage.getItem('user');
      if (user != null) {
        let data = JSON.parse(user);
        if (data.roleId == Role.Admin || data.roleId == Role.SubAdmin) {
          this.getVendorNumberAssigners();
           this.getVendors();
        let path=window.location.href.split('vendor-management/')
          } else {
          this.router.navigate([""]);
        }
  
      } else {
        this.router.navigate([this.routeMakingService.getNavigateUrl([Constants.Login])]);
      }
      this.vendorForm = this.formBuilder.group({
        selectedUser:[]
      });
    }
    searchVendorChange(event: any) {
      if (event.target.value != '' && event.target.value != undefined) {
        var result = this.lstTempVendors.filter((x: any) => x.vendorId.toString().toLowerCase().includes(event.target.value.toLowerCase())
          || x.vendorName.toLowerCase().includes(event.target.value.toLowerCase())
          || x.maskingMobileNumber.toLowerCase().includes(event.target.value.toLowerCase()))
        this.lstVendors = result;
      } else {
        this.lstVendors = this.lstTempVendors;
      }
    }
    closeRoleDropdown(event:any){
      // 
      // if(this.isdropdownshow==false){
      //   this.dropdownshow();
      // }
      
    }
    changeNumberassign:any;
    selectednewnumber:string="";
    selectednewnumberId:any;
    filterRoleassignSearch:string='';
    lstVendorsnumber:any=[];
    lstTempVendorsnumber;any=[];
    lstassignvendor:any=[];
    lsttempassignvendor:any=[];
    getVendorNumberAssigners(){
      this.spinner.show();
      this.lstVendorsnumber = [];
      this._apiService.GetVendorMaskingNumbers().subscribe(res => {
        this.spinner.hide();
        
        this.lstVendorsnumber = res.FetchAllMaskingMobileNumbersResult['resultAllMaskingMobileNumbers'];
   
        this.lstTempVendorsnumber= this.lstVendorsnumber;
        let arr=[{rolemaskingNumbersIdId:0,mobileMaskingNumber:'None'}];
        let newArr=this.lstVendorsnumber;
        for(var i=0;i<newArr.length;i++){
          arr.push(newArr[i]);
        }
        this.lstassignvendor=arr;
        this.lsttempassignvendor=this.lstassignvendor;
      });
    }
    vendrNumberchange(val,d){
    
      let payload:any={};
      if(val=='None'){
        val='';
      }
      payload.maskingMobileNumber=val
      payload.vendorId=d.vendorId
      this.spinner.show();
      
      this._apiService.updatemaskingnumberVendor(payload).subscribe(res => {
        this.spinner.hide();
        
        this.isEditIndex=-1;
        this.isdisplay=false;
        this.isnumberassign=false;
        this._alertService.success(res['result']);
        });
    }

    vendornumberchange(){
      this.vendrNumberchange(this.selectednewnumber,this.changeNumberassign)
    }

    changeNumber(value:any){
      this.isEditIndex=-1;
      this.isdropdownshow=false;
      this.filterRoleassignSearch='';
       if(value==undefined){
        this.lstVendors=this.lstTempVendors;
      }else{
        this.filterRole=value.mobileMaskingNumber;
        var result=this.lstTempVendors.filter((x:any)=>x.maskingMobileNumber==this.filterRole);
        this.lstVendors=result;
      }
    }
    closeVendorNumber(d){
    
      if(this.vendorForm.controls['selectedUser'].value=='' || this.vendorForm.controls['selectedUser'].value==null){
        const index = this.lstVendorsnumber.findIndex(user => user.mobileMaskingNumber === d.maskingMobileNumber);
        this.vendorForm.get('selectedUser').setValue(this.lstVendorsnumber[index]);
      }
   
    }
    editnumber(i,d){
      this.isEditIndex=i;
      if(d.maskingMobileNumber!=''){
        const index = this.lstVendorsnumber.findIndex(user => user.mobileMaskingNumber === d.maskingMobileNumber);
        
        if(index>=0){
        this.vendorForm.get('selectedUser').setValue(this.lstVendorsnumber[index]);
        }
      }
    }
    selectedoldNumber:string="";
    selectedchangenumber(event,d){
    
      this.selectednewnumber=event.mobileMaskingNumber;
      this.selectednewnumberId=event.maskingNumbersId;
      this.selectedoldNumber=d.maskingMobileNumber;
      this.changeNumberassign=d;
      this.isnumberassign=true;
    }
    pageChanged(event: any) {
      this.p = event;
      this.isEditIndex=-1;
   }
   getVendors() {
    this.spinner.show();
    this.lstVendors = [];
    this._apiService.GetVendors().subscribe(res => {
      this.spinner.hide();
      this.lstVendors = res.FetchAllVendorsResult['resultAllVendors'];
      this.lstTempVendors = this.lstVendors;
      this.lstassignVendors=this.lstVendors;
      this.lsttempassignVendors=this.lstassignVendors
    });
  }

  vendorIdOnsort(){
    if (this.vendorIdsort == "") {
      this.vendorIdsort = "up";
      this.lstVendors.sort((a, b) => (b.vendorId > a.vendorId) ? -1 : 1);

    } else if (this.vendorIdsort == "up") {
      this.vendorIdsort = "down";
      this.lstVendors.sort((a, b) => (a.vendorId > b.vendorId) ? -1 : 1);

    } else if (this.vendorIdsort == "down") {
      this.vendorIdsort = "";
      this.lstVendors.sort((a, b) => a.vendorId - b.vendorId);
    }
  }

  vendorCodeOnsort(){
    if (this.vendorCodesort == "") {
      this.vendorCodesort = "up";
      this.lstVendors.sort((a, b) => (b.vendorCode > a.vendorCode) ? -1 : 1);

    } else if (this.vendorCodesort == "up") {
      this.vendorCodesort = "down";
      this.lstVendors.sort((a, b) => (a.vendorCode > b.vendorCode) ? -1 : 1);

    } else if (this.vendorCodesort == "down") {
      this.vendorCodesort = "";
      this.lstVendors.sort((a, b) => a.vendorId - b.vendorId);
    }
  }

  vendorNameOnsort(){
    if (this.vendorNamesort == "") {
      this.vendorNamesort = "up";
      this.lstVendors.sort((a, b) => (b.vendorName > a.vendorName) ? -1 : 1);

    } else if (this.vendorNamesort == "up") {
      this.vendorNamesort = "down";
      this.lstVendors.sort((a, b) => (a.vendorName > b.vendorName) ? -1 : 1);

    } else if (this.vendorNamesort == "down") {
      this.vendorNamesort = "";
      this.lstVendors.sort((a, b) => a.vendorId - b.vendorId);
    }
  }
  removenumber(){
    this.isEditIndex=-1;
  }
}
