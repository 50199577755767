<div class="container-fluid height78 cntfld">
    <div class="container">
        <div class="row " *ngIf="!user">
            <div class="col-md-6 col-sm-12">
                <img src="assets/images/signinimg.png" class="img-signin"  alt="signin image" title="signin image"/>
            </div>
            <div class="col-md-6 col-sm-12" [hidden]="otpsent">
                <h2 class="text-center">Let's Get Started</h2>
                <form #f="ngForm" class="form-change" name="form" [formGroup]="signInForm" (ngSubmit)="onSubmit(f)"
                    novalidate>
                    <div class="form-group">
                        <div class=" mb-3">
                            <label for="phone" class="col-form-label label head-color"> Mobile Number</label>
                            <div class="input-group ">
                                <div class="dropdown">
                                    <button class="Rounded-Rectangle-738 btn btn-primary dropdown-toggle" type="button"
                                        id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                                        <img src="../../../assets/images/layer-9-copy.png" *ngIf="country=='India'" alt="India" title="India">
                                        <img src="../../../assets/images/USA.png" *ngIf="country=='United States'" alt="USA" title="USA">
                                        <img src="../../../assets/images/canada.png" *ngIf="country=='Canada'" alt="Canada" title="Canada">
                                    </button>
                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        <li class="dropdown-item" (click)="countrychange('India')">+91 &nbsp;<label
                                                    class="grey-color">India</label> &nbsp;<img
                                                    src="../../../assets/images/layer-9-copy.png" alt="India" title="India"></li>
                                        <li class="dropdown-item" (click)="countrychange('Canada')">+1 &nbsp;<label
                                                    class="grey-color">Canada</label> &nbsp;<img
                                                    src="../../../assets/images/canada.png" alt="Canada" title="Canada"></li>
                                        <li class="dropdown-item" (click)="countrychange('United States')">+1 &nbsp;<label
                                                    class="grey-color">United States</label> &nbsp;<img
                                                    src="../../../assets/images/USA.png" alt="USA" title="USA"></li>
                                    </ul>
                                </div>
                                <input type="text" class="form-control input-format" name="phone"
                                    [(ngModel)]="phoneNum" placeholder="Mobile Number*" maxlength="10"
                                    (input)="changeNum($event)" formControlName="mobileNum"
                                    (keypress)="OnlyNumbersAllowed($event)" />
                            </div>
                            <div *ngIf="(submitted && mobileNumber.errors)
                            || ( mobileNumber.errors && mobileNumber.touched)" class="text-danger">
                                <div *ngIf="mobileNumber.errors.required ">Mobile number is required.</div>
                                <div *ngIf="mobileNumber.errors.minlength">Mobile number must be at least 10 characters.
                                </div>
                                <div *ngIf=" mobileNumber.errors.pattern">Enter valid phone number
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class=" mb-3">
                            <div id="loginrecaptcha"></div>
                        </div>
                    </div>

                    <div class="mb-3">
                        <button class="btn btn-primary blue-btn w-100 mt-2 submit-btn" [disabled]="mobileNumber.errors">Submit</button>
                    </div>

                    <div class="row">
                        <div class="col-md-4 col-sm-12 m-auto">
                            <hr class="hr">
                        </div>
                        <div class="col-md-4 col-sm-12">
                            <h6 class="my-3 text-center f-s-20 margin-ctrl">Sign in with</h6>
                        </div>
                        <div class="col-md-4 col-sm-12 m-auto">
                            <hr class="hr">
                        </div>
                    </div>
                </form>

                <div class="mb-3">
                    <div class="text-center">
                        <ul class="list-inline" style="display: flex; justify-content: center;">
                            <li class="list-inline-item" (click)="loginWithGoogle($event)">
                                <asl-google-signin-button type='icon' size='large' [initOptions]="googleLoginOptions"></asl-google-signin-button>
                            </li>
                            <li class="list-inline-item">
                                <a (click)="signInWithFB()">
                                    <img src="assets/images/facebook.png" alt="facebook" title="facebook" style="width: 40px;" >
                                </a>
                            </li>
                            <li class="list-inline-item">
                                <a (click)="signInWithApple()">
                                    <img src="assets/images/apple.png" alt="apple" title="apple" style="width: 40px;">
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-sm-12" *ngIf="otpsent">
                <app-otp [registerFormData]="model" [result]="verificationResult" [newUser]="newUser"
                    [formnames]="formName" [mobilenum]="phno" (login)="validuser()">
                </app-otp>
            </div>
        </div>
    </div>