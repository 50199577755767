<nav style=" position: relative;top: 12px;left: 13px;" aria-label="breadcrumb" class="mlft-6">
  <app-bread-crumb [breadpath1]="home" [breadpath2]="callLog" [breadroute1]="callLogsRoute"></app-bread-crumb>
</nav>
<div class="container table-container" style="margin-top: 6%;" *ngIf="callLogs.length > 0">
  <table class="table table-bordered table-striped">
    <thead>
      <tr>
        <th>Image</th>
        <th>Name</th>
        <th>Call Status</th>
        <th>Date & Time</th>
        <th>Duration</th>
        <th>Action</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let callLog of callLogs.slice((currentPage - 1) * pageSize, currentPage * pageSize)"
        class="hover-effect">
        <td *ngIf="callLog.receiverImage === '' " (click)="goToUserProfile(callLog.DialedUser,callLog.DialedMember)">
          <h2>{{callLog.initials}}</h2>
        </td>
        <td *ngIf="callLog.receiverImage !== '' " (click)="goToUserProfile(callLog.DialedUser,callLog.DialedMember)">
          <img src="{{callLog.receiverImage}}" alt="image" title="image" width="50" height="50"></td>
        <td (click)="goToUserProfile(callLog.DialedUser,callLog.DialedMember)">{{callLog.DialedUser}}</td>
        <td>{{callLog.callStatus === 'missed' ? 'Missed' : callLog.CallType === 'in_coming' ? 'Incoming' :
          callLog.CallType === 'out_going' ? 'Outgoing' : 'Missed'}}</td>
        <td>{{callLog.startDatetime}}</td>
        <td>{{callLog.duration}} mins</td>
        <td>
          <button type="button" class="btn btn-primary" (click)="callProfile(callLog)"
            [disabled]="inProgress">Call</button>
        </td>
      </tr>
    </tbody>
  </table>

  <!-- Pagination -->
  <ul class="pagination justify-content-center">
    <li class="page-item" *ngFor="let page of getPages()">
      <a class="page-link" (click)="onPageChange(page)">{{ page }}</a>
    </li>
  </ul>
</div>

<div class="container set-height" *ngIf="callLogs.length === 0">
  <div class="row">
    <div class="col-md-12">
      <h1 class="text-center">
        No Call Logs Found
      </h1>
    </div>
  </div>
</div>

<div class="modal fade" id="viewPhoneNumberModal" tabindex="-1" aria-labelledby="viewPhoneNumberModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body">
        <div class="text-center">
          Update Mobile Number
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary close" data-bs-dismiss="modal" (click)="goToProfile()">Go To
          Profile</button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="showGif" class="loading-overlay">
  <div class="loading-gif-container">
    <img src="../../../assets/gif/call.gif" alt="Calling..." title="Calling..." />
  </div>
</div>