<div class="tasks_table1 ">
    <div class="row">
        <div class="col-md-12 search-box">
            <label style="width: 100%;"><span><input type="text" id="search"
                        class="custom-focus-input" autocomplete="off" placeholder="Search with Name, MobileNo, Location, State, City"
                        style="height: 37px;border:none;width:100.3%;"
                        (input)="searchChange($event)"></span></label>
        </div>
    </div>

</div>

<table class="table table-hover tbl">
    <thead class="tablehd-sec">
        <tr>
            <th style="width: 8%;">Unique Id</th>
            <th style="width: 10%;">Name</th>
            <th style="width: 8%;">Mobile No</th>
            <th style="width: 17%;">Location</th>
            <th style="width: 7%">State</th>
            <th style="width: 7%;">City</th>
            <th style="width: 20%;">Search log</th>
            <th style="width: 20%;">Profile last update</th>
        </tr>
    </thead>
    <tbody>
        <tr
            *ngFor="let d of lstallusersdisplay| paginate: { itemsPerPage: 10, currentPage: p};let i = index">
            <td style="width: 8%;">{{d.UserUniqueCode}}</td>
            <td style="width: 10%;">{{d.Name}}</td>
            <td style="width: 8%;">{{d.mobile | slice:0:10}}</td>

            <td style="width: 17%;" *ngIf="indx!=i">
                {{d.Location | slice:0:30}}
                <span style="color: #195fbd;cursor: pointer;" (click)="readmore(i)"
                    *ngIf="d.Location.length > 30">&nbsp;Read more</span>
            </td>
            <td style="width: 17%;" *ngIf="indx==i">{{d.Location}}
                <span style="color: #195fbd; cursor: pointer;" (click)="readless()">&nbsp;Read
                    less</span>
            </td>
            <td style="width: 7%;">{{d.stateName}}</td>
            <td style="width: 7%;">{{d.city}}</td>
            <td style="width: 22%;" *ngIf="indx1!=i">
                {{d.Searchlog | slice:0:55}}
                <span style="color: #195fbd;cursor: pointer;" (click)="readmore1(i)"
                    *ngIf="d.Searchlog.length > 55">&nbsp;Read more</span>
            </td>
            <td style="width: 20%;" *ngIf="indx1==i">{{d.Searchlog}}
                <span style="color: #195fbd; cursor: pointer;" (click)="readless1()">&nbsp;Read
                    less</span>
            </td>

            <td style="width: 20%; word-wrap: break-word;">{{d.ProfilelastUpdate}}</td>


        </tr>
    </tbody>
</table>
<div class="row">
    <div class="col">
        <div class="d-flex justify-content-center" *ngIf="lstallusersdisplay.length!=0">
            <pagination-controls (pageChange)="pageChanged($event)"
                (pageBoundsCorrection)="pageChanged($event)">
            </pagination-controls>
        </div>

    </div>
</div>