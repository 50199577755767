<div class="tasks_table1">
    <div class="row">
      <div class="content">
        <mat-form-field class="select-option">
          <select [(ngModel)]="selectedActivitiesOption" (change)="resetActivitiesOptions()" matNativeControl
            required style="height: 36px;">
            <option value="name">Name</option>
            <option value="uniqueId">Unique Id</option>
        </select>
        </mat-form-field>
        <div class="search-box">
          <div class="w-100 search-section" *ngIf="selectedActivitiesOption === 'All'">
           <span class="w-100">
                <input type="text" id="search" class="custom-focus-input" autocomplete="off"
                  [(ngModel)]="searchedAll" placeholder="Search..." (input)="searchedByAll($event)">
              </span>
           </div>
          <div class="search-section" *ngIf="selectedActivitiesOption === 'name'">
            <label>
              <span>
                <input type="text" id="search" class="custom-focus-input" autocomplete="off"
                  [(ngModel)]="searchedName" placeholder="Search With Name" (input)="searchByName($event)">
              </span>
            </label>
          </div>
           <div class="search-section" *ngIf="selectedActivitiesOption === 'uniqueId'">
            <label>
              <span>
                <input type="text" id="search" class="custom-focus-input" autocomplete="off"
                  [(ngModel)]="searchedUniqueId" placeholder="Search With Unique Id"
                  (input)="searchByUniqueId($event)">
              </span>
            </label>
          </div>
          </div>
      </div>
    </div>
  </div>

<table class="table table-striped">
    <thead>
        <tr>
            <th style="width: 7%;">S No.</th>
            <th style="width: 12%;">Unique Id</th>
            <th style="width: 15%;">Name</th>
            <th style="width: 66%;">Profile Link</th>

        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let d of lstProfileData | paginate: { itemsPerPage: 20, currentPage: p, totalItems:count }; let i = index"
            style="color: #dad5d5; text-align: left;">
            <td style="width: 7%;">{{ (p - 1) * 20 + i + 1 }}</td>
            <td style="width: 12%;">{{ d.UniqueId }}</td>
            <td style="width: 15%;">{{ d.Name }}</td>
            <td style="width: 66%;"><a href="{{ d.profileLink }}" target="_blank"
                    style="color: #195fbd;">{{ d.profileLink }}</a></td>
        </tr>

    </tbody>
</table>
<div class="row">
    <div class="col">
        <div class="d-flex justify-content-center" *ngIf="lstProfileData.length!= 0">
            <pagination-controls (pageChange)=" pageChanged($event)"
                (pageBoundsCorrection)=" pageChanged($event)">
            </pagination-controls>
        </div>
    </div>
</div>