// share-modal.component.ts

import { Component, Input, Output, EventEmitter } from '@angular/core';
import { SafeUrl,DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-share-modal',
  templateUrl: './share-modal.component.html',
  styleUrls: ['./share-modal.component.css'],
})
export class ShareModalComponent {
  @Input() show: boolean = false;
  @Input() profileUrl: string = '';
  @Output() closeModalEvent = new EventEmitter<void>();

  constructor(private sanitizer: DomSanitizer) {}

  copyToClipboard() {
    const textField = document.createElement('textarea');
    textField.innerText = this.profileUrl;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
    alert('Copied to clipboard!');
  }

  getSocialMediaLink(platform: string): SafeUrl {
    const deepLinks = {
      whatsapp: 'whatsapp://send?text=',
      instagram: 'instagram://user?username=',  
      linkedin: 'linkedin://profile/',
      facebook: 'fb://page/?id=',              
    };
  
    const isMobile = window.innerWidth < 768;
    const deepLink = deepLinks[platform.toLowerCase()];
  
    if (isMobile && deepLink) {
      // Try to open the app
      const deepLinkUrl = `${deepLink}${encodeURIComponent(this.profileUrl)}`;
      return this.sanitizer.bypassSecurityTrustUrl(deepLinkUrl);
    } else {
      // Open the web version
      switch (platform.toLowerCase()) {
        case 'whatsapp':
          return this.sanitizer.bypassSecurityTrustUrl(`https://web.whatsapp.com/send?text=${encodeURIComponent(this.profileUrl)}`);
        case 'instagram':
          return this.sanitizer.bypassSecurityTrustUrl(`https://www.instagram.com/${encodeURIComponent(this.profileUrl)}`); // Change the Instagram web link
        case 'linkedin':
          return this.sanitizer.bypassSecurityTrustUrl(`https://www.linkedin.com/shareArticle?url=${encodeURIComponent(this.profileUrl)}`);
        case 'facebook':
          const encodedUrl = encodeURIComponent(this.profileUrl);
          const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=https://www.toskie.com?ref=${encodedUrl}`;
          return this.sanitizer.bypassSecurityTrustUrl(facebookShareUrl);
        default:
          return this.sanitizer.bypassSecurityTrustUrl(''); // Handle other platforms as needed
      }
    }
  }
  

  closeModal() {
    this.closeModalEvent.emit();
  }

  getSanitizedUrl(url: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }
}
