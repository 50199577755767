import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService } from 'src/app/shared/alert/alert.service';
import { Role } from 'src/app/shared/enums/role.enum';
import { ServerApisService } from 'src/app/shared/services/server-apis.service';
import { Constants, navConstants } from 'src/app/shared/constants/constant';
import { RouteMakingService } from 'src/app/shared/services/route-making.service';

@Component({
  selector: 'app-sub-professional-category',
  templateUrl: './sub-professional-category.component.html',
  styleUrls: ['./sub-professional-category.component.css']
})
export class SubProfessionalCategoryComponent implements OnInit {
  placeholderText = 'Search'
  professionName: string = '';
  professionNameNew: any;
  public bankCtrl: FormControl = new FormControl();
  public bankFilterCtrl: FormControl = new FormControl();
  lstCategories: any = [];
  sort: string = "";
  isUpdate: boolean = false;
  isSearchChange: boolean = false;
  selectedCountry: string;
  memberId: number = 0;
  adminPanel: string = Constants.adminPanel;
  professions: string = Constants.profession;
  professionsNav: string = navConstants.assignProfessionNav;
  adminPanelRoute: string = Constants.adminPanelRoute;
  professionsTitle: string = Constants.professionsTitle;
  selectedIndex: number = 0;
  isdisplay: boolean = false
  lstallcategories: any = [];
  lstalltempcategories: any = [];
  oldservice: string = "";
  mainProfessionSort: string = "";
  childProfessionSort: string = "";
  isPopup: boolean = false;
  newservice: string = "";
  previd: number = 0;
  newservicesort: string = "";
  categorytype: string = "";
  displayedColumns: string[] = ['MemberId', 'Member Name', 'Service', 'CategoryId'];
  lstTempcategories: any = [];
  id: number = 0;
  serviceName: string = "";
  subProfessionsCategoryId: number = 0;
  searchtext: string = '';
  searchtextMain: string = '';
  searchtextChild: string = '';
  p = 1;
  page: any = "assignService";
  isCard: any = true;
  mainProfessions: any;
  tempMainProfession: any;
  selectedMainProfession: any = '';
  selectedMainProfessionId: any;
  adminApprovalCategories: any;
  tempAdminApprovalCategories: any;
   modal: string = 'add';
  allChildProfessionCategory: any;
  tempChildProfessionsCategory: any;
  selectedChildProfesionId: any;
  childProfessionName: any;
  childProfessionNameOld: any;
  metaTitle: any = '';
  metaDescription: any = '';
  metaKeywords: any = '';
  updatedmetaTitle: any = '';
  updatedmetaDescription: any = '';
  updatedmetaKeywords: any = '';

  constructor(private router: Router, private routeMakingService: RouteMakingService, private _apiService: ServerApisService, protected alertService: AlertService, private spinner: NgxSpinnerService,) { }

  ngOnInit(): void {
    const user = sessionStorage.getItem('user');
    if (user != null) {

      let data = JSON.parse(user);
      if (data.roleId === Role.Admin || data.roleId === Role.SubAdmin || Role.SeoTeam) {
        this.getSubProfessionalCategories();
        this.getAllSubProfessionCategory();
        let path = window.location.href.split('profession/')
       } else {
        this.router.navigate([""]);
      }
    } else {
      this.router.navigate([this.routeMakingService.getNavigateUrl([Constants.Login])]);
    }
    this.fetchMainProfession();
    this.getAllAdminApprovalCategory();
    this.getAllChildCategories();
  }

 
getAllChildCategories() {
    // this._apiService.FetchAllChildProfessions().subscribe((res: any) => {
    //   this.allChildProfessionCategory = res.FetchAllChildProfessionsResult.resultFetchProfessionAdminApproval;
    //   this.tempChildProfessionsCategory = this.allChildProfessionCategory;
    // })
  }

  getAllAdminApprovalCategory() {
    // this._apiService.GetAdminApprovalCategories('pending').subscribe((res: any) => {
    //   this.adminApprovalCategories = res.FetchCategoriesAdminApprovalResult.resultFetchProfessionAdminApproval;
    //   this.tempAdminApprovalCategories = this.adminApprovalCategories;
    // })
  }

fetchMainProfession() {
    // this._apiService.GetMainProfession().subscribe((res: any) => {
    //   this.mainProfessions = res.FetchAllMainProfessionsResult.resultFetchProfessionAdminApproval;
    //   this.tempMainProfession = this.mainProfessions;
    //  // console.log(this.mainProfessions);
    // })
  }
  onMouseEnter() {
    this.isCard = true;
  }

  onMouseLeave() {
    this.isCard = false;
  }


 searchtab(pageName) {
    if (pageName === 'assignService') {
      this.searchtext = '';
      this.getSubProfessionalCategories();
      this.professionsTitle = Constants.assignProfessionTitle
      this.professionsNav = navConstants.assignProfessionNav
      this.router.navigate([this.routeMakingService.getNavigateUrl([Constants.adminPanelRoute, Constants.profession, navConstants.assignProfessionNav])]);
    } else if (pageName === 'serviceCreation') {
      this.searchtextMain = '';
      this.fetchMainProfession();
      this.professionsTitle = Constants.mainProfessionsTitle
      this.professionsNav = navConstants.mainProfessionsNav
      this.router.navigate([this.routeMakingService.getNavigateUrl([Constants.adminPanelRoute, Constants.profession, navConstants.mainProfessionsNav])]);
    } else if (pageName === 'childProfession') {
      this.searchtextChild = '';
      this.getAllSubProfessionCategory();
      this.professionsTitle = Constants.childProfessionsTitle
      this.professionsNav = navConstants.childProfessionsNav
      this.router.navigate([this.routeMakingService.getNavigateUrl([Constants.adminPanelRoute, Constants.profession, navConstants.childProfessionsNav])]);
    }
    this.page = pageName;
  }

 
  getSubProfessionalCategories() {
    // let payLoad = {
    //   serviceId: 0
    // }
    // this.spinner.show();
    // if (this.newservice != '') {
    //   this.oldservice = this.newservice;
    //   this.lstCategories[this.selectedIndex].categoryName = this.oldservice;
    // }
    // this._apiService.GetSubProfessionalCategories(payLoad).subscribe(res => {
    //   this.lstCategories = res['Services'];
    //   this.lstTempcategories = res['Services'];
    //   this.spinner.hide();
    // });
  }


  getAllSubProfessionCategory() {
    // this._apiService.getAllSubProfessionCategory().subscribe(res => {
    //   this.lstallcategories = res.GetAllSubProfessionCategoryResult['resultSubProfessionCategory'];
    //   this.lstalltempcategories = res.GetAllSubProfessionCategoryResult['resultSubProfessionCategory'];
    // });
  }
 
  menu() {
    if (this.isCard) {
      this.isCard = false;
    } else {
      this.isCard = true;
    }
  }

  removeDropdown(event) {

    if (this.isdisplay == true && this.isSearchChange) {

      this.isdisplay = false;
      this.isPopup = false;
      this.lstCategories[this.selectedIndex].categoryName = this.oldservice;
      this.isSearchChange = false;
      this.previd = 0;
    }
    if (event.target.value == undefined) {

      this.isdisplay = false;
      this.previd = 0;
      this.isPopup = false;
      if (this.oldservice != '') {
        this.lstCategories[this.selectedIndex].categoryName = this.oldservice;
      }

    }

  }
}




