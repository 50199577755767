<div class="row pad2-5">
    <div class="col-md-6 col-12">
        <img src="assets/images/download.png" class="Shape float-end img-fluid" alt="downapp download icon" title="app download icon">
    </div>
    <div class="col-md-6 col-12 download-card">
        <h2 class="Download-App-Now">Download App Now</h2>
        <h2 class="download-desc">A way to achieve your Professional Requirement</h2>
        <div class="row">
            <div class="col-6 col-md-6" >
                <div class="Rectangle-12 hand" >
                    <a href="https://apps.apple.com/us/app/toskie/id1574937402" target="_blank" style="text-align: center;">
                        <img src="assets/images/app-store.png" class="img-fluid float-end" alt="logo by app store" title="logo by app store" style="width: 172px;"></a>
                </div>
            </div>
            <div class="col-6 col-md-6" >
                <div class="Rectangle-12 hand">
                    <a href="https://play.google.com/store/apps/details?id=com.spinoinc.conwok" target="_blank" style="text-align: center;">
                        <img src="assets/images/play-store.png" class="img-fluid float-start" alt="logo by playstore" title="logo by play store" style="width: 172px;"></a>
                </div>
            </div>
        </div>
    </div>
    
</div>
<div class="row pad2-5 border-0" style="background-color: #FFF;">
    <div class="col-md-6 text-center">
        <h3 class="lbllink lh-base text-center">Get the link to download the app</h3>
    </div>
    <div class="col-md-6 padright">
        <div class="Rectangle-711">
            <div class="row pad3px">
                <div class="col-7 col-md-9">
                    <input type="text" class="form-control mbl-placeholder" placeholder="Enter Mobile Number">
                </div>
                <div class="col-5 col-md-3">
                    <div class="Rectangle-4-copy">
                        <label class="Search mb-0">Send Link</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>