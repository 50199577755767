<div class="whole-section1">
  <div class="table-wrapper">
    <div class="table-title">
      <h2>Users Information</h2>
    </div>
    <mat-tab-group (selectedTabChange)="onTabChange($event)">
      <mat-tab>
        <ng-template mat-tab-label>
          <div (click)="getAllUsersInformationReport(1)">
            <div class="union-10"></div>
            <span class="approved-text"> <i class="fas fa-users active-users-icon"></i>Active User</span>
          </div>
        </ng-template>
        <div class="tasks_table1">
          <div class="row">
            <div class="search-container">
              <mat-form-field class="select-option">
                <select [(ngModel)]="selectedActivitiesOption" (change)="resetActivitiesOptions()" matNativeControl
                  required style="height: 36px;">
                  <option value="All">---All---</option>
                  <option value="name">Name</option>
                  <option value="mobileNo">Mobile No</option>
                  <option value="uniqueId">Unique Id</option>
                  <option value="profession">Profession</option>
                </select>
              </mat-form-field>
              <div class="search-box">
                <div class="search-box" *ngIf="selectedActivitiesOption === 'All'">
                  <span>
                    <input type="text" id="search" class="custom-focus-input" autocomplete="off"
                      [(ngModel)]="searchedAll" placeholder="Search..." (input)="searchedByAll($event)">
                  </span>
                </div>
                <div class="search-box" *ngIf="selectedActivitiesOption === 'name'">
                  <label>
                    <span>
                      <input type="text" id="search" class="custom-focus-input" autocomplete="off"
                        [(ngModel)]="searchedName" placeholder="Search With Name" (input)="searchedByName($event)">
                    </span>
                  </label>
                </div>
                <div class="search-box" *ngIf="selectedActivitiesOption === 'mobileNo'">
                  <label>
                    <span>
                      <input type="text" id="search" class="custom-focus-input" autocomplete="off"
                        [(ngModel)]="searchedMobileNo" placeholder="Search With Mobile No."
                        (input)="searchedByMobileNo($event)">
                    </span>
                  </label>
                </div>
                <div class="search-box" *ngIf="selectedActivitiesOption === 'uniqueId'">
                  <label>
                    <span>
                      <input type="text" id="search" class="custom-focus-input" autocomplete="off"
                        [(ngModel)]="searchedUniqueId" placeholder="Search With unique Id"
                        (input)="searchedByUniqueId($event)">
                    </span>
                  </label>
                </div>
                <div class="search-box" *ngIf="selectedActivitiesOption === 'profession'">
                  <label>
                    <span>
                      <input type="text" id="search" class="custom-focus-input" autocomplete="off"
                        [(ngModel)]="searchedProfession" placeholder="Search With Profession"
                        (input)="searchedByProfession($event)">
                    </span>
                  </label>
                </div>
              </div>
             </div>
          </div>
        </div>
        <div class="form-design">
        <form [formGroup]="usersInformationForm">
          <div class="row">
            <div class="col-md-2">
              <input type="date" class="form-control" formControlName="fromdate"  (change)="getAllUsersInformationReport(1)" [max]="maxDate">
            </div>
            <div class="col-md-2">
              <input type="date" class="form-control" formControlName="todate" (change)="getAllUsersInformationReport(1)" [max]="maxDate">
            </div>
            <div class="col-md-3">
              <ng-select [items]="lstCountry" bindLabel="country_name" placeholder="Select Country" #selectBox
                (change)="changeSelectCountry($event)" formControlName="selectedCountry">
              </ng-select>
            </div>
            <div class="col-md-3">
              <ng-select [items]="lstState" bindLabel="state_name" placeholder="Select State" #selectBox
                (change)="changeSelectState($event)" formControlName="selectedState">
              </ng-select>
            </div>
            <div class="col-md-2">
              <input type="text" placeholder="Zip Code" class="form-control">
            </div>
          </div>
        </form>
      </div>
        <table class="table table-striped">
          <thead>
            <tr>
              <th style="width: 25%;">Name</th>
              <th style="width: 16%;">Unique Id</th>
              <th style="width: 22%;">Mobile Number</th>
              <th style="width: 22%;">Profession</th>
              <th style="width: 15%;">Photos</th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let d of filteredActiveUsers | paginate: { itemsPerPage: 20, currentPage: p, totalItems: usersInfoCount }; let i = index"
              style="vertical-align: middle;">
              <td style="width: 25%;">
                <img class="profileimage" style="border: 2px solid #f2f2f2; cursor: pointer;"
                  src="{{d.profileImageUrl || 'assets/images/no-image.png'}}"
                  onerror="this.src='assets/images/no-image.png';" (click)="openProfileImageModal(d.profileImageUrl)"
                  alt="Profile Image">
                {{d.Name}}
              </td>
              <td style="width: 16%;">{{d.UserUniqueCode}}</td>
              <td style="width: 22%;">{{d.MobileNo | slice:0:10}}</td>
              <td style="width: 22%;">{{getprofession(d)}}</td>
              <td style="width: 15%;">
                <button style="background: none; border: none;" (click)="openOrganisationImageModal(d)">
                  <img class="profileimage" style="border: 2px solid #f2f2f2;" *ngIf="d.OrganisationImages.length > 0"
                    src="{{d.OrganisationImages[0].orgImagePath || 'assets/images/no-image.png'}}"
                    onerror="this.src='assets/images/no-image.png';" alt="Organisation Image">
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="row">
          <div class="col">
            <div class="d-flex justify-content-center" *ngIf="filteredActiveUsers.length != 0">
              <pagination-controls (pageChange)="pageChangedActiveUser($event)"
                (pageBoundsCorrection)="pageChangedActiveUser($event)">
              </pagination-controls>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <div (click)="getAllUsersInformationDeletedUserReport(1)">
            <div class="union-10"></div>
            <span class="approved-text"> <i class="fas fa-user-slash deleted-users-icon"></i>Deleted User</span>
          </div>
        </ng-template>
        <div class="tasks_table1">
          <div class="row">
            <div class="search-container">
              <mat-form-field class="select-option">
                <select [(ngModel)]="selectedActivitiesDeletedUserOption"(change)="resetActivitiesDeletedUsersOptions()"
                  matNativeControl required style="height: 36px;">
                  <option value="All">---All---</option>
                  <option value="name">Name</option>
                  <option value="mobileNo">Mobile No</option>
                  <option value="uniqueId">Unique Id</option>
                  <option value="profession">Profession</option>
                </select>
              </mat-form-field>
              <div class="search-box">
                <div class="search-box" *ngIf="selectedActivitiesDeletedUserOption === 'All'">
                  <label>
                    <span>
                      <input type="text" id="search" class="custom-focus-input" autocomplete="off"
                        [(ngModel)]="searchedAll" placeholder="Search..." (input)="searchedByDeletedUsersAll($event)">
                    </span>
                  </label>
                </div>
                <div class="search-box" *ngIf="selectedActivitiesDeletedUserOption === 'name'">
                  <label>
                    <span class="w-100">
                      <input type="text" id="search" class="custom-focus-input" autocomplete="off"
                        [(ngModel)]="searchedName" placeholder="Search With Name"
                        (input)="searchedByDeletedUsersName($event)">
                    </span>
                  </label>
                </div>
                <div class="search-box" *ngIf="selectedActivitiesDeletedUserOption === 'mobileNo'">
                  <label>
                    <span>
                      <input type="text" id="search" class="custom-focus-input" autocomplete="off"
                        [(ngModel)]="searchedMobileNo" placeholder="Search With Mobile No."
                        (input)="searchedByDeletedUsersMobileNo($event)">
                    </span>
                  </label>
                </div>
                <div class="search-box" *ngIf="selectedActivitiesDeletedUserOption === 'uniqueId'">
                  <label>
                    <span>
                      <input type="text" id="search" class="custom-focus-input" autocomplete="off"
                        [(ngModel)]="searchedUniqueId" placeholder="Search With unique Id"
                        (input)="searchedByDeletedUsersUniqueId($event)">
                    </span>
                  </label>
                </div>
                <div class="search-box" *ngIf="selectedActivitiesDeletedUserOption === 'profession'">
                  <label>
                    <span>
                      <input type="text" id="search" class="custom-focus-input" autocomplete="off"
                        [(ngModel)]="searchedProfession" placeholder="Search With Profession"
                        (input)="searchedByDeletedUsersProfession($event)">
                    </span>
                  </label>
                </div>
              </div>
              
            </div>
          </div>
        </div>
        <form [formGroup]="usersInformationForm">
          <div class="row">
            <div class="col-md-2">
              <input type="date" class="section-border form-control" formControlName="fromdate"  (change)="getAllUsersInformationDeletedUserReport(1)" [max]="maxDate">
            </div>
            <div class="col-md-2">
              <input type="date" class="section-border form-control" formControlName="todate" (change)="getAllUsersInformationDeletedUserReport(1)" [max]="maxDate">
            </div>
            <div class="col-md-3">
              <ng-select [items]="lstCountry" bindLabel="country_name" placeholder="Select Country" #selectBox
                (change)="changeSelectCountry($event)" formControlName="selectedCountry" class="section-border ">
              </ng-select>
            </div>
            <div class="col-md-3">
              <ng-select [items]="lstState" bindLabel="state_name" placeholder="Select State" #selectBox
                (change)="changeSelectState($event)" class="section-border" formControlName="selectedState">
              </ng-select>
            </div>
            <div class="col-md-2">
              <input type="text" placeholder="Zip Code" class="section-border form-control">
            </div>
          </div>
        </form>
        <table class="table table-striped">
          <thead>
            <tr>
              <th style="width: 25%;">Name</th>
              <th style="width: 16%;">Unique Id</th>
              <th style="width: 22%;">Mobile Number</th>
              <th style="width: 22%;">Profession</th>
              <th style="width: 15%;">Photos</th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let d of  filteredInactiveUsers| paginate: { itemsPerPage: 20, currentPage: p, totalItems: usersInfoCount }; let i = index"
              style="vertical-align: middle">
              <td style="width: 25%;">
                <img class="profileimage" style="border: 2px solid #f2f2f2; cursor: pointer;"
                  src="{{d.profileImageUrl || 'assets/images/no-image.png'}}"
                  onerror="this.src='assets/images/no-image.png';" (click)="openProfileImageModal(d.profileImageUrl)"
                  alt="Profile Image">
                {{d.Name}}
              </td>
              <td style="width: 16%;">{{d.UserUniqueCode}}</td>
              <td style="width: 22%;">{{d.MobileNo | slice:0:10}}</td>
              <td style="width: 22%;">{{getprofession(d)}}</td>
              <td style="width: 15%;">
                <button style="background: none; border: none;" (click)="openOrganisationImageModal(d)">
                  <img class="profileimage" style="border: 2px solid #f2f2f2;" *ngIf="d.OrganisationImages.length > 0"
                    src="{{d.OrganisationImages[0].orgImagePath || 'assets/images/no-image.png'}}"
                    onerror="this.src='assets/images/no-image.png';" alt="Organisation Image">
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="row">
          <div class="col">
            <div class="d-flex justify-content-center" *ngIf=" filteredInactiveUsers.length != 0">
              <pagination-controls (pageChange)="pageChangedDeletedUser($event)"
                (pageBoundsCorrection)="pageChangedDeletedUser($event)">
              </pagination-controls>
            </div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>

<div id="imageModal" class="modal">
  <span class="close" onclick="document.getElementById('imageModal').style.display='none'">&times;</span>
  <div class="carousel-container">
    <img class="modal-content" id="popupImage" onerror="this.src='assets/images/no-image.png';">
    <button *ngIf="images.length > 1" class="carousel-button prev"style="position: relative;right: 48.5%; bottom: 268px;" (click)="changeImage(-1)">&#10094;</button>
    <button *ngIf="images.length > 1" class="carousel-button next" (click)="changeImage(1)">&#10095;</button>
  </div>
</div>