import { Component, OnInit,ElementRef, Renderer2 } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService } from 'src/app/shared/alert/alert.service';
import { ServerApisService } from 'src/app/shared/services/server-apis.service';
import { Router } from '@angular/router';
import { user } from '@angular/fire/auth';
import { Constants } from 'src/app/shared/constants/constant';
import { RouteMakingService } from 'src/app/shared/services/route-making.service';

@Component({
  selector: 'app-call-logs',
  templateUrl: './call-logs.component.html',
  styleUrls: ['./call-logs.component.css']
})
export class CallLogsComponent implements OnInit{
  home = Constants.home;
  callLog = Constants.callLogs ;
  callLogsRoute = Constants.callLogsRoute;
  callLogs : any = [];
  pageSize: number = 10; 
  currentPage: number = 1;
  loggedIn: boolean;
  showGif: boolean;
  showContactNo: string;
  inProgress: boolean;
  display: string;
  call_id: string;
  authorizatonToken: string;
  intervalId: any;

  constructor(private apiService:ServerApisService,
    private _alertService:AlertService,
    private spinner:NgxSpinnerService,
    private el:ElementRef,
    private renderer:Renderer2,
    private router:Router,
  private routeMakingService:RouteMakingService) { }

  ngOnInit(): void {
    
    if(sessionStorage.getItem('memberId')){
      let payload = {
        "memberId": +sessionStorage.getItem('memberId')
      }
      this.pollApi();
      this.apiService.getCallLogs(payload).subscribe((res:any)=>{
        if (res.status === 200) {
          this.callLogs = res.resultFetchCallLogs;
          if(this.callLogs.length>0){
            this._alertService.info('latest call logs duration will be updated within 5 to 10 minutes')
          }
          this.callLogs.forEach((element:any) => {
            if (element.duration) {
              const durationInSeconds = parseInt(element.duration, 10);
              const durationInMinutes = durationInSeconds / 60;
              element.duration = durationInMinutes.toFixed(2);
            }
            element['initials'] = this.abbreviateName(element.DialedUser);
            const parsedDate = new Date(element.startDatetime)
            parsedDate.setHours(parsedDate.getHours() + 5);
            parsedDate.setMinutes(parsedDate.getMinutes() + 30);
            element.startDatetime = parsedDate.toLocaleString('en-IN', { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true, year: 'numeric', month: 'numeric', day: 'numeric' }).toUpperCase();
            if (element['receiverImage'] !== ''){
              element['receiverImage'] = this.apiService.geturl() + element['receiverImage'];
              element['receiverImage'] = element['receiverImage'].replace(/ToskieService.svc\//g, '');
            }
          });
          
        }
      });
      this.intervalId = setInterval(() => {
        this.pollApi();
      }, 3000);  
    } else {
      this.router.navigate([this.routeMakingService.getNavigateUrl([Constants.Login])]);
    }
  }

  abbreviateName(name: string): string {
    const words = name.split(' ');
  
    if (words.length === 1) {
      return words[0].charAt(0);
    } else {
      return words[0].charAt(0) + ' ' + words[1].charAt(0);
    }
  }

  goToUserProfile(username,memberId):void{
    username = username.replace(/\s+/g, ' ');
    username = username.replace(/ /g, '-');
    let params = [Constants.Profile]
    let routingUrl = this.routeMakingService.getNavigateUrl(params)
    this.router.navigate([routingUrl, `${username}-${memberId}`]).then(() => {
      window.location.reload();
    });
  }

  

  openModal(): void {
    const modal = this.el.nativeElement.querySelector('#viewPhoneNumberModal');
    this.renderer.addClass(modal, 'show');
    this.renderer.setStyle(modal, 'display', 'block');
    document.body.classList.add('modal-open');
    document.body.classList.add('no-backdrop');
  }

  goToProfile():void{
    const modal = this.el.nativeElement.querySelector('#viewPhoneNumberModal');
    this.renderer.setStyle(modal, 'display', 'none');
    this.renderer.removeClass(modal, 'show');
    let user = JSON.parse(sessionStorage.getItem('user'))
    let id = user.memberId;
    let username = user.fname.toLowerCase() + user.lname.toLowerCase()
    username = username.replace(/\s+/g, ' ');
    username = username.replace(/ /g, '-');
    let params = [Constants.Profile]
    let routingUrl = this.routeMakingService.getNavigateUrl(params)
    this.router.navigate([routingUrl, `${username}-${id}`]).then(()=>{
      window.location.reload();
    });
  }

  pollApi(): void {
    if (sessionStorage.getItem('authTokenCall') !== null && sessionStorage.getItem('authTokenCall') !== undefined && sessionStorage.getItem('authTokenCall') !== "" && sessionStorage.getItem('currentCallId') !== null && sessionStorage.getItem('currentCallId') !== undefined && sessionStorage.getItem('currentCallId') !== ""){
      this.authorizatonToken = sessionStorage.getItem('authTokenCall');
      this.call_id = sessionStorage.getItem('currentCallId');
      
      if(this.call_id !== null && this.call_id !== undefined && this.call_id !== "" && this.authorizatonToken !== null && this.authorizatonToken !== undefined && this.authorizatonToken !== ""){
        this.apiService.CheckCallRecords(this.call_id, this.authorizatonToken).subscribe((res: any) => {
          if (res.results.length === 0) {
            this.inProgress = true;
          } else {
            this.inProgress = false; 
          }
        }, (error) => {
          console.log(error);
        });
      }
    }
  }

  ngOnDestroy(): void {
    // Clear the interval when the component is destroyed
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  callProfile(data) {

    let user : any = JSON.parse(sessionStorage.getItem('user'));    
    this.loggedIn = user != null;
    if (this.loggedIn) {
      if (user.mobile === '' || user.mobile === null || user.mobile === undefined){
        this.openModal();
        return;
      } 
      this._alertService.info("Please pick up the call while we connect with the service provider");
      this.showGif = true;
      let payLoad: any = {
        "memberId": +sessionStorage.getItem('memberId'),
        "token": sessionStorage.getItem('token'),
        "deviceType": "web",
        "serviceName": data.serviceNames
      }
      this.apiService.FetchTataTeleDetails(payLoad).subscribe((res1:any) => {
        let payLoad: any = {
          "agent_number": user.mobile,
          "destination_number": data.receiverNum,
          "get_call_id": 1,
          "caller_id" : res1.TataTeleDetails.param4.slice(2)
        }
        let authToken = res1.TataTeleDetails.authorizationToken;
        sessionStorage.setItem("authTokenCall",authToken);
        this.apiService.ClickToCall(payLoad,authToken).subscribe((res2:any) => {
          if(res2.success == false){
            this._alertService.error("Call missed by user");
            this.spinner.hide();
            this.showGif = false;
            let payload: any = {
              "callerId" : sessionStorage.getItem('memberId'),
              "receiverId" : data.DialedMember,
              "tataTeleResponeCallerId" : '',
              "callerNum" : user.mobile,
              "receiverNum" : data.receiverNum,
              "agentNum" : res1.TataTeleDetails.param4.slice(2),
              "startDatetime" : this.getCurrentFormattedDateTime(),
              "callStatus" : 'missed',
            }

            this.apiService.InsertCallLogs(payload).subscribe((res3:any) => {
              // this._alertService.success("Call missed by the user")
              this.spinner.hide();
              this.showGif = false;
            }, (error) => {
              console.log(error);
              this.spinner.hide();
              this.showGif = false;
            });
          } else if (res2.call_id != undefined && res2.success === true) {
              sessionStorage.setItem('currentCallId',res2.call_id);
              this.apiService.CheckCallRecords(res2.call_id, authToken).subscribe((res3: any) => {

                if (res3.results.length === 0) {
                  this.inProgress = true;
                  setTimeout(() => {
                    this._alertService.info("If you want to make a another call then please wait for the current call to be over, call button will enabled after the current call is over.");
                  }, 3000);
                } else {
                  this.inProgress = false; 
                }
              }, (error) => {
                console.log(error);
              });
        
              // Insert caller details and call id into database
              let payload: any = {
                "callerId" : sessionStorage.getItem('memberId'),
                "receiverId" : data.DialedMember,
                "tataTeleResponeCallerId" : res2.call_id,
                "callerNum" : user.mobile,
                "receiverNum" : data.receiverNum,
                "agentNum" : res1.TataTeleDetails.param4.slice(2),
                "startDatetime" : this.getCurrentFormattedDateTime(),
                "callStatus" : res2.hasOwnProperty('call_id') ? 'Outgoing' : 'missed',
              }

              this.apiService.InsertCallLogs(payload).subscribe((res3:any) => {
                this._alertService.success("Call Connected Successfully")
                this.spinner.hide();
                this.showGif = false;
              }, (error) => {
                console.log(error);
                this.spinner.hide();
                this.showGif = false;
              });

          }
        }, (error) => {
          console.log(error);
          this.spinner.hide();
          this.showGif = false;
          if(error.status === 504 || error.status === 503){
            this._alertService.error("Call missed by the user")
            let payload: any = {
              "callerId" : sessionStorage.getItem('memberId'),
              "receiverId" : data.DialedMember,
              "tataTeleResponeCallerId" : '',
              "callerNum" : user.mobile,
              "receiverNum" : data.receiverNum,
              "agentNum" : res1.TataTeleDetails.param4.slice(2),
              "startDatetime" : this.getCurrentFormattedDateTime(),
              "callStatus" : 'missed',
            }
  
            this.apiService.InsertCallLogs(payload).subscribe((res3:any) => {
              // this._alertService.success("Call missed by the user")
              this.spinner.hide();
              this.showGif = false;
            }, (error) => {
              console.log(error);
              this.spinner.hide();
              this.showGif = false;
            });
          }
        });
      }, (error) => {
        console.log(error);
        this.spinner.hide();
        this.showGif = false;
      });
    } else {
      this.display = 'block';
    }
  }

  getCurrentFormattedDateTime(): string {
    const currentDate = new Date();
  
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Month is zero-based
    const day = String(currentDate.getDate()).padStart(2, '0');
    const hours = String(currentDate.getHours()).padStart(2, '0');
    const minutes = String(currentDate.getMinutes()).padStart(2, '0');
    const seconds = String(currentDate.getSeconds()).padStart(2, '0');
    const milliseconds = currentDate.getMilliseconds().toString().padStart(3, '0');
  
    const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
  
    return formattedDateTime;
  }

  onPageChange(pageNumber: number): void {
    this.currentPage = pageNumber;
  }

  getPages(): number[] {
    const totalItems = this.callLogs.length;
    const totalPages = Math.ceil(totalItems / this.pageSize);

    return Array.from({ length: totalPages }, (_, index) => index + 1);
  }

}
