import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgSelectComponent } from '@ng-select/ng-select';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServerApisService } from 'src/app/shared/services/server-apis.service';
import { Role } from 'src/app/shared/enums/role.enum';
import { Constants, navConstants } from 'src/app/shared/constants/constant';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { RouteMakingService } from 'src/app/shared/services/route-making.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-notifications-reports',
  templateUrl: './notifications-reports.component.html',
  styleUrls: ['./notifications-reports.component.css']
})
export class NotificationsReportsComponent {
  p = 1;
  lstNotificatons: any = [];
  lstTempNotifications: any = [];
  isCard: boolean = true;
  page: string = '';
  showNotificationPopup: boolean = false;
  notificationDetails: any;
  searchInfo: string = " ";
  isEditIndex: number;
  recordCount: any;

  constructor(private router: Router, private formBuilder: FormBuilder,
    private _apiService: ServerApisService, private spinner: NgxSpinnerService, private cdr: ChangeDetectorRef, private datePipe: DatePipe, private sanitizer: DomSanitizer, private routeMakingService: RouteMakingService) {
  }
 
  ngOnInit(): void {
    this.spinner.hide()
    const user = sessionStorage.getItem('user');
    if (user != null) {
      let data = JSON.parse(user);
      if (data.roleId === Role.Admin || data.roleId === Role.CallCenter) {
        this.getallnotifications(this.p);
      } else {
        this.router.navigate([""]);
      }
    } else {
      let params = [Constants.Login]
      let routingUrl = this.routeMakingService.getNavigateUrl(params)
      this.router.navigate([routingUrl]);
    }
  }
  loadTabData(report: any) {
    throw new Error('Method not implemented.');
  }
   searchChange(event: any) {
    {
      if (event.target.value != '' && event.target.value != undefined) {
        console.log('fine')
        this.lstNotificatons = this.lstTempNotifications.filter((x: any) =>
          x.title.toLowerCase().includes(event.target.value.toLowerCase()))
      } else {
        this.lstNotificatons = this.lstTempNotifications;
      }
    }
 }
  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  getallnotifications(page?: number) {
    let payload = {
      "page": page,
    }
     this.spinner.show();
    this.lstNotificatons = [];
    this._apiService.getAllNotifications(page).subscribe(res => {
       this.spinner.hide();
      if (res && res['FetchAllNotificationListResult'] && res['FetchAllNotificationListResult'].resultAllAppNotification) {
        this.lstNotificatons = res['FetchAllNotificationListResult'].resultAllAppNotification;
        this.lstTempNotifications = this.lstNotificatons;

        this.scrollToTop();
        this.recordCount = res['FetchAllNotificationListResult'].resultAllAppNotification[0].RecordCount;
      } else {

      }
    });
  }
  fetchNotificationsDetails(id) {
    this.spinner.show();
    this._apiService.fetchNotificationsDetails(id).subscribe((res: any) => {
      this.spinner.hide();
      if (res['FetchNotificationDeatilsResult'].status == 200) {
        this.lstNotificatons = res['FetchNotificationDeatilsResult'];
        this.lstTempNotifications = this.lstNotificatons;
      }
    });
  }
  showPopup(notificationId: any) {
    this._apiService.fetchNotificationsDetails(notificationId).subscribe((res: any) => {
      this.spinner.hide();
      if (res['FetchNotificationDeatilsResult'].status == 200) {
        let result = res['FetchNotificationDeatilsResult'];
        this.notificationDetails = {
          createdDate: result.createdDate,
          expiryDate: result.expiryDate,
          notificationId: result.notificationId,
          notificationType: result.notificationType,
          message: result.message,
          title: result.title,
          senderFullName: result.senderFullName,
          receiverFullName: result.receiverFullName,
          videoUrlPath: result.videoUrlPath ? this.setSafeYoutubeUrl(result.videoUrlPath) : null,
          imagePath: result.imagePath,
        };
        this.showNotificationPopup = true;
      }
    });
  }

  notification(notification: any) {
    throw new Error('Method not implemented.');
  }

  closePopup() {
    this.showNotificationPopup = false;
    this.notificationDetails = false;
  }
  pageChanged(event: any) {
    this.p = event;
    this.getallnotifications(this.p);
    this.isEditIndex = -1;
  }
  safeYoutubeUrl: SafeResourceUrl | null = null;

  setSafeYoutubeUrl(url: string): SafeResourceUrl {
    const embedUrl = this.getYoutubeEmbedUrl(url);
    if (embedUrl) {
      this.safeYoutubeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(embedUrl);
    } else {
      this.safeYoutubeUrl = null;
    }
    this.cdr.detectChanges();
    return this.safeYoutubeUrl;
  }

  getYoutubeEmbedUrl(youtubeUrl: string): string | null {
    const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=|shorts\/)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = youtubeUrl.match(regex);
    return match && match[1] ? `https://www.youtube.com/embed/${match[1]}` : null;
  }
  getSafeYoutubeUrl(url): SafeResourceUrl {
    const unsafeUrl = url;
    try {
      return this.sanitizer.bypassSecurityTrustResourceUrl(unsafeUrl);
    } catch (e) {
      console.error('Error creating safe YouTube URL:', e);
      return this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/default-video-id');
    }
  }
  getisRead(value: any) {
    if (value == 'Y') {
      return "Yes";
    } else {
      return "No";
    }
  }


}
