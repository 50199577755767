<section class="pad2-5">
    <div class="search-title mlft-6" style="margin: 0;">

        <app-bread-crumb class="bread-crumb-position" [breadpath1]="home" [breadpath2]="search"
            [breadroute1]="serviceProviderRoute"></app-bread-crumb>
    </div>
    <div class="container-fluid cnt-fld-result">
        <div class="container">
            <div class="search-title">
            </div>
        </div>
        <div class="search-results-section">
            <div class="container-fluid profile-page">
                <div class="row">
                    <div class="col-lg-3 col-md-4 col-sm-6 col-12" style="padding: 0;" *ngFor="let provider of providerListFinal | paginate: { itemsPerPage: 20, totalItems:recordCount, currentPage: p };; let i = index">
                        <div class="row search-list-items" >
                            <div class="row">
                                <div class="col-sm-4 col-4 provider-section hand">
                                    <a  class="img-circle"
                                        [href]="'/profile/' + getFormattedUsername(provider) + '-' + provider.memberId" 
                                        target="_blank" 
                                        style="text-decoration: none; color: inherit;"
                                        (click)="handleClick($event, provider)">
                                        <img 
                                            class="img-circle" 
                                            [src]="provider.profileImageUrl"  
                                            alt="provider-image" 
                                            title="provider image" 
                                            (error)="handleImageError(provider)" 
                                            *ngIf="!provider.imageError">
                                        <div 
                                            style="text-align: center; font-size: 20px; cursor: pointer;" 
                                            *ngIf="provider.imageError">
                                            <h1 style="margin-top: 26%;">{{ getuserChar(provider) }}</h1>
                                        </div>
                                    </a>
                                </div>
                                
                            <div class="col-sm-8 col-8" style="padding-left: 20px;">
                                <div class="row">
                                    <a class="media-heading text-truncate media-title hand"
                                        [href]="'/profile/' + getFormattedUsername(provider) + '-' + provider.memberId" 
                                        target="_blank" 
                                        style="text-decoration: none; color: inherit;"
                                        (click)="handleClick($event, provider)">
                                        {{ (provider.fname + ' ' + provider.lname) | titlecase | slice:0:20 }}
                                        <span *ngIf="(provider.fname + ' ' + provider.lname).length > 20">...</span>
                                    </a>
                                </div>                                
                                
                                <div class="row">
                                    <label class="media-heading media-dec" style="color: #38b6ff;" [title]="provider.Services[0].userService">
                                        {{ (provider.Services[0].userService | slice:0:20) + (provider.Services[0].userService.length > 20 ? '...' : '') }}
                                    </label>
                                </div>
                                <div class="row">
                                    <ng-template #t let-fill="fill">
                                        <span class="fetchstar mr-5" [class.full]="fill === 100">
                                            <span class="half" [style.width.%]="fill">&#9733;</span>&#9733;
                                        </span>
                                    </ng-template>
                                    <ngb-rating [(rate)]="provider.Rating" [starTemplate]="t" [readonly]="true" max="5"></ngb-rating>
                                </div>
                            </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-6" style="margin-left: 0;padding-left: 0;margin-top: 10px;">
                                    <button type="button" class="btn btn-call hand auser" (click)="callProfile(provider)" [disabled]="inProgress">
                                        <i class="fa fa-phone" aria-hidden="true"></i> <span class="call-btn-text">Call</span>
                                    </button>
                                </div>
                                <div class="col-lg-4 col-md-4 col-4 distance">
                                    <label style="color: #000000fb;width:80px;margin-bottom: 0;line-height: 45px;">{{ ((+provider.distance) * 0.001).toFixed(0) }}Kms</label>
                                </div>
                                <div class="col-lg-2 col-md-2 col-2 p-0">
                                    <span style="color: #38b6ff!important">
                                        <i class="fa fa-heart-o shortList" (click)="addWishlist(provider)" *ngIf="provider.isFavourite=='N'"></i>
                                        <i class="fa fa-heart shortList" (click)="deleteWishlist(provider)" *ngIf="provider.isFavourite=='Y'"></i>
                                    </span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 background-style">
                                    <img src="assets/images/toskie-verified.png" class="img-fluid" *ngIf="provider.isAccountVerified == 'Y'"  alt="toskie verified" title="toskie verified">
                                    <img src="assets/images/profession-verified.png" class="img-fluid" *ngIf="provider.isProfessionVerified == 'Y'"  alt="profession verified" title="profession verified">
                                    <img src="assets/images/business-verified.png" class="img-fluid" *ngIf="provider.isBusinessVerified == 'Y'" alt="business verified" title="business verified">
                                    <img src="assets/images/not-verified.png" class="not-verified img-fluid" *ngIf="provider.isAccountVerified == 'N' && provider.isProfessionVerified == 'N' && provider.isBusinessVerified == 'N'"  alt="not verified" title="not verified">                
                                </div>
                            </div>
                        </div>
                    </div>
                    

                    <div class="d-flex justify-content-center" *ngIf="providerListFinal.length!=0">
                        <pagination-controls (pageChange)="pageChanged($event)"
                            (pageBoundsCorrection)="pageChanged($event)">
                        </pagination-controls>
                    </div>
                </div>
                <div class="row">
                    <div class="col pagent">

                        <div *ngIf="providerListFinal.length==0">
                            <app-no-provider-results-found [search]="providerSearch" [IsData]="isResults"
                                [Islogin]="loggedIn"></app-no-provider-results-found>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>

</section>
<div id="id01" class="modal" [ngStyle]="{'display':display}">
    <form class="modal-content1" action="/action_page.php">
        <div class="container">
            <p class="paragraph">Please sign in to reach the Service Provider</p>
            <div class="clearfix">
                <button type="button" (click)="goToHome()" class="modalbutton">Ok</button>
            </div>
        </div>
    </form>
</div>

<div class="modal fade" id="viewPhoneNumberModal" tabindex="-1" aria-labelledby="viewPhoneNumberModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body">
                <div class="text-center">
                    Update Mobile Number
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary close" data-bs-dismiss="modal" (click)="goToProfile()">Go To Profile</button>
            </div>
        </div>
    </div>
</div>

<div *ngIf="showGif" class="loading-overlay">
    <div class="loading-gif-container">
        <img src="../../../assets/gif/call.gif" alt="Calling..." title="Calling..."/>
    </div>
</div>