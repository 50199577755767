import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgSelectComponent } from '@ng-select/ng-select';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServerApisService } from 'src/app/shared/services/server-apis.service';
import { Role } from 'src/app/shared/enums/role.enum';
import { Constants, navConstants } from 'src/app/shared/constants/constant';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { RouteMakingService } from 'src/app/shared/services/route-making.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-profile-data-reports',
  templateUrl: './profile-data-reports.component.html',
  styleUrls: ['./profile-data-reports.component.css']
})
export class ProfileDataReportsComponent implements OnInit {
  p = 1;
  selectedItems: any = [];
  isCard: boolean = true;
  page: string = '';
  lstTempProfileData: any;
  lstProfileData: any;
  isEditIndex: number;
  count: any;
  searchedAll: string = '';
  searchedName: string = '';
  searchedMemberId: string = '';
  searchedUniqueId: string = '';
  selectedActivitiesOption: string = 'name';

  constructor(private router: Router, private formBuilder: FormBuilder,
    private _apiService: ServerApisService, private spinner: NgxSpinnerService, private cdr: ChangeDetectorRef, private datePipe: DatePipe, private sanitizer: DomSanitizer, private routeMakingService: RouteMakingService) {
  }

  ngOnInit(): void {
    const user = sessionStorage.getItem('user');
    if (user != null) {
      let data = JSON.parse(user);
      if (data.roleId === Role.Admin || data.roleId === Role.CallCenter) {
        this.getProfileData(this.p);
      } else {
        this.router.navigate([""]);
      }
    } else {
      let params = [Constants.Login]
      let routingUrl = this.routeMakingService.getNavigateUrl(params)
      this.router.navigate([routingUrl]);
    }
  }

  getWebUrl() {
    return environment.webUrl;
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  pageChanged(event: any) {
    this.p = event;
    this.getProfileData(this.p);
    window.scrollTo(0, 0);
  }

  getProfileData(page: number) {
    let payload = {
      "searchedMemberID": this.searchedMemberId != '' ? this.searchedMemberId : 0,
      "searchedName": this.searchedName,
      "searchedUniqueID": this.searchedUniqueId,
      "searchedAll": this.searchedAll,
      "page": page
    };
   this._apiService.GetProfileData(payload).subscribe(
      (res: any) => {
        this.spinner.hide();
        if (res['FetchReportUserProfileDataListResult'] && res['FetchReportUserProfileDataListResult'].status === 200) {
          this.lstProfileData = res['FetchReportUserProfileDataListResult'].resultUserProfileData.map((profile) => ({
            ...profile,
            profileLink: `${this.getWebUrl()}/profile/${profile?.fname ? profile.fname.replace(/\s+/g, '-') : ''}-${profile?.lname ? profile.lname.replace(/\s+/g, '-') : ''}-${profile?.memberId}`,
          }));
          this.count = this.lstProfileData[0].RecordCount;
          this.lstTempProfileData = this.lstProfileData;
          this.scrollToTop();
        } else {
          console.error('API response not as expected:', res);
        }
      },
      (error) => {
        this.spinner.hide();
        console.error('Error fetching profile data:', error);
      }
    );
  }

  resetActivitiesOptions() {
    this.searchedAll = '';
    this.searchedName = '';
    this.searchedUniqueId = '';
    this.searchedMemberId = '';
    this.getProfileData(1);
  }

  searchByUniqueId(event: any) {
    this.getProfileData(1);
  }

  searchByMemberId(event: any) {
    this.getProfileData(1);
  }

  searchByName(event: any) {
   this.getProfileData(1);
  }

  searchedByAll(event: any) {
      this.getProfileData(1);
  }
}
