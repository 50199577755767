import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators ,AbstractControl} from '@angular/forms';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/shared/alert/alert.service';
import { ServerApisService } from 'src/app/shared/services/server-apis.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgSelectComponent } from '@ng-select/ng-select';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import Quill from 'quill';
import { Role } from 'src/app/shared/enums/role.enum';
import { Constants,navConstants } from 'src/app/shared/constants/constant';
import { RouteMakingService } from 'src/app/shared/services/route-making.service';

@Component({
  selector: 'app-push-notification',
  templateUrl: './push-notification.component.html',
  styleUrls: ['./push-notification.component.css']
})
export class PushNotificationComponent implements OnInit {
  showDelay = new FormControl(1000);
  hideDelay = new FormControl(3000);
  editor: Quill;
  @ViewChild('selectBox') selectBox: NgSelectComponent;
  elementRef: ElementRef;
  p = 1;
  adminPanel: string = Constants.adminPanel;
  isMessageValid: boolean = false;
  pushNotification: string = navConstants.pushNotification;
  errorMessage: string = "";
  isLimitExceeded: boolean = false;
  adminPanelRoute: string = Constants.adminPanelRoute;
  pushNotificationTitle: string = Constants.pushNotificationTitle;
  selectedCityIds: any = [];
  selectedItems: any = [];
  isNotificationFormValid: boolean = false;
  showPopup: boolean = false;

  editorModules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'header': 1 }, { 'header': 2 }],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'script': 'sub' }, { 'script': 'super' }],
      ['blockquote', 'code-block'],
      [{ 'indent': '-1' }, { 'indent': '+1' }],
      [{ 'direction': 'rtl' }],
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      [{ 'color': [] }, { 'background': [] }],
      [{ 'align': [''] }],
      [{ font: [] }],
      ['link'],
    ],
  };

  videoModal: boolean = false;
  previewBtn: boolean = false;
  imageError: string = '';
  errorMessageTag: string;

  constructor(private sanitizer: DomSanitizer, private router: Router, private formBuilder: FormBuilder, private _apiService: ServerApisService, private routeMakingService: RouteMakingService,

    private spinner: NgxSpinnerService,
    private _alertService: AlertService, private datePipe: DatePipe, private _elementRef: ElementRef, private renderer: Renderer2, private cd: ChangeDetectorRef) {
    // const FontAttributor = Quill.import('attributors/class/font');
    // FontAttributor.whitelist = [
    //   'arial',
    //   'times-new-roman',
    //   'calibri',
    //   'comic-sans-ms',
    //   'monospace',
    //   'roboto',
    //   'sans-serif',
    // ];
    // Quill.register(FontAttributor, true);
  }
  // @ViewChild('editor', { static: true }) editor: Element
  notificationForm: FormGroup;
  lstUsers: any = [];
  lstSkills: any = [];
  lstTempSkills: any = [];
  lstOrganizations: any = [];
  lstTempOrganization: any = [];
  editorcontent: any;
  ismessage: boolean = false;
  messageEvent: string = '';
  lsttempusers: any[];
  isReciverTyp = '1';
  characterLimit = 1500;
  isAllUsers: string = "";
  isTableMessageValid: boolean = false;
  isImageMessageValid: boolean = false;
  selectedRadio: string = '';
  isdropdownshow: boolean = false;
  isnotificationTypeshow: boolean = false;
  notificationtype = "Text";
  lstSelectedUsers: any = [];
  lstCities: any = [];
  lstZipCodes: any = [];
  isFormValid: boolean = false;
  submitted = false;
  isLocation: boolean = false;
  lstCountry: any = [];
  lstState: any = [];
  minDate: any;
  public imagePath;
  extn: any;
  blob: any;
  filestream: any;
  ImgValidate: any;
  temprecever: string = "";
  imageChangedEvent: any = '';
  countryName: string = '';
  stateName: string = '';
  cityName: string = '';
  zipCodeid: number = 0;
  user: string = "select";
  reciverType: string = '1';
  selectedFile: File | null;
  characterCount: number = 0;
  ngOnInit(): void {
    const urlRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
    var date = new Date();
    this.minDate = this.datePipe.transform(date, "yyyy-MM-dd");
    const user = sessionStorage.getItem('user');
    if (user != null) {
      let data = JSON.parse(user);
      if (data.roleId === Role.Admin || data.roleId === Role.SubAdmin ||  data.roleId === Role.MarketingDM ||  data.roleId === Role.Manager) {    
        this.notificationForm = this.formBuilder.group({
          title: ['', [Validators.required, this.noHtmlValidator]],
          message: ['', [Validators.required, Validators.maxLength(this.characterLimit)]],
          date: ['', Validators.required],
          userid: [0],
          image: [''],
          vediopath: ['', [Validators.pattern(urlRegex), this.videoUrlValidator]],
          country: [0],
          username: ["--Select--"],
          state: [0],
          city: [0],
          zipcode: ['']
        });
        this.reciverType = '1';
        this.getusers();
        this.getCountries();

      } else {
        this.router.navigate([""]);
      }

    } else {
      this.router.navigate([this.routeMakingService.getNavigateUrl([Constants.Login])]);
    }
    this.previewBtn = false;
    window.scrollTo(0, 0);
    this.updateFormValidity();
    this.notificationForm.get('message').valueChanges
      // .pipe(
      //   debounceTime(300), 
      //   distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr))
      // )
      .subscribe((value: string) => {
        this.onMessageChange(value);
      });
  }

  ngAfterViewInit() {
    if(this.editor) {
      this.editor = new Quill('#editor', {
        modules: this.editorModules,
        theme: 'snow',
      });
      this.editor.on('text-change', () => {
        const htmlContent = this.editor.root.innerHTML;
        const strippedContent = this.stripHtmlTags(htmlContent);
        this.onMessageChange(strippedContent);
      });
    }
  }

  updateFormValidity() {
    this.isNotificationFormValid = this.notificationForm.valid;
  }
  isValidRecieverType: boolean = false;
  changeLocation(event) {

    if (event.target.checked) {
      this.isLocation = true;
      this.selectedRadio = 'country';
      if (this.isReciverTyp == '2' || this.isReciverTyp == '3') {
        this.isValidRecieverType = true;
      }
    } else {
      this.isLocation = false;
      this.isValidRecieverType = false;
    }

  }
  openPopup() {
    this.showPopup = true;
  }

  closePopup() {
    this.showPopup = false;
  }
  openSupportPage(){
    this.router.navigate(['/user-guide']);
  }
  
  
 private urlCache: { [url: string]: SafeResourceUrl } = {};
  getSafeUrl(url: string): SafeResourceUrl {
    if (this.urlCache[url]) {
      return this.urlCache[url];
    }
    let safeUrl: SafeResourceUrl;
    if (url.includes('youtube.com/watch?v=') || url.includes('youtu.be/')) {
      const embedUrl = this.getYoutubeEmbedUrl(url);
      if (!embedUrl) {
        return '';
      }
      safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(embedUrl);
    } else if (url.includes('shorts/')) {
      const videoId = this.extractShortsVideoId(url);
      if (!videoId) {
        return '';
      }
      const embedUrl = `https://www.youtube.com/embed/${videoId}`;
      safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(embedUrl);
    } else if (url.includes('fb.watch') || url.includes('fb') || url.includes('facebook')) {
      const videoId = this.extractFacebookVideoId(url);
      if (videoId) {
        const embedUrl = `https://www.facebook.com/plugins/video.php?href=${encodeURIComponent(url)}&width=500&show_text=false`;
        safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(embedUrl);
      } else {
        return '';
      }
    } else {
      return '';
    }

    this.urlCache[url] = safeUrl;
    return safeUrl;
  }

  videoUrlValidator(control: FormControl) {
    const videoUrl = control.value;
    if (!videoUrl) {
      return null;
    }
    const acceptedExtensions = ['.mp4', '.mov', '.avi', '.mkv', '.webm', '.ogg'];
    const isYouTubeUrl = videoUrl.includes('youtube.com/watch?v=') || videoUrl.includes('youtu.be/') || videoUrl.includes('youtube.com/shorts/') || videoUrl.includes('youtu.be/shorts/');
    const isFacebookUrl = videoUrl.includes('fb.watch') || videoUrl.includes('fb') || videoUrl.includes('facebook');

    if (isYouTubeUrl || isFacebookUrl) {
      return null;
    }
    const urlParts = videoUrl.split('.');
    const fileExtension = urlParts[urlParts.length - 1].toLowerCase();

    if (acceptedExtensions.includes(`.${fileExtension}`)) {
      return null;
    } else {
      return { invalidVideoUrl: true };
    }
  }

  extractShortsVideoId(url: string): string | null {
    const shortsRegex = /(?:youtube\.com\/shorts\/|youtu.be\/shorts\/)([a-zA-Z0-9_-]{11})/i;
    const match = url.match(shortsRegex);

    if (match && match[1]) {
      return match[1];
    } else {
      return null;
    }
  }

  extractFacebookVideoId(url: string): string | null {
    const regex = /(?:fb\.watch\/|facebook\.com\/.*\/videos\/)([a-zA-Z0-9_-]+)/i;
    const match = url.match(regex);

    if (match && match[1]) {
      return match[1];
    } else {
      return null;
    }
  }

  getYoutubeEmbedUrl(youtubeUrl: string): string | null {
    const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = youtubeUrl.match(regex);
    if (match && match[1]) {
      return `https://www.youtube.com/embed/${match[1]}`;
    }
    return null;
  }


  changereciverType(event: any) {

    let val = event.target.value;
    this.isValidRecieverType = false;
    this.isReciverTyp = val;
    this.lstSelectedUsers = [];
    this.lstResult = [];
    this.temprecever = "";
    this.notificationForm.patchValue({ userid: -1 });
    this.notificationForm.patchValue({ username: '--Select--' });
    if (val == '1') {
      this.isLocation = false;
      this.getusers();
    } else if (val == '2') {
      this.getSkills();
      this.isLocation = false;
    } else if (val == '3') {
      this.getOrganizations();
      this.isLocation = false;
    } else if (val == '4') {
      this.isLocation = true;
      this.selectedRadio = 'country';
    }
    this.onSubmit();
  }
  searchUserchange(event) {

    if (event.target.value != "" && event.target.value != null && event.target.value != undefined) {
      this.isdropdownshow = true;
      var result = [];

      if (this.isReciverTyp == '1') {
        result = this.lsttempusers.filter((x: any) => x.userId.toLowerCase().includes(event.target.value.toLowerCase()));
        this.lstUsers = result;
      } else
        if (this.isReciverTyp == '2') {
          result = this.lstTempSkills.filter((x: any) => x.subProfessionCategoryName.toLowerCase().includes(event.target.value.toLowerCase()));
          this.lstSkills = result;
        } else
          if (this.isReciverTyp == '3') {
            result = this.lstTempOrganization.filter((x: any) => x.organisationName.toLowerCase().includes(event.target.value.toLowerCase()));
            this.lstOrganizations = result;
          }

    } else {

      if (this.isReciverTyp == '1') {
        this.lstUsers = this.lsttempusers;
      } else
        if (this.isReciverTyp == '2') {
          this.lstSkills = this.lstTempSkills;
        } else
          if (this.isReciverTyp == '3') {
            this.lstOrganizations = this.lstTempOrganization;
          }
    }
  }
  getCountries() {
    this._apiService.GetCountries().subscribe(res => {
      this.lstCountry = res['GetAllCountriesResult'].resultCountries;
    });
  }
  getStates(countryId: any) {
    let payload = {
      country_id: countryId
    }
    this.spinner.show();

    this._apiService.GetStatesByCountry(payload).subscribe(res => {
      this.spinner.hide();


      this.lstState = res.resultStates;

    });
  }
  getCities(stateId: any) {
    let payload = {
      state_id: stateId
    }
    this.spinner.show();

    this._apiService.GetCitiesByState(payload).subscribe(res => {
      this.spinner.hide();

      this.lstCities = res.resultCities;
    });
  }
  getZipcodes(cityId: any) {
    let payload = {
      city_id: cityId
    }
    this.spinner.show();
    this._apiService.GetZipcodeByCities(payload).subscribe(res => {
      this.spinner.hide();

      this.lstZipCodes = res.resultZipcodes;
    });
  }
  selectCountryChange(event) {

    this.changeSelectCountry(event.target.value);
  }
  changeSelectCountry(event: any) {

    if (event != undefined) {
      this.notificationForm.patchValue({ country: event.country_id });
      this.countryName = event.country_name;
      this.lstState = [];
      this.getStates(event.country_id)
    } else {
      this.notificationForm.patchValue({ country: 0 });
      this.lstState = [];
      this.lstCities = [];
      this.lstZipCodes = [];
    }

  }
  changeSelectState(event: any) {
    if (event != undefined) {
      this.notificationForm.patchValue({ state: event.state_id });
      this.stateName = event.state_name;
      this.lstCities = [];
      this.getCities(event.state_id)
    } else {
      this.notificationForm.patchValue({ state: 0 });
      this.lstCities = [];
      this.lstZipCodes = [];
    }
  }
  changeSelectCity(event: any) {
    if (event != undefined) {
      this.notificationForm.patchValue({ city: event.city_id });
      this.cityName = event.city_name;
      this.lstZipCodes = [];
      this.getZipcodes(event.city_id)
    } else {
      this.notificationForm.patchValue({ city: 0 });
      this.lstZipCodes = [];
    }


  }
  changeSelectZipcode(event: any) {
    if (event != undefined) {
      this.notificationForm.patchValue({ zipcode: event.zipcode });
      this.zipCodeid = event.zipcode_id;
    } else {
      this.notificationForm.patchValue({ zipcode: '' });
    }

  }
  isTitleValid: boolean = false;
  titleChange(event: any) {
    if (event.target.value.length >= 250) {
      this.isTitleValid = true;
    } else {
      this.isTitleValid = false;
    }
    this.onSubmit();
  }
  get f() { return this.notificationForm.controls; }
  pageChanged(event: any) {
    this.p = event;
  }

  noHtmlValidator(control: AbstractControl): { [key: string]: any } | null {
    const forbidden = /<[^>]*>/g.test(control.value);
    return forbidden ? { 'noHtml': { value: control.value } } : null;
  }

  onTitleChange() {
    const titleControl = this.notificationForm.get('title');

    // Check if the title contains emoji characters
    if (titleControl.value && this.containsEmoji(titleControl.value)) {
      // Update the form control with the original title (it should be correctly encoded)
      titleControl.setValue(titleControl.value);
      titleControl.updateValueAndValidity();
    }

    // console.log('Form Status:', this.notificationForm.status);
    // console.log('Title Field Valid:', this.f.title.valid);

    this.notificationForm.statusChanges.subscribe(() => {
      this.updateFormValidity();
    });
  }

  containsEmoji(text: string): boolean {
    const emojiRegex = /[\p{Emoji_Presentation}\p{Emoji}\p{Emoji_Modifier_Base}\p{Emoji_Modifier}\p{Emoji_Component}]/gu;
    return emojiRegex.test(text);
  }

  payloadRequest: any;
  onSubmit() {

    const hasImage = !!this.notificationForm.controls['image'].value;
    const hasVideo = !!this.notificationForm.controls['vediopath'].value;

    if (hasImage && hasVideo) {
      this.notificationtype = 'ImageAndVideo';
    } else if (hasImage) {
      this.notificationtype = 'Image';
    } else if (hasVideo) {
      this.notificationtype = 'Video';
    } else {
      this.notificationtype = 'Text';
    }

    this.isLimitExceeded = false;
    this.temprecever = this.notificationForm.controls['username'].value

    var senderid = sessionStorage.getItem('memberId');
    const payload: any = {};
    payload.title = this.notificationForm.controls['title'].value;

    payload.message = this.notificationForm.controls['message'].value?.toString();
    if (payload.message == '') {
      this.ismessage = true;
    } else {
      this.ismessage = false;
    }

    //payload.receiverId=this.notificationForm.controls['userid'].value;
    payload.senderId = senderid;
    payload.countryId = (+this.notificationForm.controls['country'].value);
    payload.stateId = (+this.notificationForm.controls['state'].value);
    payload.cityId = (+this.notificationForm.controls['city'].value);
    payload.zipCode = this.notificationForm.controls['zipcode'].value;
    payload.expiryDate = this.notificationForm.controls['date'].value;
    payload.notificationType = this.notificationtype;
    payload.countryName = this.countryName;
    payload.stateName = this.stateName;
    payload.receiverType = "";
    payload.cityName = this.cityName;
    payload.zipCodeId = (+this.zipCodeid);
    payload.receiverId = "";
    payload.receiverTypeSkills = "";
    payload.receiverTypeOrgName = "";

    if (this.lstSelectedUsers.length > 0) {
      for (var i = 0; i < this.lstSelectedUsers.length; i++) {

        if (this.isReciverTyp == '1') {
          payload.receiverType = "Client";
          if (payload.receiverId == "") {
            payload.receiverId = this.lstSelectedUsers[i].userid
          } else {
            payload.receiverId = payload.receiverId + ',' + this.lstSelectedUsers[i].userid;
          }
        }
        if (this.isReciverTyp == '2') {
          payload.receiverId = 0;
          payload.receiverType = "Skills";
          if (this.lstSelectedUsers[i].username == 'ALL') {
            payload.receiverTypeSkills = "";
            break;
          } else
            if (payload.receiverTypeSkills == "") {
              payload.receiverTypeSkills = this.lstSelectedUsers[i].username
            } else {
              payload.receiverTypeSkills = payload.receiverTypeSkills + ',' + this.lstSelectedUsers[i].username;
            }
        }
        if (this.isReciverTyp == '3') {
          payload.receiverId = 0;
          payload.receiverType = "Organization";
          if (this.lstSelectedUsers[i].username == 'ALL') {
            payload.receiverTypeOrgName = "";
            break;
          } else
            if (payload.receiverTypeOrgName == "") {
              payload.receiverTypeOrgName = this.lstSelectedUsers[i].username
            } else {
              payload.receiverTypeOrgName = payload.receiverTypeOrgName + ',' + this.lstSelectedUsers[i].username;
            }
        }
      }
    }

    if (payload.title != '' && payload.message != '' && payload.expiryDate != '' && payload.expiryDate != null && payload.title != null && payload.message != null) {
      this.isFormValid = true;

      var date = new Date();
      var min: any = date.getMinutes();
      if (min.toString().length == 1) {

        min = '0' + min;
      }
      var hrs: any = date.getHours();
      if (hrs.toString().length == 1) {

        hrs = '0' + hrs;
      }
      var time = hrs + ':' + min;
      payload.expiryDate = payload.expiryDate + 'T' + time + 'Z';
    } else {
      this.isFormValid = false;
    }
    if (this.isFormValid == true) {
      if (this.notificationtype == 'ImageAndVideo') {
        payload.imageName = this.notificationForm.controls['image'].value;

        payload.videoUrlPath = this.notificationForm.controls['vediopath'].value;

        if (payload.imageName != '' && payload.videoUrlPath != '' && this.notificationForm.controls['vediopath'].status != "INVALID") {
          this.isFormValid = true;
        } else {
          this.isFormValid = false;
        }
      } else if (this.notificationtype == 'Image') {
        payload.imageName = this.notificationForm.controls['image'].value;
        payload.videoUrlPath = '';
        if (payload.imageName != '') {
          this.isFormValid = true;
        } else {
          this.isFormValid = false;
        }
      } else if (this.notificationtype == 'Video') {
        payload.imageName = '';
        payload.videoUrlPath = this.notificationForm.controls['vediopath'].value;
        this.blob = '';
        if (payload.videoUrlPath != '' && this.notificationForm.controls['vediopath'].status != "INVALID") {
          this.isFormValid = true;
        } else {
          this.isFormValid = false;
        }
      } else if (this.notificationtype == 'Text') {
        payload.imageName = '';
        payload.videoUrlPath = '';
        this.blob = '';
      }

    }
    if (this.isReciverTyp == '4') {
      this.temprecever = '';
      payload.receiverId = 0;
      payload.receiverType = "Location";
    } else {
      if (this.lstResult.length == 0) {
        this.isFormValid = false;
      }
    }
    if (this.isFormValid && !this.isMessageValid && !this.isTitleValid && !this.isTableMessageValid) {
      payload.message = this.messageEvent;

      payload.receiverId = payload.receiverId.toString();
      this.isNotificationFormValid = true;
      this.payloadRequest = payload;
    } else {
      this.isNotificationFormValid = false;
    }
  }

  sendNotifications() {
    this.onSubmit();
    if (this.isNotificationFormValid) {
      this.submitted = true;
      this.ismessage = false;
      this.isNotificationFormValid = false;

      this._apiService.sendPublicPushNotificationViaFirebase(this.payloadRequest, this.blob).subscribe();

      this.submitted = false;
      this.ismessage = false;
      this.spinner.hide();
      this.clearAll();

      let successMessage = "Notification has been successfully sent. Please allow up to 30 minutes for a response.";
      this._alertService.success(successMessage);
      window.scrollTo(0, 0);
    }
  }

  removeConsecutiveBrTags(html: string): string {
    return html.replace(/(<br\s*\/?>\s*){2,}/g, '<br>');
  }


  clearAll() {

    this.submitted = false;
    this.isFormValid = false;
    this.notificationtype = 'Text';
    this.lstUsers = this.lsttempusers;
    this.isReciverTyp = '1';
    this.isLocation = false;
    this.notificationForm.reset();
    this.notificationForm.patchValue({ zipcode: '' });
    this.notificationForm.patchValue({ country: 0 });
    this.notificationForm.patchValue({ state: 0 });
    this.notificationForm.patchValue({ city: 0 });
    this.notificationForm.patchValue({ image : ''});
    this.countryName = '';
    this.stateName = '';
    this.cityName = '';
    this.zipCodeid = 0;
    this.temprecever = '--Select--';
    this.lstSelectedUsers = [];
    this.selectBox.clearModel();
    window.scrollTo(0, 0);
  }
  isMessageImageValid: boolean = false;
  @HostListener('paste', ['$event'])
  onMessageChange(currentContent: string): void {
    const strippedContent = this.stripHtmlTags(currentContent);
   // console.log('Stripped Content:', strippedContent.length);

    if (strippedContent !== '' && strippedContent !== null && strippedContent !== undefined) {
      this.ismessage = false;
      this.isMessageValid = false;
    } else {
      this.ismessage = true;
      this.isMessageValid = true;
    }

    this.onSubmit();
  }

  stripHtmlTags(htmlString: string): string {
    const doc = new DOMParser().parseFromString(htmlString, 'text/html');

    const preservedTags = ['a'];
    const allTags = doc.body.children;

    for (let i = allTags.length - 1; i >= 0; i--) {
      const tag = allTags[i];
      if (!preservedTags.includes(tag.tagName.toLowerCase())) {
        tag.parentNode?.replaceChild(doc.createTextNode(tag.textContent || ''), tag);
      }
    }

    return doc.body.innerHTML || "";
  }

  // Function to process the input and convert URLs to links
  processInput(content: string): string {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return content.replace(urlRegex, (url) => `<a href="${url}" target="_blank">${url}</a>`);
  }

  getusers() {
    this.spinner.show();
    this._apiService.GetAllUsers().subscribe(res => {
      this.spinner.hide();
      let arr = [{ memberId: 0, userId: 'ALL' }];
      let newArr = res['GetAllUsersDropDownValuesResult'].UserDropDownValues;
      for (var i = 0; i < newArr.length; i++) {
        arr.push(newArr[i]);
      }
      this.lstUsers = arr;
      this.lsttempusers = arr;
      this.getCountries();
    });
  }
  getSkills() {
    this.spinner.show();
    this._apiService.getAllSubProfessionCategory().subscribe(res => {
      this.spinner.hide();

      let arr = [{ subProfessionsCategoryId: 0, subProfessionCategoryName: 'ALL' }];

      let newArr = res.GetAllSubProfessionCategoryResult['resultSubProfessionCategory'];

      for (var i = 0; i < newArr.length; i++) {
        arr.push(newArr[i]);
      }
      this.lstSkills = arr;
      this.lstTempSkills = arr;
    });
  }
  getOrganizations() {
    this.spinner.show();
    this._apiService.FetchAllOrganisationNames().subscribe(res => {
      this.spinner.hide();

      let arr = [{ organisationId: 0, organisationName: 'ALL' }];

      let newArr = res['FetchAllOrganisationNamesResult'].resultFetchAllOrganisationNames;
      for (var i = 0; i < newArr.length; i++) {
        if (newArr[i]) {
          arr.push({ organisationId: arr.length + 1, organisationName: newArr[i].organisationName });
        }
      }

      this.lstOrganizations = arr;
      this.lstTempOrganization = arr;
    });
  }
  videoURlChange() {
    if (this.notificationForm.controls['vediopath'].value != '' && this.notificationForm.controls['vediopath'].status != "INVALID") {
      this.previewBtn = true;
    } else {
      this.previewBtn = false;
    }
    this.onSubmit();
  }

  validateVideoUrl(): void {
    const videoPathControl = this.notificationForm.get('vediopath');

    if (videoPathControl && videoPathControl.dirty && videoPathControl.invalid) {
      videoPathControl.setErrors({ invalidVideoUrl: true });
    }
  }


  addAttachment(event: any): void {
    try {
      this.ImgValidate = true;
      if (this.ImgValidate === true) {
        this.imageError = '';
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = (_event) => {
          const img = new Image();
          img.src = reader.result as string;

          // Minimum 10KB and Maximum 1MB validation
          const minFileSizeKB = 10;
          const maxFileSizeMB = 1;
          const minFileSizeBytes = minFileSizeKB * 1024;
          const maxFileSizeBytes = maxFileSizeMB * 1024 * 1024;

          if (file.size >= minFileSizeBytes && file.size <= maxFileSizeBytes) {
            const ext = '.png';
            const blob = this.dataURItoBlob(reader.result as string);
            this.blob = blob;
            this.imagePath = event.target.files;
          //  console.log('Image Path:', this.imagePath[0].name.slice(-15));
            this.notificationForm.patchValue({ image: this.imagePath[0].name.slice(-15) });

            let payLoad: any = {
              memberId: sessionStorage.getItem('memberId'),
              organisationImageName: 'business_file',
              fileType: ext,
            };

            this.onSubmit();
          } else {
            this.imageError = 'Image dimensions or size do not meet requirements.'
          }
        };

        if (file) {
          reader.readAsDataURL(file);
        }
      } else {
        this._alertService.error(this.ImgValidate);
      }
    } catch (error) {
      throw error;
    }
  }

  clearImage(): void {
    this.notificationForm.get('image')?.setValue('');
    this.imageError = '';
    this.imagePath = '';
  }

  dataURItoBlob(dataURI: string): Blob {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], { type: mimeString });
  }


  getuser(event) {
    if (this.lstSelectedUsers.length > 0) {
      var result = this.lstSelectedUsers.filter((x: any) => x.userid == event);
      if (result.length > 0) {
        return result[0].userid;
      } else {
        return -2;
      }
    } else {
      return -1;
    }
  }
  dateChange() {
    this.onSubmit();
  }

  changeUser(value: any) {
    this.lstResult = value;
    if (value.length > 0) {
      this.isdropdownshow = false;
      this.isAllUsers = "";
      if (this.isReciverTyp == '1') {
        if (value[value.length - 1].userId == 'ALL') {
          this.isAllUsers = "visible";
        } else {
          var result = value.filter((x: any) => x.userId == 'ALL')
          if (result.length > 0) {
            const secondSelectedValue = this.selectBox.selectedItems;
            this.selectBox.unselect(secondSelectedValue[0]);
          } else {
            this.lstSelectedUsers = [];
            for (var i = 0; i < value.length; i++) {
              this.lstSelectedUsers.push({ userid: value[i].memberId, username: value[i].userId });
            }

          }

        }

      }
      if (this.isReciverTyp == '2') {
        if (value[value.length - 1].subProfessionCategoryName == 'ALL') {
          this.isAllUsers = "visible";
        } else {
          var result = value.filter((x: any) => x.subProfessionCategoryName == 'ALL')
          if (result.length > 0) {
            const secondSelectedValue = this.selectBox.selectedItems;
            this.selectBox.unselect(secondSelectedValue[0]);
          } else {
            this.lstSelectedUsers = [];
            for (var i = 0; i < value.length; i++) {
              this.lstSelectedUsers.push({ userid: value[i].subProfessionsCategoryId, username: value[i].subProfessionCategoryName });
            }

          }

        }

      }
      if (this.isReciverTyp == '3') {
        if (value[value.length - 1].organisationName == 'ALL') {
          this.isAllUsers = "visible";
        } else {
          var result = value.filter((x: any) => x.organisationName == 'ALL')
          if (result.length > 0) {
            const secondSelectedValue = this.selectBox.selectedItems;
            this.selectBox.unselect(secondSelectedValue[0]);
          } else {
            this.lstSelectedUsers = [];
            for (var i = 0; i < value.length; i++) {
              this.lstSelectedUsers.push({ userid: value[i].organisationId, username: value[i].organisationName });
            }

          }

        }

      }


    } else {
      this.lstSelectedUsers = [];
    }
    this.onSubmit();

  }
  pushSelectedUsers(id, username) {

    if (this.lstSelectedUsers.length > 0) {
      var result = this.lstSelectedUsers.filter((x: any) => x.username == username);
      if (result.length == 0) {
        var result2 = this.lstSelectedUsers.filter((x: any) => x.username == 'ALL');
        if (result2.length > 0) {
        } else {
          this.lstSelectedUsers.push({ userid: id, username: username });
        }
      }
    } else {
      this.lstSelectedUsers.push({ userid: id, username: username });
    }

  }
  lstResult: any = [];
  sendAll(event: any) {
    this.isAllUsers = "";
    if (event == 'invisible') {
      const secondSelectedValue = this.selectBox.selectedItems;
      this.selectBox.unselect(secondSelectedValue[secondSelectedValue.length - 1]);

    } else if (event == 'visible') {

      const secondSelectedValue = this.selectBox.selectedItems;
      for (var i = 0; i < secondSelectedValue.length; i++) {
        if (secondSelectedValue[i].label != 'ALL') {
          this.selectBox.unselect(secondSelectedValue[i]);
        }
      }
      this.lstSelectedUsers = [];
      this.lstSelectedUsers.push({ userid: 0, username: 'ALL' });
      this.isAllUsers = 'invisible';
    }
  }

  cancel() {
    this.isAllUsers = "";
    document.getElementById('alluser').click();
    this.notificationForm.patchValue({ userid: -1 });
    this.notificationForm.patchValue({ username: '--Select--' });
  }
  ok() {
    this.isAllUsers = "";
    this.notificationForm.patchValue({ userid: 0 });
    this.notificationForm.patchValue({ username: 'ALL' });
  }
  changeUserAll(value: any) {
    this.isdropdownshow = false;
    this.notificationForm.patchValue({ userid: -1 });
    this.notificationForm.patchValue({ username: '--Select--' });
    this.temprecever = value.username;
    this.lstSelectedUsers = [];
  }
  dropdownshow() {

    if (this.isdropdownshow == false) {
      this.isdropdownshow = true;
      if (this.notificationForm.controls['username'].value == '--Select--') {
        this.notificationForm.patchValue({ username: '' });
      }
      if (this.isReciverTyp == '1') {
        this.lstUsers = this.lsttempusers;
      } else
        if (this.isReciverTyp == '2') {
          this.lstSkills = this.lstTempSkills;
        } else
          if (this.isReciverTyp == '3') {
            this.lstOrganizations = this.lstTempOrganization;
          }
    } else {

      this.isdropdownshow = false;
      this.notificationForm.patchValue({ userid: -1 });
      this.notificationForm.patchValue({ username: '--Select--' });
    }
  }
  notificationTypeshow() {
    if (this.isnotificationTypeshow == false) {
      this.isnotificationTypeshow = true;

    } else {
      this.isnotificationTypeshow = false;
    }
  }

  OnlyLettersAllowed(event): boolean {
    const charCode = (event.which) ? event.which : event.keycode;

    if ((charCode < 97 || charCode > 123) && (charCode < 65 || charCode > 90) && charCode != 32) {
      return false;
    }
    return true
  }

  removeUser(i) {
    this.lstSelectedUsers.splice(i, 1);
    if (this.lstSelectedUsers.length == 0) {
      this.notificationForm.patchValue({ userid: -1 });
      this.notificationForm.patchValue({ username: '--Select--' });
      this.temprecever = '--Select--';
    }
  }

  changeSelection(event: any) {
    this.notificationForm.patchValue({ zipcode: '' });
    this.notificationForm.patchValue({ country: 0 });
    this.notificationForm.patchValue({ state: 0 });
    this.notificationForm.patchValue({ city: 0 });
    this.countryName = '';
    this.stateName = '';
    this.cityName = '';
    this.zipCodeid = 0;
    if (event.target.value != '') {
      this.selectedRadio = event.target.value;
    } else {
      this.selectedRadio = '';
    }
  }

  openModal(): void {
    const modal = this._elementRef.nativeElement.querySelector('#videoPreviewModal');
    this.renderer.addClass(modal, 'show');
    this.renderer.setStyle(modal, 'display', 'block');
    document.body.classList.add('modal-open');
    document.body.classList.add('no-backdrop');
  }


  closeModal(): void {
    this.videoModal = false;
    const modal = this._elementRef.nativeElement.querySelector('#videoPreviewModal');
    this.renderer.setStyle(modal, 'display', 'none');
    this.renderer.removeClass(modal, 'show');
  }

  openVideoPreview() {
    if (this.notificationForm.controls['vediopath'].value != '' && this.notificationForm.controls['vediopath'].status != "INVALID") {
      this.videoModal = true;
      this.openModal();
    }
  }

  handleChangeEditor(event: any) {
    let sanitizeHtml: any;
    sanitizeHtml = this.sanitizer.bypassSecurityTrustHtml(event.html);
    this.messageEvent = sanitizeHtml.changingThisBreaksApplicationSecurity;
    this.characterCount = event.text.length - 1;
   // console.log(this.isNotificationFormValid);

    if (this.characterCount > this.characterLimit) {
   //   console.log(this.isNotificationFormValid);
      this.errorMessage = 'You cannot send the message if the actual text length exceeds 1500 characters or if applied styles (like bold, color, font size) increase its length beyond 1500 characters.';
    } else {
      this.errorMessage = '';
    }
    const unsupportedTags = ['font', 'acronym', 'applet', 'basefont', 'big', 'center', 'dir', 'frame', 'frameset', 'noframes', 'isindex', 'strike', 'tt'];
    for (const tag of unsupportedTags) {
      if (this.messageEvent.includes(`<${tag}`)) {
        this.isMessageValid = true;
        this.isTableMessageValid = true;
        this.errorMessageTag = `Message contains unsupported HTML tag <${tag}>, Please correct the styles or content.`;
        break;
      }
      else {
        this.isMessageValid = false;
        this.isTableMessageValid = false;
        this.errorMessageTag = '';
      }
    }
  }

  private decodeHtmlEntities(html: string): string {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
  }
}