import { Component, OnInit, Inject,ViewEncapsulation } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServerApisService } from 'src/app/shared/services/server-apis.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { filter } from 'rxjs/operators';
import { Role } from 'src/app/shared/enums/role.enum';
import { Constants, navConstants } from 'src/app/shared/constants/constant';
import { RouteMakingService } from 'src/app/shared/services/route-making.service';

@Component({
  selector: 'app-excetion-logs',
  templateUrl: './excetion-logs.component.html',
  styleUrls: ['./excetion-logs.component.css']
})
export class ExcetionLogsComponent implements OnInit {
  lstExceptionlogs: any = [];
  lstTempExceptionlogs: any = [];
  searchInfo: string = "";
  submitted: boolean = false;
  adminPanel: string = Constants.adminPanel;
  exceptionLogs: string = navConstants.exceptionLogs;
  adminPanelRoute: string = Constants.adminPanelRoute;
  exceptionLogsTitle: string = Constants.exceptionLogsTitle;
  p = 1;
  placeholderText = 'Search...';
  isCard: boolean = true;
  selectedErrorMessage: string = '';
  showErrorMessageModal: boolean = false;
  exceptionDateEdit: boolean = false;
  exceptionDateValue: string = '';
  startDateValue: string;
  endDateValue: string;
  maxStartDate: string;
  maxEndDate: string;

  constructor(
    private _apiService: ServerApisService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private routeMakingService: RouteMakingService,
   
    @Inject(Clipboard) private clipboard: Clipboard
  ) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      window.scrollTo(0, 0);
    });
  }

  ngOnInit(): void {
    const user = sessionStorage.getItem('user');
    if (user != null) {
      let data = JSON.parse(user);
      if (data.roleId == Role.Admin) {
        const today = new Date();
        this.endDateValue = this.formatDate(today);
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        this.startDateValue = this.formatDate(yesterday);
        this.setInitialDates();
        this.getExceptionlogs();
      } else {
        this.router.navigate([""]);
      }
    } else {
      this.router.navigate([this.routeMakingService.getNavigateUrl([Constants.Login])]);
    }
  }

  setInitialDates() {
    this.maxStartDate = this.formatDate(new Date());
    this.maxEndDate = this.formatDate(new Date());
  }

  formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }

  startDateChanged() {
    this.maxEndDate = this.startDateValue;
  }

  endDateChanged() {
    this.maxStartDate = this.endDateValue;
  }

  scrollToTop() {
    window.scrollTo(0, 0);
  }

  pageChanged(event) {
    this.p = event;
    this.scrollToTop();
  }

  closeModal() {
    this.showErrorMessageModal = false;
  }
  

  getExceptionlogs() {
    this.spinner.show();
    this._apiService.GetExceptionLogs().subscribe((res: any) => {
      this.spinner.hide();
      if (res.GetExceptionlogResult.length > 0) {
        this.lstExceptionlogs = res['GetExceptionlogResult'].map((log: any) => {
          const utcDate = new Date(log.Date);
          const istDate = new Date(utcDate.getTime() + (5.5 * 60 * 60 * 1000));
          log.Date = istDate.toLocaleString();
          return log;
        });
        this.lstTempExceptionlogs = this.lstExceptionlogs;
        // this.search();
      }
    });
  }

  search(event?: any) {
    this.submitted = true;

    let startDate: Date | null = this.startDateValue ? new Date(this.startDateValue) : null;
    let endDate: Date | null = this.endDateValue ? new Date(this.endDateValue + 'T23:59:59') : null;

    if (!startDate && !endDate) {
      const today = new Date();
      const yesterday = new Date(today);
      yesterday.setDate(today.getDate() - 1);
      startDate = yesterday;
      endDate = today;
      this.startDateValue = this.formatDate(startDate);
      this.endDateValue = this.formatDate(endDate);
    }

    let filteredLogs = this.lstTempExceptionlogs.filter((log: any) => {
      const logDate = new Date(log.Date);
      return (!startDate || logDate >= startDate) && (!endDate || logDate <= endDate);
    });

    if (this.searchInfo.trim() !== "") {
      const escapedSearchInfo = this.escapeRegExp(this.searchInfo);
      const regex = new RegExp(escapedSearchInfo, 'i');
      filteredLogs = filteredLogs.filter((x: any) => regex.test(x.ErrorMessage));
    }
  
    this.lstExceptionlogs = filteredLogs;
  }

  escapeRegExp(string: string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }

  OnlyLettersAllowed(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode < 97 || charCode > 123) && (charCode < 65 || charCode > 90)) {
      return false;
    }
    return true;
  }

  truncateErrorMessage(message: string, maxLength: number): string {
    if (message.length > maxLength) {
      return message.slice(0, maxLength) + '...';
    }
    return message;
  }

  showErrorMessagePopup(errorMessage: string) {
    this.selectedErrorMessage = errorMessage;
    this.showErrorMessageModal = true;
  }

  copyErrorMessageToClipboard() {
   // console.log('Copying to clipboard:', this.selectedErrorMessage);

    if (this.clipboard) {
      this.clipboard.copy(this.selectedErrorMessage);
    //  console.log('Copied successfully!');
    } else {
      console.error('Clipboard not available.');
    }
  }
}
