import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CallLogsRoutingModule } from './call-logs-routing.module';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    CallLogsRoutingModule
  ]
})
export class CallLogsModule { }
