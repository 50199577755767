import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
declare var google;

export class LatLng {

  lat: string;
  lng: string;
}
@Injectable({
  providedIn: 'root'
})
export class GoogleApisService {
  protected GoogleApiKey = 'AIzaSyA_VDafK_hW4l5i4TfENskMzxsCxVesUJc';
  originArray: LatLng[];
  destinaitonArray: LatLng[];
  origin$ = new BehaviorSubject(null);
  originLatLng$ = this.origin$.asObservable();
  constructor(private http: HttpClient) { }


  // Google Services
  //Google Reverse Gecoding API (From URL to User Readable address)
  getCurrentGeoAddress(data) {

    let key = this.GoogleApiKey;

    return this.http.get<any>('https://maps.googleapis.com/maps/api/geocode/json?latlng=' + data.latlng + '&key=' + key)
  }

  //Google Geocoding API (from user readable address to geo coordinates like latlng )
  getSearchAddressGeoCoordinates(data) {
    let key = this.GoogleApiKey;

    return this.http.get<any>('https://maps.googleapis.com/maps/api/geocode/json?address=' + data + '&key=' + key)
  }

  //Google Geolocation API
  getCurrentGeoLatLng() {
    let key = this.GoogleApiKey;
    
    return this.http.post<any>('https://www.googleapis.com/geolocation/v1/geolocate?key=' + key, null)
  }



  // public getDistance() {
  //   const destination:any = {};
  //   const origin:any = {}
  //   origin['lat']= 40.6655101
  //   origin['lng']=-73.89188969999998
  //   destination['lat']= 40.659569
  //   destination['lng']=-73.933783
  //    const matrix = new google.maps.DistanceMatrixService();
  //    return new Promise((resolve, reject)=>{
  //      matrix.getDistanceMatrix({
  //      origins: [new google.maps.LatLng(origin.lat, origin.lng)],
  //      destinations: [new google.maps.LatLng(destination.lat,destination.lng)],
  //      travelMode: google.maps.TravelMode.DRIVING,
  //      }, function(response, status) {
  //      
  //        if(status === 'OK'){
  //          resolve(response)
  //        }else{
  //          reject(response);
  //        }
  //      });
  //    })
  //  }


  getDistance(origin, destination): Observable<any> {

    var destinations = [];
   
    var origins = [];
    var org=origin.lat+','+ origin.lng
    origins.push(new google.maps.LatLng(origin.lat, origin.lng))
    destination.forEach(element => {
      destinations.push(new google.maps.LatLng(element.lat, element.lng))
    });
    var count:number=destinations.length;
    let tempdestination:any=[];
    let tempdestination1:any=[];
    for(var i=0;i<count;i++)
    {
      tempdestination.push({"id":1})
    }
    for(var i=0;i<count;i++)
    {
      tempdestination1.push({"id":1})
    }
    
    var result: any = [];
    var num = 25;
    var tempnum = 0;
    const matrix = new google.maps.DistanceMatrixService();
    return  new  Observable(observer => {
      console.log(google.maps.TravelMode.DRIVING);

      var d1 = Math.ceil(destinations.length / 25);
      var temprecords:any=[];
      
      for(var i=1;i<=d1;i++)
      {
        temprecords.push({'id':i});
      }
      
      if (destinations.length < 25) {
        
         matrix.getDistanceMatrix({
          origins: origins,
          destinations: destinations,
          travelMode: google.maps.TravelMode.DRIVING,
        }, function (response, status) {
          
          if (status === 'OK') {
            return observer.next(response)
          } else {
            
            return observer.error("NO DATA FOUND")
          }
        })
      } else {
        
        var d2 :any
        var originaldata:any;
        let destinationAddresses:any=[];
        let originAddresses:any=[];
        let rows:any=[];
        var respcount=1;
        console.log(origins);
        temprecords.forEach( (element) => {
          
          var j=element.id;
          d2=[];
                num = (num) * j;
                if (num <= destination.length) {
                } else {
                  num = destination.length;
                }
                var dest:string="";
                var temp1=tempnum;
                for(var i=tempnum;i<num && i<=destination.length;i++)
                {
                   d2.push(destinations[i]);
                }
                tempnum = num;
                 matrix.getDistanceMatrix({
              origins: origins,
              destinations: d2,
              travelMode: google.maps.TravelMode.DRIVING,
            }, function (response:any, status:any) {
              
              destinationAddresses=[];
              rows=[];
              
              if (status === 'OK') {
                for(var i=0;i<response.destinationAddresses.length;i++)
                {
                  destinationAddresses.push(response.destinationAddresses[i]);
                  
                }
                
                var ee=temp1+25
                if(ee>count){
                  ee= temp1+count
                }
                let j=0;
                for(var i=temp1;i<ee;i++)
                {
                  
                  tempdestination[i]=destinationAddresses[j];
                  j=j+1
                }
                
                for(var i=0;i<response.rows[0].elements.length;i++)
                {
                  rows.push(response.rows[0].elements[i]);
                }
                var ee1=temp1+25
                if(ee1>count){
                  ee1= temp1+count
                }
                let k=0;
                for(var i=temp1;i<ee;i++)
                {
                  
                  tempdestination1[i]=rows[k];
                  k=k+1
                }
               
                originAddresses=response.originAddresses;
                if(d1==respcount)
                {
                    var finalResult=new Object();
                    finalResult={'destinationAddresses':tempdestination,'originAddresses':originAddresses,'rows':tempdestination1}
                  return observer.next(finalResult);
                  
                }
                respcount=respcount+1;
              } else {
                
                return observer.error("NO DATA FOUND")
              }
            })
          
          
        });
          
         }
        if (result.length > 0) {
          return result;
        }
      

    });



  }
  getIpAddress() {
    return this.http.get('https://api.ipify.org/?format=json')

  }

  getGEOLocation(ip) {
    let url = "https://api.ipgeolocation.io/ipgeo?apiKey=9ba81e2cf8d34fcc8cfe9ea9e31a88f7&ip=" + ip + "&fields=geo";
    return this.http.get(url)

  }
  originLatLng(ol: any) {

    this.origin$.next(ol);

  }
}
