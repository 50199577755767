import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/shared/alert/alert.service';
import { ServerApisService } from 'src/app/shared/services/server-apis.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Role } from 'src/app/shared/enums/role.enum';
import { Constants,navConstants } from 'src/app/shared/constants/constant';
import { RouteMakingService } from 'src/app/shared/services/route-making.service';

@Component({
  selector: 'app-post-advertisement-advertisement',
  templateUrl: './post-advertisement-advertisement.component.html',
  styleUrls: ['./post-advertisement-advertisement.component.css']
})
export class PostAdvertisementAdvertisementComponent {
  page: string;
  constructor(private router: Router,private formBuilder: FormBuilder,private _apiService:ServerApisService,private spinner: NgxSpinnerService,
    private _alertService: AlertService,private datePipe: DatePipe,private routeMakingService: RouteMakingService) { }
    @ViewChild('selectBox') selectBox: NgSelectComponent;
    advertisementForm: FormGroup;
    lstSelectedCategories:any=[];
    minDate :any;
    submitted = false;
    extn: any;
    p = 1;
   blob: any;
    descriptionsort:string="";
     placeholderText: string = 'Search with Title, Description';
     filestream: any;
     profleimge:any;
     lstadvertisements:any=[];
     lsttempadvertisements:any=[];
     lstUsers:any=[];
     isupdate:boolean=false;
     lsttempusers:any=[];
     ImgValidate: any;
     public imagePath;
     imageChangedEvent: any = '';
     selectedFile: File | null;
    ngOnInit(): void {
      const urlRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
      var date= new Date();
      date=new Date(date.setDate(date.getDate() + 1));
      this.minDate=this.datePipe.transform(date,"yyyy-MM-dd")
      const user = sessionStorage.getItem('user');
      if (user != null) {
        let data=JSON.parse(user);
        if (data.roleId === Role.Admin || data.roleId === Role.SubAdmin || data.roleId === Role.SalesTeam || data.roleId === Role.Manager) {
          this.advertisementForm = this.formBuilder.group({
          title: ['', Validators.required],
          description: ['', Validators.required],
          date:['', Validators.required],
          image:['', Validators.required],
          vediopath:['',[Validators.pattern(urlRegex)]],
          screen:["Dashboard", Validators.required],
          category:["Top", Validators.required],
          price:[""],
          redirectlink:[''],
          phone:[""],
          email:[""],
          clientType:['InternalClient'],
          clientNavigation :['Profile'],
          navigatingToMemberId:["0"],
          selectedUser:[]
        });
       this.getusers();
        let path=window.location.href.split('advertisement/')
       }else{
        this.router.navigate([""]);
      }
        
      }else{
        this.router.navigate([this.routeMakingService.getNavigateUrl([Constants.Login])]);
      } 
    }
  getusers() {
      this.spinner.show();
      this._apiService.GetAllUsers().subscribe(res => {
        
        this.spinner.hide();
       
        this.lstUsers = res['GetAllUsersDropDownValuesResult'].UserDropDownValues;
        this.lsttempusers = res['GetAllUsersDropDownValuesResult'].UserDropDownValues;
      });
    }
    screenChange(event:any){
      if(event.target.value=='Profile'){
        this.advertisementForm.patchValue({category: 'Bottom'});
      }else{
        this.advertisementForm.patchValue({category: 'Top'});
      }
    }
    searchCategorychange(event){
  
    }
    dropdownshow(){
      
    }
    back(){
      this.page="advertisement";
    }
    Cancel(){
      this.advertisementForm.reset();
          this.advertisementForm.patchValue({screen: ''});
          this.advertisementForm.patchValue({category: ''});
          this.advertisementForm.patchValue({image: ''});
          this.advertisementForm.patchValue({clientType: 'InternalClient'});
          this.advertisementForm.patchValue({clientNavigation: 'Profile'});
          this.advertisementForm.patchValue({navigatingToMemberId:'0'});
          this.selectBox.clearModel();
          this.isupdate=false;
          this.page="advertisement";
    }
    update(){
      this.submitted = true;
      
      if(this.advertisementForm.controls['clientType'].value=='InternalClient' && this.advertisementForm.controls['clientNavigation'].value =='Profile'
      && this.advertisementForm.controls['navigatingToMemberId'].value =='0'){
  
      }else{
        if(this.advertisementForm.valid){
        
          const payload:any={};
          const userdetails = sessionStorage.getItem('user');
          const userdata = JSON.parse(userdetails);
          console.log(this.updateProfile);
          payload.addsId=this.updateProfile.addsId;
          payload.addsPostedMemberId=this.updateProfile.addsPostedMemberId;
          payload.imagePath=this.updateProfile.imagePath;
          payload.postedDate=this.updateProfile.postedDate;
          payload.slideShow=this.updateProfile.slideShow;
          payload.addsPostedMemberId=sessionStorage.getItem('memberId');
          payload.fname=userdata.fname;
          payload.title=this.advertisementForm.controls['title'].value;
          payload.description=this.advertisementForm.controls['description'].value;
          payload.category=this.advertisementForm.controls['category'].value;
          payload.screen=this.advertisementForm.controls['screen'].value;
          payload.price=this.advertisementForm.controls['price'].value;
          payload.imageName=this.advertisementForm.controls['image'].value;
          payload.videoURL=this.advertisementForm.controls['vediopath'].value;
          payload.expiryDate=this.advertisementForm.controls['date'].value;
          payload.contactEmail=this.advertisementForm.controls['email'].value;
          payload.contactPhone=this.advertisementForm.controls['phone'].value;
          payload.redirectLink=this.advertisementForm.controls['redirectlink'].value;
          payload.clientType=this.advertisementForm.controls['clientType'].value;
          payload.clientNavigation=this.advertisementForm.controls['clientNavigation'].value;
          payload.navigatingToMemberId=(+this.advertisementForm.controls['navigatingToMemberId'].value);
          var date=new Date();
          var min:any=date.getMinutes();
          if(min.toString().length==1){
    
            min='0'+min;
          }
          var hrs:any=date.getHours();
          if(hrs.toString().length==1){
    
            hrs='0'+hrs;
          }
        var time=hrs +':' + min;
          payload.expiryDate=payload.expiryDate+'T'+time+'Z';
          
          if(payload.price==null || payload.price==undefined || payload.price==''){
            payload.price=0;
          }
          // this.spinner.show();
          if(this.advertisementForm.controls['clientType'].value=='InternalClient' && this.advertisementForm.controls['clientNavigation'].value=='Profile'){
            this.advertisementForm.patchValue({redirectlink:''});
            payload.redirectLink='';
          }
          
        this._apiService.udpateAdvertisements(payload, this.blob).subscribe(res=>{
          window.location.reload();
          this.spinner.hide();
          
          this.submitted =false;
          if(res['result']=='Advertisment is updated successfully !'){
            window.scrollTo(0, 0);
          this._alertService.success(res['result']);
          this.page="advertisement";
          this.advertisementForm.reset();
          this.advertisementForm.patchValue({screen: ''});
          this.advertisementForm.patchValue({category: ''});
          this.advertisementForm.patchValue({image: ''});
          this.advertisementForm.patchValue({clientType: 'InternalClient'});
          this.advertisementForm.patchValue({clientNavigation: 'Profile'});
          this.advertisementForm.patchValue({navigatingToMemberId:'0'});
          this.selectBox.clearModel();
          
          }else{
            this._alertService.warn(res['result']);
          }
    
        });
    
    
        }
      }
    }
    onSubmit() {
      
      this.submitted = true;
      if(this.advertisementForm.controls['clientType'].value=='InternalClient' && 
      this.advertisementForm.controls['clientNavigation'].value =='Profile'
      && this.advertisementForm.controls['navigatingToMemberId'].value =='0'){
  
      }else{
        if(this.advertisementForm.valid){
        
          const payload:any={};
          const userdetails = sessionStorage.getItem('user');
          const userdata = JSON.parse(userdetails);
          payload.addsPostedMemberId=sessionStorage.getItem('memberId');
          payload.fname=userdata.fname;
          payload.title=this.advertisementForm.controls['title'].value;
          payload.description=this.advertisementForm.controls['description'].value;
          payload.category=this.advertisementForm.controls['category'].value;
          payload.screen=this.advertisementForm.controls['screen'].value;
          payload.price=this.advertisementForm.controls['price'].value;
          payload.imageName=this.advertisementForm.controls['image'].value;
          payload.videoURL=this.advertisementForm.controls['vediopath'].value;
          payload.expiryDate=this.advertisementForm.controls['date'].value;
          payload.contactEmail=this.advertisementForm.controls['email'].value;
          payload.contactPhone=this.advertisementForm.controls['phone'].value;
          payload.redirectLink=this.advertisementForm.controls['redirectlink'].value;
          payload.clientType=this.advertisementForm.controls['clientType'].value;
          payload.clientNavigation=this.advertisementForm.controls['clientNavigation'].value;
          payload.navigatingToMemberId=(+this.advertisementForm.controls['navigatingToMemberId'].value);
          var date=new Date();
          var min:any=date.getMinutes();
          if(min.toString().length==1){
    
            min='0'+min;
          }
          var hrs:any=date.getHours();
          if(hrs.toString().length==1){
    
            hrs='0'+hrs;
          }
        var time=hrs +':' + min;
          payload.expiryDate=payload.expiryDate+'T'+time+'Z';
          
          if(payload.price==null || payload.price==undefined || payload.price==''){
            payload.price=0;
          }
          this.spinner.show();
          if(this.advertisementForm.controls['clientType'].value=='InternalClient' && this.advertisementForm.controls['clientNavigation'].value=='Profile'){
            this.advertisementForm.patchValue({redirectlink:''});
            payload.redirectLink='';
          }
          
        this._apiService.sendAdvertisements(payload, this.blob).subscribe(res=>{
          this.spinner.hide();
          
          this.submitted =false;
          if(res['result']=='Adds Posted Successfully.'){
            window.scrollTo(0, 0);
          this._alertService.success('Advertisment Posted Successfully.');
          this.advertisementForm.reset();
          this.advertisementForm.patchValue({screen: ''});
          this.advertisementForm.patchValue({category: ''});
          this.advertisementForm.patchValue({image: ''});
          this.advertisementForm.patchValue({clientType: 'InternalClient'});
          this.advertisementForm.patchValue({clientNavigation: 'Profile'});
          this.advertisementForm.patchValue({navigatingToMemberId:'0'});
          this.selectBox.clearModel();
          this.page="advertisement";
         
          }else{
            this._alertService.warn(res['result']);
          }
    
        });
    
    
        }
      }
}
    get f() { return this.advertisementForm.controls; }
    addAttachment(event: any): void {
  
      try {
        this.ImgValidate = true;
        if (this.ImgValidate === true) {
          this.imageChangedEvent = event;
          for (let i = 0; i < event.target.files.length; i++) {
            this.selectedFile = event.target.files[i];
            var ext ='.png';
            var reader = new FileReader();
            this.imagePath = event.target.files;
            
            this.advertisementForm.patchValue({image: this.imagePath[0].name}); 
            
            reader.readAsDataURL(event.target.files[i]);
  
            reader.onload = (_event) => {
              
              var img:any = reader.result;
              fetch(img)
                .then(res => res.blob())
                .then(blob => {
                  const fd = new FormData();
                  fd.append('image', blob, 'filename');
                  this.extn = ext;
                  this.blob = blob;
                  // this.userInfo.imagePath = this.imgURL;
                  // this.image=this.imgURL;
                  // this.imgURL = '';
                  let payLoad:any={
                    memberId:sessionStorage.getItem('memberId'),
                    organisationImageName:'bussiness_file',
                    fileType:this.extn,
  
                  }
                  
                });
            }
          }
        } else {
          this._alertService.error(this.ImgValidate)
        }
      } catch (error) {
        throw error;
      }
  
    }
  
    OnlyNumbersAllowed(event): boolean {
  
      const charCode = (event.which) ? event.which : event.keycode;
  
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
      }
      return true
    }
    clientTypeChange(event:any){
    if(event.target.value!='' && event.target.value!=null && event.target.value!=undefined){
        this.advertisementForm.patchValue({clientType: event.target.value}); 
        if(event.target.value=='ExternalClient'){
          this.advertisementForm.patchValue({clientNavigation: ''});
          this.advertisementForm.patchValue({navigatingToMemberId: '0'});
          
        }else{
          this.advertisementForm.patchValue({clientNavigation: 'Profile'});
          this.selectBox.clearModel();
        }
      }
    }
    clientNavigationChange(event:any){
      if(event.target.value!='' && event.target.value!=null && event.target.value!=undefined){
        this.advertisementForm.patchValue({clientNavigation: event.target.value}); 
        this.advertisementForm.patchValue({navigatingToMemberId: '0'});
        if(event.target.value=='Profile'){
          this.advertisementForm.patchValue({redirectlink:''});
        }
      }
    }
    changeuser(event:any){
      if(event!=undefined){
        this.advertisementForm.patchValue({navigatingToMemberId: event.memberId});
      }
      else{
        this.advertisementForm.patchValue({navigatingToMemberId: '0'});
      }
    }
   updateProfile:any;
    selectedUsers:any=[];
  }
  