<div class="tasks_table1">
  <div class="row">
    <div class="col-md-10 search-box">
      <label style="width: 100%;">
        <span>
          <input type="text" id="search" class="custom-focus-input" autocomplete="off" [(ngModel)]="searchtext"
            [placeholder]="placeholderText" style="height: 36px; border: none; width: 120.5%;"
            (input)="searchMainCategory($event)">
        </span>
      </label>
    </div>

  </div>
</div>
<div>
  <table class="table table-striped">
    <thead class="tablehd-sec">
      <tr>
        <th style="width: 10%;">MemberId</th>
        <th style="width: 10%;">UniqueId</th>
        <th style="width: 16%;" (click)="memberSort()">Member Name&nbsp;<span><i class="fa fa-long-arrow-up"
              style="margin: 0;" [ngClass]="membersort == 'up' ? 'black' : 'grey'"></i>
            <i class="fa fa-long-arrow-down" style="margin: 0;"
              [ngClass]="membersort == 'down' ? 'black' : 'grey'"></i></span></th>
        <th style="width: 13%;" (click)="mobileonSort()">Mobile No&nbsp;<span><i class="fa fa-long-arrow-up"
              style="margin: 0;" [ngClass]="mobilesort == 'up' ? 'black' : 'grey'"></i>
            <i class="fa fa-long-arrow-down" style="margin: 0;"
              [ngClass]="mobilesort == 'down' ? 'black' : 'grey'"></i></span></th>
        <th style="width: 15%;" (click)="serviceonSort()">Old Service &nbsp;<span><i class="fa fa-long-arrow-up"
              style="margin: 0;" [ngClass]="servicesort == 'up' ? 'black' : 'grey'"></i>
            <i class="fa fa-long-arrow-down" style="margin: 0;"
              [ngClass]="servicesort == 'down' ? 'black' : 'grey'"></i></span></th>
        <th style="width: 21%;" (click)="newserviceonSort()">New Service &nbsp;<span><i class="fa fa-long-arrow-up"
              style="margin: 0;" [ngClass]="newservicesort == 'up' ? 'black' : 'grey'"></i>
            <i class="fa fa-long-arrow-down" style="margin: 0;"
              [ngClass]="newservicesort == 'down' ? 'black' : 'grey'"></i></span></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let d of lstCategories| paginate: { itemsPerPage: 20, currentPage: p}; let i = index;">
        <td style="width: 10%;">{{d.memberId}}</td>
        <td style="width: 10%;">{{d.userUniqueCode}}</td>
        <td style="width: 16%;">{{d.member_name}}</td>
        <td style="width: 13%;">{{d.mobile| slice:0:10}}</td>
        <td style="width: 15%;padding: 4px 6px 4px 0px;"> {{d.service}}</td>
        <td style="width: 17%;padding: 4px 6px 4px 0px;" (click)="edit(d,'service')">
          <div class="row profession-popup" *ngIf="id==d.serviceId && isPopup">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel" style="margin-left: 33%;">Confirm</h5>
              <button type="button" id="dismissModal" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                (click)="closepopup()"></button>
            </div>
            <div class="modal-body" style="text-align: center;">
              <p>Are you sure you want to update service </p>
              <p>from <b>{{oldservice}}</b> to <b>{{newservice}} ?</b></p>
            </div>
            <div class="modal-footer">
              <button type="button" (click)="closepopup()" class="btn btn-danger footer-button ml-auto"
                data-bs-dismiss="modal">Cancel</button>
              <button type="button" class="btn btn-primary footer-button mr-auto" (click)="update()">ok</button>
            </div>
          </div>
          <div class="dropdown">
            <input type="text" class="form-control  dropbtn inpt dropdown-toggle" name="phone"
              [(ngModel)]="d.categoryName" (click)="getindex(d,i)" autocomplete="off" style="border: 1px solid #898888;"
              (input)="searchCategorychange($event,d)" />
            <ul class="dropdownshow form-select" *ngIf="id==d.serviceId && isdisplay">
              <li *ngFor="let f of lstallcategories" (click)="catergorychange(f,d)" class="list-group-item"
                data-bs-toggle="tooltip" data-bs-placement="top" title="Tooltip on top">
                {{f.subProfessionCategoryName}}</li>
            </ul>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  <div class="row">
    <div class="col">
      <div class="d-flex justify-content-center">
        <pagination-controls (pageChange)="pageChanged($event)" (pageBoundsCorrection)="pageChanged($event)">
        </pagination-controls>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel" style="margin-left: auto;">Confirm</h5>
        <button type="button" id="dismissModal" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
          (click)="closepopup()"></button>
      </div>
      <div class="modal-body" style="text-align: center;">
        <p>Are you sure you want to update service </p>
        <p>from <b>{{oldservice}}</b> to <b>{{newservice}}</b></p>
      </div>
      <div class="modal-footer">
        <button type="button" (click)="closepopup()" class="btn btn-danger footer-button ml-auto"
          data-bs-dismiss="modal">Cancel</button>
        <button type="button" class="btn btn-primary footer-button mr-auto" (click)="update()">ok</button>
      </div>
    </div>
  </div>
</div>