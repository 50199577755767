<div class="tasks_table1">
    <div class="row">
      <div class="col-md-10 search-box">
        <label style="width: 100%;"><span><input type="text" id="search"
              class="custom-focus-input" autocomplete="off" [placeholder]="placeholderText"
              style="height: 36px;border: none;width: 115%;"
              (input)="searchMainCategory($event)"></span></label>
      </div>
      <div class="col-md-2" style="padding: 8px;text-align: center;">
        <button class="btn-primary hand add" (click)="addPop()"
            onclick="document.getElementById('addModal').style.display='block'" title="Add Main Profession">
            +
        </button>
      </div>
    </div>
  </div>
  <div>
    <table class="table table-striped">
      <thead class="tablehd-sec">
        <tr>
          <th style="width: 20%;" class="padleft-30">S. NO &nbsp;</th>
          <th style="width: 20%;" (click)="mainProfessionOnSort('profession')">
            Main Profession
            &nbsp;<span>
              <i class="fa fa-long-arrow-up" style="margin: 0;"
                [ngClass]="mainProfessionSort === 'up' ? 'black' : 'grey'"></i>
              <i class="fa fa-long-arrow-down" style="margin: 0;"
                [ngClass]="mainProfessionSort === 'down' ? 'black' : 'grey'"></i>
            </span>
          </th>
          <th style="width: 35%;">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let d of mainProfessions | paginate: { itemsPerPage: 10, currentPage: p};let i = index"
          class="tdata">
          <td style="width: 20%;padding-left: 2%;" class="padleft-30">{{ (p - 1) * 10 + i + 1 }}</td>
          <td style="width: 45%;">
            <p>{{d.mainProfessionName}}</p>
          </td>
          <td style="width: 35%;">
            <i class="fa fa-pencil thclr hand bl" (click)="updateMainProfessionPop(d)"
              style="margin-right: 10px;"
              onclick="document.getElementById('updateModal').style.display='block'"></i>
            <i class="fa fa-trash-o hand dangr" style="font-size: 20px; margin-right: 10px;"
              (click)="deleteMainProfessionPop(d)"
              onclick="document.getElementById('deleteModal').style.display='block'"></i>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="row">
      <div class="col">
        <div class="d-flex justify-content-center">
          <pagination-controls (pageChange)="pageChanged($event)" (pageBoundsCorrection)="pageChanged($event)">
          </pagination-controls>
        </div>
      </div>
    </div>
  </div>
  <div class="modal" id="addModal" style="overflow: hidden;padding-top: 50px;">
    <div class="modal-content" style="width: 30%;">
      <div class="modal-header" style="padding-top: 0;">
        <h4 class="modal-title text-center" id="myModalLabel">
         Add Main Profession</h4>
        <span onclick="document.getElementById('addModal').style.display='none'" class="close hand"
          title="Close Modal">×</span>
      </div>
      <div class="modal-body" style="padding-bottom: 0;">
        <div class="row" style="margin-bottom:15px;">
          <label style="padding: 0;">Main Profession<span style="color: red;">*</span></label>
          <input type="text" class="form-control" [(ngModel)]="professionName" placeholder="Enter Main Profession">
          <label style="color: red;padding: 0;"></label>
        </div>
        <div class="row mrt-20" style="display: flex;">
          <button class="save hand save-btn" (click)="saveProfession()">Submit</button>
          <button class="btn btn-cancel"
            onclick="document.getElementById('addModal').style.display='none'">Cancel</button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal" id="updateModal" style="overflow: hidden;padding-top: 50px;">
    <div class="modal-content" style="width: 30%;">
      <div class="modal-header" style="padding-top: 0;">
        <h4 class="modal-title2 text-center"  id="myModalLabel">
          Edit Main Profession</h4>
        <span onclick="document.getElementById('updateModal').style.display='none'" class="close hand"
          title="Close Modal">×</span>
      </div>
      <div class="modal-body" style="padding-bottom: 0;">
        <div class="row" style="margin-bottom:15px;">
          <label style="padding: 0;">Main Profession<span style="color: red;">*</span></label>
          <input type="text" class="form-control input-readonly" [(ngModel)]="professionName"
            placeholder="Enter Main Profession">
          <label style="color: red;padding: 0;"></label>
        </div>
        <div class="row" style="margin-bottom:15px;">
          <label style="padding: 0;">Edit Main Profession<span style="color: red;">*</span></label>
          <input type="text" class="form-control " [(ngModel)]="professionNameNew" placeholder="Edit Main Profession">
          <label style="color: red;padding: 0;"></label>
        </div>
        <div class="row mrt-20" style="display: flex;">
          <button *ngIf="modal == 'update'" class="save hand save-btn" (click)="updateProfession()"
            onclick="document.getElementById('updateModal').style.display='none'">Update</button>
          <button class="btn btn-cancel"
            onclick="document.getElementById('updateModal').style.display='none'">Cancel</button>
        </div>
      </div>
    </div>
  </div>
  <div id="deleteModal" class="modal" style="overflow: hidden;">
    <form class="modal-content" action="/action_page.php" style="width: 35%;">
      <div class="container">
        <div class="modal-header" style="padding-top: 0;">
          <h5 class="modal-title" id="exampleModalLabel" style="margin-left: auto;">Confirm</h5>
          <button type="button" id="dismissModal" onclick="document.getElementById('deleteModal').style.display='none'"
            class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div style="padding-top: 15px; padding-bottom: 15px; text-align: center;">
          <p>Are you sure, you want to delete </p>
          <p> <strong>{{selectedMainProfession}}</strong> Profession?</p>
        </div>
        <div class="modal-footer" style="padding-bottom: 0;">
          <button type="button" onclick="document.getElementById('deleteModal').style.display='none'"
            class="btn btn-danger footer-button ml-auto" data-bs-dismiss="modal"
            style="width: 18%;margin-left: auto;">Cancel</button>
          <button type="button" class="btn btn-primary footer-button mr-auto"
            (click)="deleteMainProfession(selectedMainProfessionId)" style="width: 18%;margin-right: auto;"
            onclick="document.getElementById('deleteModal').style.display='none'">submit</button>
        </div>
      </div>
    </form>
  </div>