import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild,ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgSelectComponent } from '@ng-select/ng-select';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServerApisService } from 'src/app/shared/services/server-apis.service';
import { Role } from 'src/app/shared/enums/role.enum';
import { Constants,navConstants } from 'src/app/shared/constants/constant';
import { DomSanitizer, SafeResourceUrl,SafeUrl } from '@angular/platform-browser';
import { RouteMakingService } from 'src/app/shared/services/route-making.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-activity-data-reports',
  templateUrl: './activity-data-reports.component.html',
  styleUrls: ['./activity-data-reports.component.css']
})
export class ActivityDataReportsComponent {
  p = 1;
  isCard: boolean = true;
  page: string = '';
  lstActivities: any = [];
  lstTempActivities: any = [];
  isEditIndex:number;

  constructor(private router: Router, private formBuilder: FormBuilder,
    private _apiService: ServerApisService, private spinner: NgxSpinnerService,private cdr: ChangeDetectorRef, private datePipe: DatePipe, private sanitizer: DomSanitizer,private routeMakingService: RouteMakingService) {
  }

ngOnInit(): void {
  const user = sessionStorage.getItem('user');
   if (user != null) {
     let data = JSON.parse(user);
     if (data.roleId === Role.Admin || data.roleId === Role.CallCenter) {
      this.getActivities();
    } else {
       this.router.navigate([""]);
     }
   } else {
     let params=[Constants.Login]
     let routingUrl= this.routeMakingService.getNavigateUrl(params)
     this.router.navigate([routingUrl]);
   }

   }
   searchChange(event: any) {
    if (event.target.value != '' && event.target.value != undefined) {
      this.lstActivities = this.lstTempActivities.filter((x: any) =>
        x.uniqueId.toLowerCase().includes(event.target.value.toLowerCase()) ||
        x.Name.toLowerCase().includes(event.target.value.toLowerCase()) ||
        x.profession.toLowerCase().includes(event.target.value.toLowerCase()) ||
        x.Activity_Date.toLowerCase().includes(event.target.value.toLowerCase()) ||
        x.Mobile_No.toLowerCase().includes(event.target.value.toLowerCase()) ||
        x.childProfession.toLowerCase().includes(event.target.value.toLowerCase())
      )
    } else
      this.lstActivities = this.lstTempActivities;
  }
  getActivities() {
    this.spinner.show();
    this._apiService.GetActivities().subscribe(res => {
      this.spinner.hide();
      if (res['TrackActivityLogsResult'].status == 200) {
        this.lstActivities = res['TrackActivityLogsResult'].ActivityLogs.map(activity => {
         
          const utcDate = new Date(activity.Activity_Date);
          const istDate = new Date(utcDate.getTime() + (5.5 * 60 * 60 * 1000));
          activity.Activity_Date = istDate.toLocaleString();
          return activity;
        });
        this.lstTempActivities = this.lstActivities;
        this.scrollToTop();
      }
    });
  }
  
  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
}
pageChanged(event: any) {
  this.p = event;
  this.isEditIndex=-1;
  }
  menu() {
    if (this.isCard) {
      this.isCard = false;
    } else {
      this.isCard = true;
    }
  }
}
