import { Injectable } from '@angular/core';

declare global {
  interface Window { dataLayer: any[]; }
}

@Injectable({
  providedIn: 'root'
})
export class GoogleTagManagerService {

  constructor() { }

  pushEvent(event: string, eventData: any) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': event,
      ...eventData
    });
  }
}
