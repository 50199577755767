
<div class="profile-content">
    <div class="container-connecting-dots">
        <div class="profile-section profile-wrap">
          <div class="under-con">
                <div class="row">
                    <div class="col-xs-12 col-md-5">
                        <div>
                            <h2 class="title-constrictions text-center">This page is under construction</h2>
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-7">
                        <div>
                            <img src="assets/images/Under-constractions.jpg" class="img-constrictions" alt="Under-constractions" title="under constractions">
                        </div>
                    </div>
                </div>
          </div>
        </div>
    </div>
</div>