import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  currentyear:number;
  constructor(private router: Router) {
    this.currentyear=new Date().getFullYear()
   }
   API_URL:string="https://blogs.toskie.com/"
   FAQ_URL:string="https://blogs.toskie.com/how-to-get-leads-toskie-faq/"
  ngOnInit(): void {
    window.scrollTo(0, 0);
    let path = window.location.href;
   
  }
  goToLink(url: string){
    window.open(url, "_blank");
}

navigate(url: string){
  this.router.navigate([url]).then(result=>{
    window.location.reload();
  });
}
}
