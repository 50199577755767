import { Role } from '../enums/role.enum';

export const RolePages: { [key in Role]: { pagename: string }[] } = {
  [Role.User]: [],
  [Role.Toskie]: [],
  [Role.Admin]: [
    { 'pagename': 'Activities' },
    { 'pagename': 'Exception Logs' },
    { 'pagename': 'Notification' },
    { 'pagename': 'Reports' },
    { 'pagename': 'Professions' },
    { 'pagename': 'Advertisement' },
    { 'pagename': 'Account Verification' },
    { 'pagename': 'User Management' },
    { 'pagename': 'Vendor Management' },
    { 'pagename': 'Sitemap' },
    { 'pagename': 'Near By Service Management' },
    { 'pagename': 'Admin Approval' }
  ],
  [Role.SubAdmin]: [
    { 'pagename': 'Notification' },
    { 'pagename': 'Advertisement' },
    { 'pagename': 'Professions' },
    { 'pagename': 'User Management' },
    { 'pagename': 'Vendor Management' },
    { 'pagename': 'Near By Service Management' },
    { 'pagename': 'Sitemap' }
  ],
  [Role.MarketingDM]: [
    { 'pagename': 'Notification' }
  ],
  [Role.SalesTeam]: [
    { 'pagename': 'Advertisement' },
    { 'pagename': 'Account Verification' }
  ],
  [Role.Manager]: [
    { 'pagename': 'Notification' },
    { 'pagename': 'Advertisement' },
    { 'pagename': 'Account Verification' }
  ],
  [Role.CallCenter]: [
    { 'pagename': 'Reports' }
  ],
  [Role.SeoTeam]: [
    { 'pagename': 'Professions' },
    { 'pagename': 'Sitemap' }
  ]
};