import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UnderConstructionRoutingModule } from './under-construction-routing.module';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    UnderConstructionRoutingModule
  ]
})
export class UnderConstructionModule { }
