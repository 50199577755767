import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/shared/alert/alert.service';
import { ServerApisService } from 'src/app/shared/services/server-apis.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Role } from 'src/app/shared/enums/role.enum';
import { Constants,navConstants } from 'src/app/shared/constants/constant';
import { RouteMakingService } from 'src/app/shared/services/route-making.service';

@Component({
  selector: 'app-vendor-assignment-vendor-management',
  templateUrl: './vendor-assignment-vendor-management.component.html',
  styleUrls: ['./vendor-assignment-vendor-management.component.css']
})
export class VendorAssignmentVendorManagementComponent {
  vendorForm: FormGroup;
  @ViewChild('selectBox') selectBox: NgSelectComponent;
  @ViewChild('selectBoxs') selectBoxs:NgSelectComponent;
  isEditIndex: number;
  lstVendors: any = [];
  lstTempVendors: any = [];
  lstalluserVendors:any=[];
  memberIdsort:string='';
  UniqueIdsort:string="";
  mobilesort:string="";
  emailsort:string="";
  vendorsort:string="";
  Namesort:string="";
  storeRole:string='';
  vendorCode: string = '';
  isdropdownshow:boolean=false;
  p = 1;
  submitted:boolean=false;
  id: number = 0;
  isdisplay: boolean = false
  isassign:boolean=false;
  previd: number = 0; 
  lstassignVendors:any=[];
  vendorName: string = '';
  filterRole:string = '';
  isSearchChange: boolean = false;
  oldservice: string = "";
  page: string = 'vendorcreation';
 lstalluserTempVendors: any;
  lsttempassignVendors: any;
  selectedIndex:number;
  placeholderroleText: string;
  placeholderText: string ='Search with Member Id, Unique Id and Vendor Name';

  constructor(private spinner: NgxSpinnerService,private routeMakingService: RouteMakingService, private _apiService: ServerApisService, private router: Router,
    private _alertService: AlertService,private formBuilder: FormBuilder) { }


    ngOnInit(): void {
      const user = sessionStorage.getItem('user');
      if (user != null) {
        let data = JSON.parse(user);
        if (data.roleId == Role.Admin || data.roleId == Role.SubAdmin) {
          this.getVendorsAssigners();
          this.getVendors();
        let path=window.location.href.split('vendor-management/')
         } else {
          this.router.navigate([""]);
        }
  
      } else {
        this.router.navigate([this.routeMakingService.getNavigateUrl([Constants.Login])]);
      }
      this.vendorForm = this.formBuilder.group({
        selectedUser:[]
      });
    }
    getuser(event){
      if(this.lstVendors.length>0)
      {
        var result=this.lstVendors.filter((x:any)=>x.vendorName==event);
        if(result.length>0){
         return result[0].vendorName;
        }else{
         return -2;
        }
      }else{
        return -1;
      }
      
    }
   
    restoreRole(){
      
      this.isdropdownshow=false;
      this.filterRole=this.storeRole;
    }
    searchChangeRole(){

    }

    editrole(i){
      this.isEditIndex=i;
    }
    catergorychange(val,d) {
    
      let payload:any={};
      payload.memberId=d.memberId
      payload.vendorId=val
      this.spinner.show();
      
      this._apiService.updateVendorAssigning(payload).subscribe(res => {
        this.spinner.hide();
        this.isEditIndex=-1;
        this.isdisplay=false;
        this.isassign=false;
     this._alertService.success(res['result']);
});
    }
    getindex(d: any, i: number) {
      this.lstVendors = this.lstTempVendors;
      this.selectedoldRole=d.vendorName;
      if (this.previd == d.roleId) {
        this.isdisplay = false;
       
        this.id = d.memberId;
        this.previd = 0;
        if (this.isSearchChange == true) {
          this.lstalluserVendors[this.selectedIndex].vendorName=this.oldservice;
        }
      }
      else {
        
        this.isdisplay = true;
        this.id = d.memberId;
        this.oldservice= d.vendorName;
        let index = this.lstalluserVendors.findIndex(x => x.memberId === this.id);
        this.selectedIndex=index;
        this.lstalluserVendors[this.selectedIndex].vendorName=this.oldservice;
  
      }
    }
    vendorchange(){
    
      this.catergorychange(this.selectednewVendorId,this.changeroleassign)
    }
    
    selectedchangeRole(event,d){
    
      this.isassign=true;
      this.selectednewRole=event.vendorName;
      this.selectednewVendorId=event.vendorId;
      this.selectednewnumber=event.maskingNumbersId;
      this.changeroleassign=d;
      
    }
    
  dropdownshow(){
    
      this.storeRole=this.filterRole;
      if(this.isdropdownshow==false){
        this.isdropdownshow=true;
        if(this.filterRole=='--Select Vendor--')
        {
          this.filterRole=''; 
        }
      }else{
        
      this.isdropdownshow=false;
      this.filterRole='--Select Vendor--'; 
      }
    }
    searchCategorychange(event,val) {

      if (event.target.value != "" && event.target.value != null && event.target.value != undefined) {
        this.isdisplay = true;
        this.isSearchChange = true;
        this.lstVendors = this.lstTempVendors;
        var result = this.lstVendors.filter((x: any) =>  x.mobile.toLowerCase().includes(event.target.value.toLowerCase()) ||
          x.vendorName.toLowerCase().includes(event.target.value.toLowerCase()));
        this.lstVendors = result;
      } else {
        this.isSearchChange = true;
        this.lstVendors = this.lstTempVendors;
      }
    }
    restorePlaceholder() {
      if(this.page=='vendorassignment'){
        this.placeholderText="Search with Member Id, Unique Id and Vendor Name";
      }
     
    }

    searchroleasgnChange(event: any) {
      if (event.target.value != '' && event.target.value != undefined) {
        if (this.filterRole != '--Select Vendor--') {
          var preResult = this.lstalluserTempVendors.filter((x: any) =>  
            x.vendorName.toLowerCase().includes(this.filterRole.toLowerCase()));
        } else {
          var preResult = this.lstalluserTempVendors;
        }
    
        var result = preResult.filter((x: any) => x.memberId.toString().toLowerCase().includes(event.target.value.toLowerCase())
          || x.Name.toLowerCase().includes(event.target.value.toLowerCase())
          ||  x.mobile.toLowerCase().includes(event.target.value.toLowerCase())
          ||  x.email.toLowerCase().includes(event.target.value.toLowerCase())
          ||  x.vendorName.toLowerCase().includes(event.target.value.toLowerCase())
          || x.UniqueId.toLowerCase().includes(event.target.value.toLowerCase()));
        this.lstalluserVendors = result;
      } else {
        if (this.filterRole != '--Select Vendor--') {
          var preResult = this.lstalluserTempVendors.filter((x: any) => x.vendorName.toLowerCase().includes(this.filterRole.toLowerCase()));
        } else {
          var preResult = this.lstalluserTempVendors;
        }
        this.lstalluserVendors = preResult;
      }
      this.restorePlaceholder();
    }
    roleCancel(){
      this.isdisplay = false;
    }
    changeroleassign:any;
    changeNumberassign:any;
    selectednewVendorId:any;
    selectednewnumber:string="";
    selectedoldRole:string="";
    selectednewRole:string="";
    filterRoleassignSearch:string='';

    getVendors() {
      this.spinner.show();
      this.lstVendors = [];
      this._apiService.GetVendors().subscribe(res => {
        this.spinner.hide();
        this.lstVendors = res.FetchAllVendorsResult['resultAllVendors'];
        this.lstTempVendors = this.lstVendors;
       this.lstassignVendors=this.lstVendors;
        this.lsttempassignVendors=this.lstassignVendors
      });
    }
    deleteServices(){
      let payload:any={};
      payload.memberId=this.selectedmemberId;
      payload.CategoryId=(+this.selectedDelteId);
      this.spinner.show();
      
      this._apiService.DeleteServiceCategory(payload).subscribe(res => {
        
        this.spinner.hide();
        this.isdeleteservice=false;
        if(res.resultModel.status==200){
            this._alertService.success(res.resultModel.result);
          }
       
      });
      
    }
    removeOption(event:any,d){
    
      if(event.target.value){
        
        let result=d.splitServices.filter((x:any)=>x.name==event.target.value);
        
        this.isdeleteservice=true;
        this.selectedDelteId=result[0].id;
        this.SelectedTest=result[0].name;
        this.selectedmemberId=d.memberId;
      }
  
    }

   changeUser(value:any){
    
      this.isEditIndex=-1;
      this.isdropdownshow=false;
      this.filterRoleassignSearch='';
      
      if(value==undefined){
         this.lstalluserVendors=this.lstalluserTempVendors;
       }else{
         this.filterRole=value.vendorName;
         var result=this.lstalluserTempVendors.filter((x:any)=>x.vendorName==this.filterRole);
         this.lstalluserVendors=result;
       }
  }
    pageChanged(event: any) {
      this.p = event;
      this.isEditIndex=-1;
      this.getVendors();
     
    }
  
 closeRoleDropdown(event:any){
      // 
      // if(this.isdropdownshow==false){
      //   this.dropdownshow();
      // }
      
    }
    isdeleteservice:boolean=false;
  selectedDelteId:any;
  SelectedTest:any;
  selectedmemberId:any;
    getVendorsAssigners(){
      this.spinner.show();
      this.lstalluserVendors = [];
      this._apiService.GetVendorAssigners().subscribe(res => {
        
        this.spinner.hide();
        this.lstalluserVendors = res.FetchAllMembersByVendorResult['resultFetchAllMembersVendor'];
        for (var i = 0; i < this.lstalluserVendors.length; i++) {
          if (this.lstalluserVendors[i].serviceIds != '0' && this.lstalluserVendors[i].serviceNames != "") {
            const serviceIdsArray = this.lstalluserVendors[i].serviceIds.split(',');
            const serviceNamesArray = this.lstalluserVendors[i].serviceNames.split(',');
            this.lstalluserVendors[i].splitServices = [];
            for (let j = 0; j < serviceIdsArray.length; j++) {
              this.lstalluserVendors[i].splitServices.push({
                id: serviceIdsArray[j],
                name: serviceNamesArray[j]
              });
            }
          }else{
            this.lstalluserVendors[i].splitServices = [];
          }
        }
        this.lstalluserTempVendors = this.lstalluserVendors;
      });
    }
    memberIdOnsort(){
      if (this.memberIdsort == "") {
        this.memberIdsort = "up";
        this.lstalluserVendors.sort((a, b) => (b.memberId > a.memberId) ? -1 : 1);
  
      } else if (this.memberIdsort == "up") {
        this.memberIdsort = "down";
        this.lstalluserVendors.sort((a, b) => (a.memberId > b.memberId) ? -1 : 1);
  
      } else if (this.memberIdsort == "down") {
        this.memberIdsort = "";
        this.lstalluserVendors.sort((a, b) => a.memberId - b.memberId);
      }
    }
    nameOnsort(){
      if (this.Namesort == "") {
        this.Namesort = "up";
        this.lstalluserVendors.sort((a, b) => (b.Name > a.Name) ? -1 : 1);
  
      } else if (this.Namesort == "up") {
        this.Namesort = "down";
        this.lstalluserVendors.sort((a, b) => (a.Name > b.Name) ? -1 : 1);
  
      } else if (this.Namesort == "down") {
        this.Namesort = "";
        this.lstalluserVendors.sort((a, b) => a.memberId - b.memberId);
      }
    }
    UniqueIdOnsort(){
      if (this.UniqueIdsort == "") {
        this.UniqueIdsort = "up";
        this.lstalluserVendors.sort((a, b) => (b.UniqueId > a.UniqueId) ? -1 : 1);
  
      } else if (this.UniqueIdsort == "up") {
        this.UniqueIdsort = "down";
        this.lstalluserVendors.sort((a, b) => (a.UniqueId > b.UniqueId) ? -1 : 1);
  
      } else if (this.UniqueIdsort == "down") {
        this.UniqueIdsort = "";
        this.lstalluserVendors.sort((a, b) => a.memberId - b.memberId);
      }
    }
  
    mobileOnsort(){
      if (this.mobilesort == "") {
        this.mobilesort = "up";
        this.lstalluserVendors.sort((a, b) => (b.mobile > a.mobile) ? -1 : 1);
  
      } else if (this.mobilesort == "up") {
        this.mobilesort = "down";
        this.lstalluserVendors.sort((a, b) => (a.mobile > b.mobile) ? -1 : 1);
  
      } else if (this.mobilesort == "down") {
        this.mobilesort = "";
        this.lstalluserVendors.sort((a, b) => a.memberId - b.memberId);
      }
    }
  
    emailOnsort(){
      if (this.emailsort == "") {
        this.emailsort = "up";
        this.lstalluserVendors.sort((a, b) => (b.email > a.email) ? -1 : 1);
  
      } else if (this.emailsort == "up") {
        this.emailsort = "down";
        this.lstalluserVendors.sort((a, b) => (a.email > b.email) ? -1 : 1);
  
      } else if (this.emailsort == "down") {
        this.emailsort = "";
        this.lstalluserVendors.sort((a, b) => a.memberId - b.memberId);
      }
    }
    vendorOnsort(){
      if (this.vendorsort == "") {
        this.vendorsort = "up";
        this.lstalluserVendors.sort((a, b) => (b.vendorName > a.vendorName) ? -1 : 1);
  
      } else if (this.vendorsort == "up") {
        this.vendorsort = "down";
        this.lstalluserVendors.sort((a, b) => (a.vendorName > b.vendorName) ? -1 : 1);
  
      } else if (this.vendorsort == "down") {
        this.vendorsort = "";
        this.lstalluserVendors.sort((a, b) => a.memberId - b.memberId);
      }
    } 

}
