<div class="profile-content" style="padding-top: 3%;">
    <div class="container-connecting-dots">
      <div class="profile-section">
        <div class="row insection">
           <h6 class="head">
            <span style="font-size: 15px; float: left;cursor: pointer;" *ngIf="isupdate" (click)="back()">
              <i class="fa fa-arrow-left" aria-hidden="true"></i>&nbsp; Back</span>
             <span style="font-size: 15px; float: right;cursor: pointer; color: red;" *ngIf="isupdate" (click)="Cancel()">
                Cancel  &nbsp;<i class="fa fa-close" aria-hidden="true"></i></span> </h6>

          <form class="login-form pad-form" [formGroup]="advertisementForm">
            <div class="row form-group  ">
              <div class="col-md-6">
                <label class="bld">Client Type</label>
                <select class="form-control form-select width-100 inpt" formControlName="clientType"
                  (change)="clientTypeChange($event)">
                  <option value="InternalClient">Internal Client</option>
                  <option value="ExternalClient">External Client</option>
                </select>
              </div>
              <div class="col-md-6" *ngIf="advertisementForm.controls.clientType.value=='InternalClient'">
                <label class="bld">Client Navigation </label>
                <select class="form-control form-select width-100 inpt" formControlName="clientNavigation"
                  (change)="clientNavigationChange($event)">
                  <option value="Profile">Profile</option>
                  <option value="Website">Website</option>
                </select>
              </div>
            </div>
            <div class="form-group mt-2">
              <label class="bld">Title <span style="color: red;">*</span></label>
              <input type="text" class="form-control inpt" formControlName="title" maxlength="100"
                style="font-weight: bold;" [ngClass]="{ 'is-invalid': submitted && f.title.errors }">

              <div *ngIf="(submitted && advertisementForm.controls.title.errors)
                    ||(advertisementForm.controls.title.errors && advertisementForm.controls.title.touched)"
                class="text-danger">
                <div *ngIf="advertisementForm.controls.title.errors.required">Title is required</div>
              </div>
            </div>
            <div class="form-group mt-2">
              <label class="bld">Description <span style="color: red;">*</span></label>
              <textarea type="textarea" class="form-control inpt" formControlName="description" 
                style="font-weight: bold;height: 49px;"
                [ngClass]="{ 'is-invalid': submitted && f.description.errors }"></textarea>

              <div
                *ngIf="(submitted && advertisementForm.controls.description.errors)
                    ||(advertisementForm.controls.description.errors && advertisementForm.controls.description.touched)"
                class="text-danger">
                <div *ngIf="advertisementForm.controls.description.errors.required">Description is required</div>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-4 mt-2" *ngIf="advertisementForm.controls.clientType.value=='InternalClient' && 
                advertisementForm.controls.clientNavigation.value=='Profile'">
                <label class="bld">Sender<span style="color: red;">*</span></label>
                <ng-select [items]="lstUsers" bindLabel="userId" placeholder="Select Sender" #selectBox formControlName="selectedUser"
                  (change)="changeuser($event)">
                </ng-select>

                <select class="form-control form-select width-100 inpt" formControlName="navigatingToMemberId"
                    (change)="changeuser($event)" [ngClass]="{ 'is-invalid': submitted && advertisementForm.controls.navigatingToMemberId.value=='0'}">
                    <option value="0">Choose Sender</option>
                    <option *ngFor="let st of lstUsers" [value]="st.memberId" class="cursor">
                      {{ st.userId }}
                    </option>
                  </select> 
               <ng-select [items]="cities"
                     bindLabel="name"
                     placeholder="Select city"
                     [(ngModel)]="selectedCity">
                  </ng-select> 
                 <div
                  *ngIf="(submitted && f.navigatingToMemberId.value=='0' && f.clientType.value=='InternalClient' && 
                  f.clientNavigation.value=='Profile' ) ||(f.navigatingToMemberId.value=='0' && f.navigatingToMemberId.touched)"
                  class="text-danger">
                  <div>Please Select Sender</div>
                </div>
              </div>
              <div class="form-group col-4 mt-2">
                <label class="bld">Screen<span style="color: red;">*</span></label>
                <select class="form-control form-select width-100 inpt" formControlName="screen"
                  (change)="screenChange($event)" [ngClass]="{ 'is-invalid': submitted && f.screen.errors }">
                  
                  <option value="Dashboard">Dashboard</option>
                  <option value="Profile">Profile</option>
                </select>
                <div *ngIf="(submitted && advertisementForm.controls.screen.errors)
                    ||(advertisementForm.controls.screen.errors && advertisementForm.controls.screen.touched)"
                  class="text-danger">
                  <div>Please Select screen</div>
                </div>
              </div>
              <div class="form-group col-4 mt-2" *ngIf="f.screen.value=='Dashboard'">
                <label class="bld">Category <span style="color: red;">*</span></label>
                <select class="form-control form-select width-100 inpt" formControlName="category"
                  [ngClass]="{ 'is-invalid': submitted && f.category.errors }">
                  
                  <option value="Top" >Top</option>
                  <option value="Bottom">Bottom</option>
                </select>
                <div *ngIf="(submitted && f.category.errors) || (f.category.errors && f.touched)"
                  class="text-danger">
                  <div>Please Select category</div>
                </div>
              </div>
              <div class="form-group col-4 mt-2" *ngIf="f.screen.value!='Dashboard'">
                <label class="bld">Category <span style="color: red;">*</span></label>
                <select class="form-control form-select width-100 inpt" formControlName="category"
                  [ngClass]="{ 'is-invalid': submitted && f.category.errors }">
                  
                   <option value="Top" >Top</option> 
                  <option value="Bottom">Bottom</option>
                </select>
                <div *ngIf="(submitted && f.category.errors) || (f.category.errors && f.touched)"
                  class="text-danger">
                  <div>Please Select category</div>
                </div>
              </div>
              <div class="form-group col-4 mt-2">
                <label class="bld">Image Upload <span style="color: red;">*</span></label>
                <div class="right-inner-addon input-container">
                  <i class="fa fa-cloud-upload imgupld" (click)="fileUpload.click()"><input #fileUpload
                      style="display: none;" type="file" accept=".jpeg,.jpg,.png,.gif,.tiff" id="upload-file"
                      name="upload-file" accept="image/png, image/gif, image/jpeg"
                      (change)="addAttachment($event)"></i>
                  <input type="text" class="form-control inpt mb-0" aria-describedby="basic-addon2"
                    (click)="fileUpload.click()" formControlName="image" readonly
                    [ngClass]="{ 'is-invalid': submitted && f.image.errors }"
                    style="border-style: dashed !important;background-color: #fff;">
                  <label class="mb-1">Accepted file types jpeg (or jpg), png, gif, tiff</label>

                  <div *ngIf="submitted && f.image.errors " class="invalid-feedback text-danger">
                    <div *ngIf="f.image.errors?.required">Image is required</div>
                  </div>
                </div>
              </div>
              <div class="form-group col-4 mt-2">
                <label class="bld">Video Url Upload</label>
                <div class="right-inner-addon input-container">
                  <i class="fa fa-play-circle vdupld"></i>
                  <input type="url" class="form-control inpt mb-0" aria-describedby="basic-addon2"
                    formControlName="vediopath" [ngClass]="{ 'is-invalid': submitted && f.vediopath.errors }">
                  <label class="mb-1">Accepted file types mp4, mov, avi, mkv, webm, ogg</label>
                  <div *ngIf="submitted && f.vediopath.errors " class="invalid-feedback">
                    <div *ngIf="f.vediopath.errors?.pattern">Please provide valid url</div>
                  </div>
                </div>
              </div>
              <div class="form-group col-4 mt-2">
                <label class="bld">Expiry Date <span style="color: red;">*</span></label>
                <input type="date" class="form-control inpt" formControlName="date" placeholder="mm-dd-yyyy"
                  onkeydown="return false" min="{{minDate}}"
                  [ngClass]="{ 'is-invalid': submitted && f.date.errors }">
                <div *ngIf="submitted && f.date.errors" class="invalid-feedback text-danger">
                  <div *ngIf="f.date.errors.required">Date is required</div>
                </div>
              </div>
              <div class="form-group col-4 mt-2">
                <label class="bld">Price</label>
                <input type="text" class="form-control inpt" formControlName="price" maxlength="50"
                  (keypress)="OnlyNumbersAllowed($event)" style="font-weight: bold;">
              </div>

              <div class="form-group col-4 mt-2">
                <label class="bld">Contact Email</label>
                <input type="text" class="form-control inpt" formControlName="email" maxlength="50"
                  style="font-weight: bold;">
              </div>
              <div class="form-group col-4 mt-2">
                <label class="bld">Contact phone</label>
                <input type="text" class="form-control inpt" formControlName="phone" maxlength="10"
                  (keypress)="OnlyNumbersAllowed($event)" style="font-weight: bold;">
              </div>

              <div class="form-group col-4 mt-2"
                *ngIf="(advertisementForm.controls.clientType.value=='InternalClient' && 
                advertisementForm.controls.clientNavigation.value=='Website')|| (advertisementForm.controls.clientType.value=='ExternalClient')">
                <label class="bld">Redirect Link</label>
                <input type="text" class="form-control inpt" formControlName="redirectlink"
                  style="font-weight: bold;">
              </div>
            </div>


            <div class="row pt-7">
              <button type="submit" *ngIf="!isupdate" class="btn btn-primary text-center mt-2 sendbtn"
                (click)="onSubmit()">Send</button>
                <button type="submit" *ngIf="isupdate" class="btn btn-primary text-center mt-2 sendbtn"
                (click)="update()">Update</button>
                
            </div>

          </form>
        </div>
      </div>
    </div>
  </div>