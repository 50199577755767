import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { SearchService } from 'src/app/shared/services/search.service';
import { ServerApisService } from 'src/app/shared/services/server-apis.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AlertService } from 'src/app/shared/alert/alert.service';
import { Constants } from '../../shared/constants/constant';
import { StaticRoutesService } from 'src/app/shared/services/static-routes.service';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.css']
})
export class PopupComponent implements OnInit{


  showPopup = false;
  formData = new FormGroup({
    name: new FormControl('', [
      Validators.required,
      Validators.minLength(2)]),
    mobileNo: new FormControl('', [
      Validators.required,
      Validators.pattern('^[0-9]{10}$')
    ]),
    // email: new FormControl('', [
    //   Validators.required,
    //   Validators.email]),
    service: new FormControl('', [Validators.required]),
    platform: new FormControl('Website'),
    location: new FormControl(sessionStorage.getItem('exactLocation') ? sessionStorage.getItem('exactLocation') : sessionStorage.getItem('location')),
  });
  country_name = 'India';
  country_code = '+91';
  allStaticRoutes = this.staticRoutes.getStaticRoutes();
  isStaticRoute: boolean;


  constructor(
    private searchService:SearchService,
    private _api:ServerApisService,
    private _alertService:AlertService,
    private staticRoutes: StaticRoutesService
  ) { }
 
  ngOnInit() {
    this.searchService.getShowPopupSubject().subscribe(() => {
      this.show();
    });
    this.searchService.getClosePopupSubject().subscribe(() => {
      this.hide();
    });
  }

  countryChange(name){
    this.country_name = name;
    this.country_code = name === 'India' ? '+91' : '+1';
  }

  clickButton(){
    const button = document.getElementById('popupModal') as HTMLButtonElement;
    if (button) {
      if(this.showPopup == false && sessionStorage.getItem('loggedIn') !== 'true') {
        button.click();
      }
    } else {
      console.error('Button with ID popupModal not found');
    }
  }

  closeButton(){
    const button = document.getElementById('closePopup') as HTMLButtonElement;
    if (button) {
      button.click();
      this.formData.reset();
      this.showPopup = false;
    } else {
      console.error('Button with ID closePopup not found');
    }
  }

  show() {
    let searchData = sessionStorage.getItem('searchData') ? JSON.parse(sessionStorage.getItem('searchData')) : null;
    if(searchData){
      this.formData.controls['service'].setValue(searchData.filterName);
    }
    const popupShownBefore = sessionStorage.getItem('homepagePopShown');
    //const searchPopupShown = sessionStorage.getItem('searchPopupShown');
    const isStaticRoute = this.allStaticRoutes.some(route => window.location.pathname.includes(route));
    if(sessionStorage.getItem('loggedIn') === 'true'){
      this.showPopup = false;
    } else {
      if (!popupShownBefore && window.location.pathname === '/') {
        this.clickButton();
        this.showPopup = true;
        sessionStorage.setItem('homepagePopShown', 'true');
      } else if(!isStaticRoute){
        this.clickButton();
        this.showPopup = true;
        sessionStorage.setItem('searchPopupShown', 'true');
      }
    }
  }

  hide() {
    this.showPopup = false;
  }

  submitForm() {
    if (this.formData.valid) {
      this.formData.value['location'] = sessionStorage.getItem('exactLocation') ? sessionStorage.getItem('exactLocation') : sessionStorage.getItem('location');
      this.formData.value['mobileNo'] = this.country_code + this.formData.value['mobileNo'];
      let formValue = this.formData.value;
      this.showPopup = false;
      this.closeButton();
      this._alertService.success('Thank you for your interest. We will get back to you soon.')
      this._api.InsertActivityLogWithoutLogIn(formValue).subscribe((res) => {
        this.formData.reset();
      }, (err) => {
        //this._alertService.error('Something went wrong. Please try again later.');
      });
    }
  }
}
