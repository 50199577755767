<div class="popup-container">
  <div class="main-container" [ngClass]="{ 'otp-screen': otpsent }">
    <div class="header">
      <h2>Mobile</h2>
      <span class="close" (click)="onClose()">&times;</span>
    </div>
    <div class="content">
      <div class="image-container">
        <img src="/assets/images/updatenumber.png" alt="Update Mobile" />
      </div>
      <div *ngIf="!otpsent">
        <div class="form-group">
          <div class="country-select-container">
            <label class="title-coun">Country*</label>
            <div class="dropdown">
              <button class="Rounded-Rectangle-738 btn btn-primary dropdown-toggle country-select" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                <div class="dropdown-item-content">
                  <span class="country-code" *ngIf="country !== ''">{{ country == 'India' ? '+91' : '+1' }}</span>
                  <span class="country-name" *ngIf="country !== ''">{{ country }}</span>
                  <span class="country-name" *ngIf="country == ''">Select...</span>
                  <img *ngIf="country === 'India'" src="../../../assets/images/layer-9-copy.png" alt="India" title="India">
                  <img *ngIf="country === 'United States'" src="../../../assets/images/USA.png" alt="USA" title="USA">
                  <img *ngIf="country === 'Canada'" src="../../../assets/images/canada.png" alt="Canada" title="Canada">
                </div>
              </button>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <li class="dropdown-item" (click)="countrychange('')">
                    <div class="dropdown-item-content">
                      <span class="country-name">Select...</span>
                    </div>
                </li>
                <li class="dropdown-item" (click)="countrychange('India')">
                    <div class="dropdown-item-content">
                      <span class="country-code">+91</span>
                      <span class="country-name">India</span>
                      <img src="../../../assets/images/layer-9-copy.png" alt="India" style="position: relative; right: 125px; width: 28px; top: 2px;" title="India">
                    </div>
                </li>
                <li>
                  <a class="dropdown-item" (click)="countrychange('Canada')">
                    <div class="dropdown-item-content">
                      <span class="country-code">+1</span>
                      <span class="country-name">Canada</span>
                      <img src="../../../assets/images/canada.png" alt="Canada" style="position: relative; right: 112px; width: 28px; top: 2px;" title="Canada">
                    </div>
                  </a>
                </li>
                <li class="dropdown-item" (click)="countrychange('United States')">
                    <div class="dropdown-item-content">
                      <span class="country-code">+1</span>
                      <span class="country-name">United States</span>
                      <img src="../../../assets/images/USA.png" alt="USA" style="position: relative; right: 73px; width: 28px; top: 2px;" title="USA">
                    </div>
                </li>
              </ul>
            </div>
          </div>
          <label class="title-mob">Mobile*</label>
          <div class="mobile-input">
            <input type="text" matInput [(ngModel)]="mobileNumber" maxlength="10" placeholder="Enter Mobile Number" (input)="onMobileInputChange($event)" />
          </div>
          <span *ngIf="errorMsg != '' && !otpsent" style="color: red; position: relative; bottom: 39px; right: 109px; font-size: 12px;">{{errorMsg}}</span>
        </div>
      </div>
      <div *ngIf="otpsent">
        <div class="form-group">
          <div class="verification-message">
            Please enter the verification code sent to your mobile number {{ country == 'India' ? '+91' : '+1' }} {{ mobileNumber }}
          </div>
          <div class="otp-input">
            <input type="text" maxlength="1" [(ngModel)]="otp[0]" (input)="focusNext($event, 0)" (keydown)="focusNext($event, 0)" />
            <input type="text" maxlength="1" [(ngModel)]="otp[1]" (input)="focusNext($event, 1)" (keydown)="focusNext($event, 1)" />
            <input type="text" maxlength="1" [(ngModel)]="otp[2]" (input)="focusNext($event, 2)" (keydown)="focusNext($event, 2)" />
            <input type="text" maxlength="1" [(ngModel)]="otp[3]" (input)="focusNext($event, 3)" (keydown)="focusNext($event, 3)" />
            <input type="text" maxlength="1" [(ngModel)]="otp[4]" (input)="focusNext($event, 4)" (keydown)="focusNext($event, 4)" />
            <input type="text" maxlength="1" [(ngModel)]="otp[5]" (input)="focusNext($event, 5)" (keydown)="focusNext($event, 5)" />
          </div>
          <span *ngIf="errorMsg != '' && otpsent" style="color: red; position:relative; bottom: 2px; left:9px; font-size: 12px;">{{errorMsg}}</span>
          <div class="timer" *ngIf="!timerValid">
            <span>Time remaining: {{ num1 }} seconds</span>
          </div>
          <div class="resend" *ngIf="timerValid">
            <button (click)="resendOTP()">Resend OTP</button>
          </div>
        </div>
      </div>
      <div id="recaptchaContainer"></div>
      <div class="actions">
        <button mat-raised-button class="submit-button" *ngIf="otpsent" [disabled]="!isOtpValid" (click)="onSubmitOtp()">Submit</button>
      </div>
      <div class="actions1">
        <button mat-raised-button class="submit-button" *ngIf="!otpsent" [disabled]="notValid" (click)="onRequestOtp()">Request OTP</button>
      </div>
    </div>
  </div>
</div>
