<div class="whole-section">
  <div class="" style="background: #fff;" (click)="removeDropdown($event)">
    <div class="whole-section">
      <nav aria-label="breadcrumb" class="mlft-6">
        <app-bread-crumb [breadTitle]="professionsTitle" [breadpath1]="adminPanel" [breadpath2]="professions"
          [breadpath3]="professionsNav" [breadroute1]="adminPanelRoute"></app-bread-crumb>
      </nav>
      <div class="row">
        <div class="col-3">
        </div>
      </div>
      <div class="table-responsive" style="padding: 1%;">
        <div class="row" style="background-color: #fff;">
          <div class="col-md-2 left-section" *ngIf="isCard"(mouseleave)="onMouseLeave()"
          style="z-index: 900;position: absolute;top: 0px;">
            <div class="row">
              <div class="col-md-12 ng-star-inserted" *ngIf="isCard">
                <div class="Rectangle-562">
                  </div>
                </div>
                <div class="hand" (click)="searchtab('assignService')"
                  [ngClass]="{'Rectangle-563-copy': page != 'assignService', 'Rectangle-563-selected active' : page == 'assignService'}">
                  <p class="mr-0">Assign Profession<span style="float: right;"></span></p>
                </div>
                <div class="hand" (click)="searchtab('serviceCreation')"
                  [ngClass]="{'Rectangle-563-copy': page != 'serviceCreation', 'Rectangle-563-selected active' : page == 'serviceCreation'}">
                  <p class="mr-0">Main Profession<span style="float: right;"></span></p>
                </div>
                <div class="hand" (click)="searchtab('childProfession')"
                  [ngClass]="{'Rectangle-563-copy': page != 'childProfession', 'Rectangle-563-selected active' : page == 'childProfession'}">
                  <p class="mr-0">Child Profession<span style="float: right;"></span></p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-1 leftminisec" (mouseenter)="onMouseEnter()">
            <div style="text-align: center; margin-bottom: 10px;">
                <!-- Content goes here -->
            </div>
        </div>
          <div class="content-section"  [ngClass]="{'col-10': isCard, 'col-11' : !isCard}">
            <ng-container *ngIf="page === 'assignService'">
                <table class="common-table">
                  <app-assign-profession-professions></app-assign-profession-professions>
              </table>
            </ng-container>
            <ng-container *ngIf="page === 'serviceCreation'">
                <table class="common-table">
                 <app-main-profession-professions></app-main-profession-professions>
            </table>
            </ng-container>
           <ng-container *ngIf="page === 'childProfession'">
                <table class="common-table">
                  <app-child-profession-professions></app-child-profession-professions>
            </table>
            </ng-container>

            </div>
</div>
      </div>
    </div>
  </div>