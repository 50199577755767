import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServerApisService } from 'src/app/shared/services/server-apis.service';
import { Role } from 'src/app/shared/enums/role.enum';
import { Constants, navConstants } from 'src/app/shared/constants/constant';
import { RouteMakingService } from 'src/app/shared/services/route-making.service';
import { AlertService } from 'src/app/shared/alert/alert.service';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NgxPaginationModule } from 'ngx-pagination';

@Component({
  selector: 'app-activities-without-login-activities',
  templateUrl: './activities-without-login-activities.component.html',
  styleUrls: ['./activities-without-login-activities.component.css']
})
export class ActivitiesWithoutLoginActivitiesComponent {
  isCard = true;
  lstactivityLogList: any = [];
  tempActivityLogList: any;
  count: number;
  placeholderText: string;
  searchedName: string;
  searchedMobileNo: string;
  searchedActivityDescription: string;
  selectedActivitiesOption: string = 'All';
  updateMessage: string;
  activityLogWithoutLogInId: any;
  selectedActivitiesWithoutLogOption: string = '';
  searchedEmail: string;
  searchedAll: string;
  searchInfo: string = "";
  submitted: boolean = false;
  p = 1;
  lstactivityLogListApproved: any = [];
  tempActivityLogListApproved: any;
  selectedActivitiesApprovedOption: string = 'AllApproved';
  searchedAllApproved: string;
  searchedNameApproved: string;
  searchedMobileNoApproved: string;
  searchedActivityDescriptionApproved: string;
  pendingCount: any;
  lstActivities: any = [];
  lstTempActivities: any = [];

  constructor(
    private _apiService: ServerApisService,
    private serviceApi: ServerApisService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private routeMakingService: RouteMakingService,
    private NgxPaginationModule: NgxPaginationModule,
    protected alertService: AlertService,
    protected matSlideToggleModule: MatSlideToggleModule,
    private cdr: ChangeDetectorRef
  ) { }


  ngOnInit(): void {
    const user = sessionStorage.getItem('user');
    if (user != null) {
      let data = JSON.parse(user);
      if (data.roleId === Role.Admin) {
        let path = window.location.href.split('activities/')
        this.getActivityLogWithoutLogin(this.p);
        this.getActivityLogWithoutLoginApproved(this.p);
      } else {
        this.router.navigate([""]);
      }
    } else {
      this.router.navigate([this.routeMakingService.getNavigateUrl([Constants.Login])]);
    }
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  scrollToTop() {
    window.scrollTo(0, 0);
  }


  pageChangedWithoutLoginActivities(event) {
    this.p = event;
    this.getActivityLogWithoutLogin(this.p);
    this.cdr.detectChanges();
  }

  pageChangedWithoutLoginActivitiesApproved(event) {
    this.p = event;
    this.getActivityLogWithoutLoginApproved(this.p);
    this.cdr.detectChanges();
  }

  getActivityLogWithoutLogin(page) {
    this.spinner.show();
    let payload = {
      name: '',
      mobileNo: '',
      activityDescription: '',
      currentPage: page,
      approvalType: 'pending'
    };

    this.serviceApi.getActivityLogWithoutLogin(payload).subscribe(
      (res: any) => {
        this.spinner.hide();
        if (res.GetActivityLogWithoutLoginResult && res.GetActivityLogWithoutLoginResult.result === "Success") {
          this.lstactivityLogList = res.GetActivityLogWithoutLoginResult.resultActivityLogWithoutLogin;
          if (this.lstactivityLogList.length > 0) {
            this.pendingCount = this.lstactivityLogList[0].RecordCount;
            this.tempActivityLogList = this.lstactivityLogList;
            this.scrollToTop();
          }
        } else {
          console.error('API response not as expected:', res);
        }
      },
      (error) => {
        this.spinner.hide();
        console.error('Error fetching activity log data:', error);
      }
    );
  }

  getActivityLogWithoutLoginApproved(page) {
    this.spinner.show();
    let payload = {
      name: '',
      mobileNo: '',
      activityDescription: '',
      currentPage: page,
      approvalType: 'approved'
    };

    this.serviceApi.getActivityLogWithoutLogin(payload).subscribe(
      (res: any) => {
        this.spinner.hide();
        if (res.GetActivityLogWithoutLoginResult && res.GetActivityLogWithoutLoginResult.result === "Success") {
          this.lstactivityLogListApproved = res.GetActivityLogWithoutLoginResult.resultActivityLogWithoutLogin;
          if (this.lstactivityLogListApproved.length > 0) {
            this.count = this.lstactivityLogListApproved[0].RecordCount;
            this.tempActivityLogListApproved = this.lstactivityLogListApproved;
            this.scrollToTop();
          }
        } else {
          console.error('API response not as expected:', res);
        }
      },
      (error) => {
        this.spinner.hide();
        console.error('Error fetching activity log data:', error);
      }
    );
  }

  isNumberKey(event: KeyboardEvent): boolean {
    const charCode = event.charCode ? event.charCode : event.keyCode;
    if (charCode !== 8 && charCode !== 9 && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    }
    return true;
  }

  resetActivitiesOptions() {
    this.searchedAll = '';
    this.searchedName = '';
    this.searchedMobileNo = '';
    this.searchedActivityDescription = '';
  }

  searchedByAll(event: any) {
    if (event.target.value != '' && event.target.value != undefined) {
      this.lstactivityLogList = this.tempActivityLogList.filter((x: any) =>
        x.name.toString().toLowerCase().includes(event.target.value.toLowerCase()) ||
        x.mobileNo.toString().toLowerCase().includes(event.target.value.toLowerCase()) ||
        x.email.toString().toLowerCase().includes(event.target.value.toLowerCase()) ||
        x.activityDescription.toLowerCase().includes(event.target.value.toLowerCase())
      );
    } else {
      this.lstactivityLogList = this.tempActivityLogList;
    }
  }

  searchedByName(event: any) {
    if (event.target.value != '' && event.target.value != undefined) {
      this.lstactivityLogList = this.tempActivityLogList.filter((x: any) =>
        x.name.toString().toLowerCase().includes(event.target.value.toLowerCase())
      );
    } else {
      this.lstactivityLogList = this.tempActivityLogList;
    }
  }

  searchedByMobileNo(event: any) {
    const inputValue = event.target.value;
    if (/^\d*$/.test(inputValue)) {
      if (inputValue !== '') {
        this.lstactivityLogList = this.tempActivityLogList.filter((x: any) =>
          x.mobileNo.toString().toLowerCase().includes(inputValue.toLowerCase())
        );
      } else {
        this.lstactivityLogList = this.tempActivityLogList;
      }
    } else {
      event.target.value = inputValue.replace(/\D/g, '');
    }
  }

  searchedByActivityDescription(event: any) {
    if (event.target.value != '' && event.target.value != undefined) {
      this.lstactivityLogList = this.tempActivityLogList.filter((x: any) =>
        x.activityDescription.toLowerCase().includes(event.target.value.toLowerCase())
      );
    } else {
      this.lstactivityLogList = this.tempActivityLogList;
    }
  }

  resetActivitiesApprovedOptions() {
    this.searchedAllApproved = '';
    this.searchedNameApproved = '';
    this.searchedMobileNoApproved = '';
    this.searchedActivityDescriptionApproved = '';
  }

  searchedByAllApproved(event: any) {
    if (event.target.value != '' && event.target.value != undefined) {
      this.lstactivityLogListApproved = this.tempActivityLogListApproved.filter((x: any) =>
        x.name.toString().toLowerCase().includes(event.target.value.toLowerCase()) ||
        x.mobileNo.toString().toLowerCase().includes(event.target.value.toLowerCase()) ||
        x.email.toString().toLowerCase().includes(event.target.value.toLowerCase()) ||
        x.activityDescription.toLowerCase().includes(event.target.value.toLowerCase())
      );
    } else {
      this.lstactivityLogListApproved = this.tempActivityLogListApproved;
    }
  }

  searchedByNameApproved(event: any) {
    if (event.target.value != '' && event.target.value != undefined) {
      this.lstactivityLogListApproved = this.tempActivityLogListApproved.filter((x: any) =>
        x.name.toString().toLowerCase().includes(event.target.value.toLowerCase())
      );
    } else {
      this.lstactivityLogListApproved = this.tempActivityLogListApproved;
    }
  }

  searchedByMobileNoApproved(event: any) {
    const inputValue = event.target.value;
    if (/^\d*$/.test(inputValue)) {
      if (inputValue !== '') {
        this.lstactivityLogListApproved = this.tempActivityLogListApproved.filter((x: any) =>
          x.mobileNo.toString().toLowerCase().includes(inputValue.toLowerCase())
        );
      } else {
        this.lstactivityLogListApproved = this.tempActivityLogListApproved;
      }
    } else {
      event.target.value = inputValue.replace(/\D/g, '');
    }
  }

  searchedByActivityDescriptionApproved(event: any) {
    if (event.target.value != '' && event.target.value != undefined) {
      this.lstactivityLogListApproved = this.tempActivityLogListApproved.filter((x: any) =>
        x.activityDescription.toLowerCase().includes(event.target.value.toLowerCase())
      );
    } else {
      this.lstactivityLogListApproved = this.tempActivityLogListApproved;
    }
  }



  clearPlaceholder() {
    this.placeholderText = '';
  }

  restorePlaceholder() {
    this.placeholderText = 'Search...';
  }

  OnlyLettersAllowed(event): boolean {
    const charCode = (event.which) ? event.which : event.keycode;
    if ((charCode < 97 || charCode > 123) && (charCode < 65 || charCode > 90)) {
      return false;
    }
    return true;
  }


  updateFollowUpStatus(activityLogWithoutLogInId: number, status: string) {
    if (status === ' ') {
      return;
    }

    const payload = {
      activityLogWithoutLogInId: activityLogWithoutLogInId,
      isFollowUpDone: status == 'Yes' ? 'Y' : 'N'
    };

    this.spinner.show();
    this.serviceApi.UpdateActivityLogWithoutLogin(payload).subscribe(
      (res: any) => {
        this.spinner.hide();
        if (res && res.status === 200) {
          this.alertService.success('Information is updated successfully!');
          this.updateMessage = res.result;
          this.getActivityLogWithoutLogin(this.p);
        } else {
          this.alertService.error('Failed to update follow-up status');
          console.error('Failed to update follow-up status:', res);
        }
      },
      (error) => {
        this.spinner.hide();
        this.alertService.error('Error updating follow-up status');
        console.error('Error updating follow-up status:', error);
      }
    );
  }


}
