<div class="head-sec " (click)="changeSearch()">
  <div class="topbar">
    <div class="topbar-socials">
      <ul class="redux-social-media-list clearfix">
        <li class="separator"></li>
        <li><a target="_blank" href="https://twitter.com/ToskieConnect"><i style="color: #1DA1F2;" class="fab fa-twitter"></i></a></li>
        <li class="separator"></li>
        <li><a target="_blank" href="https://www.instagram.com/toskieapp/"><i style="color: #C13584;" class="fab fa-instagram"></i></a></li>
        <li class="separator"></li>
        <li><a target="_blank" href="https://www.facebook.com/share/evnn2b6Y6Y3GurYZ/?mibextid=LQQJ4d"><i style="color:  #4267B2; " class="fab fa-facebook"></i></a></li>
        <li class="separator"></li>
        <li><a target="_blank" href="https://www.youtube.com/@toskie"><i style="color:  #FF0000; " class="fab fa-youtube"></i></a></li>
        <li class="separator"></li>
      </ul>
    </div>
    <div class="topbar-contact">
      <span class="separator"></span>
      <span class="topbar-phone"><a href="tel:08069179999"><i style="color: #1DA1F2; height:2px; width: 2px; position:relative; bottom: 5px; right: 18px; " class="fas fa-phone transparent-phone"></i><span class="contact-number">080 6917 9999</span></a></span>
      <span class="separator"></span>
      <span class="topbar-email"><a href="mailto:contact@toskie.com"><i style="color: #1DA1F2; height:2px; width: 2px; position:relative; bottom: 5px; right: 18px; " class="far fa-envelope"></i><span class="contact-email">contact@toskie.com</span></a></span>
      <span class="separator"></span>
      </div>
  </div>
<nav class="navbar navbar-expand-lg py-0" id="Navigation-ctrl">
        <div class="container-fluid">
          <p class="navbar-brand" (click)="home()">
              <img fetchpriority="high" src="../../../assets/images/home-toskie.png" alt="toskie logo" title="toskie logo" class="toskie-logo img-fluid" />
          </p>
          <div class="navbar-toggler border-0 p-0" >
            <ul class="list-group list-group-horizontal" *ngIf="!loggedIn">
                <li class="list-group-item border-0 navigationbar-ctrl " *ngIf="!loggedIn && !loginPage && !isHeader">
                    <h6 class="button cursor-pointer" aria-current="page" (click)="navigate('login')"
                    onclick="document.getElementById('login').style.display='block'">
                    <i class="fa fa-sign-in" aria-hidden="true"></i> &nbsp;Login/Sign up
                    </h6>
                </li>
                <li class="list-group-item border-0 navigationbar-ctrl " *ngIf="!loginPage && isHeader">
                    <h6 class="button cursor-pointer" aria-current="page" (click)="navigate('login')"
                    onclick="document.getElementById('login').style.display='block'">
                    <i class="fa fa-sign-in" aria-hidden="true"></i> &nbsp;Login/Sign up
                    </h6>
                </li>
                <li class="list-group-item border-0 navigationbar-ctrl " *ngIf="loginPage && !isHeader">
                    <h6 class="button cursor-pointer" aria-current="page" (click)="navigate('/')"
                    onclick="document.getElementById('login').style.display='block'">
                    <i class="fa fa-home" aria-hidden="true"></i>Home
                    </h6>
                </li>
                
                <li class="list-group-item navbar-toggler border-0 rounded-2 p-0">
                  <button class="btn-toggle rounded-2 btn-collapsed border-0" type="button" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="fa fa-bars"></span>
                  </button>
                </li>
            </ul>
              <ul class="list-group list-group-horizontal" *ngIf="loggedIn">
                <li class="list-group-item border-0" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Add Service">
                <h6 class="nav-link add-service-margin-ctrl active" aria-current="page">
                  <img fetchpriority="high" _ngcontent-qsm-c171="" src="../../../assets/images/addicon.png" class="d-none d-md-block add-service" (click)="callPageRoutes('addService')"
                  (click)="getServices()" alt="add service" title="add service">
                  <img fetchpriority="high" _ngcontent-qsm-c171="" src="../../../assets/images/addservice-mobile.png" class="d-md-none add-service" (click)="callPageRoutes('addService')"
                  (click)="getServices()" alt="add service" title="add service">
                  <span class="d-md-none position-absolute top-ctrl-80 start-0 service-text">Service</span>
                </h6>
              </li>
              <li class="list-group-item border-0" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Admin" *ngIf="currentUser.roleId!=1">
                <h6 class="nav-link active cursor-pointer" aria-current="page">
                <img fetchpriority="high" src="../../../assets/images/admin-panel.png" alt="admin" title="admin" class="admin" 
                (click)="callPageRoutes('adminPanel')" />
                </h6>
              </li>
              <li class="list-group-item border-0" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Notification">
                <h6 class="nav-link cursor-pointer">
                <img fetchpriority="high" src="../../../assets/images/notification.png" alt="notification" title="notification" class="notification" 
                onclick="document.getElementById('notifications').style.display='block'" />
                </h6>
              </li>
              <li class="nav-item position-relative px-1" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Wishlist">
                <h6 class="nav-link cursor-pointer" >
                <img fetchpriority="high" src="../../../assets/images/wishlist.png" alt="wishlist" title="wishlist" class="wishlist" (click)="getWishlist()"/>
                <!-- <div class="wishlist-badge" *ngIf="wishlistCount > 0">{{ wishlistCount }}</div> -->
                
                <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger badge-ctrl"
                *ngIf="wishlistCount > 0">
                    {{ wishlistCount }}
                    <span class="visually-hidden">unread messages</span>
                </span>
                
                </h6>
              </li>
             
              <li  class="nav-item dropdown px-1" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Profile">
                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <div class="profile-image">
                        <p *ngIf="imagetext!= null">{{imagetext}}</p>
                        <img fetchpriority="high" *ngIf="image!=null" src="{{image}}" alt="Profile Picture" title="Profile Picture" (error)="myImage()">
                     </div>
             
                  </a>
                  <ul class="dropdown-menu profile-dropdown-menu" aria-labelledby="navbarDropdown">
                    <li class="list-group-item w-100 border-top-0" (click)="viewProfile(currentUser)">
                      <span class="badge badge-secondary profile-pic">
                        <span class="profile-text-ctrl" *ngIf="imagetext!= null" id="cardProfileImg">{{imagetext}}</span>
                        <img fetchpriority="high" *ngIf="image!=null" src="{{image}}" (error)="myImage()" alt="profile" title="profile" classs="profile-dropdown-image">
                      </span>
                      <span class="profile-Name" (click)="viewProfile(currentUser)" >{{currentUser.name | titlecase }}</span>
                    </li>
                    <li  class="dropdown-item call-logs align-bg left-Padding" (click)="goToCallLogs()">
                         Call Logs
                    </li>
                    <li class="dropdown-item" (click)="goToRefer()">
                      <i class="fa-solid fa-users fa-fw"></i> Refer a Friend
                    </li>
                    <li class="dropdown-item" onclick="document.getElementById('id01').style.display='block'">
                        <i class="fa-solid fa-trash-can fa-fw"></i> Delete Account
                     
                    </li>
                    <li class="dropdown-item" onclick="document.getElementById('id011').style.display='block'">
                       <i class="fa-solid fa-power-off fa-fw"></i> Log Out
                    </li>
                  </ul>
              </li>
              
              <li class="list-group-item navbar-toggler border-0 rounded-2 p-0">
                <button class="btn-toggle rounded-2 btn-collapsed border-0" type="button" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                  <span class="fa fa-bars"></span>
                </button>
              </li>
            </ul> 
          </div>
          <div class="collapse navbar-collapse " id="navbarSupportedContent">
            <ul class="navbar-nav mx-auto mb-2 mb-lg-0 navigationbar-ctrl">
              <li class="nav-item" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Home">
                <h6 (click)="navigate('/about-us')" class="nav-link active cursor-pointer" aria-current="page" (click)="home()">
                <!-- <a class="nav-link active" aria-current="page"> -->
                    Home</h6><span class="divider">|</span>
              </li>
              <li class="nav-item" data-bs-toggle="tooltip" data-bs-placement="bottom" title="About Us">
                <h6 class="nav-link active cursor-pointer" (click)="navigate('/about-us')">About Us</h6><span class="divider">|</span>
              </li>
              <li class="nav-item" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Contact Us">
                <h6 (click)="navigate('/contact-us')" class="nav-link active cursor-pointer">Contact Us</h6><span class="divider">|</span>
              </li>
              
              <li class="nav-item" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Blogs">
                <h6 class="nav-link  cursor-pointer"><a href="{{API_URL}}">Blogs</a></h6>
              </li>
            </ul>
            <ul class="navbar-nav me-0 mb-2 mb-lg-0 display-none" *ngIf="!loggedIn">
                <li class="nav-item navigationbar-ctrl"  *ngIf="!loggedIn && !loginPage && !isHeader">
                    <h6 class="nav-link hand login-btn-css cursor-pointer" aria-current="page" (click)="navigate('login')"
                    onclick="document.getElementById('login').style.display='block'">
                    <i class="fa fa-sign-in" aria-hidden="true"></i>&nbsp;&nbsp;Login/Sign up
                    </h6>
                </li>
                <li class="nav-item navigationbar-ctrl" *ngIf="!loginPage && isHeader">
                    <h6 class="nav-link hand login-btn-css cursor-pointer" (click)="navigate('login')"
                        onclick="document.getElementById('login').style.display='block'" >
                        <i class="fa fa-sign-in" aria-hidden="true"></i>&nbsp;&nbsp;Login/Sign up
                    </h6>
                </li>
                <li class="nav-item navigationbar-ctrl" *ngIf="loginPage && !isHeader">
                    <h6 class="nav-link hand login-btn-css cursor-pointer" (click)="navigate('/')"
                    onclick="document.getElementById('login').style.display='block'">
                    <i class="fa fa-home" aria-hidden="true"></i>&nbsp;&nbsp;Home
                 </h6>
                </li>
            </ul>
            <ul class="navbar-nav me-0 mb-2 mb-lg-0 profile-menu" *ngIf="loggedIn">
              <li class="nav-item display-none" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Add Service">
                <h6 class="nav-link cursor-pointer add-service-margin-ctrl active" aria-current="page">
                  <img fetchpriority="high" src="../../../assets/images/addicon.png" alt="add service" title="add service" class="add-service" (click)="callPageRoutes('addService')"
                  (click)="getServices()" />
                </h6>
              </li>
              <li class="nav-item display-none" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Admin" *ngIf="currentUser.roleId!=1">
                <h6 class="nav-link cursor-pointer active" aria-current="page">
                <img fetchpriority="high" fetchpriority="high" src="../../../assets/images/admin-panel.png" alt="admin" title="admin" class="admin" 
                (click)="callPageRoutes('adminPanel')" />
                </h6>
              </li>
              <li class="nav-item display-none" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Notification" (click)="navigateToUserNotification()">
                <h6 class="nav-link cursor-pointer">
                <img fetchpriority="high" src="../../../assets/images/notification.png" alt="notification" title="notification" class="notification"  />
                <span class="position-absolute top-14 start-80 translate-middle badge rounded-pill bg-danger"
                *ngIf="notificationCount > 0">
                    {{ notificationCount }}
                    <span class="visually-hidden">unread notification</span>
                </span>
                </h6>
              </li>
                     
              <li class="nav-item display-none position-relative" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Wishlist">
                <h6 class="nav-link cursor-pointer">
                <img fetchpriority="high" src="../../../assets/images/wishlist.png" alt="wishlist" title="wishlist" class="wishlist" 
                (click)="getWishlist()" />        
                <!-- <div class="wishlist-badge" *ngIf="wishlistCount > 0">{{ wishlistCount }}</div> -->
                <span class="position-absolute top-14 start-80 translate-middle badge rounded-pill bg-danger"
                *ngIf="wishlistCount > 0">
                    {{ wishlistCount }}
                    <span class="visually-hidden">unread messages</span>
                </span>
                </h6>
              </li>
              <li  class="nav-item display-none dropdown" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Profile">
                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <div class="profile-image">
                      <p *ngIf="imagetext!= null">{{imagetext}}</p>
                      <img fetchpriority="high" *ngIf="image!=null" src="{{image}}" alt="Profile Picture" title="Profile Picture" (error)="myImage()">
                    </div>
                 <!-- You can also use icon as follows: -->
                   <!--  <i class="fas fa-user"></i> -->
                  </a>
                  <ul class="dropdown-menu profile-dropdown-menu" aria-labelledby="navbarDropdown">
                    <li class="list-group-item w-100 border-top-0" (click)="viewProfile(currentUser)">
                      <span class="badge badge-secondary profile-pic">
                        <span class="profile-text-ctrl" *ngIf="imagetext!= null" id="cardProfileImg">{{imagetext}}</span>
                        <img fetchpriority="high" *ngIf="image!=null" src="{{image}}" (error)="myImage()" alt="profile" title="profile" classs="profile-dropdown-image">
                      </span>
                      <span class="profile-Name" (click)="viewProfile(currentUser)" >{{currentUser.name | titlecase }}</span>
                    </li>
                    <li class="dropdown-item call-logs align-bg left-Padding" (click)="goToCallLogs()">
                         Call Logs
                    </li>
                    <li class="dropdown-item" (click)="goToRefer()">
                      <i class="fa-solid fa-users fa-fw"></i> Refer a Friend
                    </li>
                    <li class="dropdown-item" onclick="document.getElementById('id01').style.display='block'">
                        <i class="fa-solid fa-trash-can fa-fw"></i> Delete Account
                    </li>
                    <li class="dropdown-item" onclick="document.getElementById('id011').style.display='block'">
                       <i class="fa-solid fa-power-off fa-fw"></i> Log Out
                    </li>
                  </ul>
              </li>
             
            </ul>
          </div>
        </div>
  </nav>

<div *ngIf="isSearchResultsPage || isHeader">
    <div class="text-center">
        <!-- <h1 class="discover-desp1"> Services / Professionals </h1> -->
        <h1 class="discover-desp2"> Connects people and professionals from your neighbourhood </h1>
    </div>
    <div class="row text-center padinput">
      <div class="homepage-sec">
        <div class="row d-flex align-items-center">
            <!-- Search Area -->
            <div class="col-md-5 col-sm-12">
                <div class="rectangle1">
                    <div class="row serviceInput">
                        <div class="col-2 text-end pad-right10 pad0">
                            <!-- <img fetchpriority="high" src="../../../assets/images/magnifyingglass.png" alt="service image" title="service image" class="searchIcon pad-right0"> -->
                            <i class="fa-solid fa-magnifying-glass magnifying-glass"></i>
                        </div>
                        <div class="col-10 pad0">
                          <strong style="display: flex; width: 100%;">
                            <input type="text"
                                   name="providerProfession"
                                   class="form-control inputs"
                                   autocomplete="off"
                                   id="providerProfession"
                                   (input)="professionChange($)"
                                   placeholder="Looking for Service / Professional"
                                   #providerProfession="ngModel"
                                   [(ngModel)]="providerSearch"
                                   [appTypingPlaceholder]="['Plumber', 'Doctor', 'Teacher']">
                            <i class="fa fa-times-circle-o clearBtn"
                               *ngIf="providerSearch !== ''"
                               (click)="clearSearch()"></i>
                            <div class="position-absolute hand ex1"
                                 *ngIf="isDropdown"
                                 style="z-index: 1 !important; margin-top: 45px; background-color: #EDF8FE;">
                              <ul style="text-align: left; padding: 0;">
                                <li *ngFor="let f of lstallDRpServices"
                                    (click)="selectedFilter(f)"
                                    class="list-group-item hand">
                                  {{f.subProfessionCategoryName}}
                                </li>
                              </ul>
                            </div>
                          </strong>
                        </div>
                        
                    </div>
                </div>
            </div>
    
            <!-- Location Area -->
            <div class="col-md-5 col-sm-12">
                <div class="rectangle2">
                    <div class="row serviceInput">
                        <div class="col-2 text-end pad-right10 pad0">
                            <!-- <img fetchpriority="high" src="../../../assets/images/location.png" alt="location icon" title="location icon" class="pad-right0"> -->
                            <i class="fa-solid fa-location-dot location-dot"></i>
                        </div>
                        <div class="col-10 pading0 pad-l-0 location">
                            <app-autocomplete-google-places [currentAddress]="localAddress" (setAddress)="getLocationAddressCoordinates($event)" (change)="providerfilter()"></app-autocomplete-google-places>
                        </div>
                    </div>
                </div>
            </div>
    
            <!-- Search Button -->
            <div class="col-md-2 col-sm-12 d-flex align-items-center">
                <div class="rectangle searchdiv" (click)="providerfilter()">
                    <label class="Search hand">Search</label>
                </div>
            </div>
        </div>
    </div>
    </div>
    <div class="row text-center padrange">
        <div>
            <div class="row">
                <div class="col-10 col-md-10 pad-00 px-0">
                    <input type="range" class="hand" name="providerFilterDistance" style="width: 100%;"
                        [(ngModel)]="providerFilterDistance" id="providerFilterDistance" min="0" max="25"
                        (change)="providerfilter()" [disabled]="!isSearchResultsPage">
                </div>
                <h6 class="col-2 col-md-2 kms">{{providerFilterDistance}} Kms </h6>
            </div>
        </div>
    </div>
    </div>
</div>

<div id="id01" class="modal" (click)="closeModal($event,'id01')">
    <form class="modal-content" action="/action_page.php">
      <div class="container">
        <h2>Delete Account <span (click)="closeModal($event,'id01')" class="close" title="Close Modal">×</span></h2>
        <p>Are you sure you want to delete your account?</p>
        <div class="clearfix">
          <button type="button" onclick="document.getElementById('id01').style.display='none'" class="cancelbtn modalbutton">Cancel</button>
          <button type="button" (click)="deleteAccount()" class="deletebtn modalbutton">Delete</button>
        </div>
      </div>
    </form>
  </div>

<div id="id011" class="modal" (click)="closeModal($event,'id011')">
    <form class="modal-content" action="/action_page.php">
      <div class="container">
        <h2>Logout <span (click)="closeModal($event,'id011')" class="close" title="Close Modal">×</span></h2>
        <p>Are you sure, Do you want to Logout?</p>
        <div class="clearfix">
          <button type="button" onclick="document.getElementById('id011').style.display='none'" class="modalbutton cancelbtn">Cancel</button>
          <button type="button" (click)="logoutUser()" class="modalbutton btn-blue">Logout</button>
        </div>
      </div>
    </form>
</div>
  
<!-- MODALS -->
<!-- LOGIN MODAL -->
<div class="modal" id="Login" aria-hidden="true" aria-labelledby="loginModalToggleLabel" tabindex="-1">
    <div class="modal-dialog ">
        <div class="modal-content">
            <div class="modal-body">
                <!-- <app-login #loginComponent></app-login> -->
            </div>
        </div>
    </div>
</div>

<!-- REGISTRATION MODAL -->
<div class="modal" id="Register" aria-hidden="true" aria-labelledby="registerModalToggleLabel" tabindex="-1">
    <div class="modal-dialog ">
        <div class="modal-content">
            <div class="modal-body">
            </div>
        </div>
    </div>
</div>

<!-- header popup starts -->
<div id="adservice" class="servicemodals" style="border-radius: 0px !important;">
    <form class="modal-content add-service-section" action="/action_page.php">
        <div class="container">
            <h2>
                <span onclick="document.getElementById('adservice').style.display='none'" class="servicehead-close"
                    title="Close Modal">Ã—</span>
            </h2>
            <h2 class="Im-Service-Provider-Professional">Iâ€™m Service Provider / Professional</h2>
            <div class="row" style="padding: 2%;">
                <label class="Select-Service pad-00">
                    Select Service
                </label>
                <div class="col-9 col-md-10 my-custom-parent pad-00">
                    <ng-select [items]="lstServices" bindLabel="subProfessionCategoryName"
                        class="service-dropdown custom-ng-select-container" placeholder="Add your Service here"
                        #selectBoxs (change)="changeServicedrp($event)">
                    </ng-select>
                </div>
                <div class="col-3 col-md-2">
                    <button class="hand ADD" (click)="addServiceField()" [disabled]="!isadd"
                        [ngClass]="{'add-btn': isadd, 'add-btn-disabled' : !isadd }">
                        <i class="fa-fa-plus "></i> ADD</button>
                </div>
            </div>
            <div class="row" style="padding: 2%;" *ngIf="loggedIn">
                <div class="col-6 col-md-4 pad-0 pad-00 mrg-b15" *ngFor="let s of currentUser.Services;let i = index;">
                    <div class="Ellipse-4" (click)="removeService(s.CategoryId)">
                        <span style="color: #fff;">x</span>
                    </div>
                    <div class="row service-section">
                        <div class="col-3 col-md-3 pad-00">
                            <div class="serviceLetter-section"><span class="serviceLetter">
                                    {{getServiceLetter(s.userService)}}
                                </span></div>
                        </div>
                        <div class="col-9 col-md-9 servicce">
                            <span class="serviceName">
                                {{s.userService}}
                            </span>
                        </div>
                    </div>
                </div>
                <label style="color: red;" *ngIf="isRemovedService">Required minimum 1 service</label>
                <div class="row pr-0">
                    <div class="col-6 col-md-6 intial">
                        <button class="cancel-btn hand"
                            onclick="document.getElementById('adservice').style.display='none'">
                            Cancel
                        </button>
                    </div>
                    <div class="col-6 col-md-6 pr-0">
                        <button class="done-btn hand"
                            onclick="document.getElementById('adservice').style.display='none'">
                            Done
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
<!-- header popup ends -->

<div class="modal " id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">Modal title</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          ...
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary">Save changes</button>
        </div>
      </div>
    </div>
  </div>
  