import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/shared/alert/alert.service';
import { ServerApisService } from 'src/app/shared/services/server-apis.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Role } from 'src/app/shared/enums/role.enum';
import { Constants,navConstants } from 'src/app/shared/constants/constant';
import { RouteMakingService } from 'src/app/shared/services/route-making.service';


@Component({
  selector: 'app-create-advertisement',
  templateUrl: './create-advertisement.component.html',
  styleUrls: ['./create-advertisement.component.css']
})
export class CreateAdvertisementComponent implements OnInit {

  constructor(private router: Router,private formBuilder: FormBuilder,private _apiService:ServerApisService,private spinner: NgxSpinnerService,
    private _alertService: AlertService,private datePipe: DatePipe,private routeMakingService: RouteMakingService) { }
    @ViewChild('selectBox') selectBox: NgSelectComponent;
    advertisementForm: FormGroup;
   lstSelectedCategories:any=[];
   minDate :any;
  submitted = false;
  extn: any;
   blob: any;
  filestream: any;
   profleimge:any;
   lstadvertisements:any=[];
   lsttempadvertisements:any=[];
   lstUsers:any=[];
   isupdate:boolean=false;
   lsttempusers:any=[];
   isCard: boolean = true;
   ImgValidate: any;
   adminPanel:string=Constants.adminPanel;
   advertisement:string=Constants.advertisement;
   advertisementNav:string=navConstants.postAdvertisementNav;
   adminPanelRoute:string=Constants.adminPanelRoute;
   advertisementTitle:string=Constants.postAdvertisementTitle;
   page: string = 'createadvertisements';
   selectedFile: File | null;
  ngOnInit(): void {
    const urlRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
    var date= new Date();
    date=new Date(date.setDate(date.getDate() + 1));
    this.minDate=this.datePipe.transform(date,"yyyy-MM-dd")
    const user = sessionStorage.getItem('user');
    if (user != null) {
      let data=JSON.parse(user);
      if (data.roleId === Role.Admin || data.roleId === Role.SubAdmin || data.roleId === Role.SalesTeam || data.roleId === Role.Manager) {
        this.advertisementForm = this.formBuilder.group({
        title: ['', Validators.required],
        description: ['', Validators.required],
        date:['', Validators.required],
        image:['', Validators.required],
        vediopath:['',[Validators.pattern(urlRegex)]],
        screen:["Dashboard", Validators.required],
        category:["Top", Validators.required],
        price:[""],
        redirectlink:[''],
        phone:[""],
        email:[""],
        clientType:['InternalClient'],
        clientNavigation :['Profile'],
        navigatingToMemberId:["0"],
        selectedUser:[]
      });
  let path=window.location.href.split('advertisement/')
      if(path[1]=='post-advertisement'){
        this.searchtab('createadvertisements')
       }else if(path[1]=='modify-advertisement'){
        this.searchtab('advertisement')
       }
    }else{
      this.router.navigate([""]);
    }
      
    }else{
      this.router.navigate([this.routeMakingService.getNavigateUrl([Constants.Login])]);
    } 
  }
 
  getallAds(){
    // this.spinner.show();
    // this._apiService.FetchAllAds().subscribe(res => {
    //   this.spinner.hide();
    //   this.lstadvertisements = res.resultAdvertisementsArgs;
    //   this.lsttempadvertisements = res.resultAdvertisementsArgs;
    //   this.page="advertisement";
    // });
  }

  onMouseEnter() {
    this.isCard = true;
  }

  onMouseLeave() {
    this.isCard = false;
  }
  searchtab(pageName: string) {
    let advertisementTitle: string;

    if (pageName === 'createadvertisements') {
      advertisementTitle = Constants.postAdvertisementTitle;
      this.advertisementNav =navConstants.postAdvertisementNav;
      this.router.navigate([this.routeMakingService.getNavigateUrl([Constants.adminPanelRoute,Constants.advertisement,navConstants.postAdvertisementNav])]);
    } else if (pageName === 'advertisement') {
      advertisementTitle =Constants.modifyAdvertisementTitle;
        this.advertisementNav =navConstants.modifyAdvertisementNav;
        this.router.navigate([this.routeMakingService.getNavigateUrl([Constants.adminPanelRoute,Constants.advertisement,navConstants.modifyAdvertisementNav])]);
    } 
    this.page = pageName; 
    this.advertisementTitle = advertisementTitle;
    this.lstadvertisements = [];
    this.lsttempadvertisements = [];

    if (pageName == 'advertisement') {
        this.getallAds();
    } else if (pageName= 'createadvertisements') {
        this.advertisementForm.reset();
        this.advertisementForm.patchValue({ screen: 'Dashboard' });
        this.advertisementForm.patchValue({ category: 'Top' });
        this.advertisementForm.patchValue({ image: '' });
        this.advertisementForm.patchValue({ clientType: 'InternalClient' });
        this.advertisementForm.patchValue({ clientNavigation: 'Profile' });
        this.advertisementForm.patchValue({ navigatingToMemberId: '0' });
        this.selectBox.clearModel();
    }
    this.isCard = true;
}

}
