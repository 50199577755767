import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RoleManagmentRoutingModule } from './role-managment-routing.module';


@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    RoleManagmentRoutingModule
  ]
})
export class RoleManagmentModule { }
