import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService } from 'src/app/shared/alert/alert.service';
import { ServerApisService } from 'src/app/shared/services/server-apis.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { FormControl } from '@angular/forms';
import { Role } from 'src/app/shared/enums/role.enum'; 
import { DatePipe } from '@angular/common';
import { Constants,navConstants } from 'src/app/shared/constants/constant';
import { RouteMakingService } from 'src/app/shared/services/route-making.service';
import { DataService } from 'src/app/shared/services/data.service';

@Component({
  selector: 'app-sponsored-user-user-management',
  templateUrl: './sponsored-user-user-management.component.html',
  styleUrls: ['./sponsored-user-user-management.component.css']
})
export class SponsoredUserUserManagementComponent {
  p = 1;
  id: number = 0;
  isdropdownshow: boolean = false;
  memberID: number = Number(sessionStorage.getItem('memberId'));
  page: string = 'rolecreation';
  placeholderText: string = 'Search with Id and Name';
  searchedSponsoredUniqueID: any = '';
  searchedSponsoredName: any = '';
  searchedSponsoredMemberID: any = '';
  isChecked: any;
  selectedSponsoredOption: any = 'name';
  listAllSponsoredAccount: any;
  sponsoredPosition: any = '';
  toDate: any = '';
  fromDate: any = '';
  isSponsored: boolean = false;
  temp: any;
  minDate :any;
  todayDate:any;
  maxDate:any;

  constructor(private spinner: NgxSpinnerService,private routeMakingService:RouteMakingService, private _apiService: ServerApisService, private router: Router, private sanitizer: DomSanitizer,private datePipe: DatePipe,
    private _alertService: AlertService,private _elementRef: ElementRef,private renderer:Renderer2,private dataService:DataService) { }
   
    ngOnInit(): void {
      const user = sessionStorage.getItem('user');
      if (user != null) {
        let data = JSON.parse(user);
        if (Role.Admin == data.roleId || Role.SubAdmin == data.roleId) {
          let date = new Date();
          this.todayDate = this.datePipe.transform(date, "yyyy-MM-dd");
          this.fetchSponsoredAccount();
         let path=window.location.href.split('user-management/')
      } else {
          this.router.navigate([""]);
        }
       } else {
        this.router.navigate([this.routeMakingService.getNavigateUrl([Constants.Login])]);
      }
  }

  changeAccountVerified({ target: { checked } }, data) {
    data.isSponsored = checked ? 'Yes' : 'No';
    if (!checked) {
        data.sponsoredFromDate = null;
        data.sponsoredToDate = null;
    } 
    if(data.sponsoredPosition){
      this.updateSponsoredAccount(data);
      data.sponsoredFromDate = 'dd/mm/yyyy';
      data.sponsoredToDate = 'dd/mm/yyyy';
      data.sponsoredPosition = null;
    }
  }

  changeFromDate({ target: { value } }, data) {
    data.fromDate = new Date(value);
    data.enableToDate = this.isValidDate(data.fromDate);
    // this.resetInvalidDates(data);
    this.updateEnabledActionItems(data);
  }

  changeToDate({ target: { value } }, data) {
    data.toDate = new Date(value);
    data.enableSponsoredPosition = this.isValidDate(data.toDate);
    // this.resetInvalidDates(data);
    this.updateEnabledActionItems(data);
  }

  updateEnabledActionItems(data) {
    if (this.isValidDate(data.fromDate) && this.isValidDate(data.toDate) && data.sponsoredPosition && data.fromDate <= data.toDate) {
      data.enabledActionItems = true;
    } else {
      data.enabledActionItems = false;
    }

    if(data.fromDate > data.toDate) {
      this._alertService.error('From Date should be less than To Date');
    }
  }

  resetInvalidDates(data) {
    if (!this.isValidDate(data.fromDate)) {
      data.fromDate = null;
    }
    if (!this.isValidDate(data.toDate)) {
      data.toDate = null;
    }
    if (data.fromDate && data.toDate && data.fromDate > data.toDate) {
      data.fromDate = null;
      data.toDate = null;
    }
  }

  isValidDate(date) {
    return date instanceof Date;
  }

  changeSponsoredPosition({ target: { value } }, data) {
    data.sponsoredPosition = value;
    if (this.isValidDate(data.fromDate) && this.isValidDate(data.toDate) && data.sponsoredPosition && data.fromDate <= data.toDate) {
      data.enabledActionItems = Boolean(data.sponsoredPosition);
    }
  }

  cancelSponsoredAccountEdit(data) {
    data.enabledActionItems = false;
    this.fetchSponsoredAccount();
  }

  formatDate(dateString: string): string {
    return dateString ? dateString.split(' ')[0] : '';
  }

  arrangeDate(dateString: string): string {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  updateSponsoredAccount(data) {
    const userMemberId = sessionStorage.getItem('memberId');
    const payload = {
      memberId: data.memberId,
      sponsoredUnsponsoredBy: userMemberId,
      sponsoredPosition: data.sponsoredPosition,
      sponsoredFromDate: data.fromDate ? this.arrangeDate(data.fromDate) : data.sponsoredFromDate,
      sponsoredToDate: data.toDate ? this.arrangeDate(data.toDate) : data.sponsoredToDate, 
      isSponsored: data.isSponsored 
    };
    this._apiService.updateAccountSponsoredUnSponsored(payload).subscribe((res: any) => {
      if (res.result.includes('Account Sponsored Successfully')) {
        this._alertService.success(res.result);
        data.enabledActionItems = false;
      } else {
        this._alertService.error(res.result);
      }
    });
  }

  searchBySponsoredUniqueId(event: any) {
    if (event.target.value != '' && event.target.value != undefined) {
      this.listAllSponsoredAccount = this.listAllSponsoredAccount.filter((x: any) =>
        x.UniqueCode.toString().toLowerCase().includes(event.target.value.toLowerCase())
      );
    } else {
      this.listAllSponsoredAccount = this.temp;
    }
  }

  searchBySponsoredMemberId(event: any) {
    if (event.target.value != '' && event.target.value != undefined) {
      this.listAllSponsoredAccount = this.listAllSponsoredAccount.filter((x: any) =>
        x.memberId.toString().toLowerCase().includes(event.target.value.toLowerCase())
      );
    } else {
      this.listAllSponsoredAccount = this.temp;
    }
  }

  searchBySponsoredName(event: any) {
    if (event.target.value != '' && event.target.value != undefined) {
      this.listAllSponsoredAccount = this.listAllSponsoredAccount.filter((x: any) =>
        x.Name.toLowerCase().includes(event.target.value.toLowerCase())
      );
    } else {
      this.listAllSponsoredAccount = this.temp;
    }
  }

  resetSponsoredOptions() {
    this.searchedSponsoredMemberID = '';
    this.searchedSponsoredName = '';
    this.searchedSponsoredUniqueID = '';
  }

  fetchSponsoredAccount() {
    this._apiService.fetchAccountSponspored().subscribe((res: any) => {
      this.listAllSponsoredAccount = res.FetchUserAccountSponsoredUnSponsoredResult.resultAccountSponsoredUnSponsored;
      this.temp = this.listAllSponsoredAccount;
    });
  }

  closeRoleDropdown(event: any) {
    // 
    // if(this.isdropdownshow==false){
    //   this.dropdownshow();
    // }

  }

  sponsorPageChanged(event: any) {
    this.p = event;
    window.scrollTo(0, 0);
  }

}
